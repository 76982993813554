import React from 'react';

import { LayoutV2 } from '@bilira-org/design';

import WithdrawInfoBar from '@Modules/crypto/components/WithdrawInfoBar';
import Header from '@Modules/crypto/withdraw/components/Header';
import CryptoWithdrawal from '@Modules/crypto/withdraw/CryptoWithdrawal';
import LastCryptoWithdrawals from '@Modules/crypto/withdraw/LastCryptoWithdrawals';

const { Content, InnerContent, Aside } = LayoutV2;

const CryptoWithdrawalContainer = () => {
  return (
    <>
      <Content>
        <InnerContent>
          <WithdrawInfoBar />
          <Header />
          <CryptoWithdrawal />
        </InnerContent>
        <Aside>
          <LastCryptoWithdrawals />
        </Aside>
      </Content>
    </>
  );
};

export default CryptoWithdrawalContainer;

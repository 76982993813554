import React, { memo } from 'react';

import { Modal, Text } from '@bilira-org/design';
import { TwoFAMethodsType, TwoFAOptionsType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import TwoFA from '@Components/TwoFA/components/TwoFA';

type Props = {
  /** Whether the modal is active */
  active: boolean;
  /** Function to be called when modal is closed */
  setActive: (active: boolean) => void;
  /** Data for available 2fa authentication methods */
  twoFAData?: TwoFAMethodsType;
  /** Function to be called with token after 2fa verified */
  callback: (values: string) => void;
  /** Action type to be verified with 2fa (e.g. 'addCryptoWallet', 'fiatWithdrawal') */
  type: TwoFAOptionsType;
  /** Additional payload to be sent with 2fa request */
  additionalPayload?: Record<string, string | undefined>;
};

/**
 * Component for displaying a modal that prompts the user to complete two-factor authentication (2FA) verification.
 */
const TwoFAModal = ({ type, twoFAData, active, callback, setActive, additionalPayload }: Props) => {
  const { t } = useTranslation();
  if (!twoFAData) return null;

  return (
    <Modal
      title={t('two-fa.security-verification')}
      open={active}
      overlayClosable={false}
      onClose={() => setActive(false)}
    >
      <Text mb="3xl">{t('two-fa.complete-verifications-for-security')}</Text>
      <TwoFA type={type} twoFAData={twoFAData} callback={callback} additionalPayload={additionalPayload} />
    </Modal>
  );
};
export default memo(TwoFAModal);

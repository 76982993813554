import React, { ReactNode } from 'react';

import { Tab } from '@bilira-org/design';
import { useLocation } from 'react-router-dom';

type DataType = {
  /** paths value for the menu item */
  defaultPath?: boolean;
  /** path value for the menu item */
  path: string;
  /** Icon to display in the menu item */
  icon: ReactNode;
  /** Text content of the menu item */
  text: string;
  /** Additional class name for styling menu item */
  className?: string;
};

type Props = {
  data: DataType[];
};

/**
 * PagerMenuBar component is a navigation menu that syncs with the location hash.
 *
 * @example
 * const menuData = [
 *   { href: '/home', hash: '#section1', icon: <Icon type="o:home" />, text: 'Home' },
 *   { href: '/dashboard', hash: '#section2', icon: <Icon type="o:plus-circle" />, text: 'Dashboard' },
 * ];
 *
 * <PagerMenuBar data={menuData} defaultHash="#section1" />
 *
 */
const PagerMenuBar = ({ data }: Props) => {
  const { pathname } = useLocation();

  // const routeExists = data.some((d) => d.path === pathname);

  return (
    <Tab
      variant="bordered"
      selectedKey={pathname}
      headers={data.map(({ text, path }) => ({
        name: text,
        link: path,
      }))}
    />
  );
};

export default PagerMenuBar;

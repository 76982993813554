import React from 'react';

import { Block, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

function WarningContent() {
  const { t } = useTranslation();

  return (
    <>
      <Block gap="md">
        <Text heading size="xl" weight="bold" align="center">
          {t('two-fa.warning-modal.title')}
        </Text>

        <ul style={{ paddingLeft: '24px' }}>
          <li>
            <Text size="sm">{t('two-fa.warning-modal.description-1')}</Text>
          </li>
          <li>
            <Text size="sm">{t('two-fa.warning-modal.description-2')}</Text>
          </li>
        </ul>
      </Block>
    </>
  );
}

export default WarningContent;

import { useEffect } from 'react';

import { detectBrowser } from '@bilira-org/react-utils';

/**
 * Custom React hook to handle viewport adjustments specifically for iOS devices.
 *
 * This hook detects if the user's browser is running on an iOS device and, if so, manages the viewport
 * height and offset properties in response to visual viewport resizing (e.g., when the virtual keyboard opens).
 *
 * @example
 * // Usage in a functional component:
 * function Layout() {
 *   useIosViewPort();
 *   return <div>My iOS-friendly component</div>;
 * }
 *
 */
function useIosViewPort() {
  useEffect(() => {
    const browser = detectBrowser();
    if (!browser.isIOS) {
      return;
    }
    const eventController = new AbortController();

    window?.visualViewport?.addEventListener(
      'resize',
      () => {
        const viewportHeight = window?.visualViewport?.height || 0;
        const viewportOffset = window.innerHeight - viewportHeight;
        document.documentElement.style.setProperty('--viewport-height', `${viewportHeight}px`);
        document.documentElement.style.setProperty('--viewport-offset', `${viewportOffset}px`);
        if (viewportOffset > 0) {
          document.documentElement.classList.add('virtual-keyboard-open');
        } else {
          document.documentElement.classList.remove('virtual-keyboard-open');
        }
      },
      {
        signal: eventController.signal,
      },
    );

    return () => {
      document.documentElement.classList.remove('virtual-keyboard-open');

      eventController.abort();
    };
  }, []);
}

export default useIosViewPort;

import React from 'react';

import { Dropdown } from '@bilira-org/design';
import { OTCStatusType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

type Props = {
  callback: (state?: OTCStatusType) => void;
};
const OTCFilter = ({ callback }: Props) => {
  const { t } = useTranslation();
  const [filter, setFilter] = React.useState<string>(t('common.all'));

  const selectFilter = (status?: OTCStatusType) => {
    return () => {
      setFilter(status ? t(`otc.status.${status}`) : t('common.all'));
      callback(status);
    };
  };

  return (
    <Dropdown size="md" value={filter ? filter : t('common.all')} position="right">
      <Dropdown.Item onClick={selectFilter(undefined)}>{t('common.all')}</Dropdown.Item>
      <Dropdown.Item onClick={selectFilter('pending')}>{t('otc.status.pending')}</Dropdown.Item>
      <Dropdown.Item onClick={selectFilter('accepted')}>{t('otc.status.accepted')}</Dropdown.Item>
      <Dropdown.Item onClick={selectFilter('completed')}>{t('otc.status.completed')}</Dropdown.Item>
      <Dropdown.Item onClick={selectFilter('canceled')}>{t('otc.status.canceled')}</Dropdown.Item>
      <Dropdown.Item onClick={selectFilter('rejected')}>{t('otc.status.rejected')}</Dropdown.Item>
      <Dropdown.Item onClick={selectFilter('expired')}>{t('otc.status.expired')}</Dropdown.Item>
      <Dropdown.Item onClick={selectFilter('failed')}>{t('otc.status.failed')}</Dropdown.Item>
    </Dropdown>
  );
};
export default OTCFilter;

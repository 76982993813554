import { create } from 'zustand';

interface StoreProps {
  authParams: Record<'utm' | 'general', Record<string, string>>;
  setUtmParams: (value: Record<string, string>) => void;
  setGeneralParams: (value: Record<string, string>) => void;
}

export const useAuthParamsStore = create<StoreProps>((set) => {
  return {
    authParams: { general: {}, utm: {} },
    setGeneralParams: (value) => {
      set(({ authParams }) => ({ authParams: { ...authParams, general: value } }));
    },
    setUtmParams: (value) => {
      set(({ authParams }) => ({ authParams: { ...authParams, utm: value } }));
    },
  };
});

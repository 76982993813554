import React from 'react';

import { motion, MotionProps, Variants } from 'framer-motion';

import MotionHeight from './motionHeight';
import { BaseComponentType } from '../types';

const buttonDuration = 0.5;
const transactionDuration = buttonDuration * 0.7;

const motionSectionVariants: Record<string, Variants> = {
  opacity: {
    show: {
      opacity: 1,
      transition: { duration: transactionDuration, delay: 0.1 },
    },
    hide: {
      opacity: 0,
      transition: { duration: transactionDuration, delay: 0.1 },
    },
  },
};

export type MotionSectionProps = Omit<MotionProps, 'variant'> &
  BaseComponentType & {
    /** Whether to show the children */
    show?: boolean;
    /** Children to render */
    children: React.ReactNode | React.ReactElement[];
    /** Animation variant to use */
    variant?: 'opacity' | 'height';
    /** Whether to show the children initially */
    showInitial?: boolean;
    /** Class name to apply to the component */
    className?: string;
    /** HTML tag to render */
    as?: 'section' | 'li';
  };

/**
 * Motion component for animating section
 *
 * @example
 * <MotionSection variant="opacity" show={show}>
 *   <h1>Hello</h1>
 * </MotionSection>
 *
 * <MotionSection variant="height" show={show}>
 *   <h1>Hello</h1>
 * </MotionSection>
 */
const MotionSection = ({
  children,
  show,
  testId,
  variant = 'opacity',
  showInitial,
  as = 'section',
  ...rest
}: MotionSectionProps) => {
  if (variant === 'height') {
    return (
      <MotionHeight initial={showInitial} show={show} data-testid={testId ?? 'motion-section'} as={as} {...rest}>
        {children}
      </MotionHeight>
    );
  }
  const Component = motion[as];

  return (
    <Component
      initial={showInitial || 'hide'}
      animate={show ? 'show' : 'hide'}
      variants={motionSectionVariants[variant]}
      data-testid={testId ?? 'motion-section'}
      {...rest}
    >
      {children}
    </Component>
  );
};

MotionSection.displayName = 'MotionSection';

export default MotionSection;

import { useCallback } from 'react';

import { useGeneralStore } from '@/store/generalStore';

/**
 * Hook for start/continue tour after component is mounted.
 *
 * @returns {{
 *   start: (key: string | string[]) => void;
 * }} - An object containing the `start` function to start/continue tour.
 */
const useTourControl = () => {
  const {
    tour: { activeTour, completed },
    setTour,
  } = useGeneralStore();

  /**
   * Start/continue a tour by providing its key(s).
   *
   * @param {string | string[]} key - The key or an array of keys of the tour(s) to start/continue.
   */
  const start = useCallback(
    (key: string | string[]) => {
      const keys = Array.isArray(key) ? key : [key];
      if (completed !== undefined && activeTour && keys.includes(activeTour)) {
        setTour({
          run: true,
        });
      }
    },
    [activeTour, completed, setTour],
  );

  return { start };
};

export default useTourControl;

import React, { memo, ReactNode, useEffect } from 'react';

import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { clsx } from 'clsx';

import ModalBody from './body';
import ModalFooter from './footer';
import ModalHead from './head';
import PopupCloseButton from './popupCloseButton';
import ModalTitle from './title';
import { Display } from '../display';
import { BaseComponentType, BreakpointType, ModalSizes, PaddingSizes } from '../types';

export interface IModal extends BaseComponentType {
  /**
   * The content to be displayed within the modal body.
   * Only use when modal is not compound.
   */
  children?: ReactNode | React.ReactElement;
  /**
   * The content to be displayed within the modal footer.
   * Only use when modal is not compound.
   */
  footer?: ReactNode | React.ReactElement;
  /** Callback function to be called when the modal is closed. */
  onClose?: (val: boolean) => void;
  /** Callback function to be called when the previous button is clicked. */
  onPreviousClick?: (initial?: boolean) => void;
  /** Whether the modal has a previous button. */
  hasPreviousButton?: boolean;
  /** Whether the modal is open. */
  open?: boolean;
  /** The title of the modal. */
  title?: ReactNode | string | null;
  /** Whether the modal has a title separator */
  titleSeparator?: boolean;
  /** The padding of the modal title. */
  titlePadding?: PaddingSizes | BreakpointType<PaddingSizes>;
  /** Whether the modal is closable. */
  closable?: boolean;
  /** Whether the modal has an overlay. */
  overlay?: boolean;
  /** Whether the modal is closable by clicking overlay. */
  overlayClosable?: boolean;
  /** Whether the modal overlay should have visible color. */
  showOverlay?: boolean;
  /** The padding of the modal body. */
  padding?: PaddingSizes | BreakpointType<PaddingSizes>;
  /** The padding of the modal footer. */
  footerPadding?: PaddingSizes;
  /** The size of the modal. */
  size?: ModalSizes;
  /** Whether the modal can be closed with keyboard shortcuts. */
  enableShortcuts?: boolean;
  /** Custom close button for the modal */
  closeButton?: ReactNode;
  /** Whether the modal is responsive. */
  responsive?: boolean;
  /** Whether the modal has a limit max height. */
  limitMaxHeight?: boolean;
  /** Whether the modal is compound. */
  compound?: boolean;
  /** Background color of the modal. */
  color?: 'theme-wn' | 'theme-wb' | 'white';
  /** CSS style properties of the modal */
  style?: React.CSSProperties;
  /** Variant of the modal */
  variant?: 'default' | 'popup';
}

/**
 * The `Modal` component is used to render a customizable modal dialog.
 *
 * @example
 * <Modal
 *   open={open} title="Modal Title" footer={<div>Footer</div>}
 *   closable
 *   overlayClosable
 *   onClose={(val) => setOpen(val)}
 *   color="theme-wb"
 * >
 *  Modal body
 * </Modal>
 *
 */
const Modal = memo(
  ({
    onClose,
    children,
    onPreviousClick,
    hasPreviousButton,
    open,
    title,
    closable = true,
    padding = { xs: 'lg', sm: 'lg', md: '2xl' },
    footerPadding = '2xl',
    footer,
    size = 'lg',
    enableShortcuts = false,
    titleSeparator = true,
    titlePadding = { xs: 'lg', sm: 'lg', md: '2xl' },
    closeButton,
    overlayClosable = true,
    showOverlay = true,
    responsive = true,
    limitMaxHeight = false,
    compound,
    color = 'theme-wn',
    testId,
    style,
    variant = 'default',
  }: IModal) => {
    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (!enableShortcuts) {
          return;
        }

        if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
          onClose?.(true);
        }
      };

      if (enableShortcuts) {
        document.addEventListener('keydown', handleKeyDown);
      }
      return () => {
        if (enableShortcuts) {
          document.removeEventListener('keydown', handleKeyDown);
        }
      };
    }, [enableShortcuts]);

    function close() {
      onClose?.(false);
      onPreviousClick?.(true);
    }

    const classes = clsx('modal-container tw-drop-shadow-xl z-20', `tw-bg-${color}`, {
      [`modal-size-${size}`]: size,
      'modal-responsive': responsive,
      'limit-max-height': limitMaxHeight,
    });

    return (
      <>
        <Dialog
          as="div"
          transition
          open={open}
          className="dialog fixed inset-0 flex w-screen transition duration-100 ease-in  data-[closed]:opacity-0"
          onClose={() => overlayClosable && close()}
          data-testid={testId}
        >
          <DialogBackdrop transition className={`dialog-overlay ${showOverlay ? 'opacity-50' : 'opacity-0'}`} />
          <div className="fixed inset-0 z-10 w-screen ">
            <div className="dialog-content">
              <DialogPanel transition className={classes} style={style}>
                <Display show={variant === 'default'}>
                  <ModalHead
                    closable={closable}
                    closeButton={closeButton}
                    closeModal={close}
                    hasPreviousButton={hasPreviousButton}
                    onPreviousClick={onPreviousClick}
                    title={title}
                    titlePadding={titlePadding}
                    titleSeparator={titleSeparator}
                  />
                </Display>

                {compound ? children : <ModalBody padding={padding}>{children}</ModalBody>}
                {footer && <ModalFooter padding={footerPadding}>{footer}</ModalFooter>}
              </DialogPanel>
              <Display show={variant === 'popup' && closable && open}>
                <PopupCloseButton closeModal={close} color={color} responsive={responsive} />
              </Display>
            </div>
          </div>
        </Dialog>
      </>
    );
  },
);

Modal.displayName = 'Modal';

export default Object.assign(Modal, { Body: ModalBody, Footer: ModalFooter, Title: ModalTitle });

import { UserType } from '@bilira-org/react-utils';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface SessionStoreType {
  isNotificationViewed: boolean;
  setIsNotificationViewed: (value: boolean) => void;

  isNewFeatureViewed?: Record<string, boolean>;
  setIsNewFeatureViewed: (value: Record<string, boolean>) => void;

  isLoggedIn: boolean;

  user: UserType | null;
  setUser: (value: UserType | null) => void;
}

export const useSessionStore = create<SessionStoreType>()(
  persist(
    (set) => ({
      isNotificationViewed: false,
      setIsNotificationViewed: (value: boolean) => set({ isNotificationViewed: value }),

      isLoggedIn: false,
      user: null,
      setUser: (value: UserType | null) => set(() => ({ user: value, isLoggedIn: !!value })),

      isNewFeatureViewed: {},
      setIsNewFeatureViewed: (value: Record<string, boolean>) =>
        set((state) => ({ isNewFeatureViewed: { ...state.isNewFeatureViewed, ...value } })),
    }),
    {
      name: 'general-session-store', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

import { UserInfo } from '@bilira-org/react-utils';

import Tracker from '@Libs/tracker/Tracker';

/*
This helper filters specific fields in user kyc info for the tracker.
 */
export const addTrackerUser = ({ userInfo }: { userInfo: UserInfo }) => {
  try {
    Tracker.user({
      id: userInfo.id,
    });
  } catch (e) {
    console.error('addTrackerUser', e);
  }
};

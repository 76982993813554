import React from 'react';

import { Block, Icon, Skeleton, Text, Tooltip } from '@bilira-org/design';
import { CoinSymbolType, ForceDecimal, formatPriceValue } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

type Props = {
  /** Whether component is loading */
  loading: boolean;
  /** Commission value to display */
  commission?: string;
  /** Commission rate to display */
  rate?: string;
};

/**
 * Component to display the commission for swap.
 *
 * @example
 * <CommissionItem commission={'0.5'} loading={isLoading} />
 */
function CommissionItem({ loading, commission, rate }: Props) {
  const { t, i18n } = useTranslation();

  const hasCommission = commission && commission !== '0';

  const rateText = formatPriceValue({
    value: rate,
    locale: i18n.language,
    forceDecimal: ForceDecimal.SHOW_INSIGNIFICANT_DIGITS,
    decimal: 2,
    precision: undefined,
  });

  return (
    <Block row justify="between" items="center">
      <Text size="sm" weight="regular" color="neutral-700">
        {t('market.swap.commission')}
      </Text>
      {loading ? (
        <Skeleton width="size-16" height="size-4" />
      ) : (
        <Block row gap="xs" items="center">
          <Text weight="regular" size="sm" color={hasCommission ? 'neutral-900' : 'green-500'}>
            {hasCommission && (
              <Text as="span" weight="regular" size="sm" color="neutral-600">
                {`(${t('common.percentage', { value: rateText })}) `}
              </Text>
            )}
            {hasCommission ? `${commission}` : t('common.free')}
          </Text>
        </Block>
      )}
    </Block>
  );
}

export default CommissionItem;

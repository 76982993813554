import React from 'react';

import MarketPriceSubscription from '@Components/live/subscription/GeneralPriceSubscription';
import { withPrivateRoute } from '@Libs/hocs';
import MarketTable from '@Modules/market/market/table/components/MarketTable';
import useWatchlistHook from '@Modules/market/market/watchlist/hooks/useWatchlistHook';

const MarketWatchlistTableContent = () => {
  const { assetList, isPending, isFetchingNextPage, subscriptionList } = useWatchlistHook();

  return (
    <>
      <MarketPriceSubscription
        channel="market-table-watchlist"
        baseQuoteList={subscriptionList}
        events="last_price,symbol_statistics"
      />
      <MarketTable
        isFetchingNextPage={isFetchingNextPage}
        assetList={assetList}
        isPending={isPending}
        disableSorting={true}
      />
    </>
  );
};

export default withPrivateRoute(MarketWatchlistTableContent);

import React, { FC } from 'react';

import { useCopy } from '../../hooks';
import { Icon } from '../icons';
import { Text, TextProps } from '../typography';

export type TextCopyProps = {
  /** A callback function to execute after copying. */
  callback?: () => void;
  /** Text content to be displayed.
   *
   * Also text to be copied if `value` not provided
   */
  children?: string;
  /** The text to be displayed in the copied notification. */
  copiedText: string;
  /** Value to be copied  */
  value?: string;
  /** Whether to show copy icon */
  showCopyIcon?: boolean;
};

type Props = TextCopyProps & Omit<TextProps, keyof TextCopyProps | 'onClick'>;

/**
 * Component that displays text with copy functionality.
 *
 * @example
 * <TextCopy copiedText="Text copied" showCopyIcon>Copy me</TextCopy>
 */
const TextCopy: FC<Props> = ({ children = '', callback, copiedText, value, showCopyIcon = false, ...rest }: Props) => {
  const { onCopy } = useCopy({ callback, copiedText, copyValue: value ?? children });

  return (
    <Text onClick={() => onCopy()} {...rest}>
      {children}
      {showCopyIcon && <Icon type="o:square-2-stack" size="sm" ml="xs" />}
    </Text>
  );
};

TextCopy.displayName = 'TextCopy';

export default TextCopy;

import { ReactNode } from 'react';

import { Block, Separator, Text } from '@bilira-org/design';

type DetailProps = { title: string; children: ReactNode };

const RewardTaskDetailField = ({ title, children }: DetailProps) => {
  return (
    <Block gap="md">
      <Text weight="semibold" color="neutral-900">
        {title}
      </Text>
      <Text size="sm" color="neutral-900">
        {children}
      </Text>
      <Separator width="size-full" color="neutral-400" mt="xs" />
    </Block>
  );
};

export default RewardTaskDetailField;

/**
 * Converts a string representation of a boolean to its corresponding boolean value.
 *
 * @param {string} value - The string representation of a boolean ('true' or 'false').
 * @returns {boolean} - The boolean value corresponding to the input string.
 * @example
 * castBoolean('true') // true;
 *
 * castBoolean('false'); // false
 */
export const castBoolean = (value: string): boolean => value === 'true';

import React from 'react';

import { Panel, Text } from '@bilira-org/design';

export type Props = {
  /** Title of the transaction card header */
  title: string;
  /** Description of the transaction card header */
  description?: string;
};

/**
 * Header of the transaction card
 */
const TransactionCardHeader = ({ title, description }: Props) => {
  return (
    <>
      <Panel size="md">
        <Text heading size="xl" weight="bold" mb={description ? 'md' : 'none'}>
          {title}
        </Text>
        {description && <Text>{description}</Text>}
      </Panel>
    </>
  );
};

export default TransactionCardHeader;

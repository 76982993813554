import React, { memo } from 'react';

import { Block, Display, Meta, Number, Separator, Skeleton, Text } from '@bilira-org/design';
import { AssetPairPriceType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import FormattedNumberByPrice from '@/components/common/FormattedNumberByPrice';
import IconCoin from '@Components/icon/IconCoin';
import CommissionItem from '@Components/trade/buySell/components/ComissionItem';
import UnitPriceItem from '@Components/trade/buySell/components/UnitPriceItem';
import useSwapStateHook from '@Components/trade/buySell/hooks/useSwapStateHook';

/**
 * Component to render a preview of the swap details.
 */
function OfferPreviewItem() {
  const { t } = useTranslation();
  const {
    offerAmount,
    fromAsset,
    isOfferFetching,
    toAsset,
    offerPrice,
    commission,
    baseAsset,
    quoteAsset,
    fromAmount,
    buySellType,
    convertedOfferPrice,
  } = useSwapStateHook();

  return (
    <div>
      <Block items="center" mt="4xl">
        <Text size="sm" weight="regular" color="neutral-700">
          {t('trade.offer.labels.receive')}
        </Text>

        <Display show={isOfferFetching}>
          <Skeleton height="size-11" width="size-40" />
        </Display>
        <Display show={!isOfferFetching}>
          <Number
            size="3xl"
            color="neutral-900"
            weight="medium"
            suffix={toAsset}
            suffixWeight="regular"
            suffixSize="sm"
            value={offerAmount}
            formatPriceProps={{ decimal: 2, locale: 'tr' }}
          />
        </Display>
      </Block>

      <Block gap="md" mt="2xl">
        <Block row justify="between">
          <Text size="sm" weight="regular" color="neutral-700">
            {t('trade.offer.labels.pairs')}
          </Text>
          <Meta
            space="sm"
            extra={<IconCoin size="2xl" type={baseAsset} />}
            title={
              <Text size="sm" color="neutral-900" weight="medium">
                {`${baseAsset}/${quoteAsset}`}
              </Text>
            }
          />
        </Block>

        <UnitPriceItem
          loading={isOfferFetching}
          equalitySymbol="="
          assetPairPrice={
            {
              price: offerPrice,
              quote_asset: fromAsset,
              base_asset: toAsset,
            } as AssetPairPriceType
          }
        />
        <CommissionItem commission={commission.value} rate={commission.rate} loading={isOfferFetching} />

        <Separator width="size-full" />
        <Block row justify="between">
          <Text size="sm" weight="regular" color="neutral-700">
            {t('trade.offer.labels.total')}
          </Text>
          <Display show={!isOfferFetching}>
            <FormattedNumberByPrice
              price={convertedOfferPrice}
              type={buySellType === 'buy' ? 'price' : 'amount'}
              size="sm"
              weight="medium"
              color="neutral-900"
              value={fromAmount}
              suffix={fromAsset}
            />
          </Display>
          <Display show={isOfferFetching}>
            <Skeleton height="size-5" width="size-20" />
          </Display>
        </Block>
      </Block>
    </div>
  );
}

export default memo(OfferPreviewItem);

import React from 'react';

import { Block, Icon, Meta, Skeleton, StepProgress, Text, Tooltip } from '@bilira-org/design';
import { TransactionsRecordType } from '@bilira-org/react-utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import FormattedNumber from '@/components/common/FormattedNumber';
import FormattedNumberByPrice from '@/components/common/FormattedNumberByPrice';
import TransactionTitle from '@Components/transaction/components/TransactionTitle';
import AccountType from '@Components/transaction/modal/components/AccountType';
import { getStateConfig, TRANSACTION_STATUS } from '@Libs/helpers/getStateConfig';

import TransactionID from '../TransactionID';

type Props = {
  data?: TransactionsRecordType;
};

function Index({ data }: Props) {
  const { t, i18n } = useTranslation();

  if (!data) {
    return (
      <Block gap="md">
        <Skeleton height="size-4" width="size-full" />
        <Skeleton height="size-4" width="size-1/2" />
        <Skeleton height="size-4" width="size-full" />
        <Skeleton height="size-4" width="size-full" />
        <Skeleton height="size-4" width="size-1/2" />
        <Skeleton height="size-4" width="size-full" />
        <Skeleton height="size-4" width="size-full" />
        <Skeleton height="size-4" width="size-1/2" />
        <Skeleton height="size-4" width="size-full" />
      </Block>
    );
  }

  return (
    <StepProgress
      overflow="visible"
      color="neutral-300"
      vertical
      size="lg"
      steps={[
        {
          title: (
            <Meta
              title={<Text size="sm">{t('transactions.transaction-number')}</Text>}
              description={<TransactionID data={data} />}
            />
          ),
          icon: <Icon color="secondary-500" size="lg" type="o:hashtag" />,
        },
        {
          title: (
            <Meta
              title={<Text size="sm">{t('transactions.transaction-type')}</Text>}
              description={<TransactionTitle data={data} />}
            />
          ),
          icon: (
            <Icon
              color="secondary-500"
              size="lg"
              type={getStateConfig(data.status).icon(`${data.type}:${data.direction}`)}
            />
          ),
        },
        {
          title: (
            <Meta
              title={<Text size="sm">{t('transactions.transaction-date')}</Text>}
              description={
                <Text weight="medium" color="secondary-500">
                  {dayjs(data.created_at, { locale: i18n.language }).format('DD MMM YYYY, HH:mm')}
                </Text>
              }
            />
          ),
          icon: <Icon color="secondary-500" size="lg" type="o:clock" />,
        },
        {
          title: <AccountType data={data} />,
          icon: <Icon color="secondary-500" size="lg" type="o:wallet" />,
        },
        {
          title: (
            <Block row items="end" justify="between" wrap>
              <Meta
                title={<Text size="sm">{t(`transactions.${data.direction}-amount`)}</Text>}
                description={
                  <FormattedNumberByPrice
                    size={{ xs: 'sm', sm: 'base' }}
                    suffixSize={{ xs: 'sm', sm: 'base' }}
                    suffix={data.asset ?? 'TRYB'}
                    weight="medium"
                    suffixWeight="medium"
                    color="primary-500"
                    value={data.direction === 'withdrawal' ? data.amount_to_send : data.amount}
                    type="amount"
                    base={data.asset ?? 'TRYB'}
                  />
                }
              />
              {data?.direction === 'withdrawal' && (
                <Meta
                  position="right"
                  space="xs"
                  extra={
                    <Tooltip message={t('transactions.withdrawal-fee')}>
                      <Icon color="neutral-600" type="o:question-mark-circle" size="sm" />
                    </Tooltip>
                  }
                  title={
                    <FormattedNumberByPrice
                      size="sm"
                      suffix={data.asset ?? 'TRYB'}
                      weight="regular"
                      color="neutral-700"
                      value={data.withdrawal_fee}
                      type="amount"
                      base={data.asset ?? 'TRYB'}
                    />
                  }
                />
              )}
            </Block>
          ),
          icon: <Icon color="secondary-500" size="lg" type="o:banknotes" />,
        },
        {
          title: (
            <Meta
              title={<Text size="sm">{t('transactions.transaction-status')}</Text>}
              description={
                <Text weight="medium" color={TRANSACTION_STATUS[data?.status].text}>
                  {t(`transactions.statuses.${data?.status}`)}
                </Text>
              }
            />
          ),
          icon: <Icon color="secondary-500" size="lg" type="o:document-check" />,
        },
      ]}
    />
  );
}

export default Index;

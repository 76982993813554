import React, { useEffect } from 'react';

const hsConversationsSettings: Window['hsConversationsSettings'] = {
  loadImmediately: false,
  inlineEmbedSelector: '#hubspot-parent',
};

const Support = () => {
  useEffect(() => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.remove();
      window.hsConversationsSettings = hsConversationsSettings;
      window.HubSpotConversations.widget.load();
    } else {
      window.hsConversationsSettings = hsConversationsSettings;
      window.hsConversationsOnReady = [
        () => {
          window.HubSpotConversations?.widget.load();
        },
      ];
    }
  }, []);

  return <div id="hubspot-parent"></div>;
};

export default Support;

import React, { forwardRef, ReactElement, ReactNode, useEffect, useState } from 'react';

import { clsx } from 'clsx';

import { BaseComponentType } from '../types';
import { wrapWithElementIfInvalid } from '../utils';

export type GroupProps = BaseComponentType & {
  /** Currently selected item identifier */
  selected?: string;
  /** The buttons or items within the group. */
  children: ReactElement[];
  /** Callback when an item is selected */
  onSelected?: (selectedItem: string) => void;
};

/**
 * Group component for managing a group of buttons or items with optional selection state.
 *
 * @note
 * Custom children should have onSelected and selectedItem prop
 *
 * @example
 * <Group selected="item2" onSelected={(selectedItem) => console.log('Selected:', selectedItem)} testId="button-group">
 *   <Group.Button identifier="item1">Item 1</Group.Button>
 *   <Group.Button identifier="item2">Item 2</Group.Button>
 *   <Group.Button identifier="item3">Item 3</Group.Button>
 * </Group>
 */
export const Group = forwardRef<HTMLDivElement, GroupProps>(({ selected, onSelected, children, testId }, ref) => {
  const firstKey = children[0]?.key as string;

  const [selectedValue, setSelectedValue] = useState<string | undefined>(selected ?? firstKey);

  useEffect(() => setSelectedValue(selected), [selected]);

  const onSelectedInner = (selectedItem: string) => {
    onSelected?.(selectedItem);
    setSelectedValue(selectedItem);
  };

  return (
    <div className="item-group" ref={ref} data-testid={testId ?? 'group'}>
      {children?.map((node, index) =>
        wrapWithElementIfInvalid({
          node: node,
          wrapper: <></>,
          props: { identifier: node.key, onSelected: onSelectedInner, selectedItem: selectedValue, page: index },
        }),
      )}
    </div>
  );
});

export type PanelProps = {
  /** Content of the button */
  children: ReactNode | React.ReactElement;
  /** Currently selected item's identifier */
  selectedItem?: string;
  /** Identifier of the button */
  identifier?: string;
  /** Callback when the button is selected */
  onSelected?: (selectedItem?: string) => void;
  /** Type of button */
  type?: 'icon' | 'default';
};

/**
 * Button component for use within the Group component to represent an item.
 *
 * @example
 * <Group selected="item2" onSelected={(selectedItem) => console.log('Selected:', selectedItem)} testId="button-group">
 *   <Group.Button identifier="option1">Item 1</Group.Button>
 *   <Group.Button identifier="iconOption" type="icon">
 *     <Icon color="secondary-500" type="o:moon" size="md" />
 *   </Group.Button>
 * </Group>
 */
function GroupButton({ onSelected, selectedItem, identifier, children, type = 'default' }: PanelProps) {
  return (
    <button
      onClick={() => onSelected?.(identifier)}
      className={clsx('group-button', { selected: selectedItem === identifier }, `group-button-${type}`)}
      color="ghost"
    >
      {children}
    </button>
  );
}

export default Object.assign(Group, { Button: GroupButton });

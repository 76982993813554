import React from 'react';

import { Block, Button, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { IconCustom } from '@Components/icon/CustomIcon';
type Props = {
  onClose: () => void;
};

/**
 * Component to render the fail message for the swap.
 */
function OfferFail({ onClose }: Props) {
  const { t } = useTranslation();

  return (
    <Block justify="between" height="size-full">
      <Block justify="center" items="center" mx="auto" mb="8xl" maxWidth="sm">
        <Block mt="3xl" mb="2xl">
          <IconCustom type="x-mark" />
        </Block>
        <Text heading align="center" size="xl" weight="bold" mb="md">
          {t('trade.offer.failed.title')}
        </Text>
        <Text size="sm" align="center" mb="5xl">
          {t('trade.offer.failed.description')}
        </Text>
      </Block>
      <Button size="lg" variant="outline" onClick={onClose}>
        {t('common.close')}
      </Button>
    </Block>
  );
}

export default OfferFail;

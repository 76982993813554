import React, { memo } from 'react';

import '../style.css';
import 'dayjs/locale/tr';
import { Notification } from '../src/components/notification';

function DesignStablecoin({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <>
      {children}
      <Notification />
    </>
  );
}

export default memo(DesignStablecoin);

import { Icon } from '@bilira-org/design';
import { TextColorType } from '@bilira-org/design/types';
import { RewardsTasksType, UserParticipationType } from '@bilira-org/react-utils';

import IconCoin from '@Components/icon/IconCoin';

import { getTaskIcon } from '../helpers';

interface TaskIconProps {
  task: UserParticipationType | RewardsTasksType;
  color?: TextColorType;
}

function RewardTaskIcon({ task, color = 'neutral-900' }: TaskIconProps) {
  return (
    <>
      {task.asset ? (
        <IconCoin
          size="2xl"
          type={task.asset} // icon should change based on alias
          color="neutral-900"
        />
      ) : (
        <Icon
          size="lg"
          type={getTaskIcon(task)} // icon should change based on task type
          color={color}
        />
      )}
    </>
  );
}

export default RewardTaskIcon;

import { Block, Col, Grid, Skeleton } from '@bilira-org/design';

import UserQuery from '@Libs/clientInstances/userQuery';

import CreateReferralForm from './CreateReferralForm';

interface Props {
  onSubmit: () => void;
  onClose: () => void;
}

function CreateReferral({ onSubmit, onClose }: Props) {
  const { data: referralCodes, isLoading: isLoadingReferralCodes } = UserQuery.useGetReferralCodes();
  const { data: referralCodeTypes, isLoading: isLoadingReferralCodeTypes } = UserQuery.useGetReferralCodeTypes();

  if (isLoadingReferralCodes || isLoadingReferralCodeTypes) {
    return <CreateReferralSkeleton />;
  }

  return (
    <CreateReferralForm
      referralCodes={referralCodes}
      referralCodeTypes={referralCodeTypes}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
}

export default CreateReferral;

const CreateReferralSkeleton = () => {
  return (
    <Grid gap="4xl">
      <Col>
        <Skeleton width="size-40" height="size-5" />
        <Skeleton width="size-80" height="size-10" mt="sm" />
      </Col>
      <Col>
        <Skeleton width="size-40" height="size-5" />
        <Block row justify="between" mt="sm" gap="md">
          <Block>
            <Skeleton width="size-36" height="size-5" />
            <Skeleton width="size-12" height="size-7" mt="sm" />
          </Block>
          <Block>
            <Skeleton width="size-36" height="size-5" />
            <Skeleton width="size-12" height="size-7" mt="sm" />
          </Block>
        </Block>
      </Col>
      <Col>
        <Skeleton width="size-40" height="size-5" />
        <Skeleton width="size-80" height="size-10" mt="sm" />
      </Col>
      <Col>
        <Skeleton rounded="md" width="size-40" height="size-5" />
      </Col>
      <Col>
        <Block row gap="md">
          <Skeleton rounded="xl" width="size-full" height="size-14" />
          <Skeleton rounded="xl" width="size-full" height="size-14" />
        </Block>
      </Col>
    </Grid>
  );
};

import { useDeferredValue, useEffect, useState } from 'react';

type FilterType<T> = {
  /** The predicate function to filter items outside the hook. */
  predicate: (item: T, search: string) => unknown;
  /** The array of items to be filtered. */
  data?: T[];
};

/**
 * Hook for filtering data based on a predicate function and search text.
 * Uses useDeferredValue to postpone updating UI to improve performance.
 *
 * @template T - The type of items in the data array.
 *
 * @param {FilterType<T>} options - Options for the filter hook.
 *
 * @returns {{
 *   searchText: string,
 *   onSearch: (value: string) => void,
 *   filteredData: T[] | undefined,
 *   isDeferring: boolean
 * }} - An object containing the search text, search function, the filtered data, and pending state.
 */
const useDeferredFilter = <T>({ predicate, data }: FilterType<T>) => {
  const [searchText, setSearchText] = useState<string>('');
  const [filteredData, setFilteredData] = useState<T[] | undefined>(data);
  const deferredSearchText = useDeferredValue(searchText);
  const isDeferring = deferredSearchText !== searchText;

  const onSearch = (value: string) => {
    setSearchText(value);
  };

  useEffect(() => {
    if (!data) {
      setFilteredData(data);
      return;
    }

    const result = data.filter((d) => predicate(d, deferredSearchText));
    setFilteredData(result.length === 0 ? undefined : result);
  }, [data, predicate, deferredSearchText]);

  return { searchText, onSearch, filteredData, isDeferring };
};

export default useDeferredFilter;

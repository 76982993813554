import React, { ForwardedRef, forwardRef, HTMLAttributes, Ref } from 'react';

import { clsx } from 'clsx';

import PagerMenuItem, { PagerMenuItemProps as ItemProps } from './pagerMenuItem';
import { BaseComponentType } from '../types';

export type PagerMenuItemProps = ItemProps;

export type PagerMenuProps<T> = HTMLAttributes<HTMLUListElement> &
  BaseComponentType & {
    data?: T[];
    /** The children to be rendered inside the menu. */
    children?: React.ReactNode;
    /** A function to render each item in the data array. */
    renderItem?: (data: T, index: number) => JSX.Element | JSX.Element[];
    /** A ref to attach to the underlying `ul` element. */
    mRef?: Ref<HTMLUListElement>;
  };

/**
 * The `Menu` component is used to create a menu with optional data-driven rendering of menu items.
 *
 * @example
 * const data = [
 *   {
 *     id: 1,
 *     name: 'Item 1',
 *   },
 *   {
 *     id: 2,
 *     name: 'Item 2',
 *   },
 *   {
 *     id: 3,
 *     name: 'Item 3',
 *   },
 * ];
 *
 * <Menu data={data} renderItem={(item) => <Menu.Item key={item.id}>{item.name}</Menu.Item>} />
 */
const PagerMenu = <T,>(
  { data, renderItem, className, children, testId, ...props }: PagerMenuProps<T>,
  ref: ForwardedRef<HTMLUListElement>,
) => {
  const classes = clsx('pager-menu snap-x', className, {});

  if (data && renderItem) {
    return (
      <ul role="menu" className={classes} {...props} ref={ref} data-testid={testId}>
        {data.map(renderItem)}
        {children}
      </ul>
    );
  }

  return (
    <ul role="menu" className={classes} {...props} ref={ref} data-testid={testId}>
      {children}
    </ul>
  );
};

/**
 * The `MenuWithRef` component is a forward-ref wrapper for the `Menu` component.
 *
 * The `Menu` component is used to create a menu with optional data-driven rendering of menu items.
 */
export const PagerMenuWithRef = forwardRef(PagerMenu) as <T>(
  props: PagerMenuProps<T> & { ref?: ForwardedRef<HTMLUListElement> },
) => ReturnType<typeof PagerMenu>;

PagerMenu.displayName = 'PagerMenu';

export default Object.assign(PagerMenuWithRef, { Item: PagerMenuItem });

import React, { useMemo } from 'react';

import {
  Block,
  Button,
  ColumnDef,
  DataTable,
  Link,
  Panel,
  Skeleton,
  TableBodyStyle,
  TableHeaderStyle,
  Text,
} from '@bilira-org/design';
import { AssetListType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import routeMap from '@/libs/route/routeMap';
import SymbolPair from '@Components/common/SymbolPair';
import H24ChangeLive from '@Components/live/H24ChangeLive';
import H24SparklineLive from '@Components/live/H24SparklineLive';
import LastPriceLive from '@Components/live/LastPriceLive';
import MarketPriceSubscription from '@Components/live/subscription/GeneralPriceSubscription';
import useAssetCardsHook from '@Modules/market/market/table/components/hooks/useAssetCardsHook';

const headerStyle: TableHeaderStyle = { showBackground: true, px: 'sm', py: 'sm', weight: 'bold', size: 'xs' };
const bodyStyle: TableBodyStyle = { px: 'md', py: 'md' };

const PopularCryptoCardsGrid = () => {
  const { t } = useTranslation();

  const { assetList, isPending, callback, subscriptionList } = useAssetCardsHook({
    dataTag: 'popular',
  });

  const columns = useMemo<ColumnDef<AssetListType>[]>(
    () => [
      {
        header: '',
        accessorKey: 'base_asset',
        id: 'pair',
        cell({ row: { original: item } }) {
          return (
            <SymbolPair
              quote="TRYB"
              icon={item.base_asset}
              title={item.base_asset}
              description={item.base_asset_name}
            />
          );
        },
        skeleton: (
          <Block key="crypto" row gap="sm" items="center">
            <Skeleton rounded="full" height="size-8" width="size-8" />
            <Skeleton height="size-4" width="size-24" />
          </Block>
        ),
      },
      {
        header: '',
        accessorKey: 'base_asset',
        id: 'last-price',
        align: 'right',
        size: 150,
        cell({ row: { original: item } }) {
          return <LastPriceLive suffixColor="neutral-700" base={item.base_asset} quote="TRYB" />;
        },
        skeleton: <Skeleton height="size-4" width="size-24" />,
      },
      {
        header: '',
        accessorKey: 'base_asset',
        id: 'sparkline',
        align: 'center',
        show: { xs: false, sm: false, md: true },
        cell({ row: { original: item } }) {
          return <H24SparklineLive base={item.base_asset} change24h={item.change_24} quote="TRYB" />;
        },
        skeleton: <Skeleton height="size-4" width="size-24" />,
      },
      {
        header: '',
        accessorKey: 'base_asset',
        id: 'h24change',
        align: 'right',
        show: { xs: false, sm: false, md: true },
        size: 50,
        cell({ row: { original: item } }) {
          return <H24ChangeLive base={item.base_asset} value={item.change_24} justify="end" quote="TRYB" />;
        },
        skeleton: <Skeleton height="size-4" width="size-24" />,
      },
      {
        header: '',
        accessorKey: 'base_asset',
        id: 'action',
        show: false,
        size: 80,
        cell({ row: { original: entry } }) {
          return (
            <Block row justify="end">
              <Button size="md" variant="outline" onClick={() => callback(entry)}>
                {t('overview.buy')}
              </Button>
            </Block>
          );
        },
        skeleton: <Skeleton height="size-4" width="size-24" />,
      },
    ],
    [t],
  );

  return (
    <>
      <MarketPriceSubscription
        channel="popular-cards"
        baseQuoteList={subscriptionList}
        events="last_price,symbol_statistics"
      />
      <DataTable
        rowId="base_asset"
        loading={isPending}
        rounded
        headerStyle={headerStyle}
        bodyStyle={bodyStyle}
        displayHeader={false}
        data={assetList}
        onRowClick={callback}
        columns={columns}
        emptyMessage={t('common.no-entries-found')}
      />
    </>
  );
};

const PopularCryptoCards = () => {
  const { t, i18n } = useTranslation();
  const rankingPath = routeMap.Market.Ranking[i18n.language as 'tr' | 'en'];

  return (
    <>
      <Panel border="neutral-400" size="md" testId="popular-crypto-panel">
        <Block row justify="between" items="center" mb="xs" mt="xs">
          <Text heading size="base" weight="bold" px="md">
            {t('overview.popular')}
          </Text>
          <Link to={`${rankingPath}#popular`}>
            <Button size="sm" variant="link">
              {t('common.show-all')}
            </Button>
          </Link>
        </Block>
        <PopularCryptoCardsGrid />
      </Panel>
    </>
  );
};
export { PopularCryptoCardsGrid };

export default PopularCryptoCards;

import React, { useCallback, useMemo } from 'react';

import {
  Block,
  ColumnDef,
  DataTable,
  DataTableProps,
  TableBodyStyle,
  TableHeaderStyle,
  Text,
} from '@bilira-org/design';
import { ForceDecimal, UserTaskHistoryType } from '@bilira-org/react-utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import FormattedNumber from '@/components/common/FormattedNumber';
import RewardsQuery from '@Libs/clientInstances/rewardsQuery';

import { getTaskTitle } from '../components/helpers';

const headerStyle: TableHeaderStyle = { showBackground: true, px: 'sm', py: 'sm' };
const bodyStyle: TableBodyStyle = { px: 'md', py: 'md' };

const RewardHistoryTable = () => {
  const { t, i18n } = useTranslation();
  const { data, isPending } = RewardsQuery.useGetUserTaskHistory({ group_by: 'daily' });

  const columns = useMemo<ColumnDef<UserTaskHistoryType, string>[]>(
    () => [
      {
        accessorKey: 'date',
        header: t('rewards.history.date'),
        cell: (entry) => {
          const startDate = dayjs(entry.getValue(), { locale: i18n.language }).format('DD MMMM YYYY');
          return (
            <Block row items="center" gap="xs">
              <Text color="neutral-700" size="xs">
                {startDate}
              </Text>
            </Block>
          );
        },
      },
      {
        accessorKey: 'points',
        header: t('rewards.history.points'),
        align: 'right',
        cell: (entry) => {
          return (
            <FormattedNumber
              value={entry.getValue()}
              formatPriceProps={{ decimal: 2, forceDecimal: ForceDecimal.STRICT_DECIMAL, defaultValue: '-' }}
              size="sm"
            />
          );
        },
      },
    ],
    [],
  );

  const renderSubComponent = useCallback(
    (entry: UserTaskHistoryType) => {
      return (
        <Block gap="sm">
          {entry.tasks.map((task) => (
            <Block row justify="between" key={task.id} gap="xs">
              <Text size="xs">{getTaskTitle(task, t)}</Text>
              <FormattedNumber
                value={task.points}
                formatPriceProps={{ decimal: 2, forceDecimal: ForceDecimal.STRICT_DECIMAL, defaultValue: '-' }}
                size="sm"
              />
            </Block>
          ))}
        </Block>
      );
    },
    [t],
  );

  const getRowCanExpand = useCallback<Exclude<DataTableProps<UserTaskHistoryType>['getRowCanExpand'], undefined>>(
    (row) => {
      return row.original?.tasks?.length > 0;
    },
    [],
  );

  return (
    <>
      <DataTable
        divider
        data={data}
        loading={isPending}
        columns={columns}
        headerStyle={headerStyle}
        bodyStyle={bodyStyle}
        rowId="date"
        skeletonRowCount={5}
        renderSubComponent={renderSubComponent}
        getRowCanExpand={getRowCanExpand}
      />
    </>
  );
};

export default RewardHistoryTable;

import React, { ComponentProps, ReactNode } from 'react';

import { Block, Button, Flex, Link, Panel, Text } from '@bilira-org/design';
import { BaseComponentType } from '@bilira-org/design/types';
import { useTranslation } from 'react-i18next';

import CryptoList, { CryptoListProps } from './CryptoList';

type Props = {
  title?: ReactNode;
  showAllLinkProps?: ComponentProps<typeof Link>;
  children?: React.ReactNode;
} & BaseComponentType;

const CryptoListCard = ({ title, showAllLinkProps, testId, children }: Props) => {
  const { t } = useTranslation();

  return (
    <Panel size="md" minHeight="xs" border="neutral-400" testId={testId}>
      <Block row justify="between" items="center" mb="xs" mt="xs">
        <Text heading size="base" weight="bold" px="md">
          {title}
        </Text>
        <Link {...showAllLinkProps}>
          <Button size="sm" variant="link">
            {t('common.show-all')}
          </Button>
        </Link>
      </Block>
      {children}
    </Panel>
  );
};

export default CryptoListCard;

import React, { memo } from 'react';

import { Button } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { withPrivateRoute, withPublicRoute } from '@/libs/hocs';
import RewardsQuery from '@Libs/clientInstances/rewardsQuery';

import RewardTask from './RewardTask';

const PrivatePrerequisites = withPrivateRoute(
  memo(() => {
    const { t } = useTranslation();
    const { data } = RewardsQuery.useGetUserParticipationPrerequisites();
    const { mutateAsync, isPending } = RewardsQuery.usePatchUserParticipation();

    return (
      <>
        {data?.tasks.map((task) => <RewardTask key={task.id} task={task} color="theme-wn" />)}
        {data?.can_participate && !data.participated_at && (
          <Button mt="xs" variant="filled" size="md" stretch onClick={() => mutateAsync()} loading={isPending}>
            {t('rewards.join-point-system')}
          </Button>
        )}
      </>
    );
  }),
);

const PublicPrerequisites = withPublicRoute(
  memo(() => {
    const { data } = RewardsQuery.useGetParticipationPrerequisites();
    return <>{data?.tasks.map((task) => <RewardTask key={task.id} task={task} color="theme-wn" />)}</>;
  }),
);

const Prerequisites = () => {
  return (
    <>
      <PublicPrerequisites />
      <PrivatePrerequisites />
    </>
  );
};
export default memo(Prerequisites);

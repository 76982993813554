import React from 'react';

import { Badge, Block, Button, Icon, Meta, Modal, Panel, Span, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { useGeneralStore } from '@/store/generalStore';
import GlobalSearch from '@Components/globalSearch/GlobalSearch';

/**
 * Button for displaying global search modal
 */
const Search = () => {
  const { t } = useTranslation();
  const searchModalActive = useGeneralStore((state) => state.searchModalActive);
  const setSearchModalActive = useGeneralStore((state) => state.setSearchModalActive);
  const callback = () => {
    setSearchModalActive(!searchModalActive);
  };

  return (
    <>
      <Panel size="none" width="size-44" aria-keyshortcuts="Meta+K Control+K">
        <Button
          testId="search-btn"
          stretch
          size="sm"
          variant="filled-neutral"
          startIcon={<Icon type="o:magnifying-glass" color="neutral-600" />}
          onClick={callback}
        >
          <Block row justify="between" width="size-full">
            <Text color="neutral-600" weight="regular" size="sm">
              {t('common.home-search')}
            </Text>
            <Meta
              extra={<Icon color="neutral-600" size="xs" type="s:command" />}
              space="none"
              title={
                <Text color="neutral-600" weight="semibold" size="xs">
                  K
                </Text>
              }
            />
          </Block>
        </Button>
      </Panel>
      <Modal
        enableShortcuts
        onClose={setSearchModalActive}
        closeButton={
          <Badge size="md" variant="square" color="neutral-300">
            <Span weight="semibold" size="xs">
              ESC
            </Span>
          </Badge>
        }
        open={searchModalActive}
        padding="none"
        testId="search-modal"
      >
        <GlobalSearch callback={callback} />
      </Modal>
    </>
  );
};

export default Search;

export const LEGAL_STATUSES: { [key in LegalStatusesType]: string } = {
  native_tc_citizen: 'native-tc-citizen',
  foreigner_tc_citizen: 'foreigner-tc-citizen',
  primary_card: 'primary-card',
  kktc_citizen: 'kktc-citizen',
  corporate: 'corporate',
  employee: 'employee',
};

export const KYC_STATUSES: { [key in KycStatusesType]: string } = {
  initiated: 'initiated',
  processing: 'processing',
  verified: 'verified',
  rejected: 'rejected',
  failed: 'failed',
};

export const KYC_STATUSES_DEF: { [key in KycStatusesType]: { name: string; color: string } } = {
  initiated: { name: 'initiated', color: 'orange-500' },
  processing: { name: 'processing', color: 'orange-500' },
  verified: { name: 'verified', color: 'green-500' },
  rejected: { name: 'rejected', color: 'red-500' },
  failed: { name: 'failed', color: 'red-500' },
};

import React from 'react';

import { Empty, Icon, List, Meta, Separator, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGeneralStore, useSessionStore } from '@/store';
import useLocaleRoute from '@Libs/hooks/useLocaleRoute';

interface Props {
  /** Callback function to close Help popover */
  closeCallback: () => void;
}

/**
 * Component for displaying content of the help navigation
 */
const HelpContent = ({ closeCallback }: Props) => {
  const { t } = useTranslation();
  const routerNavigate = useNavigate();

  const isLoggedIn = useSessionStore((state) => state.isLoggedIn);
  const setLatelyModalActive = useGeneralStore((state) => state.setLatelyModalActive);
  const { getRoute } = useLocaleRoute();

  const navigate =
    (url: string, target = '_blank') =>
    () => {
      closeCallback();
      if (target === '_self') {
        routerNavigate(url);
      } else {
        window.open(url, target);
      }
    };

  const activateModal = (callback: (value: boolean) => void) => () => {
    closeCallback();
    callback(true);
  };

  const helpMenuItems = [
    {
      name: t('navigation.help.help-center'),
      callback: navigate(t('urls.support-center')),
      icon: <Icon size="lg" color="neutral-700" type="o:support" />,
      display: true,
    },
    {
      name: t('navigation.help.whats-new'),
      callback: activateModal(setLatelyModalActive),
      icon: <Icon size="lg" color="neutral-700" type="o:gift" />,
      display: true,
    },
    {
      name: t('commission.transaction-fees'),
      callback: navigate(getRoute('CommissionFee'), '_self'),
      icon: <Icon size="lg" color="neutral-700" type="o:receipt-percent" />,
      display: !isLoggedIn,
    },
    {
      name: t('navigation.help.ecosystem'),
      callback: navigate(t('urls.ecosystem')),
      icon: <Icon size="lg" color="neutral-700" type="o:globe-americas" />,
      display: true,
    },
    {
      separator: true,
      name: 'X / Twitter',
      callback: navigate('https://x.com/BiLira_Kripto'),
      icon: <Icon size="lg" color="neutral-700" type="s:twitter" />,
      display: true,
    },
    {
      name: 'Telegram',
      callback: navigate('https://t.me/BiLira_Official'),
      icon: <Icon size="lg" color="neutral-700" type="o:telegram" />,
      display: true,
    },
  ];

  const filteredHelpMenuItems = helpMenuItems.filter((helpMenuItem) => helpMenuItem.display);

  return (
    <>
      <List
        empty={<Empty message={t('common.empty-message')} />}
        dataSource={filteredHelpMenuItems}
        renderItem={(record) => (
          <React.Fragment key={`help-${record.name}`}>
            {record.separator && <Separator width="size-full" color="neutral-400" my="xs" />}
            <List.Item px="md" py="md" hoverBgColor="neutral-300" onClick={record.callback}>
              <Meta
                key={record.name}
                space="md"
                extra={record.icon}
                title={
                  <Text size="sm" color="secondary-500">
                    {record.name}
                  </Text>
                }
              />
            </List.Item>
          </React.Fragment>
        )}
        testId="help-dropdown"
      />
    </>
  );
};

export default HelpContent;

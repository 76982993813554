import React from 'react';

import { TooltipRenderProps } from 'react-joyride';

import { Button } from '../button';
import Flex from '../flex/flex';
import { Icon } from '../icons';
import { BaseComponentType } from '../types';
import { Text } from '../typography';

type Props = TooltipRenderProps &
  BaseComponentType & {
    /** The text to display on the "Finish" button in the tour. */
    finishText?: string;
    /** The text to display on the "Next" button in the tour. */
    nextText?: string;
    /** The text to display on the "Back" button in the tour. */
    backText?: string;
  };

/**
 * Tooltip to display on `Tour` steps
 */
const Tooltip = ({
  step,
  tooltipProps,
  isLastStep,
  index,
  continuous,
  primaryProps,
  closeProps,
  backProps,
  finishText = 'Turu bitir',
  nextText = 'İleri',
  backText = 'Geri',
  testId,
}: Props) => {
  return (
    <div {...tooltipProps} className="tour-wrapper" data-testid={testId ?? 'tour-tooltip'}>
      <Flex p="3xl" className="tour-tooltip" maxWidth="8xl">
        <Flex direction="row" justify={step.title ? 'between' : 'end'} mb="xs">
          {step.title && (
            <Text color="secondary-500" weight="bold" size="lg">
              {step.title}
            </Text>
          )}
          <Icon color="secondary-500" type="o:x-mark" size="md" onClick={(e) => closeProps.onClick(e as any)} />
        </Flex>
        <Text mb="4xl">{step.content}</Text>
        <Flex direction="row" justify={!isLastStep ? 'between' : 'end'}>
          {!isLastStep && (
            <Button {...closeProps} variant="outline" size="md">
              {finishText}
            </Button>
          )}
          <Flex direction="row" justify="between" gap="sm">
            {index > 0 && (
              <Button {...backProps} variant="outline" size="md">
                {backText}
              </Button>
            )}
            <Button {...primaryProps} variant="filled" size="md">
              {!continuous || isLastStep ? finishText : nextText}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

Tooltip.displayName = 'Tooltip';

export default Tooltip;

import React, { FC, ReactNode } from 'react';

import { Badge, Block } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { usePersistedStore } from '@Store/persistedStore';

type Props = {
  /** Name/key of the feature */
  badgeKey: string;
  children?: ReactNode;
};

/**
 * Badge component Wrapper for indicating new features. Persisting state with session storage
 * @param {Props} Props
 * @param {badgeKey} Props.badgeKey  - Name/key of the feature
 * @param {children} Props.children  - Children to be wrapped with Badge component.
 * @returns {React.ReactNode} Wrapped children with Badge component.
 *
 * @example
 * <NewBadge badgeKey="referral">
 *   <>Some children.</>
 * </NewBadge>
 */
const NewBadge: FC<Props> = ({ badgeKey, children }: Props): ReactNode => {
  const { t } = useTranslation();
  const { isNewFeatureViewed, setIsNewFeatureViewed } = usePersistedStore();

  if (isNewFeatureViewed?.[badgeKey]) {
    return <>{children}</>;
  }

  return (
    <Block row items="center" gap="xs" onClick={() => setIsNewFeatureViewed({ [badgeKey]: true })}>
      {children}

      <Badge color="primary" size="xs" variant="square" hasArrow arrowColor="primary-500">
        {t('common.new')}
      </Badge>
    </Block>
  );
};

export default NewBadge;

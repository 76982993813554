import React from 'react';

import { Dropdown, Group, Hidden } from '@bilira-org/design';
import { PriceAssetTagType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

type Props = {
  callback: (tag?: PriceAssetTagType) => void;
  items?: PriceAssetTagType[];
  selectedItem?: string;
};
const GeneralTags = ({ items = [] as PriceAssetTagType[], selectedItem, callback }: Props) => {
  const { t } = useTranslation();

  const callbackGeneralTag = (tag?: PriceAssetTagType | string) => {
    if (typeof tag === 'string') {
      const item = items.find((d) => d.tag === tag);
      callback(item);
      return;
    }

    callback(tag);
  };

  const getGeneralTagsButtonGroup = () => {
    const result = items.map((tag) => <Group.Button key={tag.tag}>{t(`market.tags.${tag.tag}`)}</Group.Button>);
    result.unshift(<Group.Button key="">{t('market.tags.all')}</Group.Button>);
    return result;
  };
  const getGeneralTagsDropdown = () => {
    const result = items.map((tag) => (
      <Dropdown.Item onClick={() => callbackGeneralTag(tag)} key={tag.tag}>
        {t(`market.tags.${tag.tag}`)}
      </Dropdown.Item>
    ));
    result.unshift(
      <Dropdown.Item key="" onClick={() => callbackGeneralTag()}>
        {t('market.tags.all')}
      </Dropdown.Item>,
    );
    return result;
  };

  return (
    <>
      <Hidden show={{ xs: false, sm: false, xl: true }}>
        <Group onSelected={(item) => callbackGeneralTag(item)} selected={selectedItem ?? ''}>
          {getGeneralTagsButtonGroup()}
        </Group>
      </Hidden>
      <Hidden show={{ xs: true, sm: true, xl: false }}>
        <Dropdown position="left" size="md" value={t(`market.tags.${selectedItem ?? 'all'}`)}>
          {getGeneralTagsDropdown()}
        </Dropdown>
      </Hidden>
    </>
  );
};

export default GeneralTags;

import React, { FC, memo, useEffect } from 'react';

import { Flex, Text } from '@bilira-org/design';
import { ForceDecimal, formatPriceValue, useDebounce } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import FiatQuery from '@Libs/clientInstances/fiatQuery';

type Props = {
  amount: string;
};

const WithdrawalFee: FC<Props> = ({ amount }) => {
  const { t, i18n } = useTranslation();
  const debouncedAmount = useDebounce(amount, 300);
  const { data, isFetching, refetch } = FiatQuery.useGetWithdrawalFee(debouncedAmount, {
    enabled: false,
  });

  useEffect(() => {
    if (debouncedAmount) {
      refetch().then();
    }
  }, [debouncedAmount]);

  const formatValue = (value: string | undefined, decimal: number = 2) => {
    if (value === undefined) {
      return '-';
    }

    const result = formatPriceValue({
      value: value,
      precision: undefined,
      decimal: 2,
      forceDecimal: ForceDecimal.SHOW_INSIGNIFICANT_DIGITS,
      locale: i18n.language,
      defaultValue: '-',
    });
    return `${result} TL`;
  };
  return (
    <Flex gap="sm">
      <Flex.Item flex direction="row" items="center" justify="between">
        <Text size="sm">{t('common.withdraw-fee')}</Text>
        <Text loading={isFetching} align="right" size="sm">
          {formatValue(data?.withdrawal_fee)}
        </Text>
      </Flex.Item>
      <Flex.Item flex direction="row" items="center" justify="between">
        <Text size="sm">{t('common.withdraw-total-amount')}</Text>

        <Text loading={isFetching} align="right" size="sm" color="primary-500">
          {formatValue(data?.amount_to_send)}
        </Text>
      </Flex.Item>
    </Flex>
  );
};

export default memo(WithdrawalFee);

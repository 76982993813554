import React from 'react';

import { BaseComponentType } from '../types';

interface Props extends React.HTMLProps<'div'>, BaseComponentType {}

/**
 * Div component representing html `div` element
 */
function Div({ testId, ...props }: Props) {
  return React.createElement('div', { ...props, ['data-testid']: testId ?? 'div' });
}

export default Div;

import { Block, Skeleton, Switch, Text } from '@bilira-org/design';
import { castBoolean, ChannelType, PreferencesType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { notificationPreferenceData } from '../constants';

type Props = {
  data?: PreferencesType[] | undefined;
  updatePreference: (name: string, channel: ChannelType, value: string) => void;
  notificationPreference: (typeof notificationPreferenceData)[number];
  loading?: boolean;
};

const PreferenceSwitches = ({ data, updatePreference, notificationPreference, loading }: Props) => {
  const { t } = useTranslation();
  const preference = data?.find((item) => item.name === notificationPreference.key);

  if (loading) {
    return (
      <Block my="4xl" gap="lg">
        <Block row gap="md" items="center">
          <Skeleton width="size-9" height="size-5" rounded="xl" />
          <Text>{t('common.sms-coming-soon')}</Text>
        </Block>
        <Block row gap="md" items="center">
          <Skeleton width="size-9" height="size-5" rounded="xl" />
          <Text>
            {t('common.field-mandatory', {
              field: t('common.email'),
            })}
          </Text>
        </Block>
        <Block row gap="md" items="center">
          <Skeleton width="size-9" height="size-5" rounded="xl" />

          <Text>{t('common.mobile')}</Text>
        </Block>
      </Block>
    );
  }

  return (
    <Block my="4xl" gap="lg">
      <Block row gap="md" items="center">
        <Switch
          checked={castBoolean(preference?.sms || 'false')}
          onSwitch={(state) => updatePreference(notificationPreference.key, 'sms', state.toString())}
          size="xs"
          disabled
        />
        <Text>{t('common.sms-coming-soon')}</Text>
      </Block>
      <Block row gap="md" items="center">
        <Switch disabled checked={true} />
        <Text>
          {t('common.field-mandatory', {
            field: t('common.email'),
          })}
        </Text>
      </Block>
      <Block row gap="md" items="center">
        <Switch
          checked={castBoolean(preference?.push || 'false')}
          onSwitch={(state) => updatePreference(notificationPreference.key, 'push', state.toString())}
          size="xs"
        />
        <Text>{t('common.mobile')}</Text>
      </Block>
    </Block>
  );
};

export default PreferenceSwitches;

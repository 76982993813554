import React from 'react';

import { Block, LayoutV2, withIntrinsicHidden } from '@bilira-org/design';

import TwitterShareButton from '@Modules/market/detail/components/components/TwitterShareButton';
import useAssetDetail from '@Modules/market/detail/hooks/useAssetDetail';

import WatchlistStar from './components/components/WatchlistStar';

const { Hero } = LayoutV2;

const SiderHeader = () => {
  const { assetData: result, base, quote } = useAssetDetail();

  if (!result) {
    return null;
  }

  return (
    <>
      <Hero>
        <Block row justify="end" gap="sm" items="center">
          <WatchlistStar baseAsset={base} quoteAsset={quote} />
          <TwitterShareButton name={result.name} symbol={result.symbol} quoteAsset={quote} />
        </Block>
      </Hero>
    </>
  );
};

export default withIntrinsicHidden(SiderHeader);

interface IShareOnTwitter {
  text: string;
  url: string;
  hashtags: string;
}

/**
 * Builds a Twitter share URL based on the provided parameters.
 *
 * @param {IShareOnTwitter} params - The parameters for sharing on Twitter.
 * @returns {string} - The generated Twitter share URL.
 *
 * @example
 * const twitterShareUrl = buildTwitterShareUrl({
 *   text: 'Check out this awesome website!',
 *   url: 'https://example.com',
 *   hashtags: 'webdev, example'
 * });
 */
export const buildTwitterShareUrl = ({ text, hashtags, url }: IShareOnTwitter) => {
  const buildParams = new URLSearchParams({
    text: text,
    url: url,
    hashtags: hashtags,
  });

  return `https://twitter.com/intent/tweet?${buildParams.toString()}`;
};

/**
 * Builds a Facebook share URL based on the provided URL.
 *
 * @param {{ url: string }} params - The parameters containing the URL to be shared on Facebook.
 * @returns {string} - The generated Facebook share URL.
 *
 * @example
 * const facebookShareUrl = buildFacebookShareUrl({ url: 'https://example.com' });
 */
export const buildFacebookShareUrl = ({ url }: { url: string }) => {
  const buildParams = new URLSearchParams({
    u: url,
  });

  return `https://www.facebook.com/sharer/sharer.php?${buildParams.toString()}`;
};

/**
 * Builds a LinkedIn share URL based on the provided URL.
 *
 * @param {{ url: string }} params - The parameters containing the URL to be shared on LinkedIn.
 * @returns {string} - The generated LinkedIn share URL.
 *
 * @example
 * const linkedinShareUrl = buildLinkedinShareUrl({ url: 'https://example.com' });
 */
export const buildLinkedinShareUrl = ({ url }: { url: string }) => {
  const buildParams = new URLSearchParams({
    url: url,
  });
  return `https://www.linkedin.com/sharing/share-offsite/?${buildParams.toString()}`;
};

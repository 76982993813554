import { useTranslation } from 'react-i18next';

import AccountQuery from '@Libs/clientInstances/accountQuery';
import useNotify from '@Libs/hooks/useNotify';
import useAuth from '@Libs/hooks/userAuth';

/**
 * Hook to handle fetching bank accounts, deleting bank account,
 * and updating default bank account
 *
 * @returns {Object} - Bank account-related functions and data.
 */
const useBankAccounts = () => {
  const { account } = useAuth();
  const { data, isPending } = AccountQuery.useGetBankAccounts(account);
  const del = AccountQuery.useDeleteBankAccount();
  const patch = AccountQuery.usePatchDefaultBankAccount();
  const { t } = useTranslation();
  const { awaitNotify } = useNotify({
    isSuccess: del.isSuccess || patch.isSuccess,
    isError: del.isError || patch.isError,
  });

  /**
   * Function to switch the main bank account.
   * Also shows toast for the request states.
   *
   * @param {string} id - ID of the bank account to switch.
   * @returns {Promise<void>} - A Promise that resolves after switching the main bank account.
   */
  const switchMainBankAccount = async (id: string, isDefault?: boolean) => {
    if (isDefault) {
      return Promise.resolve(true);
    }
    patch.mutate(id);

    return awaitNotify({
      loading: t('bank-account.main-bank-account-changing'),
      success: t('bank-account.main-bank-account-changed'),
      error: t('bank-account.main-bank-account-could-not-changed'),
    });
  };

  /**
   * Function to delete a bank account.
   * Also shows toast for the request states.
   *
   * @param {string} id - ID of the bank account to delete.
   * @returns {Promise<void>} - A Promise that resolves after deleting the bank account.
   */
  const bankDelete = (id: string) => {
    del.mutate(`${id}`);

    awaitNotify({
      loading: t('common.deleting'),
      success: t('common.deleted'),
      error: t('common.could-not-delete'),
    });
  };

  return { bankDelete, isPending, bankAccounts: data, switchMainBankAccount };
};

export default useBankAccounts;

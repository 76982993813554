import { NotificationTypesType } from '@bilira-org/react-utils';

import { OTC_TRANSACTION_TYPES } from './constants';

export function getIsOtcTransaction(type?: NotificationTypesType) {
  return OTC_TRANSACTION_TYPES.includes(type as NotificationTypesType);
}

export function getTransactionType(type?: NotificationTypesType) {
  return type?.split(':')[0];
}

import { useMemo } from 'react';

import { validateWithdrawLimits } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

interface Props {
  txMin: string;
  txMax: string;
  minWithdrawFee?: string;
  freeBalance?: string;
}

const useWithdrawValidations = ({ txMin, txMax, freeBalance, minWithdrawFee }: Props) => {
  const { t } = useTranslation();

  const { error, isInputDisabled } = useMemo(() => {
    const { error: withdrawLimitError } = validateWithdrawLimits({
      txMin,
      txMax,
      freeBalance,
      minWithdrawFee,
      errorMessages: {
        hasLessBalanceThanMinimum: t('common.validation.less-balance-than-minimum-tx'),
        hasLessTxLimitThanMinimum: t('common.validation.less-tx-limit-than-minimum-tx'),
        hasLessBalanceThanFee: t('common.validation.less-balance-than-fee'),
      },
    });

    return { error: withdrawLimitError, isInputDisabled: !!withdrawLimitError };
  }, [t, txMax, txMin, freeBalance, minWithdrawFee]);

  return { error, isInputDisabled };
};

export default useWithdrawValidations;

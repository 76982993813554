import React, { memo, useState } from 'react';

import { Modal, Stepper } from '@bilira-org/design';
import { BankAccountType } from '@bilira-org/react-utils/src/model/BankAccount';
import { useTranslation } from 'react-i18next';

import BankAccounts from '@Components/bank/bankAccount/BankAccounts';
import CreateBankAccount from '@Components/bank/bankAccount/CreateBankAccount';

type Props = {
  /** Whether modal is active or not */
  modalActive: boolean;
  /** Callback to be called when modal is closed */
  setModalActive: (value: boolean) => void;
  /** If true list is in selection mode, otherwise edit & delete buttons will be visible*/
  isModeSelection?: boolean;
};

/**
 * Modal to list bank accounts
 * Can create, edit, delete or select bank account
 */
function BankAccountListModal({ modalActive, setModalActive, isModeSelection }: Props) {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [bankAccount, setBankAccount] = useState<BankAccountType>();

  return (
    <>
      <Modal
        overlay={false}
        onPreviousClick={(initial) => setCurrentStep((prev) => (initial ? 0 : prev - 1))}
        hasPreviousButton={currentStep > 0}
        title={t('bank-account.saved-accounts-title')}
        open={modalActive}
        onClose={() => setModalActive(false)}
      >
        <Stepper step={currentStep}>
          <Stepper.Item key="bank-accounts">
            <BankAccounts
              isModeSelection={isModeSelection}
              callback={(data?: BankAccountType) => {
                setBankAccount(data);
                if (data && isModeSelection) {
                  setModalActive(false);
                } else {
                  setCurrentStep((prev) => prev + 1);
                }
              }}
            />
          </Stepper.Item>
          <Stepper.Item key="create-bank-account">
            <CreateBankAccount bankAccount={bankAccount} callback={() => setCurrentStep((prev) => prev - 1)} />
          </Stepper.Item>
        </Stepper>
      </Modal>
    </>
  );
}

export default memo(BankAccountListModal);

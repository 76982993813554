import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { isTimeInRange } from '../isTimeInRange';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Checks if the current time is within a specified time range in the given region.
 * @param {string} start The start time of the range in HH:mm format.
 * @param {string} end The end time of the range in HH:mm format.
 * @param {string} [region='Europe/Istanbul'] The region/timezone to consider for time calculation.
 * @returns {boolean} Returns true if the current time is outside the specified work hours, otherwise false.
 * @example
 * // Default usage for Europe/Istanbul timezone
 * isCurrentTimeInRange('09:30', '17:30'); // true if current time is between 09:30 - 17:30
 *
 * // Custom usage specifying different region
 * isCurrentTimeInRange('08:00', '16:00', 'America/New_York'); // true if current time is between 08:00 - 16:00 in America/New_York timezone
 */
export const isCurrentTimeInRange = (start: string, end: string, region: string = 'Europe/Istanbul') => {
  return isTimeInRange(dayjs().tz(region).format('HH:mm'), start, end);
};

import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import getStoreUrl from '@/libs/helpers/getStoreUrl';
import { sendUTMToMixpanel } from '@/libs/helpers/sendUTMToMixpanel';
import { useAuthParamsStore } from '@/libs/hooks/useAuthParams';

function DownloadApp() {
  const { t } = useTranslation();
  const utmParams = useAuthParamsStore((state) => state.authParams.utm);

  useEffect(() => {
    const handleRedirect = async () => {
      if (!utmParams || Object.keys(utmParams).length === 0) {
        window.location.href = getStoreUrl();
        return;
      }

      try {
        await sendUTMToMixpanel(utmParams);
      } catch (error) {
        console.error("Can't post mixpanel events", error);
      }

      window.location.href = getStoreUrl();
    };

    handleRedirect();
  }, []);

  return <div>{t('redirect.redirect-to-mobile-app')}</div>;
}

export default DownloadApp;

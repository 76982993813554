import React from 'react';

import { Block, Skeleton, Text } from '@bilira-org/design';
import { concatTicker } from '@bilira-org/react-utils';

import useFormatNumberByLivePrice from '@/libs/hooks/useFormatNumberByLivePrice';
import useStatisticsLive from '@Libs/hooks/useStatisticsLive';
import EmptyText from '@Modules/settings/commission/fee/components/EmptyText';

type Props = {
  base: string;
  quote?: string;
};
const MarketCapLive = ({ base, quote }: Props) => {
  const formatNumberByLivePrice = useFormatNumberByLivePrice({ base, quote });
  const { liveData } = useStatisticsLive({ symbol: concatTicker(base, quote) });

  if (base === 'BMMF') {
    return <EmptyText />;
  }

  if (!liveData) {
    return <Skeleton key="price" height="size-4" width="size-24" />;
  }

  const result = formatNumberByLivePrice({
    type: 'price',
    value: liveData.m_cap,
    stringifyFormat: '0[.]00a',
  });

  return (
    <Block row justify="end" gap="xs" items="baseline">
      <Text color="secondary-500" weight="medium">{`${result.shortValue ?? result.value}`}</Text>
      <Text color="neutral-700" size="xs">
        {quote}
      </Text>
    </Block>
  );
};

export default MarketCapLive;

import React, { useEffect } from 'react';

import { Navigate } from 'react-router-dom';

interface Props {
  setIsNotEligible: (eligible: boolean) => void;
}

const NotEligibleForV2 = ({ setIsNotEligible }: Props) => {
  useEffect(() => {
    setIsNotEligible(true);
  }, []);

  return <Navigate to="/" />;
};

export default NotEligibleForV2;

import React from 'react';

import { Alert, Button, Icon, Link, useSafeLocalStorage } from '@bilira-org/design';
import { Trans, useTranslation } from 'react-i18next';

const WarningClosable = () => {
  const [hidden, setHidden] = useSafeLocalStorage('CW-SW', false);
  const { t } = useTranslation();

  if (hidden) {
    return null;
  }

  return (
    <Alert
      mt="md"
      status="yellow"
      extra={
        <Button
          size="md"
          variant="transparent"
          startIcon={<Icon color="neutral-700" type="o:x-mark" />}
          onClick={() => setHidden(true)}
        />
      }
    >
      <Trans
        t={t}
        i18nKey="crypto.crypto-withdraw-warning"
        components={{ 'withdraw-anchor': <Link to="/fiat/withdraw" underline /> }}
      />
    </Alert>
  );
};

export default WarningClosable;

import React from 'react';

import { Flex, Icon, Meta, Separator } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

function DocumentFooter() {
  const { t } = useTranslation();

  return (
    <Flex items={{ md: 'center' }} direction={{ md: 'row' }} gap={{ xs: 'xs', sm: 'sm', md: 'lg' }}>
      <Flex.Item>
        <Meta
          space="sm"
          extra={<Icon size="lg" type="o:map-pin" color="neutral-700" />}
          description={t('account.id-verification.residence-permit-or-domicile-certificate')}
        />
      </Flex.Item>
      <Flex.Item flex items={{ xs: 'start', sm: 'start', md: 'center' }}>
        <Separator height="size-4" mx="md" />
      </Flex.Item>

      <Flex.Item>
        <Meta
          space="sm"
          extra={<Icon size="lg" type="o:user-circle" color="neutral-700" />}
          description={t('account.id-verification.selfie-photo')}
        />
      </Flex.Item>
      <Flex.Item flex items={{ xs: 'start', sm: 'start', md: 'center' }}>
        <Separator height="size-4" mx="md" />
      </Flex.Item>
      <Flex.Item>
        <Meta
          space="sm"
          extra={<Icon size="lg" type="o:identification" color="neutral-700" />}
          description={t('account.id-verification.personal-info')}
        />
      </Flex.Item>
    </Flex>
  );
}

export default DocumentFooter;

import React, { ReactNode, useEffect, useLayoutEffect } from 'react';

import { clsx } from 'clsx';

import { BaseComponentType, BreakpointType, PaddingSizes, ResponsiveReturnType } from '../types';
import { buildResponsiveClass } from '../utils';

type Props = BaseComponentType & {
  /** Content to be display within the modal body */
  children: ReactNode | React.ReactElement | ReactNode[] | React.ReactElement[];
  /** Padding of the modal body, single size or a responsive size object */
  padding?: PaddingSizes | BreakpointType<PaddingSizes>;
};

/**
 * The `ModalBody` component is used to render the body content of a modal.
 *
 * @example
 *
 * <ModalBody padding="lg">
 *   Modal body content
 * </ModalBody>
 *
 */
const ModalBody = ({ children, padding = { xs: 'lg', sm: 'lg', md: '2xl' }, testId }: Props) => {
  let paddingClass: ResponsiveReturnType = {};
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);
  const elementRef = React.useRef<HTMLDivElement>(null);
  if (padding) {
    paddingClass = buildResponsiveClass(padding, 'p-');
  }

  const handleScroll = (scrolling: boolean) => {
    // elementRef.current?.classList.add('custom-scrollbar');
    elementRef.current?.classList.add('visible-scroll');
    timerRef.current && clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      // elementRef.current?.classList.remove('custom-scrollbar');
      elementRef.current?.classList.remove('visible-scroll');
    }, 500);
  };

  useLayoutEffect(() => {
    elementRef.current?.scrollTo(0, 0);
  }, [children]);

  useEffect(() => {
    elementRef.current?.addEventListener('scroll', () => handleScroll(true), true);
    // elementRef.current?.addEventListener('scrollend', () => handleScroll(false), true);
    return () => {
      elementRef.current?.removeEventListener('scroll', () => handleScroll(true), true);
      // elementRef.current?.removeEventListener('scrollend', () => handleScroll(false), true);
    };
  }, []);

  return (
    <div
      ref={elementRef}
      className={clsx('modal-content custom-scrollbar text-left', paddingClass)}
      data-testid={testId ?? 'modal-body'}
    >
      {children}
    </div>
  );
};

ModalBody.displayName = 'ModalBody';

export default ModalBody;

import React from 'react';

import type { TransactionsRecordType } from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';

import FormattedNumberByPrice from '@Components/common/FormattedNumberByPrice';
import { getTransactionSuffix } from '@Components/transaction/helpers';
import { TRANSACTION_STATUS } from '@Libs/helpers/getStateConfig';

import { TransactionItemSizeType } from '../../TransactionTable';
import { transactionFormatPriceProps, transactionTextSize } from '../constants';

type Props = {
  data: TransactionsRecordType;
  size: TransactionItemSizeType;
};

const TransactionAmount = ({ data, size }: Props) => {
  const price = data.side === 'buy' ? data.offer_price : BigNumber(1).div(data.offer_price).toString();

  return (
    <FormattedNumberByPrice
      formatPriceProps={transactionFormatPriceProps}
      align="right"
      wordBreak="all"
      size={transactionTextSize[size]}
      suffixSize={transactionTextSize[size]}
      suffix={getTransactionSuffix(data)}
      weight="medium"
      suffixWeight="medium"
      color={TRANSACTION_STATUS[data.status]?.text}
      value={data.executed_amount}
      type="amount"
      price={price}
    />
  );
};

export default TransactionAmount;

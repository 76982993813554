/**
 * Utility class for managing data in the local storage.
 */
export default class LocalStore {
  /** Prefix to be added to local storage keys */
  static prefixKey = 'BL___';

  /**
   * Checks if the local storage is active and available.
   *
   * @returns {boolean} - True if local storage is active, false otherwise.
   */
  static isActive() {
    return typeof Storage !== 'undefined';
  }

  /**
   * Sets a key-value pair in the local storage.
   *
   * @template T
   * @param {string} key - The key to store the value.
   * @param {string | T} value - The value to be stored.
   */
  static set<T>(key: string, value: string | T) {
    // Check whether value is object
    if (typeof value === 'string') {
      localStorage.setItem(key, value);
      return;
    }

    localStorage.setItem(key, LocalStore.prefixKey + JSON.stringify(value));
  }

  /**
   * Gets the value associated with a key from the local storage.
   *
   * @param {string} key - The key to retrieve the value.
   * @param {*} defaultValue - The default value to return if the key is not found.
   * @returns {*} - The retrieved value or the default value if not found.
   */
  static get(key: string, defaultValue = null) {
    const value = localStorage.getItem(key);
    if (value === null) return defaultValue;
    const parsed = value.split(LocalStore.prefixKey);
    if (parsed.length > 1) return JSON.parse(parsed[1]);
    return parsed[0];
  }

  /**
   * Deletes a specific property from an object stored in the local storage.
   *
   * @param {string} key - The key associated with the object.
   * @param {string} jsonKey - The property key to be deleted.
   * @returns {boolean} - True if the property was deleted, false otherwise.
   */
  static deleteFromObject(key: string, jsonKey: string) {
    const value = localStorage.getItem(key);
    if (value === null) return false;

    const parsed = value.split(LocalStore.prefixKey);

    if (parsed.length <= 1) return false;

    const jsonObj = JSON.parse(parsed[1]);
    if (jsonObj[jsonKey]) {
      delete jsonObj[jsonKey];
      LocalStore.set(key, jsonObj);
      return true;
    }
    return false;
  }
}

// import { LocalStateKey } from '../../enums/LocalStateKey'
//
// /**
//  * Loads state from local storage by key
//  *
//  * @param {LocalStateKey} localStateKey - Unique key for data to be taken from localStorage.
//  * @return {T | undefined} - Returns a generic data or undefined if user privacy mode does not allow the use of localStorage.
//  */
// export function loadState<T>(localStateKey: LocalStateKey): T | undefined {
//   try {
//     const serializedState = localStorage.getItem(localStateKey)
//     if (serializedState === null) {
//       return undefined
//     }
//     return JSON.parse(serializedState) as T
//   } catch (err) {
//     return undefined
//   }
// }
//
// /**
//  * Saves given state to localStorage by key.
//  *
//  * @param {T} state - A state to be stored in localStorage
//  * @param {LocalStateKey} localStateKey - Unique key for data to be stored in localStorage.
//  */
// export function saveState<T>(state: T, localStateKey: LocalStateKey): void {
//   try {
//     const serializedState = JSON.stringify(state)
//     localStorage.setItem(localStateKey, serializedState)
//   } catch (err) {
//     // TODO: Error handler later.
//   }
// }

import React from 'react';

import { Alert, Button, FileType, FileUploader } from '@bilira-org/design';
import { useStateEffect } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

export type SelectedDocType = { name: string; docCount: number; slug: string };

type Props = {
  callback: (source: FileType[]) => void;
  sources?: FileType[];
};

function ResidencySelection({ callback, sources }: Props) {
  const { t } = useTranslation();
  const [files, setFiles] = useStateEffect<FileType[] | undefined>(sources);
  const isSubmitButtonDisabled = !files?.length || FileUploader.checkFileErrors(files);

  return (
    <>
      <Alert status="yellow" mb="2xl">
        {t('account.id-verification.kyc-3.upload-residency-doc')}
      </Alert>

      <FileUploader
        files={files ?? sources}
        onUpload={setFiles}
        styleItemPanelAspectRatio="0.5"
        maxFiles={1}
        acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']}
        labelIdle={t('common.upload-file-label', {
          maxFileSize: '10MB',
        })}
      />

      <Button
        disabled={isSubmitButtonDisabled}
        mt="2xl"
        stretch
        justify="center"
        variant="filled"
        size="xl"
        onClick={() => callback(files || [])}
      >
        {t('common.continue')}
      </Button>
    </>
  );
}

export default ResidencySelection;

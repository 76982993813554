import type * as React from 'react';

/**
 * Assigns a value to a React ref, handling both function and object refs.
 *
 * @template T - The type of the node that the ref is referencing.
 * @param {React.Ref<T>} ref - The React ref to be filled.
 * @param {T} node - The value to be assigned to the ref.
 * @returns {void}
 */
export function fillRef<T>(ref: React.Ref<T>, node: T) {
  if (typeof ref === 'function') {
    ref(node);
  } else if (typeof ref === 'object' && ref && 'current' in ref) {
    (ref as any).current = node;
  }
}

/**
 * Merges multiple refs into a single ref function to support ref passing.
 *
 * @template T - The type of the node that the refs are referencing.
 * @param {...React.Ref<T>[]} refs - The React refs to be composed.
 * @returns {React.Ref<T>} A composed ref function.
 */
export function composeRef<T>(...refs: React.Ref<T>[]): React.Ref<T> {
  const refList = refs.filter((ref) => ref);
  if (refList.length <= 1) {
    return refList[0];
  }

  return (node: T) => {
    refs.forEach((ref) => {
      fillRef(ref, node);
    });
  };
}

import BigNumber from 'bignumber.js';

import { isEmpty } from '../isEmpty';

/**
 * Calculates the net transaction amount by subtracting the fee from the given amount.
 *
 * @param {Object} params - The parameters object.
 * @param {string} [params.amount] - The transaction amount.
 * @param {string} [params.fee] - The transaction fee.
 * @param {string} [params.defaultValue] - The default value to return if amount is empty. Returns `undefined` by default.
 *
 * @returns {string} The calculated net transaction amount.
 *
 * @example
 * const txAmount = calculateTransactionAmount({
 *   amount: '5',
 *   fee: '2',
 * });
 * // Returns: '3'
 *
 * const txAmountDefault = calculateTransactionAmount({
 *   amount: undefined,
 *   fee: '2',
 *   defaultValue:'0'
 * });
 * // Returns: '0'
 */
const calculateTransactionAmount = ({
  amount,
  fee,
  defaultValue,
}: {
  amount?: string;
  fee?: string;
  defaultValue?: string;
}) => {
  if (isEmpty(amount)) {
    return defaultValue;
  } else if (isEmpty(fee)) {
    return amount;
  } else {
    return BigNumber(amount as string)
      .minus(fee as string)
      .toString();
  }
};

export default calculateTransactionAmount;

import { Text } from '@bilira-org/design';
import { TextColorType } from '@bilira-org/design/types';
import {
  calculateRewardPoints,
  ForceDecimal,
  RewardTaskState,
  shouldDisplayRewardMultiplier,
} from '@bilira-org/react-utils';

import FormattedNumber from '@Components/common/FormattedNumber';

import { RewardTaskProps } from '../RewardTask';

interface TaskPointsProps {
  task: RewardTaskProps['task'];
  taskState: RewardTaskState;
  color?: TextColorType;
}

function RewardTaskPoints({ task, taskState, color = 'yellow-900' }: TaskPointsProps) {
  if (shouldDisplayRewardMultiplier(task)) {
    return (
      <Text weight="medium" color={color}>
        <FormattedNumber
          as="span"
          weight="medium"
          color={color}
          value={task.point_multiplier}
          formatPriceProps={{ precision: undefined, decimal: undefined, forceDecimal: undefined, defaultValue: '-' }}
        />
        x
      </Text>
    );
  }

  return (
    <FormattedNumber
      weight="medium"
      color={color}
      value={calculateRewardPoints(task, taskState)}
      formatPriceProps={{ decimal: 2, forceDecimal: ForceDecimal.STRICT_DECIMAL, defaultValue: '-' }}
    />
  );
}

export default RewardTaskPoints;

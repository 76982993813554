import React from 'react';

import { Block, Input, Label, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

type AuthenticatorProps = {
  /** Function to be called when input changes */
  callback: (code: string) => void;
  /** Validation message to be displayed */
  validationMessage?: string;
  /** Function to be called when "Enter" key is pressed */
  onEnter?: () => void;
};

/**
 * Input field for entering authenticator OTP code
 */
const AuthenticatorOTP = ({ validationMessage, callback, onEnter }: AuthenticatorProps) => {
  const { t } = useTranslation();

  const onChange = (value: string) => {
    callback(value);
  };

  return (
    <Block>
      <Label>{t('two-fa.otp-verification-code')}</Label>
      <Input.Number disableZeroCheck onChange={onChange} onEnter={onEnter} />
      {validationMessage && (
        <Text color="red-500" size="sm" mt="sm">
          {validationMessage}
        </Text>
      )}
      <Text color="neutral-700" size="sm" mt="sm">
        {t('two-fa.type-code-sent-to-authenticator')}
      </Text>
    </Block>
  );
};
export default AuthenticatorOTP;

import React, { memo, useState } from 'react';

import { Block, Icon, Meta, Modal, Panel, Progress, Separator, Typography } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { AccountAllowances } from './components';
import AverageFiatAllowanceBar from './components/AverageFiatAllowanceBar';

const { Text } = Typography;

function Index() {
  const { t } = useTranslation();
  const [modalActive, setModalActive] = useState(false);

  return (
    <>
      <Panel
        size="lg"
        outline="neutral-400"
        color="theme-wb"
        hover="outline"
        minimize
        onClick={() => setModalActive(true)}
      >
        <Block row items="center" gap="md">
          <Icon size="lg" type="o:battery-50" color="secondary-500" />
          <Separator height="size-3" color="neutral-400" />

          <Meta
            gap="sm"
            title={
              <Text size="sm" weight="medium" color="secondary-500">
                {t('account.account-limits')}
              </Text>
            }
            description={<AverageFiatAllowanceBar />}
          />
        </Block>
      </Panel>

      <Modal title={t('account.account-limits-modal.title')} open={modalActive} onClose={() => setModalActive(false)}>
        <AccountAllowances />
      </Modal>
    </>
  );
}

export default memo(Index);

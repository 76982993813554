import React, { useState } from 'react';

import { Button, Flex, Icon, Meta, Panel, Separator, Text } from '@bilira-org/design';
import { TextColorType } from '@bilira-org/design/src/components/color';
import { useTranslation } from 'react-i18next';

import kycQuery from '@Libs/clientInstances/idpQuery';
import { KYC_STATUSES_DEF } from '@Modules/account/kyc/constants';

import DocumentFooter from './components/DocumentFooter';
import Kyc2Modal from './Kyc3Modal';
import DefaultAllowance from '../DefaultAllowance';

export const steps = {
  kycStep1: 'kyc-step-1',
  kycStep2: 'kyc-step-2',
  kycStep3: 'kyc-step-3',
  kycStep4: 'kyc-step-4',
  kycStep5: 'kyc-step-5',
};

function Kyc3Panel() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { data } = kycQuery.useGetUserKyc();

  if (!data?.kyc3_eligible) {
    return null;
  }

  const unverified = !data.kyc3;
  const verified = data.kyc3?.status === 'verified';

  const kycInfo = KYC_STATUSES_DEF?.[data.kyc3?.status as KycStatusesType];
  const showVerifyButton = unverified || ['failed', 'rejected'].includes(data.kyc3?.status || '');

  return (
    <>
      <Panel border="neutral-400">
        <Flex gap="lg" direction={{ md: 'row' }} justify="between" wrap>
          <Meta
            title={
              <Text heading size="xl" weight="bold">
                {t('account.kyc-level', { level: 3 })}
              </Text>
            }
            description={<DefaultAllowance kycLevel={3} />}
          />

          <Flex
            direction={{ xs: 'col', sm: 'col', lg: 'row' }}
            items="center"
            gap={{ xs: 'lg', sm: 'lg', md: 'none', lg: 'lg' }}
          >
            {!unverified && (
              <Flex.Item flexType="none" p="lg">
                <Meta
                  extra={
                    verified ? (
                      <Icon size="lg" color="green-500" type="s:check-circle" />
                    ) : (
                      <Icon
                        size="lg"
                        color={(kycInfo?.color as TextColorType) ?? 'orange-500'}
                        type="s:exclamation-circle"
                      />
                    )
                  }
                  space="sm"
                  title={
                    <Text size="sm" color="secondary-500" weight="regular">
                      {t(`account.id-verification.kyc-statuses.${kycInfo?.name}`)}
                    </Text>
                  }
                />
              </Flex.Item>
            )}
            {showVerifyButton && (
              <Flex.Item flexType="auto" width="full">
                <div>
                  <Button stretch variant="filled" size="md" onClick={() => setOpen(true)}>
                    {unverified ? t('common.verify') : t('common.verify-again')}
                  </Button>
                </div>
              </Flex.Item>
            )}
          </Flex>
        </Flex>
        <Separator my="2xl" width="size-full" />
        <DocumentFooter />
      </Panel>

      <Kyc2Modal open={open} setOpen={setOpen} />
    </>
  );
}

export default Kyc3Panel;

import CheckboxGroup, { CheckboxMultipleProps } from './checkboxGroup';
import CheckboxSingle, { CheckboxSingleProps } from './checkboxSingle';
import { CheckboxDataType } from './type';
import { BaseComponentType } from '../types';

export type CheckboxProps<T extends CheckboxDataType> = BaseComponentType & { dataSource?: T[] | undefined } & (
    | (CheckboxSingleProps & { dataSource?: undefined })
    | (CheckboxMultipleProps<T> & { dataSource: T[] })
  );

/**
 * Checkbox component that can be used either as a single checkbox or as a group of checkboxes.
 *
 * @example
 * // Example usage of Checkbox component as a single checkbox
 * <Checkbox label="Single Checkbox" checked onChange={handleCheckboxChange} />
 *
 * @example
 * // Example usage of Checkbox component as a group of checkboxes
 * <Checkbox
 *   dataSource={[{ label: 'Option 1', value: 'option1' }, { label: 'Option 2', value: 'option2' }]}
 *   onChange={handleCheckboxGroupChange}
 * />
 */
function Checkbox<T extends CheckboxDataType>({ ...props }: CheckboxProps<T>) {
  if (props.dataSource) {
    return <CheckboxGroup {...(props as CheckboxMultipleProps<T>)} />;
  }

  return <CheckboxSingle {...(props as CheckboxSingleProps)} />;
}

export default Checkbox;

import React, { ForwardedRef, forwardRef, PropsWithChildren } from 'react';

import { HorizontalMenuGroup } from './components/horizontalMenu/HorizontalMenuGroup';
import { VerticalMenuGroup } from './components/verticalMenu/VerticalMenuGroup';
import { MenuProps } from './types';

export const MenuGroup = forwardRef<HTMLDivElement, PropsWithChildren<MenuProps>>(
  ({ vertical, children, ...props }: PropsWithChildren<MenuProps>, ref: ForwardedRef<HTMLDivElement>) => {
    const Element = vertical ? VerticalMenuGroup : HorizontalMenuGroup;

    return (
      <Element {...props} ref={ref}>
        {children}
      </Element>
    );
  },
);

import { Hidden } from '@bilira-org/design';
import { OTCStatusType, OTCType, SwapQueryKey } from '@bilira-org/react-utils';

import { queryCache } from '@Libs/clientInstances/queryClient';
import SwapQuery from '@Libs/clientInstances/swapQuery';
import useAuth from '@Libs/hooks/userAuth';

import OTCTable from './OTCTable';
import OTCTableResponsive from './OTCTableResponsive';

type Props = {
  acceptRequest: (entry: OTCType) => () => void;
  cancelRequest: (entry: OTCType) => () => void;
  onDetailClick: (id: string) => void;
  filter?: OTCStatusType;
};
const OTCTableContainer = ({ filter, acceptRequest, cancelRequest, onDetailClick }: Props) => {
  const { account } = useAuth();
  const { data: { records: data = [] } = {}, isPending } = SwapQuery.useGetOtcTransactions({
    account: account,
    status: filter,
  });

  const expireTimeout = () => {
    queryCache.resetQueries([SwapQueryKey.SWAP_OTC_TRANSACTIONS]);
  };

  return (
    <>
      <Hidden show={{ xs: !!data.length, sm: !!data.length, lg: false }}>
        <OTCTableResponsive
          loading={isPending}
          data={data}
          onDetailClick={onDetailClick}
          acceptRequest={acceptRequest}
          cancelRequest={cancelRequest}
          expireTimeout={expireTimeout}
        />
      </Hidden>
      <Hidden show={{ xs: !data.length, sm: !data.length, lg: true }}>
        <OTCTable
          loading={isPending}
          data={data}
          onDetailClick={onDetailClick}
          acceptRequest={acceptRequest}
          cancelRequest={cancelRequest}
          expireTimeout={expireTimeout}
        />
      </Hidden>
    </>
  );
};

export default OTCTableContainer;

import React from 'react';

import {
  Block,
  Button,
  Checkbox,
  Col,
  Form,
  Grid,
  Input,
  Label,
  Meta,
  notification,
  Separator,
  Text,
} from '@bilira-org/design';
import { ReferralCodesResponseType, ReferralCodeTypeItem } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import FormattedPercentage from '@/components/common/FormattedPercentage';
import UserQuery from '@Libs/clientInstances/userQuery';

import { calculateCashbackPercentage, calculatePercentage } from '../../helpers';

interface Props {
  onSubmit: () => void;
  onClose: () => void;
  referralCodes?: ReferralCodesResponseType;
  referralCodeTypes?: ReferralCodeTypeItem[];
}

function CreateReferralForm({ onSubmit, onClose, referralCodes, referralCodeTypes }: Props) {
  const { t } = useTranslation();

  const { mutateAsync } = UserQuery.usePostReferralCode();

  const form = Form.useForm<any>({
    mode: 'onChange', // to validate on field change
    defaultValues: { type: referralCodeTypes?.[0] },
  });

  const handleSubmit = (values: any) => {
    mutateAsync({ title: values.title, type: values.type.type, default: values.default })
      .then(() => {
        notification.success(t('common.saved'));
        onSubmit();
      })
      .catch(({ response }) => {
        notification.error(response.data.message);
      });
  };

  const selectedCommission = form.getValues('type')?.value;
  const cashback =
    selectedCommission !== undefined && referralCodes?.default_comission
      ? referralCodes.default_comission - selectedCommission
      : 0;

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Grid gap="md">
        <Col>
          <Form.Field
            controlled
            label={<Label>{t('referral.referrals-cashback-rate')}</Label>}
            name="type"
            rules={{
              required: t('common.validation.required-field'),
            }}
            hidden={true}
          >
            {({ field }) => (
              <Block row gap="md">
                {referralCodeTypes?.map((item) => (
                  <Button
                    key={item.type}
                    variant={field.value.type === item.type ? 'filled' : 'outline'}
                    size="sm"
                    onClick={() => field.onChange(item)}
                  >
                    <FormattedPercentage
                      value={calculateCashbackPercentage(referralCodes?.default_comission || 0, item.value)}
                    />
                  </Button>
                ))}
              </Block>
            )}
          </Form.Field>
        </Col>
        <Col>
          <Form.Field hidden={true} label={<Label>{t('referral.distributable-amount')}</Label>}>
            <Block row justify="between" items="center">
              <Meta
                title={<Text size="sm">{t('referral.you-receive')}</Text>}
                description={
                  <Text size="lg" color="primary-500">
                    <FormattedPercentage value={selectedCommission * 100} />
                  </Text>
                }
              />
              <Separator height="size-7" />
              <Meta
                title={<Text size="sm">{t('referral.referral-cashback-rate')}</Text>}
                description={
                  <Text size="lg" color="orange-500">
                    <FormattedPercentage value={calculatePercentage(cashback)} />
                  </Text>
                }
              />
            </Block>
          </Form.Field>
        </Col>
        <Col>
          <Form.Field
            label={<Label>{t('referral.referral-title')}</Label>}
            name="title"
            rules={{
              required: t('common.validation.required-field'),
            }}
          >
            <Input />
          </Form.Field>
        </Col>
        <Col>
          <Form.Field controlled name="default" hideErrorList>
            {({ field }) => (
              <Checkbox
                checked={field.value}
                onChecked={field.onChange}
                label={t('referral.set-as-standard-referral-code')}
              />
            )}
          </Form.Field>
        </Col>
        <Col>
          <Block row gap="md">
            <Button variant="outline" justify="center" size="xl" mt="xl" stretch onClick={() => onClose()}>
              {t('referral.cancel')}
            </Button>
            <Button variant="filled" justify="center" size="xl" mt="xl" type="submit" stretch>
              {t('common.create')}
            </Button>
          </Block>
        </Col>
      </Grid>
    </Form>
  );
}

export default CreateReferralForm;

import { useCallback, useEffect, useState } from 'react';

import { Form, notification } from '@bilira-org/design';
import { CryptoAssetsType, getTxLimits, httpError } from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';

import useGetBalance from '@/libs/hooks/useGetBalance';
import { useWalletStore } from '@Components/walletAddress/components/store/walletStore';
import allowancesQuery from '@Libs/clientInstances/allowancesQuery';
import CryptoWalletApi from '@Libs/clientInstances/cryptoQuery';
import useAuth from '@Libs/hooks/userAuth';
import useTwoFA from '@Libs/hooks/useTwoFA';
import { TRACKER_EVENTS } from '@Libs/tracker/events';
import { addTrackerTwoFa } from '@Libs/tracker/helpers/addTrackerAccount';
import { handleErrorResponse } from '@Libs/utils';

import useGetWithdrawalFee from './useGetWithdrawalFee';
import useWithdrawValidations from './useWithdrawValidations';

export interface IAmount {
  amount: string;
}

type Props = {
  asset?: CryptoAssetsType;
  onSuccess: () => void;
};

const useWithdrawCryptoForm = ({ asset, onSuccess }: Props) => {
  const { t, i18n } = useTranslation();
  const { selectedWallet } = useWalletStore();
  const form = Form.useForm<IAmount>({
    mode: 'onChange',
  });

  /** Update error messages on form */
  i18n.on('languageChanged', () => {
    Form.triggerFormErrors(form);
  });

  const { account } = useAuth();
  const [totalAmount, setTotalAmount] = useState<string>();

  const { twoFAData, activeTwoFAModal, setActiveTwoFAModal, handleVerifyTwoFA, handleSave } =
    useTwoFA<IAmount>('cryptoWithdrawal');

  const {
    withdrawFee,
    withdrawTotalAmount,
    isPending: isWithdrawFeeLoading,
    withdrawFeeAsset,
    minWithdrawFee,
    minWithdrawFeeAsset,
  } = useGetWithdrawalFee(
    {
      amount: !form.formState.errors.amount ? totalAmount : '0',
      network: selectedWallet?.network,
      asset: asset?.symbol,
    },
    !form.formState.errors.amount,
  );

  const { data: allowance, isPending: isAllowanceLoading } = allowancesQuery.useGetNextTransaction(
    {
      account,
      domain: 'crypto',
      direction: 'withdrawal',
      asset: asset?.symbol,
      network: selectedWallet?.network,
    },
    {
      enabled: !!(asset && selectedWallet),
    },
  );

  const { txMax, formattedTxMax, txMin, formattedTxMin } = getTxLimits({
    allowanceMax: allowance?.max,
    allowanceMin: allowance?.min,
    locale: i18n.language,
    formatPriceProps: { decimal: asset?.decimals },
  });

  const onAmountChange = (amount: string) => {
    let currentAmount = BigNumber(amount || '0');

    if (currentAmount.isZero() || currentAmount.isNaN()) {
      currentAmount = BigNumber('0');
    }

    setTotalAmount(currentAmount.toString());
  };

  const isActive = !!selectedWallet?.network;

  const post = CryptoWalletApi.usePostCryptoWithdrawal();

  const completeWithdrawal = useCallback(
    (payload: IAmount, verification?: string) => {
      if (!selectedWallet || !asset) {
        return;
      }

      if (!withdrawFee) {
        notification.error('Withdrawal fee not fetched.');
        return;
      }

      const loadingNotifyId = notification.loading(t('crypto.withdraw.withdrawal-being-processed'));
      post
        .mutateAsync({
          account: account,
          asset: asset.symbol,
          network: selectedWallet.network,
          address: selectedWallet.address,
          amount: payload.amount.toString(),
          two_fa_token: verification,
          withdrawal_fee: withdrawFee,
        })
        .then(() => {
          notification.dismiss(loadingNotifyId);
          onSuccess();
          notification.success(t('crypto.withdraw.withdrawal-successful'));
          addTrackerTwoFa(TRACKER_EVENTS.CRYPTO_WITHDRAWAL.REQUEST_WITHDRAWAL, payload);
        })
        .catch((e) => {
          notification.dismiss(loadingNotifyId);

          if (httpError(e).is429) {
            notification.error(t('common.too-many-attempts'));
          } else {
            handleErrorResponse(e);
          }
        })
        .finally(() => {
          setTotalAmount(undefined);
          setActiveTwoFAModal(false);
          form.reset();
        });
    },
    [selectedWallet, asset, withdrawFee],
  );

  const onSubmit = (payload: IAmount) => handleSave(completeWithdrawal, payload);
  const onVerifyTwoFA = (values: string) => handleVerifyTwoFA(completeWithdrawal, values);

  useEffect(() => {
    form.reset();
  }, [asset, selectedWallet?.network]);

  const { freeBalance } = useGetBalance({ symbol: asset?.symbol });

  const { error, isInputDisabled } = useWithdrawValidations({
    txMax: txMax.toString(),
    txMin: txMin.toString(),
    minWithdrawFee,
    freeBalance: freeBalance,
  });

  return {
    selectedWallet,
    twoFAData,
    onSubmit,
    onAmountChange,
    totalAmount: form.formState.isValid ? totalAmount : undefined,
    error,
    form,
    allowance,
    activeTwoFAModal,
    setActiveTwoFAModal,
    onVerifyTwoFA,
    txMax,
    txMin,
    withdrawFee: withdrawFee || minWithdrawFee,
    withdrawTotalAmount,
    withdrawFeeAsset: withdrawFeeAsset || minWithdrawFeeAsset,
    formattedTxMax,
    formattedTxMin,
    isPending: post.isPending || isWithdrawFeeLoading || isAllowanceLoading,
    isWithdrawalFeeLoading: isWithdrawFeeLoading,
    isSubmitButtonDisabled: !isActive || !form.formState.isValid || isWithdrawFeeLoading,
    isInputDisabled: !isActive || isInputDisabled,
  };
};

export default useWithdrawCryptoForm;

/**
 * Function to set theme in the DOM
 * @param {boolean} darkMode - Whether the dark mode is enabled
 */
const setThemeDom = (darkMode: boolean) => {
  if (window === undefined) return;

  if (darkMode) {
    document.documentElement.classList.remove('light');
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
    document.documentElement.classList.add('light');
  }

  // console.debug('switched to dark mode:', !!darkMode);
};

export default setThemeDom;

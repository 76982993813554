import { NetworkAliasType } from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';

import CryptoWalletApi from '@Libs/clientInstances/cryptoQuery';

interface Props {
  balance?: string;
  network?: NetworkAliasType;
  asset?: string;
}
/**
 * Hook to calculate the maximum withdrawal amount for a given crypto asset on network.
 * @example
 * useGetMaxCryptoWithdraw({amount:"5",network:"ETH",asset:"TRYB"})
 * // {maxWithdraw:"4.92", isPending:false}
 */
const useGetMaxCryptoWithdraw = ({ balance, network, asset }: Props) => {
  const { data: withdrawalFeeData, isFetching: isWithdrawalFeeFetching } = CryptoWalletApi.useGetWithdrawalFee(
    { amount: balance, network, asset },
    {
      enabled: !!(balance && balance !== '0' && network && asset),
    },
  );

  const withdrawalAmount = BigNumber(balance || '0');
  let maxWithdraw = BigNumber('0');

  // If there is no amount, we don't need to calculate the fee neither show the fee.
  if (withdrawalAmount.isGreaterThan(0)) {
    maxWithdraw = BigNumber(balance || '0').minus(withdrawalFeeData?.withdrawal_fee || '0');
  }

  return {
    maxWithdraw: maxWithdraw,
    isPending: isWithdrawalFeeFetching,
  };
};

export default useGetMaxCryptoWithdraw;

import React from 'react';

import { clsx } from 'clsx';

import { BaseComponentType, BaseSize } from '../types';

export type DisplayProps = BaseComponentType & {
  /** Children to render */
  children: React.ReactNode;
  /** Blur effect amount */
  blur?: BaseSize | 'none';
};

/**
 * Component to blur the children
 *
 * @example
 * <Blur blur="sm">
 *   Hello world
 * </Blur>
 *
 * <Blur blur="none">
 *   No blur
 * </Blur>
 */

const Blur = ({ children, blur }: DisplayProps) => {
  return <div className={clsx({ [`tw-blur-${blur}`]: blur })}>{children}</div>;
};

Blur.displayName = 'Blur';

export default Blur;

import React, { forwardRef, ReactNode, useState } from 'react';

import { clsx } from 'clsx';

import { Display } from '../display';
import { Icon } from '../icons';
import { BaseComponentType } from '../types';

export type AnnouncementProps = BaseComponentType & {
  /** Additional class name for the Announcement component */
  className?: string;
  /** The content to be displayed within the Announcement component */
  children?: ReactNode;
  /** Flag to hide or show the Announcement component */
  hide?: boolean;
  /** Callback function to be executed when the close button is clicked */
  onClose?: () => void;
  /** Whether announcement is closable */
  closable?: boolean;
};

/**
 * Announcement component for displaying important messages or alerts.
 *
 * @example
 * <Announcement hide={isHidden} onClose={handleClose}>
 *   <p>This is an announcement</p>
 * </Announcement>
 */
const Announcement = forwardRef<HTMLDivElement, AnnouncementProps>(
  ({ hide, children, className, onClose, testId, closable = true }, ref): JSX.Element => {
    const [hideBar, setHideBar] = useState(hide);
    const classes = clsx('announcement', className);

    return (
      <div className={clsx({ hidden: hideBar })} data-testid={testId ?? 'announcement'} ref={ref}>
        <div aria-description="Announcement Bar" className={classes}>
          <div className="announcement-content">{children}</div>

          <Display show={closable}>
            <button
              className="close-announcement"
              onClick={() => {
                onClose?.();
                setHideBar(true);
              }}
              data-testid={`${testId ?? 'announcement'}-close-btn`}
            >
              <Icon type="o:x-mark" size="lg" />
            </button>
          </Display>
        </div>
      </div>
    );
  },
);

Announcement.displayName = 'Announcement';

export default Announcement;

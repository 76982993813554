import React from 'react';

import type { TransactionsRecordType } from '@bilira-org/react-utils';

import FormattedNumber from '@/components/common/FormattedNumber';

import { transactionFormatPriceProps, transactionTextSize } from './constants';
import { TransactionItemSizeType } from '../TransactionTable';

type TransactionTitleProps = {
  data: TransactionsRecordType;
  showSubAmount?: boolean;
  size: TransactionItemSizeType;
};

const TransactionSubAmount = ({ showSubAmount, data, size }: TransactionTitleProps) => {
  if (!showSubAmount) {
    return null;
  }

  if (data.type === 'swap') {
    return (
      <FormattedNumber
        size={transactionTextSize[size]}
        suffix={data.from_asset || 'TRY'}
        color="neutral-800"
        value={data.executed_quantity}
        formatPriceProps={transactionFormatPriceProps}
      />
    );
  }

  return null;
};

export default TransactionSubAmount;

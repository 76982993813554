import React from 'react';

import { Block, Display, Hidden, LayoutV2, Skeleton, Text } from '@bilira-org/design';
import { ForceDecimal, formatPrice } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { IconCustom } from '@Components/icon/CustomIcon';
import RewardsQuery from '@Libs/clientInstances/rewardsQuery';

import RewardHistoryButton from '../../rewardHistory';

const { Hero } = LayoutV2;

interface Props {
  isRegisterPage?: boolean;
}

const Header = ({ isRegisterPage }: Props) => {
  const { t, i18n } = useTranslation();
  const points = RewardsQuery.useGetPoints();
  const point = formatPrice({
    value: points.data?.points,
    locale: i18n.language,
    decimal: 2,
    forceDecimal: ForceDecimal.STRICT_DECIMAL,
  }).value;

  if (isRegisterPage) {
    return (
      <Hero>
        <Block row justify="between" items="center">
          <Text heading size="xl" weight="bold" my="md">
            {t('rewards.tracking-header')}
          </Text>
        </Block>
      </Hero>
    );
  }

  return (
    <>
      <Hero>
        <Block row justify="between" items="center">
          <Block>
            <Block>
              <Text>{t('rewards.total-points')}</Text>
              <Display show={points.isPending}>
                <Skeleton height="size-12" width="size-24" />
              </Display>
              <Display show={points.isSuccess}>
                <Text heading size="heading-4xl" weight="bold" loading={points.isPending}>
                  {point}
                </Text>
              </Display>
            </Block>

            <Block row gap="xl" mt="md">
              <RewardHistoryButton />
            </Block>
          </Block>
          <Hidden show={{ xs: false, sm: true }}>
            <IconCustom type="bilira-sphere" />
          </Hidden>
        </Block>
      </Hero>
    </>
  );
};

export default Header;

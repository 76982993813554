import React, { ReactElement } from 'react';

import { Block, Button, Display, Flex, IconCustomProps, IconCustomType } from '@bilira-org/design';
import { GapSizes, MarginSizes, PaddingSizes } from '@bilira-org/design/types';

import { IconCustom } from '@Components/icon/CustomIcon';

type Props = {
  /** Function to be called when accept button is clicked. */
  onAccept?: () => void;
  /** Function to be called when cancel button is clicked. */
  onCancel?: () => void;
  /** Content to be displayed */
  children: ReactElement | ReactElement[];
  /** Text of the cancel button */
  cancelText?: string;
  /** Text of the confirm button */
  confirmText?: string;
  /** Whether confirm button is loading */
  confirmLoading?: boolean;
  /** Icon to be displayed */
  icon?: IconCustomType;
  /** Color of the icon */
  iconColor?: IconCustomProps['color'];
  /** Gap size between items */
  gap?: GapSizes;
  /** Margin top size */
  mt?: MarginSizes;
  /** Padding top size */
  pt?: PaddingSizes;
};

/**
 * Component for rendering a warning message with customizable actions
 */
function WarningTemplate({
  onCancel,
  onAccept,
  children,
  cancelText,
  confirmLoading,
  confirmText,
  icon = 'alert-shield',
  iconColor,
  gap = 'lg',
  mt = '2xl',
  pt,
}: Props) {
  return (
    <>
      <Block gap={gap} items="center" mt={mt} pt={pt}>
        <IconCustom type={icon} color={iconColor} />
        {children}
        <Display show={cancelText || confirmText}>
          <Flex direction={{ xs: 'col', sm: 'row' }} gap="sm" width="full" mt="2xl">
            {cancelText && (
              <Flex.Item width={{ xs: 'full', sm: '2/4' }}>
                <Button size="xl" stretch variant="outline" onClick={onCancel}>
                  {cancelText}
                </Button>
              </Flex.Item>
            )}
            {confirmText && (
              <Flex.Item width={{ xs: 'full', sm: cancelText ? '2/4' : 'full' }}>
                <Button size="xl" stretch variant="filled" onClick={onAccept} loading={confirmLoading}>
                  {confirmText}
                </Button>
              </Flex.Item>
            )}
          </Flex>
        </Display>
      </Block>
    </>
  );
}

export default WarningTemplate;

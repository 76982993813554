import React from 'react';

import { BaseComponentType } from '../../types';

interface FormFieldErrorsProps extends BaseComponentType {
  /** Array of errors to display */
  errors?: string[];
}

/**
 * Displays errors on the form field
 *
 *
 * @example
 * <FormFieldErrors errors={['This is an error']} />
 */
const FormFieldErrors = ({ errors, testId }: FormFieldErrorsProps) => {
  return (
    <ul className="form-errors" data-testid={testId ?? 'form-field-errors'}>
      {errors?.map((error: React.ReactNode) => (
        <li key={`input-error-${error}`} className="text-red-500">
          {error}
        </li>
      ))}
    </ul>
  );
};

export default FormFieldErrors;

import React from 'react';

import { Skeleton, Text } from '@bilira-org/design';
import { formatDefaultAllowanceFields } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import useGetDefaultAllowance from '@/libs/hooks/useGetDefaultAllowance';

type Props = {
  kycLevel: number;
};

const DefaultAllowance = ({ kycLevel }: Props) => {
  const { t, i18n } = useTranslation();

  const { defaultAllowance, isPending: isDefaultAllowancesLoading } = useGetDefaultAllowance({
    kycLevel: kycLevel,
    domain: 'fiat',
  });

  const { dailyWithdrawLimit, monthlyWithdrawLimit } = formatDefaultAllowanceFields(defaultAllowance, i18n.language);

  return (
    <Text
      loading={isDefaultAllowancesLoading}
      skeleton={<Skeleton width="size-80" height="size-6" />}
      size="base"
      weight="regular"
    >
      {t('account.id-verification.kyc-level-limit-info', {
        'daily-limit': dailyWithdrawLimit,
        'monthly-limit': monthlyWithdrawLimit,
      })}
    </Text>
  );
};

export default DefaultAllowance;

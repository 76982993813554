/**
 * Pads a number with leading zeros to ensure it has at least 2 digits.
 *
 * @param {number} num - The number to pad.
 * @returns {string} The padded number as a string.
 *
 * @example
 * // Example usage of padTo2Digits function
 * const paddedNumber = padTo2Digits(5);
 * // Result: '05'
 */
export function padTo2Digits(num: number): string {
  return num.toString().padStart(2, '0');
}

/**
 * Converts milliseconds to a formatted string representation of minutes and seconds (MM:SS).
 *
 * @param {number} milliseconds - The duration in milliseconds.
 * @returns {string} The formatted string representation of minutes and seconds.
 *
 * @example
 * convertMsToMinutes(125000); // Result: '02:05'
 */
export function convertMsToMinutes(milliseconds: number): string {
  let seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);

  seconds = seconds % 60;

  return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
}

import { memo, useEffect } from 'react';

import { AssetPairType, concatTicker, CryptoTimeScale, parseAssetPair } from '@bilira-org/react-utils';
import { useParams } from 'react-router-dom';

import useSubscriptionHandler from '@Libs/hooks/useSubscriptionHandler';
import { timeScaleConfiguration } from '@Modules/market/detail/components/priceChart/helpers/scaleConfiguration';

type Props = {
  timeScale: CryptoTimeScale;
};

const ChartPriceSubscription = ({ timeScale }: Props) => {
  const { subscribe, unsubscribe } = useSubscriptionHandler('ohlc_channel');
  const scaleConf = timeScaleConfiguration[timeScale];

  const { assetPair } = useParams<{ assetPair: AssetPairType | undefined }>();
  const [base, quote] = parseAssetPair(assetPair);

  useEffect(() => {
    unsubscribe([concatTicker(base, quote)], { type: 'ohlc_close' });
    subscribe([concatTicker(base, quote)], { type: 'ohlc_close', params: { ohlc_period: scaleConf.scale } });

    return () => {
      unsubscribe([concatTicker(base, quote)], { type: 'ohlc_close' });
    };
  }, [scaleConf.scale]);

  useEffect(() => {
    if (assetPair) {
      subscribe([concatTicker(base, quote)], { type: 'last_price' });
      subscribe([concatTicker(base, quote)], { type: 'symbol_statistics' });
    }

    return () => {
      if (assetPair) {
        unsubscribe([concatTicker(base, quote)], { type: 'last_price' });
        unsubscribe([concatTicker(base, quote)], { type: 'symbol_statistics' });
      }
    };
  }, [assetPair]);

  return null;
};

export default memo(ChartPriceSubscription);

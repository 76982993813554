import { compareCurrencies } from '../currency';

import { SortOrder, SortOrderType } from '.';

/**
 * Compares two numeric strings according to the specified order.
 *
 * @param {string | number} [a='0'] - The first numeric string to compare. Defaults to '0'.
 * @param {string | number} [b='0'] - The second numeric string to compare. Defaults to '0'.
 * @param {SortOrderType} order - The order in which to sort (Use SortOrder enum for values).
 * @returns {number} Returns -1 if `a` should come before `b`, a 1 if `a` should come after `b`, and 0 if they are equal.
 *
 * *  Returns |                                                               |
 * :-------:|:--------------------------------------------------------------|
 *     1 if order is ascending, -1 if order is descending  | If the value of number 'a' is greater than the value of number 'b'
 *    -1 if order is ascending, 1 if order is descending   | If the value of number 'a' is less than the value of number 'b'
 *     0                                                   | If number 'a' and number 'b' has the same value
 * @example
 * compareNumbers('100', '200', SortOrder.ASC); // -1
 * compareNumbers('200', '50', SortOrder.ASC); // -1
 * compareNumbers('200', undefined, SortOrder.ASC); // 1
 * compareNumbers('200', '200', SortOrder.ASC); // 0
 *
 * compareNumbers('100', '200', SortOrder.DESC); // 1
 * compareNumbers('200', '50', SortOrder.DESC); // 1
 * compareNumbers('200', undefined, SortOrder.DESC); // -1
 * compareNumbers('200', '200', SortOrder.DESC); // 0
 */
export const compareNumbers = (a: string | number = '0', b: string | number = '0', order: SortOrderType) => {
  const compare = compareCurrencies(a, b) as number;
  return order === SortOrder.ASC ? compare : -compare;
};

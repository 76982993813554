class Gallery {
  /** The current index of the gallery. */
  index: number;
  /** The root HTML element of the gallery. */
  rootEl: HTMLDivElement;
  /** The limit of the gallery, indicating the start and the end indices */
  limit: { start: number; end: number };
  /** The platform HTML element of the gallery. */
  private platform: HTMLDivElement;
  /** The frames within the gallery. */
  private frames: NodeListOf<HTMLDivElement>;
  /** The content area of the gallery. */
  private contentArea: HTMLDivElement;
  /** The placeholder HTML element of the gallery. */
  private placeholder: HTMLDivElement;
  /** The width of the gallery. */
  private width: number;
  /** The height of the gallery. */
  private height: number;

  constructor(
    rootEl: HTMLDivElement,
    platform: HTMLDivElement,
    frames: NodeListOf<HTMLDivElement>,
    contentArea: HTMLDivElement,
    placeholder: HTMLDivElement,
  ) {
    this.index = 0;
    this.rootEl = rootEl;
    this.platform = platform;
    this.frames = frames;

    /** Sets initial height and width of the gallery */
    this.contentArea = contentArea;
    this.width = this.contentArea.getBoundingClientRect().width;
    this.height = this.contentArea.getBoundingClientRect().height;

    this.placeholder = placeholder;
    this.limit = { start: 0, end: this.frames.length - 1 };

    this.frames.forEach((each) => {
      each.style.width = `${this.width}px`;
    });

    this.goto(this.index);

    /** Attaches an observer to listen resize changes and update gallery size */
    const observerCallback: ResizeObserverCallback = (entries: ResizeObserverEntry[]) => {
      window.requestAnimationFrame((): void | undefined => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        this.width = this.contentArea.getBoundingClientRect().width;
        this.height = this.contentArea.getBoundingClientRect().height;
        this.frames.forEach((each) => {
          each.style.width = `${this.width}px`;
          this.platform.style.right = `${this.width * this.index}px`;
          this.placeholder.style.height = `${this.height}px`;
        });
      });
    };

    new ResizeObserver(observerCallback).observe(rootEl);
  }

  /** Moves the gallery to the next frame. */
  public next(): void {
    this.platform.style.right = `${this.width * ++this.index}px`;
  }

  /** Moves the gallery to the previous frame. */
  public prev(): void {
    this.platform.style.right = `${this.width * --this.index}px`;
  }

  /** Moves the gallery to the specified frame. */
  public goto(index: number): void {
    this.platform.style.right = `${this.width * index}px`;
    this.index = index;
  }
}

export default Gallery;

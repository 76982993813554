import React from 'react';

import { Block, Icon, Text, Tooltip } from '@bilira-org/design';
import { BreakpointType, FontWeight, JustifyType, TextColorType, TextSizes } from '@bilira-org/design/types';
import { AssetPairPriceType, getAssetUnitPriceInfo, isEmpty } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

export type AssetUnitPriceInfoProps = {
  /** Alignment of the text */
  justify?: JustifyType;
  /** Font weight of the text */
  weight?: FontWeight;
  /** Color of the text */
  color?: TextColorType;
  /** Color of the tooltip icon */
  iconColor?: TextColorType;
  /** Type of the tooltip icon */
  iconType?: 'o:question-mark-circle' | 'o:exclaimation-circle';
  /** Asset pair price data */
  assetPairPrice?: AssetPairPriceType;
  /** Default text to be displayed when data is empty */
  defaultText?: string;
  /** Amount used to calculate asset price  */
  amount?: string;
  /** Flag to hide tooltip */
  disableTooltip?: boolean;
  /** Symbol used for equality indication */
  equalitySymbol?: string;
  /** Size of the text */
  size?: TextSizes | BreakpointType<TextSizes>;
};

/**
 * Component for displaying asset unit price information.
 *
 * This component calculates and displays the unit price of an asset based on a given amount and asset pair price.
 * It optionally allows toggling between the base and quote assets of the asset pair.
 */
function AssetUnitPriceInfo({
  weight,
  justify,
  assetPairPrice,
  amount = '1',
  defaultText = '-',
  color = 'neutral-800',
  iconColor = 'neutral-700',
  iconType = 'o:question-mark-circle',
  disableTooltip = false,
  equalitySymbol = '≈',
  size = 'sm',
}: AssetUnitPriceInfoProps) {
  const { t, i18n } = useTranslation();

  if (isEmpty(assetPairPrice?.price)) {
    return (
      <Text size={size} weight={weight}>
        {defaultText}
      </Text>
    );
  }

  return (
    <>
      <Block row justify={justify} items="center">
        {!disableTooltip && (
          <Tooltip width="size-max" placement="top" message={t('market.swap.approximate-price-info')} position="left">
            <Icon color={iconColor} type={iconType} size="sm" />
          </Tooltip>
        )}
        <Text size={size} weight={weight} color={color}>
          {getAssetUnitPriceInfo({
            amount,
            assetPairPrice: assetPairPrice as AssetPairPriceType,
            equalitySymbol,
            locale: i18n.language,
          })}
        </Text>
      </Block>
    </>
  );
}

export default AssetUnitPriceInfo;

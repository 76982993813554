import React, { useMemo } from 'react';

import { Menu, MenuItemProps } from '@bilira-org/design';

const HeaderNavBarSocial = () => {
  const data = useMemo<MenuItemProps[]>(
    () => [
      { href: 'https://x.com/BiLira_Kripto', text: 'X / Twitter', target: '_blank' },
      { href: 'https://t.me/BiLira_Official', text: 'Telegram', target: '_blank' },
    ],
    [],
  );

  return <Menu vertical data={data} />;
};

export default HeaderNavBarSocial;

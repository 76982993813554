import { useEffect } from 'react';

import { PriceFeedData } from '@bilira-org/react-utils';
import { create } from 'zustand';

import { socketClient } from '../ws';

interface PriceFeedState {
  subscriptions: Record<string, number>;
  priceData: Record<string, PriceFeedData>;
  subscribe: (symbol: string) => void;
  unsubscribe: (symbol: string) => void;
  getPriceData: (symbol: string) => PriceFeedData | null;
}

// Subscription manager using Zustand
const usePriceFeedStore = create<PriceFeedState>((set, get) => ({
  subscriptions: {},
  priceData: {},

  subscribe: (symbol: string) => {
    const { subscriptions } = get();
    if (!subscriptions[symbol]) {
      subscriptions[symbol] = 0;
      socketClient.emit('subscribePriceFeed', { symbol: symbol });
      socketClient.registerEvents({
        event: 'price',
        onSuccess: (msg: PriceFeedData) => {
          if (msg.symbol in get().subscriptions) {
            set((state) => ({
              priceData: { ...state.priceData, [msg.symbol]: msg },
            }));
          }
        },
      });
    }
    subscriptions[symbol]++;
    set({ subscriptions });
  },

  unsubscribe: (symbol: string) => {
    const { subscriptions } = get();
    if (subscriptions[symbol]) {
      subscriptions[symbol]--;
      if (subscriptions[symbol] === 0) {
        socketClient.emit('unsubscribePriceFeed', { symbol: symbol });
        const { [symbol]: count, ...remaining } = subscriptions;
        set({ subscriptions: remaining });
      } else {
        set({ subscriptions });
      }
    }
  },

  getPriceData: (symbol: string) => get().priceData[symbol] || undefined,
}));

/**
 * Custom hook to manage subscription to PriceFeed socket and retrieve ask/bid price data for a given symbol.
 *
 * This hook subscribes to a PriceFeed for the provided symbol and updates the price data
 * in the Zustand store. When the component using this hook unmounts or the symbol changes,
 * it unsubscribes from the PriceFeed.
 *
 * @param {string} symbol - The symbol to subscribe to the PriceFeed.
 * @returns {PriceFeedData | undefined} - The current ask/bid price data for the given symbol, or undefined if not available.
 *
 * @example
 * const priceData = usePriceFeed('USDT_TRYB');
 * // { symbol: 'USDT_TRYB', asks:[{price: '1', quantity:'2'}, ...], bids:[{price: '1', quantity:'2'}, ...]}
 */
export function usePriceFeed(symbol: string) {
  const { getPriceData, unsubscribe, subscribe } = usePriceFeedStore();

  useEffect(() => {
    subscribe(symbol);

    return () => {
      unsubscribe(symbol);
    };
  }, [symbol, subscribe, unsubscribe]);

  return getPriceData(symbol);
}

import React, { memo, useState } from 'react';

import { Alert, Block, Breadcrumbs, Icon, Label, Panel, Text } from '@bilira-org/design';
import { CryptoAssetsType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import NetworkModal from '@Components/cryptoCards/NetworkModal';
import { useWalletStore } from '@Components/walletAddress/components/store/walletStore';

type Props = {
  filter?: string[];
  isNetworkDisabled?: boolean;
  activeAsset?: CryptoAssetsType;
  disabled?: boolean;
};
const SelectNetworkStep = ({ filter, isNetworkDisabled, activeAsset, disabled }: Props) => {
  const [networkModalActive, setNetworkModalActive] = useState(false);
  const { t } = useTranslation();
  const { selectedNetwork } = useWalletStore();

  const onClickProp = filter && !disabled ? { onClick: () => setNetworkModalActive(true) } : {};
  return (
    <>
      <Label>{t('crypto.select-network')}</Label>
      <Panel mb="lg" size="md" ring="neutral-400" color={disabled ? 'neutral-200' : undefined} {...onClickProp}>
        <Block row gap="md" items="center" justify="between">
          {selectedNetwork && (
            <Breadcrumbs listType="disc">
              <Breadcrumbs.Item>
                <Text size="sm" color="secondary-500" weight="semibold">
                  {selectedNetwork.name}
                </Text>
              </Breadcrumbs.Item>
              <Breadcrumbs.Item>
                <Text size="sm" weight="regular">
                  {selectedNetwork.alias}
                </Text>
              </Breadcrumbs.Item>
            </Breadcrumbs>
          )}
          {!selectedNetwork && (
            <Text size="sm" color="neutral-500">
              {t('crypto.withdraw.select-network')}
            </Text>
          )}

          <Icon size="lg" color={disabled ? 'neutral-500' : 'neutral-600'} type="s:chevron-down" />
        </Block>
      </Panel>

      {isNetworkDisabled && (
        <Alert status="red" mb="lg">
          <Text size="sm" color="secondary-500">
            {t('crypto.withdraw.withdraw-stopped-on-network-description', { asset: activeAsset?.name })}
          </Text>
        </Alert>
      )}

      <NetworkModal
        activeAsset={activeAsset?.symbol}
        filter={filter}
        setActive={setNetworkModalActive}
        active={networkModalActive}
      />
    </>
  );
};

export default memo(SelectNetworkStep);

import React, { ReactElement, useEffect } from 'react';

import {
  Badge,
  Block,
  Button,
  Dropdown,
  Icon,
  IconCustom,
  List,
  Meta,
  Modal,
  Popover,
  Separator,
  Skeleton,
  Text,
} from '@bilira-org/design';
import { IdType } from '@bilira-org/react-utils';
import { KeyValuePairType } from '@bilira-org/react-utils/src/model/Preferences';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { LANGUAGES } from '@/libs/constants';
import LanguageModal from '@Components/settings/language/LanguageModal';
import { LanguageIcons } from '@Libs/constants/constantJsx';

type Props = {
  callback: (data: KeyValuePairType) => Promise<IdType>;
  value: string;
  loading?: boolean;
  isMutating?: boolean;
};

function Language({ value, callback, loading, isMutating }: Props) {
  const { i18n, t } = useTranslation();
  const [language, setLanguage] = React.useState(i18n.language === 'tr' ? 'Türkçe' : 'English');
  const [modalActive, setModalActive] = React.useState(false);

  useEffect(() => {
    const val = LANGUAGES.find((d) => d.key === value);
    if (val) {
      setLanguage(val.value);
    }
  }, [value]);

  if (loading) {
    return (
      <>
        <Block row gap="md" items="center" justify="between">
          <Text>{t('account.preferences.language-title')}</Text>
          <Skeleton height="size-12" width="size-40" rounded="xl" />
        </Block>
      </>
    );
  }

  return (
    <>
      <Block row gap="md" items="center" justify="between">
        <Text>{t('account.preferences.language-title')}</Text>
        <Button
          variant="outline"
          size="lg"
          startIcon={LanguageIcons[i18n.language as LanguageType]}
          onClick={() => setModalActive(true)}
        >
          {language}
        </Button>
      </Block>
      <LanguageModal
        modalActive={modalActive}
        setModalActive={setModalActive}
        callback={callback}
        isMutating={isMutating}
      />
    </>
  );
}

export default Language;

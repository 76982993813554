import React, { ReactNode } from 'react';

import { Block, CoinProps, Meta, Text, TextProps } from '@bilira-org/design';
import Flex from '@bilira-org/design/src/components/flex/flex';
import { adjustQuoteAsset, CoinSymbolType, NetworkAliasType } from '@bilira-org/react-utils';

import IconCoin from '@Components/icon/IconCoin';

type Props = {
  /** Icon to be displayed */
  icon?: CoinSymbolType | NetworkAliasType;
  /** Quote symbol*/
  quote?: CoinSymbolType;
  /** Title to be displayed */
  title: string;
  /** Description to be displayed */
  description: ReactNode;
  /** Size configuration for the BreadCrumb items */
  size?: {
    icon: CoinProps['size'];
    title?: TextProps['size'];
    description?: TextProps['size'];
    quote?: TextProps['size'];
  };
  /** data-testid for the component */
  testId?: string;
};

/**
 * Displays crypto symbol - name breadcrumbs with an optional icon
 *
 * <SymbolPairBreadcrumbs
 *   icon="BTC"
 *   title="BTC"
 *   description="Bitcoin"
 *   size={{ icon: '3xl', title: 'base', description: 'sm' }}
 * />
 */
const SymbolPair = ({
  size = { icon: '3xl', title: 'base', description: 'sm' },
  icon,
  title,
  quote,
  description,
}: Props) => {
  const quoteAsset = quote && adjustQuoteAsset(title, quote);

  const withQuote = (
    <Block row gap="xs" items="baseline">
      <Text size={size.title} color="secondary-500" weight="semibold">
        {title}
      </Text>
      <Text wordBreak="none" color="neutral-700" size={size.quote || size.description}>
        {`/ ${quoteAsset}`}
      </Text>
    </Block>
  );

  return (
    <Meta
      space="sm"
      extra={<IconCoin key={icon} size={size?.icon} type={icon} />}
      title={
        quote ? (
          withQuote
        ) : (
          <Text size={size.title} color="secondary-500" weight="semibold">
            {title}
          </Text>
        )
      }
      description={
        <Flex width={{ xs: '80', sm: 'full' }}>
          <Text size={size.description} weight="regular" align="left" truncate>
            {description}
          </Text>
        </Flex>
      }
    />
  );
};

export default SymbolPair;

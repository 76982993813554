import React from 'react';

import { clsx } from 'clsx';

import {
  BackgroundColorType,
  BaseComponentType,
  BaseSize,
  BorderColorType,
  BorderStyleType,
  BreakpointType,
  CustomWidthType,
  MarginSizes,
  MinWidthType,
  OutlineColorType,
  PaddingSizes,
  RadiusSizes,
  ResponsiveReturnType,
  SizeType,
} from '../types';
import { buildResponsiveClass } from '../utils';

export type PanelProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'color' | 'onClick'> &
  BaseComponentType & {
    /** Content of the panel */
    children: React.ReactNode;
    /** Additional content to be placed on the top-right corner of the panel */
    extra?: React.ReactNode;
    /** View style of the panel */
    view?: 'banner' | 'strip';
    /** Background color of the panel */
    color?: BackgroundColorType;
    /** Padding size of the panel */
    size?: PaddingSizes | BreakpointType<PaddingSizes>;
    /** Border radius of the panel */
    radius?: RadiusSizes;
    /** Border color of the panel */
    border?: BorderColorType;
    /** Ring color of the panel */
    ring?: BorderColorType;
    /** Drop shadow size of the panel */
    dropShadow?: BaseSize;
    /** Outline color of the panel */
    outline?: OutlineColorType;
    /** Whether to minimize the panel */
    minimize?: boolean;
    /** Hover effect of the panel */
    hover?: 'outline' | 'shadow' | 'background';
    /** Callback function when the panel is clicked */
    onClick?: () => void;
    /** Minimum width of the panel */
    minWidth?: MinWidthType;
    /** Minimum height of the panel */
    minHeight?: BaseSize;
    /** Width of the panel */
    width?: SizeType | null;
    /** Padding y-axis of the panel */
    py?: PaddingSizes;
    /** Padding x-axis of the panel */
    px?: PaddingSizes;
    /** Margin y-axis of the panel */
    my?: MarginSizes;
    /** Margin x-axis of the panel */
    mx?: MarginSizes;
    /** Margin top of the panel */
    mt?: MarginSizes;
    /** Margin bottom of the panel */
    mb?: MarginSizes;
    /** Border style of the panel */
    borderVariant?: BorderStyleType;
    /** Whether to use custom border */
    customBorder?: boolean;
    /** Custom width of the panel */
    customWidth?: CustomWidthType | BreakpointType<CustomWidthType>;
    /** Outline width */
    outlineWidth?: '1';
  };

/**
 * A versatile panel component with customization options.
 *
 * @example
 * <Panel border="neutral-400" ring="neutral-200" dropShadow="sm">
 *   <div>Panel content</div>
 * </Panel>
 */
const Panel: React.FC<PanelProps> = ({
  children,
  minimize,
  border,
  ring,
  dropShadow,
  borderVariant,
  outline,
  extra,
  view,
  className,
  color,
  hover,
  onClick,
  minWidth,
  minHeight,
  width = 'size-full',
  py,
  px,
  my,
  mx,
  mt,
  mb,
  size = '2xl',
  radius = 'xl',
  testId,
  customBorder = false,
  customWidth,
  outlineWidth,
  ...props
}: PanelProps) => {
  let customWidthClasses: ResponsiveReturnType = {};

  if (customWidth) {
    customWidthClasses = buildResponsiveClass(customWidth, 'tw-custom-width-');
  }

  let sizeClasses: ResponsiveReturnType = {};
  if (size) {
    sizeClasses = buildResponsiveClass(size, 'p-');
  }

  const classesContainer = clsx(
    'panel',
    {
      minimize: minimize,
      border: border,
      'custom-border': customBorder,
      'ring-1 ring-inset': ring,
      'cursor-pointer': onClick,
      [`tw-drop-shadow-${dropShadow}`]: dropShadow,
      [`py-${py}`]: py,
      [`px-${px}`]: px,
      [`my-${my}`]: my,
      [`mx-${mx}`]: mx,
      [`mt-${mt}`]: mt,
      [`mb-${mb}`]: mb,
      [`tw-rounded-${radius}`]: radius,
      [`tw-min-w-${minWidth}`]: minWidth,
      [`tw-min-h-${minHeight}`]: minHeight,
      [`tw-w-${width}`]: width,
      [`view-${view}`]: view,
      [`tw-bg-${color}`]: color,
      [`tw-outline-${outline}`]: outline,
      [`hover:panel-${hover}`]: hover,
      [`tw-ring-${ring}`]: ring,
      [`tw-border-${border}`]: border,
      [`tw-border-${borderVariant}`]: borderVariant,
      [`outline-${outlineWidth}`]: outlineWidth,
    },
    customWidthClasses,
    sizeClasses,
    className,
  );

  return (
    <section className={classesContainer} onClick={onClick} data-testid={testId} {...props}>
      {extra && <div className="absolute right-5 top-5 flex gap-1 z-[1]">{extra}</div>}
      {children}
    </section>
  );
};

/**
 * Flexible Panel component for layout purposes.
 *
 * @component
 *
 * @param {PanelProps} props - The properties for the Panel component.
 * @returns {React.ReactElement} The rendered Panel component.
 * @example
 * <Panel>
 *   <Text>Content goes here</Text>
 * </Panel>
 */
const StoryOnlyBlock = (props: PanelProps) => (
  <>
    <Panel {...props} />
  </>
);
StoryOnlyBlock.displayName = 'Panel';

export { StoryOnlyBlock };

export default Panel;

import { IIdp } from './interface';
import { HttpClient, HttpClientType, HttpErrorType } from '../../client';
import {
  Kyc2Body,
  Kyc2ResponsePayload,
  Kyc3Body,
  Kyc3ResponsePayload,
  OauthTokenResultType,
  OauthTokenType,
  PasswordBody,
  ResultType,
  UserActivity,
  UserInfo,
  UserKyc,
} from '../../model';

class IdpApi extends HttpClient implements IIdp {
  public constructor(conf: HttpClientType) {
    super(conf);
  }

  public postKyc2 = (payload: Kyc2Body) =>
    this.instance.post<HttpErrorType, Kyc2ResponsePayload>('/kyc2-verification', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

  public postKyc3 = (payload: Kyc3Body) =>
    this.instance.post<HttpErrorType, Kyc3ResponsePayload>('/kyc3-verification', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

  public getUserKyc = async () => await this.instance.get<HttpErrorType, UserKyc>('/user-kyc');
  //TODO: endpoint will be refactored to '/otp' soon. Then, add type.
  public getOtp = async () => await this.instance.get<HttpErrorType, any>('/enable-otp');

  public getUserActivity = async () => await this.instance.get<HttpErrorType, UserActivity[]>('/user-activity');
  public patchPassword = (body: PasswordBody) => {
    return this.instance.patch<HttpErrorType, ResultType, PasswordBody>(`/password`, body);
  };

  public getHealth = async () => await this.instance.get<HttpErrorType, any>('/healthz');

  public postToken = async (param: OauthTokenType) =>
    await this.instance.post<any, OauthTokenResultType>('/oauth/token', param);

  public getLogout = async () => await this.instance.get<HttpErrorType, any>('/logout');

  public getTokenRevoke = async () => await this.instance.get<HttpErrorType, any>('/oauth/token/revoke');

  public getUserInfo = async () => await this.instance.get<HttpErrorType, UserInfo>('/oauth/userinfo');
}

export default IdpApi;

import React from 'react';

import { Block, Divider, Panel, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import HeadTags from '@/components/common/HeadTags';
import ChangePassword from '@Modules/account/security/changePassword/ChangePassword';
import { DeleteAccount, UserActivityTable } from '@Modules/account/security/components';
import { Authenticator, ChangeEmail, PhoneNumber } from '@Modules/account/security/twoFA';

function Index() {
  const { t } = useTranslation();

  return (
    <>
      <HeadTags title={t('account.headers.security-settings')} titleSuffix="subpage" />
      <Block gap="4xl">
        <Panel border="neutral-400">
          <Text heading size="xl" weight="bold" mb="md">
            {t('account.security-settings.title')}
          </Text>
          <Divider color="neutral-400">
            <ChangePassword />
            <ChangeEmail />
            <PhoneNumber />
            <Authenticator />
          </Divider>
        </Panel>

        <Panel border="neutral-400">
          <UserActivityTable />
        </Panel>

        <Panel border="neutral-400">
          <DeleteAccount />
        </Panel>
      </Block>
    </>
  );
}

export default Index;

import { useEffect } from 'react';

import usePrefersDarkMode from './usePrefersDarkMode';
import useSafeLocalStorage from './useSafeLocalStorage';
import setThemeDom from '../helpers/dom/setThemeDom';

/**
 * Hook for controlling dark mode
 * @returns An array containing the current dark mode state and a function to set the dark mode state.
 */
function useDarkMode(): any[] {
  const prefersDarkMode = usePrefersDarkMode();

  const [isEnabled, setIsEnabled] = useSafeLocalStorage('theme', undefined);
  const isPreferenceEmpty = isEnabled === undefined || isEnabled === null;
  const enabled = isPreferenceEmpty ? prefersDarkMode : isEnabled;

  useEffect(() => {
    setThemeDom(enabled);
  }, [enabled]);

  return [enabled, setIsEnabled];
}

export default useDarkMode;

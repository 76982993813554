import React, { FC, HTMLAttributes, ReactNode } from 'react';

import { Control, FieldValues, Path, RegisterOptions, useController, UseControllerReturn } from 'react-hook-form';

import FormFieldTemplate from './template';
import { BaseComponentType } from '../../types';
import { useFormContext } from '../formContext';

export type ControlledFormFieldProps<T extends FieldValues> = {
  /* 	"control" object provided by invoking useForm */
  control?: Control<T>;
  /* Name of the form field */
  name: Path<T>;
  /* Label of the form field */
  label?: ReactNode;
  /* Passes children the object returned by invoking useController */
  children: FC<UseControllerReturn<T>>;
  /* Field validation rules */
  rules?: RegisterOptions;
  /* Hides the form field & spacings */
  hidden?: boolean;
  /* Hides the error list & spacings */
  hideErrorList?: boolean;
} & Omit<HTMLAttributes<HTMLDivElement>, 'children'> &
  BaseComponentType;

/**
 * A component that provides a controlled form field using 3rd party or custom input components.
 *
 * This component should not be used separately, use the FormField interface.
 * @example
 *
 * <FormField
 *  controlled
 *  label="username"
 *  name="username"
 *  options={{
 *    required: "Please input your username!",
 *  }}
 *>
 *  {(props) => (
 *    <Input
 *      value={props.field.value}
 *      onChange={(e) => props.field.onChange(e.target.value)}
 *    />
 *  )}
 *</FormField>
 */
const ControlledFormField = <T extends FieldValues>({
  name,
  label,
  children,
  rules,
  hidden,
  hideErrorList,
  testId,
  ...rest
}: ControlledFormFieldProps<T>) => {
  const form = useFormContext<T>();

  const controller = useController({
    control: form.control as Control,
    name,
    rules,
  });

  const { fieldState } = controller;

  let errorMessages = Object.values(fieldState.error?.types || {}).map((errorMessage) => errorMessage);
  errorMessages = errorMessages.length > 0 ? errorMessages : [fieldState.error?.message];

  return (
    <FormFieldTemplate
      name={name}
      label={label}
      errors={errorMessages as string[]}
      hidden={hidden}
      hideErrorList={hideErrorList}
      {...rest}
      testId={testId ?? 'controlled-form-field'}
    >
      {children(controller as UseControllerReturn<T>)}
    </FormFieldTemplate>
  );
};

ControlledFormField.display = 'ControlledFormField';

export default ControlledFormField;

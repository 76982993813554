import React, { useEffect, useMemo } from 'react';

import { Alert, Block, Button, Col, Form, Grid, Input, Label, Separator, Text } from '@bilira-org/design';
import {
  getErrorMessage,
  getTxLimits,
  httpError,
  isNumberInRange,
  validateWithdrawLimits,
} from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import Limits from '@/components/common/Limits';
import WithdrawAllButton from '@/components/common/WithdrawAllButton';
import FormattedInputNumber from '@Components/common/FormattedInputNumber';
import FreeBalanceLabel from '@Components/common/FreeBalanceLabel';
import TwoFAModal from '@Components/TwoFA/TwoFAModal';
import accountQuery from '@Libs/clientInstances/accountQuery';
import allowancesQuery from '@Libs/clientInstances/allowancesQuery';
import useAuth from '@Libs/hooks/userAuth';
import WithdrawalFee from '@Modules/fiat/withdraw/components/withdrawForm/components/WithdrawalFee';

import useWithdrawForm, { IAmount } from './useWithdrawForm';
import WarningMessages from '../components/WarningMessages';

type WithdrawFormProps = {
  bankAccount?: string;
  onLoaded: () => void;
};

const WithdrawForm = ({ bankAccount, onLoaded }: WithdrawFormProps) => {
  const { account } = useAuth();
  const { t, i18n } = useTranslation();
  const { data: allowance, isPending: isAllowancesPending } = allowancesQuery.useGetNextTransaction({
    account,
    domain: 'fiat',
    direction: 'withdrawal',
  });
  const { data: balances, isPending: isBalancesPending } = accountQuery.useGetBalances(account, undefined);
  const cryptoBalance = useMemo(() => balances?.find((d) => d.asset === 'TRYB'), [balances]);

  const { txMax, formattedTxMax, txMin, formattedTxMin } = getTxLimits({
    allowanceMax: allowance?.max,
    allowanceMin: allowance?.min,
    freeBalance: cryptoBalance?.free_balance || '0',
    locale: i18n.language,
  });

  const { isPending, error, onSubmit, form, twoFAData, activeTwoFAModal, setActiveTwoFAModal, onVerifyTwoFA } =
    useWithdrawForm({
      bankAccount,
    });

  const { error: txLimitError, isInputDisabled } = useMemo(() => {
    const { error } = validateWithdrawLimits({
      txMin: txMin.toString(),
      txMax: txMax.toString(),
      freeBalance: cryptoBalance?.free_balance,
      errorMessages: {
        hasLessBalanceThanMinimum: t('common.validation.less-balance-than-minimum-tx'),
        hasLessTxLimitThanMinimum: t('common.validation.less-tx-limit-than-minimum-tx'),
      },
    });
    return { error, isInputDisabled: !!error };
  }, [cryptoBalance?.free_balance, t, txMax, txMin]);

  const errorMessage = !httpError(error).is429 ? getErrorMessage(error) : undefined;
  const isQueriesPending = isAllowancesPending || isBalancesPending;

  useEffect(() => {
    if (!isQueriesPending) {
      onLoaded();
    }
  }, [isQueriesPending]);

  if (isQueriesPending) {
    return null;
  }

  return (
    <>
      <Form<IAmount> form={form} onFinish={onSubmit}>
        <Grid style={{ marginRight: '5px' }}>
          {errorMessage && (
            <Col>
              <Alert status="red">{errorMessage}</Alert>
            </Col>
          )}
          {txLimitError && !error && (
            <Col>
              <Alert status="red">{txLimitError}</Alert>
            </Col>
          )}
          <Col>
            <Form.Field name="bank" hidden>
              <Input type="hidden" />
            </Form.Field>
            <Form.Field
              controlled
              name="amount"
              label={
                <Block row justify="between">
                  <Label>{t('common.amount')}</Label>
                  <FreeBalanceLabel
                    labelTextProps={{ size: 'sm', weight: 'regular' }}
                    numberTextProps={{ size: 'sm', weight: 'regular' }}
                    label={t('common.free-balance')}
                    symbol="TRYB"
                  />
                </Block>
              }
              rules={{
                validate: (value) => {
                  const errorMessage = t('common.validation.min-max', {
                    'field-name': 'Limit',
                    min: `${formattedTxMin}`,
                    max: `${formattedTxMax}₺`,
                  });

                  const isValueEmpty = value === undefined || value === '';
                  const isValueInRange = !isValueEmpty && isNumberInRange(value, txMin.toString(), txMax.toString());

                  if (isValueEmpty || !isValueInRange) {
                    return errorMessage;
                  }

                  return true;
                },
              }}
            >
              {({ field, fieldState }) => (
                <FormattedInputNumber
                  value={field.value || ''}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  disabled={isInputDisabled}
                  placeholder={t('fiat.withdraw-amount-placeholder', {
                    'min-amount': `${formattedTxMin} TRYB`,
                  })}
                  decimalPlaces={2}
                  iconEnd={
                    <Block row gap="sm" items="center">
                      <WithdrawAllButton
                        disabled={isInputDisabled}
                        decimalPlaces={2}
                        symbol="TRYB"
                        onClick={(amount) =>
                          form.setValue('amount', amount, {
                            shouldDirty: true,
                            shouldValidate: true,
                            shouldTouch: true,
                          })
                        }
                      />
                      <Separator height="size-6" />
                      <Text size="sm" color={isInputDisabled ? 'neutral-500' : 'neutral-700'}>
                        TRYB
                      </Text>
                    </Block>
                  }
                  invalid={fieldState.error !== undefined}
                />
              )}
            </Form.Field>
          </Col>
          <Col>
            <Separator width="size-full" my="md" />
          </Col>
          <Col>
            <Limits domain="fiat" direction="withdrawal" />
          </Col>
          <Col>
            <Separator width="size-full" my="md" />
          </Col>
          <Col>
            <WithdrawalFee amount={form.watch('amount')} />
          </Col>
          <Col>
            <Button
              variant="filled"
              size="xl"
              mt="2xl"
              type="submit"
              justify="center"
              stretch
              loading={isPending}
              disabled={!form.formState.isValid}
            >
              {t('common.accept')}
            </Button>
          </Col>
        </Grid>
      </Form>
      <TwoFAModal
        type="fiatWithdrawal"
        twoFAData={twoFAData}
        active={activeTwoFAModal}
        setActive={setActiveTwoFAModal}
        callback={onVerifyTwoFA}
      />

      <WarningMessages />
    </>
  );
};

export default WithdrawForm;

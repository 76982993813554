import React, { ComponentProps } from 'react';

import ReactJoyride, { CallBackProps, Step } from 'react-joyride';

import Tooltip from './Tooltip';
import { BaseComponentType } from '../types';

export type TourProps = ComponentProps<typeof ReactJoyride> &
  BaseComponentType & {
    /** The text to display on the "Finish" button in the tour. */
    finishText?: string;
    /** The text to display on the "Next" button in the tour. */
    nextText?: string;
    /** The text to display on the "Back" button in the tour. */
    backText?: string;
  };

/**
 * Tour component that provides a guided tour experience using React Joyride.
 *
 *  @example
 * // Example usage of Tour component
 * <Tour
 *   steps={[
 *     { content: 'Step 1', target: '#step1', placement: 'bottom' },
 *     { content: 'Step 2', target: '#step2', placement: 'top' },
 *     { content: 'Step 3', target: '#step3', placement: 'left' },
 *   ]}
 *   finishText="Done"
 *   nextText="Next"
 *   backText="Back"
 * />
 */
const Tour = ({ finishText, nextText, backText, testId, ...rest }: TourProps) => {
  return (
    <ReactJoyride
      tooltipComponent={(tooltipProps) => (
        <Tooltip {...tooltipProps} finishText={finishText} nextText={nextText} backText={backText} />
      )}
      styles={{ options: { arrowColor: 'var(--color-theme-wn)' } }}
      data-testid={testId ?? 'tour'}
      {...rest}
    />
  );
};

Tour.displayName = 'Tour';

export type { Step as TourStepType, CallBackProps as TourCallBackProps };
export default Tour;

import { Flex } from '../../flex';
import { BreakpointType, DirectionType, MarginSizes, PaddingSizes } from '../../types';
import { Text } from '../../typography';
import { getHiddenClass } from '../helpers';

interface Props {
  /**
   * The label to display alongside the value.
   */
  label: React.ReactNode;

  /**
   * The value to display next to the label.
   */
  value: React.ReactNode;

  /**
   * Optional flag or breakpoint settings to control visibility.
   * - `true` shows the field; `false` hides it.
   * - Breakpoint object can define visibility for different screen sizes.
   */
  show?: boolean | BreakpointType<boolean>;

  /**
   * Optional direction for the flex container.
   * - `row` for horizontal layout.
   * - `column` for vertical layout.
   */
  direction?: DirectionType;

  /**
   * Optional padding on the x-axis.
   */
  px?: PaddingSizes;

  /**
   * Optional padding on the y-axis.
   */
  py?: PaddingSizes;

  /**
   * Optional margin on the top.
   */
  mt?: MarginSizes;
}

/**
 * A component for displaying a label and value pair in a flexible layout.
 */
function DataGridField({ label, value, show = true, direction = 'row', px, py = 'xs', mt }: Props) {
  return (
    <Flex direction={direction} justify="between" px={px} py={py} mt={mt} className={getHiddenClass(show, 'flex')}>
      <Text color="neutral-600" size="sm" weight="medium">
        {label}
      </Text>

      {value}
    </Flex>
  );
}

export default DataGridField;

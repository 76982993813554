import { isInternalTransferType, TransactionsRecordType, TransactionType } from '@bilira-org/react-utils';

import AccountApi from '@Libs/clientInstances/accountQuery';
import useAuth from '@Libs/hooks/userAuth';

export type TransactionRecordInfo = {
  id: string;
  type: string;
};

type Props = {
  transactionRecordInfo?: TransactionRecordInfo;
};

const useGetTransactionDetail = ({ transactionRecordInfo }: Props) => {
  const { account, isPending: isPendingAuth } = useAuth();

  const isInternalTransfer = isInternalTransferType(transactionRecordInfo?.type);

  const { data, isPending: isPendingTransaction } = AccountApi.useTransaction(
    {
      id: account,
      transactionId: transactionRecordInfo?.id || '',
      type: transactionRecordInfo?.type as TransactionType,
    },
    {
      enabled: !isPendingAuth && !isInternalTransfer,
    },
  );

  const { data: internalTransferData, isPending: isPendingInternalTransfer } = AccountApi.useGetInternalTransfer(
    {
      id: account,
      transactionId: transactionRecordInfo?.id || '',
    },
    {
      enabled: !isPendingAuth && isInternalTransfer,
    },
  );

  return isInternalTransfer
    ? {
        data: internalTransferData ? ({ ...internalTransferData } as TransactionsRecordType) : undefined,
        isPending: isPendingAuth || isPendingInternalTransfer,
      }
    : { data, isPending: isPendingAuth || isPendingTransaction };
};

export default useGetTransactionDetail;

import { ReactNode, useState } from 'react';

import { LanguageUniversalType, useStateEffect } from '@bilira-org/react-utils';
import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/tr';

dayjs.extend(localeData);
dayjs.extend(isBetween);

import Calendar, { CalendarType } from './components/calendar';
import { getInitialCalendarDates } from './helpers';
import { Block } from '../block';
import { Dropdown } from '../dropdown';
import { DropdownProps } from '../dropdown/dropdown';
import { Hidden } from '../hidden';
import { BaseComponentType } from '../types';
import { Text, Truncate } from '../typography';

export type SelectedDatesType = [Dayjs, Dayjs];
export type Props = {
  /** Selected dates */
  value?: SelectedDatesType;
  /** Callback to be called when a date is selected */
  onChange?: (dates: SelectedDatesType) => void;
  /** Locale of the date picker */
  locale?: LanguageUniversalType;
  /** Size of the date picker */
  size?: DropdownProps<any>['size'];
  /** Width of the date picker */
  width?: DropdownProps<any>['width'];
  /** Justify content of the dropdown button */
  justify?: DropdownProps<any>['justify'];
  /** Placeholder of the date picker */
  placeholder?: string;
  /** To pass additional dropdown items */
  children?: ReactNode;
  /** Label to override dropdown text, if undefined */
  label?: ReactNode;
  /** Custom date picker option label */
  datePickerLabel?: ReactNode;
  /** Date range limit */
  limit?: [number, ManipulateType];
} & BaseComponentType;

/**
 * Component for picking date ranges
 */
const DateRangePicker = ({
  value,
  onChange,
  locale = 'en',
  size = 'lg',
  width = '192',
  justify = 'between',
  placeholder,
  testId = 'date-range-picker',
  children,
  label,
  datePickerLabel = 'Custom Date Range',
  limit,
}: Props) => {
  const [innerState, setInnerState] = useStateEffect<SelectedDatesType>(
    value?.[0] && value[1] ? value : ([undefined, undefined] as unknown as SelectedDatesType),
    [value],
  );
  const [selectedDatesState, setSelectedDates] = useState<SelectedDatesType>([
    undefined,
    undefined,
  ] as unknown as SelectedDatesType);
  const selectedDates = value || selectedDatesState;
  const [calendarDates, setCalendarDates] = useState<SelectedDatesType>(getInitialCalendarDates(innerState));
  const [highlightedDates, setHighlightedDates] = useState<SelectedDatesType | undefined>(undefined);

  const onDateSelect = (date: Dayjs, closeCallback: () => void) => {
    let newSelectedDates;

    if (!innerState[0]) {
      newSelectedDates = [date, undefined];
    } else {
      const [startDate, endDate] = innerState;

      if (date.isSame(endDate)) {
        newSelectedDates = [startDate, undefined];
      } else if (date.isSame(startDate)) {
        newSelectedDates = [endDate, undefined];
      } else if (date.isAfter(startDate)) {
        newSelectedDates = [startDate, date];
      } else {
        newSelectedDates = [date, startDate];
      }
    }
    if (newSelectedDates[1]) {
      setSelectedDates(newSelectedDates as SelectedDatesType);
      onChange?.(newSelectedDates as SelectedDatesType);
      closeCallback();
    }
    setInnerState(newSelectedDates as SelectedDatesType);
  };

  const onDateHover = (date?: Dayjs) => {
    let highlightedDates;
    if (!date) {
      setHighlightedDates(undefined);
      return;
    }
    if (!innerState[0]) {
      highlightedDates = [date, undefined];
    } else {
      const [startDate] = innerState;

      if (date.isAfter(startDate)) {
        highlightedDates = [startDate, date];
      } else if (date.isBefore(startDate)) {
        highlightedDates = [date, startDate];
      }
    }

    setHighlightedDates(highlightedDates as SelectedDatesType);
  };

  const dateRangeStringified =
    innerState[0] || innerState[1]
      ? `${innerState[0]?.locale(locale).format('DD MMMM YYYY') || '-'}/${innerState[1]?.locale(locale).format('DD MMMM YYYY') || '-'}`
      : undefined;

  const handleCalendarDateChange = (date: Dayjs, calendarDirection: 'left' | 'right') => {
    let [startDate, endDate] = calendarDirection === 'left' ? [date, calendarDates[1]] : [calendarDates[0], date];
    if (startDate.isSame(endDate) || startDate.isAfter(endDate)) {
      if (calendarDirection === 'left') {
        endDate = startDate.add(1, 'month');
      } else {
        startDate = endDate.subtract(1, 'month');
      }
    }
    setCalendarDates([startDate, endDate]);
  };

  const [calendarType, setCalendarType] = useState<CalendarType>('day');
  const [calendarDirection, setCalendarDirection] = useState<'left' | 'right'>('left');
  const [isCustomPickerSelected, setIsCustomPickerSelected] = useState(!children);
  return (
    <Dropdown
      value={
        <Text color="secondary-500" size="sm" weight="semibold" truncate={Truncate.TRUNCATE_ONLY}>
          {label || dateRangeStringified || placeholder}
        </Text>
      }
      listSize={isCustomPickerSelected ? 'size-max' : width}
      size={size}
      width={width}
      justify={justify}
      onClose={() => {
        setCalendarType('day');
        setCalendarDates(getInitialCalendarDates(innerState));
        setIsCustomPickerSelected(!children);
        setInnerState(
          selectedDates[0] && selectedDates[1]
            ? selectedDates
            : ([undefined, undefined] as unknown as SelectedDatesType),
        );
      }}
      testId={testId}
      limitListHeight={false}
    >
      {(close) => {
        if (!isCustomPickerSelected && children) {
          return (
            <>
              {children}
              <Dropdown.Item
                onClick={(e) => {
                  e.preventDefault();
                  setIsCustomPickerSelected(true);
                }}
                selected={label === undefined && innerState[1] !== undefined}
              >
                {datePickerLabel}
              </Dropdown.Item>
            </>
          );
        } else {
          return (
            <Block row width="size-fit" gap="lg">
              <Hidden show={{ xs: true, sm: calendarType === 'day' || calendarDirection === 'left' }}>
                <Calendar
                  calendarDate={calendarDates[0]}
                  onCalendarDateChange={(date) => {
                    handleCalendarDateChange(date, 'left');
                  }}
                  selectedDates={innerState}
                  highlightedDates={highlightedDates}
                  onDateSelect={(date) => {
                    onDateSelect(date, close);
                  }}
                  onDateHover={onDateHover}
                  locale={locale}
                  type={calendarType}
                  onTypeChange={(type) => {
                    setCalendarType(type);
                    setCalendarDirection('left');
                  }}
                  limit={limit}
                />
              </Hidden>

              <Hidden show={{ xs: false, sm: calendarType === 'day' || calendarDirection === 'right' }}>
                <Calendar
                  calendarDate={calendarDates[1]}
                  onCalendarDateChange={(date) => {
                    handleCalendarDateChange(date, 'right');
                  }}
                  selectedDates={innerState}
                  highlightedDates={highlightedDates}
                  onDateSelect={(date) => {
                    onDateSelect(date, close);
                  }}
                  onDateHover={onDateHover}
                  locale={locale}
                  type={calendarType}
                  onTypeChange={(type) => {
                    setCalendarType(type);
                    setCalendarDirection('right');
                  }}
                  limit={limit}
                />
              </Hidden>
            </Block>
          );
        }
      }}
    </Dropdown>
  );
};

DateRangePicker.displayName = 'DateRangePicker';

export default DateRangePicker;

import React, { useCallback, useRef, useState } from 'react';

import { Alert, FileType, Modal, notification, Stepper, StepperPreviousHandle } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { IconCustom } from '@Components/icon/CustomIcon';
import kycQuery from '@Libs/clientInstances/idpQuery';
import { TRACKER_EVENTS } from '@Libs/tracker/events';
import { addTrackerKycStep } from '@Libs/tracker/helpers/addTrackerKyc';
import { handleErrorResponse } from '@Libs/utils';
import Completed from '@Modules/account/kyc/components/components/Completed';
import {
  PersonalInfo2Type,
  PersonalInfo3Type,
  PersonalInfoType,
} from '@Modules/account/kyc/components/components/Kyc3Panel/helpers/constants';
import PersonalInfoStep1 from '@Modules/account/kyc/components/components/Kyc3Panel/steps/PersonalInfoStep1';
import PersonalInfoStep2 from '@Modules/account/kyc/components/components/Kyc3Panel/steps/PersonalInfoStep2';
import PersonalInfoStep3 from '@Modules/account/kyc/components/components/Kyc3Panel/steps/PersonalInfoStep3';
import SelfieUpload from '@Modules/account/kyc/components/components/SelfieUpload';

import { ResidencySelection, Start } from './steps';

export const steps = {
  kycStep1: 'kycStep1',
  kycStep2: 'kycStep2',
  kycStep3: 'kycStep3',
  kycStep4: 'kycStep4',
  kycStep5: 'kycStep5',
  kycStep6: 'kycStep6',
  kycStep7: 'kycStep7',
};

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

function Kyc3Panel({ open, setOpen }: Props) {
  const { t } = useTranslation();
  const { mutateAsync, isPending } = kycQuery.usePostKyc3();
  const [currentStep, setCurrentStep] = useState<string>(steps.kycStep1);

  const fileRef = useRef<{
    selfie: FileType[];
    residency: FileType[];
    personalInfo: PersonalInfoType;
    personalInfo2: PersonalInfo2Type;
    personalInfo3: PersonalInfo3Type;
  }>({
    selfie: [] as FileType[],
    residency: [] as FileType[],
    personalInfo: {} as PersonalInfoType,
    personalInfo2: {} as PersonalInfo2Type,
    personalInfo3: {} as PersonalInfo3Type,
  });
  const stepperRef = useRef<StepperPreviousHandle<string>>(null);

  const modalTitles: Record<keyof typeof steps, string | undefined> = {
    kycStep1: t('account.id-verification.modal-titles.kyc-personal-account', { level: 3 }),
    kycStep2: t('account.id-verification.modal-titles.residency-permit-document'),
    kycStep3: t('account.id-verification.modal-titles.personal-info'),
    kycStep4: t('account.id-verification.modal-titles.personal-info'),
    kycStep5: t('account.id-verification.modal-titles.personal-info'),
    kycStep6: t('account.id-verification.modal-titles.facial-recognition'),
    kycStep7: undefined,
  };

  const previous = useCallback((initial?: boolean) => {
    if (initial) {
      stepperRef?.current?.clearHistory();
      setCurrentStep(steps.kycStep1);
      return;
    }

    const previousStep = stepperRef?.current?.previous();
    setCurrentStep(previousStep as string);
  }, []);

  const completed = useCallback(() => {
    const formData = new FormData();
    const { selfie, residency, personalInfo, personalInfo2, personalInfo3 } = fileRef.current;

    residency.forEach((d) => {
      formData.append('residency', d.file as Blob, d.filename);
    });

    selfie.forEach((d) => {
      formData.append('selfie', d.file as Blob, d.filename);
    });

    formData.append('profession_experience', personalInfo?.experienceYear || '');
    formData.append('profession', personalInfo?.profession || '');
    formData.append('education_level', personalInfo?.school?.label || '');

    formData.append('income_declaration', personalInfo2?.income.label);
    formData.append('income_sources', personalInfo2?.incomeSource.map((d) => d.label).join(', '));

    formData.append('bilira_investment_percentage', personalInfo3?.investAmount);
    formData.append('purpose', personalInfo3?.investPurpose.map((d) => d.label).join(', '));

    mutateAsync(formData)
      .then(() => {
        addTrackerKycStep(TRACKER_EVENTS.KYC3.KYC_STEP_7, 3);
        setCurrentStep(steps.kycStep7);
      })
      .catch((error) => handleErrorResponse(error, t('error.application-submission-error')));
  }, []);

  const personalInfo1Callback = (personalInfo: PersonalInfoType) => {
    fileRef.current.personalInfo = { ...fileRef.current?.personalInfo, ...personalInfo };
    addTrackerKycStep(TRACKER_EVENTS.KYC3.KYC_STEP_3, 3, personalInfo);
    setCurrentStep(steps.kycStep4);
  };
  const personalInfo2Callback = (personalInfo: PersonalInfo2Type) => {
    fileRef.current.personalInfo2 = { ...fileRef.current?.personalInfo2, ...personalInfo };
    addTrackerKycStep(TRACKER_EVENTS.KYC3.KYC_STEP_4, 3, personalInfo);
    setCurrentStep(steps.kycStep5);
  };

  const personalInfo3Callback = (personalInfo: PersonalInfo3Type) => {
    fileRef.current.personalInfo3 = { ...fileRef.current?.personalInfo3, ...personalInfo };
    addTrackerKycStep(TRACKER_EVENTS.KYC3.KYC_STEP_5, 3, personalInfo);
    setCurrentStep(steps.kycStep6);
  };

  return (
    <>
      <Modal
        title={modalTitles[currentStep as keyof typeof steps]}
        open={open}
        onClose={() => setOpen(false)}
        overlayClosable={false}
        onPreviousClick={previous}
        hasPreviousButton={
          currentStep !== steps.kycStep1 &&
          currentStep !== steps.kycStep2 &&
          currentStep !== steps.kycStep7 &&
          !isPending
        }
      >
        <Stepper ref={stepperRef} step={currentStep}>
          <Stepper.Item pageName={steps.kycStep1} key={steps.kycStep1}>
            <Start
              callback={() => {
                addTrackerKycStep(TRACKER_EVENTS.KYC3.KYC_STEP_1, 3);
                setCurrentStep(steps.kycStep2);
              }}
            />
          </Stepper.Item>
          <Stepper.Item pageName={steps.kycStep2} key={steps.kycStep2}>
            <ResidencySelection
              sources={fileRef.current.residency}
              callback={(val) => {
                addTrackerKycStep(TRACKER_EVENTS.KYC3.KYC_STEP_2, 3);
                fileRef.current.residency = val;
                setCurrentStep(steps.kycStep3);
              }}
            />
          </Stepper.Item>
          <Stepper.Item pageName={steps.kycStep3} key={steps.kycStep3}>
            <PersonalInfoStep1 data={fileRef.current.personalInfo} callback={personalInfo1Callback} />
          </Stepper.Item>
          <Stepper.Item pageName={steps.kycStep4} key={steps.kycStep4}>
            <PersonalInfoStep2 data={fileRef.current.personalInfo2} callback={personalInfo2Callback} />
          </Stepper.Item>
          <Stepper.Item pageName={steps.kycStep5} key={steps.kycStep5}>
            <PersonalInfoStep3 data={fileRef.current.personalInfo3} callback={personalInfo3Callback} />
          </Stepper.Item>
          <Stepper.Item pageName={steps.kycStep6} key={steps.kycStep6}>
            <SelfieUpload
              customIcon={<IconCustom type="id-illustrator-2" />}
              alert={
                <Alert status="yellow" mb="2xl">
                  {t('account.id-verification.kyc-3.selfie-instruction-warning')}
                  <br />
                  <br />
                  <b> {t('account.id-verification.kyc-3.selfie-instruction')}</b>
                </Alert>
              }
              isPending={isPending}
              sources={fileRef.current.selfie}
              callback={(source) => {
                addTrackerKycStep(TRACKER_EVENTS.KYC3.KYC_STEP_6, 3);
                fileRef.current.selfie = source;
                completed();
              }}
            />
          </Stepper.Item>
          <Stepper.Item pageName={steps.kycStep7} key={steps.kycStep7}>
            <Completed icon="diamond2" />
          </Stepper.Item>
        </Stepper>
      </Modal>
    </>
  );
}

export default Kyc3Panel;

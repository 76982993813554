import React from 'react';

import { Button, Icon, Meta, Panel, StepProgress, useSafeLocalStorage } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation();
  const [hidden, setHidden] = useSafeLocalStorage('CD-SHP', false);

  if (hidden) {
    return null;
  }

  return (
    <>
      <Panel
        color="theme-n200d800"
        mb="4xl"
        extra={
          <Button
            testId="hide-deposit-steps-btn"
            size="md"
            variant="transparent"
            startIcon={<Icon color="neutral-700" type="o:x-mark" />}
            onClick={() => setHidden(true)}
          />
        }
      >
        <StepProgress
          // TODO : Add data & render step props to render array
          maxWidth="3xl"
          responsive
          size="sm"
          steps={[
            {
              title: <Meta title={t('crypto.deposit-steps.1.title')} description={t('crypto.deposit-steps.1.desc')} />,
            },
            {
              title: <Meta title={t('crypto.deposit-steps.2.title')} description={t('crypto.deposit-steps.2.desc')} />,
            },
            {
              title: <Meta title={t('crypto.deposit-steps.3.title')} description={t('crypto.deposit-steps.3.desc')} />,
            },
          ]}
        />
      </Panel>
    </>
  );
};

export default Header;

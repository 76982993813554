import React, { memo, useMemo } from 'react';

import { CoinSymbolType } from '@bilira-org/react-utils';

import CryptoWalletApi from '@Libs/clientInstances/cryptoQuery';

type Props = {
  symbol?: CoinSymbolType;
};
const AssetName = ({ symbol }: Props) => {
  const { data: assets, isPending } = CryptoWalletApi.useGetAssets();

  const assetName = useMemo(() => {
    return assets?.find((asset) => asset.symbol === symbol);
  }, [assets, symbol]);

  if (!symbol || isPending) {
    return null;
  }

  return <>{assetName?.name}</>;
};

export default memo(AssetName);

import React from 'react';

import { LayoutV2 } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import HeadTags from '@Components/common/HeadTags';

import PrivateOtcContent from './components/PrivateOtcContent';
import PublicOtcContent from './components/PublicOtcContent';

const { Content, InnerContent } = LayoutV2;

const Index = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeadTags title={t('navigation.otc-portal')} titleSuffix="subpage" />
      {/*<Header />*/}
      <Content>
        <InnerContent>
          <PrivateOtcContent />
          <PublicOtcContent />
        </InnerContent>
      </Content>
    </>
  );
};
export default Index;

import { DefaultAllowanceType, QueryOptions } from '@bilira-org/react-utils';

import allowancesQuery from '@Libs/clientInstances/allowancesQuery';

interface Props extends QueryOptions {
  /** The KYC level for which to retrieve the default allowance. */
  kycLevel: DefaultAllowanceType['kyc_level'];
  /** The domain for which to retrieve the default allowance. */
  domain: DefaultAllowanceType['domain'];
}

/**
 * Hook for retrieving the default allowance based on KYC level and domain for the authenticated user.
 *
 * @param {Props} options - Options for the get default allowance hook.
 *
 * @returns {{
 *   defaultAllowance: DefaultAllowanceType | undefined,
 *   isPending: boolean
 * }} - An object containing the default allowance based on KYC level and domain and a boolean indicating whether the data is pending.
 */
const useGetDefaultAllowance = ({ kycLevel, domain, ...queryOptions }: Props) => {
  const { data, isPending } = allowancesQuery.useGetDefaultAllowances(queryOptions);

  const defaultAllowance = data?.find(
    (defaultAllowance) => defaultAllowance.kyc_level === kycLevel && defaultAllowance.domain === domain,
  );

  return { defaultAllowance, isPending };
};

export default useGetDefaultAllowance;

import { Block, Button, Display } from '@bilira-org/design';

import FormattedPercentage from '@/components/common/FormattedPercentage';
import { withPrivateRoute } from '@Libs/hocs';

type Props = {
  applyPercentageCallback?: (percentage: number) => void;
  percentages?: number[];
  show?: boolean;
};

/**
 * Renders percentage buttons for swap amount
 *
 * @example
 * <SwapPercentageButtons
 *   percentages={[0.25, 0.5, 0.75, 1]}
 *   applyPercentageCallback={onPercentageSelect}
 *   show={true}
 * />
 */
const PercentageButtons = ({ show, applyPercentageCallback, percentages = [0.25, 0.5, 0.75, 1] }: Props) => {
  return (
    <Display show={show}>
      <Block mt="lg" justify="center" row gap="sm">
        {percentages.map((percentage) => (
          <Button
            key={`scale-${percentage}`}
            testId={`swap-scale-${percentage}`}
            variant="outline-filled"
            size="xs"
            onClick={() => applyPercentageCallback?.(percentage)}
          >
            <FormattedPercentage value={percentage * 100} formatPriceProps={{ decimal: 0 }} />
          </Button>
        ))}
      </Block>
    </Display>
  );
};

export default withPrivateRoute(PercentageButtons);

import { Tab } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { useSessionStore } from '@/store';
import MarketTableContent from '@Modules/market/market/table/components/MarketTableContent';
import MarketWatchlistTableContent from '@Modules/market/market/watchlist/MarketWatchlistTableContent';

import GeneralFilterTags from './components/GeneralFilterTags';
import { useMarketTableControls } from './components/hooks/useMarketTableControls';

const MarketTableContainer = () => {
  const { t } = useTranslation();
  const isLoggedIn = useSessionStore((state) => state.isLoggedIn);

  const { dataTag, setDataTag, sorting, setSorting, tab, setTab } = useMarketTableControls();

  return (
    <>
      <Tab
        panelsMt="lg"
        scrollable
        variant="bordered"
        defaultIndex={tab ?? 1}
        onChange={setTab}
        headers={[
          {
            name: t('watchlist.title'),
            key: 'tab-favorite',
            hidden: !isLoggedIn,
          },
          {
            name: 'TRYB',
            key: 'tab-tryb-pairs',
          },
          {
            name: 'USDC',
            key: 'tab-usdc-pairs',
          },
          {
            name: 'USDT',
            key: 'tab-usdt-pairs',
          },
        ]}
      >
        {isLoggedIn && (
          <Tab.Panel>
            <MarketWatchlistTableContent />
          </Tab.Panel>
        )}

        <Tab.Panel>
          <GeneralFilterTags tag={dataTag} callback={setDataTag} filterType="market" />
          <MarketTableContent dataTag={dataTag} quote="TRYB" sorting={sorting} onSortingChange={setSorting} />
        </Tab.Panel>
        <Tab.Panel>
          <GeneralFilterTags tag={dataTag} callback={setDataTag} filterType="market" />
          <MarketTableContent
            dataTag={dataTag}
            exclude={['USDC', 'TRYB', 'BMMF']}
            quote="USDC"
            sorting={sorting}
            onSortingChange={setSorting}
          />
        </Tab.Panel>
        <Tab.Panel>
          <GeneralFilterTags tag={dataTag} callback={setDataTag} filterType="market" />
          <MarketTableContent
            dataTag={dataTag}
            exclude={['USDT', 'TRYB', 'BMMF']}
            quote="USDT"
            sorting={sorting}
            onSortingChange={setSorting}
          />
        </Tab.Panel>
      </Tab>
    </>
  );
};

export default MarketTableContainer;

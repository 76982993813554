import React from 'react';

import { type TransactionsRecordType } from '@bilira-org/react-utils';

import FormattedNumber from '@/components/common/FormattedNumber';
import FormattedNumberByPrice from '@/components/common/FormattedNumberByPrice';
import { getTransactionSuffix } from '@Components/transaction/helpers';
import { OTC_TRANSACTION_STATUS } from '@Libs/helpers/getStateConfig';

import { TransactionItemSizeType } from '../../TransactionTable';
import { transactionFormatPriceProps, transactionTextSize } from '../constants';

type Props = {
  data: TransactionsRecordType;
  size: TransactionItemSizeType;
};

const TransactionAmount = ({ data, size }: Props) => {
  return (
    <FormattedNumberByPrice
      formatPriceProps={transactionFormatPriceProps}
      align="right"
      wordBreak="all"
      size={transactionTextSize[size]}
      suffixSize={transactionTextSize[size]}
      suffix={getTransactionSuffix(data)}
      weight="medium"
      suffixWeight="medium"
      color={OTC_TRANSACTION_STATUS[data.status]?.text}
      value={data.to_amount}
      price={data.pair_price}
      type={data.side === 'buy' ? 'amount' : 'price'}
    />
  );
};

export default TransactionAmount;

import React, { useState } from 'react';

import {
  Block,
  Breadcrumbs,
  Button,
  Hidden,
  Icon,
  Image,
  Link,
  Meta,
  Modal,
  Panel,
  Separator,
  Stepper,
  Text,
} from '@bilira-org/design';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useGeneralStore } from '@/store/generalStore';

import { newFeatures } from './constants';
import { getPaginationString } from './utils';

/**
 * Modal for displaying new features
 *
 * Utilizes general store to control whether the modal is active or not
 */
const NewFeatureModal = () => {
  const latelyModalActive = useGeneralStore((state) => state.latelyModalActive);
  const setLatelyModalActive = useGeneralStore((state) => state.setLatelyModalActive);

  const [step, setStep] = useState(0);
  const { t, i18n } = useTranslation();

  const onClose = () => {
    setLatelyModalActive(false);
    setStep(0);
  };

  return (
    <Modal
      size="xl"
      padding="none"
      footerPadding="none"
      open={latelyModalActive}
      onClose={onClose}
      footer={
        <>
          <Separator width="size-full" />
          <Block row justify="between" items="center" p="lg">
            <Button
              disabled={step === 0}
              onClick={() => setStep((state) => state - 1)}
              variant="outline"
              size="md"
              startIcon={<Icon type="o:arrow-left" />}
            />
            <Text>{getPaginationString(step + 1, newFeatures.length)}</Text>
            <Button
              disabled={step === newFeatures.length - 1}
              onClick={() => setStep((state) => state + 1)}
              variant="outline"
              size="md"
              startIcon={<Icon type="o:arrow-right" />}
            />
          </Block>
        </>
      }
    >
      <Stepper step={step}>
        {newFeatures.map((newFeature) => {
          const url = newFeature.urlKey ? t(newFeature.urlKey) : newFeature.url;

          return (
            <Stepper.Item key={newFeature.key}>
              <Block row>
                <Hidden show={{ xs: false, sm: true }} style={{ flexShrink: 0 }}>
                  <Image
                    alt={t(newFeature.title)}
                    width="size-72"
                    src={newFeature.img}
                    style={{ aspectRatio: '288/417' }}
                  />
                </Hidden>

                <Block justify="center" items="center">
                  <Panel size="8xl">
                    <Breadcrumbs listType="disc">
                      <Breadcrumbs.Item color="primary-500">{t('new-feature-modal.new-features')}</Breadcrumbs.Item>
                    </Breadcrumbs>
                    <Meta
                      gap="md"
                      mb="xl"
                      title={
                        <Text heading size="2xl" weight="bold">
                          {t(newFeature.title)}
                        </Text>
                      }
                      description={<Text>{t(newFeature.desc)}</Text>}
                    />
                    <Link anchor href={url} target="_blank">
                      <Button variant="filled" size="md">
                        {t('new-feature-modal.detailed-info')}
                      </Button>
                    </Link>
                  </Panel>
                </Block>
              </Block>
            </Stepper.Item>
          );
        })}
      </Stepper>
    </Modal>
  );
};

export default NewFeatureModal;

import React from 'react';

import DataTable, { DataTableColumnProps } from './dataTable';
import DataTableHOC from './hoc';
import { BreakpointType, DirectionType } from '../../../../types';
import { Hidden } from '../../hidden';
import DataGrid, { DataGridProps } from '../dataGrid/dataGrid';
import { invertBreakpointType } from '../helpers';

type Props<T> = DataTableColumnProps<T> & {
  /** Breakpoint at which the layout changes between grid and table */
  breakpoint?: BreakpointType<boolean>;
  /** Whether the cell is displayed as a row or column in smaller screens */
  responsiveCellLayout?: DirectionType;
  /** Style for the body in the table layout */
  responsiveBodyStyle?: DataGridProps<T>['bodyStyle'];
};

/**
 * A responsive table component that switches between a table and grid layout
 * based on the specified breakpoint. Uses `DataTable` for larger screens and
 * `DataGrid` for smaller screens.
 */
const ResponsiveTable = <T,>({
  breakpoint = { xs: true, sm: false },
  responsiveCellLayout,
  bodyStyle,
  responsiveBodyStyle,
  ...rest
}: Props<T>) => {
  return (
    <DataTableHOC {...rest}>
      {(table) => (
        <>
          <Hidden show={breakpoint}>
            <DataGrid {...rest} table={table} layout={responsiveCellLayout} bodyStyle={responsiveBodyStyle} />
          </Hidden>
          <Hidden show={invertBreakpointType(breakpoint)}>
            <DataTable {...rest} bodyStyle={bodyStyle} table={table} />
          </Hidden>
        </>
      )}
    </DataTableHOC>
  );
};

export default ResponsiveTable;

import React, { forwardRef, ReactNode } from 'react';

import { Tab as HeadlessTab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { clsx } from 'clsx';
import { Link } from 'react-router-dom';

import { Flex } from '../flex';
import { useTabRouter } from '../hooks';
import { Overflow } from '../overflow';
import { BaseComponentType, BaseSize, MarginSizes, PaddingSizes } from '../types';
import { Text, TextProps } from '../typography';

type TabHeaderType<T extends string> = {
  /** The name of the tab header. */
  name: ReactNode;
  /** The unique key for the tab header. */
  key: T;
  /** Whether the tab header is hidden. */
  hidden: boolean;
  /** Whether the tab header is disabled. */
  disabled: boolean;
  /** Link does not exist */
  link: never;
};

type LinkHeaderType<T extends string> = Omit<TabHeaderType<T>, 'link' | 'key'> & {
  /** Describes a location that is the destination of some navigation */
  link: string;
  /** The unique key for the tab header. */
  key?: T;
};

type HeaderType<T extends string> = TabHeaderType<T> | LinkHeaderType<T>;

type HeaderStyle = {
  rowPy: PaddingSizes;
  rowPx: PaddingSizes;
  size: BaseSize;
};

export type TabProps<T extends string> = BaseComponentType & {
  /** An array of tab headers. Each header should have a name and a unique key. */
  headers: Partial<HeaderType<T>>[];
  /** The content of the tab, either as a ReactNode or a function that receives the headers. */
  children?: ReactNode | React.ReactElement | ((val: Partial<HeaderType<T>>[]) => ReactNode | React.ReactElement);
  /** The variant of the tab */
  variant?: 'bordered' | 'boxed';
  /** Callback function triggered when the selected tab changes. */
  onChange?: ((index: number, header: Partial<HeaderType<T>>) => void) | undefined;
  /** The default index of the selected tab. */
  defaultIndex?: number;
  /** Whether the tab header should stretch to fill the available space. Defaults to `true`. */
  headerStretch?: boolean;
  /** Whether tab content should be scrollable */
  scrollable?: boolean;
  /** Margin top of the panel contents */
  panelsMt?: MarginSizes;
  headerStyle?: Partial<HeaderStyle>;
  headerTextStyle?: Partial<TextProps>;
  /** Key of the selected tab */
  selectedKey?: T;
  /** Extra node to be rendered on headers */
  extra?: ReactNode;
};

/**
 * Tab component for managing and displaying content in tabs.
 *
 * @example
 * // Example usage of the Tab component
 * <Tab
 *   headers={[
 *     { name: 'Tab 1', key: 'tab1' },
 *     { name: 'Tab 2', key: 'tab2' },
 *     { name: 'Tab 3', key: 'tab3' },
 *   ]}
 *   onChange={handleTabChange}
 *   defaultIndex={0}
 *   headerStretch
 *   testId="customTestId"
 * >
 *   {(headers) => (
 *     <>
 *       {headers.map((header, index) => (
 *         <Tab.Panel key={header.key} center={index === 1}>
 *           <p>{`Content for ${header.name}`}</p>
 *         </Tab.Panel>
 *       ))}
 *     </>
 *   )}
 * </Tab>
 */
const TabComponent = <T extends string>(
  {
    headerStretch = true,
    variant = 'boxed',
    defaultIndex,
    headers,
    onChange,
    children,
    testId,
    scrollable,
    panelsMt = '4xl',
    headerStyle,
    headerTextStyle,
    selectedKey,
    extra,
  }: TabProps<T>,
  ref: React.Ref<HTMLDivElement>,
) => {
  return (
    <div ref={ref} className={clsx('tab-page', { scrollable: scrollable })} data-testid={testId ?? 'tab'}>
      <TabGroup
        defaultIndex={defaultIndex}
        selectedIndex={
          selectedKey ? headers.findIndex((header) => (header.link || header.key) === selectedKey) : undefined
        }
        onChange={(index: number) => onChange?.(index, headers[index])}
      >
        <TabHeader
          headers={headers}
          headerStretch={headerStretch}
          variant={variant}
          headerStyle={headerStyle}
          headerTextStyle={headerTextStyle}
          extra={extra}
        />
        {children && (
          <TabPanels className={clsx({ [`mt-${panelsMt}`]: panelsMt }, 'tab-panels')}>
            {typeof children === 'function' ? children(headers) : children}
          </TabPanels>
        )}
      </TabGroup>
    </div>
  );
};

const Tab = forwardRef(TabComponent) as <T extends string>(
  props: TabProps<T> & { ref?: React.Ref<HTMLDivElement> },
) => ReturnType<typeof TabComponent>;

export type PanelProps = BaseComponentType & {
  /** The content of the panel. */
  children: ReactNode | React.ReactElement;
  /** Whether to center the content within the panel. */
  center?: boolean;
};

/**
 * Tab panels to be used within `Tab` component
 *
 * @example
 * <Panel center>
 *   Content
 * </Panel>
 */
function Panel({ center, children, testId }: PanelProps) {
  return (
    <TabPanel className={clsx('tab-panel', { 'text-center': center })} data-testid={testId ?? 'tab-panel'}>
      {children}
    </TabPanel>
  );
}

type TabHeaderProps<T extends string> = {
  headerStretch?: TabProps<T>['headerStretch'];
  variant?: TabProps<T>['variant'];
  headers: TabProps<T>['headers'];
  headerStyle?: TabProps<T>['headerStyle'];
  headerTextStyle?: TabProps<T>['headerTextStyle'];
  extra?: TabProps<T>['extra'];
};

const TabHeader = <T extends string>({
  headerStretch = true,
  variant = 'boxed',
  headers,
  headerStyle,
  headerTextStyle,
  extra,
}: TabHeaderProps<T>) => {
  return (
    <Flex
      width="full"
      direction="row"
      justify="between"
      className={clsx('tab-list-container', {
        [`px-${headerStyle?.rowPx}`]: headerStyle?.rowPx,
        [`py-${headerStyle?.rowPy}`]: headerStyle?.rowPy,
      })}
    >
      <Overflow overflow="x-scroll" className="w-full">
        <TabList
          className={clsx('group tab-list', {
            [`tab-${variant}-list`]: variant,
            [`header-stretch`]: headerStretch,
          })}
        >
          {headers.map((item) => {
            if (item.hidden) {
              return null;
            }

            const linkProps = item.link ? { to: item.link } : {};

            return (
              <HeadlessTab
                as={item.link ? Link : 'div'}
                {...linkProps}
                key={item.link || item.key}
                disabled={item.disabled}
                className={({ selected }: { selected: boolean }) =>
                  clsx('tab-header-item cursor-pointer', {
                    [`header-stretch`]: headerStretch,
                    [`tab-${variant}`]: variant,
                    [`tab-${variant}-selected`]: selected,
                    [`tab-${variant}-${headerStyle?.size}`]: headerStyle?.size,
                    [`tab-disabled`]: item.disabled,
                  })
                }
              >
                {headerTextStyle ? <Text {...headerTextStyle}>{item.name}</Text> : item.name}
              </HeadlessTab>
            );
          })}
        </TabList>
      </Overflow>
      {extra && (
        <Flex direction="row" grow="1" justify="end" className={`extra tab-${variant}-list`}>
          {extra}
        </Flex>
      )}
    </Flex>
  );
};

TabComponent.displayName = 'Tab';

Panel.displayName = 'Panel';

export default Object.assign(Tab, { Panel, useTabRouter });

import React from 'react';

import { Button, Flex, Image, Modal, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import BgImage from './assets/trade-only-popup-bg.png';
import useTradeOnlyPopup from './hooks/useTradeOnlyPopup';

interface Props {
  symbol?: string;
}

const TradeOnlyPopup = ({ symbol }: Props) => {
  const { t } = useTranslation();

  const { open, handleClose, isPending } = useTradeOnlyPopup(symbol);

  return (
    <Modal compound variant="popup" open={open} overlayClosable={false} closable={false}>
      <div style={{ position: 'relative' }}>
        <Image alt="" width="size-full" src={BgImage} skeletonAspectRatio={512 / 160} />
        <Text
          heading
          color="white"
          size={{ xs: 'xl', sm: '3xl' }}
          weight="bold"
          style={{
            position: 'absolute',
            right: '12.20703125%',
            top: '50%',
            left: '34.66796875%',
            transform: 'translateY(-50%)',
            fontSize: 'clamp(0.9375rem, -0.4018rem + 6.6964vw, 1.875rem)', // dynamic font size to adjust with bg image
          }}
        >
          {t('trade.trade-only-popup.information')}
        </Text>
      </div>
      <Flex p={{ xs: '2xl', sm: '4xl' }} gap="2xl" align="center" items="center">
        <Flex gap="md">
          <Text weight="bold" color="neutral-900">
            {t('trade.trade-only-popup.about-token', { token: symbol })}
          </Text>
          <Text color="neutral-900">{t('trade.trade-only-popup.description')}</Text>
        </Flex>
        <Button variant="filled" size="md" loading={isPending} onClick={handleClose}>
          {t('common.understood')}
        </Button>
      </Flex>
    </Modal>
  );
};

export default TradeOnlyPopup;

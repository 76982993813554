import React from 'react';

import { Block, Overflow, Panel, Text } from '@bilira-org/design';
import { OTCStatusType, OTCType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import withPrivateRoute from '@Libs/hocs/withPrivateRoute';
import OTCFilter from '@Modules/otc/otcTrade/components/OTCFilter';
import RejectOfferModal from '@Modules/otc/otcTrade/RejectOfferModal';
import SuccessOfferModal from '@Modules/otc/otcTrade/SuccessOfferModal';
import { useGeneralStore } from '@Store/generalStore';

import OTCTableContainer from './OTCTableContainer';
import OTCTwoFAModal from './OTCTwoFAModal';

const OTCContent = () => {
  const { t } = useTranslation();

  const [activeSuccessOfferModal, setActiveSuccessOfferModal] = React.useState(false);
  const [activeRejectOfferModal, setActiveRejectOfferModal] = React.useState(false);
  const [activeTwoFaModal, setActiveTwoFaModal] = React.useState(false);
  const [dataId, setDataId] = React.useState<string>('');
  const [filter, setFilter] = React.useState<OTCStatusType>();

  const setNotificationModal = useGeneralStore((state) => state.setNotificationModal);

  const acceptRequest = (entry: OTCType) => () => {
    setDataId(entry.id);
    setActiveTwoFaModal(true);
  };
  const cancelRequest = (entry: OTCType) => () => {
    setDataId(entry.id);
    setActiveRejectOfferModal(true);
  };

  const onRejected = () => {
    setActiveRejectOfferModal(false);
  };

  const onConfirm = () => {
    setActiveTwoFaModal(false);
    setActiveSuccessOfferModal(true);
  };

  const onDetailClick = (id: string) => {
    setDataId(id);
    setNotificationModal({
      open: true,
      title: t('transactions.transaction-details'),
      hideButton: true,
      notification: {
        type: 'otc:swap',
        id: 'transaction',
        read: false,
        data: { transactionId: id, content: 'transaction', description: '', title: '' },
      },
    });
  };

  const onHistoryClick = () => {
    setActiveSuccessOfferModal(false);
    onDetailClick(dataId);
  };

  return (
    <Panel border="neutral-400" size="none" py="sm">
      <Block row justify="between" items="center" mb="2xl" mx="2xl" mt="lg">
        <Text size="xl" weight="bold">
          {t('otc.title')}
        </Text>
        <OTCFilter callback={setFilter} />
      </Block>

      <Overflow overflow="x-auto">
        <OTCTableContainer
          filter={filter}
          acceptRequest={acceptRequest}
          cancelRequest={cancelRequest}
          onDetailClick={onDetailClick}
        />
      </Overflow>
      <RejectOfferModal
        callback={onRejected}
        data={dataId}
        onAccept={onRejected}
        setActive={setActiveRejectOfferModal}
        active={activeRejectOfferModal}
      />

      <SuccessOfferModal
        callback={onHistoryClick}
        setActive={setActiveSuccessOfferModal}
        active={activeSuccessOfferModal}
      />

      <OTCTwoFAModal callback={onConfirm} data={dataId} active={activeTwoFaModal} setActive={setActiveTwoFaModal} />
    </Panel>
  );
};

export default withPrivateRoute(OTCContent);

import React, { PropsWithChildren } from 'react';

import { HorizontalMenuItemWithoutDropdown } from './components/horizontalMenu';
import { VerticalMenuItemWithoutDropdown } from './components/verticalMenu';
import { MenuItemWithoutDropdownProps } from './types';

export const MenuItemWithoutDropdown = ({
  vertical,
  children,
  display = true,
  ...props
}: PropsWithChildren<MenuItemWithoutDropdownProps> & { vertical?: boolean }) => {
  if (!display) {
    return null;
  }

  const Element = vertical ? VerticalMenuItemWithoutDropdown : HorizontalMenuItemWithoutDropdown;

  return <Element {...props} />;
};

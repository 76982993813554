import { useCallback, useEffect, useRef, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import Tracker from '@Libs/tracker/Tracker';

interface Props {
  /** Title of the document */
  title?: string;
  /** Title suffix of the document
   * * homepage: Kripto Alım/Satım
   * * subpage: Kripto Alım/Satım - BiLira
   * * undefined: no suffix
   */
  titleSuffix?: 'homepage' | 'subpage';
  /** Thumbnail image source of the page */
  thumbnail?: string;
  /** Description of the page */
  description?: string;
}

/** Default values */
const meta = {
  thumbnail: '/share-thumbnail.png',
  description:
    'BiLira ile popüler kripto paralara en uygun fiyatlardan hızlı ve güvenli bir şekilde ulaşın, ayrıcalıklı kripto deneyimi yaşayın.',
};

/**
 * Component to handle HTML head tags of the page
 *
 * @example
 * <HeadTags title="Welcome" titleSUffix="homepage"/>
 */
const HeadTags = ({
  title,
  titleSuffix: titleSuffixFromProps,
  thumbnail: thumbnailFromProps = meta.thumbnail,
  description = meta.description,
}: Props) => {
  const recentTrackedUrl = useRef<string>();
  const [tags, setTags] = useState<JSX.Element[]>([]);
  const { t } = useTranslation();

  const getTitleSuffix = () => {
    if (titleSuffixFromProps == 'homepage') {
      return ` | ${t('title-suffix')}`;
    } else if (titleSuffixFromProps === 'subpage') {
      return ` | ${t('title-suffix')} | BiLira`;
    } else {
      return '';
    }
  };

  const getFormattedThumbnail = useCallback(() => {
    let formattedThumbnail = thumbnailFromProps;
    if (formattedThumbnail?.startsWith('/')) {
      formattedThumbnail = window.location.origin + formattedThumbnail;
    }
    return formattedThumbnail;
  }, [thumbnailFromProps]);

  const browserTitle = title ? title + getTitleSuffix() : undefined;

  useEffect(() => {
    if (browserTitle && recentTrackedUrl.current !== window.location.href) {
      recentTrackedUrl.current = window.location.href;
      Tracker.trackPageView(browserTitle);
    }
  }, [browserTitle]);

  const thumbnail = getFormattedThumbnail();

  const getTags = useCallback(() => {
    const result = [];
    if (browserTitle) {
      result.push(<title key="head-title">{browserTitle}</title>);
    }

    result.push(
      <meta key="og-image" property="og:image" content={thumbnail} />,
      <meta key="twitter-image" name="twitter:image" content={thumbnail} />,
    );

    result.push(
      <meta key="twitter-description" name="twitter:title" content={description} />,
      <meta key="og-title" property="og:title" content={description} />,
      <meta key="og-description" property="og:description" content={description} />,
      <meta key="description" property="description" content={description} />,
      <meta key="twitter-description" name="twitter:description" content={description} />,
    );

    return result;
  }, [browserTitle, description, thumbnail]);

  useEffect(() => {
    const newTags = getTags();
    setTags(newTags);
  }, [getTags]);

  return <>{tags.length > 0 && <Helmet>{tags}</Helmet>}</>;
};

export default HeadTags;

import React from 'react';

import { clsx } from 'clsx';

import { BaseComponentType, FixSizeType } from '../types';

export type BadgeRibbonColors = 'neutral-200' | 'green' | 'yellow' | 'red';

export type BadgeRibbonProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'color'> &
  BaseComponentType & {
    /** Additional classes to be added to the badge. */
    className?: string;
    /** The variant of the badge. */
    rounded?: 'rounded' | 'right-top';
    /** Ribbon position*/
    position?: 'top-right';
    /** The size of the badge. */
    size?: FixSizeType;
    /** The color of the badge. */
    color?: BadgeRibbonColors;
  };

/**
 * Badge component
 *
 * @example
 *
 * <Badge color="primary">Primary</Badge>
 * <Badge color="secondary" variant="square">Secondary</Badge>
 * <Badge color="neutral-600" size="lg">Neutral 600</Badge>
 * <Badge color="orange" transparent>Orange</Badge>
 * <Badge color="red" responsive>Red</Badge>
 * <Badge color="green" bordered>Green</Badge>
 * <Badge color="transparent">Transparent</Badge>
 * <Badge color="ghost">Ghost</Badge>
 */
const Ribbon: React.FC<BadgeRibbonProps> = ({
  children,
  rounded,
  position,
  size = 'md',
  color,
  className,
  testId,
  ...props
}): JSX.Element => {
  const classes = clsx('badge-ribbon', className, {
    [`ribbon-${position}`]: position,
    [`ribbon-${color}`]: color,
    [`ribbon-${size}`]: size,
  });

  return (
    <div aria-label="Badge Ribbon" {...props} className={classes} data-testid={testId}>
      {children ?? <>&nbsp;</>}
    </div>
  );
};

Ribbon.displayName = 'Ribbon';

export default Ribbon;

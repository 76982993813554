import React, { memo, useRef } from 'react';

import { Confetti, ConfettiRef, Modal } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import WarningTemplate from '@Components/template/WarningTemplate';
import SuccessContent from '@Modules/otc/otcTrade/SuccessContent';

type Props = {
  active: boolean;
  setActive: (active: boolean) => void;
  callback: () => void;
};

const SuccessOfferModal = ({ callback, active, setActive }: Props) => {
  const { t } = useTranslation();
  const particleRef = useRef<ConfettiRef>(null);

  if (active) {
    setTimeout(() => particleRef.current?.boom?.(), 100);
  }

  return (
    <Modal open={active} onClose={() => setActive(false)}>
      <WarningTemplate icon="completed-big" onAccept={callback} confirmText={t('otc.success-modal.confirm')}>
        <SuccessContent />
        <Confetti ref={particleRef} />
      </WarningTemplate>
    </Modal>
  );
};
export default memo(SuccessOfferModal);

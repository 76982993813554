import React from 'react';

import { DialogTitle } from '@headlessui/react';

type Props = {
  /** The content to be displayed as the title. */
  children: React.ReactNode;
};

/**
 * The `ModalTitle` component is used to render the title of a modal.
 *
 * @example
 * <ModalTitle>Title</ModalTitle>
 */
const ModalTitle = ({ children }: Props) => {
  return (
    <DialogTitle as="h3" className="text-xl font-secondary font-bold leading-6 tw-text-secondary-500">
      {children}
    </DialogTitle>
  );
};

ModalTitle.displayName = 'ModalTitle';

export default ModalTitle;

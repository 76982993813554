import React from 'react';

import { IconAnimated, IconAnimatedProps, useDarkMode } from '@bilira-org/design';

/**
 * Custom animated icon component that renders icons based on type and theme
 *
 * @example
 * <AnimatedIcon type="empty-folder" size="sm" />
 */
export function AnimatedIcon({ type, ...rest }: IconAnimatedProps) {
  const [isDark] = useDarkMode();

  return <IconAnimated theme={isDark ? 'dark' : 'light'} type={type} {...rest} />;
}

import React, { forwardRef, ReactNode, useImperativeHandle, useLayoutEffect, useRef } from 'react';

import { create, Options, Reset } from 'canvas-confetti';

import { BaseComponentType } from '../types';

export interface ConfettiProps extends BaseComponentType {
  /** @deprecated */
  children?: ReactNode;
  /** Style of the confetti canvas */
  style?: React.CSSProperties;
}

export interface ConfettiRef {
  /** Booms the confetti. */
  boom: (options?: Options) => void;
  /** Resets the confetti. */
  reset: Reset;
}

const defaultOptions = {
  spread: 80,
  // origin: { y: 1.2 },
  drift: 0,
  gravity: 0.5,
  origin: { x: 0.5, y: 1 },
  // any other options from the global
  // confetti function
};

/**
 * Confetti component for creating a confetti animation effect.
 *
 *  Use the forwarded ref for accessing imperative methods to control the confetti.
 *
 * @example
 * // Example usage of Confetti component
 * const confettiRef = useRef(null);
 *
 * const handleBoom = () => {
 *   confettiRef.current?.boom();
 * };
 *
 * return (
 *   <div>
 *     <button onClick={handleBoom}>Boom</button>
 *     <Confetti ref={confettiRef} />
 *   </div>
 * );
 */
const Confetti = forwardRef<ConfettiRef, ConfettiProps>(
  ({ testId, style }: ConfettiProps, ref?: React.ForwardedRef<ConfettiRef>) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const confettiRef = useRef<ConfettiRef>({
      boom: (options?: Options) => console.log('allocated'),
      reset: () => console.log('allocated'),
    });

    useLayoutEffect(() => {
      if (canvasRef.current) {
        const myConfetti = create(canvasRef.current, {
          resize: true,
          useWorker: true,
        });
        confettiRef.current.boom = myConfetti;
        confettiRef.current.reset = myConfetti.reset;
      }
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        boom: (options: Options = defaultOptions, timeout = 3000) => {
          if (canvasRef.current) {
            canvasRef.current.style.height = `100%`;
            canvasRef.current.style.width = `100%`;
          }
          confettiRef.current?.boom?.(options);

          setTimeout(() => {
            if (canvasRef.current) {
              canvasRef.current.style.height = `0`;
              canvasRef.current.style.width = `0`;
              confettiRef.current?.reset();
            }
          }, timeout);
        },
        reset: () => {
          confettiRef.current?.reset?.();
        },
      }),
      [],
    );

    return (
      <>
        <canvas
          className="fixed top-0 left-0 w-0 h-0 -z-10"
          style={style}
          ref={canvasRef}
          data-testid={testId ?? 'confetti'}
        />
      </>
    );
  },
);

export default Confetti;

import React from 'react';

import { LayoutV2 } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import TransactionPanelCard from '@Components/transaction/TransactionPanelCard';
import DepositInfoBar from '@Modules/fiat/components/DepositInfoBar';
import FiatDeposit from '@Modules/fiat/deposit/components/FiatDeposit';

const { Content, InnerContent, Aside } = LayoutV2;
const FiatDepositContainer = () => {
  const { t } = useTranslation();
  return (
    <>
      <Content>
        <InnerContent>
          <DepositInfoBar />
          <FiatDeposit />
        </InnerContent>
        <Aside>
          <TransactionPanelCard
            title={t('common.recent-deposit-transactions')}
            limit={5}
            type="fiat"
            direction="deposit"
            showAllButton
          />
        </Aside>
      </Content>
    </>
  );
};

export default FiatDepositContainer;

import React, { memo, useMemo } from 'react';

import { Alert, Block, Empty, List, Meta, Skeleton, Text } from '@bilira-org/design';
import { concatTicker, CryptoAssetsType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { StoredAssetType } from '@Components/globalSearch/types';
import IconCoin from '@Components/icon/IconCoin';
import H24ChangeLive from '@Components/live/H24ChangeLive';
import LastPriceLive from '@Components/live/LastPriceLive';
import CustomPriceSubscription from '@Components/live/subscription/GlobalSearchSubscription';

type Props = {
  searchText: string;
  onSelected: (item: StoredAssetType) => void;
  data: CryptoAssetsType[] | undefined;
  isPending: boolean;
};

const SearchResultList = memo(({ searchText, onSelected, data, isPending }: Props) => {
  const { t } = useTranslation();

  const isPendingOrHasData = isPending || (data && data?.length > 0);

  const subscriptionList = useMemo(() => data?.map((d) => concatTicker(d.symbol, 'TRYB')), [data]);

  return (
    <>
      {isPendingOrHasData && (
        <Text size="sm" weight="semibold" color="secondary-500" mb="sm">
          {t('common.crypto')}
        </Text>
      )}

      <CustomPriceSubscription channel="search-result" baseQuoteList={subscriptionList} />

      <List
        testId="search-result-list"
        empty={<Empty message={t('global-search.no-result', { search: searchText })} />}
        loading={isPending}
        skeleton={
          <Block row justify="between" p="sm">
            <Meta
              extra={<Skeleton rounded="full" width="size-9" height="size-9" />}
              title={<Skeleton width="size-16" height="size-3" />}
              description={<Skeleton width="size-24" height="size-3" />}
            />
            <Meta
              title={<Skeleton width="size-16" height="size-3" />}
              description={<Skeleton width="size-8" height="size-3" />}
            />
          </Block>
        }
        dataSource={data}
        space="xs"
        renderItem={(record) => (
          <List.Item
            key={`search-result-${record.symbol}-${record.name}`}
            px="sm"
            py="sm"
            hoverBgColor="neutral-300"
            onClick={() => onSelected({ symbol: record.symbol, name: record.name })}
            extra={
              <Block items="end">
                <LastPriceLive base={record.symbol} quote="TRYB" weight="medium" size="sm" />
                <H24ChangeLive base={record.symbol} quote="TRYB" size="sm" />
              </Block>
            }
          >
            <Meta
              extra={<IconCoin size="2xl" type={record.symbol} />}
              space="sm"
              gap="none"
              title={
                <Text size="sm" color="secondary-500" weight="semibold">
                  {record.symbol}
                </Text>
              }
              description={
                <Text size="sm" weight="regular">
                  {record.name}
                </Text>
              }
            />
          </List.Item>
        )}
      />
      {!isPending && !data?.length && (
        <Alert status="yellow" mt="xl" testId="crypto-listing-request-alert">
          {t('global-search.contact-us-for-crypto-listing-request', { email: 'support@bilira.co' })}
        </Alert>
      )}
    </>
  );
});

export default SearchResultList;

import React from 'react';

import { Card, Layout } from '@bilira-org/design';
import { Outlet } from 'react-router-dom';

import Header from './components/Header';

const { Head, Content } = Layout;
export default function OAuthCallbackLayoutLogin() {
  return (
    <Layout color="neutral-200">
      <Head>
        <Header />
      </Head>
      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
}

import React from 'react';

import { Badge, Empty, List, Meta, Text } from '@bilira-org/design';
import { NotificationType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGeneralStore } from '@/store/generalStore';
import UserQuery from '@Libs/clientInstances/userQuery';

type NotificationsType = {
  data: NotificationType[];
  onClose?: () => void;
};

/**
 * Component that lists notifications and handles opening notification modal
 */
const Notifications = ({ data, onClose }: NotificationsType) => {
  const { t } = useTranslation();
  const setNotificationModal = useGeneralStore((state) => state.setNotificationModal);

  const { mutateAsync } = UserQuery.usePatchNotifications();

  const onNotificationClick = (notification: NotificationType) => {
    if (!notification.read) {
      mutateAsync({ notifications: [notification.id] });
    }
    onClose?.();
    setNotificationModal({ open: true, notification: notification });
  };

  return (
    <List
      empty={<Empty message={t('navigation.notifications.empty-notification-message')} />}
      dataSource={data}
      space="none"
      renderItem={(record) => (
        <List.Item
          key={record.id}
          px="md"
          py="md"
          hoverBgColor="neutral-300"
          onClick={() => {
            onNotificationClick(record);
          }}
        >
          <Meta
            extra={<Badge size="xs" color={record.read ? 'neutral-400' : 'primary'} />}
            space="md"
            title={
              <Text size="sm" color="secondary-500" weight="medium">
                {record.data.title}
              </Text>
            }
            description={
              <Text size="xs" weight="regular">
                {record.data.description}
              </Text>
            }
          />
        </List.Item>
      )}
    />
  );
};

export default Notifications;

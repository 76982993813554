import React, { forwardRef, HTMLAttributes, useState } from 'react';

import { clsx } from 'clsx';

import StarOutline from '../../assets/icon/outline/star.svg';
import { BaseComponentType, BaseSize, TextColorType } from '../types';

export type RankColors = 'yellow' | 'neutral' | 'orange';

export type RatingProps = Omit<HTMLAttributes<HTMLDivElement>, 'color'> &
  BaseComponentType & {
    className?: string;
    variant?: 'rating';
    shape?: 'star';
    onClick?: (ranked?: boolean) => void;
    ranked?: boolean;
    disabled?: boolean;
    size?: 'md' | 'lg';
    color?: TextColorType;
  };

export type RankingProps = Omit<HTMLAttributes<HTMLDivElement>, 'color'> &
  BaseComponentType & {
    /** Additional classes to be added to the Rank. */
    className?: string;
    /** The variant of the Rank. */
    variant?: 'badge';
    shape?: 'polygon' | 'circle';
    /** The size of the Rank. */
    size?: BaseSize;
    /** The color of the Rank. */
    color?: RankColors;
  };

/**
 * Rank component
 *
 * @example
 *
 * <Rank color="neutral" size="lg">Neutral 600</Rank>
 * <Rank color="orange" transparent>Orange</Rank>
 * <Rank color="red" responsive>Red</Rank>
 * <Rank color="green" bordered>Green</Rank>
 */
const Ranking = forwardRef<HTMLDivElement, RankingProps>(
  (
    { children, variant = 'badge', shape = 'polygon', size = 'sm', color, className, testId, onClick, ...props },
    ref,
  ): JSX.Element => {
    const classes = clsx('rank-badge', className, {
      [`rank-${shape}`]: shape,
      [`rank-${color}`]: color,
      [`rank-${size}`]: size,
    });

    return (
      <div aria-label="Rank" {...props} className={classes} ref={ref} data-testid={testId}>
        <span> {children}</span>
      </div>
    );
  },
);

const Rating = forwardRef<HTMLDivElement, RatingProps>(
  ({ onClick, ranked, testId, disabled, size = 'md', color = 'neutral-500', ...props }, ref) => {
    const [checkedState, setChecked] = useState<boolean>(!!ranked);
    const checked = ranked !== undefined ? ranked : checkedState;

    const onRankClick = () => {
      if (!disabled) {
        setChecked(!checked);
        onClick?.(!checked);
      }
    };

    return (
      <div aria-label="Rank" {...props} ref={ref} data-testid={testId} className="pointer" onClick={onRankClick}>
        <StarOutline
          aria-checked={checked}
          className={clsx('rank-rating', {
            [`tw-text-${color}`]: color,
            [`rank-rating-${size}`]: size,
          })}
        />
      </div>
    );
  },
);

export type RankProps = { controlled?: boolean } & (
  | (RankingProps & { variant?: 'badge' })
  | (RatingProps & { variant: 'rating' })
) &
  BaseComponentType;

const Rank = forwardRef<HTMLDivElement, RankProps>(({ variant, ...props }, ref) => {
  if (variant === 'rating') {
    return <Rating ref={ref} {...(props as RatingProps)} />;
  }

  return <Ranking ref={ref} {...(props as RankingProps)} />;
});

Rank.displayName = 'Rank';

export default Rank;

import { useState } from 'react';

import { clsx } from 'clsx';

import AccordionGroup from './group';
import AccordionItem, { AccordionItemProps } from './item';
import { AccordionContext } from './useAccordionContext';
import Flex, { FlexProps } from '../flex/flex';
import { BaseComponentType } from '../types';

export interface AccordionProps extends FlexProps, BaseComponentType {
  /** Whether to allow multiple accordions to be open at the same time. */
  allowMultiple?: boolean;
  /** The variant of the accordion */
  variant?: 'primary' | 'secondary' | 'filled' | 'filled-theme';
  /** The icon props to pass to the AccordionItem component. */
  iconProps?: AccordionItemProps['iconProps'];
}

/**
 * Accordion component that controls the open/closed state of accordion items.
 *
 * @example
 * // Multiple accordions can be open at the same time
 * <Accordion allowMultiple variant="primary">
 *   <Accordion.Item title="Title 1">
 *     Content 1
 *   </Accordion.Item>
 *   <Accordion.Item title="Title 2">
 *     Content 2
 *   </Accordion.Item>
 * </Accordion>
 *
 * // Only one accordion can be open at a time
 * <Accordion variant="secondary">
 *   <Accordion.Item title="Title 1">
 *     Content 1
 *   </Accordion.Item>
 *   <Accordion.Item title="Title 2">
 *     Content 2
 *   </Accordion.Item>
 * </Accordion>
 */
const Accordion = ({
  allowMultiple,
  children,
  variant = 'primary',
  className,
  testId,
  iconProps,
  ...rest
}: AccordionProps) => {
  const [activeAccordions, setActiveAccordions] = useState<Map<string, true>>(new Map());

  /**
   * Toggles the open/closed state of an accordion.
   *
   * @param {string} key - The key of the accordion to toggle.
   */
  const toggleAccordion = (key: string) => {
    let newActiveAccordions = new Map(activeAccordions);
    const isAccordionActive = newActiveAccordions.get(key);
    if (isAccordionActive) {
      newActiveAccordions.delete(key);
    } else {
      if (allowMultiple) {
        newActiveAccordions.set(key, true);
      } else {
        newActiveAccordions = new Map([[key, true]]);
      }
    }
    setActiveAccordions(newActiveAccordions);
  };

  return (
    <AccordionContext.Provider value={{ activeAccordions, toggleAccordion, variant, iconProps }}>
      <Flex
        gap="md"
        className={clsx(`accordion accordion-${variant}`, className)}
        data-testid={testId ?? 'accordion'}
        {...rest}
      >
        {children}
      </Flex>
    </AccordionContext.Provider>
  );
};
const AccordionWithItem: typeof Accordion & {
  Item: typeof AccordionItem;
  Group: typeof AccordionGroup;
} = Object.assign(Accordion, { Item: AccordionItem, Group: AccordionGroup });

Accordion.displayName = 'Accordion';

export default AccordionWithItem;

import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import RedirectSpinCard from '@/components/common/RedirectSpinCard';
import { useAuthParamsStore } from '@/libs/hooks/useAuthParams';

const Invitation = () => {
  const { t } = useTranslation();
  const { code } = useParams();
  const navigate = useNavigate();
  const { setGeneralParams } = useAuthParamsStore();

  useEffect(() => {
    if (code) {
      setGeneralParams({ ref: code });
    }

    const timeoutId = setTimeout(() => {
      navigate('/');
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [code, navigate, setGeneralParams]);

  return <RedirectSpinCard title={t('redirect.please-wait')} description={t('redirect.redirecting')} />;
};

export default Invitation;

import React from 'react';

import { Flex, Hidden, Logo, Space } from '@bilira-org/design';
import { Link } from 'react-router-dom';

import PrivateHeaderButtons from '@Components/layouts/components/components/PrivateHeaderButtons';
import PublicHeaderButtons from '@Components/layouts/components/components/PublicHeaderButtons';
import Search from '@Components/layouts/components/components/Search';
import HeaderNavBar from '@Components/layouts/components/HeaderNavBar';

const Header = () => {
  return (
    <Flex direction="row" justify="between">
      <Space space="md">
        <Link to="/">
          <Logo variant="kripto-text-icon" />
        </Link>

        <HeaderNavBar hiddenProps={{ show: { xl: true } }} />
        <Hidden show={{ '2xl': true }}>
          <Search />
        </Hidden>
      </Space>
      <PrivateHeaderButtons />
      <PublicHeaderButtons />
    </Flex>
  );
};

export default Header;

import BigNumber from 'bignumber.js';

import { stringifyCurrency } from '../currency';
import { defaultFormatOption, formatOptionsMap } from '../currency/formatOptionsMap';
import { LanguageUniversalType } from '../types';

export type FormatNumberByPriceType = 'price' | 'amount';
export type TresholdType = { limit: BigNumber; price: { precision: number }; amount: { precision: number } };

/**
 * Thresholds for the formatting
 * @see https://drive.google.com/open?id=1FwD29rWfhddaVxBIC6pi8ZB4LURAaUn6V2c2gwsm4KE&usp=chrome_ntp
 */
const thresholds: TresholdType[] = [
  { limit: BigNumber(100000), price: { precision: 0 }, amount: { precision: 8 } },
  { limit: BigNumber(10000), price: { precision: 1 }, amount: { precision: 7 } },
  { limit: BigNumber(1000), price: { precision: 2 }, amount: { precision: 6 } },
  { limit: BigNumber(100), price: { precision: 3 }, amount: { precision: 5 } },
  { limit: BigNumber(10), price: { precision: 4 }, amount: { precision: 4 } },
  { limit: BigNumber(1), price: { precision: 4 }, amount: { precision: 3 } },
  { limit: BigNumber(0.1), price: { precision: 4 }, amount: { precision: 2 } },
  { limit: BigNumber(0.01), price: { precision: 4 }, amount: { precision: 1 } },
  { limit: BigNumber(-Infinity), price: { precision: 4 }, amount: { precision: 0 } },
];

export interface FormatNumberByPriceProps {
  /** Price of the currency */
  price: string | number;
  /** The value to be formatted. */
  value?: string | number;
  /** The default value to be used if the input value is empty. */
  defaultValue?: string | number;
  /** The locale for formatting. */
  locale: string;
  /** Type of the value to determine formatting precision */
  type: FormatNumberByPriceType;
  /** The format to be used for stringifying the currency.
   * @see http://numeraljs.com/#format
   * */
  stringifyFormat?: string;
}

/**
 * Formats the value based on the given price and type and locale.
 *
 * @returns The formatted value
 *
 * @example
 *
 * getFormattedNumberByPrice({ price: 990, type: 'price', locale: 'tr', value: 10.123456789 }) // '10,123';
 * getFormattedNumberByPrice({ price: 990, type: 'amount', locale: 'tr', value: 10.123456789 }) // '10,12345';
 *
 *
 */
export function getFormattedNumberByPrice({
  price,
  type,
  value,
  locale,
  defaultValue = '',
}: Omit<FormatNumberByPriceProps, 'stringifyFormat'>) {
  if (value === undefined || value === null) {
    return defaultValue as string;
  }

  const bigValue = BigNumber(price);
  const threshold = thresholds.find((threshold) => bigValue.isGreaterThan(threshold.limit));

  //TODO: Can threshold be null?;
  return format({ value, precision: threshold?.[type]?.precision || 0, locale });
}

/**
 * Formats a value based on the given price and type, and optionally applies a custom string format.
 * @returns The formatted values
 *
 * @example
 *
 * formatNumberByPrice({ price: 990, type: 'price', locale: 'tr', value: 10.123456789 }) // {value:'10,123', values:['10','123'], shortValue:undefined};
 * formatNumberByPrice({ price: 990, type: 'amount', locale: 'tr', value: 10.123456789 }) // {value:'10,12345', values:['10','12345'], shortValue:undefined};
 *
 * formatNumberByPrice({ price: 990, type: 'price', locale: 'tr', value: 1000000, stringifyFormat: '0[.]00a' })
 * // '{value:'1.000.000,000', values:['1.000.000','000'], shortValue:'1M'}
 *
 *
 */
export function formatNumberByPrice(props: FormatNumberByPriceProps) {
  let numeralFormattedValue: string | undefined;

  if (props.stringifyFormat) {
    const result = stringifyCurrency({
      value: props.value,
      locale: props.locale,
      format: props.stringifyFormat,
    });
    if (result !== props.value) {
      numeralFormattedValue = result;
    }
  }
  const bigValue = getFormattedNumberByPrice(props);

  /** Get decimal separator of the locale format to split integer and decimal part of the formatted number */
  const options = formatOptionsMap[props.locale as LanguageUniversalType];

  const decimalSeparator = options.decimalSeparator as string;

  const [integerPart, decimalPart] = bigValue.split(decimalSeparator);
  const composedDecimalPart = decimalPart ? `${decimalSeparator}${decimalPart}` : undefined;

  return {
    values: [integerPart, composedDecimalPart],
    value: bigValue,
    shortValue: numeralFormattedValue,
  };
}

interface FormatProps {
  /** The value to be formatted. */
  value: string | number;
  /** Formatting precision */
  precision: number;
  /** The locale for formatting. */
  locale: string;
}

/**
 * Formats value by customized precision logic
 *
 * a. If the value is less between -1 and 1, (value has no integer part):
 *   - Value will be formatted to precision, if result's precision does not satisfy the target precision
 *     0's will be added to the end of result.
 *     0.04 -> precision 2 -> 0.040
 *     0.000123 -> precision 2 -> 0.00012
 * b. If the value is greater than 1 or less than -1 (value has integer part)
 *   - Value will be formatted to decimal places, if result's decimal places does not satisfy the target precision
 *     0's will be added to the end of result.
 *      10.1 -> precision 2 -> 10.10
 *      1234.123 -> precision 3 -> 1,234.123
 *
 * For example format results check spreadsheet below.
 * @see https://docs.google.com/spreadsheets/d/1A9sOi69oJ1Uq9Ya2kZQmpus47JgdxqJWOTjIJ6UCfrc/edit?usp=sharing
 */
export function format({ value, precision, locale }: FormatProps) {
  const bigValue = BigNumber(value);

  const formatOptions = formatOptionsMap[locale as LanguageUniversalType];

  if (bigValue.gte(1) || bigValue.lte(-1) || precision === 0) {
    return bigValue.toFormat(precision, BigNumber.ROUND_DOWN, formatOptions);
  } else {
    const result = bigValue.precision(precision, BigNumber.ROUND_DOWN);

    const precisionOffset = precision - result.precision();
    return result.toFormat((result.decimalPlaces() || 1) + precisionOffset, BigNumber.ROUND_DOWN, formatOptions);
  }
}

import { useGeneralStore } from '@/store/generalStore';

/**
 * Custom hook to calculate the total height of the header based on the heights
 * stored in the general store.
 *
 * @returns {number} The total height of the header.
 */
const useHeaderHeight = () => {
  const { headerHeights } = useGeneralStore();

  const totalHeight = Object.values(headerHeights).reduce((acc, cur) => acc + cur, 0);

  return totalHeight;
};

export default useHeaderHeight;

import React, { useCallback, useRef, useState } from 'react';

import { Alert, Block, Button, Modal, notification, Stepper } from '@bilira-org/design';
import { getErrorMessage } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import WarningTemplate from '@Components/template/WarningTemplate';
import AuthenticatorOTP from '@Components/TwoFA/components/AuthenticatorOTP';
import TwoFA from '@Components/TwoFA/components/TwoFA';
import UserQuery from '@Libs/clientInstances/userQuery';
import { OTP_CODE_LENGTH } from '@Libs/config';
import WarningContent from '@Modules/account/security/twoFA/authenticator/components/WarningContent';

interface Props {
  modalActive: boolean;
  setModalActive: (active: boolean) => void;
}

function DisableAuthenticatorModal({ modalActive, setModalActive }: Props) {
  const { t } = useTranslation();
  const { mutateAsync, failureReason } = UserQuery.usePostVerifyTOTP();
  const twoFATokenRef = useRef<string>('');
  const [currentStep, setCurrentStep] = useState(0);

  const [codeCompleted, setCodeCompleted] = useState(false);
  const twoFAItemRef = useRef<string>('');

  const handleValidation = (token: string) => {
    twoFATokenRef.current = token;
    changeStep();
  };

  const inputCallback = useCallback((value: string) => {
    twoFAItemRef.current = value;
    const completed = twoFAItemRef.current.length === OTP_CODE_LENGTH;
    setCodeCompleted(completed);
  }, []);

  const callback = () => {
    mutateAsync({ two_fa_token: twoFATokenRef.current, enable: false, code: twoFAItemRef.current }).then(() => {
      notification.success(t('two-fa.disabled-two-fa'));
      onModalClose();
    });
  };

  const changeStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const onModalClose = () => {
    setCurrentStep(0);
    setModalActive(false);
  };

  const onEnter = codeCompleted ? callback : undefined;

  const failureMessage = getErrorMessage(failureReason);

  return (
    <>
      <Modal
        title={currentStep === 0 ? null : t('two-fa.security-verification')}
        titleSeparator={currentStep !== 0}
        onClose={onModalClose}
        open={modalActive}
      >
        <Stepper step={currentStep}>
          <Stepper.Item key="authenticator-step-0">
            <WarningTemplate
              onAccept={changeStep}
              onCancel={() => setModalActive(false)}
              cancelText={t('two-fa.totp-warning-cancel')}
              confirmText={t('two-fa.totp-warning-accept')}
            >
              <WarningContent />
            </WarningTemplate>
          </Stepper.Item>
          <Stepper.Item key="authenticator-step-1">
            {failureMessage && (
              <Alert status="red" mb="md">
                {failureMessage}
              </Alert>
            )}
            <Block mt="md">
              <TwoFA
                type="totpSettings"
                twoFAData={{ email: true, totp: false, sms: false }}
                callback={handleValidation}
              />
            </Block>
          </Stepper.Item>

          <Stepper.Item key="authenticator-step-3">
            <Block gap="md">
              <Block>
                <AuthenticatorOTP
                  validationMessage={getErrorMessage(failureReason) as string}
                  callback={inputCallback}
                  onEnter={onEnter}
                />
              </Block>

              <Button
                mt="4xl"
                disabled={!codeCompleted}
                size="md"
                stretch
                justify="center"
                variant="filled"
                onClick={callback}
              >
                {t('common.accept')}
              </Button>
            </Block>
          </Stepper.Item>
        </Stepper>
      </Modal>
    </>
  );
}

export default DisableAuthenticatorModal;

import { ReactNode, useMemo } from 'react';

import { ColumnDef, flexRender, getCoreRowModel, Table, useReactTable } from '@tanstack/react-table';

import DataGridContainer from './container';
import DataGridEntry from './entry';
import DataGridField from './field';
import { Optional, XOR } from '../../../../custom';
import { DirectionType } from '../../../../types';
import EmptyState from '../dataTable/empty';
import { TableBodyStyle } from '../dataTable/types';
import { getRowId, highlightFn } from '../helpers';

interface CommonProps<T> {
  /** Data to be displayed in the grid */
  data?: T[];
  /** Column definitions for the grid */
  columns: ColumnDef<T, any>[];
  /** Loading state for the grid */
  loading?: boolean;
  /** Identifier for each row, can be a key or a function */
  rowId?: keyof T | ((row: T) => string);
  /** Number of skeleton rows to display while loading */
  skeletonRowCount?: number;
  /** Whether to show a divider between grid entries */
  divider?: boolean;
  /** Message or element to display when the grid is empty */
  emptyMessage?: ReactNode;
  /** Layout direction for the grid */
  layout?: DirectionType;
  /** Variant of row highlighting, either 'bordered' or 'marker' */
  highlightVariant?: 'bordered' | 'marker';
  /** Key or function to determine highlighted rows */
  highlightedRowKey?: string | ((entry: T) => boolean);
  /** Style properties for the grid body */
  bodyStyle?: TableBodyStyle;
}

interface TableProvidedProps<T> {
  table: Table<T>;
}

interface ColumnsProvidedProps<T> {
  columns: ColumnDef<T, any>[];
}

export type DataGridProps<T> = XOR<TableProvidedProps<T>, ColumnsProvidedProps<T>> & CommonProps<T>;

/**
 * Renders the base DataGrid component with a pre-configured table instance.
 */
function DataGridBase<T>({
  table,
  divider,
  loading,
  emptyMessage = 'No Entries Found',
  highlightVariant = 'bordered',
  highlightedRowKey,
  bodyStyle,
}: CommonProps<T> & TableProvidedProps<T>) {
  if (table.getRowCount() === 0 && !loading) {
    return <EmptyState emptyMessage={emptyMessage} />;
  }

  return (
    <DataGridContainer divider={divider}>
      {table.getRowModel().flatRows.map((row) => (
        <DataGridEntry
          key={row.id}
          divider={divider}
          highlighted={highlightFn({ row: row.original, rowId: row.id, highlightedRowKey })}
          highlightVariant={highlightVariant}
        >
          {table.getFlatHeaders().map((header) => {
            const cell = row.getAllCells().find((cell) => cell.column.id === header.id);

            return (
              <DataGridField
                key={header.id}
                label={flexRender(header.column.columnDef.header, header.getContext())}
                value={
                  cell &&
                  flexRender(
                    loading ? header.column.columnDef.skeleton : header.column.columnDef.cell,
                    cell.getContext(),
                  )
                }
                show={cell?.column.columnDef.show}
                px={bodyStyle?.px}
                py={bodyStyle?.py}
                mt={bodyStyle?.mt}
              />
            );
          })}
        </DataGridEntry>
      ))}
    </DataGridContainer>
  );
}

/**
 * Renders the DataGrid component, either using a provided table instance or generating one internally.
 */
function DataGrid<T>({ table, ...rest }: Optional<DataGridProps<T>, 'table'>) {
  if (!table) {
    return <DataGridWithHook {...rest} />;
  } else {
    return <DataGridBase table={table} {...rest} />;
  }
}

/**
 * Renders the DataGrid component, creating a table instance internally using hooks.
 */
function DataGridWithHook<T>(props: Optional<DataGridProps<T>, 'table'>) {
  const { loading, skeletonRowCount, columns, rowId, data: dataFromProps = [] } = props;
  const data = useMemo(
    () => (loading ? (new Array(skeletonRowCount).fill(false) as T[]) : dataFromProps),
    [dataFromProps, loading, skeletonRowCount],
  );
  const table = useReactTable({
    data: data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: getRowId({ rowId, loading }),
  });
  return <DataGridBase {...props} table={table} />;
}

export default DataGrid;

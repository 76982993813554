import { ReactElement, ReactNode, useMemo } from 'react';

import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';

import TableHeader from './tableHeader';
import TableRow from './tableRow';
import { Display } from '../display';
import { BaseComponentType, BreakpointType, PaddingSizes, ResponsiveReturnType, SizeType } from '../types';

type ColumnStyle = {
  verticalAlign?: 'top' | 'bottom' | 'middle' | 'baseline';
  width: SizeType;
};

export type TTableColumn<TEntry> = {
  title: ReactNode;
  field: keyof TEntry;
  cell?(entry: TEntry): ReactElement;
  style?(entry?: TEntry): Partial<ColumnStyle>;
  skeleton?: ReactElement;
  hidden?: boolean;
  align?: 'left' | 'right' | 'center';
};

type HeaderStyle = {
  showBackground: boolean;
  py: PaddingSizes;
  px: PaddingSizes;
};

/**
 * @deprecated Use DataTable.Responsive component instead
 *
 * Represents the properties of a table component.
 * @template TEntry - The type of data entries in the table.
 */
export type TTableProps<TEntry> = BaseComponentType & {
  /** The array of data entries to be displayed in the table. */
  data: TEntry[] | undefined;
  /** Row's unique key field */
  rowKey?: keyof TEntry | 'id';
  /** The configuration of columns in the table. */
  columns: TTableColumn<TEntry>[];
  /** Whether the table should stretch to fill available space.
   * If a `BreakpointType` is provided, it will control stretch based on screen size.
   */
  stretch?: boolean | BreakpointType<boolean>;
  /** The padding size for the body rows of the table.  */
  bodySpace?: PaddingSizes;
  /** Whether to display the header row. Defaults to `true`. */
  displayHeader?: boolean;
  /** Padding y-axis for the table */
  py?: PaddingSizes;
  /** Padding x-axis for the table */
  px?: PaddingSizes;
  /** The message to be displayed when no entries are found  */
  emptyMessage?: string | ReactElement;
  /** Padding bottom of the table header */
  headerPb?: PaddingSizes;
  /** Padding y-axis of the cells */
  cellPy?: PaddingSizes;
  /** Additional styling options for the table header. */
  headerStyle?: Partial<HeaderStyle>;
  /** Additional styling options for the table body. */
  bodyStyle?: Partial<HeaderStyle>;
  /** Whether to display divider between rows */
  divider?: boolean;
  /** Whether the table is loading */
  loading?: boolean;
  skeletonRowCount?: number;
  /** Whether the cell is displayed as a row or column in smaller screens */
  responsiveCellLayout?: 'row' | 'column';
  /** Unique identifier of the highlighted row */
  highlightedRowKey?: string | ((entry: TEntry) => boolean);
  /** Callback to triggered when a row is clicked */
  onRowClick?: (data: TEntry) => void;
  /** Whether to fix first column in position */
  fixFirstColumn?: boolean;
  hoverVariant?: 'normal' | 'indicator';
  contentHeight?: SizeType;
  /** Whether tab content should be scrollable */
  scrollable?: boolean;
  highlightVariant?: 'bordered' | 'marker';
  responsive?: boolean;
};

/**
 * A customizable table component for displaying data with support for responsive design, loading states,
 * and skeleton loading rows.
 *
 * @example
 * <Table
 *   data={[
 *     { id: 1, name: 'John Doe', age: 28 },
 *     { id: 2, name: 'Jane Smith', age: 24 },
 *     { id: 3, name: 'Bob Smith', age: 35 },
 *   ]}
 *   columns={[
 *     { title: 'ID', field: 'id', skeleton:<Skeleton /> },
 *     { title: 'Name', field: 'name', skeleton:<Skeleton /> },
 *     { title: 'Age', field: 'age', skeleton:<Skeleton /> },
 *   ]}
 *   stretch
 *   responsive
 *   loading={false}
 *   skeletonRowCount={5}
 *   highlightedRowKey='1'
 *   rowPadding="md"
 *   onRowClick={(data) => console.log('Clicked row:', data)}
 *   emptyMessage="No entries found"
 * />
 */
const Table = <TEntry,>({
  data,
  columns,
  bodySpace,
  py,
  px,
  stretch = true,
  displayHeader = true,
  emptyMessage = 'No Entries Found',
  headerPb,
  cellPy = 'sm',
  headerStyle,
  bodyStyle,
  divider,
  testId,
  loading,
  skeletonRowCount,
  responsiveCellLayout = 'row',
  rowKey = 'id',
  highlightedRowKey,
  contentHeight,
  onRowClick,
  fixFirstColumn,
  hoverVariant = 'normal',
  scrollable,
  highlightVariant,
  responsive = true,
}: TTableProps<TEntry>) => {
  let stretchClass: ResponsiveReturnType;
  if (typeof stretch === 'object') {
    stretchClass = {
      [stretch['xs'] ? `xs:w-full` : `xs:w-auto xs:border-separate xs:border-spacing-x-12`]: true,
      [stretch['sm'] ? `sm:w-full` : `sm:w-auto sm:border-separate sm:border-spacing-x-12`]: true,
      [stretch['md'] ? `md:w-full` : `md:w-auto md:border-separate md:border-spacing-x-12`]: true,
      [stretch['lg'] ? `lg:w-full` : `lg:w-auto lg:border-separate lg:border-spacing-x-12`]: true,
    };
  } else {
    stretchClass = { [`w-full`]: !!stretch };
  }

  const classes = clsx('tw-table', stretchClass, {
    [`py-${py}`]: py,
    [`px-${px}`]: px,
    [`table-hover-${hoverVariant}`]: hoverVariant,
    'table-divider': divider,
    'tw-table-responsive': responsive,
  });

  const availableColumns = columns.filter((column) => !column.hidden);

  const rows = useMemo(
    () => (loading ? (new Array(skeletonRowCount).fill('') as TEntry[]) : data),
    [loading, skeletonRowCount, data],
  );

  if (!data?.length && !loading) {
    return typeof emptyMessage === 'string' ? (
      <div className="tw-bg-theme-wn tw-text-neutral-500 h-80 flex justify-center items-center flex-col">
        <ArchiveBoxIcon className="h-16 w-16" />
        <h4>{emptyMessage}</h4>
      </div>
    ) : (
      <>{emptyMessage}</>
    );
  }

  const highlightFn = (entry: TEntry): boolean => {
    if (!entry) {
      return false;
    }

    if (typeof highlightedRowKey === 'string') {
      return highlightedRowKey === entry[rowKey as keyof TEntry];
    }

    if (typeof highlightedRowKey === 'function') {
      return highlightedRowKey(entry);
    }

    return false;
  };

  return (
    <div className={clsx({ [`tw-h-${contentHeight}`]: contentHeight, 'tw-table-container': scrollable })}>
      <div className={classes} data-testid={testId ?? 'table'}>
        <Display show={displayHeader}>
          <TableHeader
            columns={availableColumns}
            headerPb={headerPb}
            headerStyle={headerStyle}
            fixFirstColumn={fixFirstColumn}
          />
        </Display>

        <div className={clsx('tw-table-row-group')}>
          {rows?.map((entry, entryIndex) => (
            <TableRow
              onClick={onRowClick ? () => onRowClick(entry) : undefined}
              loading={loading}
              rowKey={rowKey}
              key={(entry?.[rowKey as keyof TEntry] as any) || entryIndex}
              entry={entry}
              bodySpace={bodySpace}
              bodyStyle={bodyStyle}
              cellPy={cellPy}
              columns={availableColumns}
              highlightVariant={highlightVariant}
              displayHeader={displayHeader}
              responsiveCellLayout={responsiveCellLayout}
              highlighted={highlightFn(entry)}
              fixFirstColumn={fixFirstColumn}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

Table.displayName = 'Table';

export default Table;

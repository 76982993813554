import React from 'react';

import { LayoutV2 } from '@bilira-org/design';

import DepositInfoBar from '@Modules/crypto/components/DepositInfoBar';
import Header from '@Modules/crypto/deposit/components/Header';
import CryptoDeposit from '@Modules/crypto/deposit/CryptoDeposit';
import LastCryptoDeposits from '@Modules/crypto/deposit/LastCryptoDeposits';

const { Content, InnerContent, Aside } = LayoutV2;

const CryptoDepositContainer = () => {
  return (
    <Content>
      <InnerContent>
        <DepositInfoBar />
        <Header />
        <CryptoDeposit />
      </InnerContent>
      <Aside>
        <LastCryptoDeposits />
      </Aside>
    </Content>
  );
};

export default CryptoDepositContainer;

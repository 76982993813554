import React from 'react';

import { Badge, Block, Breakpoints, Button, Flex, Hidden, Text, useWindowSize } from '@bilira-org/design';
import Breadcrumbs from '@bilira-org/design/src/components/breadcrumbs/breadcrumbs';
import { getOfferStatus, OTCType } from '@bilira-org/react-utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import IconCoin from '@/components/icon/IconCoin';
import { getOTCStatusConfig } from '@/libs/helpers/getStateConfig';
import { getShortId } from '@/libs/utils';

type Props = {
  entry: OTCType;
  onDetailClick: (id: string) => void;
};

const OTCRowPassive = ({ entry, onDetailClick }: Props) => {
  const { t, i18n } = useTranslation();
  const status = getOfferStatus(entry);
  const breakpoint = useWindowSize({ enable: true });
  return (
    <Flex
      direction="row"
      py="lg"
      px="2xl"
      gap="md"
      items="center"
      justify="between"
      onClick={['xs', 'sm'].includes(breakpoint || '') ? () => onDetailClick(entry.id) : undefined}
    >
      <Hidden show={{ md: true }}>
        <Flex direction="col">
          <Text size="xs" color="neutral-700">
            {dayjs(entry.created_at, { locale: i18n.language }).format('DD MMM YYYY,')}
          </Text>

          <Text size="xs" color="neutral-700">
            {dayjs(entry.created_at, { locale: i18n.language }).format('HH:mm')}
          </Text>
        </Flex>
      </Hidden>

      <Block gap="xs">
        <Hidden show={{ xs: true, sm: true, md: false }}>
          <Text size="xs" color="neutral-700">
            {dayjs(entry.created_at, { locale: i18n.language }).format('DD MMM YYYY, HH:mm')}
          </Text>
        </Hidden>

        <Breadcrumbs listType="disc">
          <Breadcrumbs.Item>
            <Text size="sm" color="secondary-500" weight="regular">
              #{getShortId(entry.id)}
            </Text>
          </Breadcrumbs.Item>

          <Breadcrumbs.Item>
            <Text size="sm" weight="semibold">
              {entry.pair?.replace('_', '/')}
            </Text>
          </Breadcrumbs.Item>
        </Breadcrumbs>

        <Hidden show={{ xs: false, sm: false, md: true }}>
          {entry.network && (
            <Badge size="md" variant="square" color="neutral-200" bordered width="size-fit">
              <IconCoin type={entry.network} />

              <Text color="neutral-700" weight="medium" size="sm" ml="xs">
                {entry.network}
              </Text>
            </Badge>
          )}
        </Hidden>

        <Hidden show={{ xs: true, sm: true, md: false }}>
          <Badge size="md" variant="square" color={getOTCStatusConfig(status).badgeBg} transparent bordered>
            <Badge size="xs" color={getOTCStatusConfig(status).badgeBg} />

            <Text ml="xs" as="span" size="xs" weight="medium" color="secondary-500">
              {t(`otc.status.${status}`)}
            </Text>
          </Badge>
        </Hidden>
      </Block>

      <Hidden show={{ xs: true, sm: true, md: false }}>
        {entry.network && (
          <Badge size="md" variant="square" color="neutral-200" bordered width="size-fit">
            <IconCoin type={entry.network} />

            <Text color="neutral-700" weight="medium" size="sm" ml="xs">
              {entry.network}
            </Text>
          </Badge>
        )}
      </Hidden>

      <Hidden show={{ xs: false, sm: false, md: true }}>
        <Badge size="md" variant="square" color={getOTCStatusConfig(status).badgeBg} transparent bordered>
          <Badge size="xs" color={getOTCStatusConfig(status).badgeBg} />

          <Text ml="xs" as="span" size="xs" weight="medium" color="secondary-500">
            {t(`otc.status.${status}`)}
          </Text>
        </Badge>
      </Hidden>

      <Hidden show={{ xs: false, sm: false, md: true }}>
        <Block row gap="sm" justify="end">
          <Button variant="outline" size="sm" onClick={() => onDetailClick(entry.id)}>
            {t(`otc.detail`)}
          </Button>
        </Block>
      </Hidden>
    </Flex>
  );
};

export default OTCRowPassive;

import { CoinSymbolType, parseAssetPair } from '@bilira-org/react-utils';

import { BuySellDirection } from '@Components/trade/types';

/**
 * Determines and returns the order of coin pairs depending on the transaction type (buying or selling).
 *
 * @param {BuySellDirection} type - The type of trade ('buy' or 'sell').
 * @param {`${CoinSymbolType}_${CoinSymbolType}`} assetPair - The pair of assets being traded.
 *
 * @returns {Array.<CoinSymbolType>} An array that contains the order of coin pairs.
 *
 * @example
 * // When buying ETH with USDT
 * getPairsByType('buy', 'ETH_USDT')
 * //returns: ['USDT', 'ETH', 'ETH', 'USDT']
 *
 * @example
 * // When selling USDT for ETH
 * getPairsByType('sell', 'ETH_USDT')
 * //returns: ['ETH', 'USDT', 'ETH', 'USDT']
 */
const getPairsByType = (
  type?: BuySellDirection,
  assetPair?: `${CoinSymbolType | undefined}_${CoinSymbolType | undefined}`,
): Array<CoinSymbolType> => {
  const [base, quote] = parseAssetPair(assetPair);
  switch (type) {
    case 'buy':
      return [quote, base, base, quote];
    case 'sell':
      return [base, quote, base, quote];
    default:
      return [base, quote, base, quote];
  }
};

export default getPairsByType;

import BigNumber from 'bignumber.js';

/**
 * Calculates the commission percentage by amount and fee.
 *
 * @param {string} amount - The total amount for which the commission percentage needs to be calculated.
 * @param {string} fee - The commission fee to be applied.
 * @param {number} [precision=6] - The precision to be used for formatting the commission percentage.
 * @returns {string} - The formatted commission percentage as a string.
 *
 * @example
 * getCommissionPercentage('100', '5'); // %5.00
 */
const getCommissionPercentage = (amount: string, fee: string, precision = 6) => {
  const bigValue = BigNumber(fee).multipliedBy(100).dividedBy(amount);

  if (bigValue.isGreaterThanOrEqualTo(0)) {
    return `%${bigValue.toFormat(2)}`;
  }

  return `%${bigValue.toPrecision(precision)}`;
};

export default getCommissionPercentage;

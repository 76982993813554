import React from 'react';

import { Block, Flex, Hidden, Icon, Meta } from '@bilira-org/design';
import { CoinSymbolType } from '@bilira-org/react-utils';

import FormattedNumberByPrice from '@/components/common/FormattedNumberByPrice';
import IconCoin from '@/components/icon/IconCoin';

type Props = {
  fromAsset: CoinSymbolType;
  fromAmount: string;
  toAsset: CoinSymbolType;
  toAmount: string;
  pairPrice: string;
  side: string;
};

const SwapInfo = ({ fromAmount, fromAsset, toAmount, toAsset, pairPrice, side }: Props) => {
  return (
    <Flex direction={{ xs: 'col', sm: 'row' }} items={{ xs: 'start', sm: 'center' }} gap="sm">
      <Meta
        space="sm"
        extra={<IconCoin type={fromAsset} size="xl" />}
        title={
          <FormattedNumberByPrice
            color="secondary-500"
            weight="medium"
            value={fromAmount}
            suffix={fromAsset}
            price={pairPrice}
            type={side === 'sell' ? 'amount' : 'price'}
            prefix="-"
          />
        }
      />
      <Hidden show={{ xs: false, sm: true }}>
        <Icon type="o:arrow-right" size="md" color="neutral-700" />
      </Hidden>
      <Hidden show={{ xs: true, sm: false }}>
        <Flex direction="row" justify="end">
          <Icon type="o:arrow-down" size="md" color="neutral-700" />
        </Flex>
      </Hidden>
      <Meta
        space="sm"
        extra={<IconCoin type={toAsset} size="xl" />}
        title={
          <FormattedNumberByPrice
            color="secondary-500"
            weight="medium"
            value={toAmount}
            suffix={toAsset}
            price={pairPrice}
            prefix="+"
            type={side === 'buy' ? 'amount' : 'price'}
          />
        }
      />
    </Flex>
  );
};

export default SwapInfo;

import { Flex, Group, Icon, List, Meta, Text, useDarkMode } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { LANGUAGES } from '@/libs/constants';
import { LanguageIcons } from '@/libs/constants/constantJsx';
import { useGeneralStore } from '@/store/generalStore';

/**
 * Popover content for displaying/updating user preferences
 */
const PreferencesContent = () => {
  const { t, i18n } = useTranslation();
  const [enabled, setEnabled] = useDarkMode();
  const setLanguageModalActive = useGeneralStore((state) => state.setLanguageModalActive);

  const language = LANGUAGES.find((d) => d.key === i18n.language);
  return (
    <>
      <List>
        <List.Item px="md" py="xs" hoverBgColor="neutral-300" onClick={() => setEnabled(!enabled)}>
          <Flex direction="row" justify="between" align="center" items="center" width="full">
            <Meta
              space="md"
              position="left-top"
              extra={<Icon color="neutral-700" type="o:stop-circle" size="lg" />}
              title={
                <Text size="sm" color="secondary-500" weight="regular">
                  {t('account.preferences.theme-title')}
                </Text>
              }
            />

            <Group
              selected={JSON.stringify(enabled)}
              onSelected={(selectedKey) => {
                setEnabled(JSON.parse(selectedKey));
              }}
            >
              <Group.Button type="icon" key="false">
                <Icon color="secondary-500" type="o:sun" size="md" />
              </Group.Button>
              <Group.Button type="icon" key="true">
                <Icon color="secondary-500" type="o:moon" size="md" />
              </Group.Button>
            </Group>
          </Flex>
        </List.Item>

        <List.Item px="md" py="md" hoverBgColor="neutral-300" onClick={() => setLanguageModalActive(true)}>
          <Meta
            space="md"
            position="left-top"
            extra={LanguageIcons[language?.key as LanguageType]}
            title={
              <Text size="sm" color="secondary-500" weight="regular">
                {t('navigation.profile.language')}: {language?.value}
              </Text>
            }
          />
        </List.Item>
      </List>
    </>
  );
};

export default PreferencesContent;

import React, { FC, memo, ReactNode, useMemo } from 'react';

import { clsx } from 'clsx';

import { createRoundedPath, createSparkline, getPathData } from './helpers';
import { BaseComponentType, BaseSize, TextColorType } from '../types';

export type PriceChartProps = BaseComponentType & {
  /** Array of prices to display in the sparkline */
  prices: number[];
  /** Color of the sparkline */
  color?: TextColorType;
  /** Size of the sparkline */
  size?: BaseSize;
  /** @deprecated */
  name?: string;
  /** @deprecated */
  children?: ReactNode;
  /** @deprecated */
  margin?: number;
};

/**
 * Sparkline component to display a simple line chart representing the price trend over time.
 *
 * @example
 * // Example usage of Sparkline component
 * <Sparkline prices={[50, 60, 70, 65, 80]} color="primary" size="small" />
 */
const Sparkline: FC<PriceChartProps> = ({ prices, color = 'neutral', size, testId }) => {
  const width = 64;
  const height = 30;

  const data = useMemo(() => createSparkline({ data: prices, width, height }), [prices]);
  const pathData: string = useMemo(() => createRoundedPath(data, 2, false), [data]);

  // const gradientId = `area-gradient`;

  return (
    <div
      className={clsx('sparkline', { [`sparkline-${color}`]: color, [`sparkline-${size}`]: size })}
      data-testid={testId ?? 'sparkline'}
    >
      <svg viewBox={`0 0 ${width} ${height}`} className="w-full h-full">
        {/*<defs>*/}
        {/*  <linearGradient id={gradientId} x1="0%" y1="0%" x2="50%" y2="70%">*/}
        {/*    <stop offset="0%" className={'start-color'} />*/}
        {/*    <stop offset="0%" className={'stop-color'} />*/}
        {/*  </linearGradient> fill={`url(#${gradientId})`}*/}
        {/*</defs>*/}
        <path strokeLinecap="round" className="line" d={pathData} fill="none" />
      </svg>
    </div>
  );
};

/**
 * Sparkline component to display a simple line chart representing the price trend over time.
 *
 * @component
 * @param {PriceChartProps} props - The properties for the Sparkline component.
 * @param {number[]} props.prices - Array of prices to display in the sparkline.
 * @param {TextColorType} [props.color='neutral'] - Color of the sparkline.
 * @param {BaseSize} [props.size] - Size of the sparkline.
 * @param {string} [props.testId] - Optional test ID for the component.
 * @returns {React.ReactElement} The rendered Sparkline component.
 * @example
 * <Sparkline
 *   prices={[50, 60, 70, 65, 80]}
 *   color="primary"
 *   size="small"
 * />
 */

const StoryOnlySparkline = (props: PriceChartProps): React.ReactElement => (
  <>
    <Sparkline {...props} />
  </>
);

StoryOnlySparkline.displayName = 'Sparkline';

export { StoryOnlySparkline };

export default memo(Sparkline);

import { Tour } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import useHeaderHeight from '@/libs/hooks/useHeaderHeight';
import { withPrivateRoute } from '@Libs/hocs';

import { useOnboardingTour } from './hooks/useOnboardingTour';

/**
 * Onboarding tour component responsible for displaying a series of tour steps.
 *
 * Synchronized with backend to control which onboarding tour should start
 */
function OnboardingTour() {
  const { t } = useTranslation();
  const { run, stepIndex, filteredSteps, handleCallback } = useOnboardingTour();
  const headerHeight = useHeaderHeight();

  return (
    <Tour
      callback={handleCallback}
      continuous
      run={run}
      stepIndex={stepIndex}
      steps={filteredSteps}
      disableOverlayClose
      disableScrollParentFix
      nextText={t('tour.next')}
      backText={t('tour.back')}
      finishText={t('tour.finish')}
      scrollOffset={headerHeight}
    />
  );
}

export default withPrivateRoute(OnboardingTour);

import { Block } from '../../block';

interface Props {
  /** Whether to show a divider between grid entries */
  divider?: boolean;
  /**  The content to be displayed inside the entry. */
  children: React.ReactNode;
}

/**
 * A container component for wrapping content in a data grid layout.
 */
function DataGridContainer({ divider, children }: Props) {
  return (
    <Block className="data-grid" gap={divider ? undefined : 'xs'}>
      {children}
    </Block>
  );
}

export default DataGridContainer;

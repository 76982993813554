/**
 * Checks if a word matches a given pattern.
 *
 * @param {string} word - The word to be checked.
 * @param {RegExp | number} pattern - The pattern to match against. If a number, checks if the word's length is greater than or equal to the number.
 * @returns {boolean} - `true` if the word matches the pattern, `false` otherwise.
 *
 * @example
 * isPatternMatching('hello', /^h/); // true
 * isPatternMatching('world', 5); // true
 * isPatternMatching('example', /z/); // false
 */
export const isPatternMatching = (word: string, pattern: RegExp | number): boolean => {
  if (typeof pattern === 'number') {
    return word.length >= pattern;
  }

  return !!word.match(pattern)?.length;
};

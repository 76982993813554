import React, { forwardRef, useState } from 'react';

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

import Input, { InputProps } from './input';
import fixIosInputFocus from '../../helpers/fixIosInputFocus';

export type PasswordProps = Omit<InputProps, 'type'>;

/**
 * InputPassword component for password input with an icon to toggle visibility.
 *
 * @example
 * <InputPassword value={value} onChange={handleChange} />
 */
const InputPassword = forwardRef<HTMLInputElement, PasswordProps>(
  ({ testId, onFocus, ...props }: InputProps, ref): JSX.Element => {
    const [isPassword, setIsPassword] = useState(true);

    return (
      <Input
        {...props}
        ref={ref}
        type={isPassword ? 'password' : 'text'}
        iconEnd={
          <div>
            {isPassword ? (
              <EyeIcon className="h-4 w-4 cursor-pointer" onClick={() => setIsPassword(!isPassword)} />
            ) : (
              <EyeSlashIcon className="h-4 w-4 cursor-pointer" onClick={() => setIsPassword(!isPassword)} />
            )}
          </div>
        }
        testId={testId ?? 'input-password'}
        onFocus={(e) => {
          fixIosInputFocus(e.currentTarget);
          onFocus?.(e);
        }}
      />
    );
  },
);

InputPassword.displayName = 'InputPassword';

export default InputPassword;

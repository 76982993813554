import React from 'react';

import PrivateReward from '@Modules/rewards/private';
import PublicReward from '@Modules/rewards/public';

const Index = () => {
  return (
    <>
      <PublicReward />
      <PrivateReward />
    </>
  );
};

export default Index;

import React from 'react';

import { Qr as QrFromDesign, QrProps, useDarkMode } from '@bilira-org/design';

type Props = Omit<QrProps, 'theme'>;

/**
 * Qr component generates a QR code based on the provided value.
 * * Extends QR component from design with dark mode preference
 *
 * @example
 * <Qr value="https://example.com" size={128} level="L" theme="light" />
 */
export function Qr(props: Props) {
  const [isDark] = useDarkMode();

  return <QrFromDesign theme={isDark ? 'dark' : 'light'} {...props} />;
}

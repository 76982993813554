import React, { useState } from 'react';

import { Alert, Block, Breadcrumbs, Icon, Label, Motion, Panel, Separator, Text } from '@bilira-org/design';
import { CryptoAssetsType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import useGetDisabledNetworks from '@/libs/hooks/useGetDisabledNetworks';
import NetworkModal from '@Components/cryptoCards/NetworkModal';
import { useWalletStore } from '@Components/walletAddress/components/store/walletStore';
import DepositWalletAddress from '@Modules/crypto/deposit/components/DepositWalletAddress';

import DepositLimits from '../DepositLimits';

type Props = {
  asset?: CryptoAssetsType;
};
const SelectWalletStep = ({ asset }: Props) => {
  const [modalActive, setModalActive] = useState(false);
  const { selectedNetwork } = useWalletStore();
  const { t } = useTranslation();

  const { disabled } = useGetDisabledNetworks({
    asset: asset?.symbol,
    network: selectedNetwork?.alias,
    operation: 'deposit',
  });

  return (
    <>
      <>
        <Text size="lg" weight="medium" mb="2xl" color="secondary-500">
          {t('crypto.deposit-address-step')}
        </Text>
        <Label>{t('crypto.select-network')}</Label>
        <Panel
          mb="2xl"
          size="md"
          border="neutral-400"
          color={asset ? undefined : 'neutral-200'}
          {...(asset ? { onClick: () => setModalActive(true) } : {})}
          testId="select-network-panel"
        >
          <Block row gap="md" items="center" justify="between">
            {selectedNetwork && (
              <Motion.Section layout="position" show={!!selectedNetwork}>
                <Breadcrumbs listType="disc">
                  <Breadcrumbs.Item>
                    <Text size="sm" color="secondary-500" weight="semibold">
                      {selectedNetwork.name}
                    </Text>
                  </Breadcrumbs.Item>
                  <Breadcrumbs.Item>
                    <Text size="sm" weight="regular">
                      {selectedNetwork.alias}
                    </Text>
                  </Breadcrumbs.Item>
                </Breadcrumbs>
              </Motion.Section>
            )}
            {!selectedNetwork && (
              <Text size="sm" color="neutral-500">
                {t('crypto.select-network-placeholder')}
              </Text>
            )}
            <Icon size="lg" color={asset ? 'neutral-600' : 'neutral-500'} type="s:chevron-down" />
          </Block>
        </Panel>

        {disabled && (
          <Alert status="red">
            <Text weight="bold" size="sm" color="secondary-500">
              {t('crypto.deposit.deposit-stopped', { asset: asset?.symbol })}
            </Text>
            <Text size="sm" color="secondary-500">
              {t('crypto.deposit.deposit-stopped-on-network-description', { asset: asset?.name })}
            </Text>
          </Alert>
        )}

        <Motion.Section layout="position" show={!!selectedNetwork && !disabled}>
          {selectedNetwork && !disabled && <DepositWalletAddress asset={asset} network={selectedNetwork} />}

          {selectedNetwork && !disabled && (
            <Alert status="yellow" mt="lg" mb="2xl">
              <Text size="sm" weight="bold" color="secondary-500">
                {t('crypto.network-asset-warning.title', {
                  name: selectedNetwork.name,
                  symbol: selectedNetwork.alias,
                })}
              </Text>
              {t('crypto.network-asset-warning.desc')}
            </Alert>
          )}
        </Motion.Section>

        <Separator width="size-full" mb="2xl" />
        <DepositLimits asset={asset?.symbol} network={selectedNetwork?.alias} />
      </>

      <NetworkModal filter={asset?.available_on} setActive={setModalActive} active={modalActive} />
    </>
  );
};

export default SelectWalletStep;

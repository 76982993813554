import React from 'react';

import { Accordion, Block, notification, Panel, Separator, Text } from '@bilira-org/design';
import { ChannelType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import PreferencesQuery from '@Libs/clientInstances/preferencesQuery';
import useAuth from '@Libs/hooks/userAuth';

import CEMPreference from './components/CEMPreference';
import PreferenceSwitches from './components/PreferenceSwitches';
import { notificationPreferenceData } from './constants';

function Index() {
  const { t } = useTranslation();
  const { account } = useAuth();

  const { data, isPending } = PreferencesQuery.useGetPreferences(account);
  const put = PreferencesQuery.usePutReference();

  const updatePreference = async (name: string, channel: ChannelType, value: string) => {
    put
      .mutateAsync({
        account: account,
        body: {
          type: 'notification',
          name: name,
          channel: channel,
          value: value,
        },
      })
      .then(() => {
        notification.success(t('common.saved'));
      })
      .catch(({ response }) => {
        notification.error(response.data.message);
      });
  };

  return (
    <>
      <Panel size="none">
        <Text heading size="xl" py="sm" weight="bold">
          {t('common.notifications')}
        </Text>

        <Accordion variant="secondary" gap="xl" mt="xl" allowMultiple>
          {notificationPreferenceData.map((notificationPreference) => (
            <React.Fragment key={notificationPreference.key}>
              <Accordion.Item
                accordionKey={notificationPreference.key}
                label={
                  <Block items="start">
                    <Text heading size="base" weight="semibold">
                      {t(`account.preferences.notifications.${notificationPreference.i18nKey}.title`)}
                    </Text>
                    <Text mt="md">{t(`account.preferences.notifications.${notificationPreference.i18nKey}.sub`)}</Text>
                  </Block>
                }
              >
                <PreferenceSwitches
                  data={data}
                  updatePreference={updatePreference}
                  notificationPreference={notificationPreference}
                  loading={isPending}
                />
              </Accordion.Item>

              <Separator width="size-full" />
            </React.Fragment>
          ))}

          <CEMPreference />
        </Accordion>
      </Panel>
    </>
  );
}

export default Index;

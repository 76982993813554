import Cookies from 'js-cookie';

import { Cookie } from './Cookie';
import { CookieAuth } from './CookieAuth';
import LocalStore from './LocalStorage';

const cookie = new Cookie(Cookies);
const cookieAuth = new CookieAuth(Cookies);

Object.freeze(cookie._cookie);
Object.freeze(cookieAuth._cookie);

Object.seal(cookie._cookie);
Object.seal(cookieAuth._cookie);

export { cookie as Cookie, cookieAuth as CookieAuth, LocalStore };

import React, { memo } from 'react';

import { Avatar, Block, Display, Icon, IconCustom, Meta, Skeleton, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { USER_TIER_ICONS } from '@/libs/constants';
import kycQuery from '@Libs/clientInstances/idpQuery';
import SwapQuery from '@Libs/clientInstances/swapQuery';
import { getInitials, maskEmail } from '@Libs/helpers';

function AccountAvatar() {
  const { t } = useTranslation();
  const { data, isError, isPending } = kycQuery.useGetUserKyc();
  const navigate = useNavigate();
  const { data: swapTier, isPending: isSwapTierPending } = SwapQuery.useGetSwapCommissionTier();

  if (isError) {
    return null;
  }

  if (isPending || isSwapTierPending) {
    return (
      <Meta
        extra={<Skeleton height="size-10" width="size-10" rounded="full" />}
        title={<Skeleton width="size-14" />}
        description={<Skeleton height="size-4" width="size-20" />}
      />
    );
  }

  const props = data?.kyc_level === 1 ? { onClick: () => navigate('/account/id-verification') } : {};

  return (
    <Meta
      extra={
        <Avatar
          size="9xl"
          avatar={getInitials(data.first_name, data.last_name)}
          tr={
            swapTier?.name ? (
              <IconCustom type={USER_TIER_ICONS[swapTier?.name.toLowerCase()]} width="size-4" />
            ) : undefined
          }
          bordered
        />
      }
      title={
        <Display show={swapTier?.name}>
          <Block row items="center">
            <Text heading size="xl" weight="bold" color="primary-500" {...props}>
              {t(`commission.levels.${swapTier?.name.toLowerCase()}`)}
            </Text>
            <Icon type="s:chevron-right" size="lg" color="primary-500" />
          </Block>
        </Display>
      }
      description={
        <Text size="base" color="neutral-800">
          {maskEmail(data?.email)}
        </Text>
      }
    />
  );
}

export default memo(AccountAvatar);

import React, { FunctionComponent } from 'react';

import { ERROR_PAGE_STATE } from '@bilira-org/react-utils';
import { Navigate, useLocation } from 'react-router-dom';

import { useSessionStore } from '@/store';
import useCheckHealth from '@Libs/hooks/useCheckHealth';

/**
 * PublicRoute component for handling public routes in the application.
 *
 * This component checks the user's authentication status and redirects them accordingly.
 * It renders a skeleton layout while waiting for authentication status.
 * If there's user is unauthorized, it renders the provided element.
 * Otherwise, it redirects the user to the home page.
 *
 * @example
 * <PublicRoute element={MyComponent} />
 */
function PublicRoute({ element }: { element: FunctionComponent }) {
  const location = useLocation();

  const isLoggedIn = useSessionStore((state) => state.isLoggedIn);
  const { isServerError, isLoggedOut, isLoading } = useCheckHealth();

  if (isLoading) {
    return null;
  }

  if (isLoggedOut || !isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they log in, which is a nicer user experience
    // than dropping them off on the home page.
    const Element = element;
    return <Element />;
  }

  // Network Error is unauthorized
  // Is Service unavailable
  // if (clientState?.isServerUnavailable) {
  if (isServerError) {
    return (
      <Navigate
        to="/error"
        state={{ from: location, errorState: ERROR_PAGE_STATE.SERVER_ERROR, authServiceDown: true }}
        replace
      />
    );
  }

  // If the user is already logged in, redirect to homepage.
  return <Navigate to="/" state={{ from: location }} replace />;
}

export default PublicRoute;

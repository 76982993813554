const routeMap = {
  Root: {
    tr: '/',
    en: '/',
  },
  Assets: {
    tr: '/assets',
    en: '/assets',
  },
  Fiat: {
    tr: '/fiat',
    en: '/fiat',
    Deposit: {
      tr: '/fiat/deposit',
      en: '/fiat/deposit',
    },
    Withdraw: {
      tr: '/fiat/withdraw',
      en: '/fiat/withdraw',
    },
  },
  Crypto: {
    tr: '/crypto',
    en: '/crypto',
    Deposit: {
      tr: '/crypto/deposit',
      en: '/crypto/deposit',
    },
    Withdraw: {
      tr: '/crypto/withdraw',
      en: '/crypto/withdraw',
    },
  },
  Account: {
    tr: '/account',
    en: '/account',
    SecuritySettings: {
      tr: '/account/security-settings',
      en: '/account/security-settings',
    },
    Preferences: {
      tr: '/account/preferences',
      en: '/account/preferences',
    },
    IdVerification: {
      tr: '/account/id-verification',
      en: '/account/id-verification',
    },
  },
  TransactionHistory: {
    tr: '/transaction-history',
    en: '/transaction-history',
  },
  OTC: {
    tr: '/otc',
    en: '/otc',
  },
  Swap: {
    tr: '/kripto-para-al-sat',
    en: '/buy-sell-crypto',
  },
  Notification: {
    tr: '/notification',
    en: '/notification',
  },
  Market: {
    tr: '/piyasalar',
    en: '/market',
    AssetPair: {
      tr: '/piyasalar/:assetPair',
      en: '/market/:assetPair',
    },
    Ranking: {
      tr: '/piyasalar/siralamalar',
      en: '/market/ranking',
    },
  },
  CommissionFee: {
    tr: '/komisyonlar-ve-ucretler',
    en: '/fees-and-commissions',
    Fee: {
      tr: '/komisyonlar-ve-ucretler/ucretler',
      en: '/fees-and-commissions/fees',
    },
    Commission: {
      tr: '/komisyonlar-ve-ucretler/komisyonlar',
      en: '/fees-and-commissions/commissions',
    },
  },
  Referral: {
    tr: '/referral',
    en: '/referral',
  },
  Rewards: {
    tr: '/rewards',
    en: '/rewards',
    LeaderboardId: {
      tr: '/rewards/leaderboard/:id',
      en: '/rewards/leaderboard/:id',
    },
  },
  Logout: {
    tr: '/cikis-yap',
    en: '/logout',
  },
  Login: {
    tr: '/giris-yap',
    en: '/login',
  },
  Register: {
    tr: '/uye-ol',
    en: '/signup',
  },
  OAuthCallback: {
    tr: '/oauth/callback',
    en: '/oauth/callback',
  },
  Support: {
    tr: '/support',
    en: '/support',
  },
  DownloadRedirect: {
    tr: '/download-redirect',
    en: '/download-redirect',
  },
  WidgetMarket: {
    tr: '/widget/market',
    en: '/widget/market',
  },
  WidgetMarketCards: {
    tr: '/widget/market-cards',
    en: '/widget/market-cards',
  },
  Invitation: {
    tr: '/invitation/:code',
    en: '/invitation/:code',
  },
  Error: {
    tr: '/error',
    en: '/error',
  },
  Wildcard: {
    tr: '/*',
    en: '/*',
  },
};

export const routesAvailableLocale = [
  {
    tr: routeMap.Market.tr,
    en: routeMap.Market.en,
  },
  {
    tr: routeMap.Market.Ranking.tr,
    en: routeMap.Market.Ranking.en,
  },
  {
    tr: routeMap.CommissionFee.tr,
    en: routeMap.CommissionFee.en,
  },
  {
    tr: routeMap.CommissionFee.Fee.tr,
    en: routeMap.CommissionFee.Fee.en,
  },
  {
    tr: routeMap.CommissionFee.Commission.tr,
    en: routeMap.CommissionFee.Commission.en,
  },
  {
    tr: routeMap.Login.tr,
    en: routeMap.Login.en,
  },
];

export default routeMap;

import React from 'react';

import { Block, LayoutV2, Panel, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { IconCustom } from '@/components/icon/CustomIcon';

import FAQ from './FAQ';
import Header from '../public/components/Header';

const NoSeasonContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Panel color="theme-n200d800" size="8xl">
        <Block gap="md" items="center">
          <IconCustom type="empty-folder" />
          <Text heading size="xl" color="secondary-500" align="center">
            {t('rewards.no-active-season.title')}
          </Text>
          <Text size="sm" color="neutral-800" align="center">
            {t('rewards.no-active-season.desc')}
          </Text>
        </Block>
      </Panel>
      <Block mt="8xl">
        <Text heading size="xl" mb="2xl">
          {t('rewards.faq-title')}
        </Text>
        <FAQ />
      </Block>
    </>
  );
};

const { Content, InnerContent } = LayoutV2;

interface ContainerProps {
  children?: React.ReactNode;
}
const Container = ({ children }: ContainerProps) => {
  return (
    <>
      <Header />
      <Content>
        <InnerContent>{children}</InnerContent>
      </Content>
    </>
  );
};

export default Object.assign(NoSeasonContent, { Container });

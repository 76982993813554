import { getRewardTaskState } from './getRewardTaskState';
import { UserParticipationType } from '../../model';

/**
 * Determines the sorting precedence for a reward task based on its current state.
 *
 * Tasks are assigned precedence as follows:
 * - 'recurringActive': 3
 * - 'recurringCoolDown': 2
 * - 'completed': 1
 * - 'recurringToDo' or 'toDo': 0
 *
 * This allows tasks to be sorted with 'recurringActive' tasks having the highest priority
 * and 'toDo' or 'recurringToDo' having the lowest.
 *
 * @param {UserParticipationType} task - The task object representing user participation.
 * @returns {number} - A numeric value representing the sorting precedence of the task, where higher numbers indicate higher precedence.
 */
export function getRewardTaskSortingPrecedence(task: UserParticipationType) {
  const state = getRewardTaskState(task);

  const precedenceOrder = [
    'completed', // (lowest precedence)
    'toDo',
    'recurringToDo',
    'recurringCompleted',
    'recurringCoolDown',
    'recurringActive', // (highest precedence)
  ];

  return precedenceOrder.indexOf(state);
}

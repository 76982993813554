import { CdnQuery } from '@bilira-org/react-utils';

import { env } from '@Libs/constants/env';

import getExtraHeaders from '../helpers/getExtraHeaders';
import getI18nLanguage from '../helpers/getI18nLanguage';

const client = new CdnQuery({
  baseUrl: env.CDN_BASE_URL,
  getLocale: getI18nLanguage,
  getExtraHeaders,
});

Object.freeze(client);

export default client;

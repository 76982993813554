/**
 * Checks if the input string is valid according to regex pattern.
 *
 * @param {string} input The input string to validate.
 * @returns {boolean} True if the input is valid, otherwise false.
 */
export default function isInputValid(input: string) {
  const regex = /^[-+]?[0-9]+(\.[0-9]*)?$/;
  const detectZeroRegex = /^0[0-9]$/;
  return !(input && (detectZeroRegex.test(input) || !regex.test(input)));
}

import React, { CSSProperties, ReactElement, useMemo } from 'react';

import { clsx } from 'clsx';

import GridviewHeader from './gridviewHeader';
import GridviewRow from './gridviewRow';
import { Breakpoints } from '../../hooks';
import { Display } from '../display';
import { Empty } from '../empty';
import { Skeleton } from '../skeleton';
import { BaseComponentType, BaseSize, BreakpointType } from '../types';

type GridBreakpoint = boolean | undefined | Breakpoints[];

type ColumnStyle = CSSProperties & {
  verticalAlign?: 'top' | 'bottom' | 'middle' | 'baseline';
};

export type TTableColumn<TEntry> = {
  /** The title of the column. */
  title: string;
  /** The field/key of the data entry corresponding to the column. */
  field: keyof TEntry;
  /** Array of breakpoints at which the column should be hidden. */
  hidden: GridBreakpoint;
  /** Custom cell renderer function for the column. */
  cell(entry: TEntry): ReactElement;
  /** Custom style function for the column. */
  style(entry: TEntry): Partial<ColumnStyle>;
  align: 'left' | 'right' | 'center';
  skeleton?: ReactElement;
  /** Disable user pointer event. */
  disableClick?: boolean;
};

export type TTableProps<TEntry> = BaseComponentType & {
  /** The array of data entries to be displayed. */
  data: TEntry[] | undefined;
  /** An array of column configurations for the grid. */
  columns: Partial<TTableColumn<TEntry>>[];
  detail?: (entry: TEntry) => ReactElement;
  /**
   * If `true`, the grid will stretch to fill the available space.
   * If a `BreakpointType` is provided, it will control stretch based on screen size.
   */
  stretch?: boolean | BreakpointType<boolean>;
  /** Whether the grid should be responsive based on window size. Defaults to `false`. */
  responsive?: boolean;
  /** Whether to display the header row. Defaults to `true`. */
  displayHeader?: boolean;
  /** Whether the grid is loading */
  loading?: boolean;
  /** On endless scroll, if the next page is fetching.. */
  isFetchingNextPage?: boolean;
  /** Skeleton element to be displayed when loading */
  skeleton?: ReactElement;
  /** Padding of the rows */
  rowPadding?: BaseSize;
  /** Callback to triggered when a row is clicked */
  onRowClick?: (data: TEntry) => void;
  /** Number of skeletons to be rendered when loading */
  skeletonRowCount?: number;
  /** The message to be displayed when no entries are found  */
  emptyMessage?: string;
  idField: keyof TEntry | ((item: TEntry) => string);
  rounded?: boolean;
  /** Whether to display divider between rows */
  divider?: boolean;
  /** Display incremental row number*/
  rowNumber?: boolean;
};

/**
 *
 * @deprecated Use DataTable component instead
 *
 * Gridview component for displaying tabular data in a grid format.
 *
 * @example
 * <Gridview
 *   data={[
 *     { id: 1, name: 'John Doe', age: 30 },
 *     { id: 2, name: 'Jane Doe', age: 25 },
 *     { id: 3, name: 'Bob Smith', age: 35 },
 *   ]}
 *   columns={[
 *     { title: 'ID', field: 'id' },
 *     { title: 'Name', field: 'name' },
 *     { title: 'Age', field: 'age' },
 *   ]}
 *   stretch
 *   responsive
 *   displayHeader
 *   loading={false}
 *   skeleton={<Skeleton space="lg" columns={3} repeat={5} />}
 *   renderSkeletonAsRow={[
 *     { id: <Skeleton space="sm" />, name: <Skeleton space="md" />, age: <Skeleton space="lg" /> },
 *   ]}
 *   skeletonRowCount={5}
 *   rowPadding="md"
 *   onRowClick={(data) => console.log('Clicked row:', data)}
 * />
 */
const Gridview = <TEntry,>({
  data,
  columns,
  detail,
  displayHeader = true,
  responsive = false,
  loading,
  isFetchingNextPage,
  skeletonRowCount = 1,
  onRowClick,
  rowPadding = 'md',
  emptyMessage = 'No Entries Found',
  testId,
  idField,
  divider,
  rowNumber,
  rounded = true,
}: TTableProps<TEntry>) => {
  const rows = useMemo(
    () => (loading ? new Array(skeletonRowCount).fill(null) : data) as [],
    [loading, skeletonRowCount, data],
  );

  if (!data?.length && !loading) {
    return <Empty message={emptyMessage} />;
  }

  return (
    <div
      className={clsx('gridview', { 'rounded-md': rounded, 'table-divider': divider })}
      data-testid={testId ?? 'gridview'}
    >
      <table className="gridview-table">
        <GridviewHeader rowNumber={rowNumber} displayHeader={displayHeader} columns={columns} responsive={responsive} />
        <tbody className={clsx('gridview-body', { 'with-header': displayHeader })}>
          {rows.map((entry, entryIndex) => (
            <GridviewRow
              rowNumber={rowNumber}
              rounded={rounded}
              key={(() => {
                if (!entry) {
                  return entryIndex;
                }

                const key = typeof idField === 'function' ? idField(entry) : (entry?.[idField] as string);
                return key || entryIndex;
              })()}
              columns={columns}
              detail={detail}
              entry={entry}
              responsive={responsive}
              loading={loading}
              onRowClick={onRowClick}
              rowPadding={rowPadding}
              idField={idField}
              testId={testId}
              entryIndex={entryIndex}
            />
          ))}
          <Display show={isFetchingNextPage}>
            {new Array(skeletonRowCount).fill(null).map((unused, entryIndex) => (
              <tr className="body-row" key={entryIndex}>
                {columns.map(({ skeleton }, columnIndex) => (
                  <td
                    className={clsx('body-cell', {
                      [`row-rounded`]: rounded,
                      [`row-padding-${rowPadding}`]: rowPadding,
                    })}
                    key={columnIndex}
                  >
                    {skeleton ?? <Skeleton height="size-3" space="lg" />}
                  </td>
                ))}
              </tr>
            ))}
          </Display>
        </tbody>
      </table>
    </div>
  );
};

Gridview.displayName = 'Gridview';

export default Gridview;

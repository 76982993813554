import React, { useEffect, useState } from 'react';

import { Block, Empty, List, Meta, Panel, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { getSavedRecent } from '@Components/globalSearch/components/helpers/handleSaveToLocalStore';
import { StoredAssetType } from '@Components/globalSearch/types';
import IconCoin from '@Components/icon/IconCoin';

type Props = {
  /** Callback function to be called when an item is selected  */
  onSelected: (item: StoredAssetType) => void;
};

/**
 * Lists recently searched items
 */
const LastSearchList = ({ onSelected }: Props) => {
  const { t } = useTranslation();
  const [data, setData] = useState<StoredAssetType[]>();

  useEffect(() => {
    const data = getSavedRecent();
    setData(data);
  }, []);

  if (!data) {
    return null;
  }

  return (
    <>
      <Text size="sm" weight="semibold" color="secondary-500" mb="sm">
        {t('global-search.recent-searches')}
      </Text>

      <List
        testId="last-search-list"
        empty={<Empty message={t('common.empty-message')} />}
        dataSource={data}
        space="sm"
        direction="row"
        renderItem={(record) => (
          <List.Item
            key={`last-search-${record.symbol}-${record.name}`}
            px="sm"
            py="sm"
            bgColor="neutral-200"
            hoverBgColor="neutral-300"
            onClick={() => onSelected({ symbol: record.symbol, name: record.name })}
          >
            <Block items="center" width="size-20" my="xs">
              <IconCoin size="2xl" type={record.symbol} />
              <Text size="xs" weight="semibold" align="center" mt="xs" color="secondary-500" truncate>
                {record.symbol}
              </Text>
              <Text size="xs" color="neutral-800" align="center" truncate>
                {record.name}
              </Text>
            </Block>
          </List.Item>
        )}
      />
    </>
  );
};

export default LastSearchList;

import { IconType } from '@bilira-org/design';

type NavItemType = {
  href: string;
  title: string;
  desc: string;
  icon: IconType;
};

export const navItems: NavItemType[] = [
  {
    href: '/fiat/deposit',
    title: 'deposit-withdraw.deposit-tl.title',
    desc: 'deposit-withdraw.deposit-tl.desc',
    icon: 'o:building-library-inside',
  },
  {
    href: '/fiat/withdraw',
    title: 'deposit-withdraw.withdraw-tl.title',
    desc: 'deposit-withdraw.withdraw-tl.title',
    icon: 'o:building-library-inside',
  },
  {
    href: '/crypto/deposit',
    title: 'deposit-withdraw.deposit-crypto.title',
    desc: 'deposit-withdraw.deposit-crypto.title',
    icon: 'o:token-arrow-up',
  },
  {
    href: '/crypto/withdraw',
    title: 'deposit-withdraw.withdraw-crypto.title',
    desc: 'deposit-withdraw.withdraw-crypto.title',
    icon: 'o:token-arrow-down',
  },
];

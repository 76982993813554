/**
 * Adjusts the quote asset based on the given base and defaultQuoteAsset.
 *
 * @param {string} base - The base asset.
 * @param {string} defaultQuoteAsset - The default quote asset.
 * @returns {string} - The adjusted quote asset.
 */
export const adjustQuoteAsset = (base: string, defaultQuoteAsset: string): string => {
  if (base === 'TRYB' && defaultQuoteAsset === 'TRYB') {
    return 'TRY';
  }

  return defaultQuoteAsset;
};

export default adjustQuoteAsset;

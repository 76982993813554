import '../style.css';
import { Block, Flex, Hidden, LayoutV2, Loop, Panel, Skeleton } from '../src/components';

function LayoutSkeleton() {
  return (
    <LayoutV2>
      <LayoutV2.Head>
        <Flex px="xl" py="md" direction="row" items="center" justify="between">
          <Flex direction="row" items="center">
            <Flex.Item>
              <Skeleton rounded="full" height="size-10" width="size-10" />
            </Flex.Item>

            <Flex.Item>
              <Skeleton className="ml-3" width="size-20" height="size-3" />
            </Flex.Item>

            <Flex.Item className="tw-hidden lg:flex">
              <Skeleton className="ml-12" width="size-72" height="size-3" />
            </Flex.Item>
          </Flex>

          <Flex direction="row" items="center">
            <Flex.Item className="tw-hidden sm:block">
              <Skeleton width="size-20" height="size-3" />
            </Flex.Item>

            <Flex direction="row" gap="lg" className="ml-6">
              <Skeleton rounded="full" height="size-6" width="size-6" className="tw-hidden sm:block" />

              <Skeleton
                rounded="full"
                height="size-6"
                width="size-6"
                className="tw-hidden xs:block sm:block lg:hidden"
              />

              <Skeleton rounded="full" height="size-6" width="size-6" />

              <Skeleton rounded="full" height="size-6" width="size-6" className="tw-hidden sm:block" />

              <Skeleton rounded="full" height="size-6" width="size-6" />
            </Flex>
          </Flex>
        </Flex>
      </LayoutV2.Head>

      <LayoutV2.Container>
        <LayoutV2.Main>
          <LayoutV2.Content>
            <LayoutV2.InnerContent>
              <Block gap="4xl">
                <Flex
                  minWidth="min"
                  items="stretch"
                  gap="4xl"
                  justify={{ sm: 'start', md: 'end' }}
                  direction={{ xs: 'col', sm: 'col', xl: 'row' }}
                >
                  <Flex.Item flexType="1">
                    <Panel px="2xl" py="3xl" border="neutral-400">
                      <Block row justify="between" items="center" mb="md" mt="md">
                        <Skeleton height="size-4" width="size-36" />

                        <Skeleton height="size-4" width="size-20" />
                      </Block>

                      <Block gap="md" mt="5xl">
                        <Loop data={3}>
                          <Block row justify="between">
                            <Block row gap="md" items="center">
                              <Skeleton height="size-3" width="size-3" />

                              <Skeleton rounded="full" height="size-6" width="size-6" />

                              <Skeleton height="size-3" width="size-20" />
                            </Block>
                            <Skeleton height="size-3" width="size-9" />
                          </Block>
                        </Loop>
                      </Block>
                    </Panel>
                  </Flex.Item>

                  <Flex.Item flexType="1">
                    <Panel px="2xl" py="3xl" border="neutral-400">
                      <Block row justify="between" items="center" mb="md" mt="md">
                        <Skeleton height="size-4" width="size-36" />

                        <Skeleton height="size-4" width="size-20" />
                      </Block>

                      <Block gap="md" mt="5xl">
                        <Loop data={3}>
                          <Block row justify="between">
                            <Block row gap="md" items="center">
                              <Skeleton height="size-3" width="size-3" />

                              <Skeleton rounded="full" height="size-6" width="size-6" />

                              <Skeleton height="size-3" width="size-20" />
                            </Block>
                            <Skeleton height="size-3" width="size-9" />
                          </Block>
                        </Loop>
                      </Block>
                    </Panel>
                  </Flex.Item>
                </Flex>

                <Panel px="2xl" py="3xl" border="neutral-400">
                  <Block row justify="between" items="center" mb="md" mt="md">
                    <Skeleton height="size-4" width="size-36" />

                    <Skeleton height="size-4" width="size-20" />
                  </Block>

                  <Block gap="5xl" mt="5xl">
                    <Loop data={3}>
                      <Block row justify="between">
                        <Block row gap="sm" items="center">
                          <Skeleton rounded="full" height="size-8" width="size-8" />

                          <Skeleton height="size-4" width="size-24" />
                        </Block>
                        <Skeleton height="size-4" width="size-24" />

                        <Hidden show={{ xs: false, sm: false, md: true }}>
                          <Skeleton height="size-4" width="size-14" />
                        </Hidden>

                        <Hidden show={{ xs: false, sm: false, md: true }}>
                          <Skeleton height="size-4" width="size-16" />
                        </Hidden>

                        <Hidden show={{ xs: false, sm: false, md: false, lg: true }}>
                          <Skeleton height="size-4" width="size-24" />
                        </Hidden>
                      </Block>
                    </Loop>
                  </Block>
                </Panel>
              </Block>
            </LayoutV2.InnerContent>
            <LayoutV2.Aside>
              <Block gap="4xl">
                <Panel border="neutral-400">
                  <Block row justify="between">
                    <Skeleton width="size-36" height="size-4" />

                    <Block row gap="sm">
                      <Skeleton width="size-20" height="size-4" />
                    </Block>
                  </Block>

                  <Block row justify="between" mt="lg">
                    <Block gap="sm" justify="center">
                      <Skeleton width="size-44" height="size-3" />

                      <Skeleton width="size-20" height="size-3" />

                      <Skeleton width="size-32" mt="4xl" />
                    </Block>

                    <Skeleton rounded="full" width="size-20" height="size-20" mt="sm" />
                  </Block>
                </Panel>

                <Panel border="neutral-400">
                  <Block row justify="between">
                    <Skeleton width="size-36" height="size-4" />
                    <Block row gap="sm">
                      <Skeleton width="size-20" height="size-4" />
                    </Block>
                  </Block>

                  <Block row justify="between" mt="lg">
                    <Block gap="sm" justify="center">
                      <Skeleton width="size-44" height="size-3" />

                      <Skeleton width="size-20" height="size-3" />
                    </Block>
                  </Block>

                  <Block gap="lg" mt="2xl">
                    <Loop data={3}>
                      <Block row gap="md" items="center">
                        <Skeleton style={{ width: '150px', height: '75px' }} />
                        <div>
                          <Skeleton width="size-28" height="size-3" rounded="sm" mb="sm" />

                          <Skeleton width="size-20" height="size-3" rounded="sm" />
                        </div>
                      </Block>
                    </Loop>
                  </Block>
                </Panel>

                <Panel px="2xl" py="4xl" border="neutral-400">
                  <Block row justify="between" items="center" mb="md" mt="md">
                    <Skeleton height="size-4" width="size-36" />
                  </Block>

                  <Block gap="md" mt="6xl">
                    <Block row justify="between">
                      <Block row gap="md" items="center">
                        <Skeleton rounded="full" height="size-6" width="size-6" />

                        <Skeleton height="size-3" width="size-20" />
                      </Block>

                      <Skeleton height="size-3" width="size-9" />
                    </Block>
                  </Block>
                </Panel>
              </Block>
            </LayoutV2.Aside>
          </LayoutV2.Content>
        </LayoutV2.Main>
      </LayoutV2.Container>
    </LayoutV2>
  );
}

export default LayoutSkeleton;

import BigNumber from 'bignumber.js';

import { defaultFormatOption, formatOptionsMap } from './formatOptionsMap';
import stringifyCurrency from './stringifyCurrency';
import { LanguageUniversalType } from '../types';

BigNumber.config({ EXPONENTIAL_AT: 100 });

export const ForceDecimal = {
  /** Format numbers with decimal places, regardless of whether they have an integer part. */
  ENFORCE: true,
  /** Display the desired number of decimal places even if the digits are insignificant.
   * Note: Additional decimal places may be displayed if the number has no integer part (formatted to precision).
   */
  SHOW_INSIGNIFICANT_DIGITS: 1,
  /** Strictly display N digits of decimal places. */
  STRICT_DECIMAL: 2,
} as const;
type ForceDecimalType = (typeof ForceDecimal)[keyof typeof ForceDecimal];

/**
 * Represents the parameters for formatting a currency value.
 */
export type FormatPriceType = {
  /** The value to be formatted. */
  value: string | undefined;
  /** The precision to be used in formatting. */
  precision?: number;
  /** The number of decimal places to display. */
  decimal?: number;
  /** The locale for formatting. */
  locale?: string;
  /** The rounding mode to be applied. */
  roundingMode?: BigNumber.RoundingMode;
  /** The default value to be used if the input value is empty. */
  defaultValue?: string;
  /** The format to be used for stringifying the currency.
   * @see http://numeraljs.com/#format
   * */
  stringifyFormat?: string;
  /** Force displaying decimal places even for whole numbers. */
  forceDecimal?: ForceDecimalType;
};

/**
 * Represents the parameters for formatting a currency value excluding the stringifyFormat option.
 */
type FormatPriceValueType = Omit<FormatPriceType, 'stringifyFormat'>;

/**
 * Format a currency value with a specified precision, locale, and currency symbol.
 * @note By default, the value is formatted with decimal places only if it has an integer part. You can override this behavior by using the forceDecimal prop.
 *
 * @param {FormatPriceValueType} props - The parameters for formatting the currency value.
 * @returns {string} - The formatted currency value.
 */
function formatPriceValue({
  value,
  precision,
  decimal: decimalFromProps,
  locale,
  defaultValue = '',
  roundingMode = BigNumber.ROUND_DOWN,
  forceDecimal,
}: FormatPriceValueType): string {
  if (!value) {
    return defaultValue;
  }

  const bigValue = BigNumber(value);
  let significantDigits: number | undefined = bigValue.decimalPlaces() || 0;

  const decimal = significantDigits === 0 ? 0 : decimalFromProps;

  const options = locale ? formatOptionsMap[locale as LanguageUniversalType] : defaultFormatOption;

  if (forceDecimal === ForceDecimal.STRICT_DECIMAL) {
    return bigValue.toFormat(decimalFromProps || 0, roundingMode, options);
  }

  if (
    bigValue.isGreaterThanOrEqualTo(10) ||
    bigValue.isLessThanOrEqualTo(-10) ||
    forceDecimal === ForceDecimal.ENFORCE
  ) {
    if (typeof decimal !== 'number') {
      return bigValue.toFormat(options);
    }

    significantDigits =
      forceDecimal === ForceDecimal.SHOW_INSIGNIFICANT_DIGITS ? decimal : Math.min(significantDigits, decimal);

    return bigValue.toFormat(significantDigits, roundingMode, options);
  }

  significantDigits = precision;

  let result = bigValue;
  if (significantDigits !== undefined) {
    result = BigNumber(
      significantDigits !== 0
        ? bigValue.toPrecision(significantDigits, roundingMode)
        : bigValue.decimalPlaces(0, roundingMode),
    );
  }

  if (
    forceDecimal === ForceDecimal.SHOW_INSIGNIFICANT_DIGITS &&
    (result.decimalPlaces() as number) < (decimal as number)
  ) {
    return result.toFormat(decimal as number, roundingMode, options);
  }

  return result.toFormat(options);
}

/**
 * Format a currency value with options such as precision, locale, and formatting.
 * @note By default, the value is formatted with decimal places only if it has an integer part. You can override this behavior by using the forceDecimal prop.
 *
 * @param {FormatPriceType} props - The parameters for formatting the currency value.
 * @returns The formatted currency value along with its parts.
 *
 * @example
 * formatPrice({
 *   value: '10.1234',
 *   decimal: 2,
 *   precision: 4,
 *   locale: i18n.language,
 * }).value; // "10.12"
 *
 * formatPrice({
 *   value: '30',
 *   decimal: 2,
 *   precision: 4,
 *   locale: i18n.language,
 * }).value; // "30"
 *
 * formatPrice({
 *   value: '0.0001234',
 *   precision: 2,
 *   locale: i18n.language,
 * }).value; // "0.00012"
 *
 * formatPrice({
 *    value: '0.1234',
 *    decimal: 2,
 *    precision: 4,
 *    locale: i18n.language,
 *    forceDecimal: ForceDecimal.ENFORCE,
 *  }).value, // '0.12'
 *
 * formatPrice({
 *   value: '30',
 *   decimal: 2,
 *   precision: 4,
 *   forceDecimal: ForceDecimal.SHOW_INSIGNIFICANT_DIGITS,
 *   locale: i18n.language,
 * }).value // "30.00"
 *
 * formatPrice({
 *   value: '0.1',
 *   decimal: 2,
 *   precision: 4,
 *   forceDecimal: ForceDecimal.SHOW_INSIGNIFICANT_DIGITS,
 *   locale: i18n.language,
 * }).value // "0.10"
 *
 * formatPrice({
 *   value: '0.00001234',
 *   decimal: 2,
 *   precision: 4,
 *   forceDecimal: ForceDecimal.SHOW_INSIGNIFICANT_DIGITS,
 *   locale: i18n.language,
 * }).value // "0.00001234"
 */
function formatPrice(props: FormatPriceType) {
  let numeralFormattedValue: string | undefined;

  if (props.stringifyFormat) {
    const result = stringifyCurrency({
      value: props.value,
      locale: props.locale || 'tr',
      format: props.stringifyFormat,
    });
    if (result !== props.value) {
      numeralFormattedValue = result;
    }
  }
  const bigValue = formatPriceValue(props);

  const options = props.locale ? formatOptionsMap[props.locale as LanguageUniversalType] : defaultFormatOption;

  const decimalSeparator = options.decimalSeparator as string;

  const [integerPart, decimalPart] = bigValue.split(decimalSeparator);
  const composedDecimalPart = decimalPart ? `${decimalSeparator}${decimalPart}` : undefined;

  return {
    values: [integerPart, composedDecimalPart],
    value: bigValue,
    shortValue: numeralFormattedValue,
  };
}

export { formatPrice, formatPriceValue };

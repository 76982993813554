import { NetworkAliasType, QueryOptions } from '@bilira-org/react-utils';

import CryptoWalletApi from '@Libs/clientInstances/cryptoQuery';

type Props = {
  // Alias of the network to retrieve details for
  alias?: NetworkAliasType;
};

/**
 * Hook to get network details based on alias.
 * @param {NetworkAliasType} alias The alias of the network to retrieve details for.
 * @param {QueryOptions} options Options for the query.
 * @returns - Network details with query results
 * @example
 * useGetNetworkDetailsFromAlias('ETH') // {network, ...queryResults}
 */
const useGetNetworkDetailsByAlias = ({ alias }: Props, options?: QueryOptions) => {
  const { data, ...rest } = CryptoWalletApi.useGetNetworks(options);

  const network = data?.find((d) => d.alias === alias);
  return { network, ...rest };
};

export default useGetNetworkDetailsByAlias;

import React, { forwardRef, ReactNode } from 'react';

import { clsx } from 'clsx';
import { Link, LinkProps } from 'react-router-dom';

import { buildResponsiveClass } from '../../components/utils';
import { Spinner } from '../spinner';
import {
  BaseComponentType,
  BaseSize,
  BreakpointType,
  ButtonVariant,
  FlexType,
  JustifyType,
  PaddingSizes,
  ResponsiveReturnType,
} from '../types';

export type ButtonProps2 = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'> &
  BaseComponentType & {
    /** Additional properties for the anchor element when used as a link */
    link?: React.AnchorHTMLAttributes<HTMLAnchorElement>;
    /** Additional properties for the route element when used as a link */
    route?: LinkProps;
    /** Size of the button */
    size?: BaseSize;
    /** Variant of the button */
    variant?: ButtonVariant;
    /** Content of the button */
    children?: ReactNode | React.ReactElement;
    /** Icon to be placed at the start of the button */
    startIcon?: ReactNode | React.ReactElement;
    /** Icon to be placed at the end of the button */
    endIcon?: ReactNode | React.ReactElement;
    /** Margin of the y-axis */
    my?: PaddingSizes;
    /** Margin of the x-axis */
    mx?: PaddingSizes;
    /** Margin of the top */
    mt?: PaddingSizes;
    /** Margin of the bottom */
    mb?: PaddingSizes;
    /** Additional class names */
    className?: string;
    /** Whether the button should stretch to fill its container */
    stretch?: boolean;
    /** Whether the button is in a loading sate */
    loading?: boolean;
    /** Alignment of the button content */
    justify?: JustifyType;
    /** Flex type for the button */
    flexType?: FlexType | BreakpointType<FlexType>;
    /** Whether the button should have a shining effect */
    shine?: boolean;
  };

/**
 * Customizable button component.
 *
 * @example
 * // Example usage of Button component
 * <Button variant="primary" size="md" onClick={handleClick}>
 *   Click me
 * </Button>
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps2>(
  (
    {
      children,
      startIcon,
      endIcon,
      className,
      variant,
      size,
      style,
      my,
      mx,
      mb,
      mt,
      link,
      stretch,
      loading,
      justify = 'center',
      flexType,
      shine,
      testId,
      route,
      ...props
    },
    ref,
  ) => {
    let flexTypeClass: ResponsiveReturnType = {};
    if (flexType) {
      flexTypeClass = buildResponsiveClass(flexType, 'tw-flex-');
    }
    const classes = clsx(
      'button',
      className,
      {
        'shine shine-anime': shine,
        'button-loading': loading,
        'button-empty': !children,
        'button-stretch': stretch,
        [`button-variant-${variant}`]: variant,
        [`button-size-${size}`]: size,
        [`tw-justify-${justify}`]: justify,
        [`my-${my}`]: my,
        [`mx-${mx}`]: mx,
        [`mt-${mt}`]: mt,
        [`mb-${mb}`]: mb,
      },
      flexTypeClass,
    );

    const startIconComponent = loading ? <Spinner size="sm" /> : startIcon;

    if (route) {
      return (
        <Link data-testid={testId} className={classes} style={style} {...route}>
          {startIconComponent && <span className="button-icon">{startIconComponent}</span>}
          {children}
          {endIcon && <span className="button-icon">{endIcon}</span>}
        </Link>
      );
    }

    if (link) {
      return (
        <a data-testid={testId} className={classes} style={style} {...link}>
          {startIconComponent && <span className="button-icon">{startIconComponent}</span>}
          {children}
          {endIcon && <span className="button-icon">{endIcon}</span>}
        </a>
      );
    }

    return (
      <button data-testid={testId} {...props} ref={ref} className={classes} style={style}>
        {startIconComponent && <span className="button-icon">{startIconComponent}</span>}
        {children}
        {endIcon && <span className="button-icon">{endIcon}</span>}
      </button>
    );
  },
);

Button.displayName = 'Button';

// to auto generate args documentation on forwardRef component

/**
 * Customizable button component.
 *
 * @component
 *
 * @param {ButtonProps2} props - The properties for the Button component.
 * @returns {React.ReactElement} The rendered Button component.
 * @example
 * // Example usage of Button component
 * <Button variant="primary" size="md" onClick={handleClick}>
 *   Click me
 * </Button>
 */
const StoryOnly = (props: ButtonProps2): React.ReactElement => (
  <>
    <Button {...props} />
  </>
);
StoryOnly.displayName = 'Button';

export { StoryOnly };
export default Button;

import React from 'react';

import { DirectionType, DomainType, getMax } from '@bilira-org/react-utils';

import FormattedNumber from '@/components/common/FormattedNumber';
import allowancesQuery from '@Libs/clientInstances/allowancesQuery';
import { withPrivateRoute } from '@Libs/hocs';
import useAuth from '@Libs/hooks/userAuth';

import EmptyText from './EmptyText';

type Props = {
  asset?: string;
  network?: string;
  domain: DomainType;
  direction: DirectionType;
};

const MinTransaction = ({ asset, network, domain, direction }: Props) => {
  const { account } = useAuth();

  const { data: allowance, isFetching } = allowancesQuery.useGetNextTransaction(
    {
      account,
      domain: domain,
      direction: direction,
      asset: asset,
      network: network,
    },
    { enabled: !!account },
  );

  if (!allowance) {
    return <EmptyText />;
  }

  const minAllowancePerTransaction = getMax(allowance?.min, '0').toString();

  return <FormattedNumber value={minAllowancePerTransaction} size="sm" weight="regular" loading={isFetching} />;
};

export default withPrivateRoute(MinTransaction);

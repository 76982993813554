import BigNumber from 'bignumber.js';

import { LanguageUniversalType } from '../types';

export const defaultFormatOption = {
  decimalSeparator: '.',
};

export const formatOptionsMap: Record<LanguageUniversalType, BigNumber.Format> = {
  // 'en-US': {
  //   groupSize: 3,
  //   groupSeparator: ',',
  //   decimalSeparator: '.',
  //   fractionGroupSeparator: '.',
  //   prefix: '',
  //   suffix: '',
  // },
  // 'tr-TR': {
  //   groupSize: 3,
  //   groupSeparator: '.',
  //   decimalSeparator: ',',
  //   fractionGroupSeparator: ',',
  //   prefix: '',
  //   suffix: '',
  // },
  en: {
    groupSize: 3,
    groupSeparator: ',',
    decimalSeparator: '.',
    fractionGroupSeparator: '.',
    prefix: '',
    suffix: '',
  },
  tr: {
    groupSize: 3,
    groupSeparator: '.',
    decimalSeparator: ',',
    fractionGroupSeparator: ' ',
    prefix: '',
    suffix: '',
  },
};

import ReconnectingWebSocket from 'reconnecting-websocket';

class WebSocketManager {
  private readonly url: string;
  private socket: ReconnectingWebSocket | null;
  private subscriptions: Map<string, any>;

  constructor(url: string) {
    this.url = url;
    this.socket = null;
    this.subscriptions = new Map();

    this.socket = new ReconnectingWebSocket(this.url);
  }
  public reconnect(): void {
    this.socket?.reconnect();
  }

  public connect(): void {
    this.socket?.addEventListener('open', () => {
      this.resubscribeAll();
      console.debug('Connected to price');
    });

    this.socket?.addEventListener('close', (event) => {
      if (event.wasClean) {
        console.debug(`Connection closed, =${event.code} reason=${event.reason}`);
        return;
      }
    });
  }

  private isReady(): boolean {
    return this.socket?.readyState === WebSocket.OPEN;
  }

  public subscribe(channel: string, payload: any): void {
    // console.debug('🟢 Subscribed.', JSON.stringify(payload));
    this.socket?.send(`sub|${JSON.stringify(payload)}`);
    this.subscriptions.set(channel, payload);
  }

  public unsubscribe(channel: string, payload: any, clean: boolean = true): void {
    if (!this.isReady()) {
      return;
    }
    // console.debug('🔴 Unsubscribed.', JSON.stringify(payload));
    this.socket?.send(`unsub|${JSON.stringify(payload)}`);
    if (clean) {
      this.subscriptions.delete(channel);
    }
  }

  public resubscribeAll(): void {
    for (const [channel, payload] of this.subscriptions.entries()) {
      this.subscribe(channel, payload);
    }
  }

  public close(): void {
    this.socket?.close();
  }

  public handleMessage(callback: (data: any, type: any) => void): void {
    this.socket?.addEventListener('message', (event) => {
      const [type, payloadString] = event.data?.split('|');
      try {
        callback(JSON.parse(payloadString), type);
      } catch (error) {
        console.debug('Message could not be parsed', type, event.data);
      }
    });
  }
}

export default WebSocketManager;

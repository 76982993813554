import React from 'react';

import { Block, Icon, LayoutV2 } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import PagerMenuBar from '@Components/common/PagerMenuBar';

const { Hero } = LayoutV2;

const InfoBar = () => {
  const { t } = useTranslation();

  const headers = [
    {
      text: t('account.headers.account'),
      path: '/account',
      defaultPath: true,
      icon: <Icon type="o:user-circle" size="lg" />,
    },
    {
      text: t('account.headers.security-settings'),
      path: '/account/security-settings',
      icon: <Icon type="o:shield-check" size="lg" />,
    },
    {
      text: t('account.headers.id-verification'),
      path: '/account/id-verification',
      icon: <Icon type="o:identification" size="lg" />,
    },
    {
      text: t('account.headers.preferences'),
      path: '/account/preferences',
      icon: <Icon type="o:cog-6-tooth" size="lg" />,
    },
  ];

  return (
    <Hero>
      <Block row mb="lg">
        <PagerMenuBar data={headers} />
      </Block>
    </Hero>
  );
};

export default InfoBar;

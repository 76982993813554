import mixpanel from 'mixpanel-browser';

export const sendUTMToMixpanel = async (utmParams: Record<string, string>): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (utmParams && Object.keys(utmParams).length > 0) {
      const distinctId = mixpanel.get_property('distinct_id');

      try {
        mixpanel.track('Download Redirect', {
          service: 'bilira-id',
          user_id: distinctId || 'unknown',
          ...utmParams,
        });

        resolve();
      } catch (error) {
        reject(error);
      }
    } else {
      resolve();
    }
  });
};

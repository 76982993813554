import React from 'react';

import { Block, Button, Flex, Icon, LayoutV2, Popover, Separator } from '@bilira-org/design';
import { parseAssetPair } from '@bilira-org/react-utils';
import { useNavigate, useParams } from 'react-router-dom';

import AssetName from '@Components/common/AssetName';
import SymbolPair from '@Components/common/SymbolPair';
import BuySellPairs from '@Components/trade/buySell/components/BuySellPairs';
import useNavigateToMarket from '@Libs/hooks/useNavigateToMarket';
import AssetCurrentStatisticValues from '@Modules/market/detail/components/information/components/AssetCurrentStatisticValues';
import SiderHeader from '@Modules/market/detail/SiderHeader';

const { Hero } = LayoutV2;

const Header = () => {
  const { assetPair } = useParams();
  const [base, quote] = parseAssetPair(assetPair);
  const navigate = useNavigate();
  const navigateToMarket = useNavigateToMarket();

  return (
    <>
      <Hero>
        <Flex direction="row" items="center" justify="between" gap="lg">
          <Flex.Item
            flex
            items="center"
            gap="lg"
            direction="row"
            grow={{ xs: '1', sm: '1', lg: '0' }}
            justify="between"
          >
            <Block row justify="between" items="center" gap="xl">
              <div>
                <Button
                  variant="outline-filled"
                  size="xs"
                  startIcon={<Icon type="o:arrow-left" size="md" />}
                  onClick={() => navigate('/market')}
                />
              </div>

              <Popover
                position="left"
                button={
                  <SymbolPair
                    quote={quote}
                    icon={base}
                    title={base}
                    description={<AssetName symbol={base} />}
                    size={{
                      icon: '3xl',
                      title: 'sm',
                      description: 'xs',
                    }}
                  />
                }
                customWidth="320"
                py="none"
                px="none"
              >
                {(close) => (
                  <BuySellPairs
                    contentScrollable={{
                      height: 'size-64',
                    }}
                    assetPair={`${base}_${quote}`}
                    setAsset={(selectedAsset) => {
                      close();
                      navigateToMarket({ base: selectedAsset.base_asset, quote: selectedAsset.quote });
                    }}
                  />
                )}
              </Popover>
            </Block>

            <Separator height="size-10" />
          </Flex.Item>
          <Flex.Item grow={{ xs: '0', sm: '0', lg: '1' }}>
            <AssetCurrentStatisticValues hiddenProps={{ show: { xs: false, sm: false, xl: true } }} />
            <SiderHeader hiddenProps={{ show: { xs: true, sm: true, lg: false } }} />
          </Flex.Item>
        </Flex>

        <AssetCurrentStatisticValues hiddenProps={{ show: { xs: true, sm: true, xl: false } }} />
      </Hero>
    </>
  );
};

export default Header;

import React from 'react';

import { clsx } from 'clsx';

import { TTableProps } from './table';

type Props<TEntry> = {
  /** Additional styling options for the table header. */
  headerStyle: TTableProps<TEntry>['headerStyle'];
  /** Padding bottom of the table header */
  headerPb: TTableProps<TEntry>['headerPb'];
  /** The configuration of columns in the table. */
  columns: TTableProps<TEntry>['columns'];
  /** Whether to fix first column in position */
  fixFirstColumn?: boolean;
};

/**
 * Header section of the Table component
 */
const TableHeader = <TEntry,>({ headerStyle, headerPb, columns, fixFirstColumn }: Props<TEntry>) => {
  return (
    <div className={clsx('tw-table-header-group')}>
      <div className={clsx('tw-table-row')}>
        {columns.map((column, index) => (
          <div
            key={((column?.title as string) || '') + index}
            className={clsx('tw-table-header-cell', {
              [`pb-${headerPb}`]: headerPb,
              [`tw-text-${column.align}`]: column.align,
              [`px-${headerStyle?.px}`]: headerStyle?.px,
              [`py-${headerStyle?.py}`]: headerStyle?.py,
              'sticky left-0 z-10': fixFirstColumn && index === 0,
              [`header-background`]: headerStyle?.showBackground,
              /**
               *  When the header is fixed in position and lacks a background, it becomes transparent and reduces visibility.
               *  To address this issue;
               *  If the column is fixed and 'showBackground' prop is set to false, indicating no background should be displayed,
               *  we apply the 'tw-bg-theme-wn' color to maintain an opaque background on the header cell.
               **/
              'tw-bg-theme-wn': !headerStyle?.showBackground && fixFirstColumn && index === 0,
            })}
          >
            {column.title}
          </div>
        ))}
      </div>
    </div>
  );
};

TableHeader.displayName = 'TableHeader';

export default TableHeader;

import { CoinSymbolType, NetworkAliasType } from '@bilira-org/react-utils';

/**
 * Generates a URL for the icon of a given coin or network type.
 * This function uses the `ALIAS_MAP` to transform certain network aliases into their respective coin symbols.
 * For instance, 'BSC' is mapped to 'BNB'.
 *
 * @param {string} baseUrl - The base URL of the CDN.
 * @param {CoinSymbolType | NetworkAliasType} [type] - The coin or network type for which to get the icon URL.
 * @returns {string|undefined} The URL for the icon if the type is provided; otherwise, undefined.
 *
 * @example
 * getIconURLByType('https://cdn.bilira.co/', 'SOL') -> https://cdn.bilira.co/symbol/svg/SOL.svg
 * getIconURLByType('https://cdn.bilira.co/', 'BSC') -> https://cdn.bilira.co/symbol/svg/BNB.svg
 */
export function getIconURLByType(baseUrl: string, type?: CoinSymbolType | NetworkAliasType) {
  if (!type) {
    return undefined;
  }

  return `${baseUrl}/symbol/svg/${type}.svg`;
}

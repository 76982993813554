import React from 'react';

import { Alert, Block, Button, Link, Text } from '@bilira-org/design';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  errorKey?: string;
};

const WithdrawNotAllowed = ({ errorKey }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {errorKey && (
        <Alert mt="md" status="red">
          <Block gap="sm">
            <Text as="span" size="sm" color="secondary-500">
              <Trans
                t={t}
                i18nKey={errorKey}
                components={{
                  'kyc2-anchor': <Link to="/account/id-verification" underline />,
                  'fiat-tx-anchor': <Link to="/fiat" underline />,
                }}
              />
            </Text>
            {errorKey === 'crypto.withdraw-not-allowed.kyc2' && (
              <Link to="/account/id-verification">
                <Button variant="filled" size="sm">
                  {t('crypto.withdraw-not-allowed.upgrade-now')}
                </Button>
              </Link>
            )}
          </Block>
        </Alert>
      )}
    </>
  );
};

export default WithdrawNotAllowed;

import React, { useMemo } from 'react';

import { ColumnDef, DataTable, Skeleton, TableBodyStyle, TableHeaderStyle, Text } from '@bilira-org/design';
import { UserActivity } from '@bilira-org/react-utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import idpQuery from '@Libs/clientInstances/idpQuery';

const headerStyle: TableHeaderStyle = { pb: '2xl' };
const bodyStyle: TableBodyStyle = { py: 'md' };

function UserActivityTable() {
  const { t, i18n } = useTranslation();
  const { data, isPending } = idpQuery.useGetUserActivity();

  const cols = useMemo<ColumnDef<UserActivity, string>[]>(
    () => [
      {
        accessorKey: 'time',
        header: t('account.security-settings.login-summary.time'),
        cell: (cell) => <>{dayjs(cell.getValue(), { locale: i18n.language }).format('DD/MM/YYYY, HH:mm:ss')}</>,
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'ip',
        header: t('account.security-settings.login-summary.ip-address'),
        skeleton: <Skeleton height="size-6" width="size-28" />,
      },
      {
        accessorFn: (row) => `${row.browser} (${row.os})`,
        id: 'browser',
        header: t('account.security-settings.login-summary.browser'),
        skeleton: <Skeleton height="size-6" width="size-28" />,
      },
    ],
    [t, i18n.language],
  );

  return (
    <>
      <Text heading size="xl" weight="bold" mb="md">
        {t('account.security-settings.recent-login-history')}
      </Text>
      <Text mb="2xl">{t('account.security-settings.summary-of-last-10-login-activities')}</Text>

      <DataTable.Responsive
        columns={cols}
        loading={isPending}
        skeletonRowCount={3}
        data={data}
        headerStyle={headerStyle}
        bodyStyle={bodyStyle}
      />
    </>
  );
}

export default UserActivityTable;

type Props<T> = {
  list: T[];
  pinnedField: string;
  pinnedValue?: string;
};

/**
 * Rearranges an array of objects by pinning items that match a specified field value to the start of the array.
 *
 * The function takes a generic array of objects (`list`) and a `pinnedField` which is a string.
 * If an object in the array has a property matching `pinnedField` with a value equal to `pinnedAsset`,
 * these objects are moved to the beginning of the array. The rest of the items follow afterwards.
 * If `pinnedAsset` is not provided, the original list is returned without any modifications.
 *
 * @template T - The type of the objects in the array.
 * @param {Props<T>} props - The properties for the pinItems function.
 * @param {T[]} props.list - An array of objects to be rearranged.
 * @param {string} props.pinnedField - The name of the field to be checked against `pinnedAsset`.
 * @param {string} [props.pinnedValue] - The value to match against `pinnedField`. If not provided, the list is returned as is.
 * @returns {T[]} The rearranged array with matched items pinned to the start.
 *
 * @example
 * // Example of a list of objects
 * const productList = [
 *   { id: 1, name: "Apple", category: "Fruit" },
 *   { id: 2, name: "Carrot", category: "Vegetable" },
 *   { id: 3, name: "Banana", category: "Fruit" }
 * ];
 *
 * // Pinning items where category is 'Fruit'
 *  pinItems({
 *   list: productList,
 *   pinnedField: "category",
 *   pinnedValue: "Fruit"
 * });
 * // --> Returns:
 * // [
 * //   { id: 1, name: "Apple", category: "Fruit" },
 * //   { id: 3, name: "Banana", category: "Fruit" },
 * //   { id: 2, name: "Carrot", category: "Vegetable" }
 * // ]
 *
 * // Using pinItems without specifying a pinnedValue
 * pinItems({ list: productList, pinnedField: "category" });
 * // --> Returns the productList in its original order as no pinnedValue is given
 */
const pinItems = <T>({ list, pinnedValue, pinnedField }: Props<T>): T[] => {
  if (!pinnedValue || !list) {
    return list;
  }

  const foundList = list.filter((asset) => asset[pinnedField as keyof T] === pinnedValue);
  const notFoundList = list.filter((asset) => asset[pinnedField as keyof T] !== pinnedValue);

  return [...foundList, ...notFoundList];
};

export default pinItems;

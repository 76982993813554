import React from 'react';

import { Block, Skeleton, Text } from '@bilira-org/design';
import { AssetPairPriceType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import AssetUnitPriceInfo, { AssetUnitPriceInfoProps } from '@Components/trade/components/AssetUnitPriceInfo';

type Props = AssetUnitPriceInfoProps & {
  /** Whether component is loading */
  loading: boolean;
};

/**
 * Renders unit price item for the swap.
 *
 * @example
 * <UnitPriceItem loading={true} toAsset={'TRYB'} fromAsset={'USDT'}  />
 */
function UnitPriceItem({
  loading,
  disableTooltip = false,
  weight = 'bold',
  justify = 'end',
  ...priceInfoProps
}: Props) {
  const { t } = useTranslation();

  return (
    <Block row justify="between" items="center">
      <Text size="sm" weight="regular" color="neutral-700">
        {t('market.swap.price')}
      </Text>
      {loading ? (
        <Skeleton width="size-16" height="size-4" />
      ) : (
        <AssetUnitPriceInfo disableTooltip weight="bold" justify="end" {...priceInfoProps} />
      )}
    </Block>
  );
}

export default UnitPriceItem;

/**
 * This is a detectBrowser function that detects user's browser and operating system.
 * It returns an object with boolean properties indicating the detected browser and operating system.
 *
 * @function detectBrowser
 * @returns {object} Returns an object with properties:
 *    - isAndroid: true if the user's operating system is Android
 *    - isIOS: true if the user's operating system is iOS
 *
 * @example
 * // Usage
 * const browserDetector = detectBrowser();
 * const isAndroid = browserDetector.isAndroid; // true or false
 * const isIOS = browserDetector.isIOS; // true or false
 *
 *
 */
const detectBrowser = () => {
  const userAgent = window.navigator.userAgent;
  const isAndroid = Boolean(userAgent.match(/Android/i));
  const isIos = Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isHarmony = Boolean(userAgent.match(/HarmonyOS|HUAWEI/i));

  return {
    isAndroid: isAndroid,
    isIOS: isIos,
    isWeb: !isAndroid && !isIos && !isHarmony,
    isHarmony: isHarmony,
  };
};

export default detectBrowser;

import React, { FC, memo, useCallback, useRef, useState } from 'react';

import { Modal, Stepper, StepperPreviousHandle } from '@bilira-org/design';
import { CryptoNetworkType, WalletModel } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { Networks } from '@Components/cryptoCards';
import { useWalletStore } from '@Components/walletAddress/components/store/walletStore';
import { TRACKER_EVENTS } from '@Libs/tracker/events';
import { addTrackerCryptoWithdrawal } from '@Libs/tracker/helpers/addTrackerCryptoWithdrawal';

import { CreateWallet, Wallets } from './components';
import { steps } from './constants';

type WalletAddressModalType = {
  /** Indicates whether the modal is active or not. */
  modalActive: boolean;
  /** Function to be called when modal is closed. */
  setModalActive: (active: boolean) => void;
  /** Callback function to be executed after selecting a wallet. */
  callback?: () => void;
  /** Indicates whether the modal is in selection mode or not. */
  isSelectionMode?: boolean;
  /** Network filter for crypto wallets */
  filter?: CryptoNetworkType;
};

const WalletAddressModal: FC<WalletAddressModalType> = ({
  filter,
  callback,
  modalActive,
  setModalActive,
  isSelectionMode,
}) => {
  const [currentStep, setCurrentStep] = useState(steps.walletAddress);
  const { selectedWallet, setSelectedWallet } = useWalletStore();
  const [activeNetwork, setActiveNetwork] = useState<CryptoNetworkType>();
  const { t } = useTranslation();

  const stepperRef = useRef<StepperPreviousHandle<string>>(null);

  const previous = useCallback((initial?: boolean) => {
    if (initial) {
      stepperRef?.current?.clearHistory();
      setCurrentStep(steps.walletAddress);
      return;
    }

    const previousStep = stepperRef?.current?.previous();
    setCurrentStep(previousStep as string);
  }, []);

  return (
    <Modal
      onPreviousClick={previous}
      hasPreviousButton={currentStep !== steps.walletAddress}
      title={t('wallet-address.modal.title')}
      open={modalActive}
      onClose={setModalActive}
    >
      <Stepper ref={stepperRef} step={currentStep}>
        <Stepper.Item pageName={steps.walletAddress} key={steps.walletAddress}>
          <Wallets
            filter={filter}
            isSelectionMode={isSelectionMode}
            onSelected={() => {
              callback?.();
              previous(true);
            }}
            callback={(wallet?: WalletModel) => {
              addTrackerCryptoWithdrawal(TRACKER_EVENTS.CRYPTO_WITHDRAWAL.SELECT_WALLET, wallet);

              setSelectedWallet(wallet);
              setActiveNetwork(undefined);
              setCurrentStep(steps.createWalletsAddress);
            }}
          />
        </Stepper.Item>
        <Stepper.Item pageName={steps.createWalletsAddress} key={steps.createWalletsAddress}>
          <CreateWallet
            onFinish={() => {
              previous(true);
            }}
            callback={(wallet) => {
              if (wallet) {
                addTrackerCryptoWithdrawal(TRACKER_EVENTS.CRYPTO_WITHDRAWAL.SELECT_WALLET, wallet);
              } else {
                addTrackerCryptoWithdrawal(TRACKER_EVENTS.CRYPTO_WALLET.ADD_WALLET);
              }

              setSelectedWallet(wallet);
            }}
            wallet={selectedWallet}
            network={filter || activeNetwork}
            onNetworkClick={() => setCurrentStep(steps.networks)}
            disableNetworkSelection={!!filter}
          />
        </Stepper.Item>
        <Stepper.Item pageName={steps.networks} key={steps.networks}>
          <Networks onFinish={() => previous()} setNetwork={setActiveNetwork} />
        </Stepper.Item>
      </Stepper>
    </Modal>
  );
};

export default memo(WalletAddressModal);

import React from 'react';

import { Block, LayoutV2, Text } from '@bilira-org/design';

const { Hero } = LayoutV2;

type Props = {
  /** Title to be displayed */
  title: string;
};

/**
 * Hero banner for displaying title of the screen
 */
const HeroStrip = ({ title }: Props) => {
  return (
    <>
      <Hero>
        <Block row justify="between" items="center">
          <Text heading size="xl" weight="bold" my="md">
            {title}
          </Text>
        </Block>
      </Hero>
    </>
  );
};

export default HeroStrip;

import React, { memo } from 'react';

import { Block, Button, Confetti, Modal, Text } from '@bilira-org/design';
import { Trans, useTranslation } from 'react-i18next';

import { withPrivateRoute } from '@/libs/hocs';
import WarningTemplate from '@Components/template/WarningTemplate';

import useRegistrationModal from './hooks/useRegistrationModal';

const RewardsRegistrationModal = () => {
  const { t } = useTranslation();

  const {
    open,
    handleReject,
    isRejectPending,
    handleParticipation,
    isParticipationPending,
    isPrerequisitesPending,
    particleRef,
  } = useRegistrationModal();

  return (
    <>
      <Modal open={open} closable={false}>
        <WarningTemplate icon="completed-big">
          <Block gap="md" items="center" maxWidth="sm" align="center">
            <Text heading size="xl" weight="bold" align="center">
              {t('rewards.eligible-modal.congrats')}
            </Text>

            <Text size="sm" color="neutral-800" align="center">
              <Trans t={t} i18nKey="rewards.eligible-modal.description" components={{ br: <br /> }} />
            </Text>
          </Block>
          <Block gap="md" width="size-full" mt="lg">
            <Button
              size="xl"
              stretch
              variant="filled"
              onClick={handleParticipation}
              loading={isParticipationPending}
              disabled={isRejectPending}
            >
              {t('rewards.join-point-system')}
            </Button>
            <Button
              size="xl"
              stretch
              variant="outline"
              onClick={handleReject}
              loading={isRejectPending || isPrerequisitesPending}
              disabled={isParticipationPending}
            >
              {t('rewards.later')}
            </Button>
          </Block>
        </WarningTemplate>

        <Confetti ref={particleRef} />
      </Modal>
    </>
  );
};
export default memo(withPrivateRoute(RewardsRegistrationModal));

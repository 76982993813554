import React from 'react';

import { Button } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import useLocaleRoute from '@Libs/hooks/useLocaleRoute';

const LoginRegisterButton = ({ type }: { type: 'login' | 'register' }) => {
  const { t } = useTranslation();
  const { getRoute } = useLocaleRoute();

  if (type === 'login') {
    return (
      <Button
        route={{ to: getRoute('Login') }}
        style={{ height: '40px' }}
        variant="outline"
        size="md"
        testId="login-btn"
      >
        {t('navigation.login')}
      </Button>
    );
  }

  return (
    <Button route={{ to: getRoute('Register') }} style={{ height: '40px' }} variant="filled" size="md">
      {t('navigation.create-free-account')}
    </Button>
  );
};

export default LoginRegisterButton;

import React from 'react';

import { Block, Icon, Meta, Skeleton, StepProgress, Text } from '@bilira-org/design';
import { TransactionsRecordType } from '@bilira-org/react-utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import OTCTag from '@/modules/otc/components/OTCTag';
import IconCoin from '@Components/icon/IconCoin';
import TransactionTitle from '@Components/transaction/components/TransactionTitle';
import AccountType from '@Components/transaction/modal/components/AccountType';
import { getOTCStatusConfig, OTC_TRANSACTION_STATUS } from '@Libs/helpers/getStateConfig';

import TransactionID from '../TransactionID';

type TransactionSummaryModalContentProps = {
  data?: TransactionsRecordType;
};

function Index({ data }: TransactionSummaryModalContentProps) {
  const { t, i18n } = useTranslation();

  if (!data) {
    return (
      <Block gap="md">
        <Skeleton height="size-4" width="size-full" />
        <Skeleton height="size-4" width="size-1/2" />
        <Skeleton height="size-4" width="size-full" />
        <Skeleton height="size-4" width="size-full" />
        <Skeleton height="size-4" width="size-1/2" />
        <Skeleton height="size-4" width="size-full" />
        <Skeleton height="size-4" width="size-full" />
        <Skeleton height="size-4" width="size-1/2" />
        <Skeleton height="size-4" width="size-full" />
      </Block>
    );
  }

  return (
    <StepProgress
      overflow="visible"
      color="neutral-300"
      vertical
      size="lg"
      steps={[
        {
          title: (
            <Meta
              title={<Text size="sm">{t('transactions.transaction-number')}</Text>}
              description={<TransactionID data={data} />}
            />
          ),
          icon: <Icon color="secondary-500" size="lg" type="o:hashtag" />,
        },
        {
          title: (
            <Meta
              title={<Text size="sm">{t('transactions.transaction-type')}</Text>}
              description={
                <Block row gap="md" items="center">
                  <TransactionTitle data={data} />
                  <OTCTag />
                </Block>
              }
            />
          ),
          icon: (
            <Icon
              color="secondary-500"
              size="lg"
              type={getOTCStatusConfig(data.status).icon(`${data.type}:${data.direction}`)}
            />
          ),
          iconExtra: data.network ? { icon: <IconCoin type={data.network?.alias} size="lg" /> } : undefined,
        },
        {
          title: (
            <Meta
              title={<Text size="sm">{t('transactions.offer-time')}</Text>}
              description={
                <Text weight="medium" color="secondary-500">
                  {dayjs(data?.created_at, { locale: i18n.language }).format('DD MMM YYYY, HH:mm')}
                </Text>
              }
            />
          ),
          icon: <Icon color="secondary-500" size="lg" type="o:clock" />,
        },
        {
          title: (
            <Meta
              title={<Text size="sm">{t('transactions.settlement-date')}</Text>}
              description={
                <Text weight="medium" color="secondary-500">
                  {dayjs(data.settlement_day, { locale: i18n.language }).format('DD MMM YYYY')}
                </Text>
              }
            />
          ),
          icon: <Icon color="secondary-500" size="lg" type="o:clock" />,
          hidden: !['accepted', 'completed'].includes(data.status),
        },
        {
          title: <AccountType data={data} />,
          icon: <Icon color="secondary-500" size="lg" type="o:circle-stack" />,
        },
        {
          title: (
            <Meta
              title={<Text size="sm">{t('transactions.transaction-status')}</Text>}
              description={
                <Text weight="medium" color={OTC_TRANSACTION_STATUS[data?.status].text}>
                  {t(`otc.status.${data.status}`)}
                </Text>
              }
            />
          ),
          icon: <Icon color="secondary-500" size="lg" type="o:document-check" />,
        },
      ]}
    />
  );
}

export default Index;

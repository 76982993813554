import React from 'react';

import { Text } from '@bilira-org/design';
import type { InternalTransferType } from '@bilira-org/react-utils';
import { t } from 'i18next';

import { TransactionItemSizeType } from '../../TransactionTable';
import { transactionTextSize } from '../constants';

type TransactionTitleProps = {
  data: InternalTransferType;
  size: TransactionItemSizeType;
};
const TransactionTitle = ({ data, size }: TransactionTitleProps) => {
  return (
    <Text weight="medium" size={transactionTextSize[size]} wordBreak="none" color="secondary-500">
      {t(`transactions.${data.type}-${data.direction}`, { info: data.info, asset: data.asset })}
    </Text>
  );
};

export default TransactionTitle;

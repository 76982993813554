import BigNumber from 'bignumber.js';

/**
 * Calculates the minimum value among a list of string or undefined values.
 *
 * @param {...(string | undefined)} values - The values to find the minimum from.
 * @returns {BigNumber} - The minimum value as a BigNumber.
 * @example
 * const minValue = getMin('5', '1', '3');
 * console.log(minValue.toString()); // Output: 1
 */
export const getMin = (...values: (string | number | undefined)[]): BigNumber => {
  const filteredValues = values.filter(
    (value) => (typeof value === 'string' || typeof value === 'number') && value !== '',
  ) as string[];

  return BigNumber.min(...filteredValues.map((value) => BigNumber(value)));
};

/**
 * Calculates the maximum value among a list of string or undefined values.
 *
 * @param {...(string | undefined)} values - The values to find the maximum from.
 * @returns {BigNumber} - The maximum value as a BigNumber.
 * @example
 * const maxValue = getMax('5', '2', '10');
 * console.log(maxValue.toString()); // Output: 10
 */
export const getMax = (...values: (string | number | undefined)[]): BigNumber => {
  const filteredValues = values.filter(
    (value) => (typeof value === 'string' || typeof value === 'number') && value !== '',
  ) as string[];

  return BigNumber.max(...filteredValues.map((value) => BigNumber(value)));
};

/**
 * Calculates the percentage change between an old value and a new value.
 *
 * @param {string} oldValue - The old value.
 * @param {string} newValue - The new value.
 * @returns {string} - The calculated percentage change as a string.
 * @example
 * const percentageChange = calculateChangePercentage('50', '75');
 * console.log(percentageChange); // Output: "50.00"
 */
export const calculateChangePercentage = (oldValue: string, newValue: string): string => {
  return new BigNumber(newValue).minus(oldValue).div(oldValue).multipliedBy(100).toPrecision(2);
};

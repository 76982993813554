import React, { ComponentProps } from 'react';

import { Number } from '@bilira-org/design';
import { ForceDecimal, FormatPriceType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

type Props = ComponentProps<typeof Number>;

/**
 * Component to display number with default format props and locale
 *
 * @example
 * <FormattedNumber value="30"/> // 30.00 or 30,00 based on i18n locale
 *
 * <FormattedNumber value="0.1"/> // 0.10 or 0,10 based on i18n locale
 *
 * <FormattedNumber value="1000"/> // 1,000.00 or 1.000,00 based on i18n locale
 *
 * <FormattedNumber value="0.00001234"/> // 0.00001234 or 0,00001234 based on i18n locale
 *
 * <FormattedNumber value="0.0000123456" formatPriceProps={{precision:0}}/> // 0.0000123456 or 0,0000123456 based on i18n locale
 */
const FormattedNumber = ({ formatPriceProps, ...rest }: Props) => {
  const { i18n } = useTranslation();

  const format: Partial<FormatPriceType> = {
    decimal: 2,
    forceDecimal: ForceDecimal.SHOW_INSIGNIFICANT_DIGITS,
    precision: 5,
    locale: i18n.language,
    ...formatPriceProps,
  };

  return <Number formatPriceProps={format} {...rest} />;
};

export default FormattedNumber;

import React from 'react';

import { BankNames, IconBank, useDarkMode } from '@bilira-org/design';

import { OtherBankIcon } from './OtherBankIcon';

export type IconProps = Omit<React.ComponentProps<'svg'>, 'type' | 'name'> & {
  /** Name of the icon */
  name: string;
  /** Type of the icon */
  type?: 'icon' | 'logo';
};

/**
 * Bank icon component that renders different bank icons based on the specified type and theme.
 * @example
 * // Example usage of the IconBank component
 * <IconBank name="akbank" type="icon" size="lg" />
 */
export function BankIcon({ name, type = 'logo' }: IconProps) {
  const [isDark] = useDarkMode();

  if (name === 'other') {
    return <OtherBankIcon type={type} />;
  }

  return <IconBank type={`${name}-${type}-${isDark ? 'dark' : 'light'}` as BankNames} />;
}

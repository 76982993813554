// Since swap token selection depends on base asset, base asset must be a valid asset which included among pairs.
// Since we don't have TRY among pairs, we should do this check.
// NOTE: Yet, this TRY is necessary for chart detail. And chart detail does not use this function.
import { CoinSymbolType } from '@bilira-org/react-utils';

/**
 * Filters the base asset, replacing 'TRY' with 'TRYB' otherwise returns asset.
 *
 * @param {string} asset - The asset symbol to filter.
 * @returns {string} - The filtered asset symbol.
 *
 * @example
 * filterBaseAsset('TRY') // 'TRYB'
 * filterBaseAsset('ETH') // 'ETH'
 */
const filterBaseAsset = (asset: string): string => {
  if (asset === 'TRY') {
    return 'TRYB';
  }

  return asset;
};

/**
 * Parses an asset pair string into an array of coin symbols.
 *@deprecated import it from utils
 * @param {string | undefined} assetPair - The asset pair string to parse splitted with '_' (USDT_ETH).
 * @returns {CoinSymbolType[]} - An array containing two coin symbols.
 *                              If parsing fails, an empty array is returned.
 *
 * @example
 * parseAssetPair('USDT_ETH'); // ['USDT', 'ETH']
 */
const parseAssetPair = (assetPair?: string) => {
  if (!assetPair) {
    return [];
  }

  const result = assetPair.split('_');

  if (result.length > 1) {
    return [result[0] as CoinSymbolType, result[1] as CoinSymbolType];
  }

  return [];
};

export default parseAssetPair;

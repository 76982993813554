import { IConfigApi } from './interface';
import { HttpClient, HttpClientType, HttpErrorType } from '../../client';
import { RemoteConfigRecord } from '../../model';

class ConfigApi extends HttpClient implements IConfigApi {
  public constructor(conf: HttpClientType) {
    super(conf);
  }

  public getRemoteConfig = async () => await this.instance.get<HttpErrorType, RemoteConfigRecord[]>('/remote_config');
}

export default ConfigApi;

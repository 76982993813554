import { Flex, Icon, Layout, Span, Tab, TabProps } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import RewardsQuery from '@Libs/clientInstances/rewardsQuery';
import Header from '@Modules/rewards/private/components/Header';
import TaskList from '@Modules/rewards/private/components/TaskList';

import RewardInfoAlert from './components/RewardInfoAlert';
import SeasonTimer from './seasonTimer/SeasonTimer';

const { Content } = Layout;

const ParticipatedContent = () => {
  const { t } = useTranslation();

  const { data: seasons, isPending: isSeasonsPending } = RewardsQuery.useGetSeasons();
  const { data } = RewardsQuery.useGetUserParticipationPrerequisites();

  const headers: TabProps<string>['headers'] = [
    {
      name: (
        <Flex direction="row" items="center" gap="xs">
          {t('rewards.tabs.season', { number: seasons ? seasons?.past_seasons.length + 1 : '-' })}
          {!data?.participated_at && <Icon type="o:lock-closed" size="sm" />}
        </Flex>
      ),
      key: 'rewards.tabs.currentSeason',
    },
    {
      name: (
        <Flex direction="row" items="center">
          {t('rewards.tabs.season', { number: seasons ? seasons?.past_seasons.length + 2 : '-' })} &nbsp;
          <Span size="xs" weight="medium">
            {t('rewards.tabs.coming-soon')}
          </Span>
        </Flex>
      ),
      key: 'rewards.tabs.nextSeason',
      disabled: true,
    },
  ];

  return (
    <>
      <Header />
      <Content>
        <Tab
          defaultIndex={0}
          variant="bordered"
          headers={headers}
          extra={isSeasonsPending ? undefined : <SeasonTimer season={seasons} key={seasons?.current_season?.id} />}
        >
          <Tab.Panel>
            <RewardInfoAlert />
            <TaskList />
          </Tab.Panel>
        </Tab>
      </Content>
    </>
  );
};

export default ParticipatedContent;

import { memo } from 'react';

import { Display, Modal } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import GenericNotificationContent from '@Components/layouts/components/components/modals/notification/components/GenericNotificationContent';
import TransactionNotificationContent from '@Components/layouts/components/components/modals/notification/components/TransactionNotificationContent';
import { useGeneralStore } from '@Store/generalStore';

/**
 * Notification modal that is controlled by general store.
 * Utilizes notificationData and setNotificationData states to manage modal state.
 */
const NotificationModal = () => {
  const { t } = useTranslation();

  const { notificationData, setNotificationData } = useGeneralStore((store) => ({
    notificationData: store.notificationModal,
    setNotificationData: store.setNotificationModal,
  }));

  const { open, title, hideButton, notification } = notificationData || {};

  const onClose = () => {
    setNotificationData({ open: false });
  };

  return (
    <Modal compound title={title || t('common.notifications')} open={open} onClose={onClose}>
      <Display show={notification?.type === 'generic'}>
        <GenericNotificationContent data={notification} onClose={onClose} hideButton={hideButton} />
      </Display>
      <Display show={notification?.type !== 'generic'}>
        <TransactionNotificationContent data={notification} onClose={onClose} hideButton={hideButton} />
      </Display>
    </Modal>
  );
};

export default memo(NotificationModal);

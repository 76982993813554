import React, { PropsWithChildren } from 'react';

import { HorizontalMenuItemWithDropdown } from './components/horizontalMenu';
import { VerticalMenuItemWithDropdown } from './components/verticalMenu';
import { MenuItemWithDropdownProps } from './types';

export const MenuItemWithDropdown = ({
  vertical,
  children,
  display = true,
  ...props
}: PropsWithChildren<MenuItemWithDropdownProps> & { vertical?: boolean }) => {
  if (!display) {
    return null;
  }

  const Element = vertical ? VerticalMenuItemWithDropdown : HorizontalMenuItemWithDropdown;

  return <Element {...props} />;
};

import React from 'react';

import { Text, TextProps } from '@bilira-org/design';

type Props = { children: React.ReactNode } & TextProps;

const CellText = ({ children, ...rest }: Props) => {
  return (
    <Text size="sm" weight="regular" color="neutral-900" {...rest}>
      {children}
    </Text>
  );
};

export default CellText;

import { useEffect, useState } from 'react';

import { Alert, Block, Button, Grid, notification, RadioGroup, Separator, Text } from '@bilira-org/design';
import Modal from '@bilira-org/design/src/components/modal/modal';
import { TransactionsParamsType, TransactionTypes } from '@bilira-org/react-utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import useAuth from '@/libs/hooks/userAuth';
import { handleErrorResponse } from '@/libs/utils';
import { useGeneralStore } from '@/store';
import AccountApi from '@Libs/clientInstances/accountQuery';

import Filters, { VariantType } from './components/Filters';
import TransactionExportSuccessModal from './TransactionExportSuccessModal';

type Props = {
  defaultFilters: Omit<TransactionsParamsType, 'account'>;
  defaultType: TransactionTypes;
  defaultVariant: VariantType;
  defaultFilterLabels: Record<string, string | undefined>;
};

function TransactionExportModal({ defaultFilters, defaultVariant, defaultType, defaultFilterLabels }: Props) {
  const { t } = useTranslation();

  const { account } = useAuth();
  const { data: allowance } = AccountApi.useGetTransactionExportAllowance(account);
  const { mutateAsync, isPending } = AccountApi.usePostTransactionExportRequest();

  const [txExportModal, setTxExportModal] = useGeneralStore((state) => [state.txExportModal, state.setTxExportModal]);
  const [variant, setVariant] = useState<VariantType>('deposit-withdraw');
  const [type, setType] = useState<TransactionTypes>(defaultType);
  const [filters, setFilters] = useState<Omit<TransactionsParamsType, 'account'> & { transaction?: 'buy' | 'sell' }>(
    {},
  );
  const [filterLabels, setFilterLabels] = useState<Record<string, string | undefined>>({});
  const [successModal, setSuccessModal] = useState(false);

  useEffect(() => {
    if (txExportModal && defaultVariant !== 'other') {
      setFilters(defaultFilters);
      setVariant(defaultVariant);
      setType(defaultType);
      setFilterLabels(defaultFilterLabels);
    }
  }, [txExportModal]);

  const onExport = () => {
    const loadingNotificationId = notification.loading(t('transactions.export-being-processed'));

    mutateAsync({
      accountId: account,
      body: {
        asset: filters.asset || 'all',
        direction: filters.side || filters.direction || 'all',
        type: type,
        start_date: filters.startDate || dayjs().subtract(1, 'year').toISOString(),
        end_date: filters.endDate || dayjs().toISOString(),
      },
    })
      .then(() => {
        notification.dismiss(loadingNotificationId);
        setTxExportModal(false);
        setSuccessModal(true);
      })
      .catch((e) => {
        notification.dismiss(loadingNotificationId);
        handleErrorResponse(e, t('transactions.export-failed'));
      });
  };

  return (
    <>
      <Modal
        open={txExportModal}
        onClose={() => setTxExportModal(false)}
        title={t('transactions.export-all-statements')}
        testId="transaction-export-modal"
      >
        <RadioGroup
          controlled
          as={Grid}
          col={2}
          testId="variant-radio-group"
          mt="sm"
          dataSource={[
            { id: 'deposit-withdraw', label: t('transactions.deposit-withdraw-transactions') },
            { id: 'swap', label: t('transactions.buy-sell-transactions') },
          ]}
          selected={{ id: variant, label: '' }}
          setSelected={(value) => {
            setVariant(value.id as any);
            setFilters({});
            setType(value.id === 'deposit-withdraw' ? 'fiat' : 'swap');
          }}
        />
        <Separator width="size-full" my="3xl" />
        <Grid col={2} gap="lg">
          <Filters
            displayVariant="export"
            filters={filters}
            setFilters={setFilters}
            variant={variant}
            type={type}
            setType={(value) => {
              setType(value);
              setFilters({});
            }}
            filterLabels={filterLabels}
            setFilterLabels={setFilterLabels}
          />
        </Grid>
        <Block row items="center" mt="3xl" gap="lg">
          <Button
            onClick={onExport}
            variant="filled"
            size="xl"
            disabled={!allowance?.remaining || allowance?.remaining < 1}
            loading={isPending}
          >
            {t('transactions.create-export-request')}
          </Button>
          <Text size="xs" color="neutral-800">
            {t('transactions.monthly-remaining-exports', { remaining: allowance?.remaining || '0' })}
          </Text>
        </Block>
        <Alert status="yellow" mt="md">
          {t('transactions.monthly-export-quota', { quota: allowance?.monthly_limit || '-' })}
        </Alert>
      </Modal>
      <TransactionExportSuccessModal active={successModal} setActive={setSuccessModal} />
    </>
  );
}

export default TransactionExportModal;

import React from 'react';

import { Block, Icon, Link, useCopy } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { getShortId } from '@/libs/utils';

type Props = {
  txId?: string;
  txUrl?: string;
};

const TransactionId = ({ txId, txUrl }: Props) => {
  const { t } = useTranslation();
  const { onCopy } = useCopy({ copiedNotification: t('common.copied') });
  return (
    <Block
      row
      gap="xs"
      onClick={(e) => {
        e.stopPropagation();
        if (txUrl) {
          window.open(txUrl, '_blank');
        } else {
          onCopy({ copyValue: txId });
        }
      }}
      items="center"
    >
      <Link anchor target="_blank" href={txUrl}>
        #{getShortId(txId)}
      </Link>
      {txUrl && <Icon type="o:arrow-top-right-on-square" size="sm" />}
      <Icon
        type="o:square-2-stack"
        size="sm"
        onClick={(e) => {
          e.stopPropagation();
          onCopy({ copyValue: txId });
        }}
      />
    </Block>
  );
};

export default TransactionId;

import React from 'react';

import { clsx } from 'clsx';

import AppIcon from '../../assets/bilira/AppIcon.svg';
import DevIcon from '../../assets/bilira/AppIconDev.svg';
import KriptoAppIcon from '../../assets/bilira/KriptoAppIcon.svg';
import KriptoAppIconWithText from '../../assets/bilira/KriptoLogoWithText.svg';
import AppIconWithText from '../../assets/bilira/LogoWithText.svg';
import MetamaskIcon from '../../assets/partners/MetamaskIcon.svg';
import { BaseComponentType, FixSizeType, MarginSizes, TextColorType } from '../types';

type LogoVariantTypes =
  | 'text-icon'
  | 'dev-icon'
  | 'app-icon'
  | 'direct'
  | 'metamask-icon'
  | 'kripto-app-icon'
  | 'kripto-text-icon';

export type LogoProps = BaseComponentType & {
  /** Additional class names for styling. */
  className?: string;
  /** Margin on the y-axis.*/
  my?: MarginSizes;
  /** Margin on the top. */
  mt?: MarginSizes;
  /** Margin on the bottom. */
  mb?: MarginSizes;
  /** Variant of the logo to display. */
  variant?: LogoVariantTypes;
  /** Fixed size for the logo. */
  size?: FixSizeType | 'custom-direct' | 'custom-metamask';
  /** Additional inline styles for the logo. */
  style?: React.CSSProperties;
  /** Color of the icon. */
  color?: TextColorType;
};

/**
 * The `Logo` component is used to display different variants of the application icon.
 *
 * @example
 * <Logo variant="text-icon" />
 * <Logo variant="dev-icon" />
 * <Logo variant="app-icon" />
 */
const Logo = ({ my, mt, mb, size, className, style, variant = 'app-icon', testId, color }: LogoProps): JSX.Element => {
  const classes = clsx('logo', className, {
    [`my-${my}`]: my,
    [`mt-${mt}`]: mt,
    [`mb-${mb}`]: mb,
    [`fix-size-${size}`]: size && !size.includes('custom'),
    [`logo-${size}`]: size?.includes('custom'),
    [`tw-text-${color}`]: color,
  });

  const Element = IconLogoComponent[variant];

  return <Element className={classes} style={style} data-testid={testId ?? 'logo'} />;
};

Logo.displayName = 'Logo';

export default Logo;

const IconLogoComponent: Record<LogoVariantTypes, any> = {
  'text-icon': AppIconWithText,
  'dev-icon': DevIcon,
  'app-icon': AppIcon,
  'metamask-icon': MetamaskIcon,
  'kripto-app-icon': KriptoAppIcon,
  'kripto-text-icon': KriptoAppIconWithText,
  direct: KriptoAppIconWithText,
};

import FormattedNumber from '@/components/common/FormattedNumber';
import UserQuery from '@Libs/clientInstances/userQuery';

const ReferralTotalEarning = () => {
  const { data: referralCode, isPending: isReferralCodePending } = UserQuery.useGetReferralCodes();

  return (
    <FormattedNumber
      loading={isReferralCodePending}
      color="secondary-500"
      weight="medium"
      value={referralCode?.total_earning?.toString()}
      formatPriceProps={{ defaultValue: '0' }}
      suffix="TRYB"
    />
  );
};

export default ReferralTotalEarning;

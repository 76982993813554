import React from 'react';

import kycQuery from '@Libs/clientInstances/idpQuery';

import { LEGAL_STATUSES } from '../constants';
import Kyc1Panel from './components/Kyc1Panel';
import Kyc2Panel from './components/Kyc2Panel';
import Kyc3Panel from './components/Kyc3Panel';
import KycCorporatePanel from './components/KycCorporatePanel';

function Cards() {
  const { data, isLoading } = kycQuery.useGetUserKyc();

  if (isLoading) {
    return null;
  }

  if (data?.legal_status === LEGAL_STATUSES.corporate) {
    return <KycCorporatePanel />;
  }

  return (
    <>
      <Kyc1Panel />
      <Kyc2Panel />
      <Kyc3Panel />
    </>
  );
}

export default Cards;

import React from 'react';

import { Block, Icon, Text, Tooltip } from '@bilira-org/design';
import { CoinSymbolType, getFormattedNumberByPrice, isEmpty } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

type Props = {
  amount?: string;
  price?: string;
  base: CoinSymbolType;
  quote: CoinSymbolType;
};

/**
 * Component for displaying asset unit price information.
 *
 * This component calculates and displays the unit price of an asset based on a given amount and asset pair price.
 * It optionally allows toggling between the base and quote assets of the asset pair.
 */
function UnitPrice({ price, quote, base }: Props) {
  const { t, i18n } = useTranslation();

  if (isEmpty(price)) {
    return <Text>-</Text>;
  }

  const getUnitAssetPriceInfo = () => {
    if (price === undefined) {
      return '-';
    }
    const formattedPrice = getFormattedNumberByPrice({
      value: price,
      price: price,
      type: 'price',
      locale: i18n.language,
    });

    return `1 ${base} = ${formattedPrice} ${quote}`;
  };

  return (
    <>
      <Block row items="center">
        <Tooltip width="size-max" placement="top" message={t('market.swap.approximate-price-info')} position="left">
          <Icon color="neutral-500" type="s:information-circle" size="sm" />
        </Tooltip>
        <Text size="sm" weight="regular" color="neutral-700">
          {getUnitAssetPriceInfo()}
        </Text>
      </Block>
    </>
  );
}

export default UnitPrice;

import React from 'react';

import { Block, Display, IconCustom, Meta, Skeleton, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { USER_TIER_ICONS } from '@/libs/constants';
import SwapQuery from '@Libs/clientInstances/swapQuery';
import useUserInfo from '@Libs/hooks/useUserInfo';

/**
 * Component to display KYC level of the user with an icon
 */
function UserKycCard() {
  const { t } = useTranslation();
  const { maskedEmail } = useUserInfo();
  const { data, isPending } = SwapQuery.useGetSwapCommissionTier();

  return (
    <Block mx="md">
      <Text size="base" weight="medium" mb="xs" color="secondary-500">
        {maskedEmail}
      </Text>
      <Display show={isPending || (!isPending && data?.name)}>
        <Meta
          extra={
            <Block p="xs">
              {isPending ? (
                <Skeleton rounded="full" width="size-4" />
              ) : (
                <IconCustom type={USER_TIER_ICONS[data?.name.toLowerCase() as string]} width="size-4" />
              )}
            </Block>
          }
          space="xs"
          title={
            <Text weight="regular" size="sm" color="neutral-700" loading={isPending}>
              {t(`commission.levels.${data?.name.toLowerCase()}`)}
            </Text>
          }
        />
      </Display>
    </Block>
  );
}

export default UserKycCard;

import React from 'react';

import { Badge, Block, Flex, Hidden, Icon, Meta, Text } from '@bilira-org/design';
import { OTCType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import FormattedNumber from '@/components/common/FormattedNumber';
import IconCoin from '@/components/icon/IconCoin';

import NetworkIndicator from '../otcTrade/components/NetworkIndicator';

type Props = { entry: OTCType };

const OTCInfo = ({ entry }: Props) => {
  const { t } = useTranslation();

  const Inner = (
    <Flex
      direction={{ xs: 'col', sm: 'row' }}
      items={{ xs: 'stretch', sm: 'end' }}
      justify={{ xs: 'between', sm: 'between', lg: 'start' }}
      gap="sm"
      mt="sm"
      mb={{ xs: 'sm', sm: 'sm', lg: 'lg' }}
      width="full"
    >
      <Flex direction={{ xs: 'row', sm: 'col' }} justify={{ xs: 'between', lg: 'start' }} items={{ xs: 'center' }}>
        <Text size="xs" mb="xs" weight="medium" color="neutral-700">
          {t('otc.selling')}
        </Text>
        <Flex gap="sm" direction={{ xs: 'row', sm: 'row-reverse' }}>
          <FormattedNumber
            size={{ xs: 'sm', xl: 'base' }}
            color="secondary-500"
            weight="medium"
            value={entry.from_amount}
            suffix={entry.from_asset}
          />
          <IconCoin type={entry.from_asset} size="2xl" />
        </Flex>
      </Flex>
      <Hidden show={{ xs: false, sm: true }}>
        <Icon type="o:arrow-right" size="xs" my="xs" color="neutral-700" />
      </Hidden>
      <Hidden show={{ xs: true, sm: false }}>
        <Flex direction="row" justify="end">
          <Icon type="o:arrow-down" size="xs" style={{ marginRight: '6px' }} color="neutral-700" />
        </Flex>
      </Hidden>

      <Flex direction={{ xs: 'row', sm: 'col' }} justify={{ xs: 'between' }}>
        <Flex direction="row" justify={{ xs: 'start', sm: 'end', lg: 'start' }}>
          <Text size="xs" mb="xs" weight="medium" color="neutral-700">
            {t('otc.buying')}
          </Text>
        </Flex>
        <Flex gap="sm" direction={{ xs: 'row', sm: 'row', lg: 'row-reverse' }}>
          <FormattedNumber
            size={{ xs: 'sm', xl: 'base' }}
            color="secondary-500"
            weight="medium"
            value={entry.to_amount}
            suffix={entry.to_asset}
          />
          <NetworkIndicator networkAlias={entry.network}>
            <IconCoin type={entry.to_asset} size="2xl" />
          </NetworkIndicator>
        </Flex>
      </Flex>
    </Flex>
  );
  return (
    <>
      <Hidden show={{ xs: true, sm: true, lg: false }}>
        <Badge size="lg" variant="square" color="neutral-200" width="size-full">
          {Inner}
        </Badge>
      </Hidden>
      <Hidden show={{ xs: false, sm: false, lg: true }}>{Inner}</Hidden>
    </>
  );
};

export default OTCInfo;

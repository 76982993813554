import React, { ComponentProps } from 'react';

import { Number } from '@bilira-org/design';
import { formatNumberByPrice, FormatNumberByPriceType, LanguageUniversalType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import useFormatNumberByLivePrice from '@/libs/hooks/useFormatNumberByLivePrice';

type StaticPriceComponentProps = ComponentProps<typeof Number> & {
  type: FormatNumberByPriceType;
  price: string;
  quote?: never;
  base?: never;
};

type LivePriceComponentProps = ComponentProps<typeof Number> & {
  quote?: string;
  type: FormatNumberByPriceType;
  base: string;
  price?: never;
};

type Props = LivePriceComponentProps | StaticPriceComponentProps;

/**
 * Component to display number with formatted according to price of the asset, formatting props and locale
 *
 * Component has 2 usages:
 * 1- If price is not passed, it subscribes to live price data to format the value.
 * 2- If price is passed, it is using passed price to format the value.
 *
 * @see See `formatByPrice` utility for formatting details.
 *
 * @example
 * <FormattedNumberByPrice price="1234" type="price" value="1"/> // uses static data
 *
 * <FormattedNumberByPrice base="ETH" quote="TRYB" type="price" value="1"/> // uses live price data
 */
const FormattedNumberByPrice = (props: Props) => {
  if (isStaticPriceComponentProps(props)) {
    return <StaticPriceComponent {...props} />;
  }
  return <LivePriceComponent {...props} />;
};

const StaticPriceComponent = ({ price, type = 'amount', ...rest }: StaticPriceComponentProps) => {
  const { i18n } = useTranslation();
  const formatter = (value?: string, defaultValue?: string) => {
    return formatNumberByPrice({ price, value, defaultValue, type, locale: i18n.language as LanguageUniversalType })
      .values as string[];
  };

  return <Number formatter={formatter} {...rest} />;
};

const LivePriceComponent = ({ base, quote = 'TRYB', type = 'price', ...rest }: LivePriceComponentProps) => {
  const formatNumberByLivePrice = useFormatNumberByLivePrice({ base, quote });

  const formatter = (value?: string, defaultValue?: string) => {
    return formatNumberByLivePrice({ value, defaultValue, type }).values as string[];
  };

  return <Number formatter={formatter} {...rest} />;
};

// Type guard to check if props is StaticPriceComponentProps
function isStaticPriceComponentProps(props: Props): props is StaticPriceComponentProps {
  return (props as StaticPriceComponentProps).price !== undefined;
}

export default FormattedNumberByPrice;

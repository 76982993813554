import { useMemo } from 'react';

import { concatTicker } from '@bilira-org/react-utils';

import AccountQuery from '@Libs/clientInstances/accountQuery';
import { useSubscribeToScroll } from '@Libs/hooks/useScrollEvents';

const useWatchlistHook = () => {
  const {
    pages: assetList,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
  } = AccountQuery.useGetWatchListData({
    category: 'market',
    limit: 10,
  });

  useSubscribeToScroll({ eventName: 'reached-content-scroll-bottom', callback: fetchNextPage });

  const subscriptionList = useMemo<string[]>(() => {
    return assetList?.map((item) => concatTicker(item.base_asset, item.quote_asset)) ?? [];
  }, [assetList]);

  return {
    isFetchingNextPage,
    assetList,
    isPending: isLoading,
    subscriptionList,
  };
};

export default useWatchlistHook;

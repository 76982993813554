import React from 'react';

import { Block, Flex, Image, Link, List, Loop, Skeleton, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import BlogQuery from '@Libs/clientInstances/blogQuery';

const LearnCard = () => {
  const { i18n, t } = useTranslation();
  const { data: { records: data = [] } = {}, isPending } = BlogQuery.useGetBlogPosts(
    {
      locale: i18n.language,
      limit: 3,
      offset: 0,
    },
    { gcTime: 50000, staleTime: 50000 },
  );

  if (isPending) {
    return <LearnCardSkeleton />;
  }

  return (
    <List
      testId="learn-list"
      direction={{
        xs: 'col',
        sm: 'row',
        md: 'col',
        xl: 'col',
      }}
      gap="xl"
      dataSource={data}
      renderItem={(item) => (
        <List.Item key={item.slug}>
          <Link width="size-full" anchor href={`${t('urls.blog-prefix')}${item.slug}`} target="_blank">
            <Flex
              direction={{ xs: 'row', sm: 'col', md: 'row' }}
              gap="md"
              items={{ xs: 'center', sm: 'start', md: 'center' }}
            >
              <Image
                style={{ width: '150px' }}
                src={item.image}
                alt={item.name}
                objectFit="fill"
                rounded="lg"
                skeletonAspectRatio={2}
              />
              <Text weight="semibold" color="secondary-500" lineClamp={2}>
                {item.name}
              </Text>
            </Flex>
          </Link>
        </List.Item>
      )}
    />
  );
};

export default LearnCard;

const LearnCardSkeleton = () => {
  return (
    <Block gap="lg" mt="sm">
      <Loop data={3}>
        <Block row gap="md" items="center">
          <Skeleton style={{ width: '150px', height: '75px' }} />
          <div>
            <Skeleton width="size-28" height="size-3" rounded="sm" mb="sm" />

            <Skeleton width="size-20" height="size-3" rounded="sm" />
          </div>
        </Block>
      </Loop>
    </Block>
  );
};

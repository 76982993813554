import React from 'react';

import BigNumber from 'bignumber.js';

import FormattedNumberByPrice from '@/components/common/FormattedNumberByPrice';

type Props = {
  amount?: string;
  withdrawalFee?: string | null;
} & ({ base: string; price?: never } | { price: string; base?: never });

const TransactionAmount = ({ amount = '0', withdrawalFee, ...rest }: Props) => {
  const feeExcludedAmount = BigNumber(amount)
    .minus(withdrawalFee || '0')
    .toString();

  return <FormattedNumberByPrice size="sm" color="neutral-900" value={feeExcludedAmount} type="amount" {...rest} />;
};

export default TransactionAmount;

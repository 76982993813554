import { createContext, useContext } from 'react';

import { AccordionItemProps } from './item';

export type AccordionContextType =
  | {
      /** Map of active accordions */
      activeAccordions: Map<string, true>;
      /** Toggles an accordion item with given key */
      toggleAccordion: (key: string) => void;
      /** The variant of the accordion items */
      variant: 'primary' | 'secondary' | 'filled' | 'filled-theme';
      /** The icon props of the accordion items */
      iconProps?: AccordionItemProps['iconProps'];
    }
  | undefined;

export const AccordionContext = createContext<AccordionContextType>(undefined);

export function useAccordionContext() {
  const context = useContext(AccordionContext);
  if (context === undefined) {
    throw new Error('useAccordionContext must be used within a AccordionContextProvider');
  }
  return context;
}

import React from 'react';

import { Input, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { BuySellDirection, SwapAssetType } from '@Components/trade/types';

type Props = {
  placeholder?: string;
  onFromInput: (value: string) => void;
  value?: string;
  type: BuySellDirection;
  asset?: Partial<SwapAssetType>;
};

/**
 * Component representing a buy/sell input.
 */
const InputBuySell = ({ onFromInput, value, placeholder = '0', asset, type }: Props) => {
  const { i18n } = useTranslation();

  return (
    <>
      <Input.FormattedFancyNumber
        testId="trade-input"
        decimalPlaces={asset?.scale}
        disabled={!asset?.symbol}
        onChange={(value) => {
          if (asset?.symbol) onFromInput(value);
        }}
        cursorColor={type === 'buy' ? 'green' : 'red'}
        placeholder={placeholder}
        value={value}
        locale={i18n.language}
        icon={
          <Text size="sm" weight="regular" color="neutral-600">
            {asset?.symbol}
          </Text>
        }
      />
    </>
  );
};

export default InputBuySell;

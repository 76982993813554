import React, { forwardRef, useEffect, useRef, useState } from 'react';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

import { FilePondErrorDescription, FilePondFile, FileStatus } from 'filepond';
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilepondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { FilePond, FilePondProps, registerPlugin } from 'react-filepond';

import { Lightbox } from '../lightbox';
import { composeRef } from '../ref';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { BaseComponentType } from '../types';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilepondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginFileEncode,
  FilePondPluginImageEdit,
);

export interface FileUploaderRefProps extends FilePond {}

export type FileType = FilePondFile;

export type FileUploaderProps = Omit<FilePondProps, 'onupdatefiles' | 'files'> &
  BaseComponentType & {
    maxFiles?: number;
    maxFileSize?: string | null;
    base64File?: string;
    onUpload?: (source: FileType[]) => void;
    files?: FileType[];
    // files?: Array<
    //   FilePondInitialFile | (Blob & { readonly lastModified: number; readonly name: string }) | Blob | string
    // >;
  };

const FileUploader = forwardRef<FileUploaderRefProps, FileUploaderProps>(
  (
    {
      base64File,
      maxFileSize = '10MB',
      maxFiles = 1,
      credits = false,
      allowMultiple = true,
      styleItemPanelAspectRatio = '1',
      onUpload,
      files,
      testId,
      labelIdle: labelIdleFromProps,
      ...props
    },
    ref,
  ) => {
    const innerRef = useRef<FilePond | null>(null);
    const previewRef = useRef<string>();

    const [open, setOpen] = useState(false);

    const editor = {
      open: (file: Blob) => {
        // open editor here

        // const image = new Image();
        previewRef.current = URL.createObjectURL(file);
        setOpen(true);
        // console.log('on editor open', image);
      },
    };

    useEffect(() => {
      if (base64File) {
        innerRef.current?.addFile(base64File);
      }
    }, [base64File]);

    const labelIdle =
      labelIdleFromProps ||
      `
    <div class='flex flex-col gap-2'>
      <div><span>Dosyalarınızı buraya bırakın ya da <span class='filepond--label-action text-primary-500 font-medium'>göz atın</span></span></div> 
      <div><span class='filepond--label-action filepond--label-description'>Maks. dosya boyutu ${maxFileSize}</span></div>
    </div>`;

    return (
      <>
        <FilePond
          imageEditEditor={editor}
          allowImageEdit={true}
          imageEditIconEdit={`
          <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'>
            <path stroke-linecap='round' stroke-linejoin='round' d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6' />
          </svg>
          `}
          ref={composeRef(ref, innerRef)}
          files={files?.map((d) => d.source)}
          className="file-uploader"
          styleItemPanelAspectRatio={styleItemPanelAspectRatio}
          onupdatefiles={onUpload}
          onaddfile={(err, item) => {
            if (err) {
              console.warn(err);
              return;
            }
            // console.log(item.file);
            // const dataURL = item.getFileEncodeDataURL();
            // const base64String = item.getFileEncodeBase64String();
          }}
          credits={credits}
          allowMultiple={allowMultiple}
          maxFiles={maxFiles}
          maxFileSize={maxFileSize}
          labelIdle={labelIdle}
          data-testid={testId ?? 'file-uploader'}
          {...props}
        />
        <Lightbox toggleOpen={setOpen} isOpen={open} src={previewRef.current} alt="hebele" />
      </>
    );
  },
);

FileUploader.displayName = 'FileUploader';

export default FileUploader;
export { FileStatus };

import { compareNumbers } from './compareNumbers';
import { compareStrings } from './compareStrings';
import { isNumberOrStringOrUndefined } from '../isNumberOrStringOrUndefined';
import { isNumericValue } from '../isNumericValue';
import { isStringOrUndefined } from '../isStringOrUndefined';

import { SortOrderType } from '.';

/**
 * Returns a sorting function for the specified field and order.
 *
 * @param {T} field - The field by which to sort.
 * @param {SortOrderType} order - The order in which to sort (Use SortOrder enum for values).
 * @param {'auto' | 'number' | 'string'} [sorter] - The type of the sort field. 'auto' by default.
 * @returns {(a, b) => number} A function that compares two crypto assets.
 */
const getSortingFunction = <T>(field: keyof T, order: SortOrderType, sorter: 'auto' | 'number' | 'string') => {
  switch (sorter) {
    case 'string':
      return (a: T, b: T) => {
        const x = a[field];
        const y = b[field];

        if (isStringOrUndefined(x) && isStringOrUndefined(y)) {
          return compareStrings(x, y, order);
        }
        return 0;
      };
    case 'number':
      return (a: T, b: T) => {
        const x = a[field];
        const y = b[field];

        if (isNumberOrStringOrUndefined(x) && isNumberOrStringOrUndefined(y)) {
          return compareNumbers(x, y, order);
        }

        return 0;
      };
    case 'auto':
    default:
      return (a: T, b: T) => {
        const x = a[field];
        const y = b[field];

        if (isNumberOrStringOrUndefined(x) && isNumberOrStringOrUndefined(y)) {
          if (isNumericValue(x) || isNumericValue(y)) {
            return compareNumbers(x, y, order);
          } else if (typeof x !== 'number' && typeof y !== 'number') {
            return compareStrings(x, y, order);
          }
        }
        return 0;
      };
  }
};

/**
 * Sorts a list of crypto assets based on the specified field and order.
 *
 * @param {T[]} data - The list of crypto assets to sort.
 * @param {keyof T} field - The field by which to sort.
 * @param {SortOrderType} [order] - The order in which to sort (Use SortOrder enum for values).
 * @param {'auto' | 'number' | 'string'} [sorter] - The sorting function, you can pass custom function or use available sorters. 'auto' by default.
 * @returns {T[]} The sorted list of crypto assets.
 *
 * @example
 * const assets = [
 *   { symbol: 'BTC', lastPrice: '40000', marketCap: '600000', change24h: '.05' },
 *   { symbol: 'ETH', lastPrice: '3000', marketCap: '300000', change24h: '.03' }
 * ];
 *
 * const sortedByPriceAsc = sortList(assets, 'price', SortOrder.ASC);
 * // => sortedByPriceAsc: [{ symbol: 'ETH', lastPrice: '3000', marketCap: '3000000', change24h: '.03' }, { symbol: 'BTC', lastPrice: '40000', marketCap: '600000', change24h: '.05' }]
 *
 * const sortedBySymbolDesc = sortList(assets, 'symbol', SortOrder.DESC);
 * // => sortedBySymbolDesc: [{ symbol: 'ETH', lastPrice: '3000', marketCap: '300000', change24h: '.03' }, { symbol: 'BTC', lastPrice: '40000', marketCap: '600000', change24h: '.05' }]
 *
 * const sortedBySymbolLastCharacter = sortList(assets, 'symbol', undefined, (a, b, order)=> a.symbol[3] > b.symbol[3] ? 1 : -1);
 * // => sortedBySymbolLastCharacter: [{ symbol: 'BTC', lastPrice: '40000', marketCap: '600000', change24h: '.05' }, { symbol: 'ETH', lastPrice: '3000', marketCap: '300000', change24h: '.03' }]
 */
export const sortListBy = <T>(
  data: T[],
  field: keyof T,
  order?: SortOrderType,
  sorter: 'auto' | 'number' | 'string' | ((a: T, b: T) => number) = 'auto',
): T[] => {
  if (!data || !data.length || !field || !order) {
    return data;
  }

  const sortingFunction =
    typeof sorter === 'function' ? (a: T, b: T) => sorter(a, b) : getSortingFunction(field, order, sorter);

  return [...data].sort(sortingFunction);
};

import {
  HttpClientType,
  HttpErrorType,
  PreferencesPutType,
  QueryOptions,
  UserPreferenceType,
} from '@bilira-org/react-utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { PreferencesType } from '../../model/Preferences';
import { IPreferencesApi, PreferencesApi } from '../api';
import { PreferencesQueryKey, UserQueryKey } from '../constants';

class PreferencesQuery {
  _api: IPreferencesApi;

  public constructor(params: HttpClientType) {
    this._api = new PreferencesApi(params);
  }

  public usePutUserPreference = (options?: QueryOptions) => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: this._api.putUserPreference,
      onSuccess: (data, variables) => {
        if (variables.key === 'language') {
          // reset notifications if language changed
          queryClient.resetQueries({ queryKey: [UserQueryKey.NOTIFICATIONS] });
        }

        queryClient.setQueriesData({ queryKey: [PreferencesQueryKey.USER_PREFERENCES] }, (oldData) => {
          if (!Array.isArray(oldData)) {
            return oldData;
          }
          const newData: UserPreferenceType[] = JSON.parse(JSON.stringify(oldData));
          const index = newData.findIndex((preference) => preference.key === variables.key);
          if (index > -1) {
            newData[index].value = variables.value;
          }

          return newData;
        });
      },
      ...options,
    });
  };
  public usePutReference = (options?: QueryOptions) => {
    return useMutation<PreferencesType, HttpErrorType, PreferencesPutType>({
      mutationFn: this._api.putAccountPreference,
      ...options,
    });
  };

  public useGetPreferences = (accountId: string, options?: QueryOptions) =>
    useQuery<PreferencesType[], HttpErrorType>({
      queryKey: [PreferencesQueryKey.ACCOUNT_PREFERENCES, accountId],
      queryFn: () => this._api.getPreferences(accountId),
      ...options,
    });

  public useGetUserPreferences = (options?: QueryOptions) =>
    useQuery<UserPreferenceType[], HttpErrorType>({
      queryKey: [PreferencesQueryKey.USER_PREFERENCES],
      queryFn: () => this._api.getUserPreference(),
      ...options,
    });
}

export default PreferencesQuery;

import React, { useEffect, useState } from 'react';

import { Block } from '@bilira-org/design';
import { PriceAssetTagType } from '@bilira-org/react-utils';
import { useLocation } from 'react-router-dom';

import PriceQuery from '@Libs/clientInstances/priceQuery';
import { SWAP_COMPLETED_HASH } from '@Libs/constants';
import GeneralTags from '@Modules/market/market/table/components/components/GeneralTags';

type Props = {
  callback: (tag?: string) => void;
  tag?: string;
  filterType?: 'market' | 'general';
};
const GeneralFilterTags = ({ tag, callback, filterType }: Props) => {
  const [dataTag, setDataTag] = useState<string | undefined>(tag);

  const { data } = PriceQuery.useGetAssetTags();
  const params = useLocation();

  const callbackTag = (tag?: PriceAssetTagType) => {
    setSelectedTag(tag?.tag);
  };

  useEffect(() => {
    if (params.hash && params.hash !== SWAP_COMPLETED_HASH) {
      const tag = params.hash.replace('#', '');
      setSelectedTag(tag);
    }
  }, [params]);

  const setSelectedTag = (tag?: string) => {
    setDataTag(tag);
    callback(tag);
  };

  const marketTags = data?.filter((d) => d.category === filterType);

  return (
    <>
      <Block row justify="between" gap="md" items="center" mb="lg">
        <GeneralTags selectedItem={dataTag} items={marketTags} callback={callbackTag} />
      </Block>
    </>
  );
};

export default GeneralFilterTags;

import React from 'react';

import { Block, Button, Hidden, Icon, Layout, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

const { Hero } = Layout;

const DepositInfoBar = () => {
  const { t } = useTranslation();

  return (
    <Hero>
      <Block row justify="between" items="center">
        <Text heading size="xl" weight="bold" px="md">
          {t('crypto.crypto-deposit-tab')}
        </Text>

        <Hidden show={{ md: true }}>
          <Button
            endIcon={<Icon type="o:arrow-right" />}
            size="md"
            route={{ to: '/crypto/withdraw' }}
            variant="filled-neutral"
          >
            {t('crypto.crypto-withdraw-tab')}
          </Button>
        </Hidden>
      </Block>
    </Hero>
  );
};

export default DepositInfoBar;

import React from 'react';

import { Avatar, Icon } from '@bilira-org/design';
import { OTCTransactionType } from '@bilira-org/react-utils';

import IconCoin from '@Components/icon/IconCoin';
import { getOTCStatusConfig } from '@Libs/helpers/getStateConfig';

type Props = {
  data: OTCTransactionType;
};

const TransactionIcon = ({ data }: Props) => {
  return (
    <Avatar
      variant="padded"
      iconColor="theme-wn"
      tr={<IconCoin type={data.network?.alias} size={{ xs: 'md', sm: 'lg' }} />}
      avatar={
        <Icon
          size={{ xs: 'xs', sm: 'md' }}
          type={getOTCStatusConfig(data.status).icon(`${data.type}:${data.direction}`)}
          color={getOTCStatusConfig(data.status).text}
          overlayColor={getOTCStatusConfig(data.status).bg}
          overlaySize={{ xs: '2xl', sm: '4xl' }}
        />
      }
      offset={{ top: 'sm', right: 'sm' }}
    />
  );
};

export default TransactionIcon;

import { SortOrder, SortOrderType } from '.';

/**
 * Compares two strings according to the specified order, ignoring case.
 *
 * @param {string} [a=''] - The first string to compare. Defaults to an empty string.
 * @param {string} [b=''] - The second string to compare. Defaults to an empty string.
 * @param {SortOrderType} order - The order in which to sort (Use SortOrder enum for values).
 * @returns {number} Returns -1 if `a` should come before `b`, 1 if `a` should come after `b`, and 0 if they are equal.
 *
 * *  Returns |                                                        |
 * :-------:|:--------------------------------------------------------|
 *     1 if order is ascending, -1 if order is descending  | If string `a` is greater than string `b` (case insensitive)
 *    -1 if order is ascending, 1 if order is descending   | If string `a` is less than string `b` (case insensitive)
 *     0                                                   | If string `a` and string `b` are equal (case insensitive)
 *
 * @example
 * compareStrings('apple', 'banana', SortOrder.ASC); // -1
 * compareStrings('banana', 'apple', SortOrder.ASC); // 1
 * compareStrings('apple', undefined, SortOrder.ASC); // 1
 * compareStrings('apple', 'apple', SortOrder.ASC); // -1
 *
 * compareStrings('apple', 'banana', SortOrder.DESC); // 1
 * compareStrings('banana', 'apple', SortOrder.DESC); // -1
 * compareStrings('apple', undefined, SortOrder.ASC); // -1
 * compareStrings('apple', 'apple', SortOrder.DESC); // 1
 */
export const compareStrings = (a: string = '', b: string = '', order: SortOrderType) => {
  const compare = a?.toLowerCase() > b?.toLowerCase() ? 1 : -1;
  return order === SortOrder.ASC ? compare : -compare;
};

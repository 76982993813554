import { Button, Modal, Span, Text } from '@bilira-org/design';
import { NotificationType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

type Props = {
  /** Notification data */
  data?: NotificationType;
  /** Callback function to be called when modal is closed*/
  onClose: () => void;
  /** Whether to hide Understood button */
  hideButton?: boolean;
};

/**
 * Generic content for the notification modal
 */
const GenericNotificationContent = ({ data, onClose, hideButton }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal.Body>
        <Text weight="medium" color="secondary-500" mb="sm">
          {data?.data.title}
        </Text>
        <Text dangerouslySetInnerHTML={{ __html: data?.data.description || '' }} />
      </Modal.Body>
      {!hideButton && (
        <Modal.Footer>
          <Button justify="center" stretch variant="outline" size="lg" onClick={onClose}>
            <Span color="primary-500">{t('common.understood')}</Span>
          </Button>
        </Modal.Footer>
      )}
    </>
  );
};

export default GenericNotificationContent;

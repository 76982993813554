import { useMemo } from 'react';

import { Text, TextProps } from '@bilira-org/design';
import {
  BalanceType,
  CoinSymbolType,
  formatNumberByPrice,
  FormatNumberByPriceProps,
  FormatNumberByPriceType,
  LanguageUniversalType,
} from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { useSessionStore } from '@/store';
import accountQuery from '@Libs/clientInstances/accountQuery';
import useAuth from '@Libs/hooks/userAuth';
import { usePersistedStore } from '@Store/persistedStore';

export type PriceChangeProps = Omit<TextProps, 'children'> & {
  /** Value to be formatted and displayed */
  value?: string;
  format?: FormatNumberByPriceProps;
};

/**
 * Displays a formatted balance, optionally masked for privacy.
 * * Mask flag controlled by persisted store on app scale.
 * `HiddenBalanceEyeIcon` can be used for toggling visibility.
 * * Extends `Text` component props for customization.
 *
 * @example
 * <MaskableFormattedBalance value={"542.32"} suffix="TRYB" />
 */
const MaskableFormattedBalance = ({ value, format, ...props }: PriceChangeProps) => {
  const { i18n } = useTranslation();
  const isLoggedIn = useSessionStore((state) => state.isLoggedIn);
  const maskBalance = usePersistedStore.getState().maskBalance;

  const result = formatNumberByPrice({
    value: isLoggedIn && value ? value : '',
    locale: i18n.language as LanguageType,
    price: '',
    type: 'amount',
  });

  return <Text {...props}>{maskBalance ? '******' : result.value}</Text>;
};

export type MaskableBalanceProps = Omit<TextProps, 'children'> & {
  type: FormatNumberByPriceType;
  /** Value to be formatted and displayed */
  value?: string;
  format?: Partial<FormatNumberByPriceProps>;
  field: keyof BalanceType;
  base: CoinSymbolType;
};

/**
 * Displays a formatted balance, optionally masked for privacy.
 * * Mask flag controlled by persisted store on app scale.
 * `HiddenBalanceEyeIcon` can be used for toggling visibility.
 * * Extends `Text` component props for customization.
 *
 * @example
 * <MaskableFormattedBalance value={"542.32"} suffix="TRYB" />
 */
const MaskableBalance = ({ type, value, format, field, base, ...props }: MaskableBalanceProps) => {
  const { i18n } = useTranslation();

  const maskBalance = usePersistedStore.getState().maskBalance;
  const { account } = useAuth();
  const { data = [] as BalanceType[] } = accountQuery.useGetBalances(
    account,
    { quote_asset: 'TRYB' },
    { enabled: !!account },
  );

  const item = useMemo(() => {
    const item = data.find((item) => item.asset === base);
    return item;
  }, [data, base]);

  const result = formatNumberByPrice({
    type: type,
    price: item?.unit_price || 1,
    value: item?.[field] as string,
    locale: i18n.language as LanguageUniversalType,
    ...format,
  });

  return <Text {...props}>{maskBalance ? '﹡﹡﹡﹡﹡' : result.value}</Text>;
};

export { MaskableBalance };

export default MaskableFormattedBalance;

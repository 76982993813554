import React, { ReactNode } from 'react';

import { clsx } from 'clsx';

import { BaseComponentType, BorderColorType, MarginSizes } from '../types';

interface IDivider extends BaseComponentType {
  /** Color of the dividers */
  color?: BorderColorType;
  /** Children to add divider between */
  children?: ReactNode;
  /** If true, divider will be vertical */
  vertical?: boolean;
  /** Margin y-axis size*/
  my?: MarginSizes;
  /** Margin x-axis size*/
  mx?: MarginSizes;
}

/**
 * Adds divider between children
 *
 * @example
 * <Divider>
 *   <div>1</div>
 *   <div>2</div>
 *   <div>3</div>
 * </Divider>
 */
const Divider = ({ vertical, color = 'neutral-400', children, my, mx, testId }: IDivider) => {
  return (
    <div
      className={clsx({
        'divide-black': !color,
        'divide-y': !vertical,
        'divide-x': vertical,
        [`tw-divide-${color}`]: color,
        [`my-${my}`]: my,
        [`mx-${mx}`]: mx,
      })}
      data-testid={testId ?? 'divider'}
    >
      {children}
    </div>
  );
};

Divider.displayName = 'Divider';

export default Divider;

import React from 'react';

import { Text } from '@bilira-org/design';
import type { TransactionsRecordType } from '@bilira-org/react-utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { TransactionItemSizeType } from '../../TransactionTable';
import { transactionStatusSize } from '../constants';

type Props = {
  data: TransactionsRecordType;
  size: TransactionItemSizeType;
};

const TransactionStatus = ({ data, size }: Props) => {
  const { t, i18n } = useTranslation();

  data.status ??= 'completed';

  return (
    <Text size={transactionStatusSize[size]} wordBreak="none">
      {data.status === 'completed'
        ? dayjs(data.created_at, { locale: i18n.language }).format('DD MMM YYYY, HH:mm')
        : t(`transactions.statuses.${data.status}`)}
    </Text>
  );
};

export default TransactionStatus;

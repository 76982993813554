import React from 'react';

import useRemoteConfig, { UseRemoteConfigProps } from '@/libs/hooks/useRemoteConfig';

export type FeatureFlagProps = {
  /* Content to display. */
  children: React.ReactNode;
  /* Whether to display the content. */
  remoteConfigKey: UseRemoteConfigProps['key'];
};

/**
 * Display component based on Remote Config parameters.
 *
 * @example
 * <FeatureFlag remoteConfigKey={'REMOTE_CONFIG_KEY'}>
 *   <div>Content</div>
 * </FeatureFlag>
 */

const FeatureFlag = ({ children, remoteConfigKey }: FeatureFlagProps) => {
  const {
    data: { isEnabled },
  } = useRemoteConfig({ key: remoteConfigKey });

  if (!isEnabled) {
    return null;
  }

  return <>{children}</>;
};

export default FeatureFlag;

import { clsx } from 'clsx';

import { Icon } from '../icons';
import { IconProps } from '../icons/icon';

/**
 * Default icon props for the accordion variants.
 */
export const defaultIconProps = {
  primary: {
    position: 'left',
    color: 'neutral-700',
    type: 's:chevron-right',
    size: 'lg',
  },
  secondary: {
    position: 'right',
    color: 'neutral-700',
    type: 's:chevron-right',
    size: 'lg',
  },
  filled: { position: 'right', size: 'lg', color: 'neutral-700', type: 's:chevron-down' },
  'filled-theme': { position: 'right', size: 'lg', color: 'neutral-700', type: 's:chevron-down' },
} as const;

const activeIconClass = {
  's:chevron-right': 'rotate-90 transform',
  's:chevron-down': 'rotate-180 transform',
};

export interface AccordionIconProps {
  /** Color of the icon. */
  color: IconProps['color'];
  /** Type of the icon. */
  type: 's:chevron-right' | 's:chevron-down';
  /** Size of the icon. */
  size: IconProps['size'];
  /** Whether the accordion is active or not. */
  active: boolean;
  /** Position of the icon. */
  position: 'right' | 'left';
}

/**
 * Styled icon for accordion component.
 *
 * @example
 * <AccordionIcon
 *   color="neutral-700"
 *   type="s:chevron-right"
 *   size="lg"
 *   active={true}
 *   position="right"
 * />
 */
const AccordionIcon = (props: AccordionIconProps) => (
  <Icon
    size={props.size}
    type={props.type}
    color={props.color}
    className={clsx('transition duration-75', {
      'ml-auto': props.position === 'right',
      [activeIconClass[props.type]]: props.active,
    })}
  />
);

export default AccordionIcon;

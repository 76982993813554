import BigNumber from 'bignumber.js';

/**
 * Checks if a value is a numeric value, including strings representing numbers.
 *
 * @param {unknown} value - The value to be checked.
 * @returns {boolean} - `true` if the value is a finite number, `false` otherwise.
 *
 * @example
 * isNumericValue(42); // true
 * isNumericValue(Infinity); // false
 * isNumericValue(NaN); // false
 * isNumericValue('42'); // true
 */
export function isNumericValue(value: any) {
  return BigNumber(value).isFinite();
}

import React, { memo } from 'react';

import { LayoutV2 } from '@bilira-org/design';
import { Outlet } from 'react-router-dom';

import Header from '@Components/layouts/components/Header';

import ErrorBoundary from '../errorBoundary/ErrorBoundary';

const { Head, Main, Container } = LayoutV2;

function LayoutError() {
  return (
    <ErrorBoundary>
      <LayoutV2>
        <Head>
          <LayoutV2.Header>
            <Header />
          </LayoutV2.Header>
        </Head>
        <Container>
          <Main>
            {/* Replacing your content */}
            <Outlet />
            {/* /End replace */}
          </Main>
        </Container>
      </LayoutV2>
    </ErrorBoundary>
  );
}

export default memo(LayoutError);

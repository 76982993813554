import React, { useMemo } from 'react';

import { Empty, List, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import SymbolPair from '@Components/common/SymbolPair';
import { StoredAssetType } from '@Components/globalSearch/types';
import CryptoQuery from '@Libs/clientInstances/cryptoQuery';

type Props = {
  /** Callback function to be called when an item is selected  */
  onSelected: (item: StoredAssetType) => void;
};

/**
 * Lists popular cryptos
 */
const PopularList = ({ onSelected }: Props) => {
  const { t } = useTranslation();
  const { data, isPending } = CryptoQuery.useGetAssets();
  const records = useMemo(() => data?.filter((record) => record.tags.includes('popular')), [data]);

  if (isPending) {
    return null;
  }

  return (
    <>
      <Text size="sm" weight="semibold" color="secondary-500" mb="sm">
        {t('global-search.popular')}
      </Text>

      <List
        empty={<Empty message={t('common.empty-message')} />}
        dataSource={records}
        space="xs"
        renderItem={(record) => (
          <List.Item
            key={`search-popular-${record.symbol}-${record.name}`}
            px="sm"
            py="sm"
            hoverBgColor="neutral-300"
            onClick={() => onSelected({ symbol: record.symbol, name: record.name })}
          >
            <SymbolPair
              size={{ icon: '2xl', title: 'sm', description: 'sm' }}
              icon={record.symbol}
              title={record.symbol}
              description={record.name}
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default PopularList;

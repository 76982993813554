import React, { Children, ForwardedRef, forwardRef, ReactElement, SelectHTMLAttributes, useState } from 'react';

import { clsx } from 'clsx';

import SelectOption, { SelectOptionProps } from './selectOption';
import { BackgroundColorType, BaseComponentType, BaseSize } from '../types';
import { wrapWithElementIfInvalid } from '../utils';

export type SelectProps<T> = Omit<SelectHTMLAttributes<HTMLSelectElement>, 'onChange' | 'value' | 'size' | 'color'> &
  BaseComponentType & {
    /** Additional classes for styling */
    className?: string;
    /** The options for the select dropdown. */
    children: ReactElement<SelectOptionProps<T>> | ReactElement<SelectOptionProps<T>>[];
    /** The initial selected value. */
    initialValue?: T;
    /** The selected value. */
    value?: T;
    /** Callback function triggered when the value changes. */
    onChange?: (value: T) => void;
    /** The size of the select. */
    size?: BaseSize;
    /** The background color of the select. */
    color?: BackgroundColorType;
    /** Whether the select is bordered. */
    bordered?: boolean;
    /** Whether the border offset should be applied on focus. */
    borderOffset?: boolean;
  };

/**
 * Customizable Select dropdown component.
 *
 * Passes selected value to the children(`SelectOption`)
 *
 * @example
 * <SelectInner value="1" onChange={(value) => console.log(value)}>
 *   <SelectOption value="1">Option 1</SelectOption>
 *   <SelectOption value="2">Option 2</SelectOption>
 *   <SelectOption value="3">Option 3</SelectOption>
 * <SelectInner>
 */
const SelectInner = <T extends string | number | undefined>(
  props: SelectProps<T>,
  ref: ForwardedRef<HTMLSelectElement>,
): JSX.Element => {
  const {
    children,
    initialValue,
    value,
    onChange,
    size,
    color,
    bordered = true,
    borderOffset,
    className,
    testId,
    ...rest
  } = props;

  const classes = clsx('select', className, {
    [`select-${size}`]: size,
    [`select-${color}`]: color,
    [`focus:outline-offset-0`]: !borderOffset,
    'select-bordered': bordered,
  });

  const [selectedValue, setSelectedValue] = useState<T | undefined>(value || initialValue);

  const childrenList = Children.toArray(children);

  return (
    <select
      {...rest}
      ref={ref}
      className={classes}
      value={value}
      onChange={(e) => {
        setSelectedValue(e.currentTarget.value as T);
        onChange && onChange(e.currentTarget.value as T);
      }}
      data-testid={testId ?? 'select'}
    >
      {childrenList.map((child) => {
        return wrapWithElementIfInvalid({
          node: child,
          wrapper: <div></div>,
          props: { selectedValue: selectedValue },
        });
      })}
    </select>
  );
};

SelectInner.displayName = 'Select';

// Make forwardRef work with generic component
const Select = forwardRef(SelectInner) as <T>(
  props: SelectProps<T> & { ref?: ForwardedRef<HTMLSelectElement> },
) => ReturnType<typeof SelectInner>;

export default Object.assign(Select, { Option: SelectOption });

class EventEmitter {
  private eventTarget: EventTarget;

  constructor() {
    console.log('initializezd.');
    this.eventTarget = new EventTarget();
  }

  emit(event: string, detail: Record<string, unknown> = {}): void {
    this.eventTarget.dispatchEvent(new CustomEvent(event, { detail }));
  }

  on(event: string, callback: EventListenerOrEventListenerObject): void {
    this.eventTarget.addEventListener(event, callback);
  }

  off(event: string, callback: EventListenerOrEventListenerObject): void {
    this.eventTarget.removeEventListener(event, callback);
  }
}

export const eventEmitter = new EventEmitter();

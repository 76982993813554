import React, { FC, HTMLAttributes } from 'react';

import { clsx } from 'clsx';

import { BaseComponentType } from '../types';

export type LayoutProps = HTMLAttributes<HTMLDivElement> &
  BaseComponentType & {
    /** Additional classes for styling */
    className?: string;
    /** The children to be rendered within the Position component. */
    children: React.ReactNode;
    /** CSS position property of the component. */
    position?: 'absolute' | 'relative' | 'fixed' | 'sticky' | 'static';
    /** Alignment of the component. */
    align?: 'center' | 'left' | 'right' | 'top' | 'bottom';
  };

/**
 * A component that provides positioning and alignment for its children components.
 *
 * @example
 * <Position position="relative" align="center">
 *   <Button>Button</Button>
 * </Position>
 */
const Position: React.FC<LayoutProps> = ({
  children,
  position = 'relative',
  align,
  className,
  testId,
}: LayoutProps) => {
  const classes = clsx('tw-position', className, {
    [`tw-position-${position}`]: position,
    [`tw-position-${align}`]: align,
  });

  return (
    <div className={classes} data-testid={testId}>
      {children}
    </div>
  );
};

Position.displayName = 'Position';

export default Position;

import React from 'react';

import { Block, Logo } from '@bilira-org/design';

const Header = () => {
  return (
    <Block row justify="between">
      <Logo variant="kripto-text-icon" />
    </Block>
  );
};

export default Header;

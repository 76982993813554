import React from 'react';

import { Icon } from '@bilira-org/design';
import type { TransactionsRecordType } from '@bilira-org/react-utils';

import { getStateConfig } from '@Libs/helpers/getStateConfig';

type Props = {
  data: TransactionsRecordType;
};

const TransactionIcon = ({ data }: Props) => {
  return (
    <Icon
      size={{ xs: 'xs', sm: 'md' }}
      type={getStateConfig(data.status).icon(`${data.type}:${data.direction}`)}
      color={getStateConfig(data.status).text}
      overlayColor={getStateConfig(data.status).bg}
      overlaySize={{ xs: '2xl', sm: '4xl' }}
    />
  );
};

export default TransactionIcon;

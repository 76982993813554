import React from 'react';

import { CoinSymbolType, NetworkAliasType } from '@bilira-org/react-utils';

import FormattedNumber from '@Components/common/FormattedNumber';
import useGetMinCryptoDepositLimit from '@Libs/hooks/useGetMinCryptoDepositLimit';

type Props = {
  asset: CoinSymbolType;
  networks: { alias: NetworkAliasType; name: string }[];
};

const MinDepositLimit = ({ asset, networks }: Props) => {
  const { getMinDepositLimit, isPending } = useGetMinCryptoDepositLimit();
  return (
    <>
      {networks.map((network) => (
        <FormattedNumber
          key={network.alias}
          loading={isPending}
          value={getMinDepositLimit(asset, network.alias)}
          size="sm"
          weight="regular"
        />
      ))}
    </>
  );
};

export default MinDepositLimit;

import { RewardsTasksType, UserParticipationType } from '../../model';

export type RewardTaskState =
  | 'toDo'
  | 'recurringCoolDown'
  | 'recurringToDo'
  | 'recurringActive'
  | 'recurringCompleted'
  | 'completed';

/**
 * Determines the state of a reward task based on the task's properties.
 *
 * @param {UserParticipationType | RewardsTasksType} task - The task object, which can either be a user participation task or a rewards task.
 * @returns {RewardTaskState} - Returns the current state of the task, which can be one of the following:
 * - 'toDo': The task has not been completed.
 * - 'recurringCoolDown': The task is recurring, but it is in the cooldown period.
 * - 'recurringToDo': The task is recurring but has not been completed in this season (points for this season are 0).
 * - 'recurringActive': The task is recurring and actively being progressed.
 * - 'completed': The task has been completed.
 */
export const getRewardTaskState = (task: UserParticipationType | RewardsTasksType): RewardTaskState => {
  if (!('status' in task)) {
    return 'toDo';
  }

  if (task.recursion_period && task.recursion_period !== 'none') {
    if (task.status === 'completed') {
      return 'recurringCompleted';
    }

    if (!task.recurring) {
      return 'recurringCoolDown';
    }

    if (task.points_this_season === '0' || task.points_this_season === undefined) {
      return 'recurringToDo';
    }

    return 'recurringActive';
  }

  return task.status === 'completed' ? 'completed' : 'toDo';
};

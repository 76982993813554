import React from 'react';

import { Block, Button, Checkbox, Col, Form, Grid, Input } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import {
  buildOptions,
  PersonalInfo3Type,
} from '@Modules/account/kyc/components/components/Kyc3Panel/helpers/constants';

type Props = {
  data?: PersonalInfo3Type;
  callback: (value: PersonalInfo3Type) => void;
};

function PersonalInfoStep3({ callback, data }: Props) {
  const { t } = useTranslation();

  const incomePurposeData = t('account.id-verification.kyc-3.personal-info.invest-purpose-list', {
    returnObjects: true,
  }) as string[];

  return (
    <>
      <Form initialValues={data} onFinish={callback}>
        <Grid>
          <Col>
            <Form.Field
              rules={{ required: t('common.validation.required-field') }}
              label={t('account.id-verification.kyc-3.personal-info.investment-percentage')}
              name="investAmount"
            >
              <Input
                placeholder={t('account.id-verification.kyc-3.personal-info.investment-percentage-placeholder')}
                name="investAmount"
              />
            </Form.Field>
          </Col>
          <Col>
            <Form.Field
              rules={{ required: t('common.validation.required-field') }}
              controlled
              label={t('account.id-verification.kyc-3.personal-info.purpose')}
              name="investPurpose"
            >
              {({ field }) => (
                <Block gap="lg" mt="md">
                  <Checkbox
                    dataSource={buildOptions(incomePurposeData, 'income-purpose')}
                    checked={field.value}
                    onChecked={field.onChange}
                  />
                </Block>
              )}
            </Form.Field>
          </Col>

          <Col>
            <Button variant="filled" justify="center" size="xl" mt="lg" type="submit" stretch>
              {t('common.continue')}
            </Button>
          </Col>
        </Grid>
      </Form>
    </>
  );
}

export default PersonalInfoStep3;

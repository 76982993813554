import { useEffect, useState } from 'react';

const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

/**
 * Hook for detecting the user's preference for dark mode.
 * @returns {boolean} - A boolean indicating whether the user prefers dark mode.
 */
function usePrefersDarkMode(): boolean {
  // Initialize a media query based on the user's preference for dark mode.

  const [value, setValue] = useState(mediaQuery.matches);

  useEffect(() => {
    // Listen and update with the changes on the user's preference for dark mode.
    const handler = () => setValue(mediaQuery.matches);
    mediaQuery.addEventListener('change', handler);
    return () => mediaQuery.removeEventListener('change', handler);
  }, []);

  return value;
}

export default usePrefersDarkMode;

import React from 'react';

import { Badge, Flex, Icon, LayoutV2, Link, Panel, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import HeadTags from '@/components/common/HeadTags';

import { navItems } from './constants';

const { Content, Hero, InnerContent } = LayoutV2;

function DepositWithdraw() {
  const { t } = useTranslation();

  return (
    <>
      <HeadTags title={t('navigation.mobile.deposit-withdraw')} titleSuffix="subpage" />
      <Hero>
        <Text heading size="xl" weight="bold" my="md">
          {t('navigation.mobile.deposit-withdraw')}
        </Text>
      </Hero>
      <Content>
        <InnerContent>
          <Flex direction="col" gap="xs">
            {navItems.map((item) => (
              <Link to={item.href} key={item.title}>
                <Panel color="theme-wn" px="sm" py="md" hover="background">
                  <Flex direction="row" gap="lg" justify="between" items="center">
                    <Flex direction="row" gap="sm">
                      <Badge size="6xl" variant="circle" color="neutral-300">
                        <Icon size="md" type={item.icon} color="secondary-500" />
                      </Badge>
                      <Flex>
                        <Text weight="medium" size="sm" color="secondary-500">
                          {t(item.title)}
                        </Text>
                        <Text size="xs" color="neutral-800">
                          {t(item.desc)}
                        </Text>
                      </Flex>
                    </Flex>
                    <Icon size="lg" type="s:chevron-right" color="neutral-600" />
                  </Flex>
                </Panel>
              </Link>
            ))}
          </Flex>
        </InnerContent>
      </Content>
    </>
  );
}

export default DepositWithdraw;

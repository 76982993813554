export const WS_EVENTS = {
  fiat_deposit: 'fiat:deposit',
  fiat_withdrawal: 'fiat:withdrawal',
  crypto_deposit: 'crypto:deposit',
  crypto_withdrawal: 'crypto:withdrawal',
  crypto_promo: 'crypto:promo',
  account_balance: 'account:balance',
  swap_quote: 'swap:quote',
  swap_transaction: 'swap:transaction',
  otc_transaction: 'otc:transaction',
  payback_transaction: 'payback:realized',
  // reward_prerequisites_completed: 'reward-prerequisites:completed', // will be added later after BE improvements
};

import { useEffect, useState } from 'react';

import { Accordion, Block, Button, Hidden, Modal, Switch, Text } from '@bilira-org/design';
import { LegalDocumentType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { ActiveConsentType } from '../hooks/useLegalDocuments';

type Props = {
  /** Whether the modal is open or not */
  open?: boolean;
  /** Consent data to be displayed on modal */
  data?: ActiveConsentType;
  /** Callback function to handle acceptance */
  onAccept: (value: string[]) => void;
  /** Text for the accept button */
  acceptButtonText?: string;
  /** Whether the request is pending */
  isPending?: boolean;
};

/**
 * Modal component for displaying legal documents and managing user consents.
 */
const LegalDocumentsModal = ({ open = true, data, onAccept, acceptButtonText, isPending }: Props) => {
  const { i18n, t } = useTranslation();
  const language = i18n.language as LanguageType;
  const [values, setValues] = useState<Map<string, boolean>>(new Map());

  // whether user clicked accept or reject action
  const [pendingAction, setPendingAction] = useState<'accept' | 'reject'>('accept');
  const isAcceptActionPending = pendingAction === 'accept';
  acceptButtonText = acceptButtonText || t('common.accept');

  useEffect(() => {
    if (data?.defaultConsentValues) {
      setValues(data.defaultConsentValues);
    }
  }, [data]);

  const handleSave = () => {
    const acceptedValues = Array.from(values.entries()).reduce(
      (prev: string[], [key, value]) => (value ? [...prev, key] : prev),
      [],
    );
    if (!acceptedValues.find((value) => value === 'doc')) {
      acceptedValues.push('doc');
    }
    setPendingAction('accept');
    onAccept(acceptedValues);
  };

  const handleReject = () => {
    setPendingAction('reject');
    onAccept([]);
  };

  if (!data || !data.contents) {
    return <></>;
  }

  const filterOptionsByLanguage = (options: LegalDocumentType['option_contents'], language: string) => {
    return Object.entries(options).reduce(
      (prev, [key, options]) => {
        const option = options.find((option) => option.lang === language);

        if (option) {
          return [
            ...prev,
            {
              key: key,
              title: option.title,
              content: option.content,
            },
          ];
        }

        return prev;
      },
      [] as { key: string; title: string; content: string }[],
    );
  };

  return (
    <Modal title={data.contents[language]?.title} open={open} size="xl" closable={false} compound limitMaxHeight>
      <Modal.Body padding={{ xs: 'md', sm: 'md', md: '2xl' }} key={`body-${data.document_type}`}>
        <Text
          colorizeHtml
          dangerouslySetInnerHTML={{
            __html: data.contents[language]?.content,
          }}
        />
        {data.option_contents && (
          <Accordion allowMultiple mt="4xl" key={data.document_type}>
            {filterOptionsByLanguage(data.option_contents, language).map((option) => (
              <Accordion.Item
                key={option.key}
                accordionKey={option.key}
                label={
                  data?.options?.[option.key] ? (
                    <Block>
                      <Text color="secondary-500" size="sm" weight="medium">
                        {option.title}
                      </Text>
                      <Text align="left" size="sm">
                        {t('common.always-active')}
                      </Text>
                    </Block>
                  ) : (
                    <Block row justify="between" width="size-full">
                      <Text color="secondary-500" size="sm" weight="medium">
                        {option.title}
                      </Text>
                      <Hidden show={option.key !== 'doc'}>
                        <Block items="center" justify="center">
                          <Switch
                            checked={values.get(option.key) || false}
                            onSwitch={(value) => setValues((values) => values.set(option.key, value))}
                            disabled={isPending}
                          />
                        </Block>
                      </Hidden>
                    </Block>
                  )
                }
              >
                <Text size="sm">{option.content}</Text>
              </Accordion.Item>
            ))}
          </Accordion>
        )}
      </Modal.Body>
      <Modal.Footer padding="none" py={{ xs: 'xl', sm: 'xl', md: '2xl' }} px="2xl">
        <Block row width="size-full" justify="end" gap="md">
          {data.options?.doc === false && (
            <Button
              variant="outline"
              size="md"
              onClick={handleReject}
              loading={isPending && !isAcceptActionPending}
              disabled={isPending && isAcceptActionPending}
            >
              {t('common.reject')}
            </Button>
          )}
          <Button
            variant="filled"
            size="md"
            onClick={handleSave}
            testId="accept-legal-doc-btn"
            loading={isPending && isAcceptActionPending}
            disabled={isPending && !isAcceptActionPending}
          >
            {acceptButtonText}
          </Button>
        </Block>
      </Modal.Footer>
    </Modal>
  );
};

export default LegalDocumentsModal;

import React, { memo, ReactElement } from 'react';

import { Block, Empty, List, Meta, Skeleton } from '@bilira-org/design';
import type { TransactionsRecordType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import OTCTag from '@/modules/otc/components/OTCTag';
import TransactionAmount from '@Components/transaction/components/TransactionAmount';
import TransactionIcon from '@Components/transaction/components/TransactionIcon';
import TransactionStatus from '@Components/transaction/components/TransactionStatus';
import TransactionSubAmount from '@Components/transaction/components/TransactionSubAmount';
import TransactionTitle from '@Components/transaction/components/TransactionTitle';
import { LIMIT } from '@Libs/config';

export type TransactionItemSizeType = 'sm' | 'base';

type TransactionTableProps = {
  /** Array of transaction records */
  data: TransactionsRecordType[];
  /** Function to be called when a transaction is clicked */
  callback?: (item: TransactionsRecordType) => void;
  /** Whether to show sub amounts */
  showSubAmount?: boolean;
  /** Whether the data is loading */
  loading: boolean;
  /** Number of transactions to display per page */
  limit?: number;
  /** Component to render when the transaction data is empty */
  empty?: ReactElement;
  /** Number of skeleton lines to display when loading */
  skeletonLine?: number;
  /** Whether to hide OTC tag */
  hideOTCTag?: boolean;
  /** Size of the transaction item */
  size?: TransactionItemSizeType;
};

/**
 * Displays a list of transaction records in a table
 */
const TransactionTable = ({
  data,
  callback,
  showSubAmount,
  loading,
  limit,
  empty,
  skeletonLine,
  hideOTCTag,
  size = 'base',
}: TransactionTableProps) => {
  const { t } = useTranslation();

  return (
    <Block gap="2xl">
      <List
        testId="transaction-list"
        loading={loading}
        skeletonLine={skeletonLine ?? limit ?? LIMIT}
        skeleton={
          <Block row justify="between" items="center" p={{ xs: 'sm', sm: 'md' }}>
            <Meta
              space={{ xs: 'sm', sm: 'lg' }}
              extra={<Skeleton rounded="full" height="size-9" width="size-9" />}
              title={<Skeleton height="size-6" width="size-20" />}
              description={<Skeleton height="size-5" width="size-16" />}
            />

            <Skeleton height="size-6" width="size-14" />
          </Block>
        }
        empty={empty ?? <Empty mt="4xl" message={t('common.empty-history-data-message')} />}
        dataSource={data}
        renderItem={(record) => (
          <List.Item
            key={record.id}
            size={{ xs: 'sm', sm: 'md' }}
            hoverBgColor="neutral-300"
            onClick={() => callback?.(record)}
            extra={
              <Block items="end">
                <TransactionAmount data={record} size={size} />
                <TransactionSubAmount showSubAmount={showSubAmount} data={record} size={size} />
              </Block>
            }
          >
            <Block row gap="lg" items="center">
              <Meta
                space={{ xs: 'sm', sm: 'lg' }}
                extra={<TransactionIcon data={record} />}
                title={<TransactionTitle data={record} size={size} />}
                description={<TransactionStatus data={record} size={size} />}
              />
              {!hideOTCTag && record.type === 'otc' && <OTCTag />}
            </Block>
          </List.Item>
        )}
      />
    </Block>
  );
};

export default memo(TransactionTable);

import BigNumber from 'bignumber.js';

/**
 *  Returns |                                                               |
 * :-------:|:--------------------------------------------------------------|
 *     1    | If the value of this BigNumber is greater than the value of `n`
 *    -1    | If the value of this BigNumber is less than the value of `n`
 *     0    | If this BigNumber and `n` have the same value
 *  `null`  | If the value of either this BigNumber or `n` is `NaN`
 *
 * ```ts
 *
 * x = Infinity
 * y = 5
 * compareCurrencies(y,x)                 // 1
 * ```
 * @param num1 A numeric value.
 * @param num2 A numeric value.
 */
export default function compareCurrencies(num1?: string | number, num2?: string | number): number | null {
  if (num1 === undefined || num1 === null || num2 === undefined || num2 === null) {
    return null;
  }

  const bigNum1 = new BigNumber(num1);
  const bigNum2 = new BigNumber(num2);

  return bigNum1.comparedTo(bigNum2);
}

import React, { memo } from 'react';

import { Button, Flex, Meta, Panel, Skeleton, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { IconCustom } from '@Components/icon/CustomIcon';
import idpQuery from '@Libs/clientInstances/idpQuery';

const KYCLevelInfoCard = memo(() => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, isLoading } = idpQuery.useGetUserKyc();

  if (isLoading) {
    return <KYCLevelInfoCardSkeleton />;
  }

  const canUserIncreaseKycLevel = data && ((data.kyc_level === 2 && data.kyc3_eligible) || data.kyc_level < 2);

  return (
    <Panel size="2xl" border="neutral-400">
      <Flex direction="col" gap={{ xs: 'lg' }} items="start">
        <Meta
          items="start"
          direction={{ xs: 'col', sm: 'row' }}
          position={{ xs: 'left', sm: 'right' }}
          justify="between"
          minWidth="full"
          gap="sm"
          extra={<IconCustom type="kyc" />}
          title={
            <Text heading size="xl" weight="bold">
              {t('account.cards.id-verification.title')}
            </Text>
          }
          description={
            <Text size="sm" weight="regular">
              {t(
                canUserIncreaseKycLevel
                  ? 'account.cards.id-verification.level-increase-info'
                  : 'account.cards.id-verification.level-info',
                { level: data?.kyc_level },
              )}
            </Text>
          }
        />

        {canUserIncreaseKycLevel && (
          <Button size="md" variant="filled" onClick={() => navigate('/account/id-verification')}>
            {t('account.cards.id-verification.upgrade-to-level', { level: data.kyc_level + 1 })}
          </Button>
        )}
      </Flex>
    </Panel>
  );
});

export default KYCLevelInfoCard;

const KYCLevelInfoCardSkeleton = () => {
  return (
    <Panel size="2xl" border="neutral-400">
      <Meta
        items="start"
        direction={{ xs: 'col', sm: 'row' }}
        position={{ xs: 'left', sm: 'right' }}
        gap="sm"
        extra={<IconCustom type="kyc" />}
        title={<Skeleton height="size-7" width="size-48" />}
        description={<Skeleton height="size-5" width="size-32" />}
      />

      <Skeleton rounded="xl" width="size-32" height="size-11" />
    </Panel>
  );
};

import React, { useState } from 'react';

import { Badge, Block, Button, Display, Icon, Separator, Text } from '@bilira-org/design';
import {
  AssetPairPriceType,
  calculateOTCTotalExtractingCommission,
  OTCTransactionType,
  OTCType,
} from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import FormattedNumber from '@/components/common/FormattedNumber';
import FormattedNumberByPrice from '@/components/common/FormattedNumberByPrice';
import FormattedPercentage from '@/components/common/FormattedPercentage';
import AssetUnitPriceInfo from '@Components/trade/components/AssetUnitPriceInfo';

type Props = {
  /** OTC transaction entry */
  entry: OTCType | OTCTransactionType;

  /** Custom style of the panel */
  style?: React.CSSProperties;
};

/**
 * Component that displays OTC transaction commission and total amount details.
 */
const OTCCommissionPanel = ({ entry, style }: Props) => {
  const { t } = useTranslation();

  return (
    <Badge
      size="md"
      variant="square"
      color="neutral-300"
      transparent
      width="size-full"
      style={style}
      bordered="customLeftBorder"
    >
      <Block gap="md" width="size-full" py="md" px="lg">
        <Block row justify="between" width="size-full" gap="md">
          <Text size="xs" color="neutral-700">
            {t('otc.price-with-commission')}
          </Text>
          <AssetUnitPriceInfo
            disableTooltip
            color="neutral-900"
            iconColor="neutral-900"
            weight="medium"
            amount={entry.from_amount}
            assetPairPrice={
              {
                price: entry.to_amount,
                quote_asset: entry.to_asset,
                base_asset: entry.from_asset,
              } as AssetPairPriceType
            }
            equalitySymbol="="
            size="xs"
          />
        </Block>
        <Separator width="size-full" color="neutral-400" />
        <Block row justify="between" width="size-full" gap="md">
          <Text size="xs" color="neutral-700">
            {t('otc.without-commission')}
          </Text>
          <FormattedNumberByPrice
            value={calculateOTCTotalExtractingCommission(entry)}
            suffix={entry.commission_asset || entry.to_asset}
            size="xs"
            color="neutral-900"
            type="price"
            price={entry.pair_price}
          />
        </Block>

        <Block row justify="between" width="size-full" gap="md">
          <Text size="xs" color="neutral-700">
            {t('market.swap.commission')} (
            <FormattedPercentage value={parseFloat(entry.commission_rate || '0') * 100} />)
          </Text>
          <FormattedNumberByPrice
            value={entry.commission_amount || '0'}
            suffix={entry.commission_asset || entry.to_asset}
            size="xs"
            color="neutral-900"
            type="price"
            price={entry.pair_price}
          />
        </Block>

        <Block row justify="between" width="size-full" gap="md">
          <Text size="xs" color="neutral-700">
            {t('otc.total-commission-included')}
          </Text>
          <FormattedNumberByPrice
            value={entry.to_amount}
            suffix={entry.to_asset}
            size="sm"
            weight="medium"
            color="neutral-900"
            type="amount"
            price={entry.pair_price}
          />
        </Block>
      </Block>
    </Badge>
  );
};

/**
 *  Provides a hidable view for displaying OTC commission details
 */
const HidableOTCCommissionPanel = ({ entry, style }: Props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  return (
    <Block style={style} gap="lg" width="size-full">
      <Display show={show} motion>
        <OTCCommissionPanel entry={entry} style={style} />
      </Display>

      <Block row items="center">
        <Separator width="size-4" />
        <Button variant="transparent" size="sm" onClick={() => setShow(!show)}>
          <Block row gap="xs" items="center">
            <Text color="neutral-700" size="xs">
              {show ? t('common.hide-details') : t('common.show-details')}
            </Text>
            <Icon color="neutral-700" type={show ? 's:chevron-down-up' : 's:chevron-up-down'} size="sm" />
          </Block>
        </Button>
        <Separator width="size-full" />
      </Block>
    </Block>
  );
};

export default OTCCommissionPanel;
export { HidableOTCCommissionPanel };

import { Block, Flex, Meta, Panel, Skeleton, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import UserQuery from '@Libs/clientInstances/userQuery';
import { withPrivateRoute } from '@Libs/hocs';
import CreateReferralButton from '@Modules/settings/commission/referral/createReferral/CreateReferralButton';

import ReferralAccountInfo from './components/ReferralAccountInfo';
import ReferralCampaign from './components/ReferralCampaign';
import ReferralItem from './components/ReferralItem';
import { calculatePercentage, pinDefaultReferralCode } from './helpers';

const Index = () => {
  const { t } = useTranslation();

  const { data, refetch, isLoading } = UserQuery.useGetReferralCodes();
  const defaultReferralCode = data?.referral_codes?.find((code) => code.default);
  const sortedReferralCodes = pinDefaultReferralCode(data?.referral_codes);

  if (isLoading) {
    return <IndexSkeleton />;
  }

  if (!data) {
    return (
      <Block gap="4xl">
        <ReferralAccountInfo />

        <ReferralCampaign />

        <Panel color="neutral-200" size="xl">
          <Flex
            direction={{ xs: 'col', sm: 'col', md: 'row' }}
            width="full"
            justify={{ md: 'between' }}
            items={{ xs: 'stretch', sm: 'stretch', md: 'start' }}
            gap="xl"
            mb="md"
          >
            <Meta
              title={
                <Text heading size="xl" weight="bold" mb="lg">
                  {t('referral.referral-links-list')} (0/0)
                </Text>
              }
              description={<Text>{t('referral.create-and-share-referral-codes')}</Text>}
            />
            <CreateReferralButton refetch={refetch} remainingCodeCount={0} />
          </Flex>
        </Panel>
      </Block>
    );
  }

  return (
    <Block gap="4xl">
      <ReferralAccountInfo
        defaultCommissionPercentage={calculatePercentage(data.default_comission)}
        totalEarning={data.total_earning.toString(10)}
        totalReferralCount={data.total_referral_count || 0}
      />

      <ReferralCampaign
        defaultCommission={data.default_comission}
        referralCodes={data.referral_codes}
        defaultReferralCode={defaultReferralCode}
      />

      <Panel color="theme-n200d800" size="xl">
        <Flex
          direction={{ xs: 'col', sm: 'col', md: 'row' }}
          width="full"
          justify={{ md: 'between' }}
          items={{ xs: 'stretch', sm: 'stretch', md: 'start' }}
          gap="xl"
          mb="md"
        >
          <Meta
            title={
              <Text heading size="xl" weight="bold" mb="lg">
                {t('referral.referral-links-list')} ({data.code_count}/{data.remaining_code_count + data.code_count})
              </Text>
            }
            description={<Text>{t('referral.create-and-share-referral-codes')}</Text>}
          />
          <CreateReferralButton refetch={refetch} remainingCodeCount={data.remaining_code_count} />
        </Flex>

        <Block gap="2xl">
          {sortedReferralCodes?.map((referralCode, index) => (
            <ReferralItem
              key={referralCode.code}
              referralCode={referralCode}
              index={index}
              defaultCommission={data.default_comission}
              refetch={refetch}
            />
          ))}
        </Block>
      </Panel>
    </Block>
  );
};

export default withPrivateRoute(Index);

const IndexSkeleton = () => {
  return (
    <Block gap="4xl">
      <Skeleton width="size-full" height="size-40" />
      <Skeleton width="size-full" height="size-96" />
      <Skeleton width="size-full" height="size-96" />
    </Block>
  );
};

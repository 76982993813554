import React, { useState } from 'react';

import { Block, Button, FileType, FileUploader, Flex, Webcam } from '@bilira-org/design';
import { isEmpty, useStateEffect } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

type Props = {
  callback: (source: FileType[]) => void;
  sources?: FileType[];
  isPending?: boolean;
  alert?: React.ReactNode;
  customIcon?: React.ReactNode;
  // completed: () => void;
};

const steps = {
  step1: 'step1',
  step2: 'step2',
  step3: 'step3',
  step4: 'step4',
};

function SelfieUpload({ isPending, sources, callback, alert, customIcon }: Props) {
  const { t } = useTranslation();
  const [step, setStep] = useState<string>(isEmpty(sources) ? steps.step1 : steps.step4);
  const [base64File, setBase64File] = useState<string>();
  const [files, setFiles] = useStateEffect<FileType[] | undefined>(sources);
  const isSubmitButtonDisabled = !Array.isArray(files) || FileUploader.checkFileErrors(files);

  return (
    <>
      {alert}

      {step === steps.step1 && (
        <>
          <Block row justify="center">
            {customIcon}
          </Block>

          <Flex direction={{ xs: 'col', sm: 'row' }} gap="sm" width="full" mt="2xl">
            <Flex.Item width={{ xs: 'full', sm: '2/4' }}>
              <Button
                stretch
                variant="outline"
                justify="center"
                size="xl"
                onClick={() => {
                  setStep(steps.step2);
                }}
              >
                {t('common.take-photo')}
              </Button>
            </Flex.Item>
            <Flex.Item width={{ xs: 'full', sm: '2/4' }}>
              <Button
                stretch
                variant="filled"
                justify="center"
                size="xl"
                onClick={() => {
                  setStep(steps.step4);
                }}
              >
                {t('common.upload-file')}
              </Button>
            </Flex.Item>
          </Flex>
        </>
      )}

      {step === steps.step2 && (
        <>
          <Webcam
            imageSmoothing={false}
            mirrored={false}
            onUserMediaError={(test) => console.log(test)}
            forceScreenshotSourceSize={false}
            screenshotFormat="image/jpeg"
            screenshotQuality={0.9}
            onCapture={(source) => {
              setBase64File(source as string);
              setStep(steps.step3);
            }}
          />
        </>
      )}

      {step === steps.step3 && (
        <>
          <FileUploader
            base64File={base64File}
            files={files ?? sources}
            onUpload={setFiles}
            maxFiles={1}
            allowBrowse={false}
            allowDrop={false}
            allowPaste={false}
            styleItemPanelAspectRatio={null}
            imagePreviewHeight={218}
            onremovefile={() => {
              setStep(steps.step2);
              setBase64File(undefined);
            }}
            labelIdle={t('common.upload-file-label', {
              maxFileSize: '10MB',
            })}
          />

          <Flex direction={{ xs: 'col', sm: 'row' }} gap="sm" width="full" mt="2xl">
            <Flex.Item width={{ xs: 'full', sm: '2/4' }}>
              <Button
                variant="outline"
                mt="2xl"
                stretch
                justify="center"
                size="xl"
                onClick={() => {
                  setBase64File(undefined);
                  setFiles(undefined);
                  setStep(steps.step2);
                }}
              >
                {t('common.upload-file')}
              </Button>
            </Flex.Item>
            <Flex.Item width={{ xs: 'full', sm: '2/4' }}>
              <Button
                disabled={isSubmitButtonDisabled}
                variant="filled"
                mt="2xl"
                stretch
                justify="center"
                size="xl"
                onClick={() => {
                  callback(files || []);
                  // completed();
                }}
              >
                {t('common.continue')}
              </Button>
            </Flex.Item>
          </Flex>
        </>
      )}
      {step === steps.step4 && (
        <>
          <FileUploader
            files={files ?? sources}
            onUpload={setFiles}
            maxFiles={1}
            styleItemPanelAspectRatio={null}
            imagePreviewHeight={218}
            acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']}
            labelIdle={t('common.upload-file-label', {
              maxFileSize: '10MB',
            })}
          />

          <Flex direction={{ xs: 'col', sm: 'row' }} gap="sm" width="full" mt="2xl">
            <Flex.Item width={{ xs: 'full', sm: '2/4' }}>
              <Button
                disabled={isPending}
                variant="outline"
                mt="2xl"
                stretch
                justify="center"
                size="xl"
                onClick={() => {
                  setBase64File(undefined);
                  setFiles(undefined);
                  setStep(steps.step2);
                }}
              >
                {t('common.take-photo')}
              </Button>
            </Flex.Item>
            <Flex.Item width={{ xs: 'full', sm: '2/4' }}>
              <Button
                loading={isPending}
                disabled={isSubmitButtonDisabled}
                variant="filled"
                mt="2xl"
                stretch
                justify="center"
                size="xl"
                onClick={() => {
                  callback(files || []);
                }}
              >
                {t('common.complete')}
              </Button>
            </Flex.Item>
          </Flex>
        </>
      )}
    </>
  );
}

export default SelfieUpload;

import BigNumber from 'bignumber.js';

/**
 * Checks if a given number is within a specified range (inclusive).
 *
 * @param {string} input - The number to check.
 * @param {string} lowerRange - The lower bound of the range.
 * @param {string} upperRange - The upper bound of the range.
 * @returns {boolean} - True if the number is within the specified range, false otherwise.
 * @example
 * const result = isNumberInRange('5', '3', '10');
 * console.log(result); // Output: true
 */
const isNumberInRange = (input: string, lowerRange: string, upperRange: string) => {
  const number = BigNumber(input);
  return number.isGreaterThanOrEqualTo(lowerRange) && number.isLessThanOrEqualTo(upperRange);
};

export default isNumberInRange;

import { RewardsTasksType, UserParticipationType } from '../../model';

/**
 * Determines whether should display rewards multiplier or points
 *
 * @param {UserParticipationType | RewardsTasksType} task - The task object, which can either be a user participation task or a rewards task.
 * @returns {boolean} - Returns true if multiplier should be displayed, otherwise false.
 */
export const shouldDisplayRewardMultiplier = (task: UserParticipationType | RewardsTasksType): boolean => {
  return task.action_type === 'overnight_holding' || task.action === 'transaction_volume';
};

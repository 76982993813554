import React from 'react';

import { Button, Modal } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import RewardHistoryTable from './RewardHistoryTable';

const Index = () => {
  const { t, i18n } = useTranslation();
  const [modalActive, setModalActive] = React.useState(false);

  return (
    <>
      <Button variant="outline" size="md" onClick={() => setModalActive(true)}>
        {t('rewards.history.title')}
      </Button>
      <Modal title={t('rewards.history.title')} open={modalActive} onClose={setModalActive}>
        <RewardHistoryTable />
      </Modal>
    </>
  );
};

export default Index;

import { useState } from 'react';

import { format, sanitizeInput } from '../utils/formattedNumberInput';
import { LanguageUniversalType } from '../utils/types';

interface HookProps {
  /** Value passed from props for controlled usage. */
  valueFromProps: string | null | undefined;
  /** Locale used for formatting the number. */
  locale: string;
  /** Callback function triggered when the value changes. */
  onChange?: (value: string) => void;
  /** Number of decimal places allowed in the input. */
  decimalPlaces?: number;
}

interface ReturnType {
  /** Formatted value to be used in the input. */
  formattedValue: string;
  /** Function to be called when the input value changes */
  handleChange: (newValue: string) => void;
}
/**
 * Custom hook for handling formatted number input.
 */
const useFormattedNumberInput = ({ valueFromProps, locale, onChange, decimalPlaces }: HookProps): ReturnType => {
  const [innerValueState, setInnerValueState] = useState<string>('');

  const isValidInput = valueFromProps !== undefined && valueFromProps !== null;
  const value = isValidInput ? (valueFromProps as string) : innerValueState;
  const formattedValue = format(value, locale as LanguageUniversalType, decimalPlaces);

  const handleChange = (newValue: string) => {
    const result = sanitizeInput(newValue, value, locale as LanguageUniversalType, decimalPlaces);

    if (result !== value) {
      setInnerValueState(result);
      onChange?.(result);
    }
  };

  return { formattedValue, handleChange };
};

export default useFormattedNumberInput;

import React from 'react';

import { clsx } from 'clsx';

import { Icon } from '../icons';
import { BaseComponentType, PaddingSizes } from '../types';

export type IButtonClose = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'> &
  BaseComponentType & {
    background?: 'neutral-200' | 'transparent' | 'theme-wn' | 'theme-wb' | 'white';
    p?: PaddingSizes;
  };

const ButtonClose = ({ testId, className, background = 'neutral-200', p, ...rest }: IButtonClose) => {
  return (
    <button
      {...rest}
      type="button"
      className={clsx(
        'outline-none rounded-full text-sm',
        {
          'bg-transparent hover:bg-transparent': background === 'transparent',
          [`tw-bg-${background} hover:tw-${background}`]: background !== 'transparent',
          [`p-${p}`]: p,
        },
        className,
      )}
      data-testid={testId ?? 'close-button'}
    >
      <Icon color="secondary-500" type="o:x-mark" size="lg" />
    </button>
  );
};

ButtonClose.displayName = 'ButtonClose';

// to auto generate args documentation on forwardRef component

/**
 * Customizable buttonClose component.
 *
 * @component
 *
 * @param {IButtonClose} props - The properties for the Button component.
 * @returns {React.ReactElement} The rendered Button component.
 * @example
 * // Example usage of Button component
 * <ButtonClose variant="primary" size="md" onClick={handleClick}>
 *   Click me
 * </ButtonClose>
 */
const StoryOnly = (props: IButtonClose) => (
  <>
    <ButtonClose {...props} />
  </>
);
StoryOnly.displayName = 'ButtonClose';

export { StoryOnly };

export default ButtonClose;

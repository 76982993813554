import React, { useState } from 'react';

import { ChartPriceDataType, Flex, Loop, Panel, Text } from '@bilira-org/design';
import { CryptoTimeScale } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import ChartPriceSubscription from '@Components/live/subscription/ChartPriceSubscription';
import EmptyGraph from '@Modules/market/detail/components/components/EmptyGraph';
import AssetInfo from '@Modules/market/detail/components/priceChart/AssetInfo';
import { timeScales } from '@Modules/market/detail/components/priceChart/helpers/scaleConfiguration';
import usePriceChartHook from '@Modules/market/detail/components/priceChart/hooks/usePriceChartHook';
import PriceChart from '@Modules/market/detail/components/priceChart/PriceChart';

const AssetPairChart = () => {
  const { t } = useTranslation();
  const [priceInfo, setPriceInfo] = useState<ChartPriceDataType>();
  const [timeScale, setTimeScale] = useState<CryptoTimeScale>('day');

  const { hasError, data, isPending } = usePriceChartHook({ timeScale });

  if (hasError || !data?.timestamps?.length || isPending) {
    return <EmptyGraph loading={isPending} />;
  }
  return (
    <>
      <ChartPriceSubscription timeScale={timeScale} />
      <Flex
        direction={{ xs: 'col', sm: 'col', md: 'row' }}
        gap={{ xs: 'lg', sm: 'lg' }}
        justify="between"
        px="2xl"
        pt="2xl"
      >
        <Flex.Item flexType="none">
          <AssetInfo priceData={data} priceInfo={priceInfo} />
        </Flex.Item>
        <Flex.Item flex direction="row" gap="lg" justify={{ xs: 'around', sm: 'around', md: 'end' }}>
          <Loop data={timeScales}>
            {(item) => (
              <Text
                key={`time-scale-${item}`}
                style={{ cursor: 'pointer' }}
                size="xs"
                weight="medium"
                color={timeScale === item ? 'primary-500' : undefined}
                onClick={() => setTimeScale(item)}
              >
                {t(`common.time-scale.${item}`)}
              </Text>
            )}
          </Loop>
        </Flex.Item>
      </Flex>
      <Panel size="none" my="xs">
        <PriceChart priceData={data} setPriceInfo={setPriceInfo} timeScale={timeScale} />
      </Panel>
    </>
  );
};

export default AssetPairChart;

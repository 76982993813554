import React from 'react';

import { Alert, Button, Icon, StepProgress, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

type Props = {
  callback: () => void;
};

function Start({ callback }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Alert status="yellow" mb="2xl">
        {t('account.id-verification.document-warning', { level: 3 })}
      </Alert>

      <StepProgress
        color="neutral-300"
        vertical
        size="xl"
        alignItems="center"
        steps={[
          {
            title: (
              <Text weight="medium" color="secondary-500">
                {t('account.id-verification.residence-permit-or-domicile-certificate')}
              </Text>
            ),
            icon: <Icon type="o:map-pin" color="secondary-500" />,
          },
          {
            title: (
              <Text weight="medium" color="secondary-500">
                {t('account.id-verification.personal-info')}
              </Text>
            ),
            icon: <Icon type="o:identification" color="secondary-500" />,
          },
          {
            title: (
              <Text weight="medium" color="secondary-500">
                {t('account.id-verification.kyc-2.selfie')}
              </Text>
            ),
            icon: <Icon type="o:user-circle" color="secondary-500" />,
          },
        ]}
      />
      <Button mt="8xl" justify="center" stretch size="xl" variant="filled" onClick={callback}>
        {t('common.start')}
      </Button>
    </>
  );
}

export default Start;

import React from 'react';

import { Block, Card, Spinner, Text } from '@bilira-org/design';

type Props = {
  /** Title to be displayed in card */
  title: string;
  /** Description to be displayed in card */
  description: string;
};

/**
 * RedirectSpinCard component displays a card with a spinner, title, and description.
 *
 * @example
 * <RedirectSpinCard title="Redirecting..." description="Please wait while we redirect you." />
 */
const RedirectSpinCard = ({ title, description }: Props) => {
  return (
    <Block height="size-full" width="size-full" justify="center" items="center" p="lg">
      <Card color="theme-wn" shadow style={{ maxWidth: '30rem' }} size="xl" mx="auto">
        <Block justify="center" items="center" align="center" py="8xl">
          <Spinner size="xl" color="primary" />
          <Text heading size="xl" mt="xl">
            {title}
          </Text>
          <Text size="sm" mb="8xl" mt="md">
            {description}
          </Text>
        </Block>
      </Card>
    </Block>
  );
};

export default RedirectSpinCard;

import React, { useMemo } from 'react';

import { Block, ColumnDef, DataTable, Skeleton, TableBodyStyle, Text } from '@bilira-org/design';
import { AssetListType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import SymbolPair from '@Components/common/SymbolPair';
import H24ChangeLive from '@Components/live/H24ChangeLive';
import LastPriceLive from '@Components/live/LastPriceLive';
import MarketPriceSubscription from '@Components/live/subscription/GeneralPriceSubscription';
import useAssetCardsHook from '@Modules/market/market/table/components/hooks/useAssetCardsHook';

const bodyStyle: TableBodyStyle = { px: 'md', py: 'sm' };

export type CryptoListProps = {
  tag?: string;
  rowNumber?: boolean;
};

const CryptoList = ({ tag, rowNumber }: CryptoListProps) => {
  const { t } = useTranslation();
  const { assetList, isPending, callback, subscriptionList } = useAssetCardsHook({ dataTag: tag });

  const data = useMemo(() => assetList?.slice(0, 3), [assetList]);

  const columns = useMemo<ColumnDef<AssetListType>[]>(
    () => [
      {
        header: '#',
        id: 'row-number',
        cell({ row }) {
          return (
            <Text weight="regular" size="xs" color="neutral-600" as="span" pl="md">
              {row.index + 1}
            </Text>
          );
        },
        show: !!rowNumber,
        size: 10,
        style: () => ({ px: 'none' }),
        skeleton: <Skeleton rounded="full" height="size-4" width="size-4" />,
      },
      {
        header: '',
        accessorKey: 'base_asset',
        id: 'pair',
        cell({ row: { original: row } }) {
          return (
            <SymbolPair
              quote="TRYB"
              icon={row.base_asset}
              title={row.base_asset}
              description={row.base_asset_name}
              size={{ title: 'xs', description: 'xs', icon: '2xl' }}
            />
          );
        },
        skeleton: (
          <Block key="crypto" row gap="sm" items="center">
            <Skeleton rounded="full" height="size-8" width="size-8" />
            <Skeleton height="size-4" width="size-24" />
          </Block>
        ),
      },
      {
        header: '',
        accessorKey: 'base_asset',
        id: 'statistics',
        align: 'right',
        cell({ row: { original: item } }) {
          return (
            <>
              <LastPriceLive size="sm" suffixSize="sm" align="right" base={item.base_asset} quote="TRYB" />
              <H24ChangeLive base={item.base_asset} value={item.change_24} justify="end" size="sm" quote="TRYB" />
            </>
          );
        },
        skeleton: (
          <Block key="crypto" row gap="sm" items="center">
            <Skeleton height="size-4" width="size-24" />
            <Skeleton height="size-4" width="size-24" />
          </Block>
        ),
      },
    ],
    [rowNumber],
  );

  return (
    <>
      <MarketPriceSubscription
        channel="crypto-cards"
        baseQuoteList={subscriptionList}
        events="last_price,symbol_statistics"
      />
      <DataTable
        rowId="base_asset"
        loading={isPending}
        displayHeader={false}
        rounded
        bodyStyle={bodyStyle}
        data={data}
        onRowClick={callback}
        columns={columns}
        emptyMessage={t('common.no-entries-found')}
      />
    </>
  );
};

export default CryptoList;

import React, { memo } from 'react';

import { Block, Button, Input, Label, Modal, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import SwapQuery from '@Libs/clientInstances/swapQuery';
import useUserInfo from '@Libs/hooks/useUserInfo';
import { handleErrorResponse } from '@Libs/utils';

type Props = {
  active: boolean;
  setActive: (active: boolean) => void;
  data: string;
  callback: () => void;
};

const OTCTwoFAModal = ({ active, setActive, data, callback }: Props) => {
  const { t } = useTranslation();
  const [input, setInput] = React.useState<string>('');

  const { maskedEmail } = useUserInfo();
  const { mutateAsync, isPending } = SwapQuery.usePatchOTCTransaction();

  const onConfirm = () => {
    mutateAsync({ id: data, body: { action: 'accept', verification_code: input } })
      .then(callback)
      .catch(handleErrorResponse)
      .finally(() => setInput(''));
  };

  return (
    <Modal
      title={t('two-fa.security-verification')}
      open={active}
      overlayClosable={false}
      onClose={!isPending ? () => setActive(false) : undefined}
    >
      <Text mb="3xl">{t('two-fa.complete-verifications-for-security')}</Text>
      <Block>
        <Label>{t('two-fa.email-verification-code')}</Label>
        <Input.Number
          placeholder={t('two-fa.otc-placeholder')}
          disableZeroCheck
          onChange={setInput}
          onEnter={onConfirm}
        />

        <Text color="neutral-700" size="sm" mt="sm">
          {t('two-fa.type-code-sent-to-email', { email: maskedEmail })}
        </Text>
      </Block>

      <Button mt="4xl" loading={isPending} size="md" stretch justify="center" variant="filled" onClick={onConfirm}>
        {t('common.accept')}
      </Button>
    </Modal>
  );
};
export default memo(OTCTwoFAModal);

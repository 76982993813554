import React from 'react';

import { Flex, Icon, Meta, Skeleton, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import kycQuery from '@Libs/clientInstances/idpQuery';

function ChangeEmail() {
  const { t } = useTranslation();
  const { data } = kycQuery.useGetUserKyc();

  return (
    <Flex py="4xl" direction={{ xs: 'col', sm: 'row' }} justify="between" gap="xl" items={{ sm: 'center' }}>
      <Meta
        extra={<Icon size="lg" type="o:envelope" color="primary-500" />}
        position="left-top"
        title={t('account.security-settings.change-email')}
        description={t('account.security-settings.contact-us-to-change-email')}
      />
      {data?.email ? (
        <Text weight="semibold" color="secondary-500">
          {data?.email}
        </Text>
      ) : (
        <Skeleton width="size-16" />
      )}
    </Flex>
  );
}

export default ChangeEmail;

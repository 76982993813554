import React, { ReactNode } from 'react';

import { Flex } from '@bilira-org/design';

type LayoutProps = { children: ReactNode };

const Layout = ({ children }: LayoutProps) => {
  return (
    <Flex gap="4xl" direction={{ lg: 'row' }}>
      {children}
    </Flex>
  );
};

const LayoutContent = ({ children }: LayoutProps) => {
  return <Flex.Item flexType="1">{children}</Flex.Item>;
};

const LayoutSidebar = ({ children }: LayoutProps) => {
  return (
    <Flex.Item flexType={{ lg: 'none' }} width={{ lg: '368' }}>
      {children}
    </Flex.Item>
  );
};

export default Object.assign(Layout, { Sidebar: LayoutSidebar, Content: LayoutContent });

import React, { memo, useEffect } from 'react';

import { Block, Button, Flex, Number, Separator, Text } from '@bilira-org/design';
import { AssetPairPriceType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { IconCustom } from '@Components/icon/CustomIcon';
import UnitPriceItem from '@Components/trade/buySell/components/UnitPriceItem';
import useSwapStateHook from '@Components/trade/buySell/hooks/useSwapStateHook';
import { SWAP_COMPLETED_HASH } from '@Libs/constants';

type Props = {
  onClose: () => void;
};

/**
 * Component to render the completion message for the swap.
 *
 * Additionally, closes swap modal after a timeout
 */
function OfferCompleted({ onClose }: Props) {
  const { offerAmount, isOfferFetching, toAsset, offerPrice, fromAsset, buySellType } = useSwapStateHook();

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { isOfferCompleted } = useSwapStateHook();

  useEffect(() => {
    let closeTimeoutId: NodeJS.Timeout | undefined;

    if (isOfferCompleted) {
      navigate(SWAP_COMPLETED_HASH);
      closeTimeoutId = setTimeout(onClose, 5000);
    }
    return () => {
      if (closeTimeoutId !== undefined) {
        clearTimeout(closeTimeoutId);
      }
    };
  }, [isOfferCompleted]);

  return (
    <>
      <Block justify="between" height="size-full">
        <Flex direction="col">
          <Flex.Item flex items="center" my="2xl">
            <IconCustom type="completed-big-filled" />
          </Flex.Item>
          <Flex.Item>
            <Text heading size="lg" align="center" color="neutral-900" weight="bold" mb="md">
              {t('trade.offer.completed.title')}
            </Text>
            <Text size="sm" align="center" mb="5xl">
              {t(`trade.offer.completed.description.${buySellType}`)}
            </Text>
          </Flex.Item>
          <Flex.Item>
            <Separator width="size-full" my="md" />
          </Flex.Item>

          <Flex.Item flex direction="row" justify="between" mb="md">
            <Text size="sm" weight="regular" color="neutral-700">
              {t('trade.offer.labels.received')}
            </Text>
            <Number
              size="sm"
              color="primary-500"
              weight="medium"
              suffix={toAsset}
              value={offerAmount}
              formatPriceProps={{ decimal: 2, locale: i18n.language }}
            />
          </Flex.Item>
          <Flex.Item>
            <UnitPriceItem
              loading={isOfferFetching}
              equalitySymbol="="
              assetPairPrice={
                {
                  price: offerPrice,
                  quote_asset: fromAsset,
                  base_asset: toAsset,
                } as AssetPairPriceType
              }
            />
          </Flex.Item>
        </Flex>
        <Button size="lg" variant="outline" onClick={onClose}>
          {t('common.close')}
        </Button>
      </Block>
    </>
  );
}

export default memo(OfferCompleted);

import React, { useEffect } from 'react';

import { Block, LayoutV2 } from '@bilira-org/design';

import HeadTags from '@/components/common/HeadTags';
import useTourControl from '@/libs/hooks/useTourControl';

import HypeCards from './components/HypeCards';
import PopularCryptoCards from './components/PopularCryptoCards';
import PrivateWelcomePanel from './components/PrivateWelcomePanel';
import PublicWelcomePanel from './components/PublicWelcomePanel';
import Sidebar from './components/Sidebar';

const { Content, Hero, Aside, InnerContent } = LayoutV2;

const Dashboard = () => {
  const { start } = useTourControl();

  useEffect(() => {
    start('onboarding');
  }, [start]);

  return (
    <>
      <HeadTags title="BiLira" titleSuffix="homepage" />
      <Content>
        <InnerContent>
          <Hero>
            <PrivateWelcomePanel />
            <PublicWelcomePanel />
          </Hero>
          <Block gap="4xl" id="crypto-list-cards">
            <HypeCards />
            <PopularCryptoCards />
          </Block>
        </InnerContent>
        <Aside>
          <Sidebar />
        </Aside>
      </Content>
    </>
  );
};

export default Dashboard;

import React from 'react';

import { Block, Meta, Panel, Text, TextCopy } from '@bilira-org/design';
import { CryptoWithdrawalType, NetworkAliasType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import IconCoin from '@Components/icon/IconCoin';
import { truncateMiddle } from '@Libs/utils';

export type Props = {
  data: CryptoWithdrawalType;
};

export const CryptoTransferType = ({ data }: Props) => {
  const { t } = useTranslation();
  return (
    <Meta
      title={
        <Text size="sm" weight="medium" color="neutral-800">
          {data.direction === 'deposit' ? t('crypto.sender-wallet-address') : t('crypto.recipient-wallet-address')}
        </Text>
      }
      description={
        <Panel color="neutral-200" size="lg">
          <Block row items="center" justify="between">
            <Meta
              space="sm"
              extra={<IconCoin type={data.network?.alias as NetworkAliasType} size="3xl" />}
              title={
                data.direction === 'withdrawal' && (
                  <Text weight="semibold" color="secondary-500" wordBreak="all">
                    {data.network?.name}
                  </Text>
                )
              }
              description={
                <TextCopy
                  size="sm"
                  weight={data.direction === 'deposit' ? 'semibold' : 'regular'}
                  color={data.direction === 'deposit' ? 'secondary-500' : 'neutral-800'}
                  copiedText={t('common.copied')}
                  value={data.user_wallet}
                  wordBreak="all"
                >
                  {truncateMiddle(data.user_wallet, 9, 2)}
                </TextCopy>
              }
            />
            <Text size="sm" weight="medium" color="neutral-600">
              {data.network?.alias}
            </Text>
          </Block>
        </Panel>
      }
    />
  );
};

import React from 'react';

import { Block, Icon, Link } from '@bilira-org/design';

type Props = {
  pair: string;
};

const TransactionPair = ({ pair }: Props) => {
  return (
    <Link to={`/market/${pair.replace('/', '_')}`} underlineOnHover size="sm" color="neutral-900">
      <Block row gap="xs">
        {pair.replace('_', '/')}
        <Icon type="o:arrow-up-right" size="xs" my="auto" color="neutral-700" />
      </Block>
    </Link>
  );
};

export default TransactionPair;

import { useCallback, useState } from 'react';

import { DataTableProps } from '@bilira-org/design';
import { AssetListType } from '@bilira-org/react-utils';

import { localStorageConstants, StorageValues, useLocalStorage } from '@/store/localStorage';

export const useMarketTableControls = () => {
  const [dataTag, setDataTag] = useState<string>();
  const { localStore, setLocalStore } = useLocalStorage();

  const onSortingChange = useCallback<Exclude<DataTableProps<AssetListType>['onSortingChange'], undefined | string>>(
    (updater) => {
      const newSortingState =
        updater instanceof Function ? updater(localStore.MARKET_TABLE_SORT.original || []) : updater;
      const newValue: StorageValues['MARKET_TABLE_SORT'] = {
        field: undefined,
        direction: undefined,
        original: newSortingState,
      };
      if (newSortingState.length > 0) {
        newValue.field = newSortingState[0].id as Exclude<StorageValues['MARKET_TABLE_SORT'], undefined>['field'];
        newValue.direction = newSortingState[0].desc ? 'desc' : 'asc';
        setLocalStore({ MARKET_TABLE_SORT: newValue });
      } else {
        if (localStore.MARKET_TABLE_SORT.field === 'Volume_24') {
          setLocalStore({
            MARKET_TABLE_SORT: {
              field: 'Volume_24',
              direction: 'asc',
              original: [{ id: 'Volume_24', desc: false }],
            },
          });
        } else {
          setLocalStore({ MARKET_TABLE_SORT: localStorageConstants.INITIAL_MARKET_TABLE_SORT });
        }
      }
    },
    [localStore.MARKET_TABLE_SORT, setLocalStore],
  );

  const setTab = useCallback((index: number) => {
    setLocalStore({ MARKET_TAB: index });
  }, []);

  return {
    dataTag,
    setDataTag,
    sorting: localStore.MARKET_TABLE_SORT,
    setSorting: onSortingChange,
    tab: localStore.MARKET_TAB,
    setTab,
  };
};

import { SocketClient } from '@bilira-org/react-utils';

import { env } from '@Libs/constants/env';
import { WS_EVENTS } from '@Libs/ws/constants';
import wsManager from '@Libs/ws/priceWebSocket';

import {
  handleAccountBalance,
  handleCryptoDeposit,
  handleCryptoWithdrawal,
  handleFiatDeposit,
  handleFiatWithdrawal,
  handleOTCTransaction,
  handlePaybackTransaction,
  handlePromo,
  handleSwapQuote,
  handleSwapTransaction,
} from './handleTransactions';

const socketClient = new SocketClient(`${env.STABLECOIN_WS_BASE_URL}`, {
  withCredentials: true,
  path: env.BILIRA_ENV === 'staging' ? '/stablecoin/socket.io' : '',
});

socketClient.registerConnect();
socketClient.registerEvents({ onSuccess: handleFiatDeposit, event: WS_EVENTS.fiat_deposit });
socketClient.registerEvents({ onSuccess: handleFiatWithdrawal, event: WS_EVENTS.fiat_withdrawal });
socketClient.registerEvents({ onSuccess: handleCryptoDeposit, event: WS_EVENTS.crypto_deposit });
socketClient.registerEvents({ onSuccess: handleCryptoWithdrawal, event: WS_EVENTS.crypto_withdrawal });
socketClient.registerEvents({ onSuccess: handlePromo, event: WS_EVENTS.crypto_promo });
socketClient.registerEvents({ onSuccess: handleAccountBalance, event: WS_EVENTS.account_balance });
socketClient.registerEvents({ onSuccess: handleSwapQuote, event: WS_EVENTS.swap_quote });
socketClient.registerEvents({ onSuccess: handleSwapTransaction, event: WS_EVENTS.swap_transaction });
socketClient.registerEvents({ onSuccess: handleOTCTransaction, event: WS_EVENTS.otc_transaction });
socketClient.registerEvents({ onSuccess: handlePaybackTransaction, event: WS_EVENTS.payback_transaction });
// socketClient.registerEvents({
//   onSuccess: handleRewardPrerequisitesCompleted,
//   event: WS_EVENTS.reward_prerequisites_completed,
// }); // will be added later after BE improvements
socketClient.registerError({ onFail });

function onFail(err: any) {
  console.debug('🤨', err.message);
}

window.addEventListener('offline', (event) => {
  console.debug('🔴 Offline');
  wsManager.close();
  socketClient.close();
});

addEventListener('online', (event) => {
  console.debug('🟢 Online');
  wsManager.reconnect();
  socketClient.retryConnect();
});

export { socketClient };

import { Block } from '../../block';

type Props = {
  /** Whether the skeleton animation should be enabled or not */
  enabled?: boolean;
};

/**
 * Skeleton for the LightweightChart component
 */
const LineChartSkeleton = ({ enabled = true }: Props) => {
  return (
    <Block justify="center" className="overflow-hidden h-60">
      <svg className="wave-parent mt-4xl tw-text-neutral-400" viewBox="0 0 762 55" xmlns="http://www.w3.org/2000/svg">
        <path
          className={enabled ? 'wave' : ''}
          strokeWidth="2"
          stroke="currentColor"
          fill="transparent"
          d="m0,6c10.58333,0.01038 21.16667,0.02076 31.75,0.06227c10.58333,0.04152 21.16667,0.67022 31.75,1.2926c10.58333,0.62238 21.16667,1.46185 31.75,2.4417c10.58333,0.97985 21.16667,2.16164 31.75,3.43739c10.58333,1.27575 21.16667,2.7256 31.75,4.21709c10.58333,1.49148 21.16667,3.11831 31.75,4.73181c10.58333,1.61351 21.16667,3.31508 31.75,4.94923c10.58333,1.63414 21.16667,3.30355 31.75,4.85565c10.58333,1.55211 21.16667,3.08445 31.75,4.45699c10.58333,1.37254 21.16667,2.67153 31.75,3.77827c10.58333,1.10674 21.16667,2.09076 31.75,2.86215c10.58333,0.77139 21.16667,1.39336 31.75,1.7662c10.58333,0.37284 21.16667,0.55925 31.75,0.55925c10.58333,0 21.16667,-0.25535 31.75,-0.68281c10.58333,-0.42747 21.16667,-1.07451 31.75,-1.88199c10.58333,-0.80748 21.16667,-1.82615 31.75,-2.96291c10.58333,-1.13676 21.16667,-2.46304 31.75,-3.85766c10.58333,-1.39461 21.16667,-2.94518 31.75,-4.51001c10.58333,-1.56484 21.16667,-3.24226 31.75,-4.87899c10.58333,-1.63674 21.16667,-3.33562 31.75,-4.94141c10.58333,-1.60579 21.16667,-3.21938 31.75,-4.69333c10.58333,-1.47395 21.16667,-2.90086 31.75,-4.15035c10.58333,-1.2495 21.16667,-2.40008 31.75,-3.3466c10.58333,-0.94653 21.16667,-1.63955 31.75,-2.33257"
        />
        <path
          className={enabled ? 'wave-2' : ''}
          strokeWidth="2"
          stroke="currentColor"
          fill="transparent"
          d="m0,6c10.58333,0.01038 21.16667,0.02076 31.75,0.06227c10.58333,0.04152 21.16667,0.67022 31.75,1.2926c10.58333,0.62238 21.16667,1.46185 31.75,2.4417c10.58333,0.97985 21.16667,2.16164 31.75,3.43739c10.58333,1.27575 21.16667,2.7256 31.75,4.21709c10.58333,1.49148 21.16667,3.11831 31.75,4.73181c10.58333,1.61351 21.16667,3.31508 31.75,4.94923c10.58333,1.63414 21.16667,3.30355 31.75,4.85565c10.58333,1.55211 21.16667,3.08445 31.75,4.45699c10.58333,1.37254 21.16667,2.67153 31.75,3.77827c10.58333,1.10674 21.16667,2.09076 31.75,2.86215c10.58333,0.77139 21.16667,1.39336 31.75,1.7662c10.58333,0.37284 21.16667,0.55925 31.75,0.55925c10.58333,0 21.16667,-0.25535 31.75,-0.68281c10.58333,-0.42747 21.16667,-1.07451 31.75,-1.88199c10.58333,-0.80748 21.16667,-1.82615 31.75,-2.96291c10.58333,-1.13676 21.16667,-2.46304 31.75,-3.85766c10.58333,-1.39461 21.16667,-2.94518 31.75,-4.51001c10.58333,-1.56484 21.16667,-3.24226 31.75,-4.87899c10.58333,-1.63674 21.16667,-3.33562 31.75,-4.94141c10.58333,-1.60579 21.16667,-3.21938 31.75,-4.69333c10.58333,-1.47395 21.16667,-2.90086 31.75,-4.15035c10.58333,-1.2495 21.16667,-2.40008 31.75,-3.3466c10.58333,-0.94653 21.16667,-1.63955 31.75,-2.33257"
        />
      </svg>
    </Block>
  );
};
export default LineChartSkeleton;

import React, { useCallback, useRef, useState } from 'react';

import { Alert, FileType, Modal, notification, Stepper, StepperPreviousHandle } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { IconCustom } from '@Components/icon/CustomIcon';
import kycQuery from '@Libs/clientInstances/idpQuery';
import { TRACKER_EVENTS } from '@Libs/tracker/events';
import { addTrackerKycStep } from '@Libs/tracker/helpers/addTrackerKyc';
import Completed from '@Modules/account/kyc/components/components/Completed';
import SelfieUpload from '@Modules/account/kyc/components/components/SelfieUpload';

import { IdentitySelection, IdentityUpload, Start } from './steps';
import type { SelectedDocType } from './steps';

export const steps = {
  kycStep1: 'kyc-step-1',
  kycStep2: 'kyc-step-2',
  kycStep3: 'kyc-step-3',
  kycStep4: 'kyc-step-4',
  kycStep5: 'kyc-step-5',
};

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

function Kyc2Panel({ open, setOpen }: Props) {
  const { t } = useTranslation();
  const { mutateAsync, isPending } = kycQuery.usePostKyc2();
  const [currentStep, setCurrentStep] = useState(steps.kycStep1);

  const fileRef = useRef<{ identity: FileType[][]; selfie: FileType[] }>({ identity: [], selfie: [] });
  const stepperRef = useRef<StepperPreviousHandle<string>>(null);
  const selectedDocTypeRef = useRef<SelectedDocType>();
  const [selectedDocType, setSelectedDocType] = useState<string | undefined>(
    t('account.id-verification.modal-titles.kyc-personal-account', { level: 2 }),
  );

  const previous = useCallback(
    (initial?: boolean) => {
      if (initial) {
        stepperRef?.current?.clearHistory();
        addTrackerKycStep(TRACKER_EVENTS.KYC2.PREVIOUS_STEP, 2, { step: steps.kycStep1 });
        setCurrentStep(steps.kycStep1);
        return;
      }
      if (currentStep === steps.kycStep3) {
        fileRef.current.identity = [];
      }

      const previousStep = stepperRef?.current?.previous();
      addTrackerKycStep(TRACKER_EVENTS.KYC2.PREVIOUS_STEP, 2, { step: previousStep });
      setCurrentStep(previousStep as string);
    },
    [currentStep],
  );

  const completed = useCallback(() => {
    const formData = new FormData();
    const { selfie, identity } = fileRef.current;

    selfie.forEach((d) => {
      formData.append('selfie', d.file as Blob, d.filename);
    });

    identity.forEach((data) => {
      data.forEach((d) => {
        formData.append('idDocs', d.file as Blob, d.filename);
      });
    });

    mutateAsync(formData)
      .then(() => {
        addTrackerKycStep(TRACKER_EVENTS.KYC2.KYC_STEP_5, 2);
        setSelectedDocType(undefined);
        setCurrentStep(steps.kycStep5);
      })
      .catch(() => {
        notification.error(t('error.application-submission-error'));
      });
  }, []);

  return (
    <>
      <Modal
        title={currentStep === steps.kycStep5 ? undefined : selectedDocType}
        open={open}
        onClose={() => setOpen(false)}
        onPreviousClick={previous}
        hasPreviousButton={currentStep !== steps.kycStep1 && currentStep !== steps.kycStep5 && !isPending}
      >
        <Stepper ref={stepperRef} step={currentStep}>
          <Stepper.Item pageName={steps.kycStep1} key={steps.kycStep1}>
            <Start
              callback={() => {
                addTrackerKycStep(TRACKER_EVENTS.KYC2.KYC_STEP_1, 2);
                setCurrentStep(steps.kycStep2);
              }}
            />
          </Stepper.Item>
          <Stepper.Item pageName={steps.kycStep2} key={steps.kycStep2}>
            <IdentitySelection
              callback={(val) => {
                addTrackerKycStep(TRACKER_EVENTS.KYC2.KYC_STEP_2, 2, val);
                selectedDocTypeRef.current = val;
                setSelectedDocType(
                  t('account.id-verification.modal-titles.upload-doc-photo', {
                    doc: selectedDocTypeRef.current.name,
                  }),
                );
                setCurrentStep(steps.kycStep3);
              }}
            />
          </Stepper.Item>
          <Stepper.Item pageName={steps.kycStep3} key={steps.kycStep3}>
            <IdentityUpload
              docSlug={selectedDocTypeRef.current?.slug}
              docCount={selectedDocTypeRef.current?.docCount}
              sources={fileRef.current.identity}
              callback={(source) => {
                addTrackerKycStep(TRACKER_EVENTS.KYC2.KYC_STEP_3, 2);
                fileRef.current.identity = source;
                setSelectedDocType(t('account.id-verification.modal-titles.facial-recognition'));
                setCurrentStep(steps.kycStep4);
              }}
            />
          </Stepper.Item>
          <Stepper.Item pageName={steps.kycStep4} key={steps.kycStep4}>
            <SelfieUpload
              customIcon={<IconCustom type="id-illustrator" />}
              alert={
                <Alert status="yellow" mb="2xl">
                  {t('account.id-verification.kyc-2.selfie-instruction')}
                </Alert>
              }
              isPending={isPending}
              sources={fileRef.current.selfie}
              callback={(source) => {
                addTrackerKycStep(TRACKER_EVENTS.KYC2.KYC_STEP_4, 2);
                fileRef.current.selfie = source;
                completed();
              }}
            />
          </Stepper.Item>
          <Stepper.Item pageName={steps.kycStep5} key={steps.kycStep5}>
            <Completed icon="diamond" />
          </Stepper.Item>
        </Stepper>
      </Modal>
    </>
  );
}

export default Kyc2Panel;

import { memo } from 'react';

import { Block, Modal, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import WarningTemplate from '@Components/template/WarningTemplate';

type Props = {
  active: boolean;
  setActive: (active: boolean) => void;
};

const TransactionExportSuccessModal = ({ active, setActive }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal open={active} onClose={() => setActive(false)}>
      <WarningTemplate
        gap="2xl"
        mt="4xl"
        pt="8xl"
        icon="download-big"
        iconColor="primary-500"
        onAccept={() => setActive(false)}
        confirmText={t('common.okay')}
      >
        <Block gap="md" items="center" maxWidth="sm" align="center" mb="4xl">
          <Text heading size="xl" weight="bold" align="center">
            {t('transactions.received-download-request')}
          </Text>

          <Text size="sm">{t('transactions.export-email-info')}</Text>
        </Block>
      </WarningTemplate>
    </Modal>
  );
};
export default memo(TransactionExportSuccessModal);

import React from 'react';

import { LayoutV2, Panel } from '@bilira-org/design';

import Notifications from '@Modules/notification/components/NotificationContainer';

import Bar from './components/Bar';

const { Content, InnerContent } = LayoutV2;

const Notification = () => {
  return (
    <>
      <Bar />
      <Content>
        <InnerContent>
          <Panel border="neutral-400" size="md" mt="3xl">
            <Notifications />
          </Panel>
        </InnerContent>
      </Content>
    </>
  );
};

export default Notification;

import getRemainingTime from './getRemainingTime';
import { OTCType } from '../../model';

/**
 * Function to check if OTC offer is still valid.
 *
 * An OTC offer is considered valid if it is in pending status and has not timed out.
 * @param {OTCType} offer - OTC offer.
 * @returns {boolean} - True if offer is valid, false otherwise.
 *
 * @example
 * checkIsOfferValid(offer) // true
 * checkIsOfferValid(timedOutOffer) // false
 */
const checkIsOfferValid = (offer?: OTCType): boolean => {
  if (!offer) {
    return false;
  }

  const remaining = getRemainingTime(offer);
  return remaining < offer.offer_timeout_in_minutes && offer.status === 'pending';
};

export default checkIsOfferValid;

import { dehydrate, DehydratedState, hydrate, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { queryClient } from '@Libs/clientInstances/queryClient';

let dehydratedState: DehydratedState;
window.addEventListener('offline', () => {
  dehydratedState = dehydrate(queryClient);
});

window.addEventListener('online', () => {
  // The mutation can then be hydrated again when the application is started:
  hydrate(queryClient, dehydratedState);
  // Resume the paused mutations:
  queryClient.resumePausedMutations().then();
  console.log('You are now connected to the network.');
});

export { QueryClientProvider, ReactQueryDevtools };

export default queryClient;

import React from 'react';

import { Icon, Text } from '@bilira-org/design';
import type { TransactionsRecordType } from '@bilira-org/react-utils';

import { TransactionItemSizeType } from '../../TransactionTable';
import { transactionTextSize } from '../constants';

type TransactionTitleProps = {
  data: TransactionsRecordType;
  size: TransactionItemSizeType;
};
const TransactionTitle = ({ data, size }: TransactionTitleProps) => {
  return (
    <Text weight="medium" size={transactionTextSize[size]} wordBreak="none" color="secondary-500">
      {data.from_asset} <Icon type="o:arrow-right" size="xs" color="neutral-700" /> {data.to_asset}
    </Text>
  );
};

export default TransactionTitle;

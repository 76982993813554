import { truncateMiddle } from '@bilira-org/react-utils';

/**
 * Masks identity number to protect sensitive data.
 * @param {string} identityNumber - The identity number to be masked.
 * @returns {string} - The masked identity number
 *
 * @example
 * const maskedNumber = maskIdentityNumber('12345678901');
 * console.log(maskedNumber); // Output: '123******901'
 */
const maskIdentityNumber = (identityNumber?: string): string => {
  if (!identityNumber) {
    return '';
  }

  return truncateMiddle(identityNumber, 3, 3, '******');
};

export default maskIdentityNumber;

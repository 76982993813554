import React, { memo } from 'react';

import { Block, Flex, Panel } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import HeadTags from '@/components/common/HeadTags';

import AccountLimit from './accountLimit';
import BankAccount from './bankAccount';
import { AccountAvatar, AccountCards } from './components';
import WalletAddress from './walletAddress';

function Index() {
  const { t } = useTranslation();

  return (
    <>
      <HeadTags title={t('account.headers.account')} titleSuffix="subpage" />
      <Block gap="4xl">
        <Panel color="neutral-200">
          <Flex direction={{ lg: 'row', xs: 'col' }} gap="md" justify="between" items={{ lg: 'center' }}>
            <AccountAvatar />
            <Flex direction={{ xs: 'col', sm: 'col', md: 'row' }} gap="md" items="stretch">
              <AccountLimit />
              <BankAccount />
              <WalletAddress />
            </Flex>
          </Flex>
        </Panel>
        <AccountCards />
      </Block>
    </>
  );
}

export default memo(Index);

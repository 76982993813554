import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import en from '../assets/locales/en/default.json';
import tr from '../assets/locales/tr/default.json';

// const apiKey = '_nP9-gWsizblqFq-w6MN_Q';
// const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

// const localPath = `../assets/locales/{{lng}}/{{ns}}.json`;
i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'tr',

    ns: ['default'],
    defaultNS: 'default',

    supportedLngs: ['en', 'tr'],
    resources: {
      en: { default: en },
      tr: { default: tr },
    },
    interpolation: {
      escapeValue: false, // react already safes from
      // xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    // backend: {
    //   loadPath: localPath,
    // },
  })
  .then(() => console.debug('Locale initialized.'));

export default i18next;

import { useEffect, useRef } from 'react';

import { ConfettiRef } from '@bilira-org/design';
import { useNavigate } from 'react-router-dom';

import useGlobalModalQueue from '@/libs/hooks/useGlobalModalQueue';
import useAuth from '@/libs/hooks/userAuth';
import { TRACKER_EVENTS } from '@/libs/tracker/events';
import { addTrackerReward } from '@/libs/tracker/helpers/addTrackerReward';
import { handleErrorResponse } from '@/libs/utils';
import RewardsQuery from '@Libs/clientInstances/rewardsQuery';
import UserQuery from '@Libs/clientInstances/userQuery';

const useRegistrationModal = () => {
  const particleRef = useRef<ConfettiRef>(null);

  const { user } = useAuth();

  const navigate = useNavigate();

  const { data, isPending: isPrerequisitesPending } = RewardsQuery.useGetUserParticipationPrerequisites();
  const { mutateAsync: mutateParticipation, isPending: isParticipationPending } =
    RewardsQuery.usePatchUserParticipation();
  const { mutateAsync: mutateDisclaimerRecord, isPending: isDisclaimerPending } = UserQuery.usePostDisclaimerRecords();
  const { isOpen: open, dequeue, enqueue } = useGlobalModalQueue('RewardsRegistrationModal');
  useEffect(() => {
    if (!isPrerequisitesPending) {
      if (data?.can_participate && !data.participated_at && data.is_pop_up_allowed) {
        enqueue();
      } else {
        dequeue();
      }
    }
  }, [data, dequeue, enqueue, isPrerequisitesPending]);

  const handleParticipation = () => {
    mutateParticipation()
      .then(() => {
        dequeue();
        navigate('/rewards');
      })
      .catch((e) => handleErrorResponse(e));
  };

  const handleReject = () => {
    mutateDisclaimerRecord({ user, type: 'point_system_join_announcement', entity_id: data?.season })
      .then(() => {
        dequeue();
      })
      .catch((e) => handleErrorResponse(e));
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => particleRef.current?.boom(), 100);
      addTrackerReward(TRACKER_EVENTS.REWARDS.REGISTRATION_MODAL_SHOWN);
    }
  }, [open]);

  return {
    open,
    closeModal: dequeue,
    handleReject,
    isRejectPending: isDisclaimerPending,
    handleParticipation,
    isParticipationPending,
    isPrerequisitesPending,
    particleRef,
  };
};

export default useRegistrationModal;

import React, { ReactNode, useRef } from 'react';

import { clsx } from 'clsx';

import { TTableProps } from './gridview';
import { Breakpoints, useWindowSize } from '../../hooks';
import { Display } from '../display';
import { Skeleton } from '../skeleton';
import { BaseComponentType, BaseSize } from '../types';
import { Text } from '../typography';

type GridBreakpoint = boolean | undefined | Breakpoints[];

export type Props<TEntry> = BaseComponentType & {
  /** An array of column configurations for the grid. */
  columns: TTableProps<TEntry>['columns'];
  detail: TTableProps<TEntry>['detail'];
  /** Whether the grid should be responsive based on window size. Defaults to `false`. */
  responsive?: boolean;
  /** Whether the grid is loading */
  loading?: boolean;
  /** Padding of the rows */
  rowPadding?: BaseSize;
  /** Callback to triggered when a row is clicked */
  onRowClick?: (data: TEntry) => void;
  /** The message to be displayed when no entries are found  */
  entry: TEntry;
  idField: TTableProps<TEntry>['idField'];
  entryIndex: number;
  rounded?: boolean;
  rowNumber?: boolean;
};

/**
 * Gridview component for displaying tabular data in a grid format.
 *
 * @example
 * <Gridview
 *   data={[
 *     { id: 1, name: 'John Doe', age: 30 },
 *     { id: 2, name: 'Jane Doe', age: 25 },
 *     { id: 3, name: 'Bob Smith', age: 35 },
 *   ]}
 *   columns={[
 *     { title: 'ID', field: 'id' },
 *     { title: 'Name', field: 'name' },
 *     { title: 'Age', field: 'age' },
 *   ]}
 *   stretch
 *   responsive
 *   displayHeader
 *   loading={false}
 *   skeleton={<Skeleton space="lg" columns={3} repeat={5} />}
 *   rowPadding="md"
 *   onRowClick={(data) => console.log('Clicked row:', data)}
 * />
 */
const GridviewRow = <TEntry,>({
  columns,
  detail,
  entry,
  responsive = false,
  loading,
  onRowClick,
  rowPadding = 'md',
  idField,
  testId,
  entryIndex,
  rounded,
  rowNumber,
}: Props<TEntry>) => {
  const breakpoint = useWindowSize({ enable: responsive });
  const rowRef = useRef<HTMLTableRowElement | null>(null);
  const parentRef = useRef<HTMLTableRowElement | null>(null);

  const getHiddenClass = (hidden?: GridBreakpoint) => {
    if (typeof hidden === 'boolean') {
      return hidden ? 'hidden' : '';
    }

    return responsive && hidden?.includes(breakpoint) ? 'hidden' : '';
  };

  const onClick = (entry: TEntry) => () => {
    if (detail) {
      const isExpanded = rowRef.current?.classList.contains('active');

      if (isExpanded) {
        parentRef.current?.classList.remove('active');
        rowRef.current?.classList.remove('active');
      } else {
        parentRef.current?.classList.add('active');
        rowRef.current?.classList.add('active');
      }
    } else {
      onRowClick?.(entry);
    }
  };

  return (
    <>
      <tr
        ref={parentRef}
        key={(() => {
          if (!entry) {
            return entryIndex;
          }

          const key = typeof idField === 'function' ? idField(entry) : (entry?.[idField] as string);
          return key || entryIndex;
        })()}
        className="body-row"
        data-testid={testId ? `${testId}-row` : null}
      >
        <Display show={rowNumber}>
          <td
            className={clsx('body-cell', {
              [`row-rounded`]: rounded,
              [`row-padding-${rowPadding}`]: rowPadding,
            })}
          >
            <Text weight="regular" size="xs" color="neutral-600">
              {entryIndex + 1}
            </Text>
          </td>
        </Display>
        {columns.map(({ cell, field, title, hidden, style, align, skeleton, disableClick }, columnIndex) => {
          const { verticalAlign, ...rest } = style?.(entry) || {};
          return (
            <td
              onClick={disableClick ? undefined : entry && onClick(entry)}
              align={align}
              key={(title || '') + columnIndex}
              style={rest}
              className={clsx(
                'body-cell',
                {
                  [`row-rounded`]: rounded,
                  [`row-padding-${rowPadding}`]: rowPadding,
                  [`tw-align-${verticalAlign}`]: verticalAlign,
                },
                clsx(getHiddenClass(hidden)),
              )}
            >
              <Display show={loading}>{skeleton ?? <Skeleton height="size-3" space="lg" />}</Display>
              {!loading && <>{cell ? cell(entry) : <>{entry?.[field as keyof typeof entry] as ReactNode}</>}</>}
            </td>
          );
        })}
      </tr>
      <Display show={!!detail && entry}>
        <tr ref={rowRef} className="body-row details">
          <Display show={rowNumber}>
            <td
              className={clsx('body-cell', {
                [`row-rounded`]: rounded,
                [`row-padding-${rowPadding}`]: rowPadding,
              })}
            >
              <Text weight="regular" size="xs" color="neutral-600">
                {entryIndex + 1}
              </Text>
            </td>
          </Display>
          <td
            colSpan={columns.length}
            className={clsx('body-cell', {
              [`row-padding-${rowPadding}`]: rowPadding,
            })}
          >
            {entry && detail?.(entry)}
          </td>
        </tr>
      </Display>
    </>
  );
};

GridviewRow.displayName = 'GridviewRow';

export default GridviewRow;

import { getIconURLByType as getIconURLByTypeUtility, notification } from '@bilira-org/design';
import { CoinSymbolType, getErrorMessage, HttpErrorType, NetworkAliasType } from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';
import dayjs, { UnitType } from 'dayjs';

import { env } from '@Libs/constants/env';

/**
 * There are cases where important information is at the end of the string and truncating the end isn't helpful.
 * This function solves that.
 *
 * @param  {string} str         String to be truncated
 * @param  {number} frontLen    Number of characters to be remained in front.
 * @param  {number} backLen     Number of characters to be remained at the back.
 * @param  {string} truncateStr String that is replaced the truncated portion
 * @return {string}             Truncated string. Defaults to '&hellip;' if unspecified.
 */
export const truncateMiddle = (str: string | undefined, frontLen = 6, backLen = 4, truncateStr = '...') => {
  if (!str) {
    return '';
  }
  const strLen = str.length;
  // Setting default values
  frontLen = ~~frontLen; // will cast to integer
  backLen = ~~backLen;
  truncateStr = truncateStr || '&hellip;';
  if ((frontLen === 0 && backLen === 0) || frontLen >= strLen || backLen >= strLen || frontLen + backLen >= strLen) {
    return str;
  }

  if (backLen === 0) {
    return str.slice(0, frontLen) + truncateStr;
  }

  return str.slice(0, frontLen) + truncateStr + str.slice(strLen - backLen);
};

export const toLocaleCapitalize = (locale: string, text?: string) => {
  if (!text) return '';

  return text
    ?.toLocaleLowerCase(locale)
    .split(' ')
    .map((word) => word.charAt(0).toLocaleUpperCase(locale) + word.slice(1))
    .join(' ');
};

const oauthParams: { [key: string]: string } = {
  authorizationURL: env.AUTHORIZATION_URL,
  tokenURL: env.TOKEN_URL,
  clientID: env.CLIENT_ID,
  callbackURL: env.CALLBACK_URL,
  scope: env.SCOPE,
};

const buildParams = new URLSearchParams({
  client_id: oauthParams.clientID,
  redirect_uri: oauthParams.callbackURL,
  response_type: 'code',
  scope: oauthParams.scope,
});

export const oauthLoginUrl = new URL(`${oauthParams.authorizationURL}?${buildParams.toString()}`);
export const oauthRegisterUrl = new URL(`${oauthParams.authorizationURL}?${buildParams.toString()}&action=register`);

export const getReferralRegisterUrl = (referralCode: string) => {
  oauthRegisterUrl.searchParams.set('ref', referralCode);
  return oauthRegisterUrl.toString();
};

export const subtractMonths = (date: Date, months: number) => {
  const result = new Date(date);
  result.setMonth(result.getMonth() - months);
  return result;
};

export const handleErrorResponse = (error?: HttpErrorType, defaultError?: string) => {
  const message = getErrorMessage(error, defaultError);

  if (message) {
    notification.error(message);
  }
};

export const logoutUrl = `${process.env.OAUTH_PROVIDER_URL}/logout?redirectTo=${process.env.STABLECOIN_WEB_URL}`;

export function priceChangeState(price: string) {
  const value = BigNumber(price);

  return {
    up: () => value.isGreaterThan(0),
    down: () => value.isLessThan(0),
    equal: () => value.isEqualTo(0),
    getColor: function () {
      if (this.up()) {
        return 'green-500';
      } else if (this.down()) {
        return 'red-500';
      } else {
        return 'neutral-800';
      }
    },
    getTransparentColor: function () {
      if (this.up()) {
        return 'green-50';
      } else if (this.down()) {
        return 'red-50';
      } else {
        return 'neutral-200';
      }
    },
    getArrow: function () {
      if (this.up()) {
        return 'o:arrow-up-right';
      } else if (this.down()) {
        return 'o:arrow-down-right';
      } else {
        return '';
      }
    },
  };
}

export const getPriceChangeColorState = (price: string) =>
  priceChangeState(price)?.up() ? 'green-500' : priceChangeState(price)?.down() ? 'red-500' : 'neutral-500';

export const getShortId = (id?: string) => {
  if (typeof id === 'string') {
    return id.split('-')[0];
  }

  return '-';
};

export interface CalculateTimeDifferenceProps {
  startTime?: string;
  endTime: string;
  unit: UnitType;
}

export const calculateTimeDifference = ({ startTime, endTime, unit }: CalculateTimeDifferenceProps) =>
  dayjs(startTime).diff(endTime, unit);

/**
 * Function to get coin icons in inline svg format.
 * This is useful when a library needs inline svgs.
 *
 * @param {CoinSymbolType | NetworkAliasType} type - The coin symbol or network alias.
 * @returns {React.Component | null} - The inline SVG icon component or null if not found.
 */
export function getIconURLByType(type?: CoinSymbolType | NetworkAliasType) {
  return getIconURLByTypeUtility(env.CDN_BASE_URL, type);
}

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

/**
 * Checks if a given time is within a specified time range.
 * @param {string} time The time to check in HH:mm format.
 * @param {string} start The start time of the range in HH:mm format.
 * @param {string} end The end time of the range in HH:mm format.
 * @returns {boolean} Returns true if the given time is within the specified range, otherwise false.
 * @example
 * isTimeInRange('10:30', '08:00', '12:00'); //  true
 * isTimeInRange('14:00', '08:00', '12:00'); //  false
 */
export const isTimeInRange = (time: string, start: string, end: string) => {
  const today = dayjs().format('YYYY-MM-DD');
  const currentTime = dayjs(`${today} ${time}`, 'YYYY-MM-DD HH:mm');
  const startTime = dayjs(`${today} ${start}`, 'YYYY-MM-DD HH:mm');
  const endTime = dayjs(`${today} ${end}`, 'YYYY-MM-DD HH:mm');

  // Handles cases where startTime and endTime span across midnight
  if (endTime.isBefore(startTime)) {
    return currentTime.isAfter(startTime) || currentTime.isBefore(endTime);
  }

  return currentTime.isBetween(startTime, endTime, null, '[]');
};

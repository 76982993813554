import { ReactNode } from 'react';

import { Column, ColumnDef, SortDirection } from '@tanstack/react-table';

import { Flex } from '../../flex';
import { SortingIcon } from '../../icons';
import { SortingIconProps } from '../../icons/sortingIcon';
import { JustifyType } from '../../types';

interface SortableHeaderCellProps<T> {
  column: Column<T>;
  loading?: boolean;
  children?: ReactNode;
}

const colorMap: Record<SortDirection | 'false', SortingIconProps['color']> = {
  false: { upArrow: 'neutral-600', downArrow: 'neutral-600' },
  asc: { upArrow: 'neutral-700', downArrow: 'neutral-600' },
  desc: { upArrow: 'neutral-600', downArrow: 'neutral-700' },
};

const alignToJustify: Record<Exclude<ColumnDef<any>['align'], undefined>, JustifyType> = {
  center: 'center',
  left: 'start',
  right: 'end',
};

export default function SortableHeaderCell<T>({ column, loading, children }: SortableHeaderCellProps<T>) {
  const sortingOrder = !loading ? column.getIsSorted() : false;

  return (
    <Flex justify={alignToJustify[column.columnDef.align || 'left']} direction="row">
      <Flex onClick={column.getToggleSortingHandler()} direction="row" className="cursor-pointer" items="center">
        {children}
        <SortingIcon
          className="ml-[0.125rem] flex-shrink-0"
          size="xs"
          color={colorMap[sortingOrder.toString() as SortDirection | 'false']}
        />
      </Flex>
    </Flex>
  );
}

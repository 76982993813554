import React from 'react';

import { clsx } from 'clsx';

import StepProgressSkeleton from './StepProgressSkeleton';
import { Display } from '../display';
import MotionHeight from '../framer/motionHeight';
import { Spinner } from '../spinner';
import {
  BackgroundColorType,
  BaseComponentType,
  BaseSize,
  BorderStyleType,
  FlexItemsType,
  MaxWidthType,
  OverflowType,
  PaddingSizes,
} from '../types';

export type StepItemProps = {
  /** Unique key for the step. If not provided, the index will be used as the key. */
  key?: string;
  /** Title of the step item */
  title?: string | React.ReactNode;
  /** Description of the step */
  description?: string | React.ReactNode;
  /** Icon for the step */
  icon?: string | React.ReactNode;
  /** Extra content for the step */
  extra?: React.ReactNode;
  /** Indicates whether the step is complete. */
  complete?: boolean;
  /** Indicates whether the step is active. */
  active?: boolean;
  /** Indicates whether the step is hidden. */
  hidden?: boolean;
  /** Additional icon on the top right corner of the step. */
  iconExtra?: { icon: React.ReactNode; background?: BackgroundColorType };
  /** Whether the step is in loading state or not. */
  loading?: boolean;
};

type ColorSchema = {
  completed: BackgroundColorType;
  uncompleted: BackgroundColorType;
};

interface IStepProgress extends BaseComponentType {
  /** Array of step data to be rendered */
  steps: StepItemProps[];
  /** Skeleton to display while loading=true */
  skeleton?: React.ReactNode | StepItemProps[];
  /** Whether to display steps vertically */
  vertical?: boolean;
  /** Whether the step progress should be responsive */
  responsive?: boolean;
  /** Size of the component */
  size?: BaseSize;
  /** Whether step progress is loading */
  loading?: boolean;
  /** Background color for the steps */
  color?: BackgroundColorType | ColorSchema;
  /** Border style for the steps */
  borderStyle?: BorderStyleType;
  /** Alignment of step items */
  alignItems?: FlexItemsType;
  /** Maximum width of the step progress */
  maxWidth?: MaxWidthType;
  /** Margin top */
  mt?: PaddingSizes;
  /** Whether to hide step icons on small screens */
  hideStepIconOnSm?: boolean;
  /** Whether to hide step lines on small screens */
  hideStepLinesOnSm?: boolean;
  /** Overflow behaviour of the component */
  overflow?: OverflowType;
  /** Animation effect for the steps */
  animate?: 'height';
  /** Variant */
  variant?: 'default' | 'points';
}

/**
 * Component that represents a step progress bar with various customization options.
 *
 * @example
 * <StepProgress
 *   steps={[
 *     { title: 'Step 1', icon: '1' },
 *     { title: 'Step 2', icon: '2' },
 *     { title: 'Step 3', icon: '3' },
 *   ]}
 *   size="md"
 *   loading={false}
 *   color={{ completed: 'green', uncompleted: 'gray' }}
 *   borderStyle="solid"
 *   alignItems="center"
 *   maxWidth="2xl"
 *   mt="md"
 *   hideStepIconOnSm={false}
 *   overflow="hidden"
 * />
 */
export const StepProgress = ({
  size = 'md',
  steps,
  vertical,
  responsive,
  color,
  borderStyle = 'solid',
  maxWidth,
  alignItems,
  loading,
  mt,
  hideStepIconOnSm,
  hideStepLinesOnSm,
  testId,
  overflow = 'hidden',
  animate,
  skeleton = <>loading...</>,
  variant = 'default',
}: IStepProgress) => {
  if (loading && !Array.isArray(skeleton)) {
    return <>{skeleton}</>;
  }

  let stepperClass = 'step-default';
  if (typeof color === 'object') {
    stepperClass = clsx({
      [`step-completed-${color?.completed}`]: color?.completed,
      [`step-uncompleted-${color?.uncompleted}`]: color?.completed,
    });
  } else if (color !== undefined) {
    stepperClass = `step-default-${color}`;
  }

  const stepProgressElement = (
    <ol
      className={clsx(`stepper-progress stepper-variant-${variant}`, {
        'stepper-progress-horizontal stepper-progress-horizontal-center': responsive ? false : !vertical,
        'sm:stepper-progress-horizontal xs:stepper-progress-vertical': responsive && variant === 'default',
        'lg:stepper-progress-horizontal md:stepper-progress-vertical': responsive && variant === 'points',
        'stepper-progress-vertical': vertical,
        [`stepper-progress-${size}`]: size,
        [`tw-max-w-${maxWidth}`]: maxWidth,
        [`mt-${mt}`]: mt,
        'stepper-progress-hide-icon-sm': hideStepIconOnSm,
        'stepper-progress-hide-line-sm': hideStepLinesOnSm,
        [`tw-overflow-${overflow}`]: overflow,
      })}
      data-testid={testId ?? 'step-progress'}
    >
      {loading ? (
        <StepProgressSkeleton skeleton={skeleton as StepItemProps[]} alignItems={alignItems} />
      ) : (
        steps.map((step, index) => {
          const stepItem = (
            <li
              key={step.key ? `step-progress-${step.key}` : `step-progress-${index}`}
              className={clsx(`stepper-step stepper-variant-${variant}`, stepperClass, {
                [`step-border-${borderStyle}`]: borderStyle,
                'step-active': step.active,
                'step-completed': step.complete,
              })}
            >
              <div className={clsx('stepper-head')}>
                <Display show={step.loading}>
                  <div className={clsx('stepper-head-icon')}>
                    <Spinner size="sm" borderSize="sm" />
                  </div>
                </Display>

                <Display show={!step.loading}>
                  <div
                    className={clsx('stepper-head-icon', {
                      // 'step-completed': step.complete,
                      // 'step-uncompleted': !step.complete,
                      'stepper-head-icon-has-extra': !!step.iconExtra,
                    })}
                  >
                    {step.icon ?? index + 1}
                    {step.iconExtra && (
                      <div
                        className={clsx('stepper-head-icon-extra', {
                          'tw-bg-theme-wn': !step.iconExtra.background,
                          [`tw-bg-${step.iconExtra.background}`]: step.iconExtra.background,
                        })}
                      >
                        {step.iconExtra.icon}
                      </div>
                    )}
                  </div>
                </Display>

                {step.title && (
                  <div
                    className={clsx('stepper-head-text', {
                      [`tw-items-${alignItems}`]: alignItems,
                    })}
                  >
                    <div className="stepper-head-inner">{step.title}</div>
                  </div>
                )}
              </div>
              {step.description && (
                <div className="step-progress stepper-head">
                  <div className={clsx('stepper-head-icon-placeholder')}></div>
                  <div className="stepper-description-text">{step.description}</div>
                </div>
              )}
              <div className={clsx('lg:pt-5', { hidden: variant !== 'points' })}>
                <div className="stepper-point xs:ml-[25px] sm:ml-[25px] lg:ml-0" />
              </div>
            </li>
          );

          return step.hidden ? null : stepItem;
        })
      )}
    </ol>
  );

  if (animate === 'height') {
    return (
      <MotionHeight variant="height" show={true} animateOnMount={false}>
        {stepProgressElement}
      </MotionHeight>
    );
  } else {
    return <>{stepProgressElement}</>;
  }
};

StepProgress.displayName = 'StepProgress';

import React, { Fragment, ReactNode } from 'react';

import { AccordionProps } from './accordion';
import { Separator } from '../separator';

import { Accordion } from '.';

interface AccordionGroupProps extends Omit<AccordionProps, 'children'> {
  /** If true, adds separators between accordion items. */
  linked?: boolean;
  /** An array of objects containing label, content, and key for each accordion item. */
  data: { label: ReactNode; content: ReactNode; key: string }[];
}

/**
 * AccordionGroup Component - A grouping component for managing multiple Accordion items.
 *
 * This component provides a convenient way to organize and render multiple Accordion items within a single component.
 *
 * @example
 * const data = [
 *   { label: 'Section 1', content: <div>Content for Section 1</div>, key: 'section1' },
 *   { label: 'Section 2', content: <div>Content for Section 2</div>, key: 'section2' },
 *   // ... more data
 * ];
 * <Accordion.Group linked data={data} />
 */
const AccordionGroup = ({ linked, data, ...rest }: AccordionGroupProps) => {
  return (
    <Accordion {...rest}>
      {data.map((item, index) => (
        <Fragment key={item.key}>
          <Accordion.Item label={item.label} accordionKey={item.key}>
            {item.content}
          </Accordion.Item>
          {linked && index !== data.length - 1 && <Separator height="size-4" mx="4xl" />}
        </Fragment>
      ))}
    </Accordion>
  );
};

export default AccordionGroup;

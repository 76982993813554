import React from 'react';

import { clsx } from 'clsx';
import Lottie, { LottieProps } from 'react-lottie-player';

import GiftBox from '../../assets/lotties/giftBox.json';
import { BaseComponentType, SizeType, TextColorType, Theme } from '../types';

export type AnimationType = 'gift-box';

export type IconAnimatedProps = Omit<LottieProps, 'color' | 'stroke' | 'type' | 'fill' | 'size'> &
  BaseComponentType & {
    /** Additional class name for the icon */
    className?: string;
    /** Theme to be applied to the icon */
    theme?: Theme;
    /** Type of the icon */
    type: AnimationType;
    /** Width of the icon */
    width?: SizeType;
    /** Height of the icon */
    height?: SizeType;
  };

// export type IconCustomProps = IconCustomCommonProps &
//   ((IconCustomLightProps & { theme: 'light' }) | (IconCustomDarkProps & { theme?: 'dark' }));

/**
 * Custom icon component that renders icons based on type and theme
 *
 * @example
 * <IconCustom type="empty-folder" size="sm" />
 */
export function IconAnimated({ theme, type, className, testId, width, height, ...props }: IconAnimatedProps) {
  const classes = clsx(
    'icons',
    {
      [`cursor-pointer`]: !!props.onClick,
      [`tw-w-${width}`]: width,
      [`tw-h-${height}`]: height,
    },
    className,
  );
  const animationData = theme === 'light' ? AnimationData[type].light : AnimationData[type].dark;

  if (!animationData) {
    return null;
  }

  return <Lottie play loop animationData={animationData} className={classes} {...props} />;
}

const AnimationData: Record<AnimationType, { light: any; dark: any }> = {
  'gift-box': { light: GiftBox, dark: GiftBox },
};

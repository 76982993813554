import { useCallback, useState } from 'react';

import { DataTableProps } from '@bilira-org/design';
import { AssetListType } from '@bilira-org/react-utils';

import { StorageValues, useLocalStorage } from '@/store/localStorage';

export const useRankingTableControls = () => {
  const [dataTag, setDataTag] = useState<string>();
  const [sorting, setSorting] = useState<StorageValues['MARKET_TABLE_SORT'] | undefined>();

  const onTagChange = useCallback((tag?: string) => {
    setSorting(undefined);
    setDataTag(tag);
  }, []);

  const onSortingChange = useCallback<Exclude<DataTableProps<AssetListType>['onSortingChange'], undefined | string>>(
    (updater) => {
      setSorting((prevValue) => {
        const newSortingState = updater instanceof Function ? updater(prevValue?.original || []) : updater;
        const newValue: StorageValues['MARKET_TABLE_SORT'] = {
          field: undefined,
          direction: undefined,
          original: newSortingState,
        };
        if (newSortingState.length > 0) {
          newValue.field = newSortingState[0].id as Exclude<StorageValues['MARKET_TABLE_SORT'], undefined>['field'];
          newValue.direction = newSortingState[0].desc ? 'desc' : 'asc';
        }
        return newValue;
      });
    },
    [],
  );

  return {
    dataTag,
    setDataTag: onTagChange,
    sorting,
    setSorting: onSortingChange,
  };
};

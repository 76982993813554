import React from 'react';

import { Block, ChartPriceDataType, Display, Skeleton, Text } from '@bilira-org/design';
import { OHLCSType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import FormattedNumberByPrice from '@/components/common/FormattedNumberByPrice';
import HeadTags from '@Components/common/HeadTags';
import PriceChange from '@Components/common/PriceChange';
import useAssetInfoHook from '@Modules/market/detail/components/priceChart/hooks/useAssetInfoHook';

type Props = {
  priceInfo?: ChartPriceDataType;
  priceData: OHLCSType;
};

const AssetInfo = ({ priceData, priceInfo }: Props) => {
  const { base, quote, price, defaultData, assetItem, isLoading } = useAssetInfoHook({
    priceData,
    priceInfo,
  });
  const { t } = useTranslation();

  if (!price || isLoading) {
    return (
      <Block>
        <Block row gap="md" items="center">
          <Skeleton animate={false} width="size-8" height="size-8" rounded="full" />
          <Skeleton animate={false} width="size-24" height="size-4" />
        </Block>
        <Skeleton animate={false} width="size-48" height="size-4" mt="lg" />
        <Skeleton animate={false} width="size-20" height="size-4" mt="sm" />
      </Block>
    );
  }

  return (
    <div>
      <HeadTags
        title={t('meta.market-detail.title', {
          asset: assetItem?.name,
          pair: `${base}/${quote}`,
        })}
        description={t('meta.market-detail.description', {
          asset: assetItem?.name,
          pair: `${base}/${quote}`,
        })}
      />
      <Block row gap="sm" items="center">
        <FormattedNumberByPrice
          heading
          color="secondary-500"
          size={{ xs: '2xl', sm: '4xl' }}
          decimalSize={{ xs: 'xl', sm: '2xl' }}
          suffixSize={{ xs: 'sm', sm: 'lg' }}
          weight="bold"
          suffix={quote}
          suffixHeading={false}
          value={price}
          price={price}
          type="price"
        />
        <Display show={base !== 'TRYB'}>
          <Block mt="md">
            <PriceChange justify={'end'} size="sm" withTag value={defaultData?.change} />
          </Block>
        </Display>
      </Block>
      <Text size="sm">
        {defaultData?.time.toString() ??
          `${new Date().toLocaleDateString('tr-TR')} : ${new Date().toLocaleTimeString('tr-TR')}`}
      </Text>
    </div>
  );
};

export default AssetInfo;

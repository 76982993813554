import { memo, ReactElement, useMemo } from 'react';

import { Block, DataTable, TableBodyStyle, TableHeaderStyle } from '@bilira-org/design';
import { AllTransactionsRecordType, TransactionTypes } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { LIMIT } from '@Libs/config';

import getColumns from './helpers/getColumns';

const headerStyle: TableHeaderStyle = { showBackground: true, px: '2xl', py: 'sm' };
const bodyStyle: TableBodyStyle = { px: '2xl', py: 'lg' };
const pinnedColumns = ['created_at'];

export type TransactionItemSizeType = 'sm' | 'base';

type AllTransactionTableProps = {
  /** Type of the transactions */
  type: TransactionTypes;
  /** Array of transaction records */
  data: AllTransactionsRecordType[];
  /** Function to be called when a transaction is clicked */
  callback?: (item: AllTransactionsRecordType) => void;
  /** Whether the data is loading */
  loading: boolean;
  /** Number of transactions to display per page */
  limit?: number;
  /** Component to render when the transaction data is empty */
  empty?: string | ReactElement;
  /** Number of skeleton lines to display when loading */
  skeletonLine?: number;
};

/**
 * Displays a list of transaction records in a table
 */
const AllTransactionTable = ({
  type,
  data,
  callback,
  loading,
  limit,
  empty,
  skeletonLine,
}: AllTransactionTableProps) => {
  const { t, i18n } = useTranslation();

  const columns = useMemo(() => getColumns(type, t, i18n.language), [i18n.language, t, type]);
  return (
    <Block gap="2xl">
      <DataTable
        onRowClick={callback}
        headerStyle={headerStyle}
        bodyStyle={bodyStyle}
        testId="transaction-table"
        loading={loading}
        skeletonRowCount={skeletonLine ?? limit ?? LIMIT}
        emptyMessage={empty ?? t('common.empty-history-data-message')}
        data={data}
        columns={columns}
        pinnedColumns={pinnedColumns}
        divider
      />
    </Block>
  );
};

export default memo(AllTransactionTable);

import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useAuthParamsStore } from '@/libs/hooks/useAuthParams';
import RedirectSpinCard from '@Components/common/RedirectSpinCard';
import useLocaleRoute from '@Libs/hooks/useLocaleRoute';
import { oauthLoginUrl, oauthRegisterUrl } from '@Libs/utils';

const Login = () => {
  const { t, i18n } = useTranslation();
  const { getRoute, route } = useLocaleRoute();
  const { authParams } = useAuthParamsStore();

  useEffect(() => {
    const redirectUrl = route === getRoute('Login') ? oauthLoginUrl : oauthRegisterUrl;

    const searchParams = new URLSearchParams({
      ...Object.fromEntries(redirectUrl.searchParams),
      locale: i18n.language,
      ...authParams.utm,
      ...authParams.general,
    });

    // Set the redirectUrl's search params directly
    redirectUrl.search = searchParams.toString();

    const timeoutId = setTimeout(() => {
      window.location.replace(redirectUrl.toString());
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [getRoute, i18n.language, route, authParams]);

  return <RedirectSpinCard title={t('redirect.redirecting-2')} description={t('redirect.redirecting-to-login')} />;
};

export default Login;

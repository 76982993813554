import React, { CSSProperties, HTMLAttributes, ReactNode } from 'react';

import { clsx } from 'clsx';

import { BackgroundColorType, BaseComponentType, BreakpointType, FixSizeType, PaddingSizes } from '../types';

type OffsetSize = 'xs' | 'sm' | 'md';

export type AvatarProps = HTMLAttributes<HTMLDivElement> &
  BaseComponentType & {
    /** The content or source for the avatar */
    avatar: ReactNode | string;
    /** Content for the bottom-left corner of the avatar. */
    bl?: ReactNode;
    /** Content for the bottom-right corner of the avatar. */
    br?: ReactNode;
    /** Content for the top-left corner of the avatar. */
    tl?: ReactNode;
    /** Content for the top-right corner of the avatar. */
    tr?: ReactNode;
    /** The size of the avatar. */
    size?: FixSizeType | BreakpointType<FixSizeType>;
    /** Whether the avatar has a border. */
    bordered?: boolean;
    /** Variant of the avatar. */
    variant?: 'default' | 'padded';
    /** Offset for positioning icon of avatar. */
    offset?: { top?: OffsetSize; bottom?: OffsetSize; left?: OffsetSize; right?: OffsetSize };
    /** Background color of the icon */
    iconColor?: BackgroundColorType;
  };

/**
 * Avatar component for displaying user avatars with customizable corners.
 *
 * @example
 * <Avatar
 *   avatar={<img src="avatar.jpg" alt="User Avatar" />}
 *   size="md"
 *   bordered={true}
 * />
 */
const Avatar = ({
  avatar,
  bl,
  br,
  tl,
  tr,
  size,
  testId,
  bordered,
  variant = 'default',
  iconColor,
  offset,
}: AvatarProps) => {
  const classes = clsx('avatar', { [`fix-size-${size}`]: size });

  const iconClasses = clsx({
    [`icon-${variant}`]: variant,
    [`tw-bg-${iconColor}`]: iconColor,
    [`offset-t-${offset?.top}`]: offset?.top,
    [`offset-b-${offset?.bottom}`]: offset?.bottom,
    [`offset-r-${offset?.right}`]: offset?.right,
    [`offset-l-${offset?.left}`]: offset?.left,
  });

  return (
    <div className={classes} data-testid={testId ?? 'avatar'}>
      {tl && <div className={clsx('avatar-tl', iconClasses)}>{tl}</div>}
      {tr && <div className={clsx('avatar-tr', iconClasses)}>{tr}</div>}
      <div
        className={clsx('avatar-icon', {
          'avatar-bordered': bordered,
          [`fix-size-${size}`]: size,
        })}
      >
        {avatar}
      </div>
      {bl && <div className={clsx('avatar-bl', iconClasses)}>{bl}</div>}
      {br && <div className={clsx('avatar-br', iconClasses)}>{br}</div>}
    </div>
  );
};

Avatar.displayName = 'Avatar';

export default Avatar;

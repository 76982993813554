import React from 'react';

import { Block, Flex, LineChartSkeleton, Panel, Skeleton, Text } from '@bilira-org/design';
import Blur from '@bilira-org/design/src/components/blur/blur';
import Position from '@bilira-org/design/src/components/position/position';
import { useTranslation } from 'react-i18next';

type Props = {
  loading?: boolean;
};

const EmptyGraph = ({ loading }: Props) => {
  const { t } = useTranslation();

  return (
    <Position>
      <Blur blur={loading ? 'none' : 'xs'}>
        <Flex
          direction={{ xs: 'col', sm: 'col', md: 'row' }}
          gap={{ xs: 'lg', sm: 'lg' }}
          justify="between"
          px="2xl"
          pt="2xl"
        >
          <Flex.Item flexType="none">
            <Block>
              <Block row gap="md" items="center">
                <Skeleton animate={loading} width="size-8" height="size-8" rounded="full" />
                <Skeleton animate={loading} width="size-24" height="size-4" />
              </Block>
              <Skeleton animate={loading} width="size-48" height="size-9" mt="lg" />
              <Skeleton animate={loading} width="size-20" height="size-4" mt="xs" />
            </Block>
          </Flex.Item>
          <Flex.Item flex direction="row" gap="lg" justify={{ xs: 'around', sm: 'around', md: 'end' }}>
            <Skeleton animate={loading} width="size-6" height="size-4" />
            <Skeleton animate={loading} width="size-6" height="size-4" />
            <Skeleton animate={loading} width="size-6" height="size-4" />
            <Skeleton animate={loading} width="size-6" height="size-4" />
            <Skeleton animate={loading} width="size-6" height="size-4" />
          </Flex.Item>
        </Flex>
        <Panel size="none" mb="xs">
          <LineChartSkeleton enabled={loading} />
        </Panel>
      </Blur>
      {!loading && (
        <Position position="absolute" align="center">
          <Text color="neutral-600">{t('market.empty-graph')}</Text>
        </Position>
      )}
    </Position>
  );
};

export default EmptyGraph;

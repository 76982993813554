import { memo } from 'react';

import useRemoteConfig from '@/libs/hooks/useRemoteConfig';
import { withPrivateRoute } from '@Libs/hocs';

const RemoteConfigInitializer = () => {
  useRemoteConfig();

  return null;
};

export default memo(withPrivateRoute(RemoteConfigInitializer));

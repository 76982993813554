import React from 'react';

import { Block, Button, Flex, Panel, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CryptoList from '@/components/cryptoCards/CryptoList';
import CryptoListCard from '@/components/cryptoCards/CryptoListCard';
import routeMap from '@/libs/route/routeMap';
import { CUSTOM_ASSET_TAGS } from '@/modules/market/market/table/components/hooks/constants';

const HypeCards = () => {
  const { t, i18n } = useTranslation();
  const rankingPath = routeMap.Market.Ranking[i18n.language as 'tr' | 'en'];

  return (
    <>
      <Flex
        minWidth="min"
        items="stretch"
        gap="4xl"
        justify={{ sm: 'start', md: 'end' }}
        direction={{ xs: 'col', sm: 'col', xl: 'row' }}
        wrap={{ lg: 'wrap' }}
      >
        <Flex.Item flexType="1">
          <CryptoListCard
            title={t('overview.top-gainers')}
            showAllLinkProps={{ to: `${rankingPath}#${CUSTOM_ASSET_TAGS.TOP_GAINERS}` }}
          >
            <CryptoList tag={CUSTOM_ASSET_TAGS.TOP_GAINERS} rowNumber />
          </CryptoListCard>
        </Flex.Item>
        <Flex.Item flexType="1">
          <CryptoListCard
            title={t('overview.top-losers')}
            showAllLinkProps={{ to: `${rankingPath}#${CUSTOM_ASSET_TAGS.TOP_LOSERS}` }}
          >
            <CryptoList tag={CUSTOM_ASSET_TAGS.TOP_LOSERS} rowNumber />
          </CryptoListCard>
        </Flex.Item>
      </Flex>
    </>
  );
};

export default HypeCards;

import { CryptoTimeScale } from '@bilira-org/react-utils';
import { OHLCSTimeScale } from '@bilira-org/react-utils/src/apis/price';
import dayjs from 'dayjs';

export type TimeScaleConfigurationType = (date: number, locale: string) => string;
/**
 * Returns timescale formatter functions for the LightweightChart
 */
export const scaleConfiguration: { [key in CryptoTimeScale]: TimeScaleConfigurationType } = {
  hour: (date: number, locale: string) => dayjs.unix(date).locale(locale).format('HH:mm'),
  day: (date: number, locale: string) => dayjs.unix(date).locale(locale).format('DD MMM HH:00'),
  week: (date: number, locale: string) => dayjs.unix(date).locale(locale).format('DD MMM'),
  month: (date: number, locale: string) => dayjs.unix(date).locale(locale).format('DD MMM'),
  '3month': (date: number, locale: string) => dayjs.unix(date).locale(locale).format('DD MMM'),
  '6month': (date: number, locale: string) => dayjs.unix(date).locale(locale).format('DD MMM'),
  year: (date: number, locale: string) => dayjs.unix(date).locale(locale).format('MMM'),
};

// Hourly: 60 data points for last 1 hour, per 1 minutes
// Daily:  148 data points for last 24 hour, per 10 minutes
// Weekly 336 data points for last 1 week, per 30 minutes
// Monthly 180 data points for last 1 month (30days), per 4 hours
// Annual 365 data points for last 365 days, per day
export const timeScaleConfiguration: Record<CryptoTimeScale, { point: number; scale: OHLCSTimeScale }> = {
  hour: { point: 60, scale: '1m' }, // 60
  day: { point: 96, scale: '15m' }, // 96
  week: { point: 336, scale: '30m' }, // 336
  month: { point: 180, scale: '4h' }, // 180
  '3month': { point: 360, scale: '6h' }, // 360
  '6month': { point: 360, scale: '12h' }, // 360
  year: { point: 365, scale: '1d' }, // 365
};

export const timeScales: CryptoTimeScale[] = ['day', 'week', 'month', '3month', '6month', 'year'];

export default scaleConfiguration;

const test = {
  closes: [
    '19130',
    '18817',
    '19255',
    '19257',
    '19198',
    '18983',
    '19412',
    '19390',
    '19479',
    '19335',
    '19756.1',
    '19669',
    '19646.8',
    '19661.7',
    '19857.8',
    '19945',
    '20281.5',
    '20074.1',
    '19743.5',
    '19561.2',
    '19332.6',
    '19487.9',
    '19661.6',
    '19670.9',
    '20308.1',
    '20484.5',
    '21012.9',
    '20667.4',
    '20576.3',
    '20352.5',
    '20381.2',
    '20436.5',
    '20403.6',
    '20378.6',
    '20306.3',
    '20365.5',
    '20462.5',
    '20284.2',
    '20369.4',
    '20308.2',
    '20317',
    '20314.7',
    '20397.7',
    '20301.4',
    '20291.2',
    '20328.6',
    '20418.4',
    '20596.3',
    '20300.2',
    '20000.8',
    '20192',
    '20032.1',
    '19894.8',
    '19934',
    '19679.2',
    '19863.5',
    '19907.8',
    '19834.1',
    '20025.3',
    '19902.8',
    '19798.9',
    '19592.6',
    '19589.6',
    '19654.6',
    '19460.9',
    '19691.5',
    '19543.5',
    '19644',
    '19714.4',
    '19551.9',
    '19635.5',
    '19716.1',
    '19697.6',
    '19242.2',
    '19351.9',
    '19692.6',
    '19201.2',
    '19437.9',
    '19571.1',
    '19690',
    '19658.9',
    '19649.8',
    '19689.2',
    '19575.9',
    '19454.7',
    '19415.1',
    '19322.2',
    '19205.2',
    '18805.9',
    '18710.3',
    '18853',
    '19012.3',
    '19151.5',
    '19198.6',
    '19093.8',
    '19256.5',
    '19275.2',
    '19220.1',
    '19156.1',
    '19047.6',
    '18835.8',
    '18996.4',
    '19151.7',
    '19342',
    '19323.8',
    '19341.4',
    '19565.6',
    '19526.6',
    '19460.1',
    '19515.2',
    '19494.3',
    '19355.6',
    '19254.5',
    '19242',
    '19460.9',
    '19460.3',
    '19295.5',
    '19310',
    '19134.1',
    '19281.5',
    '19124.4',
    '18791.6',
    '18912.1',
    '18845.1',
    '18386.2',
    '18411.6',
    '18526.6',
    '18511.3',
    '18701.7',
    '18729.9',
    '18749.8',
    '18763.1',
    '18803.9',
    '18939.2',
    '19034.4',
    '19252.3',
    '19511.7',
    '19188.9',
    '19283.6',
    '18984.4',
  ],
};

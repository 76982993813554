import React from 'react';

import { Col, Flex, Grid, Text, withIntrinsicHidden } from '@bilira-org/design';
import { concatTicker } from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';

import useFormatNumberByLivePrice from '@/libs/hooks/useFormatNumberByLivePrice';
import PriceChange from '@Components/common/PriceChange';
import LastPriceLive from '@Components/live/LastPriceLive';
import useStatisticsLive from '@Libs/hooks/useStatisticsLive';
import useAssetDetail from '@Modules/market/detail/hooks/useAssetDetail';

const AssetCurrentStatisticValues = () => {
  const { t } = useTranslation();
  const { hasError, base, quote, priceData } = useAssetDetail();
  const formatNumberByLivePrice = useFormatNumberByLivePrice({ base, quote });
  const { liveData = priceData } = useStatisticsLive({ symbol: concatTicker(base, quote) });

  if (hasError) {
    return null;
  }

  const getPrice = (value?: string) => {
    return formatNumberByLivePrice({
      type: 'price',
      value: value,
      defaultValue: '0.00',
    });
  };

  const high24h: any = getPrice(liveData?.h_24);
  const low24h: any = getPrice(liveData?.l_24);
  const avg24h: any = getPrice(
    BigNumber(liveData?.h_24 || '0')
      .plus(liveData?.l_24 || '0')
      .div(2)
      .toString(),
  );

  return (
    <>
      <Grid
        alignContent="between"
        mt={{ xs: 'lg', sm: 'lg', xl: 'none' }}
        col={{ xs: 3, sm: 5, md: 5, lg: 5 }}
        justifyItems="stretch"
        placeItems={{ xs: 'start', sm: 'center', xl: 'start' }}
      >
        <Col>
          <Text color="neutral-700" size="xs" weight="regular">
            {t('market.current-price')}
          </Text>

          <LastPriceLive
            size="sm"
            color="secondary-500"
            weight="medium"
            base={base}
            suffixColor="neutral-700"
            quote={quote}
            showSuffix={false}
          />
        </Col>
        <Col>
          <Text color="neutral-700" size="xs" weight="regular">
            {t('market.change-24h')}
          </Text>
          <Flex flexType="none">
            <PriceChange showArrow={false} showRawChange justify="center" size="sm" value={liveData?.change_24} />
          </Flex>
        </Col>
        <Col>
          <Text color="neutral-700" size="xs" weight="regular">
            {t('market.high-24h')}
          </Text>
          <Text color="secondary-500" size="sm" weight="medium">
            {high24h.value}
          </Text>
        </Col>
        <Col>
          <Text color="neutral-700" size="xs" weight="regular">
            {t('market.low-24h')}
          </Text>
          <Text color="secondary-500" size="sm" weight="medium">
            {low24h.value}
          </Text>
        </Col>
        <Col>
          <Text color="neutral-700" size="xs" weight="regular">
            {t('market.avg-24h')}
          </Text>
          <Text color="secondary-500" size="sm" weight="medium">
            {avg24h.value}
          </Text>
        </Col>
      </Grid>
      {/*<Flex*/}
      {/*  direction="row"*/}
      {/*  wrap="wrap"*/}
      {/*  gap="md"*/}
      {/*  mt={{ xs: 'lg', sm: 'lg', xl: 'none' }}*/}
      {/*  justify={{ xs: 'between', sm: 'between', xl: 'around' }}*/}
      {/*  minHeight={'max'}*/}
      {/*>*/}
      {/*  <Flex.Item minWidth="max">*/}
      {/*    <Text color="neutral-700" size="xs" weight="regular">*/}
      {/*      {t('market.current-price')}*/}
      {/*    </Text>*/}

      {/*    <LastPriceLive*/}
      {/*      size="sm"*/}
      {/*      color="secondary-500"*/}
      {/*      weight="medium"*/}
      {/*      base={base}*/}
      {/*      suffixColor="neutral-700"*/}
      {/*      quote={quote}*/}
      {/*      showSuffix={false}*/}
      {/*    />*/}
      {/*  </Flex.Item>*/}
      {/*  <Flex.Item minWidth="max">*/}
      {/*    <Text color="neutral-700" size="xs" weight="regular">*/}
      {/*      {t('market.change-24h')}*/}
      {/*    </Text>*/}
      {/*    <PriceChange showArrow={false} showRawChange justify="center" size="sm" value={liveData?.change_24} />*/}
      {/*  </Flex.Item>*/}
      {/*  <Flex.Item minWidth="max">*/}
      {/*    <Text color="neutral-700" size="xs" weight="regular">*/}
      {/*      {t('market.high-24h')}*/}
      {/*    </Text>*/}
      {/*    <Text color="secondary-500" size="sm" weight="medium">*/}
      {/*      {high24h.value}*/}
      {/*    </Text>*/}
      {/*  </Flex.Item>*/}
      {/*  <Flex.Item minWidth="max">*/}
      {/*    <Text color="neutral-700" size="xs" weight="regular">*/}
      {/*      {t('market.low-24h')}*/}
      {/*    </Text>*/}
      {/*    <Text color="secondary-500" size="sm" weight="medium">*/}
      {/*      {low24h.value}*/}
      {/*    </Text>*/}
      {/*  </Flex.Item>*/}
      {/*  <Flex.Item minWidth="max">*/}
      {/*    <Text color="neutral-700" size="xs" weight="regular">*/}
      {/*      {t('market.avg-24h')}*/}
      {/*    </Text>*/}
      {/*    <Text color="secondary-500" size="sm" weight="medium">*/}
      {/*      {avg24h.value}*/}
      {/*    </Text>*/}
      {/*  </Flex.Item>*/}
      {/*</Flex>*/}
    </>
  );
};

export default withIntrinsicHidden(AssetCurrentStatisticValues);

import { useCallback, useEffect, useRef } from 'react';

import { notification } from '@bilira-org/design';

type NotifyState = {
  /** The loading message */
  loading: string;
  /** The success message */
  success: string;
  /** The error message */
  error: string;
};

type UseNotifyProps = {
  /** Indicates whether the promise is a success. */
  isSuccess: boolean;
  /** Indicates whether the promise is an error. */
  isError: boolean;
  /** The error message */
  errorMsg?: string;
  /** The success message */
  successMsg?: string;
  /** Callback function to be executed when promise is resolved */
  onComplete?: () => void;
};

/**
 * Hook for handling async notifications based on success and error states of promise.
 *
 * @param {UseNotifyProps} props - The properties for the `useNotify` hook.
 * @returns {{ awaitNotify: (states: NotifyState) => Promise<boolean> }} - An object containing the `awaitNotify` function.
 */
const useNotify = ({ isSuccess, isError, errorMsg, successMsg, onComplete }: UseNotifyProps) => {
  const resolver = useRef<{ resolve: (value: boolean) => void; reject: (value: boolean) => void }>();

  useEffect(() => {
    if (isSuccess) {
      successMsg && notification.success(successMsg);
      onComplete && onComplete();

      resolver.current?.resolve(true);
    }

    if (isError) {
      errorMsg && notification.error(errorMsg);
      onComplete && onComplete();

      resolver.current?.reject(true);
    }
  }, [isSuccess, isError, successMsg, errorMsg]);

  const awaitNotify = useCallback(async (states: NotifyState) => {
    return notification.promise(
      new Promise((resolve: (value: boolean) => void, reject: (value: boolean) => void) => {
        resolver.current = { resolve, reject };
      }),
      states,
    );
  }, []);

  return { awaitNotify };
};

export default useNotify;

import { RefObject, useEffect } from 'react';

/**
 * Custom hook to manage rounded classes for the first and last visible cells
 * in table rows and header groups, based on a given `ref` and a `rounded` flag.
 *
 * @param {RefObject<HTMLTableElement>} ref - A reference to the table element containing the rows and headers.
 * @param {boolean} [rounded] - A flag indicating whether the rounded classes should be applied. If `true`, the classes will be applied to the first and last visible children in each row and header group.
 * @param {any[]} [dependencies=[]] - Optional additional dependencies that will trigger the effect when they change.
 *
 * @example
 * const tableRef = useRef<HTMLTableElement>(null);
 * useRoundedClasses(tableRef, true);
 *
 * @returns {void}
 */
function useRoundedClasses(
  ref: RefObject<HTMLTableElement>,
  rounded?: boolean,
  loading?: any,
  table?: any,
  isFetchingNextPage?: any,
) {
  useEffect(() => {
    const controller = new AbortController();

    if (rounded) {
      const handleResize = () => {
        if (ref.current) {
          const rows = ref.current.querySelectorAll('.table-row');
          const headers = ref.current.querySelectorAll('.header-group');

          toggleRoundedClasses(rows);
          toggleRoundedClasses(headers);
        }

        function toggleRoundedClasses(rows: NodeListOf<Element>) {
          rows.forEach((row) => {
            const children = Array.from(row.children);
            row.querySelector('.cell-rounded-first')?.classList.remove('cell-rounded-first');
            row.querySelector('.cell-rounded-last')?.classList.remove('cell-rounded-last');

            // Filter only the visible children
            const visibleChildren = children.filter((child) => {
              const style = window.getComputedStyle(child);
              return child.checkVisibility?.() ?? (style.display !== 'none' && style.contentVisibility !== 'hidden');
            });

            // Add the rounded classes to the first and last visible children
            if (visibleChildren.length > 0) {
              visibleChildren[0].classList.add('cell-rounded-first');
              visibleChildren[visibleChildren.length - 1].classList.add('cell-rounded-last');
            }
          });
        }
      };

      handleResize();

      window.addEventListener('resize', handleResize, {
        signal: controller.signal,
      });
    }

    return () => {
      controller.abort();
    };
  }, [rounded, ref, loading, table, isFetchingNextPage]);
}

export default useRoundedClasses;

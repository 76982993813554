import React, { useCallback, useEffect, useState } from 'react';

import { Block, Icon, Label, Modal, Panel, Text } from '@bilira-org/design';
import { CryptoAssetsType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import SymbolPair from '@Components/common/SymbolPair';
import Tokens from '@Components/cryptoCards/Tokens';
import { useWalletStore } from '@Components/walletAddress/components/store/walletStore';
import useSelectAssetBySearchParam from '@Libs/hooks/useSelectAssetBySearchParam';
import PopularCryptoButtonList from '@Modules/crypto/components/PopularCryptoButtonList';

type Props = {
  callback: (asset: CryptoAssetsType) => void;
};
const SelectCryptoStep = ({ callback }: Props) => {
  const [modalAssetActive, setModalAssetActive] = useState(false);
  const { asset } = useSelectAssetBySearchParam();
  const { setSelectedNetwork } = useWalletStore();

  const [activeAsset, setActiveAsset] = useState<CryptoAssetsType>();
  const { t } = useTranslation();

  const setAssetCallback = useCallback((asset: CryptoAssetsType) => {
    setSelectedNetwork(undefined);
    callback(asset);
    setActiveAsset(asset);
  }, []);

  useEffect(() => {
    asset && setAssetCallback(asset);
  }, [asset, setAssetCallback]);

  return (
    <>
      <>
        <Text size="lg" weight="medium" mb="2xl" color="secondary-500">
          {t('crypto.crypto-selection-step')}
        </Text>
        <Label>{t('crypto.select-crypto')}</Label>
        <Panel
          mb="sm"
          size="md"
          ring="neutral-400"
          onClick={() => setModalAssetActive(true)}
          testId="select-crypto-panel"
        >
          <Block row gap="md" items="center" justify="between">
            {activeAsset && (
              <SymbolPair icon={activeAsset.symbol} title={activeAsset.symbol} description={activeAsset.name} />
            )}
            {!activeAsset && (
              <Text size="sm" color="neutral-500">
                {t('crypto.select-crypto-placeholder')}
              </Text>
            )}

            <Icon size="lg" color="neutral-600" type="s:chevron-down" />
          </Block>
        </Panel>

        {!activeAsset && <PopularCryptoButtonList callback={setAssetCallback} />}
      </>

      <Modal
        title={t('crypto.select-crypto-modal-title')}
        open={modalAssetActive}
        onClose={() => setModalAssetActive(false)}
        testId="select-crypto-modal"
      >
        <Tokens
          onFinish={() => {
            setModalAssetActive(false);
          }}
          setAsset={setAssetCallback}
        />
      </Modal>
    </>
  );
};

export default SelectCryptoStep;

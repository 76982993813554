import React from 'react';

import { LayoutSkeleton } from '@bilira-org/design/root';

const AppSkeleton = () => {
  const pathName = document.location.pathname;

  if (pathName === '/') {
    return <LayoutSkeleton />;
  }

  return null;
};

export default AppSkeleton;

import { useRef } from 'react';

import { LastPriceType } from '@bilira-org/react-utils/src/apis/price';

import PriceQuery from '@Libs/clientInstances/priceQuery';
import { usePriceStore } from '@Store/priceStore';

type Props = {
  symbol: string;
  enabled?: boolean;
};
const useLastLivePrice = ({ symbol }: Props) => {
  const priceRef = useRef<LastPriceType | null>(null);

  const liveData = usePriceStore((state) => {
    const currentPrice = state.lastPrice?.s === symbol ? state.lastPrice : null;
    const refPrice = priceRef.current?.s === symbol ? priceRef.current : null;

    return currentPrice ?? refPrice;
  });

  const { data } = PriceQuery.useGetAllPrices();

  priceRef.current = liveData;

  let priceData = liveData;
  if (!priceData) {
    priceData = data?.find((d) => d.s === symbol) || null;
  }

  return {
    liveData: priceData,
  };
};

export default useLastLivePrice;

import React, { memo } from 'react';

import { Block, Skeleton, Span, Text } from '@bilira-org/design';
import { CoinSymbolType, NetworkAliasType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import cryptoQuery from '@Libs/clientInstances/cryptoQuery';
import useGetMinCryptoDepositLimit from '@Libs/hooks/useGetMinCryptoDepositLimit';

type Props = { network?: NetworkAliasType; asset?: CoinSymbolType };

const Limits = ({ network, asset }: Props) => {
  const { t } = useTranslation();
  const isNetworkAndAssetPresent = !!(network && asset);

  const { data: networks, isLoading } = cryptoQuery.useGetNetworks();
  const networkDetails = networks?.find((item) => item.alias === network);
  const { minDepositLimit: minAllowancePerTransaction, isPending } = useGetMinCryptoDepositLimit({
    asset: asset,
    networkAlias: network,
  });

  if (!isNetworkAndAssetPresent) {
    return <LimitsInvalid />;
  }

  if (isLoading || isPending) {
    return <LimitsSkeleton />;
  }

  return (
    <Block gap="sm">
      <Block row justify="between">
        <Text size="sm" color="neutral-700">
          {t('crypto.deposit.min-deposit-amount')}
        </Text>
        <Text size="sm" weight="medium" color="neutral-700">
          {minAllowancePerTransaction} {asset}
        </Text>
      </Block>
      <Block row justify="between">
        <Text size="sm" color="neutral-700">
          {t('crypto.deposit.deposit-confirmation-count')}
        </Text>
        <Text size="sm" weight="medium" color="neutral-700">
          <Span weight="bold">{networkDetails?.confirmations || '-'}</Span> {t('crypto.deposit.confirmation')}
        </Text>
      </Block>
    </Block>
  );
};

export default Limits;

const LimitsSkeleton = () => {
  return (
    <Block gap="sm">
      <Block row justify="between">
        <Skeleton height="size-5" width="size-24" />
        <Skeleton height="size-5" width="size-14" />
      </Block>
      <Block row justify="between">
        <Skeleton height="size-5" width="size-24" />
        <Skeleton height="size-5" width="size-14" />
      </Block>
    </Block>
  );
};

const LimitsInvalid = memo(() => {
  const { t } = useTranslation();
  return (
    <Block gap="sm">
      <Block row justify="between">
        <Text size="sm" color="neutral-700">
          {t('crypto.deposit.min-deposit-amount')}
        </Text>
        <Text size="sm" weight="medium" color="neutral-700">
          -
        </Text>
      </Block>
      <Block row justify="between">
        <Text size="sm" color="neutral-700">
          {t('crypto.deposit.deposit-confirmation-count')}
        </Text>
        <Text size="sm" weight="medium" color="neutral-700">
          <Span weight="bold">-</Span>
        </Text>
      </Block>
    </Block>
  );
});

import React, { forwardRef, ReactNode, useState } from 'react';

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { clsx } from 'clsx';

import { BaseComponentType } from '../types';

type HeaderProps = {
  /** The name of the tab header. */
  name: string;
  /** The unique key for the tab header. */
  key: string;
  /** Whether the tab header is hidden. */
  hidden?: boolean;
  /** Whether the tab header is disabled. */
  disabled?: boolean;
  /**Card top border color*/
  borderColor: 'red' | 'green';
};

export type TabCardProps = BaseComponentType & {
  headers: HeaderProps[];
  /** The content of the panel. */
  children: ReactNode | React.ReactElement;
  /** Whether to center the content within the panel. */
  center?: boolean;
  /** Callback function triggered when the selected tab changes. */
  onChange?: ((key: string, index: number) => void) | undefined;
};

function TabCard({ children, headers, onChange }: TabCardProps) {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  return (
    <TabGroup as="div" className="tab-card w-full" onChange={(index: number) => onChange?.(headers[index].key, index)}>
      <TabList className="tab-card-list">
        {headers.map(({ name, key, hidden, borderColor }, i) => (
          <Tab
            key={key}
            onClick={() => setSelectedTab(i)}
            className={clsx('tab-card-head', `tab-card-head-${borderColor}`, { hidden: hidden })}
            {...(selectedTab === i ? { 'data-selected': '' } : {})}
          >
            {name}
          </Tab>
        ))}
      </TabList>
      <TabPanels className="mt-0">{children}</TabPanels>
    </TabGroup>
  );
}

export type TabCardPanelProps = BaseComponentType & {
  /** The content of the panel. */
  children: ReactNode | React.ReactElement;
};

/**
 * Tab panels to be used within `Tab` component
 *
 * @example
 * <Panel center>
 *   Content
 * </Panel>
 */
const Panel = forwardRef<any, TabCardPanelProps>(
  ({ children, testId }: TabCardPanelProps, ref: React.ForwardedRef<any>) => {
    return (
      <TabPanel ref={ref} className="tab-card-panel" data-testid={testId}>
        {children}
      </TabPanel>
    );
  },
);

TabCard.displayName = 'TabCard';

export default Object.assign(TabCard, { Panel });

import { useMemo } from 'react';

import { useSessionStore } from '@/store';
import ConfigQuery from '@Libs/clientInstances/configQuery';

import { REMOTE_CONFIG_KEYS } from '../constants';

export interface UseRemoteConfigProps {
  key?: (typeof REMOTE_CONFIG_KEYS)[keyof typeof REMOTE_CONFIG_KEYS];
}

const useRemoteConfig = <T>({ key }: UseRemoteConfigProps = {}) => {
  const { user, isLoggedIn } = useSessionStore();

  const {
    data: remoteConfigRecords = [],
    isError,
    isPending,
  } = ConfigQuery.useGetRemoteConfig({ user: user?.id }, { enabled: isLoggedIn });

  const result = useMemo(() => {
    if (isError || !remoteConfigRecords || !key) {
      return { value: null, isEnabled: false };
    }

    const config = remoteConfigRecords.find((record) => record.type.name === key);
    return { value: (config?.value as T) ?? null, isEnabled: Boolean(config?.value) };
  }, [key, remoteConfigRecords, isError]);

  return { data: result, isError, isPending };
};

export default useRemoteConfig;

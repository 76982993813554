import React from 'react';

import { clsx } from 'clsx';

import { TextColorType } from '../types';

export type BreadcrumbsItemProps = React.LiHTMLAttributes<HTMLLIElement> & {
  /** href for the link */
  href?: string;
  /** target for the link */
  target?: string;
  /** Text color */
  color?: TextColorType;
  /** Additional class name */
  className?: string;
  /** Flex shrink property of the breadcrumbs item */
  shrink?: '1' | '0';
};

/**
 * Styled Breadcrumbs Item to be used inside BreadCrumbs component.
 *
 * Wraps anchor tag to the children if href is provided.
 *
 * @example
 * <BreadcrumbsItem href="/">Home</BreadcrumbsItem>
 *
 * <BreadcrumbsItem>
 *   <CustomLink href="/about">About</CustomLink>
 * </BreadcrumbsItem>
 *
 */
const BreadcrumbsItem = React.forwardRef<HTMLLIElement, BreadcrumbsItemProps>(
  ({ children, color, className, href, target, shrink, ...props }, ref): JSX.Element => {
    const classes = clsx('breadcrumbs', 'text-sm', className, {
      [`tw-text-${color}`]: color,
      [`tw-shrink-${shrink}`]: shrink,
    });

    return (
      <li role="link" className={classes} {...props} ref={ref}>
        {href ? (
          <a href={href} target={target}>
            {children}
          </a>
        ) : (
          <>{children}</>
        )}
      </li>
    );
  },
);

export default BreadcrumbsItem;

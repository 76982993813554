import React from 'react';

import { Link } from 'react-router-dom';

import { MenuItemWithoutDropdownProps } from '../../types';

export const HorizontalMenuItemWithoutDropdown = (props: MenuItemWithoutDropdownProps) => {
  return (
    <li key={props.href}>
      <Link to={props.href} onClick={() => props.onClick?.(props)} target={props.target}>
        <span>{props.text}</span>
      </Link>
    </li>
  );
};

import React, { ReactElement, useEffect, useRef } from 'react';

import { clsx } from 'clsx';

import { BaseComponentType, BorderColorType, JustifyType, MarginSizes, SizeType } from '../types';

interface ISeparator extends BaseComponentType {
  /** The color of the separator */
  color?: BorderColorType;
  /** Margin on the y-axis  */
  my?: MarginSizes;
  /** Margin on the x-axis  */
  mx?: MarginSizes;
  /** Margin on the top  */
  mt?: MarginSizes;
  /** Margin on the bottom  */
  mb?: MarginSizes;
  /** The width of the separator, for horizontal separators
   * @note Either set width or height, not both
   */
  width?: SizeType;
  /** The height of the separator, for vertical separators
   * @note Either set width or height, not both
   */
  height?: SizeType;
  /** Children to be rendered in the center of the separator */
  children?: ReactElement;
  /** Justify the children */
  justifyChildren?: JustifyType;
}

/**
 * Separator component that can be used to add a border between sections.
 *
 * @example
 * <div>Hello</div>
 * <Separator width="size-full" color="neutral-400" />
 * <div>World</div>
 */
const Separator = ({
  my,
  mx,
  mt,
  mb,
  width,
  height,
  color = 'neutral-400',
  children,
  justifyChildren,
  testId,
}: ISeparator) => {
  const childRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (children && childRef.current) {
      const childHeight = childRef.current.offsetHeight;
      const topPosition = Math.floor(childHeight / 2);
      childRef.current.style.top = `${-topPosition}px`;
    }
  }, [children]);

  return (
    <div
      role="separator"
      className={clsx('separator inset-1', {
        relative: children,
        'border-t': width,
        'border-l': height,
        [`tw-h-${height}`]: height,
        [`flex tw-justify-${justifyChildren}`]: justifyChildren,
        [`tw-w-${width}`]: width,
        [`tw-border-${color}`]: color,
        [`my-${my}`]: my,
        [`mx-${mx}`]: mx,
        [`mt-${mt}`]: mt,
        [`mb-${mb}`]: mb,
      })}
      data-testid={testId ?? 'separator'}
    >
      {children && (
        <div ref={childRef} className="tw-bg-theme-wn absolute">
          {children}
        </div>
      )}
    </div>
  );
};

Separator.displayName = 'Separator';

export default Separator;

import React from 'react';

import { Pagination, Separator } from '@bilira-org/design';

import ReadAllNotificationBar from '@Components/layouts/components/components/components/ReadAllNotificationBar';
import NotificationList from '@Components/notifications/NotificationList';
import UserQuery from '@Libs/clientInstances/userQuery';
import { LIMIT } from '@Libs/config';

const NotificationContainer = () => {
  const [offset, setOffset] = React.useState(0);

  const { data: { records: data = [], _metadata: metadata, unread_count: unreadCount } = {} } =
    UserQuery.useGetNotifications({
      limit: LIMIT,
      offset,
    });

  return (
    <>
      <ReadAllNotificationBar unreadCount={unreadCount} />
      <Separator width="size-auto" mx="md" my="md" />
      <NotificationList data={data} />
      <Separator my="sm" width="size-full" />
      <Pagination
        onPageChange={(page) => setOffset(page * LIMIT - LIMIT)}
        pagination={{
          page: metadata?.page,
          pageCount: metadata?.page_count,
          perPage: metadata?.per_page,
          totalCount: metadata?.total_count,
        }}
      />
    </>
  );
};

export default NotificationContainer;

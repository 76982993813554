import React from 'react';

import { Drawer, List, Separator, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { withPrivateRoute } from '@/libs/hocs';
import { useGeneralStore } from '@/store/generalStore';
import UserKycCard from '@Components/layouts/components/components/components/UserKycCard';
import HeaderNavBar from '@Components/layouts/components/HeaderNavBar';
import HeaderNavBarSocial from '@Components/layouts/components/HeaderNavBarSocial';

/**
 * Component for displaying a mobile menu drawer for authenticated users
 * containing user-related options and navigation links.
 */
const PrivateMobilMenuDrawer = () => {
  const { t } = useTranslation();
  const { privateDrawerOpen, setPrivateDrawerOpen } = useGeneralStore();
  const setLatelyModalActive = useGeneralStore((state) => state.setLatelyModalActive);

  const navigate = useNavigate();

  const redirect = (href: string) => {
    navigate(href);
    setPrivateDrawerOpen(false);
  };

  return (
    <Drawer open={privateDrawerOpen} onClose={() => setPrivateDrawerOpen(false)}>
      <UserKycCard />
      <Separator width="size-full" mt="md" mb="xs" />
      <HeaderNavBar
        onClick={(item) => {
          if (item.href === '#new') {
            setLatelyModalActive(true);
          }
          setPrivateDrawerOpen(false);
        }}
        vertical
        hiddenProps={{ show: { xs: true, sm: true, md: true } }}
      />
      <Separator width="size-full" my="xs" />
      <HeaderNavBarSocial />
      <List spaceY="xs">
        <Separator width="size-full" color="neutral-400" />
        <List.Item px="md" py="md" hoverBgColor="neutral-300" onClick={() => redirect('/logout')}>
          <Text size="sm" color="red-500" weight="medium">
            {t('navigation.profile.logout')}
          </Text>
        </List.Item>
      </List>
    </Drawer>
  );
};

export default withPrivateRoute(PrivateMobilMenuDrawer);

import React, { memo, useCallback, useRef, useState } from 'react';

import { Block, Button } from '@bilira-org/design';
import { getErrorMessage } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import AuthenticatorOTP from '@Components/TwoFA/components/AuthenticatorOTP';
import UserQuery from '@Libs/clientInstances/userQuery';
import { OTP_CODE_LENGTH } from '@Libs/config';

type Props = {
  callback: () => void;
};

const TwoFAAuthenticator = ({ callback }: Props) => {
  const { mutateAsync, failureReason } = UserQuery.usePostActivateTOTP();
  const { t } = useTranslation();
  const [codeCompleted, setCodeCompleted] = useState(false);
  const twoFAItemRef = useRef<string>('');

  const handleValidation = () => {
    mutateAsync({
      code: twoFAItemRef.current,
    }).then(callback);
  };

  const inputCallback = useCallback((value: string) => {
    twoFAItemRef.current = value;

    const completed = twoFAItemRef.current.length === OTP_CODE_LENGTH;

    setCodeCompleted(completed);
  }, []);

  const onEnter = codeCompleted ? handleValidation : undefined;

  return (
    <>
      <Block gap="md">
        <Block>
          <AuthenticatorOTP
            validationMessage={getErrorMessage(failureReason) as string}
            callback={inputCallback}
            onEnter={onEnter}
          />
        </Block>

        <Button
          mt="4xl"
          disabled={!codeCompleted}
          size="md"
          stretch
          justify="center"
          variant="filled"
          onClick={handleValidation}
        >
          {t('common.accept')}
        </Button>
      </Block>
    </>
  );
};
export default memo(TwoFAAuthenticator);

import React, { memo, useState } from 'react';

import { Block, Icon, Meta, Panel, Separator, Typography } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { CreateBankAccountModal } from '@Components/bank/bankAccount';

const { Text } = Typography;

function Index() {
  const { t } = useTranslation();
  const [modalActive, setModalActive] = useState(false);

  return (
    <>
      <Panel
        size="lg"
        color="theme-wb"
        outline="neutral-400"
        hover="outline"
        minimize
        onClick={() => setModalActive(true)}
      >
        <Block row items="center" gap="md">
          <Icon size="lg" type="o:building-library" color="secondary-500" />
          <Separator height="size-3" color="neutral-400" />

          <Meta
            title={
              <Text size="sm" weight="medium" color="secondary-500">
                {t('account.saved-bank-accounts')}
              </Text>
            }
          />
        </Block>
      </Panel>
      <CreateBankAccountModal modalActive={modalActive} setModalActive={setModalActive} />
    </>
  );
}

export default memo(Index);

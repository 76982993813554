import { extractBankCode } from '../extractBankCode';

const BLACKLISTED_BANK_CODES = [
  '00829', // papara
  '00869', // paycell
];

export const isIbanFromBlacklistedBank = (iban: string) => {
  return BLACKLISTED_BANK_CODES.includes(extractBankCode(iban));
};

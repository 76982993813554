import React from 'react';

import { Block, Meta, Panel, Text } from '@bilira-org/design';
import { AssetPairPriceType, OTCTransactionType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import OTCCommissionPanel from '@/modules/otc/components/OTCCommissionPanel';
import AssetUnitPriceInfo from '@Components/trade/components/AssetUnitPriceInfo';

import SwapInfo from '../SwapInfo';

export type Props = {
  data: OTCTransactionType;
};

export const OTCTransferType = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <Meta
      title={<Text size="sm">{t('market.swap.buy-amount')}</Text>}
      description={
        <Panel size="none">
          <SwapInfo
            fromAmount={data.from_amount}
            fromAsset={data.from_asset}
            toAmount={data.to_amount}
            toAsset={data.to_asset}
            pairPrice={data.pair_price}
            side={data.side}
          />
          <Block row gap="xs" items="center" mt="lg">
            <AssetUnitPriceInfo
              weight="bold"
              justify="end"
              iconType="o:exclaimation-circle"
              amount={data.to_amount}
              assetPairPrice={
                {
                  price: data.from_amount,
                  quote_asset: data.from_asset,
                  base_asset: data.to_asset,
                } as AssetPairPriceType
              }
            />
          </Block>
          <Block mt="md">
            <OTCCommissionPanel entry={data} />
          </Block>
        </Panel>
      }
    />
  );
};

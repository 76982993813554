import React from 'react';

import { Display } from '@bilira-org/design';
import { RewardsTasksType, RewardTaskState, UserParticipationType } from '@bilira-org/react-utils';
import { Trans, useTranslation } from 'react-i18next';

import TaskExpiresAt from '../TaskExpiresAt';

const showExpiresAtStates: RewardTaskState[] = ['recurringActive', 'recurringCoolDown'];
const showInfiniteStates: RewardTaskState[] = ['toDo', 'recurringToDo'];

type Props = {
  task: UserParticipationType | RewardsTasksType;
  taskState: RewardTaskState;
};

const RewardTaskDateDescription = ({ task, taskState }: Props) => {
  const { t } = useTranslation();

  const showExpiresAt = showExpiresAtStates.includes(taskState);
  const showInfinite = showInfiniteStates.includes(taskState);

  return (
    <>
      <Display show={showExpiresAt}>
        <Trans
          t={t}
          i18nKey="rewards.detail.date.renew-after"
          components={{
            timer: <TaskExpiresAt size="sm" color="primary-500" task={task} format="humanized" as="span" />,
          }}
        />
      </Display>

      <Display show={!showExpiresAt}>
        {showInfinite ? t('rewards.detail.date.infinite') : t('rewards.detail.date.completed')}
      </Display>
    </>
  );
};

export default RewardTaskDateDescription;

import React from 'react';

import { LayoutV2 } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import HeadTags from '@/components/common/HeadTags';
import MarketContainer from '@Modules/market/market/MarketContainer';

const { Content, InnerContent } = LayoutV2;

const Index = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeadTags title={t('navigation.market')} titleSuffix="subpage" />
      {/*<Header />*/}

      <Content>
        <InnerContent>
          <MarketContainer />
        </InnerContent>
      </Content>
    </>
  );
};

export default Index;

import { IAlertApi } from './interface';
import { HttpClient, HttpClientType, HttpErrorType } from '../../client';
import { AlertsType, AlertType, GetAlertsParams, PostAlertBody, PostAlertResponseType } from '../../model';

class AlertApi extends HttpClient implements IAlertApi {
  public constructor(conf: HttpClientType) {
    super(conf);
  }

  public getAlerts = async (params: GetAlertsParams) =>
    await this.instance.get<HttpErrorType, AlertsType>('/alerts', { params });

  public postAlert = async (body: PostAlertBody) =>
    await this.instance.post<HttpErrorType, PostAlertResponseType>('/alerts', body);

  public getAlert = async (id: string) => await this.instance.get<HttpErrorType, AlertType>(`/alerts/${id}`);

  public deleteAlert = async (id: string) => await this.instance.delete<HttpErrorType, undefined>(`/alerts/${id}`);
}

export default AlertApi;

/**
 * Checks if a value is empty or undefined.
 *
 * @template T - The type of the value.
 * @param {T} obj - The value to be checked.
 * @returns {boolean} - `true` if the value is empty or undefined, `false` otherwise.
 *
 * @example
 * isEmpty(''); // true
 * isEmpty({}); // true
 * isEmpty([]); // true
 * isEmpty(undefined); // true
 * isEmpty('something'); // false
 */
export function isEmpty<T>(obj: T) {
  if (typeof obj === 'undefined' || obj === null) {
    return true;
  }

  if (typeof obj === 'string') {
    return !obj || obj === '';
  }

  if (!obj || typeof obj !== 'object') {
    return undefined;
  }

  if (Array.isArray(obj)) {
    return obj.length === 0;
  }

  return Object.keys(obj).length === 0;
}

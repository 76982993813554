import { detectBrowser } from '@bilira-org/react-utils';

/**
 * Fixes viewport issues on iOS browsers when virtual keyboard opens by
 * Preventing scrolling behavior when focusing on input elements.
 * See https://gist.github.com/kiding/72721a0553fa93198ae2bb6eefaa3299
 * @param target: Input element
 *
 * @example
 * ...
 * onFocus={(e) => fixIosInputFocus(e.currentTarget)}
 * ...
 */
const fixIosInputFocus = (target: HTMLElement) => {
  if (detectBrowser().isIOS) {
    target.style.opacity = '0';
    setTimeout(() => {
      target.style.opacity = '1';
    }, 100);
  }
};

export default fixIosInputFocus;

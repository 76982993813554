import { RefObject, useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';

/**
 * Hook for automatically scrolling to the top of a specified element when the location changes.
 *
 * @returns {RefObject<HTMLDivElement>} - A ref object for the target element.
 */
const useScrollRestoration = (): RefObject<HTMLDivElement> => {
  const location = useLocation();
  const mainRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Scroll to the top of the target element when the location changes.

    const element = mainRef.current;

    element?.scrollTo(0, 0);
  }, [location.pathname]);

  return mainRef;
};

export default useScrollRestoration;

import React, { useState } from 'react';

import { Alert, Block, Button, Flex, Link, Panel, StepProgress, Text } from '@bilira-org/design';
import { Trans, useTranslation } from 'react-i18next';

import { IconCustom } from '@Components/icon/CustomIcon';

import OTCApplicationModal from './OTCApplicationModal';

const DefaultContent = () => {
  const { t } = useTranslation();
  const [activeApplicationForm, setActiveApplicationForm] = useState<'individual' | 'institutional' | undefined>();
  return (
    <>
      <Block gap="4xl">
        <Panel size="6xl" border="neutral-400">
          <Flex direction={{ xs: 'col', sm: 'col', md: 'row' }} justify="between" items="center">
            <Flex.Item flex gap="sm" maxWidth="xl">
              <Text heading size="xl" weight="bold">
                {t('otc.cards.definition.title')}
              </Text>
              <Text size="sm" weight="regular">
                {t('otc.cards.definition.description')}
              </Text>
            </Flex.Item>
            <Flex.Item>
              <IconCustom type="graph-box" />
            </Flex.Item>
          </Flex>
        </Panel>

        <Panel size="6xl" border="neutral-400" color="neutral-200">
          <Text heading size="xl" weight="bold" mb="sm">
            {t('otc.cards.contact.title')}
          </Text>

          <Text size="sm" weight="regular" mb="2xl">
            <Trans
              t={t}
              i18nKey="otc.cards.contact.description"
              components={{ anchor: <Link to="kyc@bilira.co" color="primary-500" /> }}
            />
          </Text>
          <Flex direction={{ xs: 'col', sm: 'row' }} gap="md">
            <Button
              size="sm"
              flexType={{ xs: '1', sm: 'none' }}
              variant="filled"
              onClick={() => setActiveApplicationForm('individual')}
            >
              {t('otc.cards.contact.individual-otc-application')}
            </Button>
            <Button
              size="sm"
              flexType={{ xs: '1', sm: 'none' }}
              variant="outline"
              onClick={() => setActiveApplicationForm('institutional')}
            >
              {t('otc.cards.contact.institutional-otc-application')}
            </Button>
          </Flex>
        </Panel>

        <Panel size="6xl" border="neutral-400">
          <Text heading size="xl" weight="bold" mb="2xl">
            {t('otc.cards.steps.title')}
          </Text>
          <StepProgress
            variant="points"
            responsive
            size="xl"
            color="white"
            steps={[
              {
                icon: '01',
                title: (
                  <Flex minHeight="full" justify={{ xs: 'center', sm: 'center', lg: 'start' }}>
                    <Text size="sm"> {t('otc.cards.steps.step1')}</Text>
                  </Flex>
                ),
              },
              {
                icon: '02',
                title: (
                  <Flex minHeight="full" justify={{ xs: 'center', sm: 'center', lg: 'start' }}>
                    <Text size="sm"> {t('otc.cards.steps.step2')}</Text>
                  </Flex>
                ),
              },
              {
                icon: '03',
                title: (
                  <Flex minHeight="full" justify={{ xs: 'center', sm: 'center', lg: 'start' }}>
                    <Text size="sm"> {t('otc.cards.steps.step3')}</Text>
                  </Flex>
                ),
              },
              {
                icon: '04',
                title: (
                  <Flex minHeight="full" justify={{ xs: 'center', sm: 'center', lg: 'start' }}>
                    <Text size="sm"> {t('otc.cards.steps.step4')}</Text>
                  </Flex>
                ),
              },
            ]}
          />
        </Panel>

        <Alert status="yellow">
          <Text heading size="sm" weight="bold" mb="sm">
            {t('otc.cards.terms.title')}
          </Text>

          <ul style={{ marginLeft: '18px' }}>
            <li>
              <Text size="sm">{t('otc.cards.terms.items.1')}</Text>
            </li>
            <li>
              <Text size="sm">{t('otc.cards.terms.items.2')}</Text>
            </li>
            <li>
              <Text size="sm">{t('otc.cards.terms.items.3')}</Text>
            </li>
          </ul>
        </Alert>
      </Block>
      <OTCApplicationModal activeForm={activeApplicationForm} onClose={() => setActiveApplicationForm(undefined)} />
    </>
  );
};
export default DefaultContent;

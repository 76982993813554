import React from 'react';

import { Block, Button, Col, Form, Grid, Input } from '@bilira-org/design';
import Radio from '@bilira-org/design/src/components/radio/radio';
import { useTranslation } from 'react-i18next';

import {
  buildOptions,
  DataType,
  PersonalInfoType,
} from '@Modules/account/kyc/components/components/Kyc3Panel/helpers/constants';

type Props = {
  data?: PersonalInfoType;
  callback: (value: PersonalInfoType) => void;
};

function PersonalInfoStep1({ data, callback }: Props) {
  const { t } = useTranslation();

  const schoolData = t('account.id-verification.kyc-3.personal-info.education-list', {
    returnObjects: true,
  }) as string[];

  return (
    <>
      <Form onFinish={callback} initialValues={data}>
        <Grid>
          <Col>
            <Form.Field
              rules={{ required: t('common.validation.required-field') }}
              label={t('account.id-verification.kyc-3.personal-info.profession')}
              name="profession"
            >
              <Input placeholder={t('account.id-verification.kyc-3.personal-info.profession-placeholder')} />
            </Form.Field>
          </Col>
          <Col>
            <Form.Field
              rules={{ required: t('common.validation.required-field') }}
              label={t('account.id-verification.kyc-3.personal-info.profession-experience')}
              name="experienceYear"
            >
              <Input
                placeholder={t('account.id-verification.kyc-3.personal-info.profession-experience-placeholder')}
                name="experienceYear"
              />
            </Form.Field>
          </Col>
          <Col>
            <Form.Field
              rules={{ required: t('common.validation.required-field') }}
              controlled
              label={t('account.id-verification.kyc-3.personal-info.education-level')}
              name="school"
            >
              {({ field }) => (
                <Block gap="lg" mt="lg">
                  <Radio<DataType>
                    vertical
                    selected={field.value}
                    setSelected={field.onChange}
                    dataSource={buildOptions(schoolData, 'school')}
                  />
                  {field.value?.id === 'other' && (
                    <Input
                      placeholder={t('account.id-verification.kyc-3.personal-info.education-level-placeholder')}
                      onChange={(value) => {
                        field.onChange({ id: 'other', label: value.target.value });
                      }}
                    />
                  )}
                </Block>
              )}
            </Form.Field>
          </Col>
          <Col>
            <Button variant="filled" justify="center" size="xl" mt="lg" type="submit" stretch>
              {t('common.continue')}
            </Button>
          </Col>
        </Grid>
      </Form>
    </>
  );
}

export default PersonalInfoStep1;

import { useCallback, useMemo } from 'react';

import {
  AssetListParamType,
  AssetListType,
  CoinSymbolType,
  concatTicker,
  SortOrderType,
} from '@bilira-org/react-utils';

import PriceQuery from '@Libs/clientInstances/priceQuery';
import useNavigateToMarket from '@Libs/hooks/useNavigateToMarket';

import { customTagsParamMapping } from './constants';

export type UseAssetCardsHookProps = {
  dataTag?: string;
  quote?: CoinSymbolType;
  sort?: {
    field: AssetListParamType['sort'];
    direction: SortOrderType;
  };
};

const useAssetCardsHook = ({ dataTag, quote = 'TRYB' }: UseAssetCardsHookProps) => {
  const navigate = useNavigateToMarket();

  const sortingParams = dataTag ? customTagsParamMapping[dataTag] : undefined;

  const {
    pages: assetList,
    fetchNextPage,
    isPending,
  } = PriceQuery.useGetAssetList({
    quote_asset: quote,
    tags: sortingParams === undefined ? dataTag : undefined,
    limit: 10,
    ...sortingParams,
  });

  const callback = useCallback((item: AssetListType) => {
    navigate({ base: item.base_asset, quote: item.quote_asset });
  }, []);

  const subscriptionList = useMemo(() => assetList.map((d) => concatTicker(d.base_asset, d.quote_asset)), [assetList]);

  return {
    assetList: assetList,
    isPending: isPending,
    callback,
    subscriptionList,
    fetchNextPage,
  };
};

export default useAssetCardsHook;

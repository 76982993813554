import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import RedirectSpinCard from '@/components/common/RedirectSpinCard';

const UserDeclined = () => {
  const { t } = useTranslation();
  useEffect(() => {
    setTimeout(() => {
      window.location.replace('/');
    }, 1000);
  }, []);

  return <RedirectSpinCard title={t('redirect.problem-occurred')} description={t('redirect.redirecting')} />;
};

export default UserDeclined;

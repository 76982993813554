import React from 'react';

import { clsx } from 'clsx';

import { IModal } from './modal';
import ModalTitle from './title';
import ButtonClose from '../button/buttonClose';
import { Icon } from '../icons';
import { ResponsiveReturnType } from '../types';
import { buildResponsiveClass } from '../utils';

type Props = Pick<
  IModal,
  'title' | 'closable' | 'closeButton' | 'onPreviousClick' | 'hasPreviousButton' | 'titleSeparator' | 'titlePadding'
> & {
  titlePaddingClass?: ResponsiveReturnType;
  closeModal?: () => void;
};

const ModalHead = ({
  title,
  closable,
  closeButton,
  titlePadding,
  closeModal,
  onPreviousClick,
  hasPreviousButton,
  titleSeparator,
}: Props) => {
  let titlePaddingClass: ResponsiveReturnType = {};
  if (titlePadding) {
    titlePaddingClass = buildResponsiveClass(titlePadding, 'p-');
  }
  return (
    <>
      {title && (
        <div className={clsx('modal-title', titlePaddingClass, { 'title-separator': titleSeparator })}>
          {hasPreviousButton && (
            <p className="cursor-pointer pr-6" onClick={() => onPreviousClick?.()}>
              <Icon type="o:arrow-left" size="lg" color="secondary-500" />
            </p>
          )}
          {title && (typeof title === 'string' ? <ModalTitle>{title}</ModalTitle> : title)}
          {closable &&
            (closeButton ? (
              <button tabIndex={-1} className="bg-transparent border-0 ring-0" onClick={closeModal}>
                {closeButton}
              </button>
            ) : (
              <ButtonClose background="transparent" onClick={closeModal} />
            ))}
        </div>
      )}
      {!title && closable && (
        <div className="absolute right-5 z-30 top-5 flex gap-1">
          {closeButton ? (
            <button tabIndex={-1} className="bg-transparent border-0 ring-0" onClick={closeModal}>
              {closeButton}
            </button>
          ) : (
            <ButtonClose className="flex-none" onClick={closeModal} />
          )}
        </div>
      )}
    </>
  );
};

ModalHead.displayName = 'ModalHead';

export default ModalHead;

import React from 'react';

import { Badge, Block, Span, Text } from '@bilira-org/design';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

type Props = {
  value: number;
  unit: string;
};
const TimerUnit = ({ value, unit }: Props) => {
  return (
    <Badge transparent color="neutral-200" variant="square" bordered>
      <Block row gap="xs" items="baseline">
        <Text as="span" size="sm" weight="semibold" color="neutral-900">
          {value}
        </Text>
        <Span size="xs" weight="regular" color="neutral-700">
          {unit}
        </Span>
      </Block>
    </Badge>
  );
};

export default TimerUnit;

import { useMemo } from 'react';

import { LanguageUniversalType } from '@bilira-org/react-utils';
import { clsx } from 'clsx';
import dayjs, { Dayjs, ManipulateType } from 'dayjs';

import { CalendarType } from './calendar';
import { Col, Grid } from '../../grid';
import { Separator } from '../../separator';
import { Text } from '../../typography';
import { generateDateArray, getYearsInDecade } from '../helpers';

interface CalendarBodyProps {
  /** Locale of the calendar */
  locale: LanguageUniversalType;
  /** Callback to be called when a date is selected */
  onSelect?: (date: Dayjs) => void;
  /** Callback to be called when a date is hovered */
  onHover?: (date?: Dayjs) => void;
  /** Selected dates */
  selectedDates: [Dayjs, Dayjs];
  /** Highlighted dates */
  highlightedDates?: [Dayjs, Dayjs];
  /** Type of the calendar */
  type: CalendarType;
  /** Callback to be called when calendar type is changed */
  onTypeChange: (type: CalendarType) => void;
  /** Date of the calendar */
  calendarDate: Dayjs;
  /** Callback to be called when calendar date is changed */
  onCalendarDateChange: (date: Dayjs) => void;
  /** Date range limit */
  limit?: [number, ManipulateType];
}

const CalendarBody = ({
  calendarDate,
  locale,
  onSelect,
  onHover,
  selectedDates,
  highlightedDates,
  type,
  onTypeChange,
  onCalendarDateChange,
  limit,
}: CalendarBodyProps) => {
  const weekdays = useMemo(() => {
    const days = dayjs().locale(locale).localeData().weekdaysShort();
    return [...days.slice(1), days[0]];
  }, [locale]);

  const renderWeekdays = () => {
    return weekdays.map((weekday) => (
      <Text key={weekday} size="sm" color="neutral-800" align="center">
        {weekday}
      </Text>
    ));
  };

  const days = useMemo(() => generateDateArray(calendarDate.month(), calendarDate.year()), [calendarDate]);

  const allowedRange =
    selectedDates[0] && !selectedDates[1]
      ? [
          selectedDates[0].subtract(limit?.[0] as number, limit?.[1] as ManipulateType), // TODO: Is limit really optional? @sulhadin
          selectedDates[0].add(limit?.[0] as number, limit?.[1] as ManipulateType),
        ]
      : undefined;
  const isDateDisabled = (date: Dayjs) => {
    if (!allowedRange) {
      return false;
    } else if (date.isBefore(allowedRange[0]) || date.isAfter(allowedRange[1])) {
      return true;
    }
    return false;
  };
  const highlight = highlightedDates || selectedDates;
  const renderDays = () => {
    return days.map((day) => {
      const disabled = isDateDisabled(day.date);
      return (
        <button
          onClick={!disabled ? () => onSelect && onSelect(day.date) : undefined}
          onMouseEnter={!disabled ? () => onHover && onHover(day.date) : undefined}
          onMouseLeave={!disabled ? () => onHover && onHover() : undefined}
          disabled={disabled}
          key={day.date.toString()}
          className="calendar-btn-wrapper"
        >
          <div
            className={clsx('calendar-btn', {
              highlight:
                highlight &&
                highlight[0] &&
                highlight[1] &&
                day.date.isBetween(highlight[0], highlight[1], 'day', '[]'),
              'highlight-start': highlight && highlight[0] && day.date.isSame(highlight[0], 'day'),
              'highlight-end': highlight && highlight[1] && day.date.isSame(highlight[1], 'day'),
              offset: day.offset || disabled,
            })}
          >
            {day.date.date()}
          </div>
        </button>
      );
    });
  };

  const months: string[] = useMemo(() => dayjs().locale(locale).localeData().months(), [locale]);

  const renderMonths = () => {
    return months.map((month, index) => (
      <button
        key={month}
        className="calendar-btn calendar-btn-month"
        onClick={() => {
          onCalendarDateChange(calendarDate.month(index));
          onTypeChange('day');
        }}
      >
        {month}
      </button>
    ));
  };

  const years: number[] = useMemo(() => getYearsInDecade(calendarDate.year()), [calendarDate.year()]);
  const renderYears = () => {
    return years.map((year, index) => (
      <button
        key={year}
        className={clsx('calendar-btn calendar-btn-year', {
          offset: index === 0 || index === 11,
        })}
        onClick={() => {
          onCalendarDateChange(calendarDate.year(year));
          onTypeChange('day');
        }}
      >
        {year}
      </button>
    ));
  };

  const renderCalendarBody = () => {
    if (type === 'day') {
      return (
        <Grid col={7} gap="none" className="calendar">
          {renderWeekdays()}
          <Col span={7}>
            <Separator width="size-full" mt="md" />
          </Col>
          {renderDays()}
        </Grid>
      );
    } else if (type === 'month') {
      return (
        <Grid col={3} gap="none" className="calendar">
          {renderMonths()}
        </Grid>
      );
    } else if (type === 'year') {
      return (
        <Grid col={3} gap="none" className="calendar">
          {renderYears()}
        </Grid>
      );
    }
  };

  return renderCalendarBody();
};

export default CalendarBody;

import React, { memo, useMemo } from 'react';

import { Block } from '@bilira-org/design';
import { filterRewardTasksByActionType, RewardTaskActionType, sortRewardTasks } from '@bilira-org/react-utils';

import { REMOTE_CONFIG_KEYS } from '@/libs/constants';
import useRemoteConfig from '@/libs/hooks/useRemoteConfig';
import RewardsQuery from '@Libs/clientInstances/rewardsQuery';

import RewardTask from '../../components/RewardTask';

const TaskList = () => {
  const { data } = RewardsQuery.useGetUserParticipation();
  const {
    data: { isEnabled: isReferralEnabled },
  } = useRemoteConfig({ key: REMOTE_CONFIG_KEYS.REFERRAL });

  const filteredTasks = useMemo(() => {
    const hiddenActionTypes: Partial<Record<RewardTaskActionType, boolean>> = {
      referral: !isReferralEnabled,
      // Add more flags here as needed
    };

    return filterRewardTasksByActionType(data?.tasks, hiddenActionTypes);
  }, [data?.tasks, isReferralEnabled]);

  const sortedTasks = useMemo(() => sortRewardTasks(filteredTasks), [filteredTasks]);

  if (!data) {
    return null;
  }

  return (
    <>
      <Block gap="lg" mb="lg">
        {sortedTasks.map((task) => (
          <RewardTask task={task} key={task.id} color="theme-n200d800" />
        ))}
      </Block>
    </>
  );
};

export default memo(TaskList);

import { Badge, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

const OTCTag = () => {
  const { t } = useTranslation();
  return (
    <Badge size="md" variant="square" color="neutral-300" transparent bordered>
      <Badge size="xs" color="neutral-600" />
      <Text ml="xs" as="span" size="xs" weight="medium" color="neutral-700">
        {t('otc.transaction')}
      </Text>
    </Badge>
  );
};

export default OTCTag;

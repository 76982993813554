import React from 'react';

import { Button, withHidden } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

/**
 * Navigation button for OTC page
 */
const OTCButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Button
        id="navigation-otc"
        style={{ height: '40px' }}
        variant="outline"
        size="md"
        onClick={() => navigate('/otc')}
        testId="otc-navigation-btn"
      >
        {t('navigation.otc-portal')}
      </Button>
    </>
  );
};

export default withHidden(OTCButton);

import React from 'react';

import { TransactionsRecordType } from '@bilira-org/react-utils';

import TransactionDetail from '@Components/transaction/modal/components/components/TransactionDetail';

type Props = {
  data?: TransactionsRecordType;
};

function TransactionDetailModalBody({ data }: Props) {
  if (!data) {
    return null;
  }

  return <TransactionDetail data={data} />;
}

export default TransactionDetailModalBody;

import React from 'react';

import { Block, Panel, Separator, Text } from '@bilira-org/design';
import { AllowancesType, getAllowanceFields } from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';

import { AllowanceWidget } from '@Components/allowance';

type Props = {
  deposit?: AllowancesType | 'unlimited';
  withdrawal?: AllowancesType;
  depositTitle: string;
  withdrawalTitle: string;
  asset: string;
};

function AllowancesSummary({ deposit, withdrawal, depositTitle, withdrawalTitle, asset }: Props) {
  const { t } = useTranslation();
  const {
    dailyAllowed: depositDailyAllowed,
    dailyPercentage: depositDailyPercentage,
    dailyRemaining: depositDailyUsed,
    monthlyAllowed: depositMonthlyAllowed,
    monthlyPercentage: depositMonthlyPercentage,
    monthlyRemaining: depositMonthlyUsed,
  } = getAllowanceFields(deposit !== 'unlimited' ? deposit : undefined);

  const {
    dailyAllowed: withdrawalDailyAllowed,
    dailyPercentage: withdrawalDailyPercentage,
    dailyRemaining: withdrawalDailyUsed,
    monthlyAllowed: withdrawalMonthlyAllowed,
    monthlyPercentage: withdrawalMonthlyPercentage,
    monthlyRemaining: withdrawalMonthlyUsed,
  } = getAllowanceFields(withdrawal);

  return (
    <Block gap="4xl">
      <Panel border="neutral-400" size="lg">
        <Text size="xs" weight="medium" color="neutral-600" mb="lg">
          {depositTitle}
        </Text>

        <AllowanceWidget
          allowed={depositDailyAllowed.toString()}
          used={depositDailyUsed.toString()}
          asset={asset}
          percentage={BigNumber(depositDailyPercentage).toNumber()}
          period={t('account.account-limits-modal.daily')}
          unlimited={deposit === 'unlimited'}
        />
        <Separator width="size-full" my="md" />
        <AllowanceWidget
          allowed={depositMonthlyAllowed.toString()}
          used={depositMonthlyUsed.toString()}
          asset={asset}
          percentage={BigNumber(depositMonthlyPercentage).toNumber()}
          period={t('account.account-limits-modal.monthly')}
          unlimited={deposit === 'unlimited'}
        />
      </Panel>
      <Panel border="neutral-400" size="lg">
        <Text size="xs" weight="medium" color="neutral-600" mb="lg">
          {withdrawalTitle}
        </Text>

        <AllowanceWidget
          allowed={withdrawalDailyAllowed.toString()}
          used={withdrawalDailyUsed.toString()}
          asset={asset}
          percentage={BigNumber(withdrawalDailyPercentage).toNumber()}
          period={t('account.account-limits-modal.daily')}
        />
        <Separator width="size-full" my="md" />
        <AllowanceWidget
          allowed={withdrawalMonthlyAllowed.toString()}
          used={withdrawalMonthlyUsed.toString()}
          asset={asset}
          percentage={BigNumber(withdrawalMonthlyPercentage).toNumber()}
          period={t('account.account-limits-modal.monthly')}
        />
      </Panel>
    </Block>
  );
}

export default AllowancesSummary;

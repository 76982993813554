import React from 'react';

import { Disclosure, DisclosureButton } from '@headlessui/react';
import { clsx } from 'clsx';
import { Link } from 'react-router-dom';

import { MenuItemWithoutDropdownProps } from '../../types';

export const VerticalMenuItemWithoutDropdown = (props: MenuItemWithoutDropdownProps) => {
  return (
    <Disclosure as="div" className="p-0" defaultOpen={true}>
      <DisclosureButton
        as={Link}
        target={props.target}
        to={props.href}
        onClick={() => props.onClick?.(props)}
        className={clsx('vertical-menu-item group')}
      >
        <span> {props.text}</span>
      </DisclosureButton>
    </Disclosure>
  );
};

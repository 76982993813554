import { CheckboxDataType } from '@bilira-org/design/src/components/checkbox/type';

export type DataType = {
  id: string;
  label: string;
};

export type PersonalInfoType = {
  experienceYear?: string;
  profession?: string;
  school?: DataType;
};

export type PersonalInfo2Type = {
  income: DataType;
  incomeSource: CheckboxDataType[];
};

export type PersonalInfo3Type = {
  investAmount: string;
  investPurpose: CheckboxDataType[];
};

export const buildOptions = (data: string[], keyPrefix: string): any => {
  return data.map((d, i) => ({ id: `${keyPrefix}-${i}`, label: d }));
};

import React, { useCallback, useMemo } from 'react';

import {
  Block,
  ColumnDef,
  DataTable,
  GridView,
  Meta,
  Overflow,
  Skeleton,
  TableBodyStyle,
  TableHeaderStyle,
} from '@bilira-org/design';
import { BalanceType, BalanceWithQuoteType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import IconCoin from '@Components/icon/IconCoin';
import useNavigateToMarket from '@Libs/hooks/useNavigateToMarket';
import MyAssetTableBalanceField from '@Modules/assets/components/components/components/MyAssetTableBalanceField';
import MyAssetTableButtons from '@Modules/assets/components/components/components/MyAssetTableButtons';
import MyAssetTableFreeBalanceField from '@Modules/assets/components/components/components/MyAssetTableFreeBalanceField';

const headerStyle: TableHeaderStyle = { showBackground: true, px: 'sm', py: 'sm', weight: 'bold', size: 'xs' };
const bodyStyle: TableBodyStyle = { px: 'md', py: 'md' };

type Props = {
  data?: BalanceType[];
  loading: boolean;
};
const MyAssetTable = ({ data, loading }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigateToMarket();

  const callback = useCallback((item: BalanceType) => {
    navigate({ base: item.asset });
  }, []);

  const columns = useMemo<ColumnDef<BalanceType>[]>(
    () => [
      {
        header: t('assets.asset'),
        accessorKey: 'asset',
        align: 'left',
        cell({ row: { original: item } }) {
          return (
            <>
              <Meta
                style={{ width: '150px' }}
                space="sm"
                extra={<IconCoin size="3xl" type={item.asset} />}
                title={item.asset}
                description={item.name}
              />
            </>
          );
        },
        skeleton: (
          <Block row gap="sm" items="center">
            <Skeleton rounded="full" height="size-8" width="size-8" />
            <Skeleton height="size-4" width="size-24" />
          </Block>
        ),
      },
      {
        header: t('assets.balance'),
        accessorKey: 'balance',
        align: 'right',
        cell({ row: { original: item } }) {
          return <MyAssetTableBalanceField balance={item} />;
        },
        skeleton: <Skeleton width="size-24" height="size-4" />,
      },
      {
        header: t('assets.free-balance'),
        accessorKey: 'free_balance',
        align: 'right',
        show: { xs: false, sm: false, md: true },
        cell({ row: { original: item } }) {
          return <MyAssetTableFreeBalanceField balance={item} />;
        },
        skeleton: <Skeleton width="size-24" height="size-4" />,
      },
      {
        header: t('assets.transaction'),
        accessorKey: 'name',
        align: 'right',
        show: { xs: false, sm: false, md: false, lg: true },
        cell({ row: { original: entry } }) {
          return <MyAssetTableButtons balance={entry} />;
        },
        skeleton: (
          <Block row gap="sm" items="center">
            <Skeleton height="size-4" width="size-16" />
            <Skeleton height="size-4" width="size-16" />
          </Block>
        ),
      },
    ],
    [t],
  );

  return (
    <Overflow overflow="x-auto">
      <DataTable
        rowId="id"
        loading={loading}
        rounded
        headerStyle={headerStyle}
        bodyStyle={bodyStyle}
        onRowClick={callback}
        data={data}
        skeletonRowCount={5}
        columns={columns}
        emptyMessage={t('common.no-entries-found')}
      />
    </Overflow>
  );
};

export default MyAssetTable;

import React, { ComponentType, ForwardRefRenderFunction } from 'react';

import Hidden, { HiddenProps } from '../components/hidden/hidden';

type IntrinsicHiddenProps = {
  /** The props to be passed to the `Hidden` component, excluding 'children'. */
  hiddenProps: Omit<HiddenProps, 'children'>;
};

/**
 * Higher-order component that wraps another component with the `Hidden` component,
 * passing additional props for hiding content.
 */
export const withIntrinsicHidden =
  <T,>(
    Component: ComponentType<T & JSX.IntrinsicAttributes> | ForwardRefRenderFunction<unknown, T>,
  ): ComponentType<T & IntrinsicHiddenProps> =>
  (props: T & IntrinsicHiddenProps) => {
    return (
      <Hidden {...props.hiddenProps}>
        <Component {...(props as T & JSX.IntrinsicAttributes)} />
      </Hidden>
    );
  };

/**
 * Higher-order component that wraps another component with the `Hidden` component,
 */
const withHidden = (Component: ComponentType) => (props: Omit<HiddenProps, 'children'>) => {
  return (
    <Hidden {...props}>
      <Component />
    </Hidden>
  );
};
export default withHidden;

import React from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import HeadTags from '@/components/common/HeadTags';
import FiatDepositContainer from '@Modules/fiat/deposit/FiatDepositContainer';
import WithdrawFiatContainer from '@Modules/fiat/withdraw/WithdrawFiatContainer';

const FiatBoard = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const defaultRoute = pathname === '/fiat/withdraw';

  return (
    <>
      <HeadTags title={t('navigation.crypto')} titleSuffix="subpage" />

      {defaultRoute ? <WithdrawFiatContainer /> : <FiatDepositContainer />}
    </>
  );
};

export default FiatBoard;

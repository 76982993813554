import React, { forwardRef, memo, useImperativeHandle } from 'react';

import { Badge, Block, Button, Motion, Text } from '@bilira-org/design';
import { AssetPairType } from '@bilira-org/react-utils';

import FreeBalanceLabel from '@Components/common/FreeBalanceLabel';
import AssetSelector from '@Components/trade/buySell/components/AssetSelector';
import InputBuySell from '@Components/trade/buySell/components/InputBuySell';
import PercentageButtons from '@Components/trade/buySell/components/PercentageButtons';
import UnitPrice from '@Components/trade/buySell/components/UnitPrice';
import { TradeWidgetType } from '@Components/trade/buySell/store';
import useBuySellHook from '@Components/trade/buySell/useBuySellHook';
import { BuySellDirection, SwapAssetType } from '@Components/trade/types';

export type BuySellRefProps = {
  refetchOffer: () => void;
  onOfferClose: (isOfferCompleted: boolean) => void;
  onAssetSelectorClick: () => void;
};

type Props = {
  assetPair: AssetPairType;
  type: BuySellDirection;
  assetInfo?: SwapAssetType;
  onAssetSelectorClick: () => void;
  tradeWidgetType: TradeWidgetType;
};

/**
 * Component for buying or selling assets.
 *
 * @param {object} props - The props object.
 * @param {string} props.type - The type of transaction (buy or sell).
 * @param {Function} props.onAssetSelectorClick - The callback function when the asset selector is clicked.
 * @param {object} props.assetInfo - The information about the asset.
 * @param {string} props.assetPair - The pair of assets being traded.
 * @param {string} props.tradeWidgetType - The type of trade widget.
 * @param {React.ForwardedRef} ref - The ref object.
 * @returns {React.Element} The BuySell component.
 */
const BuySellTrade = forwardRef<BuySellRefProps, Props>(
  (
    { type, onAssetSelectorClick, assetInfo, assetPair, tradeWidgetType },
    ref?: React.ForwardedRef<BuySellRefProps>,
  ) => {
    const {
      inputValue,
      applyPercentageCallback,
      onFromInput,
      baseAsset,
      assetPrice,
      isPairPriceFetching,
      loginLink,
      disabled,
      fetchOffer,
      base,
      quote,
      isPending,
      t,
      onOfferClose,
    } = useBuySellHook({
      type,
      assetInfo,
      assetPair,
      tradeWidgetType,
    });

    useImperativeHandle(
      ref,
      () => ({
        onOfferClose: onOfferClose,
        refetchOffer: fetchOffer,
        onAssetSelectorClick: onAssetSelectorClick,
      }),
      [onOfferClose, fetchOffer, onAssetSelectorClick],
    );

    return (
      <>
        <AssetSelector baseAsset={base} quoteAsset={quote} callback={onAssetSelectorClick} />

        <Block py="4xl" items="center">
          <InputBuySell type={type} value={inputValue.from} onFromInput={onFromInput} asset={assetInfo} />
          <Block gap="sm" items="center">
            <Text size="xs" weight="regular">
              {t('trade.approximate', { amount: `${inputValue.to} ${type === 'buy' ? base : quote}` })}
            </Text>
            <Motion.Section show={!isPairPriceFetching}>
              <Badge color="neutral-300" px="sm" py="xs">
                <UnitPrice base={base} quote={quote} price={assetPrice} />
              </Badge>
            </Motion.Section>
          </Block>
        </Block>

        <Block gap="md" py="md">
          <PercentageButtons show={true} applyPercentageCallback={applyPercentageCallback} />
          <Motion.Section show={true}>
            <Block justify="center" gap="lg">
              <FreeBalanceLabel
                labelTextProps={{ size: 'xs', weight: 'regular', color: 'neutral-600' }}
                numberTextProps={{ size: 'xs', weight: 'regular' }}
                formatPriceProps={{ precision: 3, decimal: 2 }}
                justify="center"
                label={t('trade.available-balance')}
                symbol={baseAsset}
              />
            </Block>
          </Motion.Section>
        </Block>
        <Button
          disabled={disabled}
          loading={isPending}
          onClick={fetchOffer}
          link={loginLink}
          stretch
          mt="2xl"
          size="md"
          variant={type === 'buy' ? 'filled-success' : 'filled-danger'}
        >
          {t(`trade.button-${type}`, { asset: base })}
        </Button>
      </>
    );
  },
);

export default memo(BuySellTrade);

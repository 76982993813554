import { isAxiosError } from 'axios';

import { HttpErrorType } from '../../client';

/**
 * Analyzes the given HTTP error object and returns an object with boolean flags
 * for specific HTTP error status codes and network error.
 *
 * @param {HttpErrorType | null} error - The HTTP error object to analyze or null if there's no error.
 * @returns {{
 *   is302: boolean,
 *   is401: boolean,
 *   is403: boolean,
 *   is500: boolean,
 *   is503: boolean,
 *   is422: boolean,
 *   is429: boolean,
 *   is500p: boolean,
 *   isNetwork: boolean
 * }} An object with boolean flags for HTTP error status codes (401, 500, 422, 429) and network error.
 *
 * @example
 * * const error = { response: { status: 401 } };
 *  httpError(error);
 * // { is401: true, is500: false, is503: false, is422: false, is429: false, isNetwork: false }
 */
export default function httpError(error: HttpErrorType | null) {
  const isServerErrorAbove500 = (error?.response?.status || 0) > 500;
  const isServerErrorBelow600 = (error?.response?.status || 0) < 600;

  return {
    // 401 Unauthorized
    is401: error?.response?.status === 401,
    // 403 Forbidden
    is403: error?.response?.status === 403,
    // 403 Forbidden
    is302: error?.response?.status === 302,
    // 500 Internal Server Error
    is500: error?.response?.status === 500,
    // 503 Service Unavailable
    is503: error?.response?.status === 503,
    // 422 Unprocessable Entity
    is422: error?.response?.status === 422,
    // 429 Too Many Requests
    is429: error?.response?.status === 429,
    // 501-599 Server is down
    is500p: isServerErrorAbove500 && isServerErrorBelow600,
    // Network Error
    isNetwork: error?.message === 'Network Error',
  };
}

export const isHttpError = (error: HttpErrorType | null) => {
  const errorCode = httpError(error);
  return {
    // We block UI
    isServerUnavailable: errorCode.is500p,
    // We report critical errors.
    isCriticalError: errorCode.is500p || errorCode.isNetwork || errorCode.is500 || errorCode.is429 || errorCode.is422,
    // We redirect to public route
    isLogout: errorCode.is401,
  };
};

/**
 * Extracts the error message from an HTTP error object.
 *
 * @param {HttpErrorType} error - The HTTP error object.
 * @returns {string | null} The extracted error message or null if not available.
 *
 * @example
 * const errorResponse = { ...,response: { data: { message: 'Invalid credentials' } } }; // Axios Error
 * extractError(errorResponse); // 'Invalid credentials'
 *
 * const errorRequest = { ..., request: 'Error in the request' }; // Axios Error
 * extractError(errorRequest); // 'Error in the request'
 *
 * const errorMessage = { ..., message: 'Error message' }; // Axios Error
 * extractError(errorMessage); // 'Error message'
 */
export const extractError = (error: HttpErrorType): string | null | any => {
  if (!isAxiosError(error)) {
    return null;
  }

  const errMsg = error.response?.data as { message: string };

  if (errMsg?.message) {
    return errMsg.message;
  }

  if (error.message) {
    return error.message;
  }

  if (error.request?.statusText) {
    return error.request.statusText;
  }
  if (error.request) {
    return error.request;
  }

  return null;
};

/**
 * Gets the error message from an HTTP error object with default error message option.
 *
 * @param {HttpErrorType} error - The HTTP error object.
 * @param {string} defaultError - The default error message to return if the extracted message is not a string.
 * @returns {string} The error message.
 *
 * @example
 * const errorResponse = { ...,response: { data: { message: 'Invalid credentials' } } }; // Axios Error
 * getErrorMessage(errorResponse); // 'Invalid credentials'
 *
 * const error = { ..., error:'Divide by zero error' } ; // non-axios error
 * getErrorMessage(error, 'An unexpected error occurred'); // 'An unexpected error occurred'
 */

export const getErrorMessage = (error?: HttpErrorType | null, defaultError?: string) => {
  if (!error) {
    return null;
  }
  const innerError = extractError(error);

  if (typeof innerError !== 'string') {
    return defaultError;
  }

  return /<[a-z/][\s\S]*>/i.test(innerError) ? defaultError : innerError;
};

/**
 * Enum of custom error codes for the application.
 */
export enum ERROR_PAGE_STATE {
  LOGIN_ERROR = 'LE104',
  TOO_MANY_REQUEST = 'TMR429',
  NETWORK_ERROR = 'NE205',
  INTERNAL_SERVER_ERROR = 'SE500',
  SERVER_HANDLED_ERROR = 'SH422',
  SERVER_ERROR = 'SE503',
  AUTH_ERROR = 'AE304',
  UI_ERROR = 'UI400',
  UNHANDLED_ERROR = 'UE306',
  EMPTY_PARAM = 'EP106',
}

/**
 * Gets the BiLira-specific error code based on the HTTP error.
 *
 * @param {HttpErrorType | null} error - The HTTP error object.
 * @returns {ERROR_PAGE_STATE | undefined} The BiLira error code or undefined if not applicable.
 *
 * @example
 * const error = { response: { status: 401 } };
 * getBiLiraErrorCode(error); // 'BL304'
 */
export const getBiLiraErrorCode = (error: HttpErrorType | null): string | null => {
  const errorCode = httpError(error);
  if (errorCode.is401) return ERROR_PAGE_STATE.AUTH_ERROR;
  if (errorCode.is500) return ERROR_PAGE_STATE.INTERNAL_SERVER_ERROR;
  if (errorCode.is503) return ERROR_PAGE_STATE.SERVER_ERROR;
  if (errorCode.is422) return ERROR_PAGE_STATE.SERVER_HANDLED_ERROR;
  if (errorCode.is429) return ERROR_PAGE_STATE.TOO_MANY_REQUEST;
  if (errorCode.isNetwork) return ERROR_PAGE_STATE.NETWORK_ERROR;
  return null;
};

import React from 'react';

import { Button, Icon, withHidden } from '@bilira-org/design';

import { useGeneralStore } from '@/store/generalStore';

/**
 * Button for displaying global search modal
 */
const SearchButton = () => {
  const setSearchModalActive = useGeneralStore((state) => state.setSearchModalActive);

  return (
    <>
      <Button
        onClick={() => setSearchModalActive(true)}
        startIcon={<Icon color="secondary-500" type="o:magnifying-glass" />}
        variant="pill-neutral"
        size="md"
        testId="search-btn"
      />
    </>
  );
};

export default withHidden(SearchButton);

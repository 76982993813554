import { Cookie } from './Cookie';

const AUTH_TOKEN = 'access_token';

/**
 * A specialized extension of the Cookie class tailored for handling authentication-related cookies.
 */
export class CookieAuth<T> extends Cookie<T> {
  /**
   * Sets the authentication token in the cookie.
   *
   * @param {T | string} authToken - The authentication token to be stored.
   */
  setAuthData(authToken: T | string): void {
    this.set(AUTH_TOKEN, authToken, {
      expires: 1,
      secure: true,
      // sameSite: 'strict',
      // domain: '.bilira.co',
    });
  }

  /**
   * Retrieves the authentication token from the cookie.
   *
   * @returns {T | undefined} - The authentication token or undefined if not present.
   */
  getAuthData() {
    return this.get(AUTH_TOKEN);
  }

  /**
   * Retrieves the authentication token formatted as a Bearer token for use in authorization headers.
   *
   * @returns {string | undefined} - The Bearer token or undefined if not authenticated.
   */
  getBearer() {
    const authData = this.getAuthData();
    if (!authData) {
      return undefined;
    }

    return `Bearer ${this.getAuthData()}`;
  }

  /**
   * Checks if the user is authenticated based on the presence of the authentication token.
   *
   * @returns {boolean} - True if authenticated, false otherwise.
   */
  isAuthenticated() {
    return !!this.get(AUTH_TOKEN);
  }

  /**
   * Deletes the authentication token from the cookie.
   */
  deleteAuthData() {
    this.remove(AUTH_TOKEN, { path: '/' });
  }
}

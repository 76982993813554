import React, { memo, useCallback, useEffect, useState } from 'react';

import { Block, Icon, Label, Modal, Panel, Text } from '@bilira-org/design';
import { CryptoAssetsType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import SymbolPair from '@Components/common/SymbolPair';
import Tokens from '@Components/cryptoCards/Tokens';
import { useWalletStore } from '@Components/walletAddress/components/store/walletStore';
import useSelectAssetBySearchParam from '@Libs/hooks/useSelectAssetBySearchParam';
import { TRACKER_EVENTS } from '@Libs/tracker/events';
import { addTrackerCryptoWithdrawal } from '@Libs/tracker/helpers/addTrackerCryptoWithdrawal';
import PopularCryptoButtonList from '@Modules/crypto/components/PopularCryptoButtonList';

type Props = {
  disabled?: boolean;
  activeAsset?: CryptoAssetsType;
  setActiveAsset: (asset: CryptoAssetsType) => void;
};
const SelectCryptoStep = ({ disabled, activeAsset, setActiveAsset }: Props) => {
  const [modalAssetActive, setModalAssetActive] = useState(false);
  const { asset } = useSelectAssetBySearchParam();
  const { t } = useTranslation();
  const { setSelectedNetwork } = useWalletStore();

  const setAssetCallback = useCallback((asset: CryptoAssetsType) => {
    addTrackerCryptoWithdrawal(TRACKER_EVENTS.CRYPTO_WITHDRAWAL.SELECT_ASSET, asset);

    setActiveAsset(asset);
    setSelectedNetwork(undefined);
  }, []);

  useEffect(() => {
    asset && !disabled && setAssetCallback(asset);
  }, [asset, disabled]);

  return (
    <>
      <>
        <Text size="lg" weight="medium" mb="2xl" color="secondary-500">
          {t('crypto.crypto-selection-step')}
        </Text>
        <Label>{t('crypto.select-crypto')}</Label>
        <Panel
          mb="lg"
          size="md"
          ring="neutral-400"
          onClick={disabled ? undefined : () => setModalAssetActive(true)}
          color={disabled ? 'neutral-200' : undefined}
        >
          <Block row gap="md" items="center" justify="between">
            {activeAsset && (
              <SymbolPair
                size={{ icon: { xs: '2xl', sm: '2xl', md: '3xl' }, title: 'base', description: 'base' }}
                icon={activeAsset.symbol}
                title={activeAsset.symbol}
                description={activeAsset.name}
              />
            )}

            {!activeAsset && (
              <Text size="sm" color="neutral-500">
                {t('crypto.withdraw.select-crypto')}
              </Text>
            )}

            <Icon size="lg" color={disabled ? 'neutral-500' : 'neutral-600'} type="s:chevron-down" />
          </Block>
        </Panel>
        {!activeAsset && <PopularCryptoButtonList callback={setAssetCallback} disabled={disabled} />}
      </>

      <Modal
        title={t('crypto.select-crypto-to-withdraw')}
        open={modalAssetActive}
        onClose={() => setModalAssetActive(false)}
      >
        <Tokens
          onFinish={() => {
            setModalAssetActive(false);
          }}
          setAsset={setAssetCallback}
        />
      </Modal>
    </>
  );
};

export default memo(SelectCryptoStep);

import { useState } from 'react';

import { Flex, Panel, StepProgress, Text } from '@bilira-org/design';
import { CryptoAssetsType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import SelectCryptoStep from '@Modules/crypto/deposit/components/steps/SelectCryptoStep';
import SelectWalletStep from '@Modules/crypto/deposit/components/steps/SelectWalletStep';
import WarningClosable from '@Modules/crypto/deposit/components/WarningClosable';

const CryptoDeposit = () => {
  const { t } = useTranslation();
  const [activeAsset, setActiveAsset] = useState<CryptoAssetsType>();

  return (
    <Panel border="neutral-400">
      <Flex width={{ xs: 'full', sm: 'full', xl: '3/4' }}>
        <Text heading size="xl" weight="bold">
          {t('crypto.crypto-deposit-title')}
        </Text>
        <WarningClosable />
        <StepProgress
          hideStepIconOnSm
          mt="2xl"
          vertical
          borderStyle="dashed"
          size="sm"
          alignItems="center"
          steps={[
            {
              title: <SelectCryptoStep callback={setActiveAsset} />,
              active: true,
              complete: !!activeAsset,
            },
            {
              title: <SelectWalletStep asset={activeAsset} />,
              active: !!activeAsset,
            },
          ]}
        />
      </Flex>
    </Panel>
  );
};

export default CryptoDeposit;

import { CryptoNetworkType, WalletModel } from '@bilira-org/react-utils';
import { create } from 'zustand';

interface WalletStoreType {
  selectedWallet?: WalletModel;
  setSelectedWallet: (selectedWallet?: WalletModel) => void;
  selectedNetwork?: CryptoNetworkType;
  setSelectedNetwork: (selectedNetwork?: CryptoNetworkType) => void;
}

export const useWalletStore = create<WalletStoreType>((set) => ({
  selectedWallet: undefined,
  setSelectedWallet: (selectedWallet?: WalletModel) => set({ selectedWallet: selectedWallet }),
  selectedNetwork: undefined,
  setSelectedNetwork: (selectedNetwork?: CryptoNetworkType) => set({ selectedNetwork: selectedNetwork }),
}));

import React from 'react';

import { Block, Hidden, Separator } from '@bilira-org/design';

import HeaderButtons from '@Components/layouts/components/components/HeaderButtons';
import ProfileBadge from '@Components/layouts/components/components/ProfileBadge';
import { withPrivateRoute } from '@Libs/hocs';

/**
 * Layout component for displaying header buttons for authenticated users
 */
const PrivateHeaderButtons = () => {
  return (
    <>
      <Block row gap="lg" height="size-full" items="center">
        <HeaderButtons />
        <Hidden show={{ sm: true }}>
          <Separator height="size-5" color="neutral-500" />
        </Hidden>
        <ProfileBadge show={{ sm: true }} />
      </Block>
    </>
  );
};

export default withPrivateRoute(PrivateHeaderButtons);

import { Dispatch, SetStateAction, useState } from 'react';

import { Display } from '@bilira-org/design';
import { CustomWidthType } from '@bilira-org/design/types';
import { TransactionsParamsType, TransactionTypes } from '@bilira-org/react-utils';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import OtherFilters from './OtherFilters';
import SwapFilters from './SwapFilters';
import WithdrawDepositFilters from './WithdrawDepositFilters';

export type VariantType = 'deposit-withdraw' | 'swap' | 'other';

type FiltersProps = {
  variant: VariantType;
  filters: Omit<TransactionsParamsType, 'account'>;
  setFilters: (filters: Omit<TransactionsParamsType, 'account'>) => void;
  type: TransactionTypes;
  setType: (type: TransactionTypes) => void;
  displayVariant?: 'table' | 'export';
  filterLabels: Record<string, string | undefined>;
  setFilterLabels: Dispatch<SetStateAction<Record<string, string | undefined>>>;
  onReset?: () => void;
};

const Filters = (props: FiltersProps) => {
  const { variant, displayVariant, setFilterLabels, onReset } = props;
  const [customDate, setCustomDate] = useState<[Dayjs, Dayjs]>();

  const updateFilterKey = (key: string, value?: string) => {
    setFilterLabels((filters) => ({ ...filters, [key]: value }));
  };

  const getDropdownSize = (width: CustomWidthType): CustomWidthType => {
    if (displayVariant === 'export') {
      return 'full';
    }
    return width;
  };

  const handleReset = () => {
    setCustomDate(undefined);
    onReset?.();
  };

  return (
    <>
      <Display show={variant === 'deposit-withdraw'}>
        <WithdrawDepositFilters
          {...props}
          customDate={customDate}
          setCustomDate={setCustomDate}
          updateFilterKey={updateFilterKey}
          getDropdownSize={getDropdownSize}
          onReset={handleReset}
          key="withdraw"
        />
      </Display>

      <Display show={variant === 'swap'}>
        <SwapFilters
          {...props}
          customDate={customDate}
          setCustomDate={setCustomDate}
          updateFilterKey={updateFilterKey}
          getDropdownSize={getDropdownSize}
          onReset={handleReset}
          key="swap"
        />
      </Display>

      <Display show={variant === 'other'}>
        <OtherFilters
          {...props}
          customDate={customDate}
          setCustomDate={setCustomDate}
          updateFilterKey={updateFilterKey}
          getDropdownSize={getDropdownSize}
          onReset={handleReset}
          key="other"
        />
      </Display>
    </>
  );
};

export default Filters;

import React from 'react';

import { LayoutV2 } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import TransactionPanelCard from '@Components/transaction/TransactionPanelCard';
import WithdrawInfoBar from '@Modules/fiat/components/WithdrawInfoBar';
import FiatWithdrawalSidebar from '@Modules/fiat/withdraw/components/FiatWithdraw';

const { Content, InnerContent, Aside } = LayoutV2;

const WithdrawFiatContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <Content>
        <InnerContent>
          <WithdrawInfoBar />
          <FiatWithdrawalSidebar />
        </InnerContent>
        <Aside>
          <TransactionPanelCard
            title={t('common.recent-withdraw-transactions')}
            limit={5}
            type="fiat"
            direction="withdrawal"
            showAllButton
          />
        </Aside>
      </Content>
    </>
  );
};

export default WithdrawFiatContainer;

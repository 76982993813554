import React from 'react';

import { Block, Display, LayoutV2 } from '@bilira-org/design';
import { AssetPairType, parseAssetPair } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';

import { HIDE_SWAP_WIDGET_ON_PAIRS } from '@/libs/constants';
import BuySellCard from '@Components/trade/buySell/BuySellCard';
import TransactionPanelCard from '@Components/transaction/TransactionPanelCard';
import Header from '@Modules/market/detail/Header';
import AssetPairContainer from '@Modules/market/detail/PriceChartContainer';
import SiderHeader from '@Modules/market/detail/SiderHeader';

const { Content, InnerContent, Aside } = LayoutV2;

const Index = () => {
  const { t } = useTranslation();
  const { assetPair } = useParams<{ assetPair: AssetPairType | undefined }>();
  const [base, quote] = parseAssetPair(assetPair);

  if (!assetPair || !base || !quote) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Content>
        <InnerContent>
          <Header />
          <AssetPairContainer assetPair={assetPair} />
        </InnerContent>
        <Aside>
          <Block gap="4xl">
            <SiderHeader hiddenProps={{ show: { xs: false, sm: false, lg: true } }} />
            <Display show={!HIDE_SWAP_WIDGET_ON_PAIRS.includes(assetPair)}>
              <BuySellCard
                defaultAssetPair={assetPair}
                navigateOnSelection={true}
                hiddenProps={{ show: { xs: false, sm: true } }}
              />
            </Display>
            <TransactionPanelCard
              title={t('common.recent-swap-transactions')}
              limit={5}
              type="swap"
              filterAsset={base}
              filterStatus="completed"
              showSubAmount={false}
              showAllButton
            />
          </Block>
        </Aside>
      </Content>
    </>
  );
};

export default Index;

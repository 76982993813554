import AcademyImg from './assets/Academy.png';
import FiatImg from './assets/Fiat.png';
import LowCommissionImg from './assets/LowCommission.png';
import MarketImg from './assets/Market.png';
import NotificationImg from './assets/Notification.png';
import PortfolioImg from './assets/Portfolio.png';
import SecurityImg from './assets/Security.png';
import SupportImg from './assets/Support.png';
import SwapImg from './assets/Swap.png';

export const newFeatures = [
  {
    key: 'swap',
    title: 'new-feature-modal.swap.title',
    desc: 'new-feature-modal.swap.description',
    url: '/market',
    img: SwapImg,
  },
  {
    key: 'low-commission',
    title: 'new-feature-modal.low-commission.title',
    desc: 'new-feature-modal.low-commission.description',
    url: '/crypto',
    img: LowCommissionImg,
  },
  {
    key: 'market',
    title: 'new-feature-modal.markets.title',
    desc: 'new-feature-modal.markets.description',
    url: '/market',
    img: MarketImg,
  },
  {
    key: 'fiat',
    title: 'new-feature-modal.fiat-deposit-withdrawal.title',
    desc: 'new-feature-modal.fiat-deposit-withdrawal.description',
    url: '/fiat',
    img: FiatImg,
  },
  {
    key: 'assets',
    title: 'new-feature-modal.portfolio.title',
    desc: 'new-feature-modal.portfolio.description',
    url: '/assets',
    img: PortfolioImg,
  },
  {
    key: 'support',
    title: 'new-feature-modal.support.title',
    desc: 'new-feature-modal.support.description',
    urlKey: 'urls.support-center',
    img: SupportImg,
  },
  {
    key: 'blog',
    title: 'new-feature-modal.learn.title',
    desc: 'new-feature-modal.learn.description',
    url: '/blog',
    img: AcademyImg,
  },
  {
    key: 'notifications',
    title: 'new-feature-modal.notifications.title',
    desc: 'new-feature-modal.notifications.description',
    url: '/account/preferences',
    img: NotificationImg,
  },
  {
    key: 'security',
    title: 'new-feature-modal.security.title',
    desc: 'new-feature-modal.security.description',
    url: '/account/security-settings',
    img: SecurityImg,
  },
];

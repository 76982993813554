import React from 'react';

import { Block } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import HeadTags from '@/components/common/HeadTags';
import Cards from '@Modules/account/kyc/components/Cards';
import Header from '@Modules/account/kyc/components/Header';

function Index() {
  const { t } = useTranslation();

  return (
    <>
      <HeadTags title={t('account.headers.id-verification')} titleSuffix="subpage" />
      <Block gap="4xl">
        <Header />
        <Cards />
      </Block>
    </>
  );
}

export default Index;

import { LanguageUniversalType } from '@bilira-org/react-utils';
import { Dayjs, ManipulateType } from 'dayjs';

import CalendarBody from './calendarBody';
import CalendarHeader from './calendarHeader';
import { Block } from '../../block';
import { SelectedDatesType } from '../dateRangePicker';

export type CalendarType = 'day' | 'month' | 'year';

interface CalendarProps {
  /** Selected dates  */
  selectedDates: SelectedDatesType;
  /** Highlighted dates */
  highlightedDates?: SelectedDatesType;
  /** Callback to be called when a date is selected */
  onDateSelect: (date: Dayjs) => void;
  /** Callback to be called when a date is hovered */
  onDateHover: (date?: Dayjs) => void;
  /** Date of the calendar */
  calendarDate: Dayjs;
  /** Callback to be called when calendar date is changed */
  onCalendarDateChange: (date: Dayjs) => void;
  /** Locale of the calendar */
  locale: LanguageUniversalType;
  /** Type of the calendar */
  type: CalendarType;
  /** Callback to be called when calendar type is changed */
  onTypeChange: (type: CalendarType) => void;
  /** Range limit */
  limit?: [number, ManipulateType];
}

/**
 * Component for displaying calendar for DateRangePicker
 */
const Calendar = ({
  selectedDates,
  highlightedDates,
  onDateSelect,
  onDateHover,
  calendarDate,
  onCalendarDateChange,
  locale,
  type,
  onTypeChange,
  limit,
}: CalendarProps) => {
  return (
    <Block width="size-fit">
      <CalendarHeader
        date={calendarDate}
        onChange={(date) => onCalendarDateChange(date)}
        locale={locale}
        type={type}
        onTypeChange={onTypeChange}
      />

      <CalendarBody
        locale={locale}
        onSelect={onDateSelect}
        onHover={onDateHover}
        selectedDates={selectedDates}
        highlightedDates={highlightedDates}
        type={type}
        onTypeChange={onTypeChange}
        calendarDate={calendarDate}
        onCalendarDateChange={(date) => onCalendarDateChange(date)}
        limit={limit}
      />
    </Block>
  );
};

export default Calendar;

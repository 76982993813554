import React from 'react';

import { useTicker } from '@bilira-org/react-utils';

import CircleTimerInner from './circleTimerInner';
import { durationTypeConfig } from './constants';
import { BaseComponentType, BaseSize, MarginSizes } from '../types';

export interface ICircleTimerReturn {
  reset: (immediateStart?: boolean) => void;
  pause: (pause: boolean) => void;
}

interface ICircleTimer extends BaseComponentType {
  /** The total duration of the timer in the specified duration type. */
  duration: number;
  /** Callback to be triggered when timer completed */
  onComplete?: () => void;
  /** Margin y-axis */
  my?: MarginSizes;
  /** The variant of the CircleTimer */
  variant?: 'countdown' | 'circle';
  /** Whether the timer is enabled */
  enabled?: boolean;
  /** The update interval for the timer, in seconds. */
  updateInterval?: number;
  /** Size of the timer */
  size?: BaseSize;
  /** Whether to show label */
  showLabel?: boolean;
  /** The duration type of the timer */
  durationType?: 'hour' | 'minute' | 'second';
  /** The time to start the timer at, in the specified duration type.  */
  startAt?: number;
  /** Whether to display the timer in digital format (only applicable for `countdown` variant). */
  digitalDisplay?: boolean;
}

/**
 * CircleTimer component for displaying a circular timer or countdown.
 *
 * @example
 * <CircleTimer
 *   duration={60}
 *   my="4"
 *   size="lg"
 *   showLabel
 *   onComplete={() => console.log('Timer completed!')}
 *   variant="circle"
 *   enabled
 *   updateInterval={0.1}
 *   durationType="second"
 * />
 */
const CircleTimerTicker = React.forwardRef<ICircleTimerReturn | undefined, ICircleTimer>(
  (
    {
      duration,
      onComplete,
      enabled = true,
      updateInterval = 0.1,
      durationType = 'second',
      startAt = 0,
      ...props
    }: ICircleTimer,
    ref?: React.ForwardedRef<ICircleTimerReturn | undefined>,
  ) => {
    const calculatedDuration = duration * durationTypeConfig[durationType];
    const calculatedStartAt = startAt * durationTypeConfig[durationType];

    const { time, reset, pause } = useTicker({
      duration: calculatedDuration,
      enabled: enabled,
      updateInterval: updateInterval,
      onComplete: onComplete,
      startAt: calculatedStartAt,
    });

    return (
      <CircleTimerInner
        ref={ref}
        time={time}
        reset={reset}
        pause={pause}
        calculatedDuration={calculatedDuration}
        {...props}
      />
    );
  },
);

export default CircleTimerTicker;

import { RewardTaskActionType, UserParticipationType } from '../../model';

/**
 * Filters reward tasks based on action types.
 *
 * @param {UserParticipationType[]} [tasks] - The list of tasks to filter.
 * @param {Partial<Record<RewardTaskActionType, boolean>>} [hiddenActionTypes={}] - An object mapping `RewardTaskActionType` to a boolean indicating whether the task type should be hidden (`true` to hide).
 * @returns {UserParticipationType[]} The filtered list of tasks.
 *
 * @example
 * const { isSwapDisabled } = RemoteConfig;
 *
 * const tasks: UserParticipationType[] = [
 *   { action_type: 'referral', ...otherProps },
 *   { action_type: 'overnight_holding', ...otherProps },
 *   { action_type: 'purchase', ...otherProps },
 * ];
 *
 * const hiddenActionTypes: Partial<Record<RewardTaskActionType, boolean>> = {
 *   referral: true, // Hide referral tasks
 *   overnight_holding: false, // Do not hide overnight holding tasks
 *   swap: isSwapDisabled, // Hide based on swap feature flag
 * };
 *
 * const filteredTasks = filterRewardTasksByActionType(tasks, hiddenActionTypes);
 * console.log(filteredTasks);
 * // Output will exclude 'referral' tasks
 */
export const filterRewardTasksByActionType = (
  tasks?: UserParticipationType[],
  hiddenActionTypes: Partial<Record<RewardTaskActionType, boolean>> = {},
) => {
  if (!tasks) {
    return [];
  }

  // If no feature flag is filtering tasks, return tasks array directly
  if (Object.values(hiddenActionTypes).every((enabled) => !enabled)) {
    return tasks;
  }

  return tasks.filter((task) => {
    return !hiddenActionTypes[task.action_type];
  });
};

import React, { FC, memo, useCallback, useMemo } from 'react';

import { Block, Breadcrumbs, Empty, Icon, Input, List, Meta, Skeleton, Text } from '@bilira-org/design';
import {
  BalanceType,
  CoinSymbolType,
  CryptoAssetsType,
  filterExpression,
  useDeferredFilter,
} from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import useGetSortedBalances from '@/libs/hooks/useGetSortedBalances';
import AssetName from '@Components/common/AssetName';
import { MaskableBalance } from '@Components/common/MaskableFormattedBalance';
import IconCoin from '@Components/icon/IconCoin';
import cryptoQuery from '@Libs/clientInstances/cryptoQuery';

interface ITokens {
  /** Function to be called after user selects asset */
  onFinish: () => void;
  /** Function to be called when user selects asset */
  setAsset: (asset: CryptoAssetsType) => void;
  /** An array of crypto asset symbols to filter the tokens on displayed list */
  tokenFilter?: CoinSymbolType[];
}

/**
 * Tokens component for displaying a list of crypto assets with balances and search functionality.
 */
const Tokens: FC<ITokens> = ({ tokenFilter, onFinish, setAsset }) => {
  const { t } = useTranslation();

  const { data: assets, isPending } = cryptoQuery.useGetAssets();
  const { data: sortedBalances } = useGetSortedBalances();

  /** Filtered token list */
  const tokenList = useMemo(() => {
    const tradeOnlyAssets =
      assets?.reduce((acc, asset) => {
        if (asset.only_for_trade) {
          return [...acc, asset.symbol];
        }
        return acc;
      }, [] as CoinSymbolType[]) || [];

    if (!sortedBalances) return [];

    return sortedBalances.filter((item) => {
      const isTokenIncluded = !tokenFilter || tokenFilter.includes(item.asset);
      const isTradeOnlyExcluded = !tradeOnlyAssets?.includes(item.asset);

      return isTokenIncluded && isTradeOnlyExcluded;
    });
  }, [sortedBalances, tokenFilter]);

  const filter = useCallback(
    (item: BalanceType, search: string) => filterExpression<BalanceType>(['asset', 'name'])(item, search),
    [],
  );

  const { searchText, onSearch, filteredData } = useDeferredFilter({ predicate: filter, data: tokenList ?? [] });

  if (isPending) {
    return <TokensSkeleton />;
  }

  return (
    <>
      <Block mb="md">
        <Input.Search
          variant="bordered"
          testId="crypto-search-input"
          iconStart={<Icon size="md" type="o:magnifying-glass" />}
          placeholder={t('common.do-search')}
          value={searchText}
          onChange={(val) => onSearch(val.target.value)}
        />
      </Block>
      <List
        testId="crypto-list"
        empty={<Empty message={t('error.data-not-found')} />}
        color="neutral-300"
        space="xs"
        dataSource={filteredData}
        skeleton={
          <Block row justify="between" items="center" p="md" gap="lg">
            <Block row items="center" gap="md">
              <Skeleton width="size-9" height="size-9" rounded="full" />

              <Skeleton height="size-3" width="size-20" />
            </Block>

            <Skeleton height="size-3" width="size-12" />
          </Block>
        }
        skeletonLine={9}
        renderItem={(item) => (
          <List.Item
            size={{ xs: 'sm', sm: 'md' }}
            key={item.name}
            hoverBgColor="theme-n200d800"
            onClick={() => {
              const asset = assets?.find((asset) => asset.symbol === item.asset);
              if (asset) {
                setAsset(asset);
              }
              onFinish();
            }}
            extra={
              <MaskableBalance
                type="amount"
                field="free_balance"
                base={item.asset}
                color="secondary-500"
                size={{ xs: 'sm', sm: 'sm', md: 'base' }}
              />
            }
          >
            <Meta
              space="sm"
              extra={<IconCoin size={{ xs: '2xl', sm: '3xl' }} type={item.asset} />}
              title={
                <Breadcrumbs listType="disc">
                  <Breadcrumbs.Item>
                    <Text size={{ xs: 'sm', sm: 'base' }} color="secondary-500" weight="semibold">
                      {item.asset}
                    </Text>
                  </Breadcrumbs.Item>
                  <Breadcrumbs.Item>
                    <Text size={{ xs: 'sm', sm: 'base' }} weight="regular">
                      <AssetName symbol={item.asset} />
                    </Text>
                  </Breadcrumbs.Item>
                </Breadcrumbs>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default memo(Tokens);

const TokensSkeleton = () => {
  return (
    <>
      <Block mb="md">
        <Skeleton width="size-full" height="size-12" />
      </Block>
      <List
        color="neutral-300"
        space="xs"
        loading={true}
        skeleton={
          <Block row justify="between" items="center" p="md" gap="lg">
            <Block row items="center" gap="md">
              <Skeleton width="size-9" height="size-9" rounded="full" />

              <Skeleton height="size-3" width="size-20" />
            </Block>

            <Skeleton height="size-3" width="size-12" />
          </Block>
        }
        skeletonLine={9}
      />
    </>
  );
};

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { clsx } from 'clsx';
import ReactWebcam, { WebcamProps } from 'react-webcam';

import { Button } from '../button';
import { Div } from '../common';
import { Icon } from '../icons';
import { Spinner } from '../spinner';
import { BaseComponentType } from '../types';

const defaultConstraints = {
  width: 1920,
  height: 1080,
  facingMode: 'user',
};

export type Props = Omit<WebcamProps, 'audio' | 'videoConstraints' | 'onUserMedia' | 'disablePictureInPicture'> &
  BaseComponentType & {
    /** Whether to activate the webcam */
    activate?: boolean;
    /** Video constraints for the webcam  */
    videoConstraints?: boolean | MediaTrackConstraints | undefined;
    /**  Callback function triggered when capturing an image. */
    onCapture?: (source?: string | null) => void;
    /** Callback function triggered when user media is obtained */
    onUserMedia?: (stream: MediaStream) => void;
  };

/**
 * Component that provides webcam capture functionality.
 *
 * @example
 * <WebcamCapture activate={true} onCapture={handleCapture} />
 */
const WebcamCapture = ({
  activate = true,
  onCapture,
  onUserMedia,
  minScreenshotHeight = defaultConstraints.width,
  minScreenshotWidth = defaultConstraints.height,
  videoConstraints = defaultConstraints as MediaTrackConstraints,
  testId,
  ...props
}: Props) => {
  const classes = clsx('webcam');
  const [loading, setLoading] = React.useState(true);

  const webcamRef = useRef<ReactWebcam>(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    onCapture?.(imageSrc);
  }, [webcamRef]);

  const handleUserMedia = () => setTimeout(() => setLoading(false), 2_000);

  useEffect(() => {
    return () => {
      webcamRef.current?.stream?.getTracks().forEach((track) => track.stop());
    };
  }, []);

  if (!activate) {
    return null;
  }

  return (
    <Div className={classes} testId={testId ?? 'webcam'}>
      {loading && <Spinner />}
      <ReactWebcam
        disablePictureInPicture
        style={{ opacity: loading ? 0 : 1 }}
        ref={webcamRef}
        audio={false}
        onUserMedia={(mediaStream) => {
          handleUserMedia();
          onUserMedia?.(mediaStream);
        }}
        videoConstraints={videoConstraints}
        minScreenshotHeight={minScreenshotHeight}
        minScreenshotWidth={minScreenshotWidth}
        {...props}
      />

      <Button
        variant="pill"
        size="xl"
        style={{ opacity: loading ? 0 : 1 }}
        onClick={capture}
        startIcon={<Icon type="s:camera" />}
      />
    </Div>
  );
};

WebcamCapture.displayName = 'Webcam';

export default WebcamCapture;

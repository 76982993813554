import type { Socket } from 'socket.io-client';
import { io } from 'socket.io-client';

type RegisterConnect = {
  onSuccess: (result: any) => void;
  onFail: (result: any) => void;
  event: string;
};

export type InstanceParamsType = Record<string, unknown> & {
  withCredentials?: boolean;
  path?: string;
};

/**
 * Class representing a SocketClient for handling socket connections.
 * @class
 */
class SocketClient {
  /**
   * The underlying Socket.IO client instance.
   * @private
   */
  _client: Socket;

  /**
   * An interval for retrying connection.
   * @private
   */
  _interval: any | undefined;

  private _subscriptions: Map<string, any>;

  constructor(uri: string, params?: InstanceParamsType) {
    this._client = io(uri, {
      ...params,
      autoConnect: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 10000,
      reconnection: true,
    });

    this._subscriptions = new Map();
  }

  public retryConnect = () => {
    this._client.connect();

    this._interval = setInterval(() => {
      console.log('Reconnecting');
      if (this._client.connected) {
        console.log('no need');
        clearInterval(this._interval);
        return;
      }
      this._client.connect();
    }, 10000);
  };

  registerConnect = (registerSubscriptions?: boolean) => {
    this._client.on('connect', () => {
      if (registerSubscriptions) {
        this.resubscribeAll();
      }
      console.debug('🤳🏾 Connected.');
      this._clearInterval();
    });
  };

  _clearInterval = () => {
    this._interval && clearInterval(this._interval);
  };

  registerError = ({ onFail }: Omit<RegisterConnect, 'onSuccess' | 'event'>) => {
    this._client.on('disconnect', (reason: Socket.DisconnectReason) => {
      console.debug('🥲 Disconnected', reason);
      onFail(reason);
    });

    this._client.on('connect_failed', () => console.debug('connect_failed'));
    this._client.on('reconnect', (attempt) => console.debug('reconnect.', attempt));
    this._client.on('reconnect_failed', () => console.debug('reconnect_failed'));

    this._client.on('connect_error', (data) => {
      if (data.message.includes('Authentication')) {
        this._clearInterval();
      }
      onFail(data);
    });
  };

  registerMessage = ({ onSuccess }: Omit<RegisterConnect, 'onFail' | 'event'>) => {
    this._client.on('message', (data) => {
      onSuccess(data);
    });
  };

  registerEvents = ({ onSuccess, event }: Omit<RegisterConnect, 'onFail'>) => {
    this._client.on(event, (data) => {
      onSuccess(data);
    });
  };

  // subscribe
  public subscribe(channel: string, payload: any): void {
    if (!this._client.connected) {
      return;
    }

    this._client.emit('sub', JSON.stringify({ ...payload }));
    // this._client.emit('sub|{"symbols":["AVAXUSDT"],"type": "last_price"}');
    this._subscriptions.set(channel, payload);

    console.debug('🟢', JSON.stringify(payload));
  }

  public unsubscribe(channel: string, payload: any, clean: boolean = true): void {
    if (!this._client.connected) {
      return;
    }
    this._client.emit(`unsub|${JSON.stringify({ ...payload })}`);
    if (clean) {
      this._subscriptions.delete(channel);
    }

    console.debug('🔴', JSON.stringify(payload));
  }

  public resubscribeAll(): void {
    for (const [channel, payload] of this._subscriptions.entries()) {
      this.subscribe(channel, payload);
    }
  }

  public reconnect(): void {
    this._client.connect();
  }
  public close(): void {
    this._client.close();
  }

  public emit(event: string, ...args: any[]): void {
    this._client.emit(event, ...args);
  }
}

export default SocketClient;

import React from 'react';

import { Block, Button, Separator, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import UserQuery from '@Libs/clientInstances/userQuery';

type Props = {
  /** Number of unread notifications */
  unreadCount?: number;
};

/**
 * ReadAllNotificationBar component for displaying the unread notification count
 * and a button to mark all notifications as read.
 */
const ReadAllNotificationBar = ({ unreadCount = 0 }: Props) => {
  const { t } = useTranslation();
  const { mutateAsync: readAllNotifications, isPending } = UserQuery.usePutNotifications();
  return (
    <>
      <Block row justify="between" mx="md" items="center" pt="xs">
        <Text color="neutral-700" size="sm">
          {t('common.unread', { count: unreadCount })}
        </Text>
        <Button
          onClick={() => readAllNotifications()}
          variant="link"
          size="sm"
          disabled={unreadCount === 0}
          loading={isPending}
        >
          {t('common.mark-all-as-read')}
        </Button>
      </Block>
    </>
  );
};

export default ReadAllNotificationBar;

import { useEffect, useState } from 'react';

import debounce from 'lodash.debounce';

export type Breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined; // Might add '2xl' | '3xl' | '4xl' |

/**
 * Calculates the breakpoint based on the predefined window widths.
 *
 * @param {number} width - The window width.
 * @returns {Breakpoints} - The calculated breakpoint.
 */
const getBreakpoint = (width: number): Breakpoints => {
  if (width >= 1280) {
    return 'xl';
  } else if (width >= 1025) {
    return 'lg';
  } else if (width >= 768) {
    return 'md';
  } else if (width >= 640) {
    return 'sm';
  } else {
    return 'xs';
  }
};

interface Props {
  /** Flag indicating whether to enable window size tracking. */
  enable?: boolean;
}

/**
 * Hook for tracking the window size and returning the current breakpoint.
 *
 * @param {Props} props - The properties for configuring the hook.
 * @returns {Breakpoints} - The current breakpoint based on the window size.
 */
function useWindowSize({ enable = true }: Props): Breakpoints {
  const [windowSize, setWindowSize] = useState<Breakpoints>(enable ? getBreakpoint(window.innerWidth) : undefined);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = debounce(() => {
      if (enable) {
        setWindowSize(getBreakpoint(window.innerWidth));
      }
    }, 0);

    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [enable]); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export default useWindowSize;

import React, { useEffect, useState } from 'react';

import { Block, Display, Panel, Spinner, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { useBuySellStore } from '../store';

/**
 * Component to render the processing message for the swap.
 */
function SwapProcessing() {
  const { t } = useTranslation();
  const { buySellType } = useBuySellStore();
  const [isSlowLoading, setIsSlowLoading] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsSlowLoading(true);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <Panel>
        <Block justify="center" items="center" mx="auto" mb="8xl" maxWidth="sm">
          <Block mt="3xl" mb="2xl">
            <Spinner size="xl" color="primary" />
          </Block>
          <Display show={!isSlowLoading}>
            <Text heading size="lg" color="neutral-900" weight="bold" mb="md" align="center">
              {t(`trade.offer.processing.title.${buySellType}`)}
            </Text>
            <Text size="sm" weight="regular" align="center" mb="6xl">
              {t('trade.offer.processing.description')}
            </Text>
          </Display>

          <Display motion show={isSlowLoading}>
            <Text heading size="lg" color="neutral-900" weight="bold" mb="md" align="center">
              {t('trade.offer.processing.delay-title')}
            </Text>
            <Text size="sm" weight="regular" align="center" mb="6xl">
              {t('trade.offer.processing.delay-description')}
            </Text>
          </Display>
        </Block>
      </Panel>
    </>
  );
}

export default SwapProcessing;

import { IMarketingApi } from './interface';
import { HttpClient, HttpClientType, HttpErrorType } from '../../client';
import { PostPromoCodeBodyType } from '../../model/Marketing';

class MarketingApi extends HttpClient implements IMarketingApi {
  public constructor(conf: HttpClientType) {
    super(conf);
  }

  public postPromoCode = (body: PostPromoCodeBodyType) => {
    return this.instance.post<HttpErrorType, any>('/marketing/promo-codes', body);
  };
}

export default MarketingApi;

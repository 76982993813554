import { ERROR_PAGE_STATE, getBiLiraErrorCode, IdpQueryKey, isAxiosError, UserQueryKey } from '@bilira-org/react-utils';
import { isHttpError } from '@bilira-org/react-utils/src/utils/httpError';
import * as Sentry from '@sentry/react';

import { useSessionStore } from '@/store';
import router from '@App/createRouter';
import { queryCache } from '@Libs/clientInstances/queryClient';

/**
 * Handles capturing and reporting errors to Sentry.
 *
 * @param {Error} error - The error object to capture.
 * @param {string} type - The type of error.
 * @param {string} hash -  React query key
 * @returns {boolean} - Always returns false to indicate that the error is being captured.
 */
const handleErrorCapture = (error: Error, type: string, hash?: string): void => {
  if (!isAxiosError(error)) {
    Sentry.captureException(error, {
      user: { id: useSessionStore.getState().user?.id },
      tags: {
        errorType: type,
        biLiraErrorCode: ERROR_PAGE_STATE.UNHANDLED_ERROR,
      },
    });

    return;
  }

  const httpError = isHttpError(error);

  if (httpError.isCriticalError) {
    Sentry.captureException(error, {
      user: { id: useSessionStore.getState().user?.id },
      tags: {
        errorType: type,
        biLiraErrorCode: getBiLiraErrorCode(error),
      },
    });
  }

  if (window.location.pathname === '/error') {
    return;
  }

  if (httpError.isLogout) {
    useSessionStore.getState().setUser(null);
    return;
  }

  if (httpError.isServerUnavailable) {
    console.error(error);
    /**
     * PrivateRoute cashes the user and kyc requests. If server crashes during navigation between routes,
     * error is captured here first, kyc and user queries are reset. So when it is redirected to ErrorPage.tsx,
     * health check fails.
     */

    queryCache.resetQueries([IdpQueryKey.USER_INFO]);
    queryCache.resetQueries([UserQueryKey.USERS]);

    /**
     * Resetting queries does not re-render the route, so navigation on error is necessary.
     * Also, when you refresh the page, here is THE FIRST logic which captures the error.
     */

    router.navigate('/error', {
      state: {
        from: { pathname: window.location.pathname },
        errorState: ERROR_PAGE_STATE.SERVER_ERROR,
        authServiceDown: true,
      },
    });
  }
};

export default handleErrorCapture;

import BigNumber from 'bignumber.js';

import { formatPrice, FormatPriceType, getMax, getMin } from '../currency';

interface Props {
  /** The maximum allowance for the transaction. */
  allowanceMax?: string;
  /** The minimum allowance for the transaction. */
  allowanceMin?: string;
  /** The free balance available for the transaction. */
  freeBalance?: string;
  /** The locale for formatting. */
  locale: string;
  /** Optional properties for formatting the price. */
  formatPriceProps?: Partial<FormatPriceType>;
}

/**
 * Calculates transaction limits and formatted values based on the provided parameters.
 *
 * @param {Props} props - The parameters for calculating transaction limits.
 * @returns An object containing transaction limits and their formatted values.
 */
const getTxLimits = ({
  allowanceMax,
  allowanceMin,
  freeBalance,
  locale,
  formatPriceProps = { decimal: 2, precision: 4 },
}: Props) => {
  // Use getMax(value, '0') to handle cases where allowanceMax and freeBalance
  // might be calculated as negative values.
  const txMax = getMin(getMax(allowanceMax, '0').toString(), freeBalance);

  const formattedTxMax = formatPrice({
    value: txMax.toString(),
    locale: locale,
    ...formatPriceProps,
  }).value;

  // Use getMax(allowanceMin, '0') to handle cases where allowanceMin
  // might be calculated as negative values.
  const txMin = getMax(allowanceMin, '0');
  const formattedTxMin = formatPrice({
    value: txMin.toString(),
    locale: locale,
    ...formatPriceProps,
  }).value;

  return { txMax, formattedTxMax, txMin, formattedTxMin };
};

export default getTxLimits;

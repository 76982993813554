import React, { createElement, HTMLAttributes, isValidElement, ReactElement, ReactNode } from 'react';

import { clsx } from 'clsx';

import { Skeleton } from '../skeleton';
import {
  BaseComponentType,
  BreakpointType,
  FontType,
  FontWeight,
  LineClampType,
  MarginSizes,
  OverflowType,
  PaddingSizes,
  ResponsiveReturnType,
  TextAlign,
  TextColorType,
  TextSizes,
  WordBreak,
} from '../types';
import { buildResponsiveClass } from '../utils';

export const Truncate = {
  TRUNCATE_WIDTH_FULL: true,
  TRUNCATE_ONLY: 1,
} as const;
type TruncateType = (typeof Truncate)[keyof typeof Truncate];

export interface TextProps extends HTMLAttributes<HTMLParagraphElement>, BaseComponentType {
  /** The content to be rendered within the Text component */
  children?: ReactNode | ReactNode[];
  /** The size of the text */
  size?: TextSizes | BreakpointType<TextSizes>;
  /** The text color */
  color?: TextColorType;
  /** The font weight of the text */
  weight?: FontWeight;
  /** Padding on the Y-axis  */
  py?: PaddingSizes;
  /** Padding on the X-axis  */
  px?: PaddingSizes;
  /** Margin on the Y-axis  */
  my?: PaddingSizes;
  /** Margin on the X-axis  */
  mx?: PaddingSizes;
  /** Margin on the top */
  mt?: PaddingSizes;
  /** Margin on the right */
  pr?: PaddingSizes;
  /** Margin on the left */
  pl?: PaddingSizes;
  /** Margin on the bottom */
  mb?: PaddingSizes;
  /** Margin on the left */
  ml?: MarginSizes | BreakpointType<MarginSizes>;
  /** Margin on the right */
  mr?: MarginSizes;
  /** The text alignment */
  align?: TextAlign;
  /** word-break property for the text */
  wordBreak?: WordBreak;
  /** Font type of the text */
  font?: FontType;
  /** Whethet the text should have heading styles */
  heading?: boolean;
  /** Additional class names for styling. */
  className?: string;
  /** Whether the Text is in loading state */
  loading?: boolean;
  /** Whether to truncate the text with ellipsis */
  truncate?: boolean | TruncateType;
  /** Whether to underline the text */
  underline?: boolean;
  /** text-decoration property */
  decoration?: 'line-through' | 'underline' | 'none';
  /** Whethet to display text as inline */
  inline?: boolean;
  /** Text transformation */
  transform?: 'capitalize' | 'uppercase' | 'lowercase';
  /** Custom skeleton element to be rendered during loading state */
  skeleton?: ReactElement;
  /** Whether to colorize HTML content
   *
   * Useful when `dangerouslySetInnerHTML` property used
   */
  colorizeHtml?: boolean;
  /** HTML tag to use for rendering */
  as?: 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  /** Overflow property for the text */
  overflow?: OverflowType;
  /** Number of lines to limit the text */
  lineClamp?: LineClampType;
}

/**
 * Text component for displaying text with customization options.
 *
 * @example
 * <Text heading size="xl" weight="bold">
 *   Page Header
 * </Text>
 */
const Text = ({
  size,
  weight,
  py,
  px,
  pl,
  pr,
  my,
  mx,
  mb,
  mt,
  mr,
  ml,
  align,
  color,
  className,
  wordBreak,
  font,
  heading,
  children,
  skeleton,
  loading,
  truncate,
  underline,
  inline,
  transform,
  colorizeHtml,
  testId,
  decoration,
  as = 'p',
  overflow,
  lineClamp,
  ...props
}: TextProps) => {
  let defaultProps = {
    size: 'base',
    weight: 'regular',
    color: 'neutral-800',
    font: 'primary',
  };

  if (heading) {
    defaultProps = {
      size: 'xl',
      weight: 'bold',
      color: 'secondary-500',
      font: 'secondary',
    };
  }

  const innerWeight = weight ?? defaultProps.weight;
  const innerSize = size ?? defaultProps.size;
  const innerColor = color ?? defaultProps.color;
  const innerFont = font ?? defaultProps.font;

  let sizeClass: ResponsiveReturnType = {};

  if (innerSize) {
    sizeClass = buildResponsiveClass(innerSize, 'tw-text-');
  }

  let marginLeftClass: ResponsiveReturnType = {};

  if (ml) {
    marginLeftClass = buildResponsiveClass(ml, 'ml-');
  }

  const classes = clsx(
    'typography-text',
    sizeClass,
    marginLeftClass,
    {
      'cursor-pointer': !!props.onClick,
      'select-none': !!props.onClick,
      colorize: colorizeHtml,
      [`tw-font-${innerWeight}`]: innerWeight,
      [`tw-font-${innerWeight}`]: innerWeight,
      [`tw-text-${align}`]: align,
      [`tw-text-${innerColor}`]: innerColor,
      [`decoration-${decoration}`]: decoration,
      [`font-${innerFont}`]: innerFont,
      [`transform-${transform}`]: transform,
      [`py-${py}`]: py,
      [`px-${px}`]: px,
      [`pr-${pr}`]: pr,
      [`pl-${pl}`]: pl,
      [`my-${my}`]: my,
      [`mx-${mx}`]: mx,
      [`mb-${mb}`]: mb,
      [`mt-${mt}`]: mt,
      [`mr-${mr}`]: mr,
      [`tw-break-${wordBreak}`]: wordBreak,
      'w-full truncate': truncate === Truncate.TRUNCATE_WIDTH_FULL,
      truncate: truncate === Truncate.TRUNCATE_ONLY,
      underline: underline,
      inline: inline,
      [`tw-overflow-${overflow}`]: overflow,
      [`line-clamp-${lineClamp}`]: lineClamp,
    },
    className,
  );

  if (loading) {
    return <div className={classes}>{skeleton ?? <Skeleton height="size-full" width="size-12" />}</div>;
  }

  return createElement(
    as,
    {
      ...props,
      ['data-testid']: testId ?? 'typography-text',
      className: classes,
    },
    !loading ? children : isValidElement(skeleton) ? skeleton : <Skeleton height="size-full" width="size-12" />,
  );
};

Text.displayName = 'Text';

export { Text };

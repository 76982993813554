import { IUserApi } from './interface';
import { HttpClient, HttpClientType, HttpErrorType } from '../../client';
import {
  GrantCodeMobileResult,
  NotificationParamsType,
  NotificationPatchBody,
  NotificationType,
  RecordsPaginationType,
  ResultType,
  TwoFACodeBodyType,
  TwoFACodeType,
  TwoFAMethodsType,
  TwoFAOptionsType,
  TwoFAVerifyCodeBodyType,
  TwoFAVerifyCodeType,
} from '../../model';
import {
  DisclaimerRecordBodyType,
  DisclaimerRecordType,
  DisclaimParamsType,
  GrantCode,
  GrantCodeResult,
  LegalDocumentConsentType,
  LegalDocumentParamsType,
  LegalDocumentType,
  PatchReferralCodeBody,
  PatchReferralCodeResponseType,
  PostReferralCodeBody,
  PostReferralCodeResponseType,
  PutLegalDocumentConsentBodyType,
  PutPinCodeBodyType,
  ReferralCodesResponseType,
  ReferralCodeTypeItem,
  UserType,
  VerifyPinCodeBodyType,
} from '../../model/User';

class UserApi extends HttpClient implements IUserApi {
  public constructor(conf: HttpClientType) {
    super(conf);
  }

  public getUsers = async () => await this.instance.get<HttpErrorType, UserType>('/users');
  public getTwoFA = async (type: TwoFAOptionsType) =>
    await this.instance.get<HttpErrorType, TwoFAMethodsType, TwoFAOptionsType>('/users/2fa/info', {
      params: { type },
    });
  public getTwoFACode = async (body: TwoFACodeBodyType) =>
    await this.instance.post<HttpErrorType, TwoFACodeType, TwoFACodeBodyType>('/users/2fa/token', body);

  public postVerifyTOTP = async (body: any) =>
    await this.instance.post<HttpErrorType, any, any>('/users/2fa/enable-disable-totp', body);
  public postActivateTOTP = async (body: any) =>
    await this.instance.post<HttpErrorType, any, any>('/users/2fa/enable-verify-totp', body);
  public postVerifyTwoFACode = async (body: TwoFAVerifyCodeBodyType) =>
    await this.instance.post<HttpErrorType, TwoFAVerifyCodeType, TwoFAVerifyCodeBodyType>('/users/2fa/verify', body);
  public getNotifications = async (params: NotificationParamsType) =>
    await this.instance.get<
      HttpErrorType,
      RecordsPaginationType<NotificationType> & {
        unread_count: number;
      }
    >('/users/notifications', { params });

  public patchNotifications = (body: NotificationPatchBody) => {
    return this.instance.patch<HttpErrorType, ResultType, NotificationPatchBody>(`/users/notifications`, body);
  };

  public putNotifications = () => {
    return this.instance.put<HttpErrorType, ResultType>(`/users/notifications`);
  };

  public postGrantCode = async (body: GrantCode): Promise<GrantCodeResult> =>
    await this.instance.post<GrantCodeResult>(`/users/oauth/token`, body);
  public postGrantCodeMobile = async (body: GrantCode): Promise<GrantCodeMobileResult> =>
    await this.instance.post<GrantCodeMobileResult>(`/users/oauth/mobile-token`, body);

  public getLegalDocuments = (params?: LegalDocumentParamsType) => {
    return this.instance.get<HttpErrorType, LegalDocumentType[]>('/legal-documents', { params });
  };

  public getLegalDocumentConsents = () => {
    return this.instance.get<HttpErrorType, LegalDocumentConsentType[]>('/users/legal-document-consents');
  };

  public putLegalDocumentConsents = (body: PutLegalDocumentConsentBodyType) => {
    return this.instance.put<HttpErrorType, any>('/users/legal-document-consents', body);
  };

  public getReferralCodes = async (type?: string) =>
    await this.instance.get<HttpErrorType, ReferralCodesResponseType>('/users/referral-codes', { params: { type } });

  public postReferralCode = async (body: PostReferralCodeBody): Promise<PostReferralCodeResponseType> =>
    await this.instance.post<PostReferralCodeResponseType>('/users/referral-codes', body);

  public patchReferralCode = async (body: PatchReferralCodeBody): Promise<PatchReferralCodeResponseType> => {
    const { id, ...rest } = body;
    return await this.instance.patch<PatchReferralCodeResponseType>(`/users/referral-codes/${id}`, rest);
  };

  public getReferralCodeTypes = async () =>
    await this.instance.get<ReferralCodeTypeItem[]>('/users/referral-code-types');

  public putPinCode = (body: PutPinCodeBodyType) => {
    return this.instance.put<HttpErrorType, any>('/users/pin-codes', body);
  };

  public postVerifyPinCode = (body: VerifyPinCodeBodyType) => {
    return this.instance.post<HttpErrorType, any>('/users/pin-codes/verify', body);
  };

  public deletePinCode = (type: string) => {
    return this.instance.delete<HttpErrorType, ResultType, string>(`/users/pin-codes/${type}`);
  };

  public getDisclaimerRecords = (params: DisclaimParamsType) => {
    return this.instance.get<HttpErrorType, DisclaimerRecordType[]>(`/users/disclaimer-records`, { params });
  };

  public postDisclaimerRecords = (payload: DisclaimerRecordBodyType) => {
    return this.instance.post<HttpErrorType, DisclaimerRecordType | false, DisclaimerRecordBodyType>(
      `/users/disclaimer-records`,
      payload,
    );
  };
}

export default UserApi;

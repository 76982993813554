import { useSessionStore } from '@/store';
import UserQuery from '@Libs/clientInstances/userQuery';
import Tracker from '@Libs/tracker/Tracker';

type ReturnType = {
  user: string;
  account: string;
  role: string;
  isPending: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  otcUser: boolean;
  bankVerified: boolean;
};

/**
 * Hook for fetching user authentication information.
 *
 * @param {Props} [props] - The properties for the `useAuth` hook.
 * @returns {ReturnType} - An object containing user authentication information.
 */
const useAuth = (): ReturnType => {
  const isLoggedIn = useSessionStore((state) => state.isLoggedIn);
  const { isSuccess, isPending, data, isLoading } = UserQuery.useGetUsers({ enabled: isLoggedIn });
  //
  const {
    id = '',
    account = '',
    role = '',
    otc_user: otcUser = false,
    bank_verified: bankVerified = false,
  } = data || {};

  if (id) {
    Tracker.identify(id);
  }

  return { user: id, account, role, otcUser, bankVerified, isSuccess, isPending, isLoading };
};

export default useAuth;

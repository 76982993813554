import React from 'react';

import { Icon, List, Meta, Popover, Separator, Text, withHidden } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import FeatureFlag from '@/components/common/FeatureFlag';
import { useGeneralStore } from '@/store/generalStore';
import NewBadge from '@Components/common/NewBadge';
import UserKycCard from '@Components/layouts/components/components/components/UserKycCard';
import { LANGUAGES, REMOTE_CONFIG_KEYS } from '@Libs/constants';
import { LanguageIcons } from '@Libs/constants/constantJsx';
import useLocaleRoute from '@Libs/hooks/useLocaleRoute';

/**
 * Popover badge for viewing profile menu
 */
const ProfileBadge = () => {
  const { i18n, t } = useTranslation();
  const { getRoute } = useLocaleRoute();

  const setLanguageModalActive = useGeneralStore((state) => state.setLanguageModalActive);
  const navigate = useNavigate();

  const language = LANGUAGES.find((d) => d.key === i18n.language);

  const redirect = (href: string) => {
    navigate(href);
  };

  return (
    <>
      <Popover
        appearance={false}
        buttonVariant="pill-neutral"
        buttonSize="md"
        icon={<Icon color="secondary-500" type="o:user" />}
        customWidth="250"
        testId="profile-badge"
      >
        {(close) => (
          <>
            <UserKycCard />
            <Separator width="size-full" mt="md" />
            <List spaceY="none" testId="profile-dropdown">
              <List.Item
                px="md"
                py="md"
                hoverBgColor="neutral-300"
                onClick={() => {
                  redirect('/account');
                  close();
                }}
              >
                <Meta
                  extra={<Icon size="lg" color="neutral-700" type="o:user-circle" />}
                  space="md"
                  title={
                    <Text size="sm" color="secondary-500" weight="regular">
                      {t('navigation.profile.account')}
                    </Text>
                  }
                />
              </List.Item>
              <List.Item
                px="md"
                py="md"
                hoverBgColor="neutral-300"
                onClick={() => {
                  redirect('/account/security-settings');
                  close();
                }}
              >
                <Meta
                  extra={<Icon size="lg" color="neutral-700" type="o:shield-check" />}
                  space="md"
                  title={
                    <Text size="sm" color="secondary-500" weight="regular">
                      {t('navigation.profile.security')}
                    </Text>
                  }
                />
              </List.Item>
              <List.Item
                px="md"
                py="md"
                hoverBgColor="neutral-300"
                onClick={() => {
                  redirect('/account/id-verification');
                  close();
                }}
              >
                <Meta
                  extra={<Icon size="lg" color="neutral-700" type="o:identification" />}
                  space="md"
                  title={
                    <Text size="sm" color="secondary-500" weight="regular">
                      {t('navigation.profile.id-verification')}
                    </Text>
                  }
                />
              </List.Item>
              <List.Item
                px="md"
                py="md"
                hoverBgColor="neutral-300"
                onClick={() => {
                  redirect('/account/preferences');
                  close();
                }}
              >
                <Meta
                  extra={<Icon size="lg" color="neutral-700" type="o:cog-6-tooth" />}
                  space="md"
                  title={
                    <Text size="sm" color="secondary-500" weight="regular">
                      {t('navigation.profile.preferences')}
                    </Text>
                  }
                />
              </List.Item>

              <FeatureFlag remoteConfigKey={REMOTE_CONFIG_KEYS.REFERRAL}>
                <List.Item
                  px="md"
                  py="md"
                  hoverBgColor="neutral-300"
                  onClick={() => {
                    redirect('/referral');
                    close();
                  }}
                >
                  <Meta
                    extra={<Icon size="lg" color="neutral-700" type="o:user-plus" />}
                    space="md"
                    title={
                      <NewBadge badgeKey="referral-profile">
                        <Text size="sm" color="secondary-500" weight="regular">
                          {t('navigation.profile.referral')}
                        </Text>
                      </NewBadge>
                    }
                  />
                </List.Item>
              </FeatureFlag>

              <List.Item
                px="md"
                py="md"
                hoverBgColor="neutral-300"
                onClick={() => {
                  redirect(getRoute('CommissionFee'));
                  close();
                }}
              >
                <Meta
                  extra={<Icon size="lg" color="neutral-700" type="o:receipt-percent" />}
                  space="md"
                  title={
                    <Text size="sm" color="secondary-500" weight="regular">
                      {t('commission.transaction-fees')}
                    </Text>
                  }
                />
              </List.Item>
              <List.Item
                px="md"
                py="md"
                hoverBgColor="neutral-300"
                onClick={() => {
                  setLanguageModalActive(true);
                  close();
                }}
              >
                <Meta
                  extra={LanguageIcons[language?.key as LanguageType]}
                  space="md"
                  title={
                    <Text size="sm" color="secondary-500" weight="regular">
                      {t('navigation.profile.language')}: {language?.value}
                    </Text>
                  }
                />
              </List.Item>
              <Separator width="size-full" mt="md" />
              <List.Item
                px="md"
                py="md"
                hoverBgColor="neutral-300"
                onClick={() => {
                  redirect('/logout');
                  close();
                }}
              >
                <Meta
                  extra={<Icon size="lg" color="neutral-700" type="o:arrow-right-on-rectangle" />}
                  space="md"
                  title={
                    <Text size="sm" color="secondary-500" weight="regular">
                      {t('navigation.profile.logout')}
                    </Text>
                  }
                />
              </List.Item>
            </List>
          </>
        )}
      </Popover>
    </>
  );
};

export default withHidden(ProfileBadge);

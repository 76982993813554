import React, { forwardRef, HTMLAttributes, ReactNode } from 'react';

import { clsx } from 'clsx';

import { BaseComponentType, OverflowType, SizeType } from '../types';

export type OverflowProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'> &
  BaseComponentType & {
    /** The content to be rendered inside the overflow component */
    children: ReactNode;
    /** The overflow type to be applied (e.g., 'auto', 'hidden', 'visible') */
    overflow: OverflowType;
    /** Whether the overflow container should be a flex container */
    flex?: boolean;
    /** Flag indicating whether the flex layout should be in the row direction.
     *  This is only applicable if the flex prop is true.
     */
    row?: boolean;
    /** Height size */
    height?: SizeType;
  };

/**
 * The `Overflow` component is used to create a container with specified overflow behavior.
 */
const Overflow = forwardRef<HTMLDivElement, OverflowProps>(
  ({ overflow, children, flex, row, height, testId, className, ...rest }, ref) => {
    const classes = clsx(
      'overflow',
      {
        [`tw-overflow-${overflow}`]: overflow,
        [`tw-h-${height}`]: height,
        flex: flex,
      },
      flex && (row ? 'tw-flex-row' : 'tw-flex-col'),
      className,
    );

    return (
      <article className={classes} ref={ref} data-testid={testId} {...rest}>
        {children}
      </article>
    );
  },
);

Overflow.displayName = 'Overflow';

export default Overflow;

import React from 'react';

import * as Sentry from '@sentry/react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import ErrorPage from '@Components/errorPages/ErrorPage';
import { LayoutEmpty } from '@Components/layouts';
import Layout from '@Components/layouts/Layout';
import LayoutError from '@Components/layouts/LayoutError';
import LayoutLogin from '@Components/layouts/login/LayoutLogin';
import { AuthorizedRoute } from '@Components/routes';
import PrivateRoute from '@Components/routes/PrivateRoute';
import PublicRoute from '@Components/routes/PublicRoute';
import WidgetRoute from '@Components/routes/WidgetRoute';
import routeMap from '@Libs/route/routeMap';
import Account from '@Modules/account';
import Assets from '@Modules/assets';
import { Login, Logout, OAuthCallback } from '@Modules/auth';
import Crypto from '@Modules/crypto';
import DepositWithdraw from '@Modules/deposit-withdraw';
import Fiat from '@Modules/fiat';
import Invitation from '@Modules/invitation';
import Market from '@Modules/market';
import MarketDetail from '@Modules/market/detail';
import MarketRanking from '@Modules/market/ranking';
import Notification from '@Modules/notification';
import OTCPortal from '@Modules/otc';
import Overview from '@Modules/overview';
import Rewards from '@Modules/rewards';
import Leaderboard from '@Modules/rewards/leaderboard';
import CommissionAndReferral from '@Modules/settings/commission';
import Support from '@Modules/support';
import Swap from '@Modules/swap';
import TransactionHistory from '@Modules/transactios';
import DownloadApp from '@Modules/widget/any/DownloadApp';
import { MarketCardWidget, MarketTableWidget } from '@Modules/widget/market';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export default sentryCreateBrowserRouter([
  {
    path: '/',
    element: <PrivateRoute element={Layout} />,
    children: [
      {
        index: true,
        element: <Overview />,
      },
      {
        path: '/assets',
        element: <AuthorizedRoute element={Assets} />,
      },
      {
        path: '/fiat',
        element: <AuthorizedRoute element={Fiat} />,
        children: [
          {
            path: 'deposit',
            element: <AuthorizedRoute element={Fiat} />,
          },
          {
            path: 'withdraw',
            element: <AuthorizedRoute element={Fiat} />,
          },
        ],
      },
      {
        path: '/crypto',
        element: <AuthorizedRoute element={Crypto} />,
        children: [
          {
            path: 'deposit',
            element: <AuthorizedRoute element={Crypto} />,
          },
          {
            path: 'withdraw',
            element: <AuthorizedRoute element={Crypto} />,
          },
        ],
      },
      {
        path: '/account',
        element: <AuthorizedRoute element={Account} />,
        children: [
          {
            path: 'security-settings',
            element: <AuthorizedRoute element={Account} />,
          },
          {
            path: 'preferences',
            element: <AuthorizedRoute element={Account} />,
          },
          {
            path: 'id-verification',
            element: <AuthorizedRoute element={Account} />,
          },
        ],
      },
      {
        path: '/transaction-history',
        element: <AuthorizedRoute element={TransactionHistory} />,
      },
      {
        path: '/otc',
        element: <OTCPortal />,
      },
      {
        path: routeMap.Swap.en,
        element: <Swap />,
      },
      {
        path: routeMap.Swap.tr,
        element: <Swap />,
      },
      {
        path: '/notification',
        element: <AuthorizedRoute element={Notification} />,
      },
      {
        path: routeMap.Market.en,
        children: [
          {
            index: true,
            element: <Market />,
          },
          {
            path: ':assetPair',
            element: <MarketDetail />,
          },
          {
            path: 'ranking',
            element: <MarketRanking />,
          },
        ],
      },
      {
        path: routeMap.Market.tr,
        children: [
          {
            index: true,
            element: <Market />,
          },
          {
            path: ':assetPair',
            element: <MarketDetail />,
          },
          {
            path: 'siralamalar',
            element: <MarketRanking />,
          },
        ],
      },
      {
        path: routeMap.CommissionFee.en,
        element: <CommissionAndReferral />,
        children: [
          {
            path: 'fees',
            element: <CommissionAndReferral />,
          },
          {
            path: 'commissions',
            element: <CommissionAndReferral />,
          },
        ],
      },
      {
        path: routeMap.CommissionFee.tr,
        element: <CommissionAndReferral />,
        children: [
          {
            path: 'ucretler',
            element: <CommissionAndReferral />,
          },
          {
            path: 'komisyonlar',
            element: <CommissionAndReferral />,
          },
        ],
      },
      {
        path: '/referral',
        element: <CommissionAndReferral />,
      },
      {
        path: '/rewards',
        children: [
          {
            index: true,
            element: <Rewards />,
          },
          // {
          //   path: 'leaderboard/:id',
          //   element: <AuthorizedRoute element={Leaderboard} />,
          // },
        ],
      },
      {
        path: '/deposit-withdraw',
        element: <AuthorizedRoute element={DepositWithdraw} />,
      },
    ],
  },
  {
    element: <PrivateRoute element={LayoutLogin} />,
    children: [
      {
        path: routeMap.Logout.tr,
        element: <Logout />,
      },
      {
        path: routeMap.Logout.en,
        element: <Logout />,
      },
    ],
  },
  {
    element: <PublicRoute element={LayoutLogin} />,
    children: [
      {
        path: routeMap.Login.en,
        element: <Login />,
      },
      {
        path: routeMap.Login.tr,
        element: <Login />,
      },
      {
        path: routeMap.Register.en,
        element: <Login />,
      },
      {
        path: routeMap.Register.tr,
        element: <Login />,
      },
    ],
  },
  {
    element: <LayoutLogin />,
    children: [
      {
        path: '/oauth/callback',
        element: <OAuthCallback />,
      },
      {
        path: routeMap.Invitation.en,
        element: <Invitation />,
      },
    ],
  },
  {
    path: '/support',
    element: <Support />,
  },
  {
    path: '/download-redirect',
    element: <DownloadApp />,
  },
  {
    path: '/widget',
    element: <WidgetRoute element={LayoutEmpty} />,
    children: [
      {
        path: 'market',
        element: <MarketTableWidget />,
      },
      {
        path: 'market-cards',
        element: <MarketCardWidget />,
      },
    ],
  },
  {
    element: <LayoutError />,
    children: [
      {
        path: '/error',
        element: <ErrorPage />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
]);

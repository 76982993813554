import { useCallback, useEffect, useRef } from 'react';

import { TourCallBackProps, useWindowSize } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useUserPreferences from '@/libs/hooks/useUserPreferences';
import { useSessionStore } from '@/store';
import { useGeneralStore } from '@/store/generalStore';
import PreferencesApi from '@Libs/clientInstances/preferencesQuery';

import tours from '../constants/tours';

/**
 * Custom hook for managing the onboarding tour functionality.
 *
 * Synchronized with backend to control which onboarding tour should start
 */
export function useOnboardingTour() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const completedToursAssigned = useRef(false);
  const isLoggedIn = useSessionStore((state) => state.isLoggedIn);

  const {
    setTour,
    tour: { run, stepIndex, completed, activeTour },
    legalDocuments,
  } = useGeneralStore();

  const { mutateAsync } = PreferencesApi.usePutUserPreference();

  const {
    preferences: { completedTours },
  } = useUserPreferences({
    enabled: completed === undefined && isLoggedIn,
  });

  useEffect(() => {
    if (completedTours !== undefined && !completedToursAssigned.current) {
      completedToursAssigned.current = true;
      setTour({ completed: completedTours });
    }
  }, [completedTours, setTour]);

  const steps = tours[activeTour as keyof typeof tours] || [];
  const completedTourKeys = completed ? Object.keys(completed).filter((key) => completed[key]) : undefined;

  const uncompletedTourKeys = Object.keys(tours).filter((tour) =>
    completedTourKeys ? !completedTourKeys.includes(tour) : false,
  );

  const handleActiveTourNavigation = useCallback(
    (newActiveTour: string) => {
      const activeStep = tours[newActiveTour as keyof typeof tours][0];
      if (activeStep.url !== location.pathname) {
        navigate(activeStep.url);
      }
    },
    [navigate],
  );

  useEffect(() => {
    if (activeTour !== uncompletedTourKeys[0] && completedTours !== undefined) {
      setTour({ activeTour: uncompletedTourKeys[0] });
      handleActiveTourNavigation(uncompletedTourKeys[0]);
    }
  }, [activeTour, completedTours, handleActiveTourNavigation, setTour, uncompletedTourKeys]);

  const translatedSteps = steps.map((step) => ({ ...step, title: t(step.title), content: t(step.content) }));

  const handleOnboardStepChange = (index: number, newIndex: number) => {
    let run = true;
    if (
      translatedSteps[index].url !== translatedSteps[newIndex].url ||
      window.location.pathname !== translatedSteps[newIndex].url
    ) {
      navigate(translatedSteps[newIndex].url);
      run = false;
    }
    setTour({ run, stepIndex: newIndex });
  };

  const handleOnboardingComplete = (key?: string) => {
    if (!key) {
      return;
    }
    const newCompletedTours = { ...completed, [key]: true };
    let newActiveTour;
    if (uncompletedTourKeys.length > 1) {
      newActiveTour = uncompletedTourKeys[1];
      handleActiveTourNavigation(newActiveTour);
    }
    setTour({ run: false, stepIndex: 0, completed: newCompletedTours, activeTour: newActiveTour });
    mutateAsync({ key: 'completedTours', value: JSON.stringify(newCompletedTours) });
  };

  const handleCallback = (data: TourCallBackProps) => {
    const { action, index, size, type } = data;

    if (action === 'next' && type === 'step:after') {
      if (index === size - 1) {
        handleOnboardingComplete(activeTour);
      } else {
        handleOnboardStepChange(index, index + 1);
      }
    } else if (action === 'prev' && type === 'step:after') {
      handleOnboardStepChange(index, index - 1);
    } else if (action === 'close') {
      handleOnboardingComplete(activeTour);
    }
  };

  const windowSize = useWindowSize({ enable: true });
  const filteredSteps = translatedSteps.filter((step) => {
    if (step.show) {
      return step.show?.includes(windowSize);
    }
    return true;
  });

  return {
    /** Whether a tour is running */
    run: run && !legalDocuments.active,
    /** Active step index */
    stepIndex,
    /** Filtered steps based on window size */
    filteredSteps,
    handleCallback,
    nextText: t('tour.next'),
    backText: t('tour.back'),
    finishText: t('tour.finish'),
  };
}

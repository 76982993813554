import { withPrivateRoute } from '@/libs/hocs';

import useLegalDocuments from './hooks/useLegalDocuments';
import CookieModal from './modals/CookieModal';
import LegalDocumentsModal from './modals/LegalDocumentsModal';

/**
 * Component to control cookie consent logic.
 *
 * Renders cookie modal or legal documents modal based on document type.
 */
const LegalDocuments = () => {
  const { isCookieConsent, updateLegalDocumentConsent, activeConsent, isLegalDocumentModalOpen, isPending } =
    useLegalDocuments();
  return (
    <>
      <CookieModal
        open={isCookieConsent}
        data={activeConsent}
        onAccept={(value) => updateLegalDocumentConsent('cp', value)}
        authenticated
      />

      <LegalDocumentsModal
        open={isLegalDocumentModalOpen}
        data={activeConsent}
        onAccept={(value) => activeConsent && updateLegalDocumentConsent(activeConsent.document_type || '', value)}
        isPending={isPending}
      />
    </>
  );
};

export default withPrivateRoute(LegalDocuments);

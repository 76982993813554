import { friendlyFormatIBAN } from 'ibantools';

/**
 * Formats the provided IBAN.
 *
 * @param {string} iban - The IBAN to be formatted.
 * @returns {string} - The formatted IBAN.
 *
 * * @example
 * const unformattedIban = 'TR480011100000000079118373';
 * const formattedIban = formatIban(unformattedIban);
 * console.log(formattedIban);
 * // Output: '48 0011 1000 0000 0079 1183 73'
 */
export const formatIban = (iban: string = '') => {
  return friendlyFormatIBAN(iban) || '';
};

import { IDirectApi } from './interface';
import { HttpClient, HttpClientType, HttpErrorType } from '../../client';
import { ResultType } from '../../model';
import {
  DirectAcceptQuoteResultType,
  DirectAllowanceType,
  DirectCreateQuoteBodyType,
  DirectCreateQuoteType,
  DirectGetPriceParamsType,
  DirectPriceType,
  DirectQuoteType,
  DirectSetOfferBodyType,
  DirectSystemBankAccountType,
  DirectUpdateQuoteParamType,
  RedirectTokenType,
} from '../model/DirectTypes';

class DirectApi extends HttpClient implements IDirectApi {
  public constructor(conf: HttpClientType) {
    super(conf);
  }

  public Token = (code: string) => this.instance.post<any, any>(`/oauth/token`, { grant_code: code });

  public getPrice = (params: DirectGetPriceParamsType) =>
    this.instance.get<HttpErrorType, DirectPriceType>(`/price`, {
      params: params,
    });

  public getQuoteEstimatedPrice = (id: string) =>
    this.instance.get<HttpErrorType, DirectPriceType>(`/quote/${id}/estimated-price`);

  public createQuote = (body: Partial<DirectCreateQuoteBodyType>) => {
    return this.instance.post<Partial<DirectCreateQuoteBodyType>, DirectCreateQuoteType>(`/quote`, body);
  };

  public getQuote = (id: string) => this.instance.get<HttpErrorType, DirectQuoteType>(`/quote/${id}`);
  public getRenewedQuote = (id: string) => this.instance.patch<HttpErrorType, DirectQuoteType>(`/quote/${id}/renew`);
  public patchSetOffer = (id: string, body: DirectSetOfferBodyType = {}) =>
    this.instance.patch<HttpErrorType, DirectQuoteType>(`/quote/${id}/set-offer`, body);

  public getSystemBankAccounts = () =>
    this.instance.get<HttpErrorType, DirectSystemBankAccountType[]>(`/system-bank-accounts`);
  public acceptQuote = (id: string) => this.instance.post<string, DirectAcceptQuoteResultType>(`/quote/${id}/accept`);
  public mockCompleteTxn = (id: string) => this.instance.post<string, ResultType>(`/mock/quote/${id}/deposit`);
  public updateBankAccount = (param: DirectUpdateQuoteParamType) =>
    this.instance.post<DirectUpdateQuoteParamType, ResultType>(`/quote/${param.id}/deposit`, {
      bank_account_id: param.bank_account_id,
    });

  public getAllowances = () => this.instance.get<HttpErrorType, DirectAllowanceType>('/allowances');

  public getRedirectToken = (quoteId: string) =>
    this.instance.get<HttpErrorType, RedirectTokenType>(`/quote/${quoteId}/redirect-token`);
}

export default DirectApi;

import React from 'react';

import { Text, TextProps } from '@bilira-org/design';
import { padTo2Digits } from '@bilira-org/design/src/components/timer/helpers';
import { RewardsQueryKey, RewardsTasksType, UserParticipationType, useTicker } from '@bilira-org/react-utils';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useTranslation } from 'react-i18next';

import { queryCache } from '@Libs/clientInstances/queryClient';

dayjs.extend(duration);

type Props = TextProps & {
  task: UserParticipationType | RewardsTasksType;
  format: 'hour' | 'humanized';
};

const TaskExpiresAt: React.FC<Props> = ({ task, format, ...rest }) => {
  const { t } = useTranslation();

  const taskExpiresInMs = 'expires_at' in task ? dayjs(task.expires_at).diff(dayjs()) : 0;

  const { time } = useTicker({
    duration: taskExpiresInMs,
    enabled: true,
    updateInterval: format === 'hour' ? 1000 : 60000,
    onComplete: () => {
      queryCache.invalidateQuery([RewardsQueryKey.REWARDS_USER_PARTICIPATION_KEY]);
    },
  });

  const remaining = dayjs.duration(time);

  if (format === 'hour') {
    const totalHours = Math.floor(remaining.asHours() || 0);
    const formattedTime = [
      padTo2Digits(totalHours),
      padTo2Digits(remaining.minutes()),
      padTo2Digits(remaining.seconds()),
    ].join(':');

    return <Text {...rest}>{formattedTime}</Text>;
  }

  if (format === 'humanized') {
    const formattedTime = [
      { value: remaining.days(), unit: t('common.date-unit.day') },
      { value: remaining.hours(), unit: t('common.date-unit.hour') },
      { value: remaining.minutes(), unit: t('common.date-unit.min') },
    ];

    return (
      <Text {...rest}>
        {formattedTime.map(({ value, unit }, index) => (
          <React.Fragment key={index}>
            {value}
            {unit}
            {index !== formattedTime.length - 1 && ' '}
          </React.Fragment>
        ))}
      </Text>
    );
  }
};

export default TaskExpiresAt;

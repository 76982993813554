import React from 'react';

import { Block, LayoutV2, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

const { Hero } = LayoutV2;

const Bar = () => {
  const { t } = useTranslation();
  return (
    <Hero>
      <Block row justify="between">
        <Text heading size="xl" weight="bold" my="xs">
          {t('common.notifications')}
        </Text>
      </Block>
    </Hero>
  );
};

export default Bar;

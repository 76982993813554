import { Modal } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import useGetTransactionDetail, { TransactionRecordInfo } from '@/libs/hooks/useGetTransactionDetail';

import TransactionDetailModalContent from './components/TransactionDetailModalContent';

type TransactionSummaryModalProps = {
  /** Transaction data to be displayed */
  transactionRecordInfo?: TransactionRecordInfo;
  /** Whether the modal is open */
  open: boolean;
  /** Function to be called when modal is closed */
  onClose: (state: boolean) => void;
  /** Function to refetch transaction data */
  refetch: () => void;
};

/**
 * Component for displaying transaction details in a modal
 */
function TransactionDetailModal({ transactionRecordInfo, open, onClose, refetch }: TransactionSummaryModalProps) {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        testId="transaction-detail-modal"
        title={t('transactions.transaction-details')}
        open={open}
        onClose={() => onClose(false)}
        compound
      >
        <TransactionDetailModalContent
          transactionRecordInfo={transactionRecordInfo}
          onClose={onClose}
          refetch={refetch}
        />
      </Modal>
    </>
  );
}

export default TransactionDetailModal;

import React from 'react';

import { Meta, Panel, Text, TextCopy } from '@bilira-org/design';
import { extractBankCode, FiatWithdrawalType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { BankIcon } from '@Components/icon/BankIcon';
import { truncateMiddle } from '@Libs/utils';

export type Props = {
  data: FiatWithdrawalType;
};

export const FiatTransferType = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <Meta
      title={
        <Text size="sm">{data.direction === 'deposit' ? t('fiat.bank-selection') : t('transaction-bank-account')}</Text>
      }
      description={
        <Panel color="neutral-200" size="lg">
          <Meta
            extra={<BankIcon name={extractBankCode(data.bank_account?.iban.trim() || '')} type="icon" />}
            space="sm"
            title={
              <Text weight="semibold" color="secondary-500">
                {data.bank_account?.alias}
              </Text>
            }
            description={
              <TextCopy value={data.bank_account?.iban} size="sm" color="neutral-800" copiedText={t('common.copied')}>
                {truncateMiddle(data.bank_account?.iban, 9, 2)}
              </TextCopy>
            }
          />
        </Panel>
      }
    />
  );
};

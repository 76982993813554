import { useEffect, useRef } from 'react';

type EventListenerCallback<T extends Event> = (event: T) => void;

export default function useEventListener<T extends Event>(
  eventType: string,
  callback: EventListenerCallback<T>,
  element: HTMLElement | Window | null = window,
): void {
  const callbackRef = useRef(callback);

  // Update the callback reference if it changes
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (element == null) return;

    const handler = (event: T) => callbackRef.current(event);
    element.addEventListener(eventType, handler as EventListener);

    return () => element.removeEventListener(eventType, handler as EventListener);
  }, [eventType, element]);
}

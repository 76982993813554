import React, { FC } from 'react';

import { BaseComponentType } from '../types';

interface IChildren extends BaseComponentType {
  /** Content to be rendered within the head component */
  children: React.ReactNode;
}

/**
 * Head component for organizing content within the head section of a layout
 */
const Head: FC<IChildren> = ({ children, testId }) => {
  return (
    <div className="head" data-testid={testId ?? 'head'}>
      {children}
    </div>
  );
};

Head.displayName = 'Head';

export default Head;

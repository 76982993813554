import React from 'react';

import { Block, Icon, Meta, Skeleton, StepProgress, Text } from '@bilira-org/design';
import { TransactionsRecordType, TransactionStatusType } from '@bilira-org/react-utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import TransactionTitle from '@Components/transaction/components/TransactionTitle';
import AccountType from '@Components/transaction/modal/components/AccountType';
import { getStateConfig, TRANSACTION_STATUS } from '@Libs/helpers/getStateConfig';

import TransactionID from '../TransactionID';

type TransactionSummaryModalContentProps = {
  data?: TransactionsRecordType;
};

function Index({ data }: TransactionSummaryModalContentProps) {
  const { t, i18n } = useTranslation();

  return (
    <StepProgress
      loading={!data}
      skeleton={
        <Block gap="md">
          <Skeleton height="size-4" width="size-full" />
          <Skeleton height="size-4" width="size-1/2" />
          <Skeleton height="size-4" width="size-full" />
          <Skeleton height="size-4" width="size-full" />
          <Skeleton height="size-4" width="size-1/2" />
          <Skeleton height="size-4" width="size-full" />
          <Skeleton height="size-4" width="size-full" />
          <Skeleton height="size-4" width="size-1/2" />
          <Skeleton height="size-4" width="size-full" />
        </Block>
      }
      overflow="visible"
      color="neutral-300"
      vertical
      size="lg"
      steps={[
        {
          title: (
            <Meta
              title={<Text size="sm">{t('transactions.transaction-number')}</Text>}
              description={<TransactionID data={data} />}
            />
          ),
          icon: <Icon color="secondary-500" size="lg" type="o:hashtag" />,
        },
        {
          title: (
            <Meta
              title={<Text size="sm">{t('transactions.transaction-type')}</Text>}
              description={<TransactionTitle data={data} />}
            />
          ),
          icon: <Icon color="secondary-500" size="lg" type="o:arrows-right-left" />,
        },
        {
          title: (
            <Meta
              title={<Text size="sm">{t('transactions.transaction-date')}</Text>}
              description={
                <Text weight="medium" color="secondary-500">
                  {dayjs(data?.created_at, { locale: i18n.language }).format('DD MMM YYYY, HH:mm')}
                </Text>
              }
            />
          ),
          icon: <Icon color="secondary-500" size="lg" type="o:clock" />,
        },
        {
          title: <AccountType data={data} />,
          icon: <Icon color="secondary-500" size="lg" type="o:circle-stack" />,
        },
        {
          title: (
            <Meta
              title={<Text size="sm">{t('transactions.transaction-status')}</Text>}
              description={
                <Text weight="medium" color={TRANSACTION_STATUS[data?.status as TransactionStatusType].text}>
                  {t(`transactions.statuses.${data?.status}`)}
                </Text>
              }
            />
          ),
          icon: <Icon color="secondary-500" size="lg" type="o:document-check" />,
        },
      ]}
    />
  );
}

export default Index;

import { Block, Button, Layout, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { IconCustom } from '@Components/icon/CustomIcon';

const { Content } = Layout;

interface Props {
  /** Error code to be displayed */
  errorCode: string;
  /** Whether authentication service is down */
  isAuthServiceDown?: boolean;
}

/**
 * Component for displaying generic error messages with an error code
 * Displays custom message when auth service is down.
 */
function GeneralError({ errorCode, isAuthServiceDown }: Props) {
  const { t } = useTranslation();

  return (
    <Content style={{ height: '100%' }}>
      <Block height="size-full" width="size-full" justify="center" items="center">
        <Block items="center" maxWidth="md">
          <IconCustom type="oops-illustration" />
          <Text heading size="xl" mb="lg" mt="xl" align="center">
            {isAuthServiceDown ? t('error.page-is-under-maintenance') : t('error.something-went-wrong')}
            <br />
            {t('error.error-code', { code: errorCode })}
          </Text>

          {!isAuthServiceDown && (
            <Text align="center" mb="2xl">
              {t('error.refresh-then-contact-us')}
            </Text>
          )}
          <Button
            variant="outline"
            size="xl"
            onClick={() => {
              window.location.href = '/';
            }}
          >
            {t('error.go-homepage')}
          </Button>
        </Block>
      </Block>
    </Content>
  );
}

export default GeneralError;

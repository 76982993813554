import React, { memo, useCallback, useState } from 'react';

import { Flex, Label, Panel, StepProgress, Text } from '@bilira-org/design';
import { BankAccountType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { TRACKER_EVENTS } from '@Libs/tracker/events';
import { addTrackerTwoFa } from '@Libs/tracker/helpers/addTrackerAccount';
import ActiveBankPanel from '@Modules/fiat/withdraw/components/components/ActiveBankPanel';
import WithdrawForm from '@Modules/fiat/withdraw/components/withdrawForm/WithdrawForm';

const FiatWithdraw = () => {
  const { t } = useTranslation();
  const [bankAccount, setBankAccount] = useState<BankAccountType>();
  const [isWithdrawFormLoading, setIsWithdrawFormLoading] = useState(true);

  const onWithdrawFormLoaded = useCallback(() => setIsWithdrawFormLoading(false), []);

  return (
    <Panel border="neutral-400">
      <Flex width={{ xl: '3/4' }}>
        <StepProgress
          hideStepIconOnSm
          vertical
          borderStyle="dashed"
          size="sm"
          alignItems="center"
          steps={[
            {
              title: (
                <>
                  <Text size="lg" weight="medium" mb="2xl" color="secondary-500">
                    {t('fiat.bank-selection')}
                  </Text>
                  <Label>{t('bank-account.iban')}</Label>
                  <ActiveBankPanel
                    callback={(bank) => {
                      addTrackerTwoFa(TRACKER_EVENTS.FIAT_WITHDRAWAL.SELECT_BANK_ACCOUNT, { bank });
                      setBankAccount(bank);
                    }}
                  />
                </>
              ),
              active: true,
              complete: !!bankAccount,
            },
            {
              title: (
                <>
                  <Text size="lg" weight="medium" mb="2xl" color="secondary-500">
                    {t('common.withdraw-amount')}
                  </Text>
                  <WithdrawForm bankAccount={bankAccount?.id} onLoaded={onWithdrawFormLoaded} />
                </>
              ),
              hidden: !bankAccount,
              active: true,
              loading: isWithdrawFormLoading,
            },
          ]}
        />
      </Flex>
    </Panel>
  );
};

export default memo(FiatWithdraw);

import React, { memo } from 'react';

import { Block, Panel } from '@bilira-org/design';
import { AssetPairType } from '@bilira-org/react-utils';

import AssetInformationContainer from '@Modules/market/detail/components/information/AssetInformationContainer';
import AssetPairChart from '@Modules/market/detail/components/priceChart';
import RelatedPosts from '@Modules/market/detail/components/relatedPosts/RelatedPosts';

type Props = {
  assetPair: AssetPairType;
};

const PriceChartContainer = ({ assetPair }: Props) => {
  return (
    <>
      <Block gap="4xl">
        <Panel border="neutral-400" size="none">
          <AssetPairChart />
        </Panel>
        <Panel border="neutral-400">
          <AssetInformationContainer assetPair={assetPair} />
        </Panel>
        <RelatedPosts assetPair={assetPair} />
      </Block>
    </>
  );
};

export default memo(PriceChartContainer);

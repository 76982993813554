/**
 * Cleans an object by removing properties based on a filter function.
 *
 * @template T - The type of the object to be cleaned.
 *
 * @param {T} obj - The object to be cleaned.
 * @param {(value: any) => boolean} [filterFunction=(value) => value !== undefined] -
 *        A function used to filter which properties to keep. Defaults to removing `undefined` values.
 *        It should return `true` to keep the value, and `false` to omit it.
 *
 * @returns {Partial<T>} - A new object with properties that passed the filter function.
 *
 * @example
 * // Basic usage:
 * const obj = { a: 1, b: undefined, c: null };
 * const cleaned = cleanObject(obj); // { a: 1, c: null }
 *
 * @example
 * // Custom filter function to remove null values:
 * const obj = { a: 1, b: undefined, c: null };
 * const cleaned = cleanObject(obj, value => value !== null); // { a: 1, b:undefined }
 */
export function cleanObject<T extends Record<string, any>>(
  obj: T,
  filterFunction: (value: any) => boolean = (value) => value !== undefined,
) {
  const newObj: Record<any, any> = {};

  Object.keys(obj).forEach((key) => {
    if (filterFunction(obj[key])) {
      newObj[key] = obj[key];
    }
  });

  return newObj as Partial<T>;
}

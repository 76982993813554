import { LayoutV2 } from '@bilira-org/design';
import { Outlet } from 'react-router-dom';

import ErrorBoundary from '../errorBoundary/ErrorBoundary';

function LayoutEmpty() {
  return (
    <ErrorBoundary>
      <LayoutV2>
        <Outlet />
      </LayoutV2>
    </ErrorBoundary>
  );
}

export default LayoutEmpty;

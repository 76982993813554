import { useCallback, useEffect, useRef } from 'react';

import { eventEmitter } from '@Libs/EventEmitter';

type Props = {
  callback: () => void;
  eventName: string;
};

/**
 * Subscribes to a scroll event and invokes a callback function when the event is triggered.
 *
 * @param {Object} Props - The properties required for subscribing to the scroll event.
 * @param {Function} Props.callback - The callback function to be invoked when the scroll event is triggered.
 * @param {string} Props.eventName - The name of the scroll event to subscribe to.
 *
 */
const useSubscribeToScroll = ({ callback, eventName }: Props) => {
  useEffect(() => {
    eventEmitter.on(eventName, callback);
    return () => {
      eventEmitter.off(eventName, callback);
    };
  }, [callback, eventName]);

  return null;
};

type RegisterScrollProps = {
  eventName: string;
};

/**
 * Registers a scroll end event and emits the specified event name when the scroll reaches the bottom.
 * @param {RegisterScrollProps} props - The properties for registering the scroll end event.
 */
const useRegisterScrollEnd = ({ eventName }: RegisterScrollProps) => {
  const mainRef = useRef<HTMLDivElement>(null);

  const onScroll = useCallback(() => {
    if (mainRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = mainRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight;
      if (isNearBottom) {
        eventEmitter.emit(eventName);
      }
    }
  }, [eventName]);

  useEffect(() => {
    const listInnerElement = mainRef.current;

    if (listInnerElement) {
      listInnerElement.addEventListener('scroll', onScroll);
      // Clean-up
      return () => {
        listInnerElement.removeEventListener('scroll', onScroll);
      };
    }
  }, [onScroll]);

  return mainRef;
};

export { useRegisterScrollEnd, useSubscribeToScroll };

import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import fixIosInputFocus from '../../helpers/fixIosInputFocus';
import { BaseComponentType } from '../types';

export type InputCodeProps = BaseComponentType & {
  /** Allowed character types for the input */
  allowedCharacters?: 'alpha' | 'numeric' | 'alphanumeric';
  /** ARIA label for the input */
  ariaLabel?: string;
  /** Whether the input should autoFocus (default: true) */
  autoFocus?: boolean;
  /** Whether the input is disabled */
  disabled?: boolean;
  /** The length of the input (default: 5) */
  length?: number;
  /** Placeholder text for the input */
  placeholder?: string;
  /** Callback triggered on input change */
  onChange?: (res: string, completed: boolean) => void;
};

type InputMode = 'text' | 'numeric';
type InputType = 'text' | 'password';

type InputProps = {
  type: InputType;
  inputMode: InputMode;
  pattern: string;
  min?: string;
  max?: string;
};

export type InputCodeRef = {
  /** Focuses on the first input */
  focus: () => void;
  /** Clears the input values */
  clear: () => void;
};

const propsMap: { [key: string]: InputProps } = {
  alpha: {
    type: 'text',
    inputMode: 'text',
    pattern: '[a-zA-Z]{1}',
  },

  alphanumeric: {
    type: 'text',
    inputMode: 'text',
    pattern: '[a-zA-Z0-9]{1}',
  },

  numeric: {
    type: 'text',
    inputMode: 'numeric',
    pattern: '[0-9]{1}',
    min: '0',
    max: '9',
  },
};

/**
 * InputCode component for entering a sequence of characters.
 *
 * @example
 * <InputCode type="alpha" onChange={handleChange} />
 */
const InputCode = forwardRef<InputCodeRef, InputCodeProps>(
  (
    { allowedCharacters = 'numeric', ariaLabel, autoFocus = true, disabled, length = 5, placeholder, onChange, testId },
    ref,
  ) => {
    if (isNaN(length) || length < 1) {
      throw new Error('Length should be a number and greater than 0');
    }

    const inputsRef = useRef<Array<HTMLInputElement>>([]);
    const inputProps = propsMap[allowedCharacters];

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputsRef.current) {
          inputsRef.current[0].focus();
        }
      },
      clear: () => {
        if (inputsRef.current) {
          for (let i = 0; i < inputsRef.current.length; i++) {
            inputsRef.current[i].value = '';
          }
          inputsRef.current[0].focus();
        }
        sendResult();
      },
    }));

    useEffect(() => {
      if (autoFocus) {
        inputsRef.current[0].focus();
      }
    }, []);

    const sendResult = () => {
      const res = inputsRef.current.map((input) => input.value).join('');
      onChange && onChange(res, res.length === length);
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value, nextElementSibling },
      } = e;
      if (value.length > 1) {
        e.target.value = value.charAt(0);
        if (nextElementSibling !== null) {
          (nextElementSibling as HTMLInputElement).focus();
        }
      } else {
        if (value.match(inputProps.pattern)) {
          if (nextElementSibling !== null) {
            (nextElementSibling as HTMLInputElement).focus();
          }
        } else {
          e.target.value = '';
        }
      }
      sendResult();
    };

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const { key } = e;
      const target = e.target as HTMLInputElement;
      if (key === 'Backspace') {
        if (target.value === '') {
          if (target.previousElementSibling !== null) {
            const t = target.previousElementSibling as HTMLInputElement;
            t.value = '';
            t.focus();
            e.preventDefault();
          }
        } else {
          target.value = '';
        }
        sendResult();
      }
    };

    const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      fixIosInputFocus(e.currentTarget);

      e.target.select();
    };

    const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
      const pastedValue = e.clipboardData.getData('Text');

      let currentInput = 0;

      for (let i = 0; i < pastedValue.length; i++) {
        const pastedCharacter = pastedValue.charAt(i);
        const currentValue = inputsRef.current[currentInput].value;
        if (pastedCharacter.match(inputProps.pattern)) {
          if (!currentValue) {
            inputsRef.current[currentInput].value = pastedCharacter;
            if (inputsRef.current[currentInput].nextElementSibling !== null) {
              (inputsRef.current[currentInput].nextElementSibling as HTMLInputElement).focus();
              currentInput++;
            }
          }
        }
      }
      sendResult();
      e.preventDefault();
    };

    const inputs = [];
    for (let i = 0; i < length; i++) {
      inputs.push(
        <input
          key={i}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onFocus={handleOnFocus}
          onPaste={handleOnPaste}
          {...inputProps}
          type={inputProps.type}
          ref={(el: HTMLInputElement) => {
            inputsRef.current[i] = el;
          }}
          maxLength={1}
          className="input-code"
          autoComplete={i === 0 ? 'one-time-code' : 'off'}
          aria-label={ariaLabel ? `${ariaLabel}. Character ${i + 1}.` : `Character ${i + 1}.`}
          disabled={disabled}
          placeholder={placeholder}
        />,
      );
    }

    return (
      <div className="input-code-container" data-testid={testId ?? 'input-code'}>
        {inputs}
      </div>
    );
  },
);

export default InputCode;

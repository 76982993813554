import React from 'react';

import { Row } from '@tanstack/react-table';
import { clsx } from 'clsx';

import { TableBodyStyle } from './types';
import { Separator } from '../../separator';

interface ExpandedRowProps<T> {
  row: Row<T>;
  bodyStyle?: TableBodyStyle;
  renderSubComponent?: (row: T) => React.ReactNode;
}

function ExpandedRow<T>({ row, bodyStyle, renderSubComponent }: ExpandedRowProps<T>) {
  if (!row.getIsExpanded()) {
    return null;
  }
  return (
    <tr className={clsx('table-row expand-content')}>
      <td
        className={clsx('row-cell', {
          [`mt-${bodyStyle?.mt}`]: bodyStyle?.mt,
          [`pb-${bodyStyle?.py}`]: bodyStyle?.py,
          [`px-${bodyStyle?.px}`]: bodyStyle?.px,
        })}
        colSpan={row.getVisibleCells().length}
      >
        <Separator width="size-full" mb={bodyStyle?.py} color="neutral-400" />
        {renderSubComponent?.(row.original)}
      </td>
    </tr>
  );
}

export default ExpandedRow;

import React from 'react';

import type { TransactionsRecordType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import FormattedNumber from '@/components/common/FormattedNumber';
import FormattedNumberByPrice from '@/components/common/FormattedNumberByPrice';
import { getTransactionSuffix } from '@Components/transaction/helpers';
import { TRANSACTION_STATUS } from '@Libs/helpers/getStateConfig';

import { TransactionItemSizeType } from '../../TransactionTable';
import { transactionFormatPriceProps, transactionTextSize } from '../constants';

type Props = {
  data: TransactionsRecordType;
  size: TransactionItemSizeType;
};

const TransactionAmount = ({ data, size }: Props) => {
  return (
    <FormattedNumberByPrice
      formatPriceProps={transactionFormatPriceProps}
      align="right"
      wordBreak="all"
      size={transactionTextSize[size]}
      suffixSize={transactionTextSize[size]}
      suffix={getTransactionSuffix(data)}
      weight="medium"
      suffixWeight="medium"
      color={TRANSACTION_STATUS[data.status || 'completed']?.text}
      value={data.amount}
      type="amount"
      base={data.asset || 'TRYB'}
    />
  );
};

export default TransactionAmount;

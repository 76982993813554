import { CSSProperties } from 'react';

import { BadgeColors, IconCustomType } from '@bilira-org/design';
import { TextColorType } from '@bilira-org/design/types';
import { RewardTaskState } from '@bilira-org/react-utils';

import { RewardTaskProps } from '../RewardTask';

type RewardTaskConfigType = {
  panelStyle?: CSSProperties;
  badgeColor: BadgeColors;
  textColor: TextColorType;
  iconType: IconCustomType;
  opacity?: number;
  showExpiresAt?: boolean;
  iconColor?: TextColorType;
  endIconColor?: TextColorType;
  pointsColor?: TextColorType;
};

const rewardTaskConfigByTaskState: Record<
  RewardTaskProps['color'],
  Partial<Record<RewardTaskState, RewardTaskConfigType>> & Record<'default', RewardTaskConfigType>
> = {
  'theme-wn': {
    recurringCoolDown: {
      badgeColor: 'theme-n300d700',
      textColor: 'neutral-900',
      iconType: 'star-coin',
      opacity: 0.5,
      showExpiresAt: true,
    },
    default: {
      badgeColor: 'theme-n300d700',
      textColor: 'neutral-900',
      iconType: 'star-coin',
    },
  },
  'theme-n200d800': {
    completed: {
      panelStyle: { '--tw-bg-opacity': 0.7 } as CSSProperties,
      badgeColor: 'theme-n300d700/50',
      textColor: 'neutral-600',
      iconType: 'star-coin-ghost',
      iconColor: 'neutral-600',
      endIconColor: 'neutral-500',
      pointsColor: 'neutral-600',
    },
    recurringCompleted: {
      panelStyle: { '--tw-bg-opacity': 0.7 } as CSSProperties,
      badgeColor: 'theme-n300d700/50',
      textColor: 'neutral-600',
      iconType: 'star-coin-ghost',
      iconColor: 'neutral-600',
      endIconColor: 'neutral-500',
      pointsColor: 'neutral-600',
    },
    recurringCoolDown: {
      badgeColor: 'theme-n300d700',
      textColor: 'neutral-900',
      iconType: 'star-coin',
      opacity: 0.5,
      showExpiresAt: true,
    },
    default: {
      badgeColor: 'theme-n300d700',
      textColor: 'neutral-900',
      iconType: 'star-coin',
    },
  },
};

export default rewardTaskConfigByTaskState;

import React from 'react';

import { Button, Icon, Meta, Panel, StepProgress, useSafeLocalStorage } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation();
  const [hidden, setHidden] = useSafeLocalStorage('CW-SHP', false);

  if (hidden) {
    return null;
  }

  return (
    <>
      <Panel
        mb="4xl"
        color="theme-n200d800"
        extra={
          <Button
            size="md"
            variant="transparent"
            startIcon={<Icon color="neutral-700" type="o:x-mark" />}
            onClick={() => setHidden(true)}
          />
        }
      >
        <StepProgress
          maxWidth="3xl"
          responsive
          size="sm"
          steps={[
            {
              title: (
                <Meta title={t('crypto.withdraw-steps.1.title')} description={t('crypto.withdraw-steps.1.desc')} />
              ),
            },
            {
              title: (
                <Meta title={t('crypto.withdraw-steps.2.title')} description={t('crypto.withdraw-steps.2.desc')} />
              ),
            },
            {
              title: (
                <Meta title={t('crypto.withdraw-steps.3.title')} description={t('crypto.withdraw-steps.3.desc')} />
              ),
            },
          ]}
        />
      </Panel>
    </>
  );
};

export default Header;

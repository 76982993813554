/**
 * Returns a function that retrieves the value of a specified query parameter from a URL.
 *
 * @example
 * const url = 'https://example.com?a=b&c=d';
 * const getParam = getSearchParamFromURL(url);
 * console.log(getParam('a'));  // Outputs: b
 * console.log(getParam('c'));  // Outputs: d
 * console.log(getParam('e'));  // Outputs: undefined
 *
 * @param {string} url - The URL containing the query parameters.
 * @return {function(field: string): (string | undefined | number)} A function that accepts a field name and returns its corresponding value from the URL.
 */
const getSearchParamFromURL = (url: string): ((field: string) => string | undefined | number) => {
  const parseQueryString = (queryString: string): Record<string, string> => {
    return queryString.split('&').reduce(
      (acc, pair) => {
        const [key, value] = pair.split('=');

        acc[key] = decodeURIComponent(value);
        return acc;
      },
      {} as Record<string, string>,
    );
  };

  const getQueryStringFromUrl = (url: string): string => {
    const urlParts = url.split('?');
    return urlParts.length > 1 ? urlParts[1] : urlParts[0];
  };

  const queryString = getQueryStringFromUrl(url);

  const queryObject = parseQueryString(queryString);

  return (field: string) => {
    return queryObject[field];
  };
};

export default getSearchParamFromURL;

import React from 'react';

import { DataTableProps, Overflow } from '@bilira-org/design';
import { AssetListType, CoinSymbolType } from '@bilira-org/react-utils';

import { StorageValues } from '@/store/localStorage';
import MarketPriceSubscription from '@Components/live/subscription/GeneralPriceSubscription';
import { useSubscribeToScroll } from '@Libs/hooks/useScrollEvents';
import useMarketAssetList from '@Modules/market/market/table/components/hooks/useMarketAssetList';
import MarketTable from '@Modules/market/market/table/components/MarketTable';

type Props = {
  dataTag?: string;
  exclude?: CoinSymbolType[];
  quote?: CoinSymbolType;
  sorting?: StorageValues['MARKET_TABLE_SORT'];
  onSortingChange?: Exclude<DataTableProps<AssetListType>['onSortingChange'], undefined | string>;
};
const MarketTableContent = ({ dataTag, exclude, quote, sorting, onSortingChange }: Props) => {
  const { assetList, isPending, fetchNextPage, isFetchingNextPage, subscriptionList } = useMarketAssetList({
    dataTag,
    exclude,
    quote,
    sort: {
      field: sorting?.field,
      direction: sorting?.direction,
    },
  });

  useSubscribeToScroll({ eventName: 'reached-content-scroll-bottom', callback: fetchNextPage });

  return (
    <>
      <Overflow overflow="x-auto">
        <MarketPriceSubscription
          channel="market-table"
          baseQuoteList={subscriptionList}
          events="last_price,symbol_statistics"
        />

        <MarketTable
          isFetchingNextPage={isFetchingNextPage}
          assetList={assetList}
          isPending={isPending}
          quoteAsset={quote}
          sorting={sorting?.original}
          onSortingChange={onSortingChange}
        />
      </Overflow>
    </>
  );
};

export default MarketTableContent;

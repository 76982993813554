import { useMemo } from 'react';

import { validateKycCryptoWithdraw } from '@bilira-org/react-utils';

import useAuth from '@/libs/hooks/userAuth';
import useUserInfo from '@Libs/hooks/useUserInfo';

const useWithdrawalValidation = () => {
  const { kycLevel, isPending: isUserInfoPending } = useUserInfo();
  const { bankVerified, isPending: isAuthPending } = useAuth();

  const { error } = useMemo(() => {
    if (isUserInfoPending || isAuthPending) {
      return { error: undefined };
    }

    return validateKycCryptoWithdraw({
      kycLevel: kycLevel,
      bankVerified,
      errorMessages: {
        kyc2AndBankNotVerified: 'crypto.withdraw-not-allowed.kyc2-and-bank',
        kyc2NotVerified: 'crypto.withdraw-not-allowed.kyc2',
        bankNotVerified: 'crypto.withdraw-not-allowed.bank',
      },
    });
  }, [isUserInfoPending, isAuthPending, kycLevel, bankVerified]);

  return error;
};

export default useWithdrawalValidation;

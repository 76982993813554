import React from 'react';

import { BaseComponentType } from '../types';

export type SelectOptionProps<T> = Omit<React.OptionHTMLAttributes<HTMLOptionElement>, 'value'> &
  BaseComponentType & {
    /** Currently selected value */
    selectedValue?: T;
    /** Value of the option */
    value: T;
  };

/**
 * SelectOption component for the `Select` dropdown
 *
 * @example
 * <SelectOption value="1" selectedValue="1">Option 1</SelectOption>
 * <SelectOption value="2" selectedValue="1">Option 2</SelectOption>
 */
const SelectOption = <T extends string | number | undefined>({
  selectedValue,
  value,
  children,
  testId,
  ...props
}: SelectOptionProps<T>): JSX.Element => {
  return (
    <option {...props} value={value} selected={value === selectedValue} data-testid={testId ?? 'select-option'}>
      {children}
    </option>
  );
};

export default SelectOption;

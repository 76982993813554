import { Button, Empty, Link } from '@bilira-org/design';
import {
  TransactionDirectionType,
  TransactionsRecordType,
  TransactionStatusType,
  TransactionType,
} from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import TransactionTable from '@Components/transaction/TransactionTable';

export type TransactionCardProps = {
  /** Array of transaction records */
  result: TransactionsRecordType[];
  /** Whether transactions are pending */
  isPending: boolean;
  /** Number of transactions to display per page */
  limit?: number;
  /** Function to be called when a transaction record is clicked */
  tableCallback: (item: TransactionsRecordType) => void;
  /** Whether to show sub amounts in the table */
  showSubAmount?: boolean;
  /** Whether to show "Show All" button */
  showAllButton?: boolean;
  /** Type of transaction to filter */
  type?: TransactionType;
  /** Direction of transaction to filter*/
  direction?: TransactionDirectionType;
  /** Status of transaction to filter */
  status?: TransactionStatusType;
};

/**
 * Component for rendering transaction table and optional "Show All" button.
 */
const TransactionCard = ({
  result,
  isPending,
  limit,
  tableCallback,
  showSubAmount,
  showAllButton,
  type,
  direction,
  status,
}: TransactionCardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <TransactionTable
        empty={
          !showAllButton ? undefined : (
            <Empty
              gap="md"
              extra={
                <Button variant="link" size="md" onClick={() => navigate('/fiat/deposit')}>
                  {t('transactions.deposit-tl-now')}
                </Button>
              }
              message={t('common.empty-history-data-message')}
            />
          )
        }
        limit={limit}
        skeletonLine={limit}
        loading={isPending}
        data={result}
        callback={tableCallback}
        showSubAmount={showSubAmount}
        hideOTCTag
        size="sm"
      />

      {showAllButton && result.length > 0 && (
        <Link to="/transaction-history" state={{ type, direction, status }} width="size-full">
          <Button justify="center" stretch variant="outline" size="md" mt="md">
            {t('common.show-all')}
          </Button>
        </Link>
      )}
    </>
  );
};

export default TransactionCard;

import React, { memo } from 'react';

import { Modal, notification } from '@bilira-org/design';
import { AccountQueryKey } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { queryCache } from '@/libs/clientInstances/queryClient';
import WarningTemplate from '@Components/template/WarningTemplate';
import SwapQuery from '@Libs/clientInstances/swapQuery';
import { handleErrorResponse } from '@Libs/utils';
import WarningContent from '@Modules/otc/otcTrade/WarningContent';

type Props = {
  active: boolean;
  setActive: (active: boolean) => void;
  onAccept: () => void;
  data: string;
  callback: () => void;
};

const RejectOfferModal = ({ data, callback, active, setActive }: Props) => {
  const { t } = useTranslation();

  const { mutateAsync, isPending } = SwapQuery.usePatchOTCTransaction();

  const onConfirm = () => {
    mutateAsync({ id: data, body: { action: 'reject' } })
      .then(() => {
        queryCache.resetQueries([AccountQueryKey.ACCOUNT_ALL_TRANSACTION, { transactionId: data }]);
        notification.success(t('otc.reject-modal.success-info'));
        callback();
      })
      .catch(handleErrorResponse);
  };

  return (
    <Modal open={active} overlayClosable={false} onClose={() => setActive(false)}>
      <WarningTemplate
        onAccept={onConfirm}
        onCancel={() => setActive(false)}
        cancelText={t('otc.reject-modal.cancel')}
        confirmText={t('otc.reject-modal.confirm')}
        confirmLoading={isPending}
      >
        <WarningContent />
      </WarningTemplate>
    </Modal>
  );
};
export default memo(RejectOfferModal);

import { clsx } from 'clsx';

import { Block } from '../../block';
import { Separator } from '../../separator';

interface Props {
  /** Whether to show a divider between grid entries */
  divider?: boolean;
  /**  The content to be displayed inside the entry. */
  children: React.ReactNode;
  /** Additional class names */
  className?: string;
  /** Variant for highlighting entry */
  highlightVariant?: 'bordered' | 'marker';
  /** Flag for whether entry is highlighted or not */
  highlighted?: boolean;
}

/**
 *  A component representing an entry in a data grid layout.
 */
function DataGridEntry({ divider, children, className, highlightVariant, highlighted }: Props) {
  return (
    <Block className={clsx({ [`highlighted-${highlightVariant}`]: highlighted }, className)}>
      {divider && <Separator width="size-full" color="neutral-400" />}
      {children}
    </Block>
  );
}

export default DataGridEntry;

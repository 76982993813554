import React from 'react';

import { Block, Loop, Meta, Overflow, Panel, Skeleton } from '@bilira-org/design';
import { isArrayEmpty } from '@bilira-org/react-utils';

import IconCoin from '@Components/icon/IconCoin';
import H24ChangeLive from '@Components/live/H24ChangeLive';
import H24SparklineLive from '@Components/live/H24SparklineLive';
import LastPriceLive from '@Components/live/LastPriceLive';
import useAssetCardsHook from '@Modules/market/market/table/components/hooks/useAssetCardsHook';

const CryptoCardWidgets = () => {
  const { assetList, callback, isPending } = useAssetCardsHook({ dataTag: 'trend' });

  if (isArrayEmpty(assetList) && !isPending) {
    return null;
  }

  if (isPending) {
    return <CryptoCardWidgetsSkeleton />;
  }

  return (
    <Overflow overflow="x-scroll" height="size-36">
      <Block row gap="lg" minHeight="fit" width="size-full" position="absolute">
        {assetList?.map((item) => (
          <Panel
            key={`crypto-widget-${item.base_asset}`}
            border="neutral-400"
            onClick={() => callback(item)}
            hover="background"
          >
            <Meta space="sm" extra={<IconCoin size="2xl" type={item.base_asset} />} title={item.base_asset} />
            <Meta
              mt="md"
              extra={<H24SparklineLive base={item.base_asset} quote="TRYB" change24h={item.change_24} />}
              title={<LastPriceLive style={{ width: '140px' }} base={item.base_asset} quote="TRYB" />}
              description={<H24ChangeLive base={item.base_asset} value={item.change_24} quote="TRYB" />}
              position="right"
            />
          </Panel>
        ))}
      </Block>
    </Overflow>
  );
};

export default CryptoCardWidgets;

const CryptoCardWidgetsSkeleton = () => {
  return (
    <Block row gap="lg">
      <Loop data={3}>
        <Panel border="neutral-400">
          <Meta
            extra={<Skeleton height="size-6" width="size-6" rounded="full" />}
            title={<Skeleton width="size-16" height="size-3" />}
          />
          <Meta
            mt="2xl"
            extra={<Skeleton width="size-16" height="size-3" />}
            title={<Skeleton width="size-24" height="size-3" />}
            description={<Skeleton width="size-9" height="size-3" />}
            gap="sm"
            items="end"
            position="right"
          />
        </Panel>
      </Loop>
    </Block>
  );
};

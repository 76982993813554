import { getRewardTaskSortingPrecedence } from './getRewardTaskSortingPrecedence';
import { UserParticipationType } from '../../model';

/**
 * Sorts an array of reward tasks based on their sorting precedence.
 *
 * @param {UserParticipationType[]} [tasks] - The list of tasks to be sorted.
 * @returns {UserParticipationType[]} - The sorted list of tasks. If no tasks are provided, returns an empty array.
 */
export const sortRewardTasks = (tasks?: UserParticipationType[]) => {
  if (!tasks) {
    return [];
  }

  return tasks.sort((a, b) => {
    return getRewardTaskSortingPrecedence(a) > getRewardTaskSortingPrecedence(b) ? -1 : 1;
  });
};

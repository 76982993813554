import React from 'react';

import { ERROR_PAGE_STATE } from '@bilira-org/react-utils';
import * as Sentry from '@sentry/react';
import { Navigate, useLocation } from 'react-router-dom';

import { useSessionStore } from '@/store';

type Props = {
  children: React.ReactNode;
};

/**
 * Component to handle and log errors to Sentry
 */
const ErrorBoundary = ({ children }: Props) => {
  const location = useLocation();
  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope, error) => {
        console.error(error);
        scope.setTag('biLiraErrorCode', ERROR_PAGE_STATE.UI_ERROR);
        scope.setUser({ id: useSessionStore.getState().user?.id || 'Unknown' });
      }}
      fallback={(error) => {
        console.error(error);
        Sentry.captureException(error, {
          user: { id: useSessionStore.getState().user?.id },
          tags: {
            errorType: 'UI',
            biLiraErrorCode: ERROR_PAGE_STATE.UI_ERROR,
          },
        });

        return <Navigate to="/error" state={{ from: location, errorState: ERROR_PAGE_STATE.UI_ERROR }} />;
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;

import { TourStepType } from '@bilira-org/design';
import { AssetPairType, NotificationType, SwapAssetPairTradeType, UserInfo, UserKyc } from '@bilira-org/react-utils';
import { createWithEqualityFn } from 'zustand/traditional';

type ClientState = {
  isServerUnavailable: boolean;
  isLoggedOut: boolean;
};

interface GeneralStoreType {
  languageModalActive: boolean;
  setLanguageModalActive: (value: boolean) => void;

  privateDrawerOpen: boolean;
  setPrivateDrawerOpen: (value: boolean) => void;

  publicDrawerOpen: boolean;
  setPublicDrawerOpen: (value: boolean) => void;

  searchModalActive: boolean;
  setSearchModalActive: (value: boolean) => void;

  latelyModalActive: boolean;
  setLatelyModalActive: (value: boolean) => void;

  maskBalance: boolean;
  setMaskBalance: (value: boolean) => void;

  notificationModal: { open: boolean; title?: string; hideButton?: boolean; notification?: NotificationType };
  setNotificationModal: (value: GeneralStoreType['notificationModal']) => void;

  swapModal: {
    active: boolean;
    pairModalActive?: boolean;
    defaultPair?: SwapAssetPairTradeType;
  };

  setSwapModal: (params: { active: boolean; pairModalActive?: boolean; defaultPair?: SwapAssetPairTradeType }) => void;

  tour: {
    activeTour?: string;
    run: boolean;
    stepIndex: number;
    steps: (TourStepType & { url: string })[];
    completed?: Record<string, boolean>;
  };
  setTour: (value: Partial<GeneralStoreType['tour']>) => void;

  legalDocuments: { activeConsentIndex: number; invalidConsentsLength: number; active: boolean };
  setLegalDocuments: (value: Partial<GeneralStoreType['legalDocuments']>) => void;

  headerHeights: Record<string, number>;
  setHeaderHeights: (value: Record<string, number>) => void;
  clientState: Partial<ClientState> | null;
  setClientState: (data: Partial<ClientState>) => void;

  txExportModal?: boolean;
  setTxExportModal: (value: boolean) => void;
}

export const useGeneralStore = createWithEqualityFn<GeneralStoreType>((set) => ({
  languageModalActive: false,
  setLanguageModalActive: (value: boolean) => set(() => ({ languageModalActive: value })),

  privateDrawerOpen: false,
  setPrivateDrawerOpen: (value: boolean) => set(() => ({ privateDrawerOpen: value })),

  publicDrawerOpen: false,
  setPublicDrawerOpen: (value: boolean) => set(() => ({ publicDrawerOpen: value })),

  searchModalActive: false,
  setSearchModalActive: (value: boolean) => set(() => ({ searchModalActive: value })),

  latelyModalActive: false,
  setLatelyModalActive: (value: boolean) => set(() => ({ latelyModalActive: value })),

  maskBalance: false,
  setMaskBalance: (value: boolean) => set(() => ({ maskBalance: value })),

  notificationModal: { open: false, notification: undefined },
  setNotificationModal: (value: GeneralStoreType['notificationModal']) => set(() => ({ notificationModal: value })),

  swapModal: { active: false },
  setSwapModal: (params) => set(() => ({ swapModal: { ...params } })),

  tour: { run: false, stepIndex: 0, steps: [], completed: undefined },
  setTour: (value: Partial<GeneralStoreType['tour']>) => set((state) => ({ tour: { ...state.tour, ...value } })),

  clientState: null,
  setClientState: (data: Partial<ClientState>) => set((state) => ({ clientState: { ...state.clientState, ...data } })),

  legalDocuments: { activeConsentIndex: 0, invalidConsentsLength: 0, active: false },
  setLegalDocuments: (value: Partial<GeneralStoreType['legalDocuments']>) => {
    set((state) => {
      const activeConsentIndex = value.activeConsentIndex || state.legalDocuments.activeConsentIndex;
      const invalidConsentsLength = value.invalidConsentsLength || state.legalDocuments.invalidConsentsLength;
      const active = activeConsentIndex < invalidConsentsLength;

      return { legalDocuments: { activeConsentIndex, invalidConsentsLength, active } };
    });
  },

  headerHeights: {},
  setHeaderHeights: (value: Record<string, number>) =>
    set((state) => ({ headerHeights: { ...state.headerHeights, ...value } })),

  txExportModal: false,
  setTxExportModal: (value: boolean) => set(() => ({ txExportModal: value })),
}));

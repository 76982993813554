import React, { memo, useState } from 'react';

import { Icon, Modal } from '@bilira-org/design';
import { ReferralCodeType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { UpdateReferral } from './components';

interface Props {
  referralCode: ReferralCodeType;
  refetch: () => void;
}

function UpdateReferralButton({ referralCode, refetch }: Props) {
  const { t } = useTranslation();
  const [modalActive, setModalActive] = useState(false);

  return (
    <>
      <Icon size="md" type="o:pencil-square" color="secondary-500" onClick={() => setModalActive(true)} />

      <Modal title={t('referral.edit-title')} open={modalActive} onClose={() => setModalActive(false)}>
        <UpdateReferral
          referralCode={referralCode}
          onSubmit={() => {
            setModalActive(false);
            refetch();
          }}
          onClose={() => setModalActive(false)}
        />
      </Modal>
    </>
  );
}

export default memo(UpdateReferralButton);

import React, { useState } from 'react';

import { Button, Flex, Icon, Meta, Modal } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import ResetPasswordForm from '@Modules/account/security/changePassword/components/ChangePasswordForm';

function ChangePassword() {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);

  return (
    <>
      <Flex py="4xl" direction={{ xs: 'col', sm: 'row' }} gap="xl" justify="between" items={{ sm: 'center' }}>
        <Meta
          extra={<Icon size="lg" type="o:lock-closed" color="primary-500" />}
          position="left-top"
          title={t('account.security-settings.login-password')}
          description={t('account.security-settings.strengthen-password-for-security')}
        />
        <div>
          <Button onClick={() => setActive(true)} variant="outline" size="md">
            {t('common.change')}
          </Button>
        </div>
      </Flex>
      <Modal
        title={t('account.security-settings.change-login-password')}
        open={active}
        onClose={() => setActive(false)}
        compound
        limitMaxHeight
      >
        <ResetPasswordForm callback={() => setActive(false)} />
      </Modal>
    </>
  );
}

export default ChangePassword;

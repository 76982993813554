import { ReactNode } from 'react';

import { ArchiveBoxIcon } from '@heroicons/react/24/outline';

interface EmptyStateProps {
  emptyMessage: ReactNode;
}

export default function EmptyState({ emptyMessage }: EmptyStateProps) {
  return typeof emptyMessage === 'string' ? (
    <div className="tw-bg-theme-wn tw-text-neutral-500 h-80 flex justify-center items-center flex-col">
      <ArchiveBoxIcon className="h-16 w-16" />
      <h4>{emptyMessage}</h4>
    </div>
  ) : (
    <>{emptyMessage}</>
  );
}

import React, { ReactNode } from 'react';

import { clsx } from 'clsx';

import { BaseComponentType, BreakpointType, MarginSizes, PaddingSizes, ResponsiveReturnType } from '../types';
import { buildResponsiveClass } from '../utils';

type Props = BaseComponentType & {
  /** The content to be displayed within the modal footer. */
  children: ReactNode | React.ReactElement;
  /** Padding of the modal footer, single size or a responsive size object */
  padding?: PaddingSizes | BreakpointType<PaddingSizes>;
  /** Padding x-axis of the modal footer, single size or a responsive size object */
  px?: PaddingSizes | BreakpointType<PaddingSizes>;
  /** Padding y-axis of the modal footer, single size or a responsive size object */
  py?: PaddingSizes | BreakpointType<PaddingSizes>;
};

/**
 * The `ModalFooter` component is used to render the footer content of a modal.
 *
 * @example
 * <ModalFooter>
 *   Footer content
 * </ModalFooter>
 */
const ModalFooter = ({ children, padding = '2xl', px, py, testId }: Props) => {
  let paddingClass: ResponsiveReturnType = {};
  let pxClass: ResponsiveReturnType = {};
  let pyClass: ResponsiveReturnType = {};

  if (padding) {
    paddingClass = buildResponsiveClass(padding, 'p-');
  }
  if (px) {
    pxClass = buildResponsiveClass(px, 'px-');
  }
  if (py) {
    pyClass = buildResponsiveClass(py, 'py-');
  }

  return (
    <div className={clsx('footer block', paddingClass, pxClass, pyClass)} data-testid={testId ?? 'modal-footer'}>
      {children}
    </div>
  );
};

ModalFooter.displayName = 'ModalFooter';

export default ModalFooter;

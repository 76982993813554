import React from 'react';

import { Block, Display, Icon, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

export type IconProps = Omit<React.ComponentProps<'svg'>, 'type'> & {
  /** Type of the icon */
  type?: 'icon' | 'logo';
};

/**
 * Bank icon component that renders other bank icon.
 * @example
 * // Example usage of the IconBank component
 * <OtherBankIcon type="icon"/>
 */
export function OtherBankIcon({ type = 'logo' }: IconProps) {
  const { t } = useTranslation();

  return (
    <Block row items="center" gap="xs">
      <Block p="xs">
        <Icon size="lg" type="o:building-library" color="secondary-500" />
      </Block>
      <Display show={type !== 'icon'}>
        <Text weight="medium" color="secondary-500">
          {t('fiat.other-banks')}
        </Text>
      </Display>
    </Block>
  );
}

import axios, {
  AxiosError,
  AxiosHeaderValue,
  AxiosInstance,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

export type InstanceParamsType = {
  withCredentials: boolean;
};

export interface HttpErrorType extends AxiosError {}

export interface HttpClientType {
  baseUrl: string;
  accessToken?: string;
  'X-API-KEY'?: string;
  params?: InstanceParamsType;
  getAccessToken?: () => string | undefined | null;
  getLocale?: () => string | undefined;
  getExtraHeaders?: () => Record<string, AxiosHeaderValue>;
}

/**
 * Abstract class serving as the base for HTTP clients.
 * @class HttpClient
 */
export abstract class HttpClient {
  /** The Axios instance used for making HTTP requests. */
  protected readonly instance: AxiosInstance;

  /** The access token used for authorization. */
  _accessToken: string | undefined;

  /** Function to get the access token dynamically. */
  _getAccessToken: () => string | undefined | null;

  /** Function to get the current locale. */
  _getLocale: () => string | undefined;

  /** Function to get extra request headers */
  _getExtraHeaders?: () => Record<string, AxiosHeaderValue>;

  /**
   * Constructs a new instance of the HttpClient class.
   * @constructor
   * @param {HttpClientType} props - The configuration options for the HTTP client.
   */
  protected constructor(props: HttpClientType) {
    this._getAccessToken = props.getAccessToken || (() => undefined);
    this._getLocale = props.getLocale || (() => undefined);
    this._getExtraHeaders = props.getExtraHeaders;
    this._accessToken = props.accessToken;
    this.instance = axios.create({
      ...(props.params ?? {}),
      baseURL: props.baseUrl,
    });

    this._initializeResponseInterceptor();
    this._initializeRequestInterceptor();
  }

  /**
   * Initializes the response interceptor for the Axios instance.
   * @private
   */
  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(this._handleResponse, this._handleError);
  };

  /**
   * Initializes the request interceptor for the Axios instance.
   * @private
   */
  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this._handleRequest, this._handleError);
  };

  /**
   * Handles the modification of the request before it is sent.
   * This method ensures that necessary headers, such as Authorization and Accept-Language are added.
   * @param {InternalAxiosRequestConfig} config - The Axios request configuration.
   * @returns {InternalAxiosRequestConfig} - The modified Axios request configuration.
   * @private
   */
  private _handleRequest = (config: InternalAxiosRequestConfig) => {
    if (config.headers === undefined) {
      config.headers = {} as AxiosRequestHeaders;
    }

    const accessToken = this._getAccessToken() ?? this._accessToken;
    const locale = this._getLocale?.();

    if (accessToken) {
      config.headers.Authorization = accessToken;
    }
    config.headers['Accept-Language'] = locale;

    if (this._getExtraHeaders) {
      const extraHeaders = this._getExtraHeaders();

      for (const field in extraHeaders) {
        config.headers[field] = extraHeaders[field];
      }
    }

    return config;
  };

  /**
   * Handles the response after it is received.
   * @param {AxiosResponse} response - The Axios response.
   * @returns {*} - The data from the response object.
   * @private
   */
  private _handleResponse = ({ data }: AxiosResponse) => data;

  /**
   * Handles HTTP errors.
   * @param {HttpErrorType} error - The Axios error.
   * @returns {Promise<HttpErrorType>} - A rejected Promise containing the error.
   * @protected
   */
  protected _handleError = (error: HttpErrorType) => Promise.reject<HttpErrorType>(error as HttpErrorType);
}

import BigNumber from 'bignumber.js';

import { compareCurrencies } from '../currency';

const errorMessages = {
  hasLessBalanceThanFee: 'hasLessBalanceThanMinimum',
  hasLessBalanceThanMinimum: 'hasLessBalanceThanMinimum',
  hasLessTxLimitThanMinimum: 'hasLessTxLimitThanMinimum',
};

interface Props {
  /** The minimum transaction limit allowed. */
  txMin: string;
  /** The maximum transaction limit allowed. */
  txMax: string;
  /** The free balance available for the withdrawal. */
  freeBalance?: string;
  /** The minimum withdrawal fee. */
  minWithdrawFee?: string;
  /** An object containing error messages. */
  errorMessages?: Partial<typeof errorMessages>;
}

/**
 * Validates withdraw limits for crypto and fiat withdrawals.
 *
 * @param {Props} props - The parameters for validating withdraw limits.
 * @returns {{ error: string | undefined }} - An object containing an error message if validation fails.
 */
const validateWithdrawLimits = ({ txMin, txMax, freeBalance, minWithdrawFee, errorMessages }: Props) => {
  const hasLessBalanceThanFee = compareCurrencies(freeBalance, minWithdrawFee) === -1;
  const hasLessBalanceThanMinimum = compareCurrencies(txMin.toString(), freeBalance) === 1;
  const hasLessTxLimitThanMinimum = compareCurrencies(txMin.toString(), txMax.toString()) === 1;

  let error: string | undefined;

  if (hasLessBalanceThanFee) {
    error = errorMessages?.hasLessBalanceThanFee;
  } else if (hasLessBalanceThanMinimum) {
    error = errorMessages?.hasLessBalanceThanMinimum;
  } else if (hasLessTxLimitThanMinimum) {
    error = errorMessages?.hasLessTxLimitThanMinimum;
  }

  return { error };
};

export default validateWithdrawLimits;

import React from 'react';

import { Block, LayoutV2, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

const { Hero } = LayoutV2;

const Header = () => {
  const { t } = useTranslation();

  return (
    <>
      <Hero>
        <Block row justify="between" items="center">
          <Text heading size="xl" color="white" weight="bold" my="md">
            {t('rewards.tracking-header')}
          </Text>
        </Block>
      </Hero>
    </>
  );
};

export default Header;

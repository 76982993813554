import React from 'react';

import { Text } from '@bilira-org/design';
import { CryptoWithdrawalFeesType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import FormattedNumber from '@/components/common/FormattedNumber';

import { ZERO_FEE_SYMBOLS } from '../constants';

type Props = { value?: string; symbol: string; network?: string; fees?: CryptoWithdrawalFeesType[]; loading?: boolean };

const Fee = ({ symbol, network, fees, loading }: Props) => {
  const { t } = useTranslation();

  const value = ZERO_FEE_SYMBOLS.includes(symbol)
    ? '0'
    : fees?.find((fee) => fee.asset === symbol && fee.network === network)?.withdrawal_fee;

  const getPlaceholderText = (value?: string) => {
    if (loading) {
      return undefined;
    }
    if (!value) {
      return `- ${symbol}`;
    }
    if (value === '0') {
      return t('common.free');
    }

    return undefined;
  };

  const placeholderText = getPlaceholderText(value);

  if (placeholderText) {
    return (
      <Text size="sm" weight="regular">
        {placeholderText}
      </Text>
    );
  }

  return <FormattedNumber loading={loading} value={value?.toString()} size="sm" weight="regular" suffix={symbol} />;
};

export default Fee;

import React, { memo, useMemo } from 'react';

import { Menu, MenuItemProps, Text, useWindowSize, withIntrinsicHidden } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { useSessionStore } from '@/store';
import useLocaleRoute from '@Libs/hooks/useLocaleRoute';

const HeaderNavBar = ({ vertical = false, onClick }: { vertical?: boolean; onClick?: (value: any) => void }) => {
  const { t } = useTranslation();
  const isLoggedIn = useSessionStore((state) => state.isLoggedIn);
  const breakPoint = useWindowSize({ enable: true });

  const { getRoute } = useLocaleRoute();

  const data = useMemo<MenuItemProps[]>(
    () => [
      { href: '/', text: t('navigation.overview') },
      { href: getRoute('Market'), text: t('navigation.market') },
      {
        text: t('navigation.buy-sell'),
        href: getRoute('Swap'),
        display: isLoggedIn && breakPoint !== 'xl',
      },
      {
        text: t('navigation.deposit-withdraw'),
        display: isLoggedIn,
        menu: [
          {
            icon: 'o:building-library-inside',
            title: t('navigation.fiat.deposit'),
            description: t('navigation.fiat.deposit-desc'),
            href: '/fiat',
          },
          {
            icon: 'o:building-library-outside',
            title: t('navigation.fiat.withdraw'),
            description: t('navigation.fiat.withdraw-desc'),
            href: '/fiat/withdraw',
          },
          {
            icon: 'o:token-arrow-up',
            title: t('navigation.crypto.deposit'),
            description: t('navigation.crypto.deposit-desc'),
            href: '/crypto',
          },
          {
            icon: 'o:token-arrow-down',
            title: t('navigation.crypto.withdraw'),
            description: t('navigation.crypto.withdraw-desc'),
            href: '/crypto/withdraw',
          },
        ],
      },
      {
        text: (
          <Text as="span" color="gradient-1" weight="medium" size="sm">
            {t('navigation.reward-center')}
          </Text>
        ),
        href: '/rewards',
      },
      {
        text: t('navigation.otc-portal'),
        href: '/otc',
        display: breakPoint !== 'xl',
      },
      {
        text: t('navigation.more'),
        menu: [
          {
            icon: 'o:user-plus',
            title: t('navigation.referral'),
            href: '/referral',
          },
          {
            icon: 'o:device-phone-mobile',
            title: t('navigation.download-app'),
            href: t('urls.download'),
          },
          {
            title: t('navigation.help.help-center'),
            href: t('urls.support-center'),
            display: breakPoint !== 'xl',
          },
          {
            title: t('navigation.help.whats-new'),
            href: '#new',
            display: breakPoint !== 'xl',
          },
          {
            title: t('commission.transaction-fees'),
            href: getRoute('CommissionFee'),
            display: breakPoint !== 'xl',
          },
          {
            title: t('navigation.help.ecosystem'),
            href: t('urls.ecosystem'),
            display: breakPoint !== 'xl',
          },
        ],
      },
      {
        text: t('navigation.profile.account'),
        href: '/account',
        display: isLoggedIn && breakPoint !== 'xl',
      },
    ],
    [t, isLoggedIn, breakPoint, getRoute],
  );

  return (
    <>
      <Menu onClick={onClick} vertical={vertical} data={data} />
    </>
  );
};
export default memo(withIntrinsicHidden(HeaderNavBar));

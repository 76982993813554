import { HttpClient, HttpClientType, HttpErrorType } from '../../client';
import {
  BankAccountPatchType,
  OTCTransactionPatchType,
  OTCTransactionsParamsType,
  OTCTransactionsType,
  OTCType,
  ResultType,
  TransactionsRecordType,
} from '../../model';
import {
  PostSwapQuoteType,
  SavedCommissionsType,
  SwapBodyType,
  SwapCommissionTiersItemType,
  SwapCommissionTierType,
  SwapConfirmBodyType,
  SwapPairType,
  SwapResponseType,
} from '../../model/SwapTypes';
import { SwapTransactionsParamsType, SwapTransactionsType } from '../../model/Transaction';
import { ISwapApi } from '../api/interface';

class SwapApi extends HttpClient implements ISwapApi {
  public constructor(conf: HttpClientType) {
    super(conf);
  }

  public postSwapQuoteRequest = (body: SwapBodyType, controller: AbortController) => {
    return this.instance.post<HttpErrorType, PostSwapQuoteType, SwapBodyType>(`/swap/quote`, body, {
      signal: controller.signal,
    });
  };
  public getSwapPairs = () => {
    return this.instance.get<HttpErrorType, SwapPairType[]>(`/swap/pairs`);
  };
  public patchSwapQuoteConfirm = ({ id, ...rest }: SwapConfirmBodyType) => {
    return this.instance.patch<HttpErrorType, SwapResponseType, Omit<SwapConfirmBodyType, 'id'>>(
      `/swap/quote/${id}/confirm`,
      rest,
    );
  };

  public getTransaction = (id: string, account: string) => {
    return this.instance.get<HttpErrorType, TransactionsRecordType>(`/swap/transactions/${id}?account=${account}`);
  };
  public getOtcTransactions = (params: Partial<OTCTransactionsParamsType>) => {
    return this.instance.get<HttpErrorType, OTCTransactionsType>(`/swap/otc-transactions`, {
      params,
    });
  };
  public getOtcTransactionDetail = (id: string) => {
    return this.instance.get<HttpErrorType, OTCType>(`/swap/otc-transactions/${id}`);
  };

  public patchOtcTransaction = ({ id, body }: { id: string; body: Partial<OTCTransactionPatchType> }) => {
    return this.instance.patch<OTCTransactionPatchType, ResultType>(`/swap/otc-transactions/${id}`, body);
  };

  public getSavedCommissions = () => {
    return this.instance.get<HttpErrorType, SavedCommissionsType>('/swap/saved-commissions');
  };

  public getSwapCommissionTier = () => {
    return this.instance.get<HttpErrorType, SwapCommissionTierType>('/swap/commission-tier');
  };

  public getSwapCommissionTiers = () => {
    return this.instance.get<HttpErrorType, SwapCommissionTiersItemType[]>('/swap/commission-tiers');
  };

  public getTransactions = (params: SwapTransactionsParamsType) => {
    return this.instance.get<HttpErrorType, SwapTransactionsType>(`/swap/transactions/`, {
      params,
    });
  };
}

export default SwapApi;

import React, { memo } from 'react';

import { Button, Icon, withHidden } from '@bilira-org/design';

import { useGeneralStore } from '@/store/generalStore';

/**
 * Button for opening side drawer for authenticated users
 */
const PrivateMobilMenu = () => {
  const { privateDrawerOpen, setPrivateDrawerOpen } = useGeneralStore();
  return (
    <>
      <Button
        onClick={() => setPrivateDrawerOpen(!privateDrawerOpen)}
        startIcon={<Icon color="secondary-500" type="o:bars-3" />}
        variant="pill-neutral"
        size="md"
      />
    </>
  );
};

export default memo(withHidden(PrivateMobilMenu));

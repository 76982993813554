import React from 'react';

import { IconCustom as IconCustomDesign, IconCustomProps, useDarkMode } from '@bilira-org/design';

/**
 * Custom icon component that renders icons based on type and theme
 *
 * @example
 * <IconCustom type="empty-folder" size="sm" />
 */
export function IconCustom({ type, ...rest }: IconCustomProps) {
  const [isDark] = useDarkMode();

  return <IconCustomDesign theme={isDark ? 'dark' : 'light'} type={type} {...rest} />;
}

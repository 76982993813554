import { ReferralCodeType } from '@bilira-org/react-utils';

/**
 * Calculate cashback percentage based on default and actual commission.
 * @param {number} defaultCommission The default commission.
 * @param {number} commission The actual commission.
 * @returns {number} The calculated cashback percentage.
 * @example
 * calculateCashbackPercentage(0.2, 0.1); // 10
 */
export function calculateCashbackPercentage(defaultCommission: number, commission: number) {
  return calculatePercentage(defaultCommission - commission);
}

/**
 * Calculate percentage value.
 * @param {number} value The value to calculate percentage.
 * @returns {number} The calculated percentage.
 * @example
 * calculatePercentage(0.25); // 25
 */
export function calculatePercentage(value: number) {
  return parseFloat((value * 100).toFixed(2));
}

/**
 * Pins the default referral code to the beginning of an array of referral codes.
 * @param {ReferralCodeType[]} [referralCodes] Array of referral codes.
 * @returns {ReferralCodeType[]} Sorted array of referral codes with default code first.
 * @example
 * const referralCodes = [
 *   { ...,code: 'REF1', default: false },
 *   { ...,code: 'REF2', default: true },
 *   { ...,code: 'REF3', default: false }
 * ];
 * pinDefaultReferralCode(referralCodes);
 * // [{ code: 'REF2', default: true }, { code: 'REF1', default: false }, { code: 'REF3', default: false }]
 */
export function pinDefaultReferralCode(referralCodes?: ReferralCodeType[]) {
  if (!referralCodes) {
    return undefined;
  }

  let defaultItem: ReferralCodeType | null = null;

  const filteredReferralCodes = referralCodes.filter((item) => {
    if (item.default) {
      defaultItem = item;
      return false;
    }
    return true;
  });

  if (!defaultItem) {
    return referralCodes;
  }
  return [defaultItem, ...filteredReferralCodes];
}

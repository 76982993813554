import { badgeNotify } from '@bilira-org/design';
import {
  OTCStatusType,
  TransactionStatusType,
  UserQueryKey,
  WSLanguageType,
  WSNotificationType,
} from '@bilira-org/react-utils';
import { getI18n } from 'react-i18next';

import router from '@/app/createRouter';
import { queryCache } from '@Libs/clientInstances/queryClient';
import getI18nLanguage from '@Libs/helpers/getI18nLanguage';
import { getOTCStatusConfig, getStateConfig, IconMapType } from '@Libs/helpers/getStateConfig';
import { useGeneralStore } from '@Store/generalStore';
import { useSessionStore } from '@Store/sessionStore';

export const handleNotification = (
  notification: WSNotificationType | string,
  status: TransactionStatusType,
  type: IconMapType,
) => {
  const { text, bg, icon } = getStateConfig(status);

  badgeNotify({
    message:
      typeof notification === 'string' ? notification : notification.title?.[getI18nLanguage() as WSLanguageType],
    iconProps: {
      type: icon(type),
      overlayColor: bg,
      color: text,
    },
    closeText: getI18n().t('common.close'),
  });

  useSessionStore.getState().setIsNotificationViewed(false);
  queryCache.resetQueries([UserQueryKey.NOTIFICATIONS]);
};
export const handleOTCNotification = (notification: WSNotificationType, status: OTCStatusType) => {
  const { text, bg, icon } = getOTCStatusConfig(status);

  const setNotificationModal = useGeneralStore.getState().setNotificationModal;

  const onClose = () => {
    if (status === 'completed') {
      setNotificationModal({
        open: true,
        title: getI18n().t('transactions.transaction-details'),
        hideButton: true,
        notification: {
          type: 'otc:swap',
          id: 'transaction',
          read: false,
          data: { transactionId: notification.transactionId, content: 'transaction', description: '', title: '' },
        },
      });
      return;
    }

    router.navigate('/otc');
    // customHistory.push('/otc');
  };

  badgeNotify({
    message: notification.title?.[getI18nLanguage() as WSLanguageType],
    iconProps: {
      type: icon('otc:swap'),
      overlayColor: bg,
      color: text,
    },
    closeText: getI18n().t('otc.detail'),
    onClose: onClose,
  });

  useSessionStore.getState().setIsNotificationViewed(false);
  queryCache.resetQueries([UserQueryKey.NOTIFICATIONS]);
};

import React, { memo, useState } from 'react';

import { Block, Button, Modal, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { CreateReferral } from './components';

interface Props {
  refetch: () => void;
  remainingCodeCount: number;
}

function CreateReferralButton({ refetch, remainingCodeCount }: Props) {
  const { t } = useTranslation();
  const [modalActive, setModalActive] = useState(false);

  return (
    <>
      <Button
        flexType={{ xs: '1', sm: '1', md: 'none' }}
        size="md"
        variant="filled"
        onClick={() => setModalActive(true)}
      >
        {t('referral.create-referral-code')}
      </Button>

      <Modal
        title={
          <Block row justify="between" width="size-full" items="center">
            <Modal.Title>{t('referral.creating-referral-code')}</Modal.Title>
            <Block row mx="lg">
              <Text size="sm" color="neutral-700">
                {t('referral.remaining')}:&nbsp;
              </Text>
              <Text size="sm" color="neutral-900">
                {remainingCodeCount}
              </Text>
            </Block>
          </Block>
        }
        open={modalActive}
        onClose={() => setModalActive(false)}
      >
        <CreateReferral
          onSubmit={() => {
            setModalActive(false);
            refetch();
          }}
          onClose={() => setModalActive(false)}
        />
      </Modal>
    </>
  );
}

export default memo(CreateReferralButton);

/**
 * Navigates to the specified URL, optionally opening it in a new browser window or tab.
 *
 * @param {string | undefined} url - The URL to navigate to.
 * @param {string | undefined} target - Optional target attribute for opening the URL in a new window or tab.
 * @returns {() => void} - A function that performs the navigation when called.
 *
 * @example
 * navigate('https://github.com/') // returns a function that navigates in current window
 * navigate('https://github.com/', '_blank) // returns a function that navigates in new tab
 */
export const navigate = (url?: string, target?: string) => () => {
  if (url) {
    if (target) {
      window.open(url, target);
    } else {
      window.location.href = url;
    }
  }
};

import React, { FC, useEffect, useRef } from 'react';

import { ForceDecimal, formatNumberByPrice, formatPrice, LanguageUniversalType } from '@bilira-org/react-utils';
import { unix } from 'dayjs';
import { createChart, IChartApi, ISeriesApi, SeriesMarker, Time, UTCTimestamp } from 'lightweight-charts';

import { lightweightChartConfiguration, subscribeCrosshairMoveEvent } from './lightweightChart/helpers';
import { useDarkMode } from '../../hooks';
import { BaseComponentType } from '../types';

export type ChartPriceDataType = { time: Time; value: number; change?: string };
export type TimeStamp = UTCTimestamp;
export type GraphConf = {
  athIndex: number;
  atlIndex: number;
};

export type ChartProps = BaseComponentType & {
  /** Data to be used in chart */
  data?: ChartPriceDataType[];
  /** Colors to be used in chart */
  colors?: {
    backgroundColor?: string;
    lineColor?: string;
    textColor?: string;
    areaTopColor?: string;
    areaBottomColor?: string;
    markerColor?: string;
  };
  /** Subscribe to crosshair move event */
  subscribeCrosshairMove?: (params?: ChartPriceDataType) => void;
  /** Locale of the chart */
  locale?: string;
  /** Timescale of the chart */
  scaleConfiguration?: (date: number, locale: string) => string;
  // graphConf: GraphConf;
};

/**
 * Chart component for data visualization using 'lightweight-charts' library
 */
export const LightweightChart: FC<ChartProps> = ({
  data,
  subscribeCrosshairMove,
  locale = 'tr',
  testId,
  scaleConfiguration,
  // graphConf,
}) => {
  const [isDark] = useDarkMode();

  const colors = {
    backgroundColor: 'transparent',
    lineColor: isDark ? '#287CFB' : '#0066FF',
    textColor: isDark ? '#8F96AC' : '#A4AEC0',
    borderColor: isDark ? '#2C2F36' : '#E7E9EE',
    areaTopColor: 'rgba(41,98,255,0.3)',
    areaBottomColor: 'rgba(41,98,255,0)',
    markerColor: isDark ? '#C3C5CB' : '#061024',
  } as const;

  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartSeriesRef = useRef<ISeriesApi<'Area'>>();
  const chartRef = useRef<IChartApi>();

  useEffect(() => {
    if (chartContainerRef.current) {
      const chart = createChart(chartContainerRef.current, lightweightChartConfiguration(colors));

      const newSeries = chart.addAreaSeries({
        lineColor: colors.lineColor,
        topColor: colors.areaTopColor,
        bottomColor: colors.areaBottomColor,
        lineWidth: 2,
        crosshairMarkerRadius: 6,
        lineType: 0,
        lastPriceAnimation: 2,
        priceFormat: {
          type: 'custom',
          formatter: (price: number) => {
            return formatNumberByPrice({
              type: 'price',
              price: price,
              value: price,
              locale: locale as LanguageUniversalType,
            }).value;
          },
        },
      });

      if (subscribeCrosshairMove) {
        chart.subscribeCrosshairMove((param) => subscribeCrosshairMoveEvent(param, newSeries, subscribeCrosshairMove));
      }

      chartSeriesRef.current = newSeries;
      chartRef.current = chart;

      return () => chart.remove();
    }
  }, []);

  useEffect(() => {
    if (!data?.length || !chartSeriesRef.current || !chartRef.current) {
      return;
    }

    chartRef.current.applyOptions({
      localization: {
        timeFormatter: (date: any) => {
          return unix(date).locale(locale).format("DD MMM 'YY HH:mm");
        },
      },
      timeScale: {
        tickMarkFormatter: (date: number) => {
          return scaleConfiguration?.(date, locale);
        },
      },
    });

    // const { markers } = getUpdatedConf({ colors, data, graphConf: graphConf, locale });
    // chartSeriesRef.current.setMarkers(markers);

    chartRef.current.timeScale().fitContent();

    chartSeriesRef.current.setData(data);
  }, [JSON.stringify(data), locale]);

  return <div className="relative w-full h-60" ref={chartContainerRef} data-testid={testId} />;
};

// const getAllTimeValues = (data: ChartPriceDataType[], graphConf: Partial<GraphConf>) => {
//   const athIndex = data.findIndex((d) => d.value >= data[graphConf.athIndex].value);
//   const atlIndex = data.findIndex((d) => d.value <= data[graphConf.atlIndex].value);
//
//   return { athIndex, atlIndex };
// };

/**
 * Updates the configuration object for a chart with the given properties.
 *
 * Gets ATH, ATL point markers for lightweight chart.
 *
 * @param {Partial<ChartProps>} - The partial chart properties object.
 * @returns {Object} - The updated configuration object containing markers.
 */
// const getUpdatedConf = ({ colors, data, graphConf, locale }: Partial<ChartProps>) => {
//   const atv = getAllTimeValues(data, graphConf);
//
//   if (atv.atlIndex === atv.athIndex) {
//     return { markers: [] };
//   }
//
//   const atObj = {
//     ath: {
//       time: data[atv.athIndex].time,
//       price: formatPrice({ value: data[atv.athIndex].value.toString(), locale: locale }).value,
//     },
//     atl: {
//       time: data[atv.atlIndex].time,
//       price: formatPrice({ value: data[atv.atlIndex].value.toString(), locale: locale }).value,
//     },
//   };
//
//   const markers: SeriesMarker<Time>[] = [
//     {
//       time: atObj.ath.time,
//       position: 'aboveBar',
//       color: colors.markerColor,
//       shape: 'circle',
//       size: 0,
//       text: atObj.ath.price,
//     },
//     {
//       time: atObj.atl.time,
//       position: 'belowBar',
//       color: colors.markerColor,
//       shape: 'circle',
//       size: 0,
//       text: atObj.atl.price,
//     },
//   ];
//
//   if (unix(atObj.ath.time as number).isAfter(unix(atObj.atl.time as number))) {
//     markers.push(markers[0]);
//     markers.shift();
//   }
//
//   return {
//     markers,
//   };
// };

LightweightChart.displayName = 'LightweightChart';

export default LightweightChart;

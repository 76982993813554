import React from 'react';

import { clsx } from 'clsx';

import { StepItemProps } from './StepProgress';
import { BorderStyleType, FlexItemsType } from '../types';

type Props = {
  /** An array of StepItemProps used to define the structure of the skeleton. */
  skeleton: StepItemProps[];
  /** Additional class name for styling */
  stepperClass?: string;
  /** Border style for the steps */
  borderStyle?: BorderStyleType;
  /** Alignment of the items */
  alignItems?: FlexItemsType;
};

/**
 * Component that renders a skeleton structure for the `StepProgress` component.
 * It is used to provide a visual representation of the component during loading.
 *
 * @example
 * // Example usage of StepProgressSkeleton
 * <StepProgressSkeleton skeleton={skeletonArray} stepperClass="custom-class" />
 */
const StepProgressSkeleton = ({ skeleton, stepperClass, borderStyle, alignItems }: Props) => {
  return (
    <>
      {(skeleton as StepItemProps[]).map((step, index) => {
        const stepItem = (
          <li
            key={step.key ? `step-progress-${step.key}` : `step-progress-${index}`}
            className={clsx('stepper-step', stepperClass, {
              [`step-border-${borderStyle}`]: borderStyle,
              'step-active': step.active,
              'step-completed': step.complete,
            })}
          >
            <div className={clsx('stepper-head')}>
              <div
                className={clsx('stepper-head-icon', {
                  'stepper-head-icon-has-extra': !!step.iconExtra,
                })}
              >
                {step.icon ?? index + 1}
                {step.iconExtra && (
                  <div
                    className={clsx('stepper-head-icon-extra', {
                      'tw-bg-theme-wn': !step.iconExtra.background,
                      [`tw-bg-${step.iconExtra.background}`]: step.iconExtra.background,
                    })}
                  >
                    {step.iconExtra.icon}
                  </div>
                )}
              </div>
              {step.title && (
                <div
                  className={clsx('stepper-head-text', {
                    [`tw-items-${alignItems}`]: alignItems,
                  })}
                >
                  <div className="stepper-head-inner">{step.title}</div>
                </div>
              )}
            </div>
          </li>
        );

        return step.hidden ? null : stepItem;
      })}
    </>
  );
};

export default StepProgressSkeleton;

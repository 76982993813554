import { Overflow, Panel } from '@bilira-org/design';
import { CoinSymbolType } from '@bilira-org/react-utils';
import { useSearchParams } from 'react-router-dom';

import { useMarketTableControls } from '@/modules/market/market/table/components/hooks/useMarketTableControls';
import FilterTags from '@Modules/market/market/table/components/FilterTags';
import MarketTable from '@Modules/market/market/table/components/MarketTableContent';

const MarketTableWidget = () => {
  const [searchParams] = useSearchParams();
  const excludedSymbols = searchParams.get('exclude')?.split(',');

  const { dataTag, setDataTag, sorting, setSorting } = useMarketTableControls();

  return (
    <>
      <Panel border="neutral-400" size="md">
        <FilterTags dataTag={dataTag} setDataTag={setDataTag} />
        <Overflow overflow="y-auto">
          <div style={{ height: 500 }}>
            <MarketTable
              dataTag={dataTag}
              quote="TRYB"
              exclude={(excludedSymbols || []) as CoinSymbolType[]}
              sorting={sorting}
              onSortingChange={setSorting}
            />
          </div>
        </Overflow>
      </Panel>
    </>
  );
};

export default MarketTableWidget;

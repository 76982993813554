import React, { memo } from 'react';

import { Block } from '@bilira-org/design';

import TradeActions from '@Components/trade/buySell/offer/BuySellActions';
import BuySellTimeoutAlert from '@Components/trade/buySell/offer/BuySellTimeoutAlert';
import OfferPreviewItem from '@Components/trade/buySell/offer/OfferPreviewItem';

type SwapPreviewProps = {
  refetchCallback: () => void;
};

/**
 * Component to render a preview of the swap details.
 */
function OfferPreview({ refetchCallback }: SwapPreviewProps) {
  return (
    <Block height="size-full" justify="between">
      <OfferPreviewItem />
      <Block justify="start" gap="md">
        <BuySellTimeoutAlert />
        <TradeActions refetchCallback={refetchCallback} />
      </Block>
    </Block>
  );
}

export default memo(OfferPreview);

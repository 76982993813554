import React from 'react';

import { useTranslation } from 'react-i18next';

import TransactionPanelCard from '@Components/transaction/TransactionPanelCard';
import { withPrivateRoute } from '@Libs/hocs';

const WaitingTransactions = () => {
  const { t } = useTranslation();

  return (
    <TransactionPanelCard
      title={t('overview.waiting-transactions')}
      filterStatus="processing"
      hideIfDataEmpty
      testId="waiting-transactions-panel"
    />
  );
};

export default withPrivateRoute(WaitingTransactions);

import React, { ReactElement, ReactNode, useRef, useState } from 'react';

import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useTransitionStyles,
} from '@floating-ui/react';
import { clsx } from 'clsx';

import {
  BackgroundColorType,
  BaseComponentType,
  BorderColorType,
  BorderStyleType,
  MarginSizes,
  SizeType,
} from '../types';

export type TooltipProps = BaseComponentType & {
  /** Content to be displayed in the tooltip. */
  message: ReactNode;
  /** Background color of the tooltip. */
  color?: BackgroundColorType;
  /** Placement of the tooltip relative to the trigger element */
  placement?: Placement;
  /** The trigger element that will show the tooltip on interaction */
  children?: ReactElement;
  /** Border style of the tooltip */
  border?: BorderStyleType;
  /** Border color of the tooltip */
  borderColor?: BorderColorType;
  /** Width of the tooltip */
  width?: SizeType;
  /**Trigger popup opening on click*/
  enableClick?: boolean;
  /**Trigger popup opening on hover*/
  enableHover?: boolean;
  /** Position */
  position?: 'left' | 'right';
};

/**
 * Tooltip component that displays additional information when triggered by user interaction.
 *
 *  * @example
 * <Tooltip message="This is a tooltip" color="primary-500" placement="bottom">
 *   <button>Hover me</button>
 * </Tooltip>
 */
const Tooltip = ({
  message,
  children,
  color = 'secondary-500',
  placement = 'top',
  width = 'size-max',
  enableClick,
  enableHover = true,
  border,
  testId,
  position,
}: TooltipProps): JSX.Element => {
  const classes = clsx('tooltip-trigger', {
    [`tooltip-${color}`]: color,
    [`tooltip-${border}`]: border,
  });

  const arrowRef = useRef<SVGSVGElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const {
    x,
    y,
    strategy,
    refs,
    context,
    placement: placementAware,
  } = useFloating({
    placement: placement,
    middleware: [
      offset(5),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const { isMounted, styles } = useTransitionStyles(context, {
    duration: {
      open: 200,
      close: 200,
    },
  });

  const hover = useHover(context, {
    enabled: enableHover,
  });

  const click = useClick(context, {
    enabled: enableClick,
  });

  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, click, dismiss]);

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        aria-describedby="tooltip"
        className={clsx('tooltip-trigger', { [`tooltip-${position}`]: position })}
        data-testid={`${testId ?? 'tooltip'}-trigger`}
      >
        {children}
      </div>
      {isMounted && (
        <div
          className={clsx('tooltip', { [`tw-w-${width}`]: width }, classes)}
          ref={refs.setFloating}
          data-placement={placementAware}
          style={{
            position: strategy,
            top: y ?? -10,
            left: x ?? -10,
            ...styles,
          }}
          role="tooltip"
          {...getFloatingProps()}
          data-testid={testId ?? 'tooltip'}
          onMouseEnter={() => setIsOpen(true)} // Keep tooltip open on mouse enter
          onMouseLeave={() => setIsOpen(false)} // Close tooltip on mouse leave
        >
          {message}
          <FloatingArrow ref={arrowRef} context={context} />
        </div>
      )}
    </>
  );
};

Tooltip.displayName = 'Tooltip';

export default Tooltip;

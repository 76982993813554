import { memo } from 'react';

import { OHLCSTimeScale } from '@bilira-org/react-utils';

import useSubscriptionHandler, { SubscriptionParamsType } from '@Libs/hooks/useSubscriptionHandler';

type Props = {
  channel: string;
  baseQuoteList?: string[];
  ohlcPeriod?: OHLCSTimeScale;
  /**Subscriptions types of {{EventType}} can be separated by comma
   * @example
   * events: 'last_price,symbol_statistics'
   * */
  events?: string;
};
const GeneralPriceSubscription = ({ channel, baseQuoteList, ohlcPeriod = '1h', events = 'last_price' }: Props) => {
  const params: SubscriptionParamsType[] = events.split(',').map((item) => ({ type: item as EventType }));

  if (ohlcPeriod) {
    params.push({ type: 'ohlc_close', params: { ohlc_period: ohlcPeriod } });
  }

  // Subscribe here.
  useSubscriptionHandler(channel, params, baseQuoteList);
  return null;
};

export default memo(GeneralPriceSubscription);

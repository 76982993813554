import React, { memo, useMemo, useState } from 'react';

import { Block, Flex, Icon, List, Loop, Panel, Separator, Skeleton, Span } from '@bilira-org/design';
import { addOtherBanksEntry } from '@bilira-org/react-utils';
import { SystemBankAccountModel } from '@bilira-org/react-utils/src/model/SystemBankAccount';
import { useTranslation } from 'react-i18next';

import { BankIcon } from '@Components/icon/BankIcon';
import FiatQuery from '@Libs/clientInstances/fiatQuery';

interface IBanks {
  onFinish: (data: SystemBankAccountModel | null) => void;
}

const Banks = ({ onFinish }: IBanks) => {
  const { t } = useTranslation();
  const { data, isLoading } = FiatQuery.useGetAccounts();
  const [activeBank, setBank] = useState<SystemBankAccountModel | null>(null);

  const banks = useMemo(() => addOtherBanksEntry(data, true), [data]);

  const bankData = useMemo(() => {
    if (!banks) return [];

    return !activeBank ? banks.filter((d) => d.active) : banks.filter((d) => d.id === activeBank?.id);
  }, [activeBank, banks]);

  const callback = (item: SystemBankAccountModel | null) => {
    setBank(item);
    onFinish(item);
  };

  if (isLoading) {
    return <BanksSkeleton />;
  }

  return (
    <List
      testId="banks-list"
      bordered
      space="lg"
      dataSource={bankData}
      renderItem={(item) => (
        <List.Item
          size="md"
          key={item.id}
          hoverBgColor="theme-n200d800"
          {...(item.id === activeBank?.id
            ? {}
            : {
                onClick: () => callback(item),
              })}
          extra={
            <Block row gap="md" items="center">
              <Span color="neutral-600" weight="regular" size="xs">
                {t('common.wire-transfer-eft')}
              </Span>
              {item.id === activeBank?.id ? (
                <>
                  <Separator height="size-6" />
                  <Span color="primary-500" weight="regular" size="sm" onClick={() => callback(null)}>
                    {t('common.change')}
                  </Span>
                </>
              ) : (
                <Icon size="lg" type="s:chevron-right" color="neutral-600" onClick={() => callback(item)} />
              )}
            </Block>
          }
        >
          <Block gap="lg">
            <BankIcon name={item.bank_code} />
          </Block>
        </List.Item>
      )}
    />
  );
};

export default memo(Banks);

const BanksSkeleton = () => {
  return (
    <Block gap="xl">
      <Loop data={3}>
        <Panel px="md" py="lg" border="neutral-400">
          <Flex direction="row" gap="lg" justify="between" items="center">
            <Flex.Item flex direction="row" gap="md" items="center">
              <Skeleton rounded="full" width="size-6" height="size-6" />

              <Skeleton width="size-20" height="size-3" />
            </Flex.Item>

            <Skeleton width="size-9" height="size-3" />
          </Flex>
        </Panel>
      </Loop>
    </Block>
  );
};

import React from 'react';

import { Layout, Link, useSafeLocalStorage } from '@bilira-org/design';
import { isEmpty } from '@bilira-org/react-utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import CommonQuery from '@Libs/clientInstances/commonQuery';
import { withPrivateRoute } from '@Libs/hocs';

const { Announcement } = Layout;

/**
 * Component for displaying announcements
 */
const AnnouncementBar = () => {
  const { i18n } = useTranslation();
  const [prevAnnouncement, setAnnouncement] = useSafeLocalStorage<{ id: string } | null>('announcement-bar', null);
  const { data, isPending, isError } = CommonQuery.useGetAnnouncement(i18n.language);

  if (isPending || isError) {
    return null;
  }

  const onClose = () => {
    if (data) {
      setAnnouncement({ id: data.id });
    }
  };

  const seen = isEmpty(data) || (data && prevAnnouncement?.id === data.id) || dayjs().isAfter(data.expires_at);

  if (seen) {
    return null;
  }

  return (
    <Announcement onClose={onClose} closable={!data.expires_at} testId="announcement-bar">
      {data && (
        <Link anchor href={data.link} target="_blank" px="sm">
          <span dangerouslySetInnerHTML={{ __html: data.content }} />
        </Link>
      )}
    </Announcement>
  );
};

export default withPrivateRoute(AnnouncementBar);

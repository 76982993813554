import { useEffect, useState } from 'react';

import { CryptoAssetsType } from '@bilira-org/react-utils';
import { useSearchParams } from 'react-router-dom';

import CryptoWalletApi from '@Libs/clientInstances/cryptoQuery';

/**
 * Hook for fetching asset data and selecting the asset from the search param
 * @returns {{
 *   asset: CryptoAssetsType | undefined;
 * }} - An object containing the selected cryptocurrency asset or undefined if requested asset is not found.
 */
const useSelectAssetBySearchParam = () => {
  const { data } = CryptoWalletApi.useGetAssets();

  const [asset, setAsset] = useState<CryptoAssetsType>();
  const [searchParams] = useSearchParams();

  const searchParam = searchParams.get('asset');

  useEffect(() => {
    const assetResult = data?.find((d) => d.symbol === searchParam);

    assetResult && setAsset(assetResult);
  }, [data, searchParam]);

  return { asset };
};

export default useSelectAssetBySearchParam;

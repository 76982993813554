import React from 'react';

import { withPublicRoute } from '@/libs/hocs';
import DefaultContent from '@Modules/otc/components/DefaultContent';

const PublicOtcContent = () => {
  return (
    <>
      <DefaultContent />
    </>
  );
};
export default withPublicRoute(PublicOtcContent);

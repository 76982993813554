import { useQuery } from '@tanstack/react-query';

import { HttpClientType, HttpErrorType } from '../../client';
import { QueryOptions, RemoteConfigParams, RemoteConfigRecord } from '../../model';
import { ConfigApi, IConfigApi } from '../api';
import { ConfigQueryKey } from '../constants';

class ConfigQuery {
  _api: IConfigApi;

  public constructor(params: HttpClientType) {
    this._api = new ConfigApi(params);
  }

  public useGetRemoteConfig = ({ user }: RemoteConfigParams, options?: QueryOptions) =>
    useQuery<RemoteConfigRecord[], HttpErrorType>({
      queryKey: [ConfigQueryKey.REMOTE_CONFIG, user],
      queryFn: this._api.getRemoteConfig,
      ...options,
    });
}

export default ConfigQuery;

import { useMemo } from 'react';

import {
  Block,
  ColumnDef,
  DataTable,
  Display,
  IconCustom,
  Skeleton,
  TableBodyStyle,
  TableHeaderStyle,
  Text,
} from '@bilira-org/design';
import { getCommissionTierBoundaryText, SwapCommissionTiersItemType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import FormattedPercentage from '@/components/common/FormattedPercentage';
import { useSessionStore } from '@/store';
import SwapQuery from '@Libs/clientInstances/swapQuery';

const headerStyle: TableHeaderStyle = { showBackground: true, px: '2xl', py: 'sm' };
const bodyStyle: TableBodyStyle = { px: '2xl', py: 'lg' };
const responsiveBodyStyle: TableBodyStyle = { px: '2xl', py: 'lg' };

const BuySell = () => {
  const { t, i18n } = useTranslation();
  const isLoggedIn = useSessionStore((state) => state.isLoggedIn);

  const { data, isPending } = SwapQuery.useGetSwapCommissionTiers();
  const { data: commissionTier } = SwapQuery.useGetSwapCommissionTier({ enabled: isLoggedIn });

  const columns = useMemo<ColumnDef<SwapCommissionTiersItemType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('commission.level'),
        cell: (cell) => {
          return (
            <Block row items="center" gap="xs">
              <Text>{t(`commission.levels.${cell.row.original.name?.toLowerCase()}`)}</Text>
              <Display show={commissionTier?.name === cell.getValue()}>
                <IconCustom type="badge" width="size-6" />
              </Display>
            </Block>
          );
        },
        skeleton: <Skeleton height="size-6" width="size-14" />,
      },
      {
        accessorKey: 'upperBound',
        header: t('commission.30-day-buy-sell-volume'),
        cell: (cell) => {
          return <Text>{getCommissionTierBoundaryText(cell.row.original, i18n.language)}</Text>;
        },
        skeleton: <Skeleton height="size-6" width="size-24" />,
      },
      {
        accessorKey: 'rate',
        header: t('commission.commission-rate'),
        style() {
          return {
            width: 'size-44',
          };
        },
        cell: (cell) => {
          return (
            <Text>
              <FormattedPercentage value={cell.row.original.rate * 100} />
            </Text>
          );
        },
        skeleton: <Skeleton height="size-6" width="size-10" />,
      },
    ],
    [commissionTier?.name, i18n.language, t],
  );

  return (
    <>
      {/* 
        Adding a non-hidden SVG resolves
        the issue of clashing SVG IDs causing display problems: 
        When the first SVG is hidden, other instances referencing the same SVG definitions are not displayed. 
      */}
      <IconCustom type="badge" width="size-0" />

      <DataTable.Responsive
        headerStyle={headerStyle}
        bodyStyle={bodyStyle}
        responsiveBodyStyle={responsiveBodyStyle}
        emptyMessage={t('otc.table.empty-info')}
        divider
        responsiveCellLayout="col"
        data={data}
        highlightVariant="bordered"
        highlightedRowKey={commissionTier?.name}
        loading={isPending}
        rowId="name"
        columns={columns}
      />
    </>
  );
};

export default BuySell;

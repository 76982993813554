import { useCallback, useMemo } from 'react';

import { AssetListParamType, AssetListType, CoinSymbolType, concatTicker } from '@bilira-org/react-utils';

import PriceQuery from '@Libs/clientInstances/priceQuery';
import useNavigateToMarket from '@Libs/hooks/useNavigateToMarket';

import { customTagsParamMapping } from './constants';

type Props = {
  dataTag?: string;
  exclude?: CoinSymbolType[];
  quote?: CoinSymbolType;
  sort?: {
    field?: AssetListParamType['sort'];
    direction?: AssetListParamType['sort_direction'];
  };
};
const useMarketAssetList = ({ dataTag, exclude, quote = 'TRYB', sort }: Props) => {
  const navigate = useNavigateToMarket();

  const customSortingParams = dataTag ? customTagsParamMapping[dataTag] : undefined;
  const sortingParams = sort?.direction ? { sort: sort?.field, sort_direction: sort?.direction } : customSortingParams;

  const { pages, fetchNextPage, isFetchingNextPage, isLoading } = PriceQuery.useGetAssetList({
    quote_asset: quote,
    tags: customSortingParams === undefined ? dataTag : undefined,
    ...sortingParams,
  });

  const callback = useCallback((item: AssetListType) => {
    navigate({ base: item.base_asset, quote: quote });
  }, []);

  const assetList = useMemo(() => {
    return pages.filter((item) => !exclude?.includes(item.base_asset));
  }, [exclude, pages]);

  const subscriptionList = useMemo(() => assetList.map((d) => concatTicker(d.base_asset, quote)), [assetList]);

  return {
    assetList: assetList,
    isPending: isLoading,
    callback,
    fetchNextPage,
    isFetchingNextPage,
    subscriptionList,
  };
};

export default useMarketAssetList;

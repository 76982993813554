import React from 'react';

import { LayoutV2, Panel } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import HeadTags from '@/components/common/HeadTags';
import MarketTableContent from '@Modules/market/market/table/components/MarketTableContent';
import RankingFilterTags from '@Modules/market/market/table/components/RankingFilterTags';

import { useRankingTableControls } from './hooks/useRankingTableControls';

const { Content, InnerContent } = LayoutV2;

const Index = () => {
  const { t } = useTranslation();

  const { dataTag, setDataTag, sorting, setSorting } = useRankingTableControls();
  return (
    <>
      <HeadTags title={t('navigation.market')} titleSuffix="subpage" />
      {/*<Header />*/}
      <Content>
        <InnerContent>
          <Panel border="neutral-400" size="2xl">
            <RankingFilterTags tag={dataTag} callback={setDataTag} />
            <MarketTableContent sorting={sorting} onSortingChange={setSorting} dataTag={dataTag} quote="TRYB" />
          </Panel>
        </InnerContent>
      </Content>
    </>
  );
};

export default Index;

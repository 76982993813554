import { TransactionTypes } from '@bilira-org/react-utils';

import { VariantType } from '../components/Filters';

const variantTypeMapping: Record<VariantType, TransactionTypes> = {
  'deposit-withdraw': 'fiat',
  swap: 'swap',
  other: 'promo',
};

export const getDefaultType = (variant?: string) => {
  return variantTypeMapping[variant as VariantType] || 'fiat';
};

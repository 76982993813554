import { AssetListParamType } from '@bilira-org/react-utils';

export const CUSTOM_ASSET_TAGS = {
  TOP_GAINERS: 'bl-top-gainers',
  TOP_LOSERS: 'bl-top-losers',
  TOP_VOLUME24H: 'bl-top-volume24h',
};

export const customTagsParamMapping: Record<string, Pick<AssetListParamType, 'sort_direction' | 'sort'>> = {
  [CUSTOM_ASSET_TAGS.TOP_GAINERS]: { sort_direction: 'desc', sort: 'Change_24_Hour' },
  [CUSTOM_ASSET_TAGS.TOP_LOSERS]: { sort_direction: 'asc', sort: 'Change_24_Hour' },
  [CUSTOM_ASSET_TAGS.TOP_VOLUME24H]: { sort_direction: 'desc', sort: 'Volume_24' },
};

import React from 'react';

import { Block, Button, Icon } from '@bilira-org/design';
import { BalanceType, BalanceWithQuoteType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type Props = {
  balance: BalanceType;
};
const MyAssetTableButtons = ({ balance }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const callback = (e: React.MouseEvent<HTMLButtonElement>, item: BalanceType, direction: 'withdraw' | 'deposit') => {
    // To stop event bubbling to onRowClick
    e.stopPropagation();
    navigate(`/crypto/${direction}?asset=${item.asset}`);
  };

  return (
    <Block row justify="end" gap="sm">
      <Button
        size="md"
        variant="outline"
        onClick={(e) => callback(e, balance, 'deposit')}
        startIcon={<Icon type="o:arrow-down-tray" />}
      >
        {t('common.deposit')}
      </Button>
      <Button
        size="md"
        variant="outline"
        onClick={(e) => callback(e, balance, 'withdraw')}
        startIcon={<Icon type="o:arrow-up-tray" />}
      >
        {t('common.withdraw')}
      </Button>{' '}
    </Block>
  );
};

export default MyAssetTableButtons;

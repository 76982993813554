import { useCallback, useMemo } from 'react';

import { create } from 'zustand';

type ModalKey = 'LegalDocumentsModal' | 'RewardsRegistrationModal';

interface GlobalModalQueueStoreState {
  modalQueue: { key: ModalKey; pending?: boolean }[];
  enqueue: (modal: ModalKey) => void;
  dequeue: (modal?: ModalKey) => void;
}

const useGlobalModalQueueStore = create<GlobalModalQueueStoreState>((set) => ({
  modalQueue: [
    { key: 'LegalDocumentsModal', pending: true },
    { key: 'RewardsRegistrationModal', pending: true },
  ],

  // Enqueue new modal to the queue
  enqueue: (modal: ModalKey) =>
    set((state) => {
      const existingModalIndex = state.modalQueue.findIndex((queuedModal) => queuedModal.key === modal);

      // If modal is already in the queue, update its pending status
      if (existingModalIndex !== -1) {
        const existingModal = state.modalQueue[existingModalIndex];
        if (existingModal.pending) {
          const updatedQueue = [...state.modalQueue];
          updatedQueue[existingModalIndex] = { ...existingModal, pending: false };
          return { modalQueue: updatedQueue };
        }
        return state; // No change if `pending` is already false
      }

      return { modalQueue: [...state.modalQueue, { key: modal }] };
    }),

  // Remove passed modal from the queue
  dequeue: (modal?: ModalKey) =>
    set((state) => {
      let newQueue = [...state.modalQueue];

      if (modal) {
        newQueue = newQueue.filter((queuedModal) => queuedModal.key !== modal);
      } else {
        newQueue = newQueue.slice(1);
      }

      return { modalQueue: newQueue };
    }),
}));

const useGlobalModalQueue = (modal: ModalKey) => {
  const { modalQueue, enqueue: enqueueStore, dequeue: dequeueStore } = useGlobalModalQueueStore();

  const isOpen = !!modalQueue[0] && modalQueue[0].key === modal && !modalQueue[0].pending;
  const enqueue = useCallback(() => {
    enqueueStore(modal);
  }, [enqueueStore, modal]);

  const dequeue = useCallback(() => {
    dequeueStore(modal);
  }, [dequeueStore, modal]);

  return {
    isOpen,
    enqueue,
    dequeue,
  };
};

export default useGlobalModalQueue;

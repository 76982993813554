import { memo } from 'react';

import { Block, Icon, Text, Tooltip } from '@bilira-org/design';
import { ForceDecimal, formatPriceValue } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

type Props = {
  asset?: string;
  withdrawFee?: string;
  loading?: boolean;
  totalAmount?: string;
  withdrawTotalAmount?: string;
};
const CryptoWithdrawalFee = ({ asset, withdrawFee, totalAmount, withdrawTotalAmount, loading }: Props) => {
  const { t, i18n } = useTranslation();

  const formatValue = (value: string | undefined) => {
    if (value === undefined || asset === undefined) {
      return '-';
    }

    const result = formatPriceValue({
      value: value,
      precision: undefined,
      decimal: 2,
      forceDecimal: ForceDecimal.SHOW_INSIGNIFICANT_DIGITS,
      locale: i18n.language,
    });
    return `${result} ${asset}`;
  };

  return (
    <Block gap="sm">
      <Block row justify="between">
        <Text size="sm">{t('common.withdraw-total-amount')}</Text>
        <Text loading={loading} align="right" size="sm" weight="medium" color="primary-500">
          {formatValue(totalAmount)}
        </Text>
      </Block>
      <Block row justify="between">
        <Block row items="center" gap="xs">
          <Text size="sm">{t('common.withdraw-fee')}</Text>
          <Tooltip width="size-80" placement="top" message={t('crypto.withdraw.withdraw-fee-info')}>
            <Icon color="neutral-800" type="o:question-mark-circle" size="sm" />
          </Tooltip>
        </Block>
        <Text loading={loading} align="right" size="sm" weight="medium">
          {formatValue(withdrawFee)}
        </Text>
      </Block>
      <Block row justify="between">
        <Text size="sm">{t('common.total-amount-to-be-deducted')}</Text>
        <Text loading={loading} align="right" size="sm" weight="medium" color="primary-500">
          {formatValue(withdrawTotalAmount)}
        </Text>
      </Block>
    </Block>
  );
};

export default memo(CryptoWithdrawalFee);

import { useMemo, useState } from 'react';

import { useIsomorphicEffect } from '@bilira-org/react-utils';

export type UseMeasureRect = Pick<
  DOMRectReadOnly,
  'x' | 'y' | 'top' | 'left' | 'right' | 'bottom' | 'height' | 'width'
>;
export type UseMeasureRef<E extends Element = Element> = (element: E) => void;
export type UseMeasureResult<E extends Element = Element> = [UseMeasureRef<E>, UseMeasureRect];

const defaultState: UseMeasureRect = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
};

/**
 * Hook for measuring the dimensions and position of a DOM element.
 *
 * @template E - The type of the DOM element.
 * @returns {[UseMeasureRef<E>, UseMeasureRect]} - A tuple array containing a ref function and the measured dimensions and position.
 */
function useMeasure<E extends Element = Element>(): UseMeasureResult<E> {
  const [element, ref] = useState<E | null>(null);
  const [rect, setRect] = useState<UseMeasureRect>(defaultState);

  /**
   * ResizeObserver to observe changes in the dimensions of the target element.
   */
  const observer = useMemo(
    () =>
      new (window as any).ResizeObserver((entries: ResizeObserverEntry[]) => {
        if (entries[0]) {
          const { x, y, width, height, top, left, bottom, right } = entries[0].target.getBoundingClientRect();
          setRect({ x, y, width, height, top, left, bottom, right });
        }
      }),
    [],
  );

  useIsomorphicEffect(() => {
    if (!element) return;
    observer.observe(element);

    return () => {
      // Disconnect the ResizeObserver when the component unmounts.
      observer.disconnect();
    };
  }, [element]);

  return [ref, rect];
}

export default useMeasure;

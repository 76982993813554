const arrayMin = (numbers: number[]) => Math.min(...numbers);
const arrayMax = (numbers: number[]) => Math.max(...numbers);

export interface SparklineDataPoint {
  x: number;
  y: number;
}

export interface SparklineProps {
  data: number[];
  limit?: number;
  width?: number;
  height?: number;
  margin?: number;
  max?: number;
  min?: number;
}

export function createSparkline({
  data,
  limit,
  width = 1,
  height = 1,
  margin = 1,
  max = arrayMax(data),
  min = arrayMin(data),
}: SparklineProps): SparklineDataPoint[] {
  const len = data.length;

  if (limit && limit < len) {
    data = data.slice(len - limit);
  }

  const vfactor = (height - margin * 2) / (max - min || 2);
  const hfactor = (width - margin * 2) / ((limit || len) - (len > 1 ? 1 : 0));

  return data.map((d, i) => ({
    x: i * hfactor + margin,
    y: (max === min ? 1 : max - d) * vfactor + margin,
  }));
}

export const getPointsMean = (points: SparklineDataPoint[]) => {
  const yPoints = points.map((p) => p.y) || [];
  return yPoints.reduce((a, b) => a + b) / points.length;
};

export const getPathData = (data: SparklineDataPoint[]) => {
  return data.reduce((acc, point, index) => `${acc}${index === 0 ? 'M' : 'L'}${point.x},${point.y}`, '');
};

export function createRoundedPath(coords: SparklineDataPoint[], radius: number, close: boolean) {
  let path = '';
  const length = coords.length + (close ? 1 : -1);
  for (let i = 0; i < length; i++) {
    const a = coords[i % coords.length];
    const b = coords[(i + 1) % coords.length];
    const t = Math.min(radius / Math.hypot(b.x - a.x, b.y - a.y), 0.5);

    if (i > 0) path += `Q${a.x},${a.y} ${a.x * (1 - t) + b.x * t},${a.y * (1 - t) + b.y * t}`;

    if (!close && i == 0) path += `M${a.x},${a.y}`;
    else if (i == 0) path += `M${a.x * (1 - t) + b.x * t},${a.y * (1 - t) + b.y * t}`;

    if (!close && i == length - 1) path += `L${b.x},${b.y}`;
    else if (i < length - 1) path += `L${a.x * t + b.x * (1 - t)},${a.y * t + b.y * (1 - t)}`;
  }
  if (close) path += 'Z';
  return path;
}

import { useQuery } from '@tanstack/react-query';

import { HttpClientType, HttpErrorType } from '../../client';
import { FeeDataType, LanguageType, MobileLocaleDataType, NetworkETAType, QueryOptions } from '../../model';
import { CdnApi, ICdnApi } from '../api';
import { CdnQueryKey } from '../constants';

class CdnQuery {
  _api: ICdnApi;

  public constructor(params: HttpClientType) {
    this._api = new CdnApi(params);
  }

  public useGetFeeData = (options?: QueryOptions) =>
    useQuery<FeeDataType[], HttpErrorType>({
      queryKey: [CdnQueryKey.FEE_DATA],
      queryFn: this._api.getFeeData,
      ...options,
    });

  public useGetMobileLocaleData = (locale: LanguageType, options?: QueryOptions) =>
    useQuery<MobileLocaleDataType, HttpErrorType>({
      queryKey: [CdnQueryKey.MOBILE_LOCALE_DATA, locale],
      queryFn: () => this._api.getMobileLocaleData(locale),
      ...options,
    });

  public useGetNetworkETA = (options?: QueryOptions) =>
    useQuery<NetworkETAType, HttpErrorType>({
      queryKey: [CdnQueryKey.NETWORK_ETA],
      queryFn: this._api.getNetworkETA,
      ...options,
    });
}

export default CdnQuery;

import React from 'react';

import { TransactionType } from '@bilira-org/react-utils';
import type { TransactionsRecordType } from '@bilira-org/react-utils';

import InternalContent from '@/components/transaction/modal/components/components/internal';
import ConversionContent from '@Components/transaction/modal/components/components/conversion';
import CryptoContent from '@Components/transaction/modal/components/components/crypto';
import FiatContent from '@Components/transaction/modal/components/components/fiat';
import OTCContent from '@Components/transaction/modal/components/components/otc';
import PaybackContent from '@Components/transaction/modal/components/components/payback';
import SwapContent from '@Components/transaction/modal/components/components/swap';

type Props = {
  data: TransactionsRecordType;
};

const getTransactionDetailComponent = (type: TransactionType, direction: string) => {
  switch (type) {
    case 'fiat':
      return FiatContent;
    case 'crypto':
      return CryptoContent;
    case 'otc':
      return OTCContent;
    case 'swap':
      return SwapContent;
    case 'conversion':
      return ConversionContent;
    case 'payback':
      return PaybackContent;
    case 'other':
    case 'l2e':
    case 'promo':
      return InternalContent;
    default:
      return null;
  }
};

const TransactionDetail = ({ data }: Props) => {
  const Element = getTransactionDetailComponent(data.type, data.direction);
  if (!Element) {
    return null;
  }

  return <Element data={data} />;
};

export default TransactionDetail;

import React, { HTMLProps, ReactNode } from 'react';

import { clsx } from 'clsx';
import { LinkProps, Link as RouterLink, To } from 'react-router-dom';

import { BaseComponentType, DisplayType, PaddingSizes, SizeType, TextColorType, TextSizes } from '../types';

interface CommonProps extends BaseComponentType {
  /** The content of the link. */
  children?: ReactNode;
  /** The text color of the link. */
  color?: TextColorType;
  /** The text size of the link. */
  size?: TextSizes;
  /** Additional CSS class names for styling. */
  className?: string;
  /** Indicates whether the link should have an underline. */
  underline?: boolean;
  /** Indicates whether the link should change its appearance on hover. */
  hover?: boolean;
  /** Indicates whether the link should have underline on hover. */
  underlineOnHover?: boolean;
  /** Padding size for the link. */
  px?: PaddingSizes;
  /** Indicates whether the link is an anchor (`<a>`) or a react-router `Link`. */
  anchor?: boolean;
  /** The destination URL or route for the link. */
  to?: To;
  /** The width of the anchor element. */
  width?: SizeType;
  /** Display box type of the anchor element */
  display?: DisplayType;
}

interface ILink extends CommonProps, Omit<LinkProps, keyof CommonProps> {
  anchor?: false;
}

interface IAnchor extends CommonProps, Omit<HTMLProps<HTMLAnchorElement>, keyof CommonProps> {
  anchor: true;
}

type LinkAnchorProps = ILink | IAnchor;

/**
 * A flexible and customizable link component that supports both react-router `Link` and HTML anchor (`<a>`) functionality.
 *
 * @example
 * // react-router `Link`
 * <Link to="/">Home</Link>
 *
 * // HTML anchor (`<a>`)
 * <Link anchor href="https://www.bilira.co/" target="_blank">BiLira</Link>
 */
const Link = (props: LinkAnchorProps) => {
  const {
    px,
    hover,
    underline,
    color,
    size,
    children,
    className,
    testId,
    width,
    underlineOnHover,
    display = 'inline-block',
    ...rest
  } = props;

  const classes = clsx(className, display, {
    [`tw-text-${size}`]: size,
    [`tw-text-${color}`]: color,
    'hover:text-primary-500': hover,
    [`px-${px}`]: px,
    underline: underline,
    [`tw-w-${width}`]: width,
    'hover:underline': underlineOnHover,
  });

  if (rest.anchor) {
    const { anchor, ...anchorProps } = rest;
    return (
      <a {...anchorProps} className={classes} data-testid={testId}>
        {children}
      </a>
    );
  } else {
    const { anchor, ...linkProps } = rest;
    return (
      <RouterLink {...(linkProps as LinkProps)} className={classes} data-testid={testId}>
        {children}
      </RouterLink>
    );
  }
};

Link.displayName = 'Link';

export default Link;

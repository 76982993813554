import { Fragment, useRef } from 'react';

import { Disclosure, Transition } from '@headlessui/react';

import AccordionIcon, { AccordionIconProps, defaultIconProps } from './icon';
import { useAccordionContext } from './useAccordionContext';
import { Block } from '../block';
import { BaseComponentType } from '../types';

export interface AccordionItemProps extends BaseComponentType {
  /** Key of the accordion item */
  accordionKey: string;
  /** Label of the accordion item */
  label?: React.ReactNode;
  /** Children of the accordion item */
  children?: React.ReactNode;
  /** Props for the accordion icon */
  iconProps?: Omit<AccordionIconProps, 'active'>;
}

/**
 * Accordion Item component that reveal children when clicked.
 *
 * Should be used inside Accordion component.
 *
 * @example
 * <Accordion>
 *   <AccordionItem label="Item 1">
 *     <p>Content 1</p>
 *   </AccordionItem>
 *   <AccordionItem label="Item 2">
 *     <p>Content 2</p>
 *   </AccordionItem>
 * </Accordion>
 */
const AccordionItem = ({
  accordionKey,
  label,
  children,
  testId,
  iconProps: iconPropsFromProps,
}: AccordionItemProps) => {
  const { activeAccordions, toggleAccordion, variant, iconProps: iconPropsFromContext } = useAccordionContext();
  const iconProps = iconPropsFromProps || iconPropsFromContext || defaultIconProps[variant];
  const isAccordionActive = activeAccordions.get(accordionKey) || false;
  const accordionRef = useRef<HTMLButtonElement | null>(null);

  return (
    <Disclosure data-testid={testId ?? 'accordion-item'}>
      {() => (
        <Block className={isAccordionActive ? 'accordion-item-active' : ''}>
          <Disclosure.Button
            as="div"
            onClick={() => {
              toggleAccordion(accordionKey);
              setTimeout(() => {
                if (!isAccordionActive) {
                  accordionRef.current?.scrollIntoView({ behavior: 'smooth' });
                }
              }, 100);
            }}
            className="accordion-btn cursor-pointer"
            ref={accordionRef}
          >
            {iconProps.position === 'left' && <AccordionIcon active={isAccordionActive} {...iconProps} />}
            {label}
            {iconProps.position === 'right' && <AccordionIcon active={isAccordionActive} {...iconProps} />}
          </Disclosure.Button>
          <Transition
            as={Fragment}
            show={isAccordionActive}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel static>
              <Block className="accordion-content">{children}</Block>
            </Disclosure.Panel>
          </Transition>
        </Block>
      )}
    </Disclosure>
  );
};

export default AccordionItem;

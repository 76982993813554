import dayjs, { Dayjs } from 'dayjs';

import { SelectedDatesType } from './dateRangePicker';

export function generateDateArray(month: number, year: number) {
  const date = dayjs().year(year).month(month).startOf('month');
  const startOffset = date.day() ? date.day() - 1 : 6;
  const endOffset = 42 - startOffset - date.daysInMonth();

  const dates: { date: Dayjs; offset?: boolean }[] = [];

  for (let i = startOffset; i > 0; i--) {
    dates.push({ date: date.subtract(i, 'days'), offset: true });
  }

  for (let i = 0; i < date.daysInMonth(); i++) {
    dates.push({ date: date.add(i, 'days') });
  }

  for (let i = 0; i < endOffset; i++) {
    dates.push({ date: date.add(1, 'month').add(i, 'days'), offset: true });
  }
  return dates;
}

export function getYearsInDecade(year: number) {
  const startYear = Math.floor(year / 10) * 10;

  const years = [];

  for (let i = 0; i < 12; i++) {
    years.push(startYear - 1 + i);
  }

  return years;
}

export function getInitialCalendarDates(selectedDates: SelectedDatesType) {
  const startDate = dayjs(selectedDates[0]);

  let endDate = selectedDates[1] ? dayjs(selectedDates[1]) : dayjs(selectedDates[0]).add(1, 'month');

  if (endDate.isSame(startDate, 'month') && endDate.isSame(startDate, 'year')) {
    endDate = endDate.add(1, 'month');
  }
  return [startDate, endDate] as SelectedDatesType;
}

import React from 'react';

import { clsx } from 'clsx';

import { TTableProps } from './gridview';
import { Breakpoints, useWindowSize } from '../../hooks';
import { Display } from '../display';
import { BaseComponentType } from '../types';

type GridBreakpoint = boolean | undefined | Breakpoints[];

export type Props<TEntry> = BaseComponentType & {
  /** An array of column configurations for the grid. */
  columns: TTableProps<TEntry>['columns'];
  /** Whether the grid should be responsive based on window size. Defaults to `false`. */
  responsive?: TTableProps<TEntry>['responsive'];
  /** Whether to display the header row. Defaults to `true`. */
  displayHeader?: TTableProps<TEntry>['displayHeader'];
  rounded?: TTableProps<TEntry>['rounded'];
  /** Display incremental row number*/
  rowNumber?: TTableProps<TEntry>['rowNumber'];
};

/**
 * Gridview Header component for displaying tabular data header.
 */
const GridviewHeader = <TEntry,>({
  columns,
  displayHeader = true,
  responsive = false,
  rounded,
  rowNumber,
}: Props<TEntry>) => {
  const breakpoint = useWindowSize({ enable: responsive });

  const getHiddenClass = (hidden?: GridBreakpoint) => {
    if (typeof hidden === 'boolean') {
      return hidden ? 'hidden' : '';
    }
    return responsive && hidden?.includes(breakpoint) ? 'hidden' : '';
  };

  if (!displayHeader) {
    return null;
  }

  return (
    <thead className="gridview-header">
      <tr
        className={clsx('header-row', {
          [`row-rounded`]: rounded,
        })}
      >
        <Display show={rowNumber}>
          <td>#</td>
        </Display>
        {columns.map((column, index) => (
          <th key={(column.title || '') + index} align={column.align} className={clsx(getHiddenClass(column.hidden))}>
            {column.title}
          </th>
        ))}
      </tr>
    </thead>
  );
};

GridviewHeader.displayName = 'GridviewHeader';

export default GridviewHeader;

import { useQuery } from '@tanstack/react-query';

import { HttpClientType, HttpErrorType } from '../../client';
import {
  AllowancesParamsType,
  AllowancesType,
  CryptoDepositLimitParamType,
  CryptoDepositLimitType,
  DefaultAllowanceType,
  NextTransactionType,
  QueryOptions,
} from '../../model';
import { AllowancesApi, IAllowances } from '../api';
import { AllowancesQueryKey } from '../constants';

class AllowancesQuery {
  _api: IAllowances;

  public constructor(params: HttpClientType) {
    this._api = new AllowancesApi(params);
  }

  public useGetAllowances = (params: AllowancesParamsType, options?: QueryOptions) =>
    useQuery<AllowancesType, HttpErrorType>({
      queryKey: [AllowancesQueryKey.ALLOWANCES, params],
      queryFn: () => this._api.getAllowances(params),
      ...options,
    });

  public useGetNextTransaction = (params: AllowancesParamsType, options?: QueryOptions) =>
    useQuery<NextTransactionType, HttpErrorType>({
      queryKey: [AllowancesQueryKey.NEXT_TRANSACTION, params],
      queryFn: () => this._api.getNextTransaction(params),
      ...options,
    });

  public useGetCryptoDepositLimits = (params: Partial<CryptoDepositLimitParamType>, options?: QueryOptions) =>
    useQuery<CryptoDepositLimitType[], HttpErrorType>({
      queryKey: [AllowancesQueryKey.CRYPTO_DEPOSIT_LIMIT, params],
      queryFn: () => this._api.getCryptoDepositLimits(params),
      ...options,
    });

  public useGetDefaultAllowances = (options?: QueryOptions) =>
    useQuery<DefaultAllowanceType[], HttpErrorType>({
      queryKey: [AllowancesQueryKey.DEFAULT_ALLOWANCES],
      queryFn: () => this._api.getDefaultAllowances(),
      ...options,
    });
}

export default AllowancesQuery;

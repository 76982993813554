import { getFormattedNumberByPrice } from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';

import { BuySellDirection } from '@Components/trade/types';

const CONST_AMOUNT = '1';

/**
 * Calculates the price based on the provided amount, unit price, and direction.
 *
 * @param {string} amount - The amount to calculate the price for.
 * @param {string} unitPrice - The unit price.
 * @param {string} direction - The direction of the swap ('from' or 'to').
 * @param {number} [decimalPlaces] - The number of decimal places to round the calculated price to.
 * @param {string} locale - The locale of the formatted result.
 * @returns {string | null} The calculated price or null if the calculation cannot be performed.
 *
 * @example
 *  calculatePrice('10', '0.5', 'from'); // '20'
 */
export const calculatePrice = (
  amount: string,
  unitPrice: string,
  direction: BuySellDirection,
  locale: string,
): null | string => {
  if (amount === '') return null;

  const price1 = BigNumber(direction === 'buy' ? CONST_AMOUNT : unitPrice);
  const price2 = BigNumber(direction === 'buy' ? unitPrice : CONST_AMOUNT);

  if (price1.isZero() || price2.isZero()) {
    return null;
  }

  const u = BigNumber(price1).dividedBy(BigNumber(price2));
  const price = BigNumber(amount).multipliedBy(u).toString(10);

  return getFormattedNumberByPrice({
    value: price,
    price: unitPrice,
    type: direction === 'buy' ? 'amount' : 'price',
    locale: locale,
  });
};

export default calculatePrice;

import React, { memo, useEffect, useMemo, useState } from 'react';

import { Block, Flex, Icon, Meta, Panel, Skeleton, Text } from '@bilira-org/design';
import { BankAccountType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { CreateBankAccountModal } from '@Components/bank/bankAccount';
import { BankIcon } from '@Components/icon/BankIcon';
import AccountQuery from '@Libs/clientInstances/accountQuery';
import useAuth from '@Libs/hooks/userAuth';
import { truncateMiddle } from '@Libs/utils';

type Props = {
  callback: (bankAccount?: BankAccountType) => void;
};
const ActiveBankPanel = memo(({ callback }: Props) => {
  const { t } = useTranslation();
  const [activeWalletModal, setActiveWalletModal] = useState(false);
  const { account } = useAuth();
  const { data, isLoading } = AccountQuery.useGetBankAccounts(account, true);

  const bankAccount = useMemo(() => data?.[0], [data]);

  useEffect(() => {
    callback(bankAccount);
  }, [bankAccount]);

  if (isLoading) {
    return <ActiveBankPanelSkeleton />;
  }

  return (
    <>
      <Panel border="neutral-400" size="md" onClick={() => setActiveWalletModal(true)}>
        <Block row justify="between" items="center">
          {bankAccount ? (
            <>
              <Block row gap="sm" items="center">
                <BankIcon name={bankAccount?.bank_code} type="icon" height="xs" />
                <Meta
                  title={
                    <Text color="secondary-500" weight="semibold">
                      {bankAccount.alias}
                    </Text>
                  }
                  description={<Text size="sm">{truncateMiddle(bankAccount.iban, 8, 4, '****')}</Text>}
                />
              </Block>
            </>
          ) : (
            <Text size="sm" color="neutral-500">
              {t('fiat.requested-bank-iban')}
            </Text>
          )}
          <Icon type="o:document-text" size="lg" color="neutral-700" onClick={() => setActiveWalletModal(true)} />
        </Block>
      </Panel>
      <CreateBankAccountModal isModeSelection modalActive={activeWalletModal} setModalActive={setActiveWalletModal} />
    </>
  );
});

export default ActiveBankPanel;

const ActiveBankPanelSkeleton = () => {
  return (
    <Panel border="neutral-400" size="md">
      <Flex direction="row" gap="sm" items="center">
        <Flex.Item flexType="none">
          <Skeleton rounded="full" width="size-8" height="size-8" />
        </Flex.Item>
        <Flex.Item flex gap="sm" flexType="1">
          <Skeleton width="size-1/2" />
          <Skeleton height="size-3" width="size-full" />
        </Flex.Item>
        <Flex.Item flexType="none">
          <Skeleton rounded="full" width="size-6" />
        </Flex.Item>
      </Flex>
    </Panel>
  );
};

/**
 * Checks if the value type is string or undefined
 * This utility narrows types with type guards. https://www.typescriptlang.org/docs/handbook/2/narrowing.html
 *
 * @param {unknown} value - The value to be checked.
 * @returns {boolean} - `true` if the value is a finite number, `false` otherwise.
 *
 * @example
 * isStringOrUndefined('hello'); // true
 * isStringOrUndefined(undefined); // true
 * isStringOrUndefined(42); // false
 */
export function isStringOrUndefined(value: unknown): value is string | undefined {
  return typeof value === 'string' || typeof value === 'undefined';
}

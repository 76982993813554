import React, { memo } from 'react';

import { Flex, Icon, Layout, Span, Tab } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { withPublicRoute } from '@/libs/hocs';
import HeadTags from '@Components/common/HeadTags';
import RewardsQuery from '@Libs/clientInstances/rewardsQuery';
import Header from '@Modules/rewards/public/components/Header';

import NoSeasonContent from '../components/NoSeasonContent';
import RewardsRegistration from '../components/RewardsRegistration';

const { Content } = Layout;

const Index = () => {
  const { t } = useTranslation();
  const { data: seasons, isError } = RewardsQuery.useGetSeasons();

  return (
    <>
      <HeadTags title={t('navigation.profile.reward')} titleSuffix="subpage" />
      <Header />
      <Content>
        {seasons?.current_season === undefined || isError ? (
          <NoSeasonContent />
        ) : (
          <Tab
            defaultIndex={0}
            variant="bordered"
            headers={[
              {
                name: t('rewards.tabs.entry'),
                key: 'entry',
              },
              {
                name: (
                  <Flex direction="row" items="center" gap="xs">
                    {t('rewards.tabs.season', { number: seasons ? seasons?.past_seasons.length + 1 : '-' })}
                    <Icon type="o:lock-closed" size="sm" />
                  </Flex>
                ),
                key: 'rewards.tabs.currentSeason',
                disabled: true,
              },
              {
                name: (
                  <Flex direction="row" items="center">
                    {t('rewards.tabs.season', { number: seasons ? seasons?.past_seasons.length + 2 : '-' })} &nbsp;
                    <Span size="xs" weight="medium">
                      {t('rewards.tabs.coming-soon')}
                    </Span>
                  </Flex>
                ),
                key: 'rewards.tabs.nextSeason',
                disabled: true,
              },
            ]}
          >
            <Tab.Panel>
              <RewardsRegistration />
            </Tab.Panel>
          </Tab>
        )}
      </Content>
    </>
  );
};

export default memo(withPublicRoute(Index));

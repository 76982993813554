import { FunctionComponent } from 'react';

import { useDarkMode } from '@bilira-org/design';
import { useIsomorphicEffect } from '@bilira-org/react-utils';
import { getI18n } from 'react-i18next';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

const widgetRoutes = ['/widget/market', '/widget'];

/**
 * WidgetRoute component for handling routes related to widgets in the application.
 *
 * This component manages the dark mode setting and language for widget routes.
 * If a specific pair is provided in the URL query parameters, it redirects the user to the corresponding market detail page.
 * For other widget routes, it renders the provided element if the path is included in widget routes.
 */
function WidgetRoute({ element }: { element: FunctionComponent }) {
  const [, setEnableDarkMode] = useDarkMode();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const pair = searchParams.get('pair');
  const lang = searchParams.get('lang');

  useIsomorphicEffect(() => {
    setEnableDarkMode(false);

    getI18n()
      .changeLanguage(lang || 'tr')
      .then();
  }, []);

  if (pair) {
    return <Navigate to={`/market/${pair}`} replace />;
  }

  if (!widgetRoutes.includes(location.pathname)) {
    return null;
  }

  const Element = element;
  return <Element />;
}

export default WidgetRoute;

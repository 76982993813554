import {
  ChartOptions,
  ColorType,
  CrosshairMode,
  DeepPartial,
  ISeriesApi,
  LineStyle,
  MouseEventParams,
} from 'lightweight-charts';

import { ChartPriceDataType } from '../lightweightChart';

/**
 * Returns the configuration for the LightweightChart
 */
export const lightweightChartConfiguration = (colorConf: any): DeepPartial<ChartOptions> => {
  return {
    layout: {
      background: { type: ColorType.Solid, color: colorConf.backgroundColor },
      textColor: colorConf.textColor,
      fontSize: 12,
      fontFamily: 'Archivo, sans-serif',
    },
    height: 240,
    autoSize: true,
    rightPriceScale: {
      visible: false,
    },
    leftPriceScale: {
      visible: true,
      borderColor: colorConf.borderColor,
    },
    grid: {
      horzLines: {
        visible: false,
      },
      vertLines: {
        visible: false,
      },
    },
    timeScale: {
      fixLeftEdge: true,
      fixRightEdge: true,
      barSpacing: 10,
      borderColor: colorConf.borderColor,
      timeVisible: true,
      tickMarkMaxCharacterLength: 10,
    },

    handleScale: {
      axisPressedMouseMove: {
        time: false,
        price: false,
      },
      axisDoubleClickReset: false,
      mouseWheel: false,
      // pinch: false,
    },
    handleScroll: {
      mouseWheel: false,
      pressedMouseMove: false,
      horzTouchDrag: false,
      vertTouchDrag: false,
    },
    crosshair: {
      // Change mode from default 'magnet' to 'normal'.
      // Allows the crosshair to move freely without snapping to datapoints
      mode: CrosshairMode.Magnet,
      // Vertical crosshair line (showing Date in Label)
      vertLine: {
        color: '#9da1a9',
        style: LineStyle.Dotted,
        labelBackgroundColor: colorConf.areaBottomColor,
        visible: true,
      },
      // Horizontal crosshair line (showing Price in Label)
      horzLine: {
        color: '#B3D1FE',
        style: LineStyle.Dotted,
        labelBackgroundColor: '#E2EDFE',
        visible: false,
        labelVisible: false,
      },
    },
  };
};

/**
 * Utility function that subscribes to the crosshair move event
 */
export const subscribeCrosshairMoveEvent = (
  param: MouseEventParams,
  series: ISeriesApi<'Area'>,
  callback: (data?: ChartPriceDataType) => void,
) => {
  if (param.point === undefined || !param.time || param.point.x < 0 || param.point.y < 0) {
    callback(undefined);
  } else {
    const seriesData = param.seriesData.get(series);
    const price = (seriesData as { value: number })?.value;

    const { value } = series.dataByIndex(0) as { value: number };

    const change = (price * 100) / value - 100;

    callback({ time: param.time, value: price, change: change.toPrecision(3) });
  }
};

import React from 'react';

import { Alert, Display } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import useSwapStateHook from '@Components/trade/buySell/hooks/useSwapStateHook';

/**
 * Component to render a timeout alert for the swap preview.
 */
function BuySellTimeoutAlert() {
  const { t } = useTranslation();
  const { isOfferTimeout, isOfferCanceled, offerPrice } = useSwapStateHook();

  return (
    <>
      <Display motion show={isOfferTimeout}>
        <Alert status="yellow">{t('market.swap.could-not-get-offer-try-again')}</Alert>
      </Display>
      <Display motion show={isOfferCanceled && !offerPrice}>
        <Alert status="yellow">{t('market.swap.could-not-get-offer-try-again')}</Alert>
      </Display>
    </>
  );
}

export default BuySellTimeoutAlert;

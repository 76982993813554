import React from 'react';

import { LayoutV2 } from '@bilira-org/design';

import CallbackContainer from '@Modules/auth/callback/CallbackContainer';

const { Content, InnerContent } = LayoutV2;

const Index = () => {
  return (
    <Content style={{ height: '100%' }}>
      <InnerContent>
        <CallbackContainer />
      </InnerContent>
    </Content>
  );
};

export default Index;

import React from 'react';

import { Block, Loop, Separator, Skeleton } from '@bilira-org/design';
import { checkIsOfferValid, OTCType } from '@bilira-org/react-utils';

import OTCRowActive from './components/OTCRowActive';
import OTCRowPassive from './components/OTCRowPassive';

type Props = {
  data: OTCType[];
  onDetailClick: (id: string) => void;
  acceptRequest: (entry: OTCType) => () => void;
  cancelRequest: (entry: OTCType) => () => void;
  expireTimeout: () => void;
  loading?: boolean;
};

const OTCTableResponsive = ({ data, onDetailClick, acceptRequest, cancelRequest, expireTimeout, loading }: Props) => {
  if (loading) {
    return <OTCTableResponsiveSkeleton />;
  }

  return (
    <Block>
      {data.map((entry) => {
        if (checkIsOfferValid(entry)) {
          return (
            <React.Fragment key={entry.id}>
              <Separator width="size-full" color="neutral-400" />
              <OTCRowActive
                key={entry.id}
                entry={entry}
                acceptRequest={acceptRequest}
                cancelRequest={cancelRequest}
                expireTimeout={expireTimeout}
              />
            </React.Fragment>
          );
        }
        return (
          <React.Fragment key={entry.id}>
            <Separator width="size-full" color="neutral-400" />
            <OTCRowPassive key={entry.id} entry={entry} onDetailClick={onDetailClick} />
          </React.Fragment>
        );
      })}
    </Block>
  );
};

export default OTCTableResponsive;

const OTCTableResponsiveSkeleton = () => {
  return (
    <Block gap="4xl" mt="lg">
      <Loop<number> data={[1, 2, 3]}>
        {(data) => (
          <Block key={`skeleton-${data}`} row justify="between" mx="md">
            <Skeleton height="size-3" width="size-20" />
            <Skeleton height="size-3" width="size-20" />
            <Skeleton height="size-3" width="size-20" />
            <Skeleton height="size-3" width="size-20" />
          </Block>
        )}
      </Loop>
    </Block>
  );
};

import { BreakpointType, TextSizes } from '@bilira-org/design/types';
import { FormatPriceType } from '@bilira-org/react-utils';

import { TransactionItemSizeType } from '../TransactionTable';

export const transactionTextSize: Record<TransactionItemSizeType, TextSizes | BreakpointType<TextSizes>> = {
  base: { xs: 'sm', sm: 'base' },
  sm: 'sm',
};

export const transactionStatusSize: Record<TransactionItemSizeType, TextSizes | BreakpointType<TextSizes>> = {
  base: { xs: 'xs', sm: 'sm' },
  sm: 'xs',
};

export const transactionFormatPriceProps: Partial<FormatPriceType> = { decimal: 2, precision: 8 };

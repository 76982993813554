import { SwapPairType } from '../../model';

/**
 * Filters a list of swap pairs to find a specific pair based on the given base and quote assets. If there's only one pair that matches the base asset, that pair is returned. If there are multiple pairs that match the base asset, but only one matches both base and quote assets, that pair is returned.
 * If there's one match based on base asset, the function may return it without checking quote asset.
 *
 * @param {SwapPairType[]} swapPairs - An array of swap pairs. Each swap pair should include base_asset and quote_asset among other details.
 * @param {string} base - The base asset symbol to filter by.
 * @param {string} quote - The quote asset symbol to filter by.
 * @returns {SwapPairType} The swap pair that matches the specified base and quote assets. If multiple pairs match the base asset, but only one matches both base and quote assets, that pair is returned. If there's no match or multiple matches, the function may return undefined or the first matching pair.
 *
 * @example
 * const swapPairs = [
 *   { base_asset: 'BTC', quote_asset: 'TRYB', ... },
 *   { base_asset: 'BTC', quote_asset: 'USD', ... },
 *   { base_asset: 'ETH', quote_asset: 'USD', ... }
 * ];
 *
 * filterSwapPairByPair(swapPairs, 'BTC', 'USD');
 * // --> Output: The swap pair object for BTC/USD
 *
 * @example
 * // Example with ETH that does not have a pair with TRYB.
 * const swapPairsMultiple = [
 *   { base_asset: 'BTC', quote_asset: 'TRYB', ... },
 *   { base_asset: 'BTC', quote_asset: 'USD', ... },
 *   { base_asset: 'ETH', quote_asset: 'USD', ... }
 * ];
 * filterSwapPairByPair(swapPairsMultiple, 'BTC', 'TRYB');
 * // --> Output: The swap pair object for BTC/USD
 */
const filterSwapPairByPair = (swapPairs: SwapPairType[], base: string, quote: string): SwapPairType => {
  const filteredPairs = swapPairs.filter((asset) => asset.base_asset === base);

  // If there's only one pair that matches the base asset, that pair is returned.
  return filteredPairs.length === 1
    ? filteredPairs[0]
    : (filteredPairs.find((asset) => asset.base_asset === base && asset.quote_asset === quote) as SwapPairType);
};
export default filterSwapPairByPair;

import React from 'react';

import { BaseComponentType } from '../../types';

interface FormFieldLabelProps extends BaseComponentType {
  children?: React.ReactNode;
  htmlFor?: string;
}

const FormFieldLabel = ({ children, htmlFor, testId }: FormFieldLabelProps) => {
  return (
    <label htmlFor={htmlFor} className="label" data-testid={testId ?? 'form-field-label'}>
      {children}
    </label>
  );
};

export default FormFieldLabel;

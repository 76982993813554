import { PriceQuery } from '@bilira-org/react-utils/src/apis/price';

import { env } from '@Libs/constants/env';

import getExtraHeaders from '../helpers/getExtraHeaders';

const client = new PriceQuery({
  baseUrl: env.PRICE_SERVICE_BASE_URL,
  getExtraHeaders,
});

Object.freeze(client);

export default client;

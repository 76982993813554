import { ICommonApi } from './interface';
import { HttpClient, HttpClientType, HttpErrorType } from '../../client';
import { AnnouncementPopupType, AnnouncementType, FeedbackBodyType, ResultType } from '../../model';

class CommonApi extends HttpClient implements ICommonApi {
  public constructor(conf: HttpClientType) {
    super(conf);
  }

  public getAnnouncements = async () =>
    await this.instance.get<HttpErrorType, AnnouncementType>('/common/announcements');

  /**
   * @deprecated
   */
  public postFeedback = async (body: FeedbackBodyType): Promise<ResultType> =>
    await this.instance.post<FeedbackBodyType, ResultType>(`/common/feedbacks`, body);

  public getAnnouncementPopup = async () =>
    await this.instance.get<HttpErrorType, AnnouncementPopupType>('/common/pop-ups');
}

export default CommonApi;

export const notificationPreferenceData = [
  {
    key: 'notification:fiat_deposit',
    i18nKey: 'fiat_deposit',
  },
  {
    key: 'notification:fiat_withdrawal',
    i18nKey: 'fiat_withdrawal',
  },
  {
    key: 'notification:crypto_deposit',
    i18nKey: 'crypto_deposit',
  },
  {
    key: 'notification:crypto_withdrawal',
    i18nKey: 'crypto_withdrawal',
  },
  {
    key: 'notification:swap',
    i18nKey: 'swap',
  },
];

export const dropdownOptions = [
  { name: 'common.permission-denied', value: false },
  { name: 'common.permission-granted', value: true },
];

import React from 'react';

import { Block, BlockProps, Icon, Tag, Text, TextProps } from '@bilira-org/design';
import { BackgroundColorType } from '@bilira-org/design/src/components/color';
import { FontWeight, JustifyType } from '@bilira-org/design/src/components/types';
import BigNumber from 'bignumber.js';

import { priceChangeState } from '@Libs/utils';

import FormattedPercentage from './FormattedPercentage';

type PriceChangeProps = Omit<BlockProps, 'children'> & {
  /** Price change percent */
  value?: string;
  /** Whether to wrap price change with tag */
  withTag?: boolean;
  /** Text size of the price change value */
  size?: TextProps['size'];
  /** Font weight of the price change value */
  weight?: FontWeight;
  /** Justify of the price change value */
  justify?: JustifyType;
  /** Display price raw change is a raw value to calculate percentage.*/
  showRawChange?: boolean;
  /** Display arrow*/
  showArrow?: boolean;
};

/**
 * Component to display price change with an rising or falling arrow based on value
 * Returns null if value is not provided.
 * @example
 * <PriceChange size="sm" withTag value={currentPriceChange} />
 *
 */
const PriceChange = ({
  withTag,
  value,
  showRawChange,
  showArrow = true,
  size,
  weight = 'medium',
  justify,
  ...props
}: PriceChangeProps) => {
  if (!value) {
    return null;
  }

  const changeState = priceChangeState(value);

  const arrow = changeState.getArrow();

  const rawChange = BigNumber(value).abs().toString();
  const absChange = BigNumber(value).multipliedBy(100).abs().toPrecision(2);

  const data = (
    <Block row items="center" justify={justify} gap="xs" {...props}>
      {showArrow && arrow && <Icon color={changeState.getColor()} type={arrow} size="xs" />}
      {showRawChange && (
        <Text color={changeState.getColor()} size={size} weight={weight}>
          {rawChange}
        </Text>
      )}
      <Text color={changeState.getColor()} size={size} weight={weight}>
        <FormattedPercentage value={absChange} />
      </Text>
    </Block>
  );

  if (withTag) {
    return <Tag color={`${changeState.getTransparentColor()}` as BackgroundColorType}>{data}</Tag>;
  }

  return data;
};

export default PriceChange;

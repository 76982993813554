import React from 'react';

import { Block, Hidden, Separator } from '@bilira-org/design';

import Help from '@Components/layouts/components/components/Help';
import LoginRegisterButton from '@Components/layouts/components/components/LoginRegister';
import PublicMobilMenu from '@Components/layouts/components/components/PublicMobilMenu';
import { withPublicRoute } from '@Libs/hocs';

import Preferences from './Preferences';

/**
 * Layout component for displaying header buttons for anonymous users
 */
const PublicHeaderButtons = () => {
  return (
    <Block row gap="lg" height="size-full" items="center">
      <Hidden show={{ xs: false, sm: false, md: true }}>
        <Block row gap="lg" height="size-full" items="center">
          <LoginRegisterButton type="login" />
          <LoginRegisterButton type="register" />
          <Separator height="size-5" color="neutral-500" />
          <Help show={{ sm: true }} />
          <Preferences show={{ sm: true }} />
        </Block>
      </Hidden>

      <PublicMobilMenu show={{ xs: true, sm: true, md: true, lg: true, xl: false }} />
    </Block>
  );
};

export default withPublicRoute(PublicHeaderButtons);

import { useEffect, useRef, useState } from 'react';

import debounce from 'lodash.debounce';

type FilterType<T> = {
  /** The predicate function to filter items outside the hook. */
  predicate: (item: T, search: string) => unknown;
  /** The array of items to be filtered. */
  data?: T[];
  /** The debounce time (in milliseconds) for filtering as the user types. */
  wait?: number;
};

/**
 * Hook for filtering data based on a predicate function and search text.
 *
 * @template T - The type of items in the data array.
 *
 * @param {FilterType<T>} options - Options for the filter hook.
 *
 * @returns {{
 *   searchText: string,
 *   onSearch: (value: string) => void,
 *   filteredData: T[] | undefined
 * }} - An object containing the search text, search function, and the filtered data.
 */
const useFilter = <T>({ predicate, data, wait = 500 }: FilterType<T>) => {
  const [searchText, setSearchText] = useState<string>('');
  const [filteredData, setFilteredData] = useState<T[] | undefined>(data);
  const [debouncing, setDebouncing] = useState(false);

  const onSearch = (value: string) => {
    setSearchText(value);
    setDebouncing(true);
  };

  useEffect(() => {
    if (!data) {
      setFilteredData(undefined);
      setDebouncing(false);
      return;
    }

    const debouncedFilter = debounce((value: string) => {
      const result = data.filter((d) => predicate(d, value));
      setFilteredData(result.length === 0 ? undefined : result);
      setDebouncing(false);
    }, wait);

    debouncedFilter(searchText);

    return () => {
      debouncedFilter.cancel(); // Cancel the debounced filter on unmount or if dependencies change
    };
  }, [data, predicate, searchText, wait]);

  return { searchText, onSearch, filteredData, debouncing };
};

export default useFilter;

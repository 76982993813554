import React from 'react';

import type { TransactionsRecordType } from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';

import FormattedNumber from '@/components/common/FormattedNumber';
import FormattedNumberByPrice from '@/components/common/FormattedNumberByPrice';
import { getTransactionSuffix } from '@Components/transaction/helpers';
import { TRANSACTION_STATUS } from '@Libs/helpers/getStateConfig';

import { TransactionItemSizeType } from '../../TransactionTable';
import { transactionFormatPriceProps, transactionTextSize } from '../constants';

type TransactionTitleProps = {
  data: TransactionsRecordType;
  size: TransactionItemSizeType;
};

const TransactionAmount = ({ data, size }: TransactionTitleProps) => {
  const withdrawAmountFeeExcluded = BigNumber(data.amount)
    .minus(data.withdrawal_fee || '0')
    .toString();

  return (
    <FormattedNumberByPrice
      align="right"
      wordBreak="all"
      size={transactionTextSize[size]}
      suffixSize={transactionTextSize[size]}
      suffix={getTransactionSuffix(data)}
      weight="medium"
      suffixWeight="medium"
      color={TRANSACTION_STATUS[data.status]?.text}
      value={withdrawAmountFeeExcluded}
      formatPriceProps={transactionFormatPriceProps}
      type="amount"
      {...(data.asset ? { base: data.asset } : { price: '1' })}
    />
  );
};

export default TransactionAmount;

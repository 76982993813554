import { SwapType } from '@bilira-org/react-utils/src/model/SwapTypes';
import { create } from 'zustand';

import { BuySellDirection } from '@Components/trade/types';

type SetStateAction<S> = S | ((prevState: S) => S);

export type TradeWidgetType = 'modal' | 'card';

interface BuySellStoreType {
  buySellOffer?: SwapType;
  setBuySellOffer: (tradeOffer?: SetStateAction<SwapType | undefined>) => void;
  buySellOfferModal?: Partial<Record<TradeWidgetType, boolean>>;
  setBuySellOfferModal: (active: Partial<Record<TradeWidgetType, boolean>>) => void;
  buySellType?: BuySellDirection;
  setBuySellType: (tradeType: BuySellDirection) => void;
}

export const useBuySellStore = create<BuySellStoreType>((set) => ({
  setBuySellOffer: (buySellOffer?: SetStateAction<SwapType | undefined>) =>
    set((state) => {
      return { buySellOffer: typeof buySellOffer === 'function' ? buySellOffer(state?.buySellOffer) : buySellOffer };
    }),
  buySellOfferModal: {} as Partial<Record<TradeWidgetType, boolean>>,
  setBuySellOfferModal: (active: Partial<Record<TradeWidgetType, boolean>>) =>
    set((state) => ({ buySellOfferModal: { ...state.buySellOfferModal, ...active } })),
  setBuySellType: (tradeType: BuySellDirection) => set(() => ({ buySellType: tradeType })),
}));

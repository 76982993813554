import { Button, Display, Empty, Overflow, Pagination, Panel, Separator } from '@bilira-org/design';
import { AllTransactionsRecordType, PagerMetadataType, TransactionTypes } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AllTransactionTable from '../AllTransactionTable';

type Props = {
  tableCallback: (item: AllTransactionsRecordType) => void;
  onPageChange: (page: number) => void;
  data: AllTransactionsRecordType[];
  metadata?: PagerMetadataType;
  isPending: boolean;
  type: TransactionTypes;
};

const AllTransactionsCard = ({ onPageChange, tableCallback, data, metadata, isPending, type }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Panel size="md" border="neutral-400">
      <div style={{ minHeight: '25vh' }}>
        <Overflow overflow="x-auto">
          <AllTransactionTable
            type={type}
            data={data}
            empty={
              <Empty
                my="3xl"
                gap="md"
                extra={
                  <Button variant="link" size="md" onClick={() => navigate('/fiat/deposit')}>
                    {t('transactions.deposit-tl-now')}
                  </Button>
                }
                message={t('transactions.empty-transaction-history-message')}
              />
            }
            loading={isPending}
            callback={tableCallback}
          />
        </Overflow>
      </div>
      <Display show={data.length}>
        <Separator my="sm" width="size-full" />
      </Display>
      <Pagination
        onPageChange={onPageChange}
        pagination={{
          page: metadata?.page,
          pageCount: metadata?.page_count,
          perPage: metadata?.per_page,
          totalCount: metadata?.total_count,
        }}
      />
    </Panel>
  );
};

export default AllTransactionsCard;

import React, { DetailedHTMLProps, forwardRef, InputHTMLAttributes, ReactNode, useRef } from 'react';

import { isEmpty } from '@bilira-org/react-utils';
import { clsx } from 'clsx';

import fixIosInputFocus from '../../helpers/fixIosInputFocus';
import { Icon } from '../icons';
import { composeRef } from '../ref';
import {
  BaseComponentType,
  BreakpointType,
  CustomWidthType,
  FontType,
  PaddingSizes,
  ResponsiveReturnType,
  TextAlign,
  TextColorType,
} from '../types';
import { buildResponsiveClass } from '../utils';

export type InputSearchProps = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'size' | 'color' | 'type' | 'placeholder' | 'padding' | 'icon' | 'textSize'
> &
  BaseComponentType & {
    /** Additional class name for the input */
    className?: string;
    /** Input type */
    variant: 'filled' | 'bordered' | 'ghost';
    /** Placeholder text for the input */
    placeholder?: string;
    /** Icon to be placed at the start of the input */
    iconStart?: ReactNode;
    /** Icon displayed at the end of the input. */
    iconEnd?: ReactNode;
    /** Custom width of the input */
    customWidth?: CustomWidthType | BreakpointType<CustomWidthType>;
    /** Text size of the input */
    size?: 'md' | 'lg';
    /** Callback function triggered on pressing Enter key */
    onEnter?: () => void;
  };

/**
 * Customizable styled Input component.
 *
 * @note
 * @see `InputNumber` for numeric inputs .
 * @see `InputPassword` for password inputs.
 *
 * @example
 * <Input
 *   iconStart={<Icon type="o:magnifying-glass" />}
 *   placeholder="Search something"
 *   value={searchText}
 *   onChange={(val) => onSearch(val.target.value)}
 * />
 */
const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>((props, ref): JSX.Element => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const {
    placeholder,
    iconEnd,
    iconStart,
    className,
    customWidth = 'full',
    size = 'md',
    testId,
    onEnter,
    onKeyDown,
    variant,
    onFocus,
    ...defaultProps
  } = props;
  let customWidthClasses: ResponsiveReturnType = {};

  if (customWidth) {
    customWidthClasses = buildResponsiveClass(customWidth, 'tw-custom-width-');
  }

  const classes = clsx('input-search', {}, className);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown?.(e);
    if (e.key === 'Enter') {
      onEnter?.();
    }
  };

  return (
    <label className={clsx('input-search-group', {})} data-testid={testId}>
      <span className="sr-only">Input Search</span>
      <span
        className={clsx('input-search-wrapper', `input-${variant}`, `input-${size}`, className, customWidthClasses)}
        aria-disabled={defaultProps.disabled}
      >
        {iconStart && <span className="input-icon-start">{iconStart}</span>}
        <input
          {...defaultProps}
          className={classes}
          ref={composeRef(inputRef, ref)}
          type="text"
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          onFocus={(e) => {
            fixIosInputFocus(e.currentTarget);
            onFocus?.(e);
          }}
        />
        {!isEmpty(inputRef?.current?.value) && (
          <span className="input-icon-end input-icon-close">
            <Icon
              tabIndex={0}
              type="s:x-circle"
              size={size === 'md' ? 'sm' : 'md'}
              className="outline-none"
              onClick={() => {
                if (inputRef?.current) {
                  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                    window.HTMLInputElement.prototype,
                    'value',
                  )!.set;
                  nativeInputValueSetter!.call(inputRef.current, '');

                  inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
                }
              }}
            />
          </span>
        )}
        {iconEnd && <span className="input-icon-end">{iconEnd}</span>}
      </span>
    </label>
  );
});

InputSearch.displayName = 'InputSearch';

export default InputSearch;

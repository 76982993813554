import React from 'react';

import { Block, Panel, Separator, Skeleton, Span, Tab } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import allowancesQuery from '@Libs/clientInstances/allowancesQuery';
import useAuth from '@Libs/hooks/userAuth';
import AllowancesSummary from '@Modules/account/account/accountLimit/components/AllowancesSummary';

function AccountAllowances() {
  const { t } = useTranslation();
  const { account } = useAuth();

  const { data: depositData, isLoading: isLoadingDepositData } = allowancesQuery.useGetAllowances({
    account,
    domain: 'fiat',
    direction: 'deposit',
  });

  const { data: withdrawalData, isLoading: isLoadingWithdrawalData } = allowancesQuery.useGetAllowances({
    account,
    domain: 'fiat',
    direction: 'withdrawal',
  });

  const { data: cryptoWithdrawalData, isLoading: isLoadingCryptoWithdrawalData } = allowancesQuery.useGetAllowances({
    account,
    domain: 'crypto',
    direction: 'withdrawal',
    asset: 'TRYB',
    network: 'ETH',
  });

  if (isLoadingDepositData || isLoadingWithdrawalData || isLoadingCryptoWithdrawalData) {
    return <AccountAllowancesSkeleton />;
  }

  return (
    <Tab
      headers={[
        {
          name: <Span size={{ xs: 'sm' }}>{t('account.account-limits-modal.bank-transfer-limits')}</Span>,
          key: 'bank-transfer-limits',
        },
        {
          name: <Span size={{ xs: 'sm' }}>{t('account.account-limits-modal.crypto-transfer-limits')}</Span>,
          key: 'crypto-limits',
        },
      ]}
    >
      <Tab.Panel>
        <AllowancesSummary
          asset="TL"
          depositTitle={t('account.account-limits-modal.asset-deposit', { asset: 'TL' })}
          withdrawalTitle={t('account.account-limits-modal.asset-withdrawal', { asset: 'TL' })}
          deposit={depositData}
          withdrawal={withdrawalData}
        />
      </Tab.Panel>
      <Tab.Panel>
        <AllowancesSummary
          asset="TRYB"
          depositTitle={t('account.account-limits-modal.crypto-deposit')}
          withdrawalTitle={t('account.account-limits-modal.crypto-withdrawal')}
          deposit="unlimited"
          withdrawal={cryptoWithdrawalData}
        />
      </Tab.Panel>
    </Tab>
  );
}

export default AccountAllowances;

const AccountAllowancesSkeleton = () => {
  return (
    <>
      <Skeleton width="size-full" mb="3xl" />
      <Block gap="4xl">
        <Panel border="neutral-400" size="lg">
          <Skeleton width="size-16" mb="lg" />

          <Block row justify="between">
            <Skeleton width="size-24" />
            <Skeleton width="size-16" />
          </Block>
          <Separator width="size-full" my="md" />
          <Block row justify="between">
            <Skeleton width="size-24" />
            <Skeleton width="size-16" />
          </Block>
        </Panel>
        <Panel border="neutral-400" size="lg">
          <Skeleton width="size-16" mb="lg" />

          <Block row justify="between">
            <Skeleton width="size-24" />
            <Skeleton width="size-16" />
          </Block>
          <Separator width="size-full" my="md" />
          <Block row justify="between">
            <Skeleton width="size-24" />
            <Skeleton width="size-16" />
          </Block>
        </Panel>
      </Block>
    </>
  );
};

import { Block, Button, Empty, Link, Modal, Span } from '@bilira-org/design';
import { NotificationType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import TransactionButtons from '@/components/transaction/modal/components/components/TransactionButtons';
import TransactionDetailModalContent from '@/components/transaction/modal/components/TransactionDetailModalContent';

import { getIsOtcTransaction, getTransactionType } from './helper';

type Props = {
  /** Notification data */
  data?: NotificationType;
  /** Callback function to be called when modal is closed*/
  onClose: () => void;
  /** Whether to hide modal action buttons */
  hideButton?: boolean;
};

/**
 * Transaction content for the notification modal.
 *
 * Displays transaction details.
 */
const TransactionNotificationContent = ({ data, onClose, hideButton }: Props) => {
  const { t } = useTranslation();

  const isOtcTransaction = getIsOtcTransaction(data?.type);

  return (
    <>
      <TransactionDetailModalContent
        transactionRecordInfo={{
          id: data?.data?.transactionId as string,
          type: getTransactionType(data?.type) as string,
        }}
        onClose={onClose}
        footer={(transactionData) => {
          return (
            !hideButton &&
            transactionData.type !== 'other' && (
              <Modal.Footer>
                <Block gap="md">
                  <TransactionButtons data={transactionData} onCancel={onClose} />
                  {isOtcTransaction && (
                    <Link to="/otc" onClick={onClose}>
                      <Button justify="center" stretch variant="outline" size="lg">
                        <Span color="primary-500">{t('otc.review-the-offer')}</Span>
                      </Button>
                    </Link>
                  )}
                </Block>
              </Modal.Footer>
            )
          );
        }}
        empty={<Empty my="xl" message={t('common.empty-message')} />}
      />
    </>
  );
};

export default TransactionNotificationContent;

import { ForwardRefExoticComponent } from 'react';

import { default as FormattedFancyInputNumber, FormattedFancyInputNumberProps } from './formattedFancyNumber';
import { default as FormattedInputNumber, FormattedInputNumberProps } from './formattedNumber';
import { InputProps, default as InputText } from './input';
import { default as InputSearch, InputSearchProps } from './inputSearch';
import { default as LegacyInputNumber } from './legacyNumber';
import { default as InputNumber, InputNumberProps } from './number';
import { default as InputPassword, PasswordProps } from './password';

export { default as Textarea } from './textarea';

export { default as InputCode } from './inputCode';
export type { InputCodeProps, InputCodeRef } from './inputCode';

interface InputCompound extends ForwardRefExoticComponent<InputProps> {
  Password: React.ComponentType<PasswordProps>;
  Number: React.ComponentType<InputNumberProps>;
  FormattedNumber: React.ComponentType<FormattedInputNumberProps>;
  FormattedFancyNumber: React.ComponentType<FormattedFancyInputNumberProps>;
  Search: React.ComponentType<InputSearchProps>;
}

const Input = Object.assign(InputText, {
  Password: InputPassword,
  Number: InputNumber,
  FormattedNumber: FormattedInputNumber,
  FormattedFancyNumber: FormattedFancyInputNumber,
  LegacyNumber: LegacyInputNumber,
  Search: InputSearch,
}) as InputCompound;

export { Input };

import { useLocation, useNavigate } from 'react-router-dom';

type TabProps = {
  /** Hash associated with the tab */
  hash: string;
  /** Index of the tab */
  index: number;
}[];

/**
 * Hook for syncing route with location hash
 *
 * @param {TabProps[]} tabs - An array of tab configurations.
 * @param {string} [defaultHash='#'] - The default hash to use if no match is found.
 * @param {number} [defaultIndex=0] - The default index to use if no match is found.
 * @returns {{ activeTab: number, onTabChange: (index: number) => void }} - An object containing the active tab index and a function to handle tab changes.
 *
 * @example
 * const tabs = [
 *   { hash: '#tab1', index: 0 },
 *   { hash: '#tab2', index: 1 },
 * ];
 * const defaultHash = '#tab1';
 * const defaultIndex = 0;
 * const { activeTab, onTabChange } = useTabRouter(tabs, defaultHash, defaultIndex);
 *
 */
export const useTabRouter = (tabs: TabProps, defaultHash = '#', defaultIndex = 0) => {
  const param = useLocation();
  const navigate = useNavigate();

  const onTabChange = (index: number) => {
    const hash = tabs.find((item) => item.index === index)?.hash || defaultHash;
    navigate(hash);
  };

  const activeTab: number = tabs.find((item) => item.hash === param.hash)?.index || defaultIndex;

  return { activeTab, onTabChange };
};

import React from 'react';

import { Text } from '@bilira-org/design';

type Props = { children: React.ReactNode };

const HeaderText = ({ children }: Props) => {
  return (
    <Text size="xs" weight="medium" color="neutral-600" wordBreak="none">
      {children}
    </Text>
  );
};

export default HeaderText;

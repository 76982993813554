import React, { ReactElement } from 'react';

import Panel, { PanelProps } from './panel';
import { useCopy } from '../../hooks';
import { Block } from '../block';
import { Icon } from '../icons';
import { IconProps } from '../icons/icon';
import { BaseComponentType } from '../types';
import { Text, TextProps } from '../typography';

export type PanelCopyProps = BaseComponentType & {
  /** A callback function to be executed after the copy action is performed. */
  callback?: () => void;
  /** The content to be displayed within the panel. */
  children?: string;
  /** Extra content to be displayed within the panel. */
  extra?: ReactElement;
  /** The text to be displayed when the content is successfully copied. */
  copiedText?: string;
  /** The notification message to be displayed when the content is successfully copied. */
  copiedNotification?: string;
  /** The value to be copied when the user clicks on the content. */
  copyValue?: string;
  /** Props for customizing the appearance of the underlying Panel component. */
  panelProps?: Partial<PanelProps>;
  /**
   * Props for customizing the appearance of the underlying Text component. */
  textProps?: Partial<Omit<TextProps, 'onClick'>>;
  /** Props for customizing the appearance of the underlying Icon component. */
  iconProps?: Partial<Omit<IconProps, 'onClick' | 'ref' | 'overlayColor'>>;
};

/**
 * A panel component with built-in copy functionality.
 * @example
 * <PanelCopy copyValue="Hello world, please click to copy" copiedText="Copied!">
 *   <Text>Hello world, please click to copy</Text>
 * </PanelCopy>
 */
const PanelCopy: React.FC<PanelCopyProps> = ({
  extra,
  children = '',
  callback,
  copiedText,
  copiedNotification,
  copyValue,
  panelProps,
  testId,
  textProps,
  iconProps,
}: PanelCopyProps) => {
  const { isCopied, onCopy } = useCopy({ callback, copiedText, copiedNotification, copyValue: copyValue || children });

  const { py = 'lg', px = 'md', border = 'neutral-400', radius = 'lg', ...panelRest } = panelProps || {};

  return (
    <Panel py={py} px={px} border={border} radius={radius} testId={testId} {...panelRest}>
      <Block row justify="between">
        <Text color="secondary-500" size="sm" weight="semibold" wordBreak="all" onClick={() => onCopy()} {...textProps}>
          {children}
        </Text>
        <Block row gap="md" items="center">
          <Icon
            size="lg"
            type="o:square-2-stack"
            onClick={() => onCopy()}
            {...iconProps}
            color={isCopied ? 'green-500' : iconProps?.color || 'neutral-600'}
          />
          {extra}
        </Block>
      </Block>
    </Panel>
  );
};

export default PanelCopy;

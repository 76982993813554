import { Form, notification } from '@bilira-org/design';
import { httpError } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import AccountApi from '@Libs/clientInstances/accountQuery';
import useAuth from '@Libs/hooks/userAuth';
import useTwoFA from '@Libs/hooks/useTwoFA';
import { TRACKER_EVENTS } from '@Libs/tracker/events';
import { addTrackerTwoFa } from '@Libs/tracker/helpers/addTrackerAccount';
import { handleErrorResponse } from '@Libs/utils';
import { useSessionStore } from '@Store/sessionStore';

export interface IAmount {
  amount: string;
}

type Props = {
  bankAccount?: string;
};

const useWithdrawForm = ({ bankAccount }: Props) => {
  const { t, i18n } = useTranslation();
  const { account } = useAuth();
  const post = AccountApi.usePostFiatWithdrawal();
  const form = Form.useForm<IAmount>({
    mode: 'onChange', // to validate on field change
  });

  /** Update error messages on form */
  i18n.on('languageChanged', () => {
    Form.triggerFormErrors(form);
  });

  const { twoFAData, activeTwoFAModal, setActiveTwoFAModal, handleVerifyTwoFA, handleSave } =
    useTwoFA<IAmount>('fiatWithdrawal');

  const onFinish = (payload: IAmount, token?: string) => {
    const loadingNotificationId = notification.loading(t('fiat.withdrawal-being-processed'));

    post
      .mutateAsync({
        account: account,
        bank_account: bankAccount || '',
        amount: payload.amount.toString(),
        two_fa_token: token,
      })
      .then(() => {
        addTrackerTwoFa(TRACKER_EVENTS.FIAT_WITHDRAWAL.REQUEST_WITHDRAWAL, payload);
        useSessionStore.getState().setIsNotificationViewed(false);
        notification.dismiss(loadingNotificationId);
        notification.success(t('fiat.withdrawal-successful'));
      })
      .catch((e) => {
        notification.dismiss(loadingNotificationId);

        if (httpError(e).is429) {
          notification.error(t('common.too-many-attempts'));
        } else {
          handleErrorResponse(e, t('fiat.withdrawal-failed'));
        }
      })
      .finally(() => {
        form.reset({ amount: '' });
      });
  };

  const onSubmit = (payload: IAmount) => handleSave(onFinish, payload);
  const onVerifyTwoFA = (values: string) => handleVerifyTwoFA(onFinish, values);

  return {
    isPending: post.isPending,
    onSubmit,
    error: post.error,
    form,
    twoFAData,
    activeTwoFAModal,
    setActiveTwoFAModal,
    onVerifyTwoFA,
  };
};

export default useWithdrawForm;

import { detectBrowser } from '@bilira-org/react-utils';

import { env } from '@Libs/constants/env';

const os = detectBrowser();

/**
 * Function to get the store URL
 *
 * @returns {string} The store url
 */
const getStoreUrl = (): string => {
  if (os.isIOS) {
    return env.APP_IOS_URL;
  }

  if (os.isAndroid) {
    return env.APP_ANDROID_URL;
  }

  if (os.isHarmony) {
    return env.APP_HUAWEI_URL;
  }

  return env.STABLECOIN_WEB_URL;
};

export default getStoreUrl;

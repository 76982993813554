import { IconType } from '@bilira-org/design';
import { RewardsTasksType, UserParticipationType } from '@bilira-org/react-utils';

export function getTaskIcon(task: UserParticipationType | RewardsTasksType): IconType {
  switch (task.action_type) {
    case 'fiat_deposit':
    case 'crypto_deposit':
    case 'fiat_or_crypto_deposit':
      return 'o:arrow-down-tray';
    case 'fiat_withdrawal':
    case 'crypto_withdrawal':
      return 'o:arrow-up-tray';
    case 'swap':
      return 'o:arrows-right-left';
    case 'otc':
      return 'o:hand-coins';
    case 'kyc1':
      return 'o:user';
    case 'kyc2':
    case 'kyc3':
      return 'o:identification';
    case 'referral':
      return 'o:user-plus';
    case 'crypto':
      return 'o:convert';
    case 'fiat':
      return 'o:banknotes';
    default:
      return 'o:question-mark-circle';
  }
}

import React, { useCallback, useState } from 'react';

import { Block, Flex, Icon, Input, Panel, Separator } from '@bilira-org/design';
import { CryptoAssetsType, filterExpression, useDeferredFilter } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { handleSaveToLocalStore } from '@Components/globalSearch/components/helpers/handleSaveToLocalStore';
import LastSearchList from '@Components/globalSearch/components/LastSearchList';
import PopularList from '@Components/globalSearch/components/PopularList';
import SearchResultList from '@Components/globalSearch/components/SearchResultList';
import { StoredAssetType } from '@Components/globalSearch/types';
import CryptoQuery from '@Libs/clientInstances/cryptoQuery';
import useNavigateToMarket from '@Libs/hooks/useNavigateToMarket';

type Props = {
  /** Callback to be called when  */
  callback: () => void;
};

/**
 * Component for searching cryptos, listing of popular and recently searched cryptos
 * Navigates to asset detail page when a crypto selected
 */
const GlobalSearch = ({ callback }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigateToMarket();
  const [initialLoad, setInitialLoad] = useState<boolean>(false);

  const { data: assetList, isPending } = CryptoQuery.useGetAssets();

  const predicate = useCallback(
    (item: CryptoAssetsType, search: string) => filterExpression<CryptoAssetsType>(['name', 'symbol'])(item, search),
    [],
  );

  const {
    searchText,
    onSearch: setSearchText,
    filteredData,
  } = useDeferredFilter<CryptoAssetsType>({
    predicate: predicate,
    data: assetList || ([] as CryptoAssetsType[]),
  });

  const onSelected = useCallback((item: StoredAssetType) => {
    navigate({ base: item.symbol });
    handleSaveToLocalStore(item);

    setInitialLoad(false);
    callback();
  }, []);

  const onSearch = (value: string) => {
    setInitialLoad(!!value);
    setSearchText(value);
  };

  return (
    <>
      <Panel size="none" px="xs" py="xs">
        <Input.Search
          size="lg"
          variant="ghost"
          iconStart={<Icon size="md" type="o:magnifying-glass" />}
          value={searchText}
          placeholder={t('common.do-search')}
          onChange={(val) => onSearch(val.target.value)}
          iconEnd={
            /** offset for modal's absolute positioned close icon */
            <Flex px="2xl">
              <wbr />
            </Flex>
          }
        />
      </Panel>

      <Separator width="size-full" mb="sm" />
      <Panel size="lg" minHeight="sm">
        {initialLoad && (
          <SearchResultList isPending={isPending} data={filteredData} onSelected={onSelected} searchText={searchText} />
        )}
        {!initialLoad && (
          <>
            <Block mb="2xl">
              <PopularList onSelected={onSelected} />
            </Block>
            <LastSearchList onSelected={onSelected} />
          </>
        )}
      </Panel>
    </>
  );
};

export default GlobalSearch;

import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import { env } from '@Libs/constants/env';

import packageJson from '../../package.json';

console.debug('BILIRA_ENV', env.BILIRA_ENV);

const conf = {
  allowUrls: [/(https:\/\/([a-z-]+)\.bilira\.(co|dev))((\/.+)|\/)?/],
  disallowUrls: [/^.*\.(hubspot|hubapi|hscollectedforms|mixpanel|google-analytics)\.(com|net)(\/.*$)?/],
};

Sentry.init({
  enabled: !env.IS_DEVELOPMENT,
  environment: env.BILIRA_ENV,
  dsn: env.SENTRY_DSN,
  release: packageJson.version,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.reportingObserverIntegration({ types: ['crash'] }),
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.extraErrorDataIntegration(),
    Sentry.captureConsoleIntegration({ levels: ['error'] }),
    Sentry.replayIntegration({
      networkDetailAllowUrls: conf.allowUrls,
      networkDetailDenyUrls: conf.disallowUrls,
      networkRequestHeaders: ['Content-Language', 'Date'],
      maskAllText: true,
      blockAllMedia: true,
      maskAllInputs: false,
    }),
    Sentry.httpClientIntegration({
      failedRequestStatusCodes: [429, 404, [500, 599]],
      failedRequestTargets: conf.allowUrls,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: env.IS_PROD ? 0.3 : 1.0,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: conf.allowUrls,
  // A pattern for error URLs which should not be sent to Sentry.
  denyUrls: conf.disallowUrls,
  // Session Replay
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: env.IS_PROD ? 0.3 : 1.0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  // Will be implemented later in profiling integration
  profilesSampleRate: env.IS_PROD ? 0.3 : 1.0,
  // Attaches stacktraces to pure capture message
  attachStacktrace: true,
});

import { TransactionsRecordType } from '@bilira-org/react-utils';

export const getTransactionSuffix = (record: TransactionsRecordType) => {
  switch (`${record.type}:${record.direction}`) {
    case 'fiat:deposit':
      return 'TL';
    case 'fiat:withdrawal':
      return 'TL';
    case 'crypto:deposit':
      return record.asset;
    case 'crypto:withdrawal':
      return record.asset;
    case 'swap:swap':
      return record.to_asset;
    case 'conversion:conversion':
      return record.to_asset;
    case 'otc:swap':
      return record.to_asset;
    case 'other:promo':
      return record.asset;
    case 'other:payback':
      return record.asset;
    case 'other:other':
      return record.asset;
    default:
      return '';
  }
};

import React, { useEffect } from 'react';

import { LegalDocumentConsentType, LegalDocumentType } from '@bilira-org/react-utils';

import useGlobalModalQueue from '@/libs/hooks/useGlobalModalQueue';
import { handleErrorResponse } from '@/libs/utils';
import { useGeneralStore } from '@/store/generalStore';
import UserQuery from '@Libs/clientInstances/userQuery';

export type ActiveConsentType = Partial<LegalDocumentConsentType> & {
  contents?: LegalDocumentType['contents'];
  option_contents?: LegalDocumentType['option_contents'];
  options?: LegalDocumentType['options'];
  defaultConsentValues?: Map<string, boolean>;
};

/**
 * Hook for managing legal document consents and related data.
 */
const useLegalDocuments = () => {
  const { data: legalDocumentConsents, isPending: isLegalDocumentConsentsPending } =
    UserQuery.useGetLegalDocumentConsents();
  const { mutateAsync: putLegalDocumentConsents, isPending } = UserQuery.usePutLegalDocumentConsents();
  const invalidConsents = legalDocumentConsents?.filter((consent) => !consent.last_consent_valid) || [];

  const { data: legalDocuments } = UserQuery.useGetLegalDocuments(undefined, { enabled: invalidConsents.length > 0 });

  /** Aggregated invalid consents with content and value */
  const invalidConsentsWithContent: ActiveConsentType[] = invalidConsents.map((consent) => {
    const legalDocument = legalDocuments?.find((document) => document.type === consent.document_type);

    let defaultConsentValues: Map<string, boolean> = new Map();
    if (consent?.consents) {
      defaultConsentValues = new Map(
        consent.consents.map((option) => [option.key, option.required ? true : option.consent || false]),
      );
    }

    return {
      ...consent,
      contents: legalDocument?.contents,
      option_contents: legalDocument?.option_contents,
      options: legalDocument?.options,
      defaultConsentValues,
    };
  });

  /**
   * Update legal document consents then opens up next legal document
   * @param {string} type - Type of legal document (`cp`, `tac`)
   * @param {string[]} value  - Value of options
   * @returns
   */
  const updateLegalDocumentConsent = async (type: string, value: string[]) => {
    return putLegalDocumentConsents({ [type]: value })
      .then(() => setLegalDocuments({ activeConsentIndex: activeConsentIndex + 1 }))
      .catch(handleErrorResponse);
  };

  const {
    legalDocuments: { activeConsentIndex },
    setLegalDocuments,
  } = useGeneralStore();

  const { isOpen, enqueue, dequeue } = useGlobalModalQueue('LegalDocumentsModal');

  const hasNoInvalidConsents = invalidConsents.length === 0;
  const activeConsent = invalidConsentsWithContent[activeConsentIndex] as
    | undefined
    | (typeof invalidConsentsWithContent)[number];
  const isCookieConsent = isOpen && activeConsent?.document_type === 'cp';
  const isLegalDocumentModalOpen = isOpen && !isCookieConsent;

  useEffect(() => {
    if (!isLegalDocumentConsentsPending) {
      if (activeConsentIndex < invalidConsents.length) {
        enqueue();
      } else {
        dequeue();
      }
    }
  }, [activeConsentIndex, dequeue, enqueue, invalidConsents.length, isLegalDocumentConsentsPending]);

  useEffect(() => {
    setLegalDocuments({ invalidConsentsLength: invalidConsents.length });
  }, [invalidConsents.length]);

  return {
    updateLegalDocumentConsent,
    hasNoInvalidConsents,
    isCookieConsent,
    activeConsent,
    isLegalDocumentModalOpen,
    isPending,
  };
};

export default useLegalDocuments;

import { useEffect, useState } from 'react';

import { SystemBankAccountModel } from '@bilira-org/react-utils/src/model/SystemBankAccount';

import FiatQuery from '@Libs/clientInstances/fiatQuery';

export const useActiveSystemBankPanel = () => {
  const [modalActive, setModalActive] = useState(false);
  const [selectedSystemBank, setSelectedSystemBank] = useState<SystemBankAccountModel | null>(null);

  return {
    modalActive,

    setModalActive,

    selectedSystemBank,
    setSelectedSystemBank,
  };
};

import React, { useState } from 'react';

import { Button, Display, Flex, Icon, notification } from '@bilira-org/design';
import { CryptoWithdrawalType, useTicker } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import CryptoQuery from '@Libs/clientInstances/cryptoQuery';
import FiatQuery from '@Libs/clientInstances/fiatQuery';
import { calculateTimeDifference, handleErrorResponse } from '@Libs/utils';

import { calculateRemainingTime } from './helpers';

type Props = {
  data?: CryptoWithdrawalType;
  onCancel?: () => void;
};

function TransactionButtons({ data, onCancel }: Props) {
  const { t } = useTranslation();

  const currentTimeDiff = calculateTimeDifference({ endTime: data?.created_at as string, unit: 'millisecond' });
  const remainingTime = calculateRemainingTime(currentTimeDiff);

  const [isTxInCancellableDuration, setIsTxInCancellableDuration] = useState(currentTimeDiff < 60000);

  const cancelCrypto = CryptoQuery.useDeleteWithdrawals();
  const cancelFiat = FiatQuery.useDeleteWithdrawals();

  useTicker({
    duration: remainingTime,
    enabled: remainingTime > 0,
    onComplete: () => setIsTxInCancellableDuration(false),
    updateInterval: remainingTime,
  });

  if (!data) {
    return null;
  }

  const cancelRequest = () => {
    const mutate = data.type === 'fiat' ? cancelFiat.mutateAsync : cancelCrypto.mutateAsync;
    // TODO: can cancel only pending ones.

    mutate(data.id)
      .then(() => {
        notification.success(t('fiat.withdrawal-canceled'));
        onCancel?.();
      })
      .catch(handleErrorResponse);
  };

  const showCancelButton =
    (data.status === 'requested' && isTxInCancellableDuration) || data.status === 'pending_approval';
  const showExplorerButton = data.type === 'crypto' && data.transaction_url;

  return (
    <Display show={showCancelButton || showExplorerButton}>
      <Flex direction={{ xs: 'col', sm: 'row' }} gap="lg">
        <Display show={showCancelButton}>
          <Flex.Item flexType="1">
            <Button size="xl" variant="outline" stretch onClick={cancelRequest}>
              {t('common.cancel')}
            </Button>
          </Flex.Item>
        </Display>
        <Display show={showExplorerButton}>
          <Flex.Item flexType="1">
            <Button
              size="xl"
              variant="filled"
              stretch
              startIcon={<Icon type="o:arrow-top-right-on-square" />}
              link={{ href: data.transaction_url, target: '_blank', rel: 'noreferrer' }}
            >
              {t('transactions.view-in-explorer')}
            </Button>
          </Flex.Item>
        </Display>
      </Flex>
    </Display>
  );
}

export default TransactionButtons;

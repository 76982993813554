import { CookieAttributes, ICookie } from './types';

/**
 * Cookie class for handling cookies with JSON serialization.
 */
export class Cookie<T> implements ICookie<T> {
  /** Internal instance of cookie interface */
  _cookie: ICookie<T>;

  /**
   * Creates an instance of the Cookie class.
   */
  constructor(cookie: ICookie<T>) {
    this._cookie = cookie;
  }

  /**
   * Gets the value of the cookie with the specified name.
   *
   * @param {string} name - The name of the cookie.
   * @returns {T | undefined} - The deserialized value of the cookie or undefined if not found or deserialization fails.
   */
  public get<T>(name: string): T | undefined {
    try {
      const serializedState = this._cookie.get(name);
      if (!serializedState) {
        return undefined;
      }
      return JSON.parse(serializedState) as T;
    } catch (err) {
      return undefined;
    }
  }

  /**
   * Removes the cookie with the specified name.
   *
   * @param {string} name - The name of the cookie to be removed.
   * @param {CookieAttributes | undefined} attributes - Additional attributes for removing the cookie.
   */
  public remove(name: string, attributes: CookieAttributes | undefined): void {
    this._cookie.remove(name, attributes);
  }

  /**
   * Sets the cookie with the specified name and value.
   *
   * @param {string} name - The name of the cookie.
   * @param {string | T} value - The value of the cookie, can be a string or an object.
   * @param {CookieAttributes | undefined} [attributes] - Additional attributes for setting the cookie.
   * @returns {string | undefined} - The name of the cookie if successfully set, otherwise undefined.
   */
  public set(name: string, value: string | T, attributes?: CookieAttributes | undefined): string | undefined {
    try {
      this._cookie.set(name, JSON.stringify(value), attributes ?? { expires: 1, secure: true });
      return name;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }
}

import { compareNumbers } from './compareNumbers';
import { compareStrings } from './compareStrings';
import { sortListBy } from './sortListBy';

/** Enum for sort orders */
export const SortOrder = {
  /** Ascending sort order */
  ASC: 'asc',
  /** Descending sort order */
  DESC: 'desc',
  /** Unsorted order */
  UNSORTED: undefined,
} as const;
export type SortOrderType = (typeof SortOrder)[keyof typeof SortOrder];

export { compareNumbers, compareStrings, sortListBy };

import React, { ButtonHTMLAttributes, forwardRef, useEffect, useState } from 'react';

import { Switch as Toggle } from '@headlessui/react';
import { clsx } from 'clsx';

import { BackgroundColorType, BaseComponentType, BaseSize } from '../types';

export type ToggleProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'size'> &
  BaseComponentType & {
    /** Additional class names for styling. */
    className?: string;
    /** @deprecated Currently not used */
    color?: BackgroundColorType;
    /** @deprecated Currently not used */
    size?: BaseSize;
    /** Callback function triggered when the switch is toggled */
    onSwitch?: (state: boolean) => void;
    /** Whether switch is checked or not */
    checked?: boolean;
    /** Whether switch is disabled */
    disabled?: boolean;
  };

/**
 * Switch component for toggling between two states.
 *
 * @example
 * <Switch checked={isChecked} onSwitch={handleCheck} />
 */
const Switch = forwardRef<HTMLButtonElement, ToggleProps>(
  ({ color, className, disabled, testId, ...props }, ref): JSX.Element => {
    const [state, setState] = useState<boolean>(!!props.checked);

    const setChecked = (val: boolean) => {
      setState(val);
      props.onSwitch?.(val);
    };

    useEffect(() => {
      setState(!!props.checked);
    }, [props.checked]);

    return (
      <Toggle
        ref={ref}
        checked={state}
        onChange={setChecked}
        className={clsx('w-9 py-0.5 relative transform ease-in-out duration-200 inline-flex items-center rounded-xl', {
          'tw-bg-primary-500 disabled:tw-bg-primary-100': state,
          'tw-bg-neutral-400': !state,
        })}
        data-testid={testId ?? 'switch'}
        disabled={disabled}
      >
        <span
          className={clsx('w-4 h-4 inline-block transform ease-in-out duration-200 rounded-full', {
            'tw-bg-neutral-200 disabled:tw-bg-primary-50': !state,
            'translate-x-[0.14rem]': !state,
            'tw-bg-theme-wn': state,
            'translate-x-[1.1rem]': state,
          })}
        />
      </Toggle>
    );
  },
);

Switch.displayName = 'Switch';

export default Switch;

import React from 'react';

import { Accordion, Block, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

const FAQ = () => {
  const { t } = useTranslation();
  const faqs = t('rewards.faqs', { returnObjects: true }) as { q: string; a: string }[];
  return (
    <Accordion variant="filled-theme" gap="md">
      {faqs.map((task) => (
        <React.Fragment key={task.q}>
          <Accordion.Item
            accordionKey={task.q}
            label={
              <Block items="start">
                <Text heading size="base" weight="semibold">
                  {task.q}
                </Text>
              </Block>
            }
          >
            <Text mt="md">{task.a}</Text>
          </Accordion.Item>
        </React.Fragment>
      ))}
    </Accordion>
  );
};

export default FAQ;

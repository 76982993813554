import { LocalStore } from '@bilira-org/design';

import { StoredAssetType } from '@Components/globalSearch/types';

/** Saves recent search item to local storage */
export const handleSaveToLocalStore = (item: StoredAssetType) => {
  let recentData = LocalStore.get('recent-search') ?? [];

  recentData = recentData.filter((d: StoredAssetType) => d.symbol !== item.symbol);
  recentData.unshift(item);
  LocalStore.set<StoredAssetType[]>('recent-search', recentData);
};

/** Get recently searched items from local storage */
export const getSavedRecent = () => {
  return LocalStore.get('recent-search');
};

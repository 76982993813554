import React from 'react';

import { Badge, Block, Display, Icon, Meta, Panel, Text, Tooltip } from '@bilira-org/design';
import { AssetPairPriceType, ForceDecimal, formatPriceValue, SwapTransactionType } from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';

import useFormatNumberByLivePrice from '@/libs/hooks/useFormatNumberByLivePrice';
import AssetUnitPriceInfo from '@Components/trade/components/AssetUnitPriceInfo';

import SwapInfo from '../SwapInfo';

export type Props = {
  data: SwapTransactionType;
};

export const SwapTransferType = ({ data }: Props) => {
  const { t, i18n } = useTranslation();
  const formatNumberByLivePrice = useFormatNumberByLivePrice({ base: data.commission_asset, quote: data.to_asset });
  const price = data.side === 'buy' ? data.offer_price : BigNumber(1).div(data.offer_price).toString();

  return (
    <Meta
      title={<Text size="sm">{t('market.swap.buy-amount')}</Text>}
      description={
        <Panel size="none">
          <SwapInfo
            fromAmount={data.executed_quantity}
            fromAsset={data.from_asset}
            toAmount={data.executed_amount}
            toAsset={data.to_asset}
            pairPrice={price}
            side={data.side}
          />
          <Block gap="md" items="start" mt="lg">
            <Display show={data.executed_amount}>
              <AssetUnitPriceInfo
                disableTooltip
                weight="regular"
                justify="end"
                iconType="o:exclaimation-circle"
                assetPairPrice={
                  {
                    price: data.execution_price,
                    quote_asset: data.from_asset,
                    base_asset: data.to_asset,
                  } as AssetPairPriceType
                }
              />
            </Display>

            <Badge size="md" variant="square" bordered transparent>
              <Block row gap="xs" items="center">
                <Text size="sm" color="neutral-700">
                  {data.commission && data.commission !== '0'
                    ? `${t('market.swap.commission')} : ${
                        formatNumberByLivePrice({
                          value: data.commission,
                          type: 'amount',
                        }).value
                      } ${data.commission_asset}`
                    : t('common.no-commission')}
                </Text>
                {/* TODO: Commission_rate will be added on next sprint 12.02.2024 */}
                {data?.commission_rate && (
                  <Tooltip
                    width="size-max"
                    placement="top"
                    message={t('market.swap.commission-rate', {
                      rate: formatPriceValue({
                        value: (parseFloat(data.commission_rate) * 100).toString(),
                        locale: i18n.language,
                        forceDecimal: ForceDecimal.SHOW_INSIGNIFICANT_DIGITS,
                        decimal: 2,
                        precision: undefined,
                      }),
                    })}
                    position="right"
                  >
                    <Icon color="neutral-700" type="o:question-mark-circle" size="sm" />
                  </Tooltip>
                )}
              </Block>
            </Badge>
          </Block>
        </Panel>
      }
    />
  );
};

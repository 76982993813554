import { useCallback, useEffect, useRef, useState } from 'react';

import { useIsomorphicEffect } from './useStateEffect';

type MayBe<T> = T | null;

export interface ReturnValue {
  /** Current elapsed time in milliseconds */
  time: number;
  /** Reset method to reset the elapsed time and start over. startAt value can be changed by passing newStartAt */
  reset: (immediateStart?: boolean) => void;
  /** Method to pause and continue the ticker.*/
  pause: (pause: boolean) => void;
}

export interface TickerProps {
  /** The total duration of the ticker in milliseconds. */
  duration: number;
  /** Flag indicating whether the ticker is initially enabled. */
  enabled?: boolean;
  /** The interval in milliseconds at which the ticker updates. */
  updateInterval?: number;
  /** Callback function triggered when the ticker completes. */
  onComplete?: () => void;
  /** The initial elapsed time for the ticker. */
  startAt?: number;
  /** Callback function to be executed on each countdown tick. */
  tick?: (time: number) => void;
}

/**
 * Hook for creating a ticker that counts down from a specified duration.
 *
 * @param {TickerProps} props - The properties for configuring the ticker.
 * @returns {ReturnValue} - An object containing the current elapsed time, reset method, and pause method.
 */
const useTicker = ({
  duration,
  enabled = true,
  onComplete,
  updateInterval = 1000,
  startAt = 0,
  tick,
}: TickerProps): ReturnValue => {
  const requestRef = useRef<number>();
  const previousTimeRef = useRef<MayBe<number>>(null);
  const completedRef = useRef<boolean>(false);
  const pausedRef = useRef<boolean>(false);
  const repeatRef = useRef<number>(startAt);
  const elapsedTimeRef = useRef<number>(startAt);
  const [timeLeft, setTimeLeft] = useState(duration);

  const onCompleteRef = useRef(onComplete);

  useEffect(() => {
    onCompleteRef.current = onComplete;
  }, [onComplete]);

  const updateTimeLeft = (value: number) => {
    tick?.(value);
    setTimeLeft(value);
  };

  const animate = useCallback(() => {
    const currentTime = new Date().getTime();
    const deltaTime = currentTime - (previousTimeRef.current || currentTime);
    previousTimeRef.current = currentTime;

    elapsedTimeRef.current += deltaTime;
    repeatRef.current += deltaTime;

    completedRef.current = elapsedTimeRef.current >= duration;
    if (repeatRef.current >= updateInterval) {
      repeatRef.current -= updateInterval;

      const currentDisplayTime =
        updateInterval === 0
          ? elapsedTimeRef.current
          : Math.floor(elapsedTimeRef.current / updateInterval) * updateInterval;

      updateTimeLeft(duration - (completedRef.current ? duration : currentDisplayTime));
    }

    if (completedRef.current) {
      onCompleteRef.current?.();
    }

    if (!completedRef.current && !pausedRef.current) {
      requestRef.current = requestAnimationFrame(animate);
    }
  }, []);

  const cleanup = () => {
    requestRef.current && cancelAnimationFrame(requestRef.current);
    completedRef.current = true;
  };

  const reset = useCallback(
    (immediateStart = false) => {
      cleanup();
      pausedRef.current = false;
      completedRef.current = false;
      elapsedTimeRef.current = startAt;
      previousTimeRef.current = new Date().getTime();

      updateTimeLeft(duration);

      if (immediateStart) {
        requestRef.current = requestAnimationFrame(animate);
      }
    },
    [animate, duration],
  );

  const pause = useCallback(
    (pause: boolean) => {
      pausedRef.current = pause;
      if (!pause) {
        /** start ticker again */
        requestRef.current = requestAnimationFrame(animate);
        previousTimeRef.current = new Date().getTime();
      }
    },
    [animate],
  );

  useIsomorphicEffect(() => {
    if (enabled) {
      completedRef.current = false;
      previousTimeRef.current = new Date().getTime();
      requestRef.current = requestAnimationFrame(animate);
    }

    return cleanup;
  }, [animate, enabled]);

  return { time: timeLeft, reset, pause };
};

export default useTicker;

import { useState } from 'react';

import Copy from 'clipboard-copy';

import { notification } from '../components/notification';

interface Props {
  /** The callback function to be executed after copying */
  callback?: () => void;
  /** The text to be displayed in the copied notification. */
  copiedText?: string;
  /**  The custom notification message for successful copy. */
  copiedNotification?: string;
  /** The value to be copied.  */
  copyValue?: string;
}

/**
 * Hook for handling copy functionality.
 *
 * @param {Props} props - The properties for the copy hook.
 * @returns {{ isCopied: boolean, onCopy: (options?: Props) => void }} - An object containing the copy state and copy function.
 */
const useCopy = (props: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  const onCopy = ({
    callback = props.callback,
    copiedText = props.copiedText,
    copiedNotification = props.copiedNotification,
    copyValue = props.copyValue,
  }: Props = {}) => {
    if (typeof copyValue !== 'string') {
      return;
    }

    Copy(copyValue).then(() => {
      callback?.();
      setIsCopied(true);
      notification.success(copiedNotification || `${copiedText}: "${copyValue}"`, { duration: 900 });
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    });
  };

  return { isCopied, onCopy };
};

export default useCopy;

import React from 'react';

import { Block, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

function SuccessContent() {
  const { t } = useTranslation();

  return (
    <>
      <Block gap="md" items="center" maxWidth="sm" align="center">
        <Text heading size="xl" weight="bold" align="center">
          {t('otc.success-modal.title')}
        </Text>

        <Text> {t('otc.success-modal.description')}</Text>
      </Block>
    </>
  );
}

export default SuccessContent;

import { create } from 'zustand';

interface StoreProps {
  selectedItem: string | null;
  setSelectedItem: (selectedIndex: string | null) => void;
}

export const useStore = create<StoreProps>((set) => ({
  selectedItem: null,
  setSelectedItem: (selectedItem: string | null) => set(() => ({ selectedItem })),
}));

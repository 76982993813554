import React from 'react';

import { Display, Flex, Number, Span, Text } from '@bilira-org/design';
import Progress from '@bilira-org/design/src/components/progress/progress';
import { useTranslation } from 'react-i18next';

import FormattedNumber from '../common/FormattedNumber';

type Props = {
  /** Percentage of used allowance */
  percentage: number;
  /** Allowed limit */
  allowed: string;
  /** Used allowance limit */
  used: string;
  /** Unit of limit */
  asset: string;
  /** Period of the allowance. Eg. ("monthly", "daily") */
  period: string;
  /** Whether allowance is unlimited */
  unlimited?: boolean;
};

/**
 * Component to display allowance of the user in an asset
 * Also displays percentage of used allowance in a progress bar
 *
 * @example
 * <AllowanceWidget percentage={40} asset="TRYB" allowed="100" used="40" />
 */
const AllowanceWidget = ({ asset, allowed, used, percentage, period, unlimited }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Flex gap="sm" direction={{ xs: 'col', sm: 'row' }} justify={{ sm: 'between' }} items={{ sm: 'center' }}>
        <Display show={unlimited}>
          <Text size="sm" weight="medium" color="secondary-500">
            {t('account.account-limits-modal.unlimited')} &nbsp;
            <Span size="sm" weight="medium" color="neutral-700">
              ({period})
            </Span>
          </Text>
        </Display>
        <Display show={!unlimited}>
          <Flex.Item flex flexType="1" direction="row" gap="xs">
            <FormattedNumber
              size="sm"
              weight="medium"
              color="primary-500"
              suffix={asset}
              value={used}
              formatPriceProps={{ precision: undefined }}
            />
            <Span size="sm" weight="medium" color="neutral-700">
              /
            </Span>
            <FormattedNumber
              size="sm"
              weight="medium"
              color="secondary-500"
              suffix={asset}
              value={allowed}
              formatPriceProps={{ precision: undefined }}
            />
            <Span size="sm" weight="medium" color="neutral-700">
              ({period})
            </Span>
          </Flex.Item>

          <Flex.Item flexType="none" width={{ xs: 'full', sm: '80' }}>
            <Progress progress={100 - percentage} size="size-1" color="primary-500" background="neutral-300" />
          </Flex.Item>
        </Display>
      </Flex>
    </>
  );
};

export default AllowanceWidget;

import { getI18n } from 'react-i18next';

/**
 * Retrieves the current language from the i18n library.
 *
 * @returns {string} - The current language code, or 'tr' if not available.
 *
 * @example
 * getI18nLanguage() // "tr"
 */
const getI18nLanguage = () => {
  return getI18n().language || 'tr';
};

export default getI18nLanguage;

import getRemainingTime from './getRemainingTime';
import { OTCStatusType, OTCType } from '../../model';

/**
 * Gets status of OTC offer.
 * @param {OTCType} offer - OTC offer.
 * @returns {OTCStatusType} - Status of the OTC offer.
 *
 * @example
 * getOfferStatus(expiredOTCOffer) // 'expired'
 * getOfferStatus(completedOTCOffer) // 'completed'
 */
const getOfferStatus = (offer: OTCType): OTCStatusType => {
  const remaining = getRemainingTime(offer);
  const offerInValid = remaining >= offer.offer_timeout_in_minutes && offer.status === 'pending';

  if (offerInValid) {
    return 'expired';
  }

  return offer.status;
};

export default getOfferStatus;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { HttpClientType, HttpErrorType } from '../../client';
import {
  Kyc2Body,
  Kyc2ResponsePayload,
  Kyc3Body,
  Kyc3ResponsePayload,
  OauthTokenResultType,
  OauthTokenType,
  PasswordBody,
  QueryOptions,
  ResultType,
  UserActivity,
  UserInfo,
  UserKyc,
} from '../../model';
import { IdpApi, IIdp } from '../api';
import { IdpQueryKey } from '../constants';

class IdpQuery {
  _api: IIdp;

  public constructor(params: HttpClientType) {
    this._api = new IdpApi(params);
  }

  public usePostKyc2 = (options?: QueryOptions) => {
    const queryClient = useQueryClient();

    return useMutation<Kyc2ResponsePayload, HttpErrorType, Kyc2Body>({
      mutationFn: this._api.postKyc2,
      onSuccess: () => queryClient.invalidateQueries({ queryKey: [IdpQueryKey.USER_KYC] }),
      ...options,
    });
  };

  public usePostKyc3 = (options?: QueryOptions) => {
    const queryClient = useQueryClient();

    return useMutation<Kyc3ResponsePayload, HttpErrorType, Kyc3Body>({
      mutationFn: this._api.postKyc3,
      onSuccess: () => queryClient.invalidateQueries({ queryKey: [IdpQueryKey.USER_KYC] }),
      ...options,
    });
  };

  public useGetUserKyc = (options?: QueryOptions) =>
    useQuery<UserKyc, HttpErrorType>({
      queryKey: [IdpQueryKey.USER_KYC],
      queryFn: this._api.getUserKyc,
      ...options,
    });
  public useGetOtp = (options?: QueryOptions) =>
    useQuery<UserKyc, HttpErrorType>({
      queryKey: [IdpQueryKey.ENABLE_OTP],
      queryFn: this._api.getOtp,
      ...options,
    });

  public useGetUserActivity = (options?: QueryOptions) =>
    useQuery<UserActivity[], HttpErrorType>({
      queryKey: [IdpQueryKey.USER_ACTIVITY],
      queryFn: this._api.getUserActivity,
      ...options,
    });

  public usePatchPassword = (options?: QueryOptions) => {
    return useMutation<ResultType, HttpErrorType, PasswordBody>({ mutationFn: this._api.patchPassword, ...options });
  };

  public useLogout = (options?: QueryOptions) => {
    return useMutation<ResultType, HttpErrorType>({ mutationFn: this._api.getLogout, ...options });
  };
  public useTokenRevoke = (options?: QueryOptions) => {
    return useMutation<ResultType, HttpErrorType>({ mutationFn: this._api.getTokenRevoke, ...options });
  };

  public usePostToken = (options?: QueryOptions) => {
    return useMutation<OauthTokenResultType, HttpErrorType, OauthTokenType>({
      mutationFn: this._api.postToken,
      ...options,
    });
  };

  public useGetHealth = (options?: QueryOptions) =>
    useQuery<UserKyc, HttpErrorType>({
      queryKey: [IdpQueryKey.HEALTH],
      queryFn: this._api.getHealth,
      ...options,
    });

  public useGetUserInfo = (options?: QueryOptions) =>
    useQuery<UserInfo, HttpErrorType>({
      queryKey: [IdpQueryKey.USER_INFO],
      queryFn: this._api.getUserInfo,
      ...options,
    });
}

export default IdpQuery;

import React, { CSSProperties, ReactNode } from 'react';

import { Skeleton } from '@bilira-org/design';
import {
  BreakpointType,
  FontWeight,
  TextAlign,
  TextColorType,
  TextSizes,
} from '@bilira-org/design/src/components/types';
import { concatTicker } from '@bilira-org/react-utils';

import useLastLivePrice from '@Libs/hooks/useLastLivePrice';

import FormattedNumberByPrice from '../common/FormattedNumberByPrice';

type Props = {
  base?: string;
  quote?: string;
  align?: TextAlign;
  weight?: FontWeight;
  color?: TextColorType;
  suffixColor?: TextColorType;
  multiplyBy?: string;
  size?: TextSizes | BreakpointType<TextSizes>;
  style?: CSSProperties | undefined;
  showSuffix?: boolean;
  skeleton?: ReactNode;
  suffixSize?: TextSizes | BreakpointType<TextSizes>;
};

const LastPriceLive = ({
  base,
  quote,
  align,
  weight = 'medium',
  color = 'secondary-500',
  suffixColor = 'secondary-500',
  style,
  size = { xs: 'sm', sm: 'base' },
  showSuffix = true,
  skeleton,
  suffixSize = 'xs',
}: Props) => {
  const { liveData } = useLastLivePrice({ symbol: concatTicker(base, quote) });

  if (!liveData) {
    if (skeleton) {
      return skeleton;
    }
    return <Skeleton key="price" height="size-4" width="size-24" mb="xs" />;
  }

  return (
    <FormattedNumberByPrice
      price={liveData?.p}
      animateChange
      color={color}
      suffixColor={suffixColor}
      weight={weight}
      align={align}
      value={liveData.p}
      type="price"
      size={size}
      style={style}
      {...(showSuffix ? { suffix: quote, suffixHeading: false, suffixSize: suffixSize } : undefined)}
    />
  );
};

export default LastPriceLive;

import React from 'react';

import { Block, Button, Drawer, List, Separator } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGeneralStore } from '@/store/generalStore';
import HeaderNavBar from '@Components/layouts/components/HeaderNavBar';
import HeaderNavBarSocial from '@Components/layouts/components/HeaderNavBarSocial';
import { withPublicRoute } from '@Libs/hocs';
import useLocaleRoute from '@Libs/hooks/useLocaleRoute';

import PreferencesContent from './components/PreferencesContent';

/**
 * Component for displaying a mobile menu drawer for anonymous users
 */
const PublicMobilMenuDrawer = () => {
  const { t } = useTranslation();
  const { publicDrawerOpen, setPublicDrawerOpen } = useGeneralStore();
  const setLatelyModalActive = useGeneralStore((state) => state.setLatelyModalActive);

  const navigate = useNavigate();
  const { getRoute } = useLocaleRoute();

  const redirect = (href: string) => {
    navigate(href);
    setPublicDrawerOpen(false);
  };

  return (
    <Drawer open={publicDrawerOpen} onClose={() => setPublicDrawerOpen(false)}>
      <List spaceY="xs">
        <List.Item px="md" py="md">
          <Block gap="sm" width="size-full">
            <Button variant="outline" size="md" stretch onClick={() => redirect(getRoute('Login'))}>
              {t('navigation.login')}
            </Button>

            <Button stretch variant="filled" size="md" onClick={() => redirect(getRoute('Register'))}>
              {t('navigation.create-free-account')}
            </Button>
          </Block>
        </List.Item>
      </List>
      <Separator width="size-full" mt="md" mb="xs" />
      <PreferencesContent />
      <Separator width="size-full" my="xs" />
      <HeaderNavBar
        onClick={(item) => {
          if (item.href === '#new') {
            setLatelyModalActive(true);
          }
          setPublicDrawerOpen(false);
        }}
        vertical
        hiddenProps={{ show: { xs: true, sm: true, md: true } }}
      />
      <Separator width="size-full" my="xs" />
      <HeaderNavBarSocial />
    </Drawer>
  );
};

export default withPublicRoute(PublicMobilMenuDrawer);

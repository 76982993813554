import React from 'react';

import { Dropdown, Icon } from '@bilira-org/design';
import { PriceAssetTagType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

type Props = {
  callback: (tag?: PriceAssetTagType) => void;
  items?: PriceAssetTagType[];
  selectedItem?: string;
};
const MarketTags = ({ selectedItem, items, callback }: Props) => {
  const { t } = useTranslation();

  const callbackMarket = (tag: PriceAssetTagType) => {
    if (tag.tag == selectedItem) {
      callback();
    } else {
      callback(tag);
    }
  };

  if (!items?.length) {
    return null;
  }

  return (
    <>
      <Dropdown size="md" value={t('common.filter')} position="right">
        {items.map((tag) => (
          <Dropdown.Item
            key={tag.tag}
            onClick={() => callbackMarket(tag)}
            extra={selectedItem === tag.tag ? <Icon type="o:check-circle" size="sm" color="primary-500" /> : null}
          >
            {t(`market.tags.${tag.tag}`)}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </>
  );
};

export default MarketTags;

import React from 'react';

import { type CoinProps, IconCoin as DesignIconCoin } from '@bilira-org/design';

import { env } from '@Libs/constants/env';

type Props = Omit<CoinProps, 'cdnBaseURL'>;

const IconCoin = (props: Props) => {
  return <DesignIconCoin cdnBaseURL={env.CDN_BASE_URL} {...props} />;
};

export default IconCoin;

import React from 'react';

import { Block, Span } from '@bilira-org/design';
import { RewardsQueryKey, SeasonsType, useTicker } from '@bilira-org/react-utils';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useTranslation } from 'react-i18next';

import { queryCache } from '@Libs/clientInstances/queryClient';
import TimerUnit from '@Modules/rewards/private/seasonTimer/components/TimerUnit';

dayjs.extend(duration);

type Props = {
  season?: SeasonsType;
};
const SeasonTimer = ({ season }: Props) => {
  const { t, i18n } = useTranslation();

  const expire = season && dayjs(season.current_season?.expires_at, { locale: i18n.language }).diff(dayjs());

  const { time } = useTicker({
    duration: expire || 0,
    enabled: !!expire,
    updateInterval: 60000,
    onComplete: () => {
      queryCache.invalidateQuery([RewardsQueryKey.REWARDS_SEASONS_KEY]);
      queryCache.invalidateQuery([RewardsQueryKey.REWARDS_USER_PARTICIPATION_PREREQUISITES_KEY]);
      queryCache.invalidateQuery([RewardsQueryKey.REWARDS_USER_PARTICIPATION_KEY]);
    },
  });

  const remaining = dayjs.duration(time);

  if (!season) {
    return null;
  }

  return (
    <Block row gap="xs" items="center">
      <TimerUnit value={remaining.months()} unit={t('common.date-unit.month')} />
      <Span weight="semibold" color="neutral-900">
        :
      </Span>
      <TimerUnit value={remaining.days()} unit={t('common.date-unit.day')} />
      <Span weight="semibold" color="neutral-900">
        :
      </Span>
      <TimerUnit value={remaining.hours()} unit={t('common.date-unit.hour')} />
      <Span weight="semibold" color="neutral-900">
        :
      </Span>
      <TimerUnit value={remaining.minutes()} unit={t('common.date-unit.min')} />
    </Block>
  );
};

export default SeasonTimer;

import { Dayjs, ManipulateType } from 'dayjs';

import { CalendarType } from './calendar';
import Flex from '../../flex/flex';
import { Icon } from '../../icons';
import { Panel } from '../../panel';
import { Text } from '../../typography';

interface CalendarHeaderProps {
  /** Date of the calendar */
  date: Dayjs;
  /** Callback to be called when calendar date changed */
  onChange: (date: Dayjs) => void;
  /** Locale of the calendar */
  locale: string;
  /** Type of the calendar */
  type: CalendarType;
  /** Callback to be called when calendar type is changed */
  onTypeChange: (type: CalendarType) => void;
}

const CalendarHeader = ({ date, onChange, locale, type, onTypeChange }: CalendarHeaderProps) => {
  const handleClick = (amount: number, unit: ManipulateType) => {
    onChange(date.add(amount, unit));
  };

  const renderContent = () => {
    if (type === 'day') {
      return (
        <>
          <Icon type="s:chevron-left" color="neutral-600" size="md" onClick={() => handleClick(-1, 'month')} />
          <Text onClick={() => onTypeChange('month')} className="hover:tw-text-primary-500">
            {date.locale(locale).format('MMMM')}
          </Text>
          <Text onClick={() => onTypeChange('year')} className="hover:tw-text-primary-500">
            {date.locale(locale).format('YYYY')}
          </Text>
          <Icon type="s:chevron-right" color="neutral-600" size="md" onClick={() => handleClick(1, 'month')} />
        </>
      );
    } else if (type === 'month') {
      return (
        <>
          <Icon type="s:chevron-left" color="neutral-600" size="md" onClick={() => handleClick(-1, 'year')} />
          <Text onClick={() => onTypeChange('year')} className="hover:tw-text-primary-500">
            {date.locale(locale).format('YYYY')}
          </Text>
          <Icon type="s:chevron-right" color="neutral-600" size="md" onClick={() => handleClick(1, 'year')} />
        </>
      );
    } else if (type === 'year') {
      const startYear = Math.floor(date.year() / 10) * 10;
      const endYear = startYear + 9;
      return (
        <>
          <Icon type="s:chevron-left" color="neutral-600" size="md" onClick={() => handleClick(-10, 'year')} />
          <Text>{`${startYear} - ${endYear}`}</Text>
          <Icon type="s:chevron-right" color="neutral-600" size="md" onClick={() => handleClick(10, 'year')} />
        </>
      );
    }
  };

  return (
    <Panel border="neutral-400" px="lg" py="md" mb="md">
      <Flex direction="row" justify="between">
        {renderContent()}
      </Flex>
    </Panel>
  );
};

export default CalendarHeader;

import { TextCopy } from '@bilira-org/design';
import { TransactionsRecordType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

type Props = {
  data?: TransactionsRecordType;
};

const TransactionID = ({ data }: Props) => {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  const shortId = data.id.split('-')[0];

  return (
    <TextCopy value={data.id} copiedText={t('common.copied')}>
      {shortId}
    </TextCopy>
  );
};

export default TransactionID;

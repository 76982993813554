import React, { ChangeEvent, forwardRef, ReactNode, useEffect, useRef } from 'react';

import { useFormattedNumberInput } from '@bilira-org/react-utils';
import { clsx } from 'clsx';

import fixIosInputFocus from '../../helpers/fixIosInputFocus';
import { Motion } from '../framer';
import { composeRef } from '../ref';
import { BaseComponentType } from '../types';

export interface Props {
  /** The value of the input. Can be a string or null. */
  value?: string | null;
  /** Callback triggered on input change. */
  onChange?: (value: string) => void;
  /** Disables the input. */
  disabled?: boolean;
  /** Icon to be displayed with the input. */
  icon?: ReactNode;
  /** Additional class name for the input. */
  className?: string;
  /** Number of decimal places to allow. */
  decimalPlaces?: number;
  /** Callback triggered on Enter key press. */
  onEnter?: () => void;
  /** Locale of the format */
  locale?: string;
  /** Cursor color */
  cursorColor?: 'primary' | 'red' | 'green';
}

export type FormattedFancyInputNumberProps = Props &
  Omit<React.HTMLProps<HTMLInputElement>, keyof Props> &
  BaseComponentType;

/**
 * InputNumber component for numeric input.
 *
 * @example
 * <NumberInput
 *  decimalPlaces={4}
 *  onChange={handleChange}
 *  value={value}
 *  color="ghost"
 *  placeholder="Enter a number"
 * />
 */
const FormattedFancyInputNumber = forwardRef<HTMLInputElement, FormattedFancyInputNumberProps>((props, ref) => {
  const {
    value: valueFromProps,
    onChange,
    disabled,
    icon,
    className,
    decimalPlaces,
    testId,
    onEnter,
    onKeyDown,
    locale = 'en',
    placeholder,
    cursorColor = 'primary',
    onFocus,
    ...rest
  } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { formattedValue: inputValue, handleChange } = useFormattedNumberInput({
    valueFromProps,
    locale,
    onChange,
    decimalPlaces,
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown?.(e);
    if (e.key === 'Enter') {
      onEnter?.();
    }
  };

  const adjustSize = (value: string) => {
    const val = value.replace(/[.,]/g, '');
    const separatorLength = value.length - val.length;

    if (!inputRef.current) {
      return;
    }

    if (val.length <= 6) {
      inputRef.current.style.fontSize = `48px`;
      inputRef.current.style.width = `${(val.length || 1) * 32 + separatorLength * 18}px`;
    } else {
      inputRef.current.style.fontSize = `36px`;
      inputRef.current.style.width = `${val.length * 24 + separatorLength * 14}px`;
    }
  };

  useEffect(() => {
    adjustSize(inputValue);
  }, [inputValue]);

  return (
    <div className="flex justify-center">
      <label className="fancy-input-group" data-testid={testId}>
        <Motion.Section show={true}>
          <input
            {...(testId ? { 'data-testid': `${testId}-inner` } : {})}
            ref={composeRef(inputRef, ref)}
            className={clsx('fancy-input', `fancy-cursor-${cursorColor}`, className)}
            onChange={handleInputChange}
            value={inputValue}
            maxLength={12}
            disabled={disabled}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            inputMode="decimal"
            onFocus={(e) => {
              fixIosInputFocus(e.currentTarget);
              onFocus?.(e);
            }}
            {...rest}
          />
        </Motion.Section>

        {icon && <div className="fancy-input-suffix">{icon}</div>}
      </label>
    </div>
  );
});

FormattedFancyInputNumber.displayName = 'FormattedFancyInputNumber';

FormattedFancyInputNumber.displayName = 'FormattedFancyInput';

export default FormattedFancyInputNumber;

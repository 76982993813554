import { useMutation, useQuery } from '@tanstack/react-query';

import { HttpClientType, HttpErrorType } from '../../client';
import { AnnouncementPopupType, AnnouncementType, QueryOptions } from '../../model';
import { CommonApi, ICommonApi } from '../api';
import { CommonQueryKey } from '../constants';

class CommonQuery {
  _api: ICommonApi;

  public constructor(params: HttpClientType) {
    this._api = new CommonApi(params);
  }

  public useGetAnnouncement = (locale: string, options?: QueryOptions) =>
    useQuery<AnnouncementType, HttpErrorType>({
      queryKey: [CommonQueryKey.COMMON_ANNOUNCEMENT, locale],
      queryFn: this._api.getAnnouncements,
      ...options,
    });

  /**
   * @deprecated
   */
  public usePostFeedback = (options?: QueryOptions) => {
    return useMutation({
      mutationFn: this._api.postFeedback,
      ...options,
    });
  };

  public useGetAnnouncementPopup = (options?: QueryOptions) =>
    useQuery<AnnouncementPopupType, HttpErrorType>({
      queryKey: [CommonQueryKey.POPUP],
      queryFn: this._api.getAnnouncementPopup,
      gcTime: 1800000, // 30 minutes
      ...options,
    });
}

export default CommonQuery;

const i18nToLocale = {
  en: 'en-US',
  tr: 'tr-TR',
};

/**
 * Converts an i18n language code to a corresponding locale code.
 *
 * @param {string} language - The i18n language code.
 * @returns {string} - The corresponding locale code.
 *
 * @example
 * const locale = i18nLanguageToLocale('en');
 * console.log(locale); // Output: 'en-US'
 */
const i18nLanguageToLocale = (language: string) => {
  return i18nToLocale[language as keyof typeof i18nToLocale] || i18nToLocale.tr;
};

export default i18nLanguageToLocale;

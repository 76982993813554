const errorMessages = {
  kyc2AndBankNotVerified: 'kyc2AndBankNotVerified',
  kyc2NotVerified: 'kyc2',
  bankNotVerified: 'bank',
};
interface Props {
  /** The KYC (Know Your Customer) level of the user. */
  kycLevel?: number;
  /** A flag indicating whether the user's bank is verified. */
  bankVerified?: boolean;
  /** An object containing error messages. */
  errorMessages: typeof errorMessages;
}

/**
 * Validates KYC and bank verification status for crypto withdrawal.
 *
 * @param {Props} props - The parameters for validating KYC and bank verification.
 * @returns {{ error: string | undefined }} - An object containing an error message if validation fails.
 */
const validateKycCryptoWithdraw = ({ kycLevel = -1, bankVerified, errorMessages }: Props) => {
  const kycLevelInsufficient = kycLevel < 2;
  const bankNotVerified = !bankVerified;

  let error: string | undefined;
  if (kycLevelInsufficient && bankNotVerified) {
    error = errorMessages.kyc2AndBankNotVerified;
  } else if (kycLevelInsufficient) {
    error = errorMessages.kyc2NotVerified;
  } else if (bankNotVerified) {
    error = errorMessages.bankNotVerified;
  }

  return { error };
};

export default validateKycCryptoWithdraw;

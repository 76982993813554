import React, { useCallback, useEffect } from 'react';

import { BottomSheet, Panel, TabCard, withIntrinsicHidden } from '@bilira-org/design';
import { AssetPairType } from '@bilira-org/react-utils';

import { BuySellDirection } from '@/components/trade/types';
import BuySell from '@Components/trade/buySell/BuySellTrade';
import BuySellOffer from '@Components/trade/buySell/components/BuySellOffer';
import BuySellPairs from '@Components/trade/buySell/components/BuySellPairs';
import { TradeWidgetType } from '@Components/trade/buySell/store';
import useBuySellCardHook from '@Components/trade/buySell/useBuySellCardHook';
import { DEFAULT_BUY_SELL_PAIR } from '@Libs/constants';

import TradeOnlyPopup from './components/TradeOnlyPopup';

type Props = {
  navigateOnSelection?: boolean;
  pairModalActive?: boolean;
  tradeWidgetType?: TradeWidgetType;
  defaultAssetPair?: AssetPairType;
};

/**
 * Swap component with card wrapper.
 */
const BuySellCard = ({
  navigateOnSelection,
  pairModalActive = false,
  tradeWidgetType = 'card',
  defaultAssetPair = DEFAULT_BUY_SELL_PAIR,
}: Props) => {
  const {
    buySellRef,
    t,
    open,
    setOpen,
    changeDetailAsset,
    swapAssetPair,
    assetPair,
    onBuySellDirectionChange,
    receivedAsset,
  } = useBuySellCardHook({
    navigateOnSelection,
    defaultAssetPair,
    tradeWidgetType,
    pairModalActive,
  });

  const refetchCallback = useCallback(() => {
    buySellRef.current?.refetchOffer();
  }, [buySellRef]);

  const onOfferClose = useCallback(
    (isOfferCompleted: boolean) => {
      buySellRef.current?.onOfferClose?.(isOfferCompleted);
    },
    [buySellRef],
  );

  return (
    <Panel size="none">
      <BottomSheet.Overlay>
        <TabCard
          headers={[
            {
              name: t('trade.labels.buy'),
              key: 'buy',
              borderColor: 'green',
            },
            {
              name: t('trade.labels.sell'),
              key: 'sell',
              borderColor: 'red',
            },
          ]}
          onChange={(key) => onBuySellDirectionChange(key as BuySellDirection)}
        >
          <TabCard.Panel>
            <BuySell
              tradeWidgetType={tradeWidgetType}
              ref={buySellRef}
              onAssetSelectorClick={() => setOpen(true)}
              assetInfo={swapAssetPair?.quote}
              type="buy"
              assetPair={assetPair}
            />
          </TabCard.Panel>
          <TabCard.Panel>
            <BuySell
              tradeWidgetType={tradeWidgetType}
              ref={buySellRef}
              onAssetSelectorClick={() => setOpen(true)}
              assetInfo={swapAssetPair?.base}
              type="sell"
              assetPair={assetPair}
            />
          </TabCard.Panel>
        </TabCard>
        <BottomSheet title={t('trade.labels.pair-selection')} onClose={() => setOpen(false)} open={open}>
          <BuySellPairs assetPair={assetPair} setAsset={changeDetailAsset} />
        </BottomSheet>
        <BuySellOffer tradeWidgetType={tradeWidgetType} onOfferClose={onOfferClose} refetchCallback={refetchCallback} />
      </BottomSheet.Overlay>
      <TradeOnlyPopup symbol={receivedAsset} />
    </Panel>
  );
};

export default withIntrinsicHidden(BuySellCard);

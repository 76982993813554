import React from 'react';

import { Badge, Rank } from '@bilira-org/design';
import { CoinSymbolType } from '@bilira-org/react-utils';

import { withPrivateRoute } from '@/libs/hocs';
import useManageWatchlist from '@/libs/hooks/useManageWatchList';

type Props = {
  baseAsset?: CoinSymbolType;
  quoteAsset?: CoinSymbolType;
};

const WatchlistStar = ({ baseAsset, quoteAsset }: Props) => {
  const { toggleWatchlist, watching, isPending } = useManageWatchlist({ baseAsset, quoteAsset });

  return (
    <>
      <Badge variant="circle" size="4xl" color="theme-n300d700" onClick={isPending ? undefined : toggleWatchlist}>
        <Rank
          color={watching ? 'white' : 'secondary-500'}
          size="lg"
          variant="rating"
          shape="star"
          ranked={watching}
          disabled={isPending}
        />
      </Badge>
    </>
  );
};

export default withPrivateRoute(WatchlistStar);

import React, { ForwardedRef, forwardRef } from 'react';

import { MenuGroup } from './MenuGroup';
import { MenuItemWithDropdown } from './MenuItemWithDropdown';
import { MenuItemWithoutDropdown } from './MenuItemWithoutDropdown';
import { MenuItemProps, MenuProps } from './types';

const Menu = forwardRef<HTMLDivElement, MenuProps>(
  ({ data, onClick, vertical, ...props }: MenuProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <MenuGroup vertical={vertical} {...props} ref={ref}>
        {data?.map((item) => <MenuItem onClick={onClick} vertical={vertical} {...item} key={item.text.toString()} />)}
      </MenuGroup>
    );
  },
);

const MenuItem = (props: MenuItemProps) => {
  if (props.menu) {
    return <MenuItemWithDropdown {...props} />;
  }

  return <MenuItemWithoutDropdown {...props} />;
};

Menu.displayName = 'Menu';

export default Menu;

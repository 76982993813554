import React from 'react';

import { ForceDecimal, FormatPriceType, formatPriceValue } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

type Props = {
  value: string | number;
  formatPriceProps?: Partial<FormatPriceType>;
};

/**
 * Component to display percentage values formatted with locale
 *
 * @example
 * <FormattedPercentage value="25"/> // 25.00 or 25,00 based on i18n locale
 *
 * <FormattedPercentage value="99.9"/> // 99.90 or 99,90 based on i18n locale
 *
 * <FormattedPercentage value="0.9"/> // 0.90 or 0,90 based on i18n locale
 */
const FormattedPercentage = ({ value: valueFromProps, formatPriceProps }: Props) => {
  const { t, i18n } = useTranslation();
  const value = typeof valueFromProps === 'number' ? valueFromProps.toString() : valueFromProps;
  return (
    <>
      {t('common.percentage', {
        value: formatPriceValue({
          value: value,
          locale: i18n.language,
          forceDecimal: ForceDecimal.SHOW_INSIGNIFICANT_DIGITS,
          decimal: 2,
          ...formatPriceProps,
        }),
      })}
    </>
  );
};

export default FormattedPercentage;

import { Icon, Popover, withHidden } from '@bilira-org/design';

import PreferencesContent from './PreferencesContent';

/**
 * Popover for viewing preferences
 */
const Preferences = () => {
  return (
    <>
      <Popover
        appearance={false}
        buttonVariant="pill-neutral"
        buttonSize="md"
        icon={<Icon color="secondary-500" type="o:cog-6-tooth" />}
        customWidth="250"
      >
        <PreferencesContent />
      </Popover>
    </>
  );
};

export default withHidden(Preferences);

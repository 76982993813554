import { TableSortingState } from '@bilira-org/design/src/components/table/dataTable';
import { AssetListParamType, SortOrderType } from '@bilira-org/react-utils';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface StorageValues {
  MARKET_TAB: number;
  MARKET_TABLE_SORT: { field: AssetListParamType['sort']; direction: SortOrderType; original?: TableSortingState };
}

export const localStorageConstants = {
  INITIAL_MARKET_TABLE_SORT: {
    field: 'Volume_24',
    direction: 'desc',
    original: [{ id: 'Volume_24', desc: true }],
  } as StorageValues['MARKET_TABLE_SORT'],
};

interface GeneralLocalStorage {
  localStore: StorageValues;
  setLocalStore: (data: Partial<StorageValues>) => void;
}

export const useLocalStorage = create<GeneralLocalStorage>()(
  persist(
    (set) => ({
      localStore: {
        MARKET_TABLE_SORT: localStorageConstants.INITIAL_MARKET_TABLE_SORT,
      } as StorageValues,
      setLocalStore: (data) => set((state) => ({ localStore: { ...state.localStore, ...data } })),
    }),
    {
      name: 'general-local-storage',
    },
  ),
);

import React from 'react';

import { Block, Button, PanelCopy, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

type Props = {
  callback: () => void;
  data: { qr_code: string; secret: string };
};

function StepQR({ callback, data }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Text mb="3xl">{t('two-fa.step-qr.title')}</Text>
      <Block gap="lg" items="center">
        <img style={{ width: '176px', padding: '16px' }} src={data.qr_code} />
        <PanelCopy copiedText={data.secret}>{data.secret}</PanelCopy>
      </Block>
      <Text mt="2xl" size="sm" align="center">
        {t('two-fa.step-qr.description')}
      </Text>

      <Button mt="4xl" size="md" stretch justify="center" variant="filled" onClick={callback}>
        {t('common.continue')}
      </Button>
    </>
  );
}

export default StepQR;

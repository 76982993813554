import React from 'react';

import { Flex, Panel } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import HeadTags from '@/components/common/HeadTags';
import { NotificationPreferences, UserPreferences } from '@Modules/account/preference/components';

function Index() {
  const { t } = useTranslation();

  return (
    <>
      <HeadTags title={t('account.headers.preferences')} titleSuffix="subpage" />
      <Panel border="neutral-400">
        <Flex gap="4xl" width={{ xs: 'full', sm: 'full', lg: '3/4' }}>
          <UserPreferences />
          <NotificationPreferences />
        </Flex>
      </Panel>
    </>
  );
}

export default Index;

import AccountApi from '@Libs/clientInstances/accountQuery';
import useAuth from '@Libs/hooks/userAuth';
import { usePersistedStore } from '@Store/persistedStore';

const useGetTotalBalance = () => {
  const { account } = useAuth();
  const maskBalance = usePersistedStore((state) => state.maskBalance);

  const { data, error, isLoading } = AccountApi.useGetTotalBalance(
    account,
    { quote_asset: 'TRYB' },
    {
      enabled: !!account,
    },
  );

  return { error, isLoading, balance: data?.balance || '0', freeBalance: data?.freeBalance || '0', maskBalance };
};

export default useGetTotalBalance;

import { PasswordRuleSchemaProps } from '@bilira-org/design';
import i18next, { t } from 'i18next';

const passwordSchemaWithi18nKeys: PasswordRuleSchemaProps[] = [
  {
    rule: 'common.validation.contains-lower-case',
    pattern: /[a-z]/g,
  },
  {
    rule: 'common.validation.contains-upper-case',
    pattern: /[A-Z]/g,
  },
  {
    rule: 'common.validation.contains-number',
    pattern: /[0-9]/g,
  },
  {
    rule: 'common.validation.contains-min-8-char',
    pattern: 8,
  },
  {
    rule: 'common.validation.contains-special-char',
    pattern: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g,
  },
];
export let passwordSchema: PasswordRuleSchemaProps[] = [];

function updateTranslations() {
  passwordSchema = passwordSchemaWithi18nKeys.map((schema) => ({
    rule: t(schema.rule),
    pattern: schema.pattern,
  }));
}

// initial fill translations when i18n is ready
if (i18next.isInitialized) {
  updateTranslations();
}

// update translations with new values on language change
i18next.on('languageChanged', () => {
  updateTranslations();
});

// update when we added some translation resource file
// https://www.i18next.com/overview/api#onadded
i18next.on('added', () => {
  updateTranslations();
});

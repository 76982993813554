import React from 'react';

import { Alert, Block, Panel, Tab, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import useLocaleRoute from '@/libs/hooks/useLocaleRoute';
import routeMap from '@/libs/route/routeMap';

import BuySell from './components/BuySell';
import DepositWithdraw from './components/DepositWithdraw';

const Index = () => {
  const { t, i18n } = useTranslation();
  const { getLocaleRoute } = useLocaleRoute();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const headers = [
    {
      name: t('commission.buy-sell'),
      key: routeMap.CommissionFee.Commission[i18n.language as LanguageType],
    },
    {
      name: t('commission.deposit-withdrawal'),
      key: routeMap.CommissionFee.Fee[i18n.language as LanguageType],
    },
  ];

  const defaultIndex = headers.findIndex((header) => getLocaleRoute(pathname) === header.key);

  return (
    <Block gap="4xl">
      <Panel border="neutral-400">
        <Text heading size="xl" weight="bold">
          {t('commission.commission-and-fee')}
        </Text>
        <Tab
          key={defaultIndex}
          defaultIndex={defaultIndex}
          variant="bordered"
          headers={headers}
          onChange={(index) => {
            navigate(getLocaleRoute(headers[index].key));
          }}
          panelsMt="lg"
        >
          <Tab.Panel>
            <BuySell />
          </Tab.Panel>
          <Tab.Panel>
            <DepositWithdraw />
          </Tab.Panel>
        </Tab>
      </Panel>
      {defaultIndex === 0 && <Alert status="yellow">{t('commission.commission-level-description')}</Alert>}
    </Block>
  );
};
export default Index;

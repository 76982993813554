import React, { HTMLAttributes } from 'react';

import { clsx } from 'clsx';

import { BaseComponentType, GapSizes } from '../types';

export interface SpaceProps extends HTMLAttributes<HTMLElement>, BaseComponentType {
  /** Children elements to be rendered within the space component. */
  children: React.ReactNode;
  /** Indicates flex-wrap property */
  wrap?: boolean;
  /** Whether to add divider between children */
  divider?: boolean;
  /** Gap between children */
  space?: GapSizes;
  /** Whether the layout should be in a column direction */
  col?: boolean;
}

/**
 * Component that provides space between its children elements.
 *
 * @example
 *  <Space space="md" col>
 *    <div>Item 1</div>
 *    <div>Item 2</div>
 *    <div>Item 3</div>
 *  </Space>
 */
const Space = ({ space, col, wrap, divider, children, testId, ...props }: SpaceProps) => {
  return (
    <div
      className={clsx('inline-flex', {
        'items-center': !col,
        'flex-col': col,
        'flex-wrap': wrap,
        [`gap-${space}`]: space,
        'divide-x divide-neutral-400': !col && divider,
        'divide-y divide-neutral-400': col && divider,
      })}
      data-testid={testId ?? 'space'}
      {...props}
    >
      {children}
    </div>
  );
};

Space.displayName = 'Space';

export default Space;

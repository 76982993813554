export type { TextProps } from './text';
export type { NumberTextProps } from './number';

import { NumberText } from './number';
import { Text, Truncate } from './text';
import TextCopy from './textCopy';

const Typography = Object.assign({ Text, Number: NumberText, TextCopy });

export { Typography, Text, NumberText as Number, TextCopy, Truncate };

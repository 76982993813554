/**
 * Returns additional headers for HTTP requests.
 * This function provides extra headers to be included in HTTP requests,
 * specifically indicating the operating system as 'web'.
 *
 * @returns {Object} An object containing extra headers.
 * @returns {string} return.os - The operating system, set to 'web'.
 */
const getExtraHeaders = () => {
  return {
    os: 'web',
  };
};

export default getExtraHeaders;

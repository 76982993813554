import { useCallback } from 'react';

import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type LocalStorageState = {
  [key: string]: any;
  setValue: <T>(key: string, value: T) => void;
};

const useLocalStorageStore = create<LocalStorageState>()(
  persist(
    (set) => ({
      setValue: <T>(key: string, value: T) => {
        set((state) => ({ ...state, [key]: value }));
      },
    }),
    {
      name: 'safe-local-storage',
    },
  ),
);

/**
 * Hook for safely using the Local Storage API via Zustand.
 *
 * @template T - The type of the value stored in Local Storage.
 * @param {string} key - The key to identify the stored value in Local Storage.
 * @param {T} initialValue - The initial value to be used if the key is not found in Local Storage.
 * @returns {[T, (val: T) => void]} - A tuple containing the current value and a function to update the value.
 */
function useSafeLocalStorage<T>(key: string, initialValue: T): [T, (val: T) => void] {
  const { [key]: value, setValue: setStoreValue } = useLocalStorageStore((state) => ({
    [key]: state[key] ?? initialValue,
    setValue: state.setValue,
  }));

  const setValue = useCallback((val: T) => setStoreValue(key, val), [key, setStoreValue]);

  return [value, setValue];
}

export default useSafeLocalStorage;

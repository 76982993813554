/**
 * There are cases where important information is at the end of the string and truncating the end isn't helpful.
 * This function solves that.
 *
 * @param  {string} str         String to be truncated
 * @param  {number} frontLen    Number of characters to be remained in front.
 * @param  {number} backLen     Number of characters to be remained at the back.
 * @param  {string} truncateStr String that is replaced the truncated portion
 * @return {string}             Truncated string. Defaults to '&hellip;' if unspecified.
 *
 * @example
 * truncateMiddle('some example text') // 'some e...text'
 * truncateMiddle('some example text', 5, 5, '???') // 'some ??? text'
 */

export const truncateMiddle = (str: string | undefined, frontLen = 6, backLen = 4, truncateStr = '...') => {
  if (!str) {
    return '';
  }
  const strLen = str.length;
  // Setting default values
  frontLen = ~~frontLen; // will cast to integer
  backLen = ~~backLen;
  truncateStr = truncateStr || '&hellip;';
  if ((frontLen === 0 && backLen === 0) || frontLen >= strLen || backLen >= strLen || frontLen + backLen >= strLen) {
    return str;
  }

  if (backLen === 0) {
    return str.slice(0, frontLen) + truncateStr;
  }

  return str.slice(0, frontLen) + truncateStr + str.slice(strLen - backLen);
};

import { useState } from 'react';

import { Badge, Flex, Panel, Separator, Text } from '@bilira-org/design';
import { getRewardTaskState, RewardsTasksType, UserParticipationType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { IconCustom } from '@Components/icon/CustomIcon';

import RewardTaskEndIcon from './components/RewardTaskEndIcon';
import RewardTaskIcon from './components/RewardTaskIcon';
import RewardTaskPoints from './components/RewardTaskPoints';
import { rewardTaskConfigByTaskState } from './constants';
import { getTaskTitle } from './helpers';
import RewardTaskDetailModal from './RewardTaskDetailModal';
import TaskExpiresAt from './TaskExpiresAt';

export type RewardTaskProps = {
  task: UserParticipationType | RewardsTasksType;
  color: 'theme-wn' | 'theme-n200d800';
};

const RewardTask = ({ task, color }: RewardTaskProps) => {
  const { t } = useTranslation();
  const taskState = getRewardTaskState(task);
  const [modal, setModal] = useState(false);

  const { panelStyle, badgeColor, textColor, iconType, opacity, showExpiresAt, iconColor, endIconColor, pointsColor } =
    rewardTaskConfigByTaskState[color][taskState] || rewardTaskConfigByTaskState[color].default;

  return (
    <>
      <Panel
        color={color}
        outline="neutral-400"
        outlineWidth="1"
        size="lg"
        onClick={() => setModal(true)}
        hover="outline"
        style={panelStyle}
      >
        <Flex direction="row" gap="md" justify="between" items="center">
          <Badge color={badgeColor} size="7xl" variant="square">
            <RewardTaskIcon task={task} color={iconColor} />
          </Badge>

          <Flex direction="col" gap="xs" flexType="auto">
            <Text weight="medium" color={textColor}>
              {getTaskTitle(task, t)}
            </Text>

            <Flex direction="row" items="center">
              <Flex direction="row" gap="sm" items="center" style={{ opacity }}>
                <IconCustom type={iconType} width="size-5" height="size-5" />
                <RewardTaskPoints task={task} taskState={taskState} color={pointsColor} />
              </Flex>

              {showExpiresAt && (
                <>
                  <Separator height="size-5" mx="sm" color="neutral-500" />
                  <TaskExpiresAt format="hour" task={task} color="neutral-700" size="xs" />
                </>
              )}
            </Flex>
          </Flex>

          <RewardTaskEndIcon taskState={taskState} color={endIconColor} />
        </Flex>
      </Panel>
      <RewardTaskDetailModal task={task} open={modal} onClose={() => setModal(false)} />
    </>
  );
};

export default RewardTask;

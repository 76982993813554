import React, { useRef, useState } from 'react';

import { Alert, Badge, Block, Button, Icon, List, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

export type SelectedDocType = { name: string; docCount: number; slug: string };

type Props = {
  callback: (value: SelectedDocType) => void;
};

function MethodSelection({ callback }: Props) {
  const { t } = useTranslation();
  const [identity, setIdentity] = useState<string>();
  const docTypeRef = useRef<SelectedDocType>();
  return (
    <>
      <Alert status="yellow" mb="2xl">
        {t('account.id-verification.kyc-2.select-id-and-start')}
      </Alert>

      <List
        loading={false}
        color="neutral-300"
        space="sm"
        dataSource={[
          {
            name: t('account.id-verification.identity'),
            slug: 'identity',
            docCount: 2,
            icon: <Icon type="o:identification" size="xl" color="primary-500" />,
          },
          {
            name: t('account.id-verification.driver-license'),
            slug: 'driving-license',
            docCount: 2,
            icon: <Icon type="o:identification" size="xl" color="primary-500" />,
          },
          {
            name: t('account.id-verification.passport'),
            slug: 'passport',
            docCount: 1,
            icon: <Icon type="o:passport" size="xl" color="primary-500" />,
          },
        ]}
        renderItem={(item) => (
          <List.Item
            size="2xl"
            key={item.name}
            hoverBgColor="neutral-300"
            onClick={() => {
              docTypeRef.current = { name: item.name, docCount: item.docCount, slug: item.slug };
              setIdentity(item.slug);
            }}
            extra={
              <Block row gap="lg" items="center">
                {item.slug === identity ? (
                  <Icon size="lg" color="primary-500" type="s:check-circle" />
                ) : (
                  <Badge size="lg" />
                )}
              </Block>
            }
          >
            <Block row gap="lg" items="center">
              {item.icon}
              <Text weight="medium" color="secondary-500">
                {item.name}
              </Text>
            </Block>
          </List.Item>
        )}
      />

      <Button
        disabled={!docTypeRef.current}
        mt="2xl"
        stretch
        justify="center"
        variant="filled"
        size="xl"
        onClick={() => callback(docTypeRef.current as SelectedDocType)}
      >
        {t('common.continue')}
      </Button>
    </>
  );
}

export default MethodSelection;

import React from 'react';

import { useTranslation } from 'react-i18next';

import RedirectSpinCard from '@Components/common/RedirectSpinCard';
import Tracker from '@Libs/tracker/Tracker';
import { logoutUrl } from '@Libs/utils';

const Login = () => {
  const { t } = useTranslation();
  setTimeout(() => {
    window.location.replace(logoutUrl);
    Tracker.logout();
  }, 500);

  return <RedirectSpinCard title={t('redirect.redirecting-2')} description={t('redirect.logging-out')} />;
};

export default Login;

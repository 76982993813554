import React, { DependencyList, Dispatch, SetStateAction } from 'react';

import canUseDom from '../utils/dom/canUseDom';

/**
 * Custom hook that provides the same API as React.useState but uses useLayoutEffect or useEffect based on whether the code is running in a DOM environment.
 *
 * @param {S} state - The initial state.
 * @param {DependencyList} [deps] - An array of dependencies for the effect.
 * @returns {[S, Dispatch<SetStateAction<S>>]} - A tuple containing the current state and a function to update the state.
 */
export const useIsomorphicEffect = canUseDom() ? React.useLayoutEffect : React.useEffect;

/**
 * Custom hook that mimics the behavior of React.useState but uses useEffect for side effects.
 *
 * @template S - The type of the state.
 * @param {S} state - The initial state.
 * @param {DependencyList} [deps] - An array of dependencies for the effect.
 * @returns {[S, Dispatch<SetStateAction<S>>]} - A tuple containing the current state and a function to update the state.
 */
export function useStateEffect<S>(state: S, deps?: DependencyList): [S, Dispatch<SetStateAction<S>>] {
  const [data, setData] = React.useState<S>(state);

  // This effect updates the state when dependencies change.
  React.useEffect(
    () => {
      setData(state);
    },
    deps || [state],
  );

  // We tell react that first mount has passed
  return [data, setData];
}

export default useStateEffect;

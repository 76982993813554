import { LocalStore } from './cookie';
import setThemeDom from './dom/setThemeDom';

export const systemTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

const safeLocalStorage = JSON.parse(LocalStore.get('safe-local-storage') || '{}');
const darkMode = safeLocalStorage?.state?.theme;
const isDarkModeEnabled = darkMode === undefined || darkMode === null ? systemTheme : darkMode;

setThemeDom(isDarkModeEnabled);

/** Initializes app with the theme preference of the user */
export const initDarkMode = () => {
  setThemeDom(isDarkModeEnabled);
};

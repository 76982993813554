import { extractIBAN } from 'ibantools';
/**
 * Extracts the bank code from the given IBAN.
 *
 * @param {string} iban - The IBAN from which to extract the bank code.
 * @returns {string} - The extracted bank code.
 *
 * @example
 * extractBankCode('TR480011100000000079118373') // '00111'
 */
export const extractBankCode = (iban: string) => {
  // Note: extractIBAN method currently does not extract TR IBAN bank identifier codes.
  const { bankIdentifier } = extractIBAN(iban);
  return bankIdentifier || iban.replaceAll(' ', '').substring(4, 9);
};

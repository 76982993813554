import React from 'react';

import { Block, Button, Checkbox, Col, Form, Grid } from '@bilira-org/design';
import Radio from '@bilira-org/design/src/components/radio/radio';
import { useTranslation } from 'react-i18next';

import {
  buildOptions,
  DataType,
  PersonalInfo2Type,
} from '@Modules/account/kyc/components/components/Kyc3Panel/helpers/constants';

type Props = {
  data?: PersonalInfo2Type;
  callback: (value: PersonalInfo2Type) => void;
};

function PersonalInfoStep2({ callback, data }: Props) {
  const { t } = useTranslation();

  const incomeSourceData = t('account.id-verification.kyc-3.personal-info.income-source-list', {
    returnObjects: true,
  }) as string[];
  const incomeData = t('account.id-verification.kyc-3.personal-info.income-list', { returnObjects: true }) as string[];

  return (
    <>
      <Form initialValues={data} onFinish={callback}>
        <Grid>
          <Col>
            <Form.Field
              rules={{ required: t('common.validation.required-field') }}
              controlled
              label={t('account.id-verification.kyc-3.personal-info.income-sources')}
              name="incomeSource"
            >
              {({ field }) => (
                <Block gap="lg" mt="lg">
                  <Checkbox
                    dataSource={buildOptions(incomeSourceData, 'income-source')}
                    checked={field.value}
                    onChecked={(value: any) => field.onChange(value)}
                  />
                </Block>
              )}
            </Form.Field>
          </Col>
          <Col>
            <Form.Field
              rules={{ required: t('common.validation.required-field') }}
              controlled
              label={t('account.id-verification.kyc-3.personal-info.income-declaration')}
              name="income"
            >
              {({ field }) => (
                <Block gap="lg" mt="lg">
                  <Radio<DataType>
                    name={field.name}
                    controlled
                    vertical
                    selected={field.value}
                    setSelected={(value) => {
                      field.onChange(value);
                    }}
                    dataSource={buildOptions(incomeData, 'income')}
                  />
                </Block>
              )}
            </Form.Field>
          </Col>
          <Col>
            <Button variant="filled" justify="center" size="xl" mt="lg" type="submit" stretch>
              {t('common.continue')}
            </Button>
          </Col>
        </Grid>
      </Form>
    </>
  );
}

export default PersonalInfoStep2;

import { useMemo } from 'react';

import { CoinSymbolType, NetworkAliasType, OperationType } from '@bilira-org/react-utils';

import CryptoQuery from '@Libs/clientInstances/cryptoQuery';

interface Props {
  /** The asset symbol. */
  asset?: CoinSymbolType;
  /** The network identifier. */
  network?: NetworkAliasType;
  /** The operation type. */
  operation: OperationType;
}

/**
 * Hook for fetching and checking if desired asset operation on network is disabled.
 *
 * @param {Props} props - The properties for fetching and checking asset operation on network is disabled.
 *
 * @property {boolean} disabled - Indicates whether the specified asset operation on network is disabled
 *
 * @returns {Result} - The result object containing the disabled status.
 */
const useGetDisabledNetworks = ({ asset, network, operation }: Props) => {
  const { data } = CryptoQuery.useGetDisabledOperations({ enabled: Boolean(asset && network) });

  const disabledOperations = useMemo(() => {
    const parsed = data?.map((disabledOperation) => {
      const [network, asset, operation] = disabledOperation.split('-');
      return {
        asset,
        network,
        operation,
      };
    });
    return parsed || [];
  }, [data]);

  const resultIndex = disabledOperations.findIndex(
    (d) => d.network === network && d.asset === asset && d.operation === operation,
  );

  return { disabled: resultIndex !== -1 };
};

export default useGetDisabledNetworks;

import React, { useMemo } from 'react';

import { ColumnDef, DataTable, Flex, Panel, TableBodyStyle, TableHeaderStyle, Text } from '@bilira-org/design';
import { BreakpointType } from '@bilira-org/design/types';
import { useTranslation } from 'react-i18next';

import { maskIdentityNumber } from '@/libs/helpers';
import kycQuery from '@Libs/clientInstances/idpQuery';
import { LEGAL_STATUSES } from '@Modules/account/kyc/constants';

const stretch: BreakpointType<boolean> = { xs: true, sm: true, md: false };
const headerStyle: TableHeaderStyle = { pb: 'none' };
const bodyStyle: TableBodyStyle = { py: 'sm' };
const responsiveBodyStyle: TableBodyStyle = { py: 'xs' };

function Header() {
  const { t } = useTranslation();
  const { data } = kycQuery.useGetUserKyc();

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }
    return [
      {
        id: data.identity_number,
        name: `${data.first_name} ${data.last_name}`,
        type:
          data.legal_status === 'corporate'
            ? t('account.id-verification.enterprise-account')
            : t('account.id-verification.personal-account'),
        identity: t(
          `account.id-verification.legal-statuses.${LEGAL_STATUSES?.[data.legal_status as LegalStatusesType]}`,
        ),
        identityNumber: data.identity_number,
      },
    ];
  }, [data, t]);

  const columns = useMemo<ColumnDef<(typeof tableData)[number], string>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('account.id-verification.full-name'),
        cell: (cell) => (
          <Text weight="medium" color="secondary-500">
            {cell.getValue()}
          </Text>
        ),
      },
      {
        accessorKey: 'identity',
        header: t('account.id-verification.identity-type'),
        cell: (cell) => (
          <Text weight="medium" color="secondary-500">
            {cell.getValue()}
          </Text>
        ),
      },
      {
        accessorKey: 'identityNumber',
        header: t('account.id-verification.identity-number'),
        cell: (cell) => <Text color="secondary-500">{maskIdentityNumber(cell.getValue())}</Text>,
      },
    ],
    [t],
  );

  if (!data) {
    return null;
  }
  return (
    <Panel color="neutral-200">
      <Flex justify="between" items={{ md: 'center', sm: 'stretch' }} gap="lg" direction={{ md: 'row' }}>
        <Text heading size="xl" weight="bold">
          {t('account.id-verification.verified-id')}
        </Text>

        <DataTable.Responsive
          stretch={stretch}
          columns={columns}
          data={tableData}
          headerStyle={headerStyle}
          bodyStyle={bodyStyle}
          responsiveBodyStyle={responsiveBodyStyle}
        />
      </Flex>
    </Panel>
  );
}

export default Header;

import React from 'react';

import { Icon, Popover, withHidden } from '@bilira-org/design';

import HelpContent from '@Components/layouts/components/components/HelpContent';

/**
 * Popover for displaying help content
 */
const Help = () => {
  return (
    <>
      <Popover
        appearance={false}
        buttonVariant="pill-neutral"
        buttonSize="md"
        icon={<Icon color="secondary-500" type="o:question-mark-circle" />}
        customWidth="250"
        testId="help-badge"
      >
        {(close) => <HelpContent closeCallback={close} />}
      </Popover>
    </>
  );
};

export default withHidden(Help);

import React from 'react';

import { Icon, List, Meta, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

function DeleteAccount() {
  const { t } = useTranslation();

  return (
    <>
      <Text heading size="xl" weight="bold" mb="md">
        {t('account.security-settings.delete-account')}
      </Text>

      <List divider>
        <List.Item
          key="remove-user"
          // extra={
          //   <Button color={'red'} variant={'outline'}>
          //     Hesabı Sil
          //   </Button>
          // }
        >
          <Meta
            extra={<Icon size="lg" type="o:trash" color="primary-500" />}
            position="left-top"
            title={t('account.security-settings.remove-account')}
            description={t('account.security-settings.contact-us-to-delete-account')}
          />
        </List.Item>
      </List>
    </>
  );
}

export default DeleteAccount;

import React from 'react';

import { Block, Button, Empty, Icon, List, Meta, Popover, Separator, Text, withHidden } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import WalletTotalBalance from '@Components/layouts/components/components/walletBadge/WalletTotalBalance';

/**
 * Popover badge for viewing wallet details
 */
const WalletBadge = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const callback = (url: string) => {
    navigate(url);
  };

  const walletMenuList = [
    {
      name: t('navigation.assets'),
      callback: () => callback('/assets'),
      icon: <Icon size="lg" color="neutral-700" type="o:wallet" />,
    },
    {
      name: t('transactions.transaction-history'),
      callback: () => callback('/transaction-history'),
      icon: <Icon size="lg" color="neutral-700" type="o:history" />,
    },
  ];

  return (
    <>
      <Popover
        appearance={false}
        position="center"
        buttonVariant="pill-neutral"
        buttonSize="md"
        icon={<Icon color="secondary-500" type="o:wallet" />}
        customWidth="320"
        testId="wallet-badge"
      >
        {(close) => (
          <>
            <WalletTotalBalance />

            <Separator width="size-full" my="md" />

            <Block row gap="sm" mb="md">
              <Button
                stretch
                variant="filled"
                size="sm"
                onClick={() => {
                  callback('/fiat');
                  close();
                }}
              >
                {t('common.fiat-deposit')}
              </Button>
              <Button
                stretch
                variant="outline"
                size="sm"
                onClick={() => {
                  callback('/crypto');
                  close();
                }}
              >
                {t('common.crypto-deposit')}
              </Button>
            </Block>

            <List
              empty={<Empty message={t('common.empty-message')} />}
              dataSource={walletMenuList}
              renderItem={(record) => (
                <React.Fragment key={`help-${record.name}`}>
                  <List.Item
                    px="md"
                    py="md"
                    hoverBgColor="neutral-300"
                    onClick={() => {
                      record.callback();
                      close();
                    }}
                  >
                    <Meta
                      key={record.name}
                      space="md"
                      extra={record.icon}
                      title={
                        <Text size="sm" color="secondary-500">
                          {record.name}
                        </Text>
                      }
                    />
                  </List.Item>
                </React.Fragment>
              )}
            />
          </>
        )}
      </Popover>
    </>
  );
};

export default withHidden(WalletBadge);

import React, { createElement, CSSProperties, ReactNode, useEffect, useRef } from 'react';

import { compareCurrencies, formatPrice } from '@bilira-org/react-utils';
import type { FormatPriceType } from '@bilira-org/react-utils';
import { clsx } from 'clsx';

import { Text, TextProps } from './text';
import { Skeleton } from '../skeleton';
import { BreakpointType, FontType, FontWeight, TextAlign, TextColorType, TextSizes, WordBreak } from '../types';
import { usePrevious } from '../utils';

export interface NumberTextProps
  extends Omit<TextProps, 'children' | 'prefix' | 'heading' | 'defaultValue' | 'wordBreak'> {
  /** Whether mask the value */
  masked?: boolean;
  /** Numerical value to be display */
  value?: string;
  /** Prefix to be displayed before the value.*/
  prefix?: ReactNode;
  /** Suffix to be displayed after the value.*/
  suffix?: ReactNode;
  /** Default value to display when the value is not provided.*/
  defaultValue?: string;
  /** Whether the text should have heading styles */
  heading?: boolean;
  /** The size of the decimal part of the number */
  decimalSize?: TextSizes | BreakpointType<TextSizes>;
  /** The font weight of the decimal part of the number */
  decimalWeight?: FontWeight;
  /** The size of the suffix */
  suffixSize?: TextSizes | BreakpointType<TextSizes>;
  /** The font weight of the suffix */
  suffixWeight?: FontWeight;
  /** Whether the suffix should have heading styles */
  suffixHeading?: boolean;
  /** Whether the component is in a loading state  */
  loading?: boolean;
  /** The font type of the suffix */
  suffixFont?: FontType;
  /**Suffix color*/
  suffixColor?: TextColorType;
  /** Whether to animate changes in the value */
  animateChange?: boolean;
  /** Additional class name for the container */
  containerClassName?: string;
  /** The word-break property for the text */
  wordBreak?: WordBreak;
  /** The text-align property for the text */
  align?: TextAlign;
  /** Additional formatting options for the number */
  formatPriceProps?: Partial<FormatPriceType>;
  /** Custom formatter function that returns main and decimal parts of the number */
  formatter?: (value?: string, defaultValue?: string) => string[];
  /** Css style*/
  style?: CSSProperties | undefined;
}

/**
 * NumberText component for displaying numerical values with optional formatting.
 *
 * @example
 * <NumberText size="sm" weight="medium" color="primary-500" suffix="TRYB" value={"15.423"} />
 */
const NumberText = ({
  value,
  prefix,
  suffix,
  loading,
  size,
  weight,
  heading,
  suffixHeading,
  decimalSize,
  decimalWeight,
  suffixSize,
  suffixWeight,
  suffixFont,
  skeleton,
  masked,
  animateChange,
  defaultValue = '0',
  containerClassName,
  wordBreak,
  align,
  formatPriceProps,
  style,
  color,
  suffixColor = color,
  testId = 'number-text',
  as = 'p',
  formatter,
  ...props
}: NumberTextProps) => {
  const prevValue = usePrevious(value);
  const numberRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (animateChange && prevValue) {
      removeChangeAnimation();
      const comparison = compareCurrencies(value, prevValue);

      if (comparison === 1) {
        numberRef.current?.classList.add('rise');
      }
      if (comparison === -1) {
        numberRef.current?.classList.add('fall');
      }
      const timer = setTimeout(removeChangeAnimation, 1000);

      return () => clearTimeout(timer);
    }
  }, [value]);

  const removeChangeAnimation = () => {
    numberRef.current?.classList.remove('rise');
    numberRef.current?.classList.remove('fall');
  };

  if (loading) {
    return skeleton ?? <Skeleton width="size-full" />;
  }
  if (masked) {
    return (
      <p
        data-testid={testId}
        className={clsx(
          'typography-number',
          {
            [`tw-break-${wordBreak}`]: wordBreak,
            [`tw-text-${align}`]: align,
            [`tw-text-${color}`]: color,
          },

          containerClassName,
        )}
      >
        <Text as="span" heading={heading} size={size} weight={weight} color={color} {...props}>
          ﹡﹡﹡﹡﹡
        </Text>
      </p>
    );
  }

  const [main, decimals] = formatter
    ? formatter(value, defaultValue)
    : formatPrice({
        defaultValue: defaultValue,
        ...formatPriceProps,
        value: value,
      }).values;

  const Wrapper = as;

  return (
    <Wrapper
      data-testid={testId}
      ref={numberRef}
      style={style}
      className={clsx(
        'typography-number',
        {
          [`tw-break-${wordBreak}`]: wordBreak,
          [`tw-text-${align}`]: align,
          [`tw-text-${color}`]: color,
        },

        containerClassName,
      )}
    >
      {prefix && (
        <Text
          wordBreak="none"
          as="span"
          heading={suffixHeading}
          size={suffixSize ?? decimalSize ?? size}
          weight={suffixWeight ?? decimalWeight ?? weight}
          font={suffixFont}
          className="mr-1"
          color={color}
          {...props}
        >
          {prefix}
        </Text>
      )}
      <Text as="span" heading={heading} size={size} weight={weight} color={color} {...props}>
        {main === '0' ? defaultValue : main}
      </Text>
      {decimals !== '0' ? (
        <Text
          as="span"
          heading={heading}
          size={decimalSize ?? size}
          color={color}
          weight={decimalWeight ?? weight}
          {...props}
        >
          {decimals}
        </Text>
      ) : null}
      {suffix && (
        <Text
          color={suffixColor}
          wordBreak="none"
          as="span"
          heading={suffixHeading}
          size={suffixSize ?? decimalSize ?? size}
          weight={suffixWeight ?? decimalWeight ?? weight}
          className="ml-1"
          font={suffixFont}
          {...props}
        >
          {suffix}
        </Text>
      )}
    </Wrapper>
  );
};

NumberText.displayName = 'NumberText';

export { NumberText };

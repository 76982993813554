import React, { ReactElement, ReactNode, useEffect } from 'react';

import { clsx } from 'clsx';
import { useSnapCarousel } from 'react-snap-carousel';

import { BaseComponentType } from '../types';

export interface SlideShowProps<T> extends BaseComponentType {
  /** Array of items to be rendered in the carousel */
  items: T[];
  /** Function to render each item */
  renderItem: (props: SlideShowRenderItemProps<T>) => ReactElement<SlideShowItemProps>;
}

export interface SlideShowRenderItemProps<T> {
  /** Item to be rendered */
  item: T;
  /** Index of the item */
  index: number;
  /** Whether the item is a snap point */
  isSnapPoint: boolean;
  /** Whether the item is active */
  isActive: boolean;
}
/**
 * Carousel component that displays a list of items in a carousel layout.
 *
 * Carousel is also controllable by arrow keys in the keyboard.
 *
 * @example
 * <Carousel
 *   items={data}
 *   renderItem={({ item, isActive, isSnapPoint }) => (
 *     <CarouselItem key={item.slug} isSnapPoint={isSnapPoint} isActive={isActive}>
 *       <img src={item.src} />
 *     </CarouselItem>
 *   )}
 * />;
 */

export const Carousel = <T,>({ items, renderItem, testId }: SlideShowProps<T>) => {
  const { scrollRef, next, prev, goTo, pages, activePageIndex, snapPointIndexes } = useSnapCarousel();

  useEffect(() => {
    const handle = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowLeft':
          next();
          return;
        case 'ArrowRight':
          prev();
          return;
        default:
          return;
      }
    };
    window.addEventListener('keypress', handle);
    return () => {
      window.removeEventListener('keypress', handle);
    };
  }, [next, prev]);

  return (
    <div className={clsx('slider root')} data-testid={testId ?? 'carousel'}>
      <ul className="scroll" ref={scrollRef}>
        {items.map((item, index) =>
          renderItem({
            item,
            index,
            isSnapPoint: snapPointIndexes.has(index),
            isActive: activePageIndex === index,
          }),
        )}
      </ul>

      <div className="controls">
        <ol className="pagination">
          {pages.map((p, i) => (
            <li
              key={i}
              className={clsx('pagination-item', {
                'pagination-item-active': i === activePageIndex,
              })}
            >
              <button className="pagination-button" onClick={() => goTo(i)} />
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export interface SlideShowItemProps {
  /** Indicates whether the item is a snap point. */
  isSnapPoint: boolean;
  /** Indicates whether the item is currently active */
  isActive: boolean;
  /** Children to be rendered within carousel item */
  children?: ReactNode;
}

/**
 * Carousel item component that represents an individual item within the carousel.
 *
 *  @example
 * <Carousel
 *   items={data}
 *   renderItem={({ item, isActive, isSnapPoint }) => (
 *     <CarouselItem key={item.slug} isSnapPoint={isSnapPoint} isActive={isActive}>
 *       <img src={item.src} />
 *     </CarouselItem>
 *   )}
 * />;
 */
export const CarouselItem = ({ isSnapPoint, isActive, children }: SlideShowItemProps) => {
  return (
    <li
      className={clsx('item', {
        ['snap-point']: isSnapPoint,
        ['itemActive']: isActive,
      })}
    >
      {children}
    </li>
  );
};

Carousel.displayName = 'Carousel';

CarouselItem.displayName = 'CarouselItem';

export default Carousel;

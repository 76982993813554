import React from 'react';

import { useTranslation } from 'react-i18next';

import TransactionPanelCard from '@Components/transaction/TransactionPanelCard';

const LastCryptoDeposits = () => {
  const { t } = useTranslation();
  return (
    <TransactionPanelCard
      title={t('common.recent-deposit-transactions')}
      limit={5}
      type="crypto"
      direction="deposit"
      showAllButton
    />
  );
};

export default LastCryptoDeposits;

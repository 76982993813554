import { isEmpty } from '@bilira-org/react-utils';

import { useAuthParamsStore } from '../hooks/useAuthParams';

/**
 * Saves UTM parameters from the current URL's query string into the auth parameters store.
 * Note: If there are existing UTM parameters stored and no new UTM parameters are found in the URL,
 * the existing ones will not be modified.
 *
 * @returns {void}
 */
export const saveUTMParams = () => {
  const {
    authParams: { utm: utmParams },
    setUtmParams,
  } = useAuthParamsStore.getState();

  const isUtmParamsEmpty = isEmpty(utmParams);
  const searchParams = Array.from(new URLSearchParams(window.location.search));

  const newUtmParams = searchParams.reduce(
    (agg, [key, value]) => {
      if (key.startsWith('utm')) {
        agg[key] = value;
      }

      return agg;
    },
    {} as Record<string, string>,
  );

  // If UTM params stored before and new params is empty do not modify the stored value
  if (!isUtmParamsEmpty && Object.keys(newUtmParams).length === 0) {
    return;
  }

  setUtmParams(newUtmParams);
};

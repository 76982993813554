import { validateIBAN } from 'ibantools';
/**
 * Validates the provided string against the IBAN format.
 *
 * @param {string} str - The string to be validated as an IBAN.
 * @returns {boolean} - true if the string is a valid IBAN, false otherwise.
 *
 * validateIban("abc") // false
 * validateIban("TR480011100000000079118373") // true
 */
export const validateIban = (iban: string) => {
  return validateIBAN(iban.replaceAll(' ', '')).valid;
};

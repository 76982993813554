import React from 'react';

import { withPrivateRoute } from '@/libs/hocs';
import useAuth from '@/libs/hooks/userAuth';
import DefaultContent from '@Modules/otc/components/DefaultContent';

import OTCContent from '../otcTrade/OTCContent';

const PrivateOtcContent = () => {
  const { otcUser } = useAuth();
  return <>{otcUser ? <OTCContent /> : <DefaultContent />}</>;
};
export default withPrivateRoute(PrivateOtcContent);

import { memo, useEffect } from 'react';

import useSubscriptionHandler from '@Libs/hooks/useSubscriptionHandler';

type Props = {
  channel: string;
  baseQuoteList?: string[];
};

const GlobalSearchSubscription = ({ channel, baseQuoteList }: Props) => {
  const { subscribe } = useSubscriptionHandler(channel);

  useEffect(() => {
    // This is necessary for global search, if you open global search on the market list and close it,
    // tokens should only remain subscribed not should not be unsubscribed.
    baseQuoteList && subscribe(baseQuoteList, { type: 'last_price' });
    baseQuoteList && subscribe(baseQuoteList, { type: 'symbol_statistics' });
  }, [baseQuoteList]);

  return null;
};

export default memo(GlobalSearchSubscription);

import React, { useMemo } from 'react';

import { Block, ColumnDef, DataTable, Meta, Panel, TableBodyStyle, TableHeaderStyle, Text } from '@bilira-org/design';
import { ConversionType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

const headerStyle: TableHeaderStyle = { showBackground: true, px: 'sm', py: 'sm', weight: 'bold', size: 'xs' };
const bodyStyle: TableBodyStyle = { px: 'md', py: 'md' };

export type Props = {
  data: ConversionType;
};

export const ConversionTransferType = ({ data }: Props) => {
  const { t } = useTranslation();

  const columns = useMemo<ColumnDef<ConversionType['conversions'][number], string>[]>(
    () => [
      {
        header: t('conversion.table.crypto'),
        accessorKey: 'crypto',
        cell: (item) => {
          return (
            <Block row gap="md">
              <Text size="sm" color="secondary-500">
                {item.getValue()}
              </Text>
            </Block>
          );
        },
      },
      {
        header: t('conversion.table.amount'),
        accessorKey: 'amount',
        cell: (item) => (
          <Text size="sm" color="secondary-500">
            {item.getValue()}
          </Text>
        ),
      },
      {
        header: t('conversion.table.tryb-value'),
        accessorKey: 'converted_amount',
        cell: (item) => (
          <Text size="sm" color="secondary-500">
            {item.getValue()}
          </Text>
        ),
      },
    ],
    [t],
  );

  return (
    <Meta
      title={<Text size="sm">{t('conversion.converted-crypto')}</Text>}
      gap="sm"
      description={
        <Panel size="none">
          <DataTable
            rowId="id"
            rounded
            headerStyle={headerStyle}
            bodyStyle={bodyStyle}
            data={data.conversions}
            columns={columns}
          />
        </Panel>
      }
    />
  );
};

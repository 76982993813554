const emailRegEx =
  '([\\w-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([\\w-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)';

/**
 * Masks an email address, keeping the first n characters of the local part
 * and the domain extension intact, and replacing the rest with asterisks.
 *
 * Mask an email address by replacing parts of it with asterisks.
 * @param {string} email - The email address to mask.
 * @param {number} [unmaskedLength=2] - The number of unmasked characters.
 * @param {number} [maskRepeat=2] - The number of times the mask character will be repeated.
 * @returns {string} The masked email address.
 *
 * @example
 * maskEmail('example@example.com', 3, 3);
 * // 'exa***@example.com'
 */
const maskEmail = (email?: string, unmaskedLength = 2, maskRepeat = 2) => {
  if (!email || !email.match(emailRegEx)) {
    return null;
  }

  const mask = '*'.repeat(maskRepeat);
  const [username, domain] = email.split('@');
  const unmaskedLetters = username.slice(0, unmaskedLength);

  //Mask domain.
  // const firstDotIndex = domain.indexOf('.');
  // const result = domain.slice(firstDotIndex + 1, domain.length);
  // const subdomainPrefix = `${mask}.${result}`;

  return `${unmaskedLetters}${mask}@${domain}`;
};

export default maskEmail;

import React from 'react';

import { Modal } from '@bilira-org/design';

import { DEFAULT_BUY_SELL_PAIR } from '@/libs/constants';
import BuySellCard from '@Components/trade/buySell/BuySellCard';
import { useGeneralStore } from '@Store/generalStore';

/**
 * Component to render swap modal.
 *
 * Utilizes general store to control active state of the modal
 */
const TradeModal = () => {
  const { swapModal, setSwapModal } = useGeneralStore();

  return (
    <>
      <Modal
        testId="swap-modal"
        open={swapModal.active}
        overlayClosable={true}
        onClose={() => setSwapModal({ active: false })}
        compound
        variant="popup"
      >
        <BuySellCard
          tradeWidgetType="modal"
          defaultAssetPair={swapModal.pairModalActive ? undefined : DEFAULT_BUY_SELL_PAIR}
          hiddenProps={{ show: { xs: true, sm: true } }}
          pairModalActive={swapModal.pairModalActive}
        />
      </Modal>
    </>
  );
};

export default TradeModal;

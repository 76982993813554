import { FileStatus, FileType } from './fileUploader';

/**
 * Checks if any files in the array have error statuses.
 * @param {FileType[]} files - An array of files to check.
 * @returns {boolean} - Returns true if any file has an error status, otherwise false.
 */
const checkFileErrors = (files: FileType[]): boolean => {
  return files.some((file) => [FileStatus.LOAD_ERROR, FileStatus.PROCESSING_ERROR].includes(file.status));
};

export default checkFileErrors;

import { Block, Skeleton } from '@bilira-org/design';

const WalletItemSkeleton = () => {
  return (
    <Block row justify="between" items="center" p="md" gap="lg">
      <Block row items="center" gap="md">
        <Skeleton width="size-8" height="size-8" rounded="full" />

        <Block gap="sm">
          <Skeleton height="size-5" width="size-28" />

          <Skeleton height="size-4" width="size-14" />
        </Block>
      </Block>

      <Block row gap="lg" items="center">
        <Skeleton height="size-4" width="size-9" />
        <Skeleton height="size-5" width="size-5" rounded="full" />
      </Block>
    </Block>
  );
};

export default WalletItemSkeleton;

import React from 'react';

import { Block, Button, IconCustom, Layout, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const { Content } = Layout;

/**
 * Component for displaying an error message related to the login process.
 */
const LoginError = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Content style={{ height: '100%' }}>
      <Block height="size-full" width="size-full" justify="center" items="center">
        <Block items="center" maxWidth="md">
          <IconCustom type="oops-illustration" />
          <Text heading size="xl" mb="lg" mt="xl">
            {t('error.something-went-wrong')}
          </Text>

          <Text align="center" mb="2xl">
            {t('error.login-error-desc')}
          </Text>
          <Button variant="outline" size="xl" onClick={() => navigate('/login')}>
            {t('error.go-back')}
          </Button>
        </Block>
      </Block>
    </Content>
  );
};

export default LoginError;

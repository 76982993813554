import { LanguageUniversalType } from '@bilira-org/react-utils';

import { routesAvailableLocale } from '@Libs/route/routeMap';

const getNewLocaleRoute = (oldLocale: LanguageUniversalType, newLocale: LanguageUniversalType) => {
  const pathName = window.location.pathname;

  if (!pathName) {
    return null;
  }

  let result = routesAvailableLocale.find((route) => route[oldLocale] === pathName);
  if (!result) {
    result = routesAvailableLocale.find((route) => location.pathname.includes(route[oldLocale]));
  }

  if (!result) {
    return null;
  }

  return pathName.replace(result[oldLocale], result[newLocale]);
};

export default getNewLocaleRoute;

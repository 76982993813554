import React from 'react';

import { useTranslation } from 'react-i18next';

import TransactionPanelCard from '@Components/transaction/TransactionPanelCard';

const LastCryptoWithdrawals = () => {
  const { t } = useTranslation();

  return (
    <>
      <TransactionPanelCard
        title={t('common.recent-withdraw-transactions')}
        limit={5}
        type="crypto"
        direction="withdrawal"
        showAllButton
      />
    </>
  );
};

export default LastCryptoWithdrawals;

import { SwapType } from '@bilira-org/react-utils/src/model/SwapTypes';
import { create } from 'zustand';

type SetStateAction<S> = S | ((prevState: S) => S);

interface SwapStoreType {
  swapOffer?: SwapType;
  setSwapOffer: (swapOffer?: SetStateAction<SwapType | undefined>) => void;
}

export const useSwapStore = create<SwapStoreType>((set) => ({
  swapOffer: undefined,
  setSwapOffer: (swapOffer?: SetStateAction<SwapType | undefined>) =>
    set((state) => {
      return { swapOffer: typeof swapOffer === 'function' ? swapOffer(state?.swapOffer) : swapOffer };
    }),
}));

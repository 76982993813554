import { Block, Button, Hidden, Icon, LayoutV2, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { useGeneralStore } from '@/store';
import AllTransactionsCardContainer from '@Modules/transactios/components/AllTransactionsCardContainer';

const { Content, Hero, InnerContent } = LayoutV2;

function Home() {
  const { t } = useTranslation();
  const setTxExportModal = useGeneralStore((state) => state.setTxExportModal);
  return (
    <>
      <Hero>
        <Block row justify="between" items="center">
          <Text heading size="xl" color="neutral-900" weight="bold" my="md">
            {t('transactions.transaction-history')}
          </Text>

          <Hidden show={{ md: true }}>
            <Button
              startIcon={<Icon type="o:document-arrow-down" />}
              size="md"
              variant="outline-filled"
              onClick={() => setTxExportModal(true)}
            >
              {t('transactions.export-all-statements')}
            </Button>
          </Hidden>
        </Block>
      </Hero>
      <Content>
        <InnerContent>
          <AllTransactionsCardContainer />
        </InnerContent>
      </Content>
    </>
  );
}

export default Home;

import React, { useImperativeHandle } from 'react';

import { clsx } from 'clsx';

import { convertMsToMinutes } from './helpers';
import { BaseComponentType, BaseSize, MarginSizes } from '../types';

export interface ICircleTimerReturn {
  reset: (immediateStart?: boolean) => void;
  pause: (pause: boolean) => void;
}

interface HookProps {
  time: number;
  reset: (immediateStart?: boolean) => void;
  pause: (pause: boolean) => void;
  calculatedDuration: number;
}

interface ICircleTimer extends BaseComponentType, HookProps {
  /** Margin y-axis */
  my?: MarginSizes;
  /** The variant of the CircleTimer */
  variant?: 'countdown' | 'circle';
  /** Size of the timer */
  size?: BaseSize;
  /** Whether to show label */
  showLabel?: boolean;
  /** Whether to display the timer in digital format (only applicable for `countdown` variant). */
  digitalDisplay?: boolean;
  /** Custom render function */
  render?: (time: number) => React.ReactNode;
}

const sizeConfig = {
  xs: { width: 16, height: 16, viewBox: '0 0 18 18', radius: 8, angle: 50 },
  sm: { width: 24, height: 24, viewBox: '0 0 27 27', radius: 12, angle: 75 },
  md: { width: 64, height: 64, viewBox: '0 0 64 64', radius: 30, angle: 190 },
  lg: { width: 80, height: 80, viewBox: '0 0 80 80', radius: 37, angle: 235 },
  xl: { width: 96, height: 96, viewBox: '0 0 96 96', radius: 45, angle: 284 },
};

/**
 * Inner CircleTimer component for displaying a circular timer or countdown.
 * Pass hook controls for functionality.
 *
 * @example
 * <CircleTimer
 *   duration={60}
 *   my="4"
 *   size="lg"
 *   showLabel
 *   onComplete={() => console.log('Timer completed!')}
 *   variant="circle"
 *   enabled
 *   updateInterval={0.1}
 *   durationType="second"
 * />
 */
const CircleTimerInner = React.forwardRef<ICircleTimerReturn | undefined, ICircleTimer>(
  (
    {
      my,
      size = 'md',
      showLabel = true,
      variant = 'circle',
      digitalDisplay,
      testId,
      time,
      reset,
      pause,
      calculatedDuration,
      render,
    }: ICircleTimer,
    ref?: React.ForwardedRef<ICircleTimerReturn | undefined>,
  ) => {
    const { width, height, viewBox, radius, angle } = sizeConfig[size];

    const period = angle / calculatedDuration;
    const offset = angle - (calculatedDuration - time) * period;

    useImperativeHandle(
      ref,
      () => ({
        reset: reset,
        pause: pause,
      }),
      [reset, pause],
    );

    if (render) {
      return render(time);
    }

    // If format is required, pass it as a prop.
    const formattedTime = convertMsToMinutes(time);

    if (variant === 'countdown') {
      return <span>{digitalDisplay ? formattedTime : time / 1000}</span>;
    }

    return (
      <div
        style={{ width: `${width}px`, height: `${height}px` }}
        className={clsx('circle-timer', { [`my-${my}`]: my, [`timer-size-${size}`]: size })}
        data-testid={testId ?? 'circle-timer'}
      >
        <svg width={width} height={height} viewBox={viewBox}>
          <circle className="circle-bg" cx="50%" cy="50%" r={radius} />
          <circle
            className="circle"
            cx="50%"
            cy="50%"
            r={radius}
            strokeLinecap="square"
            strokeDasharray={angle}
            strokeDashoffset={offset}
            shapeRendering="geometricPrecision"
          />
        </svg>
        {showLabel && <span className="timer">{formattedTime}</span>}
      </div>
    );
  },
);

export default CircleTimerInner;

import { useEffect } from 'react';

import { isEmpty } from '@bilira-org/react-utils';

import wsManager from '@Libs/ws/priceWebSocket';

export type SubscriptionParamsType = {
  type: EventType;
  params?: Record<string, string>;
};

const useSubscriptionHandler = (channel: string, params?: SubscriptionParamsType[], symbols?: string[]) => {
  useEffect(() => {
    if (!isEmpty(symbols) && params) {
      params.forEach((param) => {
        wsManager.subscribe(`${channel}-${param.type}`, { symbols: symbols, ...param });
      });
    }

    return () => {
      if (!isEmpty(symbols) && params) {
        params.forEach((param) => {
          wsManager.unsubscribe(`${channel}-${param.type}`, { symbols: symbols, ...param });
        });
      }
    };
  }, [JSON.stringify(symbols), JSON.stringify(params)]);

  const subscribe = (symbols: string[], param: SubscriptionParamsType) => {
    !isEmpty(symbols) && wsManager.subscribe(`${channel}-${param.type}`, { symbols: symbols, ...param });
  };

  const unsubscribe = (symbols: string[], param?: SubscriptionParamsType) => {
    !isEmpty(symbols) && wsManager.unsubscribe(`${channel}-${param?.type}`, { symbols: symbols, ...param });
  };

  return {
    subscribe,
    unsubscribe,
  };
};

export default useSubscriptionHandler;

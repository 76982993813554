import { useCallback } from 'react';

import { SwapStatusType, useTicker } from '@bilira-org/react-utils';

type Props = {
  /** Function to change swap status */
  changeSwapStatus: (status: SwapStatusType) => void;
};

/**
 * Hook for handling offer timeout functionality for swap.
 *
 * Manages the countdown timer for offer timeout and updates the swap status accordingly
 */
function useOfferTimeout({ changeSwapStatus }: Props) {
  const onComplete = useCallback(() => {
    changeSwapStatus('timeout');
  }, []);

  const { reset, pause } = useTicker({
    duration: 20000,
    enabled: false,
    updateInterval: 10000,
    onComplete: onComplete,
  });

  const initializeTimeout = useCallback(
    (resetTimeout: boolean) => {
      if (resetTimeout) {
        reset(true);
      } else {
        pause(true);
      }
    },
    [reset, pause],
  );

  return { initializeTimeout };
}

export default useOfferTimeout;

import React from 'react';

import { Alert, Badge, Block, Button, Col, FileType, FileUploader, Grid, IconCustomType } from '@bilira-org/design';
import { useStateEffect } from '@bilira-org/react-utils';
import { FilePondErrorDescription, FilePondFile } from 'filepond';
import { useTranslation } from 'react-i18next';

import { IconCustom } from '@/components/icon/CustomIcon';

import { identityUploadLabels } from './constants';

type Props = {
  callback: (source: FileType[][]) => void;
  sources?: FileType[][];
  docCount?: number;
  docSlug?: string;
};

function IdentityUpload({ sources, callback, docCount = 2, docSlug }: Props) {
  const { t } = useTranslation();
  const [files, setFiles] = useStateEffect<FileType[][] | undefined>(sources);
  const hideInfoIllustration = files !== undefined && files.length > 0;
  const isSubmitButtonDisabled =
    (files?.length || 0) < docCount ||
    files?.some((file) => !file || file?.length < 1 || FileUploader.checkFileErrors(file));

  const fileLabel = (index: number) => {
    switch (index) {
      case 0:
        return t(docCount === 2 ? identityUploadLabels[0] : identityUploadLabels.default, {
          maxFileSize: '10MB',
        });
    }
  };

  return (
    <>
      <Alert status="yellow" mb="2xl">
        {t(`account.id-verification.kyc-2.identity-upload-instruction.${docCount === 1 ? 'front' : 'front-and-back'}`)}
      </Alert>
      {!hideInfoIllustration && (
        <Block mb="2xl">
          <Badge size="md" variant="square" color="neutral-300">
            <IconCustom type={`kyc-${docSlug}` as IconCustomType} />
          </Badge>
        </Block>
      )}
      <Grid col={docCount} gap="lg">
        {Array(docCount)
          .fill(0)
          .map((data, index) => (
            <Col key={index}>
              <FileUploader
                labelIdle={t(
                  docCount === 2
                    ? identityUploadLabels[index as keyof typeof identityUploadLabels]
                    : identityUploadLabels.default,
                  {
                    maxFileSize: '10MB',
                  },
                )}
                files={files?.[index] ?? sources?.[index]}
                onUpload={(files) =>
                  setFiles((value) => {
                    const newFiles = [...(value || [])];
                    newFiles[index] = files;
                    return newFiles;
                  })
                }
                styleItemPanelAspectRatio="1"
                maxFiles={1}
                onremovefile={(error: FilePondErrorDescription | null, file: FilePondFile) => {
                  console.log(error, file.file);
                }}
                acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']}
              />
            </Col>
          ))}
      </Grid>

      <Button
        variant="filled"
        disabled={isSubmitButtonDisabled}
        mt="2xl"
        stretch
        justify="center"
        size="xl"
        onClick={() => callback(files || [])}
      >
        {t('common.continue')}
      </Button>
    </>
  );
}

export default IdentityUpload;

import React from 'react';

import { ERROR_PAGE_STATE } from '@bilira-org/react-utils';
import { Navigate, useLocation } from 'react-router-dom';

import GeneralError from '@Components/errorPages/components/GeneralError';
import LoginError from '@Components/errorPages/components/LoginError';

/**
 * Component to handle and display error pages based on the error state.
 *
 * Does API health checks if Network Error received.
 */
function ErrorPage() {
  const { state } = useLocation();

  if (state?.errorState === ERROR_PAGE_STATE.AUTH_ERROR) {
    // Redirect to public homepage if AUTH_ERROR received
    return <Navigate to="/" />;
  }

  switch (state?.errorState) {
    case ERROR_PAGE_STATE.LOGIN_ERROR:
      return <LoginError />;
    default:
      return (
        <GeneralError
          errorCode={state?.errorState || ERROR_PAGE_STATE.UNHANDLED_ERROR}
          isAuthServiceDown={state?.authServiceDown}
        />
      );
  }
}

export default ErrorPage;

import { isStringOrUndefined } from '../isStringOrUndefined';

/**
 * Checks if the value type is number or string or undefined.
 * This utility narrows types with type guards. https://www.typescriptlang.org/docs/handbook/2/narrowing.html
 *
 * @param {unknown} value - The value to be checked.
 * @returns {boolean} - `true` if the value is a finite number, `false` otherwise.
 *
 * @example
 * isStringOrUndefined('hello'); // true
 * isStringOrUndefined(undefined); // true
 * isStringOrNumberOrUndefined(42); // true
 * isStringOrNumberOrUndefined([]); // false
 */
export function isNumberOrStringOrUndefined(value: unknown): value is number | string | undefined {
  return typeof value === 'number' || isStringOrUndefined(value);
}

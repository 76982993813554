import React, { memo } from 'react';

import { Block, Button, Icon, Input } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import MarketPriceSubscription from '@Components/live/subscription/GeneralPriceSubscription';
import useMyAssetTableHook from '@Modules/assets/components/components/hooks/useMyAssetTableHook';
import MyAssetTable from '@Modules/assets/components/components/MyAssetTable';

// import ConversionModal from '../modal/ConversionModal';

const MyAssetContainer = () => {
  const { t } = useTranslation();

  const {
    data,
    searchText,
    onSearch,
    isPending,
    isEnabled,
    setIsEnabled,
    subscriptionList,
    // isConversionModalOpen,
    // setIsConversionModalOpen,
  } = useMyAssetTableHook();

  return (
    <>
      <MarketPriceSubscription channel="my-assets" baseQuoteList={subscriptionList} />
      <Block p="2xl" id="assets">
        <Block row justify="between" items="center" mb="md">
          <Input.Search
            variant="bordered"
            customWidth={{ xs: '140', sm: '208' }}
            iconStart={<Icon size="md" type="o:magnifying-glass" />}
            placeholder={t('common.search')}
            value={searchText}
            onChange={(val) => onSearch(val.target.value)}
          />
          <Block row gap="md">
            <Button
              variant="outline"
              size="sm"
              startIcon={<Icon type={isEnabled ? 'o:circle-stack-slash' : 'o:circle-stack'} />}
              onClick={() => setIsEnabled(!isEnabled)}
            >
              {isEnabled ? t('assets.show-low-balances') : t('assets.hide-low-balances')}
            </Button>
            {/* <Button
              variant="outline"
              size="sm"
              startIcon={<Icon type="o:arrow-path" />}
              onClick={() => setIsConversionModalOpen(true)}
            >
              {t('assets.convert-low-balances')}
            </Button> */}
          </Block>
        </Block>

        <MyAssetTable data={data} loading={isPending} />
      </Block>
      {/*<ConversionModal data={data} open={isConversionModalOpen} onClose={() => setIsConversionModalOpen(false)} />*/}
    </>
  );
};

export default memo(MyAssetContainer);

import React from 'react';

import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { clsx } from 'clsx';
import { Link } from 'react-router-dom';

import { Icon } from '../../../icons';
import { MenuItemWithDropdownProps } from '../../types';

export const VerticalMenuItemWithDropdown = ({ text, menu, onClick }: MenuItemWithDropdownProps) => {
  const panelCn = 'ml-3';

  return (
    <Disclosure as="div" className="p-0">
      <DisclosureButton className={clsx('group vertical-menu-item')}>
        <span>{text}</span>
        <Icon
          type="s:chevron-down"
          className={clsx(`tw-text-neutral-800 transition group-data-[open]:rotate-180`)}
          size="sm"
        />
      </DisclosureButton>
      <DisclosurePanel transition className={panelCn}>
        {menu
          .filter((d) => d.display || d.display === undefined)
          .map((item) => (
            <DisclosureButton
              onClick={() => onClick?.(item)}
              as={Link}
              target={item.target}
              to={item.href}
              key={item.title}
              className={clsx('vertical-menu-item')}
            >
              <span>{item.title}</span>
            </DisclosureButton>
          ))}
      </DisclosurePanel>
    </Disclosure>
  );
};

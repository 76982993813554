import React from 'react';

import { Flex, Icon, Meta, Separator } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

function DocumentFooter() {
  const { t } = useTranslation();

  return (
    <Flex items={{ md: 'center' }} direction={{ md: 'row' }} gap={{ xs: 'xs', sm: 'xs', md: 'sm' }}>
      <Flex.Item>
        <Meta
          space="md"
          extra={<Icon size="lg" type="o:pencil-square" color="neutral-700" />}
          description={t('account.id-verification.name-surname')}
        />
      </Flex.Item>
      <Flex.Item flex items={{ xs: 'start', sm: 'start', md: 'center' }}>
        <Separator height="size-4" mx="md" />
      </Flex.Item>
      <Flex.Item>
        <Meta
          space="md"
          extra={<Icon size="lg" type="o:calendar" color="neutral-700" />}
          description={t('account.id-verification.birthdate')}
        />
      </Flex.Item>
      <Flex.Item flex items={{ xs: 'start', sm: 'start', md: 'center' }}>
        <Separator height="size-4" mx="md" />
      </Flex.Item>
      <Flex.Item>
        <Meta
          space="md"
          extra={<Icon size="lg" type="o:identification" color="neutral-700" />}
          description={t('account.id-verification.identity-number')}
        />
      </Flex.Item>
    </Flex>
  );
}

export default DocumentFooter;

import { useMemo } from 'react';

import { concatTicker, OHLCSType, parseAssetPair } from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';
import { useParams } from 'react-router-dom';

import useLastLivePrice from '@Libs/hooks/useLastLivePrice';
import getPriceChartData from '@Modules/market/detail/components/priceChart/helpers/getPriceChartData';

type Props = {
  priceData: OHLCSType;
};

const useUpdatePriceChart = ({ priceData }: Props) => {
  const { assetPair } = useParams();
  const [base, quote] = parseAssetPair(assetPair);

  const { liveData } = useLastLivePrice({ symbol: concatTicker(base, quote) });

  const priceChartData = useMemo(() => getPriceChartData(priceData), [priceData]);

  if (priceChartData.priceData.length && liveData) {
    priceChartData.priceData[priceChartData.priceData.length - 1].value = BigNumber(liveData.p).toNumber();
  }

  const priceChartDiffData = useMemo(
    () => ({
      firstData: priceChartData.priceData[0],
      lastData: priceChartData.priceData[priceChartData.priceData.length - 1],
    }),
    [priceChartData, liveData],
  );

  return {
    priceChartData: priceChartData.priceData,
    priceChartDiffData,
    athIndex: priceChartData.athIndex,
    atlIndex: priceChartData.atlIndex,
  };
};

export default useUpdatePriceChart;
